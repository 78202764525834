import React, { useState } from "react";
import { ProductOnboardingLayout } from "../../../Core/Layout/OnboardingLayouts";
import CustomButton from "../../../Core/Common/CustomButton";
import { useAppDispatch, useAppSelector } from "../../../Core/redux/hooks";
import {
  changeStep,
  handleCompanySize,
} from "../../../Core/redux/slices/onboarding.slice";
import CurrentStep from "../Project Onboarding/CurrentStep";
import { Radio } from "antd";
import type { RadioChangeEvent } from "antd";
import StepProgressBar from "./StepProgressBar";

const Content = () => {
  const dispatch = useAppDispatch();
  const { companySize } = useAppSelector((state) => state.onboarding);

  const [value, setValue] = useState(companySize);

  const handleRadioButtonChange = (e: RadioChangeEvent) => {
    setValue(e.target.value);
  };

  return (
    <div className="p-6 w-full h-screen bg-white">
      <div className="pl-14 w-full h-full bg-white flex justify-start items-center">
        <StepProgressBar
          currentStep={4}
          totalSteps={5}
          givenElementId="form4"
        />
        <div>
          <CurrentStep currentStep={4} totalStep={5} className="" id="form4" />
          <h1 className="mon-500-20 text-gray-900 mb-2 mt-6">
            How many people work at your company?
          </h1>
          <div className="flex justify-start items-center text-show-animation gap-x-2 mon-400-16 text-gray-500">
            <img
              src="/images/icons/frosty-icon.svg"
              className="min-h-[26px] min-w-[26px]"
              alt="frosty"
            />
            In other words, how many heads are solving the world’s problems?
          </div>
          <div className="gap-x-3">
            <Radio.Group onChange={handleRadioButtonChange} value={value}>
              <Radio className="text-gray-700 mon-500-14" value={1}>
                1 - 50
              </Radio>
              <Radio className="text-gray-700 mon-500-14" value={2}>
                50 - 100
              </Radio>
              <Radio className="text-gray-700 mon-500-14" value={3}>
                101 - 500
              </Radio>
              <Radio className="text-gray-700 mon-500-14" value={4}>
                More than 500
              </Radio>
            </Radio.Group>
          </div>
          <div className="flex items-center gap-x-6 mt-6">
            <CustomButton
              className="bg-primary-600 text-white"
              onClick={() => {
                dispatch(handleCompanySize(value));
                dispatch(changeStep(6));
              }}
              text="Continue"
              width="92px"
            />
            <CustomButton
              className="bg-white border-gray-200 border-[1px]"
              onClick={() => dispatch(changeStep(4))}
              text="Back"
              width="68px"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const Sidebar = () => {
  return (
    <div className="w-full h-screen overflow-hidden relative flex justify-center items-center bg-gray-50">
      <img
        src="/images/icons/company-size-frosty.svg"
        className="w-[188px] h-[277px]"
      />
    </div>
  );
};

const MapSelection = () => {
  return (
    <ProductOnboardingLayout content={<Content />} sidebar={<Sidebar />} />
  );
};

export default MapSelection;
