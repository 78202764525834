import React from "react";

const StepHeading = ({
  title,
  subTitle,
  className = "mt-6",
  subTitleAlignment = "items-center",
  frostyAlignment = "",
}) => {
  return (
    <div className={className}>
      <p className="text-gray-900 mon-500-20">{title}</p>
      <div
        className={`${subTitleAlignment} flex justify-start items-start text-show-animation-project gap-x-2 mon-400-16 text-gray-500`}
      >
        <img
          src="/images/icons/frosty-icon.svg"
          className={`min-h-[26px] min-w-[26px] ${frostyAlignment}`}
          alt="frosty"
        />
        <p className="mon-400-16 text-gray-500 pl-2">{subTitle}</p>
      </div>
    </div>
  );
};

export default StepHeading;
