import React from "react";
import CustomAvatar from "../../../Core/Common/CustomAvatar";
import CustomBadge from "../../../Core/Common/CustomBadge";
import CustomButton from "../../../Core/Common/CustomButton";
import CustomInput from "../../../Core/Common/CustomInput";
import CustomModal from "../../../Core/Common/CustomModal";
import {
  getMetaCategories,
  addMetaCategory,
  deleteMetaCategory,
} from "../../../Core/redux/api/settingsAPI";
import { useAppSelector, useAppDispatch } from "../../../Core/redux/hooks";
import PriorityIcon from "../../../Core/svg/PriorityIcon";
import TaskTypeIcon from "../../../Core/svg/TaskTypeIcon";
import { Dropdown } from "antd";
import _ from "lodash";
import { HexagonalColorPicker } from "react-hexagon-color-picker";
import CustomSearch from "../../../Core/Common/CustomSearch";

const MetaCard = ({ data, index, deleteFunction }) => {
  return (
    <div
      className={`border border-gray-200 rounded-xl min-w-[250px] min-h-[192px] p-4 group/card group-hover/card:bg-gray-50 cursor-pointer`}
    >
      <div className="flex justify-between items-center">
        <div className="bg-main-background max-w-max rounded-lg py-1 px-3 mon-500-12 text-gray-700 group-hover/card:bg-gray-500">
          {index + 1}
        </div>
        <div onClick={deleteFunction}>
          <img
            src="/images/icons/trash-03.svg"
            className="w-[15px] h-[15px] cursor-pointer"
            alt=""
          />
        </div>
      </div>
      <div className="mt-6">
        <p className="mon-500-10 text-gray-500">NAME</p>
        <p className="mon-500-12 mt-2 text-gray-900 ">{data.categoryName}</p>
      </div>
      <div className="mt-6">
        <p className="mon-500-10 text-gray-500">PREVIEW</p>
        <CustomBadge
          color={data.color}
          text={data.categoryName}
          className="mt-2"
        />
      </div>
    </div>
  );
};

const ScheduleMetadata = () => {
  const [selectedTab, setSelectedTab] = React.useState(null);
  const [selectedColor, setSelectedColor] = React.useState("");
  const [showAddModal, setShowAddModal] = React.useState(false);
  const [taskName, setTaskName] = React.useState("Mobile UI");
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    if (selectedTab !== null)
      dispatch(getMetaCategories({ metaId: selectedTab }));
  }, [selectedTab]);

  const { categoryList } = useAppSelector((state) => state.settings);

  const handleSearch = (search) => {
    dispatch(
      getMetaCategories({
        metaId: selectedTab,
        search,
      })
    );
  };

  return (
    <div className=" p-4">
      {selectedTab === null ? (
        <>
          <div className=" mt-2 mon-400-12 text-gray-500">
            Add or remove data related to your tasks/ event/ bugs in schedule
          </div>

          <div className=" px-2 mt-6 flex justify-start items-center gap-x-6">
            <div
              onClick={() => setSelectedTab(1)}
              className="cursor-pointer border border-gray-200 rounded-xl p-4 w-64 h-28"
            >
              <div className="flex justify-between items-center">
                <p className="mon-500-14 text-gray-900">Task Categories</p>
                <img src="/images/icons/chevron-right.svg" alt="" />
              </div>
              <p className="mt-4 mon-400-12 text-gray-500">Nothing added yet</p>
            </div>
            <div
              onClick={() => setSelectedTab(2)}
              className="cursor-pointer border border-gray-200 rounded-xl p-4 w-64 h-28"
            >
              <div className="flex justify-between items-center">
                <p className="mon-500-14 text-gray-900">Bug Categories</p>
                <img src="/images/icons/chevron-right.svg" alt="" />
              </div>
              <p className="mt-4 mon-400-12 text-gray-500">Nothing added yet</p>
            </div>
            <div
              onClick={() => setSelectedTab(3)}
              className="cursor-pointer border border-gray-200 rounded-xl p-4 w-64 h-28"
            >
              <div className="flex justify-between items-center">
                <p className="mon-500-14 text-gray-900">Priority</p>
                <img src="/images/icons/chevron-right.svg" alt="" />
              </div>
              <p className="mt-4 mon-400-12 text-gray-500">Nothing added yet</p>
            </div>
          </div>
        </>
      ) : (
        <div>
          <div className="flex justify-between items-center">
            <div
              onClick={() => setSelectedTab(null)}
              className="flex justify-start items-center gap-x-5 mt-1 cursor-pointer"
            >
              <img src="/images/icons/chevron-left.svg" alt="" />
              <div className="mon-500-12 text-gray-900">
                {selectedTab === 1 && "Task Categories"}
                {selectedTab === 2 && "Bug Categories"}
                {selectedTab === 3 && "Priority"}
              </div>
            </div>
            <div className="flex justify-end items-center gap-x-2">
              <CustomSearch
                style={{
                  backgroundColor: "#F0F5FA",
                }}
                // setSearch={handleSearch}
                placeholder="Search"
              />
              <CustomButton
                icon="/images/icons/plus-black.svg"
                text="Add"
                onClick={() => {
                  setShowAddModal(true);
                }}
                className="mon-500-14 bg-white rounded-xl border border-gray-200"
                width="90px"
              />
            </div>
          </div>
          {categoryList && categoryList?.length === 0 ? (
            <div>
              <div className="h-[calc(100vh-240px)] flex justify-center items-center">
                <div className="flex flex-col gap-y-2.5 bg-primary-50 rounded-lg p-2 text-left w-80">
                  <div className="mon-600-12 text-gray-700">
                    Nothing added yet
                  </div>
                  <div className="mon-400-12 text-gray-500">
                    Categories make it easier to identify different types of
                    tasks. Create categories based on your project requirements
                  </div>
                  <CustomButton
                    icon="/images/icons/plus.svg"
                    text="Add Category"
                    onClick={() => setShowAddModal(true)}
                    className="mon-500-14 text-white  bg-primary-600"
                    height="40px"
                    width="300px"
                  />
                </div>
              </div>
            </div>
          ) : (
            <div className=" mt-9 grid grid-cols-4 gap-6">
              {categoryList?.map((i, index) => (
                <MetaCard
                  key={i.id}
                  data={i}
                  index={index}
                  deleteFunction={async () => {
                    await dispatch(
                      deleteMetaCategory({
                        metaId: selectedTab,
                        categoryId: i.id,
                      })
                    );
                    await dispatch(
                      getMetaCategories({
                        metaId: selectedTab,
                      })
                    );
                  }}
                />
              ))}
            </div>
          )}
        </div>
      )}
      <CustomModal
        title={
          <p className="mon-500-16 text-gray-900">
            {selectedTab === 3 ? "Priority" : "Category"}
          </p>
        }
        width={"500px"}
        visible={showAddModal}
        body={
          <div
            className={`${
              selectedTab === 3
                ? "h-[25.189386056191466vh]"
                : "h-[38.189386056191466vh]"
            } overflow-y-scroll p-4`}
          >
            <p className="mon-500-14 text-gray-700">Name</p>
            <CustomInput
              onChange={(e) => setTaskName(e.target.value)}
              maxLength={40}
              placeholder="Eg. Design"
              className=" h-10 mt-1.5"
            />
            <p className="mon-500-14 text-gray-700 mt-6">Preview</p>
            {selectedTab === 3 ? (
              <div className="mt-2.5 flex justify-start items-center gap-x-2">
                <PriorityIcon priority={2} size={"16px"} />
                <p className="mon-400-12 text-primary-600">{taskName}</p>
              </div>
            ) : (
              <div
                className="bg-white border border-gray-200 p-4 rounded-xl mt-4 max-h-max
            cursor-default "
              >
                <div className="flex justify-between">
                  <div className="flex">
                    <TaskTypeIcon
                      type={selectedTab === 1 ? 1 : 3}
                      width="16"
                      height="16"
                    />
                    <h6 className="mon-500-12 text-gray-900 ml-2">
                      {selectedTab === 1 && "Sample Task Name"}
                      {selectedTab === 2 && "Sample Bug Name"}
                    </h6>
                  </div>
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    <img
                      src="/images/icons/chevron-down-gray.svg"
                      className="w-5 h-5"
                    />
                  </button>
                </div>
                <div className="mt-4">
                  <div className="flex justify-between">
                    <p className="mon-400-12 text-gray-500">1 Sub task</p>
                    <div className="flex items-center gap-x-2 ">
                      <CustomBadge
                        color={selectedColor}
                        text={taskName}
                        className="mt-2"
                      />
                      <PriorityIcon priority={2} size={"16px"} />
                      <CustomAvatar
                        title={"User"}
                        // src={assignee.profilePicture}
                        size={24}
                      />
                    </div>
                  </div>
                </div>
                <button
                  className="flex cursor-default justify-center items-center gap-x-2 w-full pt-2 rounded-lg mt-2"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <img src="/images/icons/plus-gray.svg" className="w-5 h-5" />
                  <span className="mon-500-14 text-gray-500">Add Subtask</span>
                </button>
              </div>
            )}
            <div className="mt-3 flex items-start cursor-pointer text-gray-900">
              <Dropdown
                getPopupContainer={(trigger) => trigger.parentElement}
                className=""
                overlay={
                  <div className="bg-white p-4 shadow-dropdown rounded-lg">
                    <HexagonalColorPicker
                      color={selectedColor}
                      onColorChange={(color) => setSelectedColor(color)} // f("#c45858")
                      // onColorClick={this._handle_color_menu_open}
                    />
                  </div>
                }
                trigger={["click"]}
              >
                <div className="flex item-center">
                  <p className="mon-500-12 text-gray-500">Change colour</p>
                  <img
                    src="/images/icons/chevron-down-gray.svg"
                    className="w-5 h-5 ml-2"
                  />
                </div>
              </Dropdown>
            </div>
          </div>
        }
        footer={
          <div className="flex justify-end p-3">
            <div className="w-2/4 flex justify-end gap-x-2">
              <CustomButton
                text="Cancel"
                onClick={() => setShowAddModal(false)}
                className="border-2 border-gray-200 text-black bg-white mon-500-16 "
                height="40px"
                width="49%"
              />
              <CustomButton
                text="Add"
                onClick={async () => {
                  await dispatch(
                    addMetaCategory({
                      metaId: selectedTab,
                      name: taskName,
                      color: selectedColor,
                    })
                  );
                  setShowAddModal(false);
                  await dispatch(
                    getMetaCategories({
                      metaId: selectedTab,
                    })
                  );
                }}
                className="mon-500-16 text-white  bg-primary-600 "
                height="40px"
                width="30%"
              />
            </div>
          </div>
        }
        onCancel={undefined}
      />
    </div>
  );
};

export default ScheduleMetadata;
