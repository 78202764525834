import { Col, Row } from "antd";
import CustomLayout from "../../Core/Layout/CustomLayout";
import TaskCard from "./TaskCard";
import KanbanBoard from "./KanbanBoard";
import ScheduleHeader from "./Components/ScheduleHeader";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../Core/redux/hooks";
import {
  getAllSprintsByPhase,
  getGoalsBySprint,
  getProjectPhases,
  getSprintsByPhase,
  getSwimlanes,
  updatePhase,
} from "../../Core/redux/api/scheduleAPI";
import {
  setCurrentSelectedPhase,
  updateSchedule,
} from "../../Core/redux/slices/schedule.slice";
import SetupPhaseModal from "../Overview/Components/SetupPhaseModal";
import { getProjectDetatis } from "../../Core/redux/api/projectAPI";

const ScheduleScreen = () => {
  const { projectDetails } = useAppSelector((state) => state.overview);
  const { schedule } = useAppSelector((state) => state.projects);

  const { projectId } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const {
    phases,
    currentSelectedPhase,
    sprintsLoading,
    sprints,
    newSprintModal,
    newGoalModal,
    newScopeModal,
    phaseLoading,
    allSprints,
  } = useAppSelector((state) => state.schedule);

  useEffect(() => {
    dispatch(getProjectPhases({ projectId }));
  }, [projectId]);

  useEffect(() => {
    dispatch(getProjectPhases({ projectId }));
  }, []);

  useEffect(() => {
    //TODO: this useEffect is running three time on one render. Please look into that
    if (currentSelectedPhase?.phases === searchParams.get("selectedPhase")) {
      // console.log("hellloooo");
      dispatch(
        getSprintsByPhase({
          projectId,
          phaseId: currentSelectedPhase.id,
        })
      );
    }
  }, [projectId, currentSelectedPhase, searchParams.has("selectedSprint")]);

  useEffect(() => {
    const found = phases?.findIndex(
      (p) => p.phases === searchParams.get("selectedPhase")
    );

    if (found === -1) {
      if (searchParams.has("selectedSprint")) {
        return;
      } else {
        navigate(`/schedule/${projectId}?selectedPhase=${phases[0]?.phases}`);
        dispatch(setCurrentSelectedPhase(phases?.[0]));
      }
    } else {
      dispatch(setCurrentSelectedPhase(phases?.[found]));
    }

    dispatch(
      getGoalsBySprint({
        projectId,
        sprintId: searchParams.get("selectedSprint"),
      })
    );
  }, [searchParams]);

  useEffect(() => {
    if (searchParams.has("selectedSprint")) {
      const found = sprints?.find(
        (s) => +s.id === +searchParams.get("selectedSprint")
      );
      console.log(
        found,
        sprints,
        +searchParams.get("selectedSprint"),
        "selectedSprint"
      );
      if (found) {
        dispatch(
          updateSchedule({
            key: "selectedSprint",
            value: found,
          })
        );
      }
      dispatch(
        getGoalsBySprint({
          projectId,
          sprintId: searchParams.get("selectedSprint"),
        })
      );
      dispatch(getSwimlanes({ projectId }));
    }
  }, [projectId, searchParams]);

  useEffect(() => {
    const found = sprints?.find(
      (s) => +s.id === +searchParams.get("selectedSprint")
    );

    if (found) {
      dispatch(
        updateSchedule({
          key: "selectedSprint",
          value: found,
        })
      );
    }
  }, [sprints]);

  useEffect(() => {
    const found = phases?.findIndex(
      (p) => p.phases === searchParams.get("selectedPhase")
    );

    if (found === -1) {
      if (searchParams.has("selectedSprint")) {
        return;
      } else {
        navigate(`/schedule/${projectId}?selectedPhase=${phases[0]?.phases}`);
        dispatch(setCurrentSelectedPhase(phases?.[0]));
      }
    } else {
      dispatch(setCurrentSelectedPhase(phases?.[found]));
    }
  }, [phases]);

  return (
    <CustomLayout page="schedule">
      {schedule === null ? (
        <div className="h-[calc(100vh-200px)] w-full flex justify-center items-center flex-col">
          <img src="/images/noFiles.svg" />
          <p className="mon-500-12 text-gray-500 mt-3">
            You do not have any view permissions
          </p>
        </div>
      ) : (
        <Row className="min-h-[calc(100vh-56px)] max-h-[calc(100vh-56px)]">
          <Col span={24}>
            <ScheduleHeader />
            <KanbanBoard />
          </Col>
        </Row>
      )}
    </CustomLayout>
  );
};

export default ScheduleScreen;
