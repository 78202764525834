import React from "react";

const CurrentStep = ({ currentStep, totalStep, className, id = "" }) => {
  return (
    <div className={className} id={id}>
      <p className="mon-500-14 text-gray-900">
        Step {currentStep} /
        <span className="mon-500-14 text-gray-500"> {totalStep}</span>
      </p>
    </div>
  );
};

export default CurrentStep;
