import { Checkbox, Col, Row, Table, Tooltip } from "antd";
import { ColumnsType } from "antd/lib/table";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CustomAvatar from "../../../Core/Common/CustomAvatar";
import CustomButton from "../../../Core/Common/CustomButton";
import CustomModal from "../../../Core/Common/CustomModal";
import CustomSearch from "../../../Core/Common/CustomSearch";
import CustomSelect from "../../../Core/Common/CustomSelect";
import { CustomSelectWithInlineSearch } from "../../../Core/Common/CustomSelectWithSearch";
import CustomLayout from "../../../Core/Layout/CustomLayout";
// import { setSuccess } from "../../Core/redux/slices/global.slice";
import { setSuccess } from "../../../Core/redux/slices/global.slice";
import {
  getAllMembers,
  getAllRoles,
} from "../../../Core/redux/api/settingsAPI";
import { useAppDispatch, useAppSelector } from "../../../Core/redux/hooks";
import { client } from "../../../Core/utils/axiosClient";
import AddMembersModal from "../../Overview/Components/AddMembersModal";
import Integrations from "./Product Pages/Integrations";
import MyAccount from "./Product Pages/MyAccount";
import Support from "./Product Pages/Support";

const TeamMembersRole = () => {
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.userDetails);
  const { selectedProject } = useAppSelector((state) => state.projects);
  const navigate = useNavigate();

  // const { memberList } = useAppSelector((state) => state.settings);
  const [memberList, setMemberList] = useState([]);

  const [modalState, setModalState] = useState({
    memberId: null,
    roleId: null,
  });

  const getMemberWithRoles = async () => {
    console.log("hehe");
    try {
      const response = await client.get(`/settings/get-all-product-members`);

      console.log(response.data.result);
      setMemberList(response.data.result);
    } catch (error) {
      console.log(error);
      //return error;
    }
  };

  useEffect(() => {
    getMemberWithRoles();
  }, []);

  const [selectedMemberList, setSelectedMemberList] = useState([]);

  const handleCheckMember = async (id, checked) => {
    const memberIndex = selectedMemberList.findIndex(
      ({ id: memberId }) => id === memberId
    );
    const members = [...selectedMemberList];
    members[memberIndex] = {
      ...members[memberIndex],
      checked,
    };
    setSelectedMemberList(members);
  };

  const [addMemberModal, setAddMemberModal] = useState({
    visible: false,
  });

  const roleList = [
    {
      id: 1,
      title: "Owner",
      description: "Can manage all admins, users, product settings and bills",
    },
    {
      id: 2,
      title: "Admin",
      description: "Has permissions for all administrative features",
    },
    {
      id: 3,
      title: "Basic User",
      description: "Doesn’t have permissions for administrative features",
    },
  ];

  const onSubmit = () => {
    console.log(selectedMemberList, roleList);
    let list = selectedMemberList.map((member) => {
      const roleName = roleList.filter(({ id }) => +id === +member.roleId)[0]
        .title;
      console.log(roleName, member);
      return {
        ...member,
        roleName: roleName,
      };
    });
    list = list
      .filter(({ checked }) => checked)
      .map(({ id, roleId, roleName }) => {
        return {
          userId: id,
          roleId,
          roleName,
        };
      });

    console.log(list);

    client
      .put("/settings/member-assign-roles", {
        projectId: selectedProject,
        memberList: list,
      })
      .then((res) => {
        console.log(res.data);
      })
      .finally(() => {
        console.log("hello");
        setAddMemberModal({ ...addMemberModal, visible: false });
        // getMemberWithRoles();
        setSelectedMemberList([]);
      });
  };

  const handleDelete = async (userId) => {
    try {
      console.log(userId, "record in handle delete");
      const response = await client.delete(`/settings/delete-member/`, {
        params: { userId },
      });

      setMemberList((prevMemberList) => {
        return prevMemberList?.filter(({ id }) => id !== userId);
      });
      dispatch(setSuccess({ message: "Users deleted successfully" }));
    } catch (error) {
      console.log(error);
      //return error;
    }
  };

  interface DataType {
    firstname: string;
    lastname: string;
    id: string;
    roleId: string;
    profilePicture: string;
  }

  const columns: ColumnsType<DataType> = [
    {
      title: "NAME",
      dataIndex: "name",
      key: "name",
      width: "20vw",
      sorter: true,
      render: (text, { firstname, lastname, profilePicture }) => (
        <div className="flex items-center">
          <CustomAvatar
            className="mr-3"
            title={`${firstname?.[0]} ${lastname?.[0]}`}
            size={24}
            fontSize="12px"
            src={profilePicture}
          />
          <div className="mon-400-12 text-gray-500">
            {firstname} {lastname}
          </div>
        </div>
      ),
    },
    {
      title: "DATE ADDED",
      dataIndex: "createdAt",
      key: "createdAt",
      // width: "10vw",
      sorter: true,
      render: (text) => (
        <div className="mon-400-12 text-gray-500">
          {dayjs(text).format("DD MMM YYYY")}
        </div>
      ),
    },
    {
      title: "LAST ACTIVE",
      dataIndex: "updatedAt",
      // width: "10vw",
      key: "updatedAt",
      sorter: true,
      render: (text) => (
        <div className="mon-400-12 text-gray-500">
          {text ? dayjs(text).format("DD MMM YYYY") : "-"}
        </div>
      ),
    },
    {
      width: "3vw",
      key: "updatedAt",
      render: (text, record) => (
        <div
          className="actions"
          onClick={() => {
            handleDelete(record.id);
          }}
        >
          <>{console.log(record, "recordd")}</>
          <Tooltip placement="bottom" title="Remove">
            <img
              src="/images/icons/trash-03.svg"
              className="w-[20px] h-[20px] min-h-[20px] min-w-[20px] cursor-pointer "
              alt=""
            />
          </Tooltip>
        </div>
      ),
    },
  ];

  const renderDataSource = (roleId) => {
    const dataSource = memberList?.filter(({ roleid: id }) => +id === +roleId);
    console.log("hehe", roleId, memberList, dataSource);
    return dataSource;
  };

  const [inviteMembers, setInviteMembers] = useState(false);
  const [searchRoles, setSearchRoles] = useState("");

  return (
    <div className="p-4">
      <div className="flex justify-between items-center">
        <div>
          {/* <div className="mon-500-16 text-black">Role names</div> */}
          <div className="mon-400-12 text-gray-500">
            Manage your team members and their role permissions here
          </div>
        </div>
        <div className="flex justify-end items-center gap-x-2">
          <CustomSearch
            style={{
              backgroundColor: "#F0F5FA",
            }}
            placeholder="Search Roles"
            setSearch={setSearchRoles}
          />
          <CustomButton
            icon="/images/icons/plus-black.svg"
            text="Invite Members"
            onClick={() => {
              // setInviteMembers(true);
              navigate("/invite-members");
            }}
            className="mon-500-14 bg-white rounded-xl border border-gray-200"
            // height="40px"
            width="180px"
          />
        </div>
      </div>
      <div className=" mt-8">
        {Array.isArray(roleList) && roleList.length === 0 ? (
          <Row>
            <Col span={24}>
              <div className="h-[calc(100vh-200px)] flex justify-center items-center">
                <div className="flex flex-col gap-y-2.5 bg-primary-50 rounded-lg p-2 text-left w-80">
                  <div className="mon-600-12 text-gray-700">
                    No members assigned
                  </div>
                  <div className="mon-400-12 text-gray-500">
                    Create a project role first to assign members to the roles
                    created
                  </div>
                  <CustomButton
                    icon="/images/icons/plus.svg"
                    text="Create Role"
                    onClick={() => {
                      setAddMemberModal({
                        ...addMemberModal,
                        visible: true,
                      });
                    }}
                    className="mon-500-14 text-white  bg-primary-600"
                    height="40px"
                    width="300px"
                    // disabled={showError || addGoal}
                    // icon="/images/icons/featured-thread-icon.svg"
                  />
                </div>
              </div>
            </Col>
            {/* <Col span={7}>
            <div className="mb-3 pr-4"></div>
            </Col>
          <Col span={17}></Col> */}
          </Row>
        ) : (
          <div className="h-[calc(100vh-200px)] overflow-y-scroll">
            {Array.isArray(roleList) &&
              roleList.map((role) => (
                <Row key={role.id} className="border-b border-gray-200">
                  <Col span={10}>
                    <div className=" pl-2.5 pt-6">
                      <div className="mon-500-14 text-gray-900">
                        {role.title}
                      </div>
                      <div
                        className="mon-400-12 text-gray-500"
                        // onClick={() => dispatch(changeTab("3"))}
                      >
                        {role.description}
                      </div>
                      <div></div>
                    </div>
                  </Col>
                  <Col span={14}>
                    <div className=" flex justify-center pb-4 items-center">
                      <Table
                        columns={columns}
                        dataSource={renderDataSource(role.id)}
                        className={`table-shadow custom-table custom-roles-table w-full`}
                        //style={{ maxHeight: "20vh" }}
                        scroll={{
                          y: "20vh",
                        }}
                        footer={() => <></>}
                        pagination={false}
                      />
                    </div>
                  </Col>
                </Row>
              ))}
          </div>
        )}
      </div>
      {/* <CustomModal
        title={<p className="mon-400-12 text-gray-500">Add Members</p>}
        width={"724px"}
        visible={addMemberModal.visible}
        body={
          <div className="h-[48.189386056191466vh] overflow-y-scroll p-4">
            <p className="mon-500-14 text-gray-700">Select members</p>
            <div>
              <div className="grid grid-cols-6 gap-x-4 mt-1.5">
                <div className="col-span-4">
                  <CustomSelectWithInlineSearch
                    value={modalState.memberId}
                    margin="mt-0"
                    group={false}
                    placeholder="Search member"
                    options={memberList?.map((s, i) => {
                      return {
                        id: s.id,
                        value: s.id,
                        render: () => (
                          <div className="flex justify-between items-center">
                            <p className=" capitalize">
                              {s.firstname} {s.lastname}
                            </p>
                            {selectedMemberList?.findIndex(
                              ({ id }) => id === s.id
                            ) !== -1 && (
                              <img src="/images/icons/check.svg" alt="" />
                            )}
                          </div>
                        ),
                      };
                    })}
                    onChange={(e) => {
                      console.log(e);

                      const isSelectedAlready =
                        selectedMemberList.findIndex(({ id }) => id === e) !==
                        -1;
                      if (isSelectedAlready) {
                        const newList = [...selectedMemberList].filter(
                          ({ id }) => id !== e
                        );
                        setSelectedMemberList(newList);
                        return;
                      }

                      if (modalState.roleId) {
                        const newList = [...selectedMemberList];
                        const member = memberList.filter(
                          ({ id }) => +id === +e
                        )[0];

                        const newMember = {
                          ...member,
                          roleId: modalState.roleId,
                          checked: true,
                        };

                        newList.push(newMember);
                        setSelectedMemberList(newList);
                        setModalState({
                          ...modalState,
                          memberId: null,
                          roleId: null,
                        });
                      } else {
                        setModalState({
                          ...modalState,
                          memberId: e,
                        });
                      }
                    }}
                  />
                </div>
                <div className="col-span-2">
                  <CustomSelect
                    // border={false}
                    value={modalState.roleId}
                    placeholder="Select your sector"
                    backgroundColor="bg-primary-50 text-primary-700 border border-primary-50"
                    options={
                      Array.isArray(roleList) &&
                      roleList?.map((s) => {
                        return {
                          id: s.id,
                          value: s.id,
                          render: () => (
                            <div className="flex justify-between items-center">
                              <p>{s.title}</p>
                            </div>
                          ),
                        };
                      })
                    }
                    onChange={(e) => {
                      console.log(e);
                      if (modalState.memberId) {
                        const newList = [...selectedMemberList];
                        const member = memberList.filter(
                          ({ id }) => +id === +modalState.memberId
                        )[0];
                        const newMember = {
                          ...member,
                          roleId: e,
                          checked: true,
                        };
                        newList.push(newMember);
                        setSelectedMemberList(newList);
                        setModalState({
                          ...modalState,
                          memberId: null,
                          roleId: null,
                        });
                      } else {
                        setModalState({
                          ...modalState,
                          roleId: e,
                        });
                      }
                    }}
                  />
                </div>
              </div>
              <div className="mt-5">
                {selectedMemberList
                  // ?.filter((item) => selectedMemberList?.includes(item?.id))
                  ?.map((member, i) => (
                    <div
                      className="flex justify-between items-center"
                      key={member.id}
                    >
                      <div className="flex justify-start items-center gap-x-2">
                        <Checkbox
                          checked={member.checked}
                          onChange={(e) => {
                            handleCheckMember(member.id, e.target.checked);
                          }}
                        />
                        <img
                          src={member.profilePicture}
                          className=" object-cover w-10 h-10 rounded-full"
                        />
                        <div className="capitalize mon-500-14 text-gray-700">
                          {member.firstname} {member.lastname}
                        </div>
                      </div>
                      <div>
                        {member.roleId &&
                          roleList.filter(({ id }) => id === member.roleId)[0]
                            .title}
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        }
        footer={
          <div className="flex justify-between pt-8 pb-4 px-3">
            <CustomButton
              text="Cancel"
              onClick={() => {
                setAddMemberModal({
                  ...addMemberModal,
                  visible: false,
                });
              }}
              className="border-2 border-gray-200 text-black bg-white mon-500-16 "
              height="44px"
              width="49%"
            />
            <CustomButton
              text="Assign Role"
              onClick={onSubmit}
              className="mon-500-16 text-white  bg-primary-600 "
              height="44px"
              width="49%"
            />
          </div>
        }
        onCancel={() => {
          console.log("onCancel");
          setAddMemberModal({
            ...addMemberModal,
            visible: false,
          });
        }}
      />
      <AddMembersModal
        visible={inviteMembers}
        onClose={() => setInviteMembers(false)}
      /> */}
    </div>
  );
};

const tabData = (currentTab) => {
  console.log(+currentTab);
  switch (+currentTab) {
    case 1:
      return <MyAccount />;
    case 2:
      return <Integrations />;
    case 3:
      return <TeamMembersRole />;
    case 4:
      return <Support />;
  }
};

const Content = () => {
  const { currentProductTab } = useAppSelector((state) => state.settings);
  console.log(currentProductTab);
  return (
    <div className="p-6">
      <div className="w-full min-h-[calc(100vh-106px)] bg-white rounded-xl shadow-hex">
        {tabData(currentProductTab)}
      </div>
    </div>
  );
};

const ProductSettingsScreen = () => {
  return (
    <CustomLayout page="product-settings">
      <div className="w-full h-full bg-main-background">
        <Content />
      </div>
    </CustomLayout>
  );
};

export default ProductSettingsScreen;
