import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Row, Col, Tabs, Checkbox, Dropdown, Menu, Modal, Tooltip } from "antd";
import CustomLayout from "../../Core/Layout/CustomLayout";
import ProjectOverview from "./Components/ProjectOverview";
import CustomCalendar from "./Components/CustomCalendar";
import { useState } from "react";
import CustomSearch from "../../Core/Common/CustomSearch";
import CustomButton from "../../Core/Common/CustomButton";
import CustomTable from "../../Core/Common/CustomTable";
import CustomBadge from "../../Core/Common/CustomBadge";
import CustomModal from "../../Core/Common/CustomModal";
import RemoveMemberModal from "../../Core/Common/RemoveMemberModal";
import ProfileDrawer from "./Components/ProfileDrawer";
import CopyButton from "../../Core/Common/CopyButton";
import UpcomingGoals from "./Components/UpcomingGoals";
import ProjectInfo from "./Components/ProjectInfo";
import PhasesInfo from "./Components/PhasesInfo";
import CustomFrostyMessage from "../../Core/Common/CustomFrostyMessage";
import UpcomingEvents from "./Components/UpcomingEvents";
import ComponentsDrawer from "./Components/ComponentsDrawer";
import { useAppDispatch, useAppSelector } from "../../Core/redux/hooks";
import CustomAvatar from "../../Core/Common/CustomAvatar";
import { addActivityLog } from "../../Core/redux/api/activityLogAPI";
import AddMembersModal from "./Components/AddMembersModal";
import CompleteProjectModal from "./Components/CompleteProjectModal";

const { TabPane } = Tabs;
const OverviewScreen = () => {
  const { projectId } = useParams();
  const [currentTab, setCurrentTab] = useState("summary");
  const [currentPageNo, setCurrentPageNo] = useState(1);
  const [welcomeModal, setWelcomeModal] = useState(
    !localStorage.getItem("welcome")
  );

  const { user: currentUser } = useAppSelector((state) => state.userDetails);

  const { projectList } = useAppSelector((state) => state.projects);
  const { projectDetails } = useAppSelector((state) => state.overview);

  const [limit, setLimit] = useState(10);
  const [visible, setVisible] = useState(false);
  const [completeProjectModal, setCompleteProjectModal] = useState(false);
  const [removeMemberModal, setRemoveMemberModal] = useState(false);
  const [showProfileDrawer, setShowProfileDrawer] = useState(false);
  const [user, setUser] = useState({
    firstname: "",
    lastname: "",
    email: "",
    mobile: "",
    roleName: "",
    profilePicture: "",
    location: "",
    lat: 0,
    long: 0,
    createdAt: "",
    jobTitle: "",
    company: "",
    userId: "",
    spoc: 0,
  });
  const { userList } = useAppSelector((state) => state.overview);
  const { overview } = useAppSelector((state) => state.projects);
  const manageProjects = overview?.[1]?.[0];
  const manageMembers = overview?.[2]?.[0] || {};
  const [filteredUserList, setFilteredUserList] = useState([...userList]);

  const { user: globalUserState } = useAppSelector(
    (state) => state.userDetails
  );

  useEffect(() => {
    overview !== null && setFilteredUserList([...userList]);
  }, [userList]);

  const handleSearch = (searchText) => {
    const inputData = searchText.toLowerCase();
    const users = [...userList];
    if (inputData === "") {
      setFilteredUserList(users);
      return;
    }

    const filteredData = users.filter((item) => {
      if (item.firstname !== null) {
        return (
          item.firstname.toLowerCase().includes(inputData) ||
          item.lastname.toLowerCase().includes(inputData) ||
          item.email.includes(inputData)
        );
      } else {
        if (item.email) {
          return item.email.includes(inputData);
        }
      }
    });
    setFilteredUserList(filteredData);
    return;
  };

  const dispatch = useAppDispatch();

  const nullSafeCompare = (a, b) => {
    if (!a && !b) {
      return 0;
    }
    if (!a) {
      return 1;
    }
    if (!b) {
      return -1;
    }
    return a.localeCompare(b);
  };

  const columns = [
    {
      title: () => (
        <div className="mon-500-10 pl-1.5 text-gray-500 uppercase">Name</div>
      ),
      width: "30%",
      dataIndex: "firstname",
      defaultSortOrder: "descend",
      sorter: (a, b) => nullSafeCompare(a.firstname, b.firstname),
      render: (text, record) => (
        <div className="flex  items-center   pl-1.5">
          {/* <img
            src={record.profilePicture || "/images/Avatar.png"}
            className="h-8 w-8 rounded-full"
            alt={text}
          /> */}
          <CustomAvatar
            title={text === null ? "User" : text}
            size={32}
            src={record.profilePicture}
            style={{
              border: record.spoc === 1 ? "2px solid #165ABF" : "",
            }}
          />
          {record.spoc === 1 && (
            <div
              className="mon-500-12 h-[12px] w-[32px] absolute rounded bottom-3 text-white bg-primary-600 flex justify-center items-center"
              style={{
                fontSize: "6.5px",
              }}
            >
              <p>SPOC</p>
            </div>
          )}
          <div className="max-w-[calc(100%-110px)]">
            <div className="mon-500-12 capitalize truncate whitespace-nowrap  pl-4">
              {typeof text === undefined || text === null
                ? "User"
                : `${text} ${record.lastname}`}
            </div>
            <div className="mon-400-12 text-gray-500 truncate whitespace-nowrap lowercase pl-4">
              {record.email}
            </div>
          </div>
          <div className="flex actions  relative ml-2">
            <CopyButton text={text} />
          </div>
        </div>
      ),
    },
    {
      title: () => (
        <div className="flex items-center">
          <div className="mon-500-10 text-gray-500 uppercase ">
            Mobile number
          </div>
        </div>
      ),
      //width: '12.85%',
      dataIndex: "mobile",
      defaultSortOrder: "descend",
      sorter: (a, b) => a.mobile - b.mobile,
      render: (text, record) => (
        <div className="mon-400-12 text-gray-500 ">
          {typeof text === undefined || text === null ? "N/A" : text}
        </div>
      ),
    },
    {
      title: () => (
        <div className="flex items-center">
          <div className="mon-500-10 text-gray-500 uppercase">Job title</div>
        </div>
      ),
      //width: '12.85%',
      dataIndex: "jobTitle",
      defaultSortOrder: "descend",
      sorter: (a, b) => nullSafeCompare(a.jobTitle, b.jobTitle),
      render: (text, record) => (
        <div className="mon-400-12 text-gray-500">{text || "N/A"}</div>
      ),
    },
    {
      title: () => (
        <div className="mon-500-10 text-gray-500 uppercase">Country</div>
      ),
      //width: '12.85%',
      dataIndex: "location",
      defaultSortOrder: "descend",
      sorter: (a, b) => nullSafeCompare(a.location, b.location),
      render: (text, record) => (
        <div className="flex items-center">
          <div className="mon-500-12 text-gray-900 pl-2">{text || "N/A"}</div>
          {/* <div className="actions relative">
            <CopyButton text={text} />
          </div> */}
        </div>
      ),
    },
    {
      title: () => (
        <div className="flex">
          <div className="mon-500-10 text-gray-500 uppercase">Project Role</div>
          <Tooltip
            title={
              <div className="m-3">
                <p className="mon-500-12 text-[#F9FBFE]">Project Role</p>
                <p className="mon-400-12 text-[#F9FBFE] mt-1">
                  A project role defines the add, edit, delete accesses of a
                  member in this project
                </p>
              </div>
            }
            placement="bottom"
          >
            <div>
              <img src="/images/icons/help-icon.svg" className="pl-2" />
            </div>
          </Tooltip>
        </div>
      ),
      //width: '12.85%',
      dataIndex: "roleName",
      // defaultSortOrder: "descend",
      // sorter: (a, b) => a.id - b.id,
      render: (text, record) => (
        // <div className="mon-400-12 text-gray-500">{text}</div>
        <CustomBadge
          dot
          text={text}
          color="#F40849"
          className="mon-500-12 pt-0.5"
        />
      ),
    },
    {
      title: () => <></>,
      width: 50,
      dataIndex: "more",
      render: (text, record) => (
        <div className="actions">
          <Dropdown
            getPopupContainer={(trigger) => trigger.parentElement}
            className=""
            overlay={
              <Menu className="w-[200px] rounded-lg custom-menu-dropdown  shadow-dropdown">
                <Menu.Item
                  className=" py-2.5 px-4 rounded-t-lg"
                  onClick={async () => {
                    setUser(record);
                    setShowProfileDrawer(true);
                    await dispatch(
                      addActivityLog({
                        meta: JSON.stringify(record),
                        activityType: 22,
                      })
                    );
                  }}
                >
                  <button>
                    <span className="mon-400-14 whitespace-nowrap hover:font-medium  text-gray-700">
                      View profile
                    </span>
                  </button>
                </Menu.Item>
                {/* <Menu.Item
                  className="py-2.5 px-4 rounded-b-lg"
                  onClick={() => setRemoveMemberModal(true)}
                >
                  <button>
                    <span className="mon-400-14 whitespace-nowrap  hover:font-medium text-error-600">
                      Remove member
                    </span>
                  </button>
                </Menu.Item> */}
                {/* commented due to bug no. #WV12-1449 */}
              </Menu>
            }
            trigger={["hover"]}
          >
            <div className=" cursor-pointer">
              <img
                src="/images/icons/more-vertical.svg"
                className="w-5 h-5 "
                alt="add-item"
              />
            </div>
          </Dropdown>
        </div>
      ),
    },
  ];

  return (
    <>
      <CustomLayout page="overview">
        {overview === null ? (
          <div className="h-[calc(100vh-200px)] w-full flex justify-center items-center flex-col">
            <img src="/images/noFiles.svg" />
            <p className="mon-500-12 text-gray-500 mt-3">
              You do not have any view permissions
            </p>
          </div>
        ) : (
          <div className="flex max-w-[calc(100vw-200px)] min-h-[calc(100vh-56px)]">
            <div className="bg-main-background min-w-[calc(100vw-544px)] p-6">
              <Tabs
                onTabClick={(key, event) => {
                  setCurrentTab(key);
                }}
                activeKey={currentTab}
                className="overview-tab"
                // renderTabBar={renderTabBar}
              >
                <TabPane
                  tab={
                    <div className="flex">
                      <div
                        className={`${
                          currentTab === "summary"
                            ? "font-medium text-sm text-primary-600"
                            : "text-gray-500 text-xs font-normal"
                        }`}
                        style={{ fontSize: "12px" }}
                      >
                        Summary
                      </div>
                    </div>
                  }
                  key="summary"
                  // style={tab?.style}
                  // disabled={tab?.disabled}
                >
                  {!projectDetails.isEnded &&
                    currentUser &&
                    currentUser?.roleId < 3 && (
                      <div className="relative">
                        <div className="flex absolute -top-14 right-0">
                          <CustomButton
                            text="Complete This Project"
                            className="border border-gray-200 text-gray-700 bg-white mon-500-14 "
                            onClick={() => setCompleteProjectModal(true)}
                            width="195px"
                          />
                        </div>
                      </div>
                    )}
                  <Row>
                    <CustomFrostyMessage>
                      <div className=" text-primary-600 text-xs  whitespace-nowrap font-medium pr-2">
                        Welcome to Waddle
                      </div>
                      <div className="w-[17.44px] h-[17.74px]">
                        <img
                          className=" w-full h-full"
                          src="/images/icons/emoji-party-popper.svg"
                          alt="party popper"
                        />
                      </div>
                      <div className=" text-primary-600 whitespace-nowrap   text-xs font-medium pl-2">
                        Let&#39;s begin this project by setting up all the
                        phases
                      </div>
                    </CustomFrostyMessage>
                  </Row>
                  <Row gutter={[24, 24]}>
                    <Col span={11}>
                      <ProjectOverview />
                    </Col>
                    <Col span={13}>
                      <UpcomingGoals />
                    </Col>
                    <Col span={11}>
                      <ProjectInfo />
                    </Col>
                    <Col span={13}>
                      {" "}
                      <PhasesInfo />
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <ComponentsDrawer />
                    </Col>
                  </Row>
                </TabPane>
                <TabPane
                  tab={
                    <div className="flex">
                      <div
                        className={`${
                          currentTab === "people"
                            ? "font-medium text-xs text-primary-600"
                            : "text-gray-500 text-xs font-normal"
                        }`}
                        style={{ fontSize: "12px" }}
                      >
                        People
                      </div>
                    </div>
                  }
                  key="people"
                  style={{ paddingBottom: "4px" }}

                  // disabled={tab?.disabled}
                >
                  <div className="relative">
                    <div className="flex absolute -top-14 right-0">
                      <div className="flex justify-center items-center">
                        <img
                          className="w-5 h-5"
                          src="/images/icons/Filters-lines.svg"
                        />
                        <p className="font-medium ml-2.5 text-sm text-gray-500">
                          Filter
                        </p>
                      </div>
                      <div className="ml-3">
                        <CustomSearch
                          width="min-w-[286px]"
                          placeholder="Search members"
                          setSearch={(e) => handleSearch(e)}
                        />
                      </div>
                      {manageMembers?.read === 1 && (
                        <div className="ml-3">
                          <CustomButton
                            text="Add Members"
                            icon="/images/icons/plus.svg"
                            onClick={() => setVisible(true)}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="bg-white rounded-2xl mt-2 shadow-table">
                    <div className="flex pl-6 items-center h-16">
                      <p className="mon-500-16 text-gray-900">Team members</p>
                      <CustomBadge
                        text={
                          filteredUserList.length === 1
                            ? `${filteredUserList.length.toString()} user`
                            : `${filteredUserList.length.toString()} users`
                        }
                        color="#144EA6"
                        className="ml-2"
                      />
                    </div>
                    <CustomTable
                      // customPadding="p-3"
                      className="custom-table custom-border"
                      scroll="calc(100vh - 325px)"
                      columns={columns}
                      data={filteredUserList}
                      dataCount={filteredUserList.length}
                      current={currentPageNo}
                      pageSize={
                        filteredUserList.length < limit
                          ? filteredUserList.length
                          : limit
                      }
                      setPageSize={setLimit}
                      setCurrent={(e) => {
                        setCurrentPageNo(e);
                      }}
                    />
                  </div>
                </TabPane>
              </Tabs>
            </div>
            {currentTab === "summary" && (
              <div className="bg-white max-w-[344px]">
                <div className=" p-6">
                  <CustomCalendar />
                  <div className="pt-4 flex justify-between items-center">
                    <div className="flex items-center">
                      <img
                        src="/images/icons/CloudSun.svg"
                        className="w-8 h-8"
                        alt="sun"
                      />
                      <div className="text-medium flex items-center text-xs text-gray-500 ml-3">
                        <span>Today</span>
                        <div className="mx-2 rounded-full w-1.5 h-1.5 bg-gray-500  " />
                        <span>Mumbai, IND</span>
                      </div>
                    </div>
                    <div className="text-primary-600 text-xs font-medium">
                      Jun 01
                    </div>
                  </div>
                  <div className="mt-8">
                    <h3 className="font-medium text-base text-black">
                      <UpcomingEvents />
                    </h3>
                    {/* <div className="mt-6 px-2 pt-2 pb-4 bg-primary-50 rounded-lg">
                    <div className="flex justify-between items-center ">
                      <div className="flex  items-center">
                        <img
                          src="/images/icons/Googlecalendar.svg"
                          className="h-5 w-5"
                        />
                        <p className="text-gray-700 text-xs font-semibold ml-3.5">
                          Google Calendar
                        </p>
                      </div>
                      <img
                        src="/images/icons/Question.svg"
                        className="h-5 w-5"
                      />
                    </div>
                    <p className="text-xs text-gray-500 font-normal pt-3">
                      Bring all your meetings here without leaving Waddle!
                    </p>
                    <div className="flex justify-center  items-center mt-2.5 w-full shadow-button rounded-xl border border-gray-200 bg-white">
                      <img
                        src="/images/icons/Googlecalendar.svg"
                        className="h-5 w-5"
                      />
                      <p className="text-sm font-medium text-gray-700 ml-2.5  py-2.5">
                        Integrate Google Calendar
                      </p>
                    </div>
                  </div> */}
                  </div>
                </div>
              </div>
            )}
          </div>
        )}

        <AddMembersModal
          visible={visible}
          onClose={() => setVisible(false)}
          prefiledValue={{ roleId: null, memberId: null }}
        />
      </CustomLayout>
      <RemoveMemberModal
        visible={removeMemberModal}
        onCancel={() => setRemoveMemberModal(false)}
      />
      <ProfileDrawer
        visible={showProfileDrawer}
        onClose={() => setShowProfileDrawer(false)}
        user={user}
      />
      <CompleteProjectModal
        visible={completeProjectModal}
        onCancel={() => setCompleteProjectModal(false)}
      />

      <Modal
        open={welcomeModal && projectList.length === 1}
        centered
        // zIndex={50}
        //width={"100px"}
        modalRender={() => (
          <div
            className="w-[90vw] max-w-[524px] h-full bg-white rounded-2xl"
            style={{
              pointerEvents: "all",
              //   maxHeight: height,
              //   minHeight: height,
              //   maxWidth: width,
              //   minWidth: width,
              //   overflowY: "scroll",
            }}
          >
            <div className=" flex justify-end px-4 py-4">
              <button
                onClick={() => {
                  setWelcomeModal(false);
                  localStorage.setItem("welcome", "true");
                }}
              >
                <img src="/images/icons/x-close.svg" />
              </button>
            </div>
            <div className="flex justify-center items-center relative">
              <img src="/images/mountains.svg" className="absolute bottom-0" />
              <img src="/images/icons/welcome.svg" className="relative z-10" />
            </div>
            <div className="flex flex-col items-center">
              <div className=" mt-8 mon-500-14 text-black">
                Hello,{" "}
                <span className="capitalize">{globalUserState?.firstName}</span>
                ! 👋
              </div>
              <div className="mt-5 mon-500-14 text-black">
                Are you excited to start your first project?
              </div>
            </div>
            <div className="px-4 pb-4 pt-10">
              <CustomButton
                text="Yes, Let’s Start"
                width="100%"
                onClick={() => {
                  setWelcomeModal(false);
                  localStorage.setItem("welcome", "true");
                }}
              />
            </div>
          </div>
        )}
      />
    </>
  );
};

export default OverviewScreen;
