import React, { useState, useEffect, useRef } from "react";
import { useDrop, useDrag } from "react-dnd";
import { useAppDispatch, useAppSelector } from "../../../Core/redux/hooks";

const KanbanItem = ({ id, children }) => {
  const dispatch = useAppDispatch();
  const { schedule } = useAppSelector((state) => state.projects);
  const phaseAndSprints = schedule?.[1]?.[0];
  const ref = useRef(null);
  const [{ isDragging }, drag] = useDrag({
    item: { id },

    type: "card",
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const opacity = isDragging ? 0 : 1;
  phaseAndSprints?.update === 1 && drag(ref);

  return (
    <div ref={ref} style={{ opacity }}>
      {children}
    </div>
  );
};

export default KanbanItem;
