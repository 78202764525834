import React from "react";
import { Select, Input, Divider } from "antd";
import SearchOutlined from "@ant-design/icons/SearchOutlined";

const CustomSelectWithSearch = ({
  options,
  value,
  recentOptions,
}: {
  options: any;
  value: string;
  recentOptions?: [{ id?: any; key?: any; value?: any; render?: any }];
}) => {
  return (
    <div
      className={`
          rounded-xl  mt-2 bg-white border h-11 border-gray-300`}
    >
      <Select
        //id={id}
        //name={name}
        //showSearch={showSearch}
        className={`w-full h-11 flex items-center`}
        bordered={false}
        getPopupContainer={(trigger) => trigger.parentElement}
        //   defaultValue={

        //   }
        //open={true}
        value={value}
        //onChange={handleChange}
        //placeholder={mandatory ? placeholder + '*' : placeholder}
        //disabled={disabled}
        suffixIcon={
          <img src="/images/icons/chevron-down-gray.svg" alt="down-arrow" />
        }
        //open={true}
        dropdownClassName={`truncate max-h-[320px] border border-gray-100 bg-white rounded-lg`}
        dropdownRender={(menu) => (
          <>
            <div className="  hidden-scrollbar ">
              <div className="sticky top-0 z-10  bg-gray-50">
                <Input
                  className="w-full rounded-lg bg-gray-50 focus:outline-none mon-500-14  h-10 dropdown-searchable-input"
                  prefix={<img src="/images/icons/search.svg" />}
                  bordered={false}
                  //onChange={handleSearch}
                  placeholder="Search Country"
                />
              </div>
              <div className=" hidden-scrollbar">{menu}</div>
            </div>
          </>
        )}
      >
        {recentOptions && recentOptions.length > 0 && (
          <Select.OptGroup
            label={
              <div className="mon-500-14 text-gray-900 pt-2 pb-1.5">
                Recent searches
              </div>
            }
          >
            {recentOptions?.map((option, index) => (
              <Select.Option
                //className={}
                key={option.id}
                value={option.value}
                label={option.value}
                className="py-2.5"
                //   key={
                //     searchableSelect
                //       ? option?.TransferId || option?.Id
                //       : option?.Id
                //   }
                //   value={
                //     searchableSelect
                //       ? option?.TransferId || option?.Value
                //       : option?.Value
                //   }
              >
                {option.render(value)}
              </Select.Option>
            ))}
          </Select.OptGroup>
        )}
        <Select.OptGroup
          label={
            <div className="mon-500-14 text-gray-900 pt-2 pb-1.5">A-Z</div>
          }
        >
          {options?.map((option, index) => (
            <Select.Option
              //className={}
              key={option.id}
              value={option.value}
              className="py-2.5"
              //   key={
              //     searchableSelect
              //       ? option?.TransferId || option?.Id
              //       : option?.Id
              //   }
              //   value={
              //     searchableSelect
              //       ? option?.TransferId || option?.Value
              //       : option?.Value
              //   }
            >
              {option.render(value)}
            </Select.Option>
          ))}
        </Select.OptGroup>
      </Select>
    </div>
  );
};

export default CustomSelectWithSearch;

export const CustomSelectWithInlineSearch = ({
  options,
  value,
  width,
  containerStyle,
  placeholder,
  onChange,
  showSearch = false,
  group = true,
  mode = "",
  onDropdownVisibleChange,
  margin = "mt-2",
  disabled = false,
}: {
  options?: any;
  value?: any;
  width?: string;
  containerStyle?: React.CSSProperties;
  placeholder?: string;
  onChange?: (e) => void;
  showSearch?: boolean;
  group?: boolean;
  mode?: string;
  onDropdownVisibleChange?: any;
  margin?: string;
  disabled?: boolean;
}) => {
  return (
    <div
      className={`
          rounded-xl ${margin} bg-white  border h-11 border-gray-300 ${width}`}
      style={containerStyle}
    >
      <Select
        //id={id}
        //name={name}
        //showSearch={showSearch}
        mode={mode as any}
        className={`w-full h-11 flex items-center`}
        bordered={false}
        getPopupContainer={(trigger) => trigger.parentElement}
        //   defaultValue={

        //   }
        // open={true}
        tagRender={() => <></>}
        value={value}
        //onChange={handleChange}
        placeholder={placeholder}
        //placeholder={mandatory ? placeholder + '*' : placeholder}
        disabled={disabled}
        suffixIcon={
          <img src="/images/icons/chevron-down-gray.svg" alt="down-arrow" />
        }
        onDropdownVisibleChange={(open) =>
          open
            ? " "
            : typeof onDropdownVisibleChange === "function" &&
              onDropdownVisibleChange()
        }
        onChange={onChange}
        //open={true}
        popupClassName={`truncate max-h-[320px] border border-gray-100 bg-white rounded-lg`}
        dropdownRender={(menu) => (
          <>
            <div className="  hidden-scrollbar ">{menu}</div>
          </>
        )}
        showSearch={showSearch}
        showArrow
        filterOption={(input, option) => {
          const optionLabel = `${option.label || option.value}`.toLowerCase();
          return optionLabel.includes(input?.toLowerCase());
        }}

        // (optionA!.children as unknown as string)
        //   .toLowerCase()
        //   .localeCompare(
        //     (optionB!.children as unknown as string).toLowerCase()
        //   )
      >
        {group ? (
          <>
            {options?.map((group) => {
              return (
                <Select.OptGroup
                  label={
                    <div className="mon-500-14 text-gray-900 pt-2 pb-1.5">
                      {group.title}
                    </div>
                  }
                  key={group.id}
                >
                  {group.options?.map((option, index) => {
                    return (
                      <Select.Option
                        //className={}
                        key={option.id}
                        value={option.value}
                        label={option.label || option.value}
                        className="py-2.5"
                        //   key={
                        //     searchableSelect
                        //       ? option?.TransferId || option?.Id
                        //       : option?.Id
                        //   }
                        //   value={
                        //     searchableSelect
                        //       ? option?.TransferId || option?.Value
                        //       : option?.Value
                        //   }
                      >
                        {option.render(value)}
                      </Select.Option>
                    );
                  })}
                </Select.OptGroup>
              );
            })}
          </>
        ) : (
          <>
            {options?.map((option, index) => {
              return (
                <Select.Option
                  //className={}
                  key={option.id}
                  value={option.value}
                  label={option.label || option.value}
                  className="py-2.5"
                  //   key={
                  //     searchableSelect
                  //       ? option?.TransferId || option?.Id
                  //       : option?.Id
                  //   }
                  //   value={
                  //     searchableSelect
                  //       ? option?.TransferId || option?.Value
                  //       : option?.Value
                  //   }
                >
                  {option.render(value)}
                </Select.Option>
              );
            })}
          </>
        )}
      </Select>
    </div>
  );
};
