import React, { useCallback, useEffect, useRef, useState } from "react";
import { DndProvider, useDrag, useDrop } from "react-dnd";
//import { HTML5Backend } from "react-dnd-html5-backend";
import KanbanColumn from "./KanbanColumn";
import update from "immutability-helper";
import KanbanItem from "./KanbanItem";
import TaskCard from "../TaskCard";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import SprintCard from "../SprintCard";
import { Col, Row, Tooltip } from "antd";
import CustomInput from "../../../Core/Common/CustomInput";
import KanbanColumnHeader from "./KanbanColumnHeader";

import AddNewStatusModal from "../AddNewStatusModal";
import CustomDragLayer from "./CustomDragLayer";
import { useAppDispatch, useAppSelector } from "../../../Core/redux/hooks";
import { updateSchedule } from "../../../Core/redux/slices/schedule.slice";
import {
  createSwimlane,
  getGoalsBySprint,
  getSwimlanes,
  updateGoal,
  updateProgress,
  updateSprint,
} from "../../../Core/redux/api/scheduleAPI";
import { setError } from "../../../Core/redux/slices/global.slice";
import { client } from "../../../Core/utils/axiosClient";
import { getPhaseName } from "../../../Core/utils/getPhaseName";
import { manager } from "../../../Core/utils/dnd-utils";

const classes = {
  board: {
    display: "flex",
    margin: "0 auto",
    width: "90vw",
  },
  //   column: {
  //     height: "80vh",
  //     margin: "0 auto",
  //   },

  item: {
    padding: 10,
    margin: 10,
    fontSize: "0.8em",
    cursor: "pointer",
    backgroundColor: "white",
  },
};

const tasksList = [
  { _id: 1, title: "First Task", status: 1 },
  { _id: 2, title: "Second Task", status: 1 },
  { _id: 3, title: "Third Task", status: 1 },
  { _id: 4, title: "Fourth Task", status: 2 },
  { _id: 5, title: "Fifth Task", status: 2 },
  { _id: 6, title: "Sixth Task", status: 3 },
  { _id: 7, title: "Seventh Task", status: 2 },
  { _id: 8, title: "Eighth Task", status: 2 },
  { _id: 9, title: "Ninth Task", status: 2 },
  { _id: 10, title: "Tenth Task", status: 2 },
];

const sprintChannels = [
  {
    id: 1,

    statusId: 1,
    title: "To Do",
  },
  {
    id: 2,

    statusId: 2,
    title: "In Progress",
  },

  {
    id: 3,

    statusId: 3,
    title: "Done",
  },
];

const KanbanBoard = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [tasks, setTaskStatus] = useState(tasksList);
  const [addNewStatus, setAddNewStatus] = useState(false);
  const { projectId } = useParams();

  const { swimlanes, filterGoalParameter, filters, currentSelectedPhase } =
    useAppSelector((state) => state.schedule);

  const board = useRef(null);
  const sectionRef = useRef(null);

  const dispatch = useAppDispatch();
  const channels = searchParams.has("selectedSprint")
    ? swimlanes
    : sprintChannels;

  console.log(channels, "channels check");
  const [statusArray, setStatusArray] = useState(channels);

  React.useEffect(() => {
    setStatusArray(channels);
  }, [searchParams, swimlanes]);

  const { sprints, goals } = useAppSelector((state) => state.schedule);
  const [maxHeight, setMaxHeight] = useState("0px");

  // console.log(sprints, "sprints v2");

  useEffect(() => {
    const updateHeight = () => {
      if (board && board.current) {
        const viewportOffset =
          board.current.getBoundingClientRect()?.top || 100;
        const height = `${window.innerHeight - viewportOffset}px`;
        //  board.current.style.height = height;
        board.current.style.maxHeight = height;

        console.log(
          board.current.scrollHeight,
          viewportOffset,
          window.innerHeight,
          "board.current.getBoundingClientRect().height"
        );
        setMaxHeight(board.current.getBoundingClientRect().height);
      }
    };
    updateHeight();
    window.addEventListener("resize", updateHeight);
    return () => window.removeEventListener("resize", updateHeight);
  }, [board]);

  // useEffect(() => {
  //   setMaxHeight(sectionRef.current.scrollHeight);
  // }, [sectionRef]);

  // useEffect(() => {
  //   const handleScroll = () => {
  //     const currentHeight = sectionRef.current.scrollHeight;

  //     if (currentHeight > maxHeight) {
  //       setMaxHeight(currentHeight);
  //     }

  //     console.log(maxHeight, "board.current.getBoundingClientRect().height2");
  //   };
  //   sectionRef.current.addEventListener("scroll", handleScroll);
  //   return () => {
  //     sectionRef.current.removeEventListener("scroll", handleScroll);
  //   };
  // }, [maxHeight]);

  // useEffect(() => {
  //   const currentHeight = sectionRef.current.scrollHeight;
  //   if (currentHeight > maxHeight) {
  //     setMaxHeight(currentHeight);
  //   }
  //   console.log(maxHeight, "board.current.getBoundingClientRect().height");
  // }, [sectionRef.current]);

  const changeTaskStatus = useCallback(
    async (item, status) => {
      if (searchParams.has("selectedSprint")) {
        const currentSprint = sprints.find(
          (s) => s.id === +searchParams.get("selectedSprint")
        );
        if (currentSprint.status == 3) {
          dispatch(
            setError({
              message: `Sprint already ended!`,
            })
          );

          return "";
        }
        if (currentSprint && currentSprint.status < 2) {
          // const swimlane = swimlanes.find((s)=>s.id == status)
          // if(s)
          dispatch(
            setError({
              message: `Please Start the Sprint first!`,
            })
          );

          return "";
        }

        // console.log(item);

        const statusId = swimlanes.find((s) => s.id == status).statusId || 1;

        let task = currentSprint.goals.find((task) => task.id === item.id);
        // console.log("sdcsdcsdcsdc");
        // console.log(
        //   task,

        //   "sdcsdcsdcsdc"
        // );
        // if (
        //   task &&
        //   task.subTasks &&
        //   task.subTasks.length > 0 &&
        //   statusId == 3
        // ) {
        //   const notDoneSubTask = task.subTasks.find((f) => f.statusId < 3);
        //   if (notDoneSubTask) {
        //     dispatch(
        //       setError({
        //         message: `There are some subtask that are not completed!`,
        //       })
        //     );

        //     return "";
        //   }
        // }
        const taskIndex = currentSprint.goals.indexOf(task);
        task = { ...task, swimlaneId: status };

        const newTasks = update(currentSprint.goals, {
          [taskIndex]: { $set: task },
        });

        const newSprint = { ...currentSprint, goals: newTasks };
        // currentSprint.goals = newTasks;
        const newSprints = sprints.map((s) => {
          if (s.id === currentSprint.id) {
            return newSprint;
          } else {
            return s;
          }
        });
        // console.log(newSprints, "newTasks");
        dispatch(updateSchedule({ key: "sprints", value: newSprints }));
        dispatch(updateSchedule({ key: "goals", value: newTasks }));
        //TODO: Update Swimlane id in db

        await dispatch(
          updateGoal({ goalId: item.id, swimlaneId: status, statusId })
        );
        dispatch(
          getGoalsBySprint({
            projectId,
            sprintId: searchParams.get("selectedSprint"),
          })
        );
        dispatch(
          updateProgress({
            phaseId: currentSelectedPhase.id,
            projectId,
          })
        );
      } else {
        let sprint = sprints.find((s) => s.id === item.id);
        if (!currentSelectedPhase.isOngoing) {
          dispatch(
            setError({
              message: `Please Start the Phase first!`,
            })
          );

          return;
        }
        dispatch(
          updateSchedule({
            key: "selectedSprint",
            value: sprint,
          })
        );
        const sprintIndex = sprints.indexOf(sprint);
        sprint = { ...sprint, status };
        console.log(item, "itemincallback");
        const newTasks = update(sprints, {
          [sprintIndex]: { $set: sprint },
        });
        if (status !== 1) {
          let activeSprint = sprints.find((sprint) => sprint.status == 2);
          const reduxObj =
            status == 2
              ? { key: "startSprintModal", value: true }
              : {
                  key: "completeSprintModal",
                  value: {
                    visible: true,
                    sprintId: sprint.id,
                  },
                };
          dispatch(updateSchedule(reduxObj));
          const getActiveSprint = async () => {
            const { data } = await client.get("/schedule/activeSprint", {
              params: { projectId },
            });
            if (data) {
              activeSprint = data?.activeSprint;
            }
          };
          await getActiveSprint();
          console.log("activeSprint:", activeSprint);
          if (activeSprint && status == 2) {
            const phase = getPhaseName(+activeSprint.phaseId);
            dispatch(
              setError({
                message: `There is already an active sprint in ${phase}!`,
              })
            );

            return;
          }
        } else {
          dispatch(updateSchedule({ key: "sprints", value: newTasks }));
          dispatch(
            updateSprint({
              sprintId: item.id,
              status,
              isCompleted: +(status === 3),
            })
          );
        }
      }
    },
    [tasks, sprints]
  );

  const handelAddNewStatus = async (name, status) => {
    setAddNewStatus(false);
    setStatusArray((old) => [
      ...old,
      {
        id: old.length + 1,
        statusId: status,
        title: name,
      },
    ]);

    await dispatch(
      createSwimlane({
        projectId,
        statusId: status,
        title: name,
      })
    );
    await dispatch(getSwimlanes({ projectId }));
  };

  const currentSprintGoals = sprints.find(
    (s) => s.id === +searchParams.get("selectedSprint")
  );

  const count = (channel) => {
    let items;
    if (searchParams.has("selectedSprint")) {
      items = filterGoals(goals, channel);
    } else {
      items = filterSprints(sprints, channel);
    }

    const isMatch = (item) => {
      if (searchParams.has("selectedSprint")) {
        return (
          (item.swimlaneId === 0 && item.swimlaneId + 1 === channel.id) ||
          item.swimlaneId === channel.id
        );
      } else {
        return item.status == channel.id;
      }
    };

    return items.filter(isMatch).length;
  };

  const handleFilterForSubtask = (goals, channel) => {
    let typeFilteredGoals = goals;
    if (filterGoalParameter.typeDropdown.length > 0) {
      typeFilteredGoals = goals.filter((g1) => {
        return filterGoalParameter?.typeDropdown?.includes(+g1.goalType);
      });
    }

    if (filterGoalParameter.assignee?.length > 0) {
      typeFilteredGoals = typeFilteredGoals.filter((goal) =>
        filterGoalParameter.assignee.includes(goal.assigneeId)
      );
    }
    // console.log(typeFilteredGoals, "type filter check");

    const searchValue = filterGoalParameter?.searchValue.toLowerCase();

    typeFilteredGoals = typeFilteredGoals.filter(
      (g2) =>
        g2.name.toLowerCase().includes(searchValue) || g2.id == searchValue
    );

    return typeFilteredGoals;
  };

  const handleFilter = (goals) => {
    let filteredGoals = [...goals];
    const filterParams = filterGoalParameter;
    // filter by goal type
    if (filterParams?.typeDropdown.length > 0) {
      filteredGoals = filteredGoals.filter((goal) => {
        const type = +goal.goalType === 4 ? 1 : +goal.goalType;
        return filterParams.typeDropdown.includes(type);
      });
    }

    // filter by assignee
    if (filterParams?.assignee.length > 0) {
      filteredGoals = filteredGoals.filter((goal) =>
        filterParams.assignee.includes(goal.assigneeId)
      );
    }

    // filter by search value
    const searchValue = filterParams?.searchValue.toLowerCase();
    filteredGoals = filteredGoals.filter(
      (goal) =>
        goal.name.toLowerCase().includes(searchValue) || goal.id == searchValue
    );

    return filteredGoals;
  };

  const filterGoals = (goals, channel) => {
    let filteredGoals = [...goals];
    const searchValue = filterGoalParameter?.searchValue.toLowerCase();
    filteredGoals = filteredGoals.map((goal) => {
      if (goal.subTasks && goal.subTasks.length > 0) {
        const filteredSubtask = handleFilter(goal.subTasks);

        if (filteredSubtask.length > 0) {
          return { ...goal, subTasks: filteredSubtask, shouldIgnore: true };
        } else {
          return { ...goal, shouldIgnore: false };
        }
      } else {
        return { ...goal, shouldIgnore: false };
      }
    });

    const ignoredGoals = filteredGoals.filter((goal) => goal.shouldIgnore);
    const parentGoalsTobeFiltered = filteredGoals.filter(
      (goal) => !goal.shouldIgnore
    );

    filteredGoals = handleFilter(parentGoalsTobeFiltered);
    return [...filteredGoals, ...ignoredGoals].filter((item) => {
      return +item.swimlaneId === +channel.id;
    });
  };

  const filterSprints = (sprints, channel) => {
    const searchValue = filterGoalParameter?.searchValue.toLowerCase();

    return sprints
      .filter((s) => s.status === channel.id)
      .filter((sp) => sp.name.toLowerCase().includes(searchValue));
  };

  const filteredGoals = console.log(
    filterGoalParameter.typeDropdown,
    "type selection check"
  );

  return (
    <div className="bg-main-background flex  px-6  relative ">
      <div className="flex-1">
        <div
          className={`overflow-y-scroll ${
            searchParams.has("selectedSprint")
              ? "max-w-[calc(100vw-300px)]"
              : " max-w-[calc(100vw-250px)]"
          }`}
        >
          <DndProvider manager={manager}>
            <div className={`grid-container `}>
              {statusArray.map((channel) => (
                <KanbanColumnHeader
                  title={channel.title}
                  key={channel.id}
                  count={count(channel)}
                  channel={channel}
                  isTask={searchParams.has("selectedSprint")}
                  addSwimalne={() => setAddNewStatus(true)}
                />
              ))}
            </div>
            <div
              className="grid-container overflow-y-scroll overflow-x-hidden max-h-[100vh-200px] "
              ref={board}
            >
              {statusArray.map((channel) => (
                <KanbanColumn
                  key={channel.id}
                  status={channel.id}
                  changeTaskStatus={changeTaskStatus}
                  className="overflow-x-hidden kanban-col"
                >
                  {searchParams.has("selectedSprint")
                    ? filterGoals(goals, channel).map((item) => (
                        <KanbanItem key={item.id} id={item.id}>
                          <TaskCard task={item} />
                        </KanbanItem>
                      ))
                    : filterSprints(sprints, channel).map((sprint) => (
                        <KanbanItem key={sprint.id} id={sprint.id}>
                          {" "}
                          <SprintCard key={sprint.id} sprint={sprint} />{" "}
                        </KanbanItem>
                      ))}
                </KanbanColumn>
              ))}
            </div>
          </DndProvider>
        </div>
      </div>
      <div>
        {searchParams.has("selectedSprint") && (
          <div className="bg-white max-h-[44px] min-h-[44px] w-[44px] min-w-[44px]  rounded-xl flex items-center justify-center ml-2">
            <Tooltip title="Add new status" placement="left">
              <img
                className="h-[28px] w-[28px] cursor-pointer"
                src="/images/icons/plus-gray.svg"
                onClick={() => setAddNewStatus(true)}
              />
            </Tooltip>
          </div>
        )}
        <AddNewStatusModal
          visible={addNewStatus}
          onOk={handelAddNewStatus}
          onCancel={() => setAddNewStatus(false)}
        />
      </div>
    </div>
  );
};

export default KanbanBoard;
