import React, { useState } from "react";
import CustomModal from "../../Core/Common/CustomModal";
import CustomButton from "../../Core/Common/CustomButton";
import { Input, DatePicker } from "antd";
import { CustomSelectWithInlineSearch } from "../../Core/Common/CustomSelectWithSearch";
import CustomAvatar from "../../Core/Common/CustomAvatar";
import CustomSelect from "../../Core/Common/CustomSelect";
import { createRequest } from "../../Core/redux/api/vaultAPI";
import { useAppDispatch, useAppSelector } from "../../Core/redux/hooks";
import { useParams } from "react-router-dom";
import PhaseIcon from "../../Core/svg/PhaseIcon";
import dayjs from "dayjs";

const RequestAccessModal = ({ visible, onCancel }) => {
  const getPriorityIcon = (val: number) => {
    switch (val) {
      case 1:
        return (
          <div className="flex items-center ">
            <img src="/images/icons/flag-gray.svg" />
            <p className="mon-400-12 text-gray-400 ml-1.5">Low</p>
          </div>
        );
      case 2:
        return (
          <div className="flex items-center">
            <img src="/images/icons/flag-blue.svg" />
            <p className="mon-400-12 text-primary-600 ml-1.5">Medium</p>
          </div>
        );
      case 3:
        return (
          <div className="flex items-center">
            <img src="/images/icons/flag-red.svg" />
            <p className="mon-400-12 text-error-600 ml-1.5">High</p>
          </div>
        );
      default:
        return (
          <div className="flex items-center">
            <img src="/images/icons/flag-gray.svg" />
            <p className="mon-400-12 text-gray-400 ml-1.5">Low</p>
          </div>
        );
    }
  };
  const fileDropdown = (val: number) => {
    switch (val) {
      case 1:
        return <p className="mon-400-12 text-gray-700 ml-1.5">File</p>;
      case 2:
        return <p className="mon-400-12 text-gray-700 ml-1.5">Link</p>;
      default:
        return <p className="mon-400-12 text-gray-700 ml-1.5">File</p>;
    }
  };
  const teamMembers = (val: number) => {
    switch (val) {
      case 1:
        return (
          <div className="flex items-center">
            <CustomAvatar title="Kevin Shah" size={24} />
            <p className="ml-2 mon-400-12 text-gray-700">Kevin Shah</p>
          </div>
        );
      case 2:
        return (
          <div className="flex items-center">
            <CustomAvatar title="Ganesh Mohanty" size={24} />
            <p className="ml-2 mon-400-12 text-gray-700">Ganesh Mohanty</p>
          </div>
        );
      default:
        return (
          <div className="flex items-center">
            <CustomAvatar title="Olivia Rhye (Creator)" size={24} />
            <p className="ml-2 mon-400-12 text-gray-700">Kevin Shah</p>
          </div>
        );
    }
  };

  const [description, setDescription] = useState("");
  const [phase, setPhase] = useState({ phaseName: "", phaseId: "" });
  const [deadline, setDeadline] = useState(null);

  const { projectId } = useParams();
  const { user } = useAppSelector((state) => state.userDetails);
  const { currentPhaseGoals, phases } = useAppSelector(
    (state) => state.schedule
  );
  //filtering current user from the list
  let { userList } = useAppSelector((state) => state.overview);
  userList = userList.filter((userList) => userList.userId !== user.id);

  const [priority, setPriority] = useState(1);
  const [docType, setDocType] = useState("File");
  const [selectedUser, setSelectedUser] = useState(null);

  const dispatch = useAppDispatch();
  const requestAccess = async () => {
    const req = {
      projectId: +projectId,
      senderId: +user.id,
      receiverId: +selectedUser,
      description,
      deadline,
      priority,
      docType,
      requestType: "Access",
      phaseId: +phase.phaseId,
    };
    await dispatch(createRequest(req)).then(() => {
      onCancel();
    });
  };
  return (
    <CustomModal
      title={
        <div className="flex gap-x-1">
          <span className="mon-400-12 text-gray-500">Vault</span>
          <img className="w-4 h-4" src="/images/icons/chevron-right.svg" />
          <span className="mon-500-12 text-gray-700">Request access</span>
        </div>
      }
      width={"704px"}
      visible={visible}
      body={
        <div className="max-h-[48.189386056191466vh] overflow-y-scroll">
          <div className="p-4">
            <div className="flex items-center">
              <img src="/images/icons/users-03.svg" />
              <p className="mon-500-14 text-gray-700 ml-2">
                Ask for access from your team members to a file / link
              </p>
            </div>
            <div className="flex items-center mt-9">
              <p className="mon-400-12 text-gray-500">I need access to </p>
              <div className="ml-4 top-0 w-[100px]">
                <CustomSelect
                  options={[
                    {
                      id: "1",
                      value: "File",
                      render: (value) => <p>{fileDropdown(1)}</p>,
                    },

                    {
                      id: "2",
                      value: "Link",
                      render: (value) => <p>{fileDropdown(2)}</p>,
                    },
                  ]}
                  onChange={(e) => setDocType(e)}
                  value={docType}
                />
              </div>
              <p className="ml-4 mon-400-12 text-gray-500">From</p>
              <div className="ml-4 w-[380px]">
                <CustomSelect
                  options={userList.map((u) => {
                    return {
                      id: u.userId,
                      value: u.userId,
                      render: (value) => (
                        <div className="flex items-center">
                          <CustomAvatar
                            title={u.firstname}
                            size={24}
                            src={u.profilePicture}
                          />
                          <p className="ml-2 mon-400-12 text-gray-700">
                            {u.firstname} {u.lastname}
                          </p>
                        </div>
                      ),
                    };
                  })}
                  placeholder="Select team members"
                  value={selectedUser}
                  onChange={(e) => setSelectedUser(e)}
                />
              </div>
            </div>
            <div className="mon-500-14 text-gray-700 mt-9">
              Description
              <div className="mt-1.5">
                <Input.TextArea
                  rows={5}
                  placeholder="Give a brief description about the file or link you need..."
                  required={true}
                  className="w-full h-full bg-white rounded-xl border border-gray-300 text-gray-500 pl-4 pt-2  active:outline-none"
                  onChange={(e) => setDescription(e.target.value)}
                ></Input.TextArea>
              </div>
            </div>
            <div className="mon-500-14 text-gray-700 mt-9">
              Select phase
              <div className="mt-1.5">
                <CustomSelectWithInlineSearch
                  containerStyle={{
                    width: "100%",
                  }}
                  options={[
                    {
                      id: "1",
                      title: "Ongoing",
                      options: phases
                        .filter((phase) => phase.isOngoing || phase.isCompleted)
                        .map((p) => {
                          return {
                            id: p.id,
                            value: p.id,
                            render: (value) => (
                              <div
                                className="flex items-center -mt-0.5"
                                key={p.id + "phases"}
                                onClick={() =>
                                  setPhase({
                                    phaseId: p.id.toString(),
                                    phaseName: p.phases,
                                  })
                                }
                              >
                                <PhaseIcon
                                  width={"16"}
                                  height={"16"}
                                  phaseId={p.id}
                                  icon
                                />

                                <p className="mon-400-14 pl-2 text-gray-900 capitalize ">
                                  {p.phases}
                                </p>
                              </div>
                            ),
                          };
                        }),
                    },
                    {
                      id: "2",
                      title: "Yet to begin",
                      options: phases
                        .filter(
                          (phase) => !phase.isOngoing && !phase.isCompleted
                        )
                        .map((p) => {
                          return {
                            id: p.id,
                            value: p.id,
                            render: (value) => (
                              <div
                                className="flex items-center -mt-0.5"
                                key={p.id + "phases"}
                                onClick={() =>
                                  setPhase({
                                    phaseId: p.id.toString(),
                                    phaseName: p.phases,
                                  })
                                }
                              >
                                <PhaseIcon
                                  width={"16"}
                                  height={"16"}
                                  phaseId={p.id}
                                  icon
                                />

                                <p className="mon-400-14 pl-2 text-gray-900 capitalize ">
                                  {p.phases}
                                </p>
                              </div>
                            ),
                          };
                        }),
                    },
                  ]}
                  placeholder="Select phase"
                  value={phase.phaseId}
                />
              </div>
            </div>
            <div className=" mt-9">
              <p className="mon-500-14 text-gray-700">Deadline</p>
              <div className="pt-2">
                <DatePicker
                  className="w-[336px] h-10 bg-transparent rounded-xl border-2"
                  suffixIcon={<></>}
                  format="DD MMM YY"
                  allowClear={true}
                  placeholder="dd/mm/yyyy"
                  //value={deadline}
                  onChange={(e) => {
                    setDeadline(dayjs(e as any).format("YYYY-MM-DD"));
                  }}
                  // value={goal.dueDate !== "" && (dayjs(goal.dueDate) as any)}
                  // onChange={(e) =>
                  //   setGoal({
                  //     ...goal,
                  //     dueDate: dayjs(e as any).format("YYYY-MM-DD"),
                  //   })
                  // }
                />
              </div>
            </div>
            <div className=" mt-9">
              <p className="mon-500-14 text-gray-700">Priority</p>
              <div className="pt-2 w-[336px]">
                <CustomSelect
                  options={[
                    {
                      id: "1",
                      value: 1,
                      render: (value) => <p>{getPriorityIcon(1)}</p>,
                    },

                    {
                      id: "2",
                      value: 2,
                      render: (value) => <p>{getPriorityIcon(2)}</p>,
                    },
                    {
                      id: "3",
                      value: 3,
                      render: (value) => <p>{getPriorityIcon(3)}</p>,
                    },
                  ]}
                  placeholder="Select priority"
                  value={priority}
                  onChange={(e) => setPriority(e)}
                />
              </div>
            </div>
          </div>
        </div>
      }
      footer={
        <div className="flex justify-end py-4 gap-x-3 px-3 shadow-upward">
          <CustomButton
            text="Cancel"
            onClick={onCancel}
            className="border border-gray-200 text-gray-700 bg-white mon-500-14"
            height="40px"
            width="81px"
          />
          <CustomButton
            text="Send Request"
            disabled={
              selectedUser === null ||
              phase.phaseId === null ||
              deadline === null
            }
            onClick={requestAccess}
            className="mon-500-14 text-white bg-primary-600 "
            height="40px"
            width="132px"
          />
        </div>
      }
      onCancel={onCancel}
    />
  );
};

export default RequestAccessModal;
