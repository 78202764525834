import { Tooltip } from "antd";
import dayjs from "dayjs";
import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";
import CustomBadge from "../../Core/Common/CustomBadge";
import { useAppDispatch, useAppSelector } from "../../Core/redux/hooks";
import {
  setSelectedSprint,
  updateSchedule,
} from "../../Core/redux/slices/schedule.slice";
import PhaseIcon from "../../Core/svg/PhaseIcon";
import MenuDropdown from "../Conversations/Components/MenuDropdown";
import SprintDetailsModal from "./Components/SprintDetailsModal";
import TaskTypeIcon from "../../Core/svg/TaskTypeIcon";

const SprintCard = ({ sprint }: { sprint?: any }) => {
  const dispatch = useAppDispatch();
  const [showSubTask, setShowSubTask] = useState(false);
  const [sprintDetailsModal, setSpintDetailsModal] = useState(false);
  const { schedule } = useAppSelector((state) => state.projects);
  const { phases } = useAppSelector((state) => state.schedule);
  const phaseAndSprints = schedule?.[1]?.[0];
  const [searchParams, setSearchParams] = useSearchParams();

  const getPhaseNameById = (id) => {
    switch (id) {
      case "1":
        return "Ideation phase";
      case "2":
        return "Design phase";
      case "3":
        return "Development phase";
      case "4":
        return "Deployment phase";
      case "5":
        return "Maintenance phase";
      default:
        return "N/A";
    }
  };

  const totalTasks = sprint?.goals?.length;
  const totalEvents = sprint.goals?.filter(
    ({ goalType }) => goalType === "2"
  ).length;
  const totalBugs = sprint.goals?.filter(
    ({ goalType }) => goalType === "3"
  ).length;

  return (
    <>
      <div
        className={`bg-white   cursor-pointer  px-4 py-[18px] rounded-xl mt-4 max-h-max  hover:shadow-lg`} // removed cursor-not-allowed -> (phaseAndSprints?.update === 1 ? "" : "cursor-not-allowed")
        style={{ maxHeight: showSubTask ? "500px" : "136px" }}
        onDoubleClick={() => {
          const updatedSearchParams = new URLSearchParams(
            searchParams.toString()
          );
          updatedSearchParams.set("selectedSprint", sprint.id);
          setSearchParams(updatedSearchParams.toString());
          dispatch(setSelectedSprint(sprint));
        }}
      >
        <div className="flex justify-between items-center">
          <div className="flex items-center justify-center gap-x-2">
            {sprint.isNewSprint == 1 && (
              <TaskTypeIcon type={"newscope"} width="16" height="16" />
            )}
            <p className="text-[10px] font-semibold text-primary-600">{`SP-${sprint.id}`}</p>
            <h6 className="mon-500-12 text-gray-900 capitalize">
              {sprint.name || "N/A"}
            </h6>
          </div>
          <div className="flex items-center gap-x-2">
            {sprint.status === 2 &&
              phases
                ?.filter(
                  ({ isOngoing, isCompleted }) =>
                    isCompleted !== 1 && isOngoing === 1
                )
                .map((s) => (
                  <Tooltip
                    title={getPhaseNameById(s.phaseId)}
                    placement="bottom"
                    key={s.id}
                  >
                    <div>
                      <PhaseIcon
                        phaseId={+s.phaseId}
                        height="10px"
                        width="10px"
                      />
                    </div>
                  </Tooltip>
                ))}
            <MenuDropdown
              elements={[
                {
                  id: 1,
                  image: "/images/icons/info-circle.svg",
                  text: "View details",
                  textColor: "text-gray-700",
                  onclick: () => setSpintDetailsModal(true),
                },
                {
                  id: 2,
                  image: "/images/icons/trash.svg",
                  text: "Delete",
                  textColor: "text-error-600",
                  onclick: () => {
                    dispatch(
                      updateSchedule({
                        key: "deleteSprintModal",
                        value: {
                          visible: true,
                          sprintId: +sprint.id,
                        },
                      })
                    );
                  },
                },
              ]}
              customFunc={undefined}
            />
          </div>
        </div>
        <div className="flex justify-between items-center mt-3">
          <div className="flex items-center gap-x-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="12"
              fill="none"
              viewBox="0 0 12 12"
            >
              <path
                stroke="#344054"
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 3v3l2 1m3-1A5 5 0 111 6a5 5 0 0110 0z"
              ></path>
            </svg>
            <span className="mon-400-12 text-gray-700">
              {sprint.startDate
                ? dayjs(sprint.startDate).format("DD MMM")
                : "N/A"}{" "}
              -{" "}
              {sprint.endDate ? dayjs(sprint.endDate).format("DD MMM") : "N/A"}
            </span>
          </div>
          {/* <PriorityIcon priority={1} size={"16px"} /> */}
        </div>
        <div className="flex justify-between items-center mt-3">
          <span className="mon-400-12 text-gray-700">
            {totalTasks > 0 ? `${totalTasks} Tasks` : "0 Task"}
            {sprint.status === 2
              ? `, ${totalEvents} Events, ${totalBugs} Bugs`
              : ""}
          </span>
          {sprint.status === 2 && (
            <CustomBadge text="Active" bgColor="#D1FADF" color="#027A48" />
          )}
        </div>
      </div>
      {sprintDetailsModal && (
        <SprintDetailsModal
          visible={sprintDetailsModal}
          //onCancel={() => dispatch(resetSchedule(["sprintDetailsModal"]))}
          onCancel={() => setSpintDetailsModal(false)}
          sprint={sprint}
          // sprint={{
          //   id: "1256",
          //   name: "test",
          //   endDate: "2022-12-12",
          //   startDate: "2022-10-12",
          //   goals: [],
          // }}
        />
      )}
    </>
  );
};

export default SprintCard;
