import React, { useEffect, useState } from "react";
import { ProductOnboardingLayout } from "../../../Core/Layout/OnboardingLayouts";
import CustomButton from "../../../Core/Common/CustomButton";
import { useAppDispatch, useAppSelector } from "../../../Core/redux/hooks";
import {
  changeStep,
  handleSdgSelect,
} from "../../../Core/redux/slices/onboarding.slice";
import CurrentStep from "../Project Onboarding/CurrentStep";
import { getSDGs } from "../../../Core/redux/api/onboardingAPI";
import CustomFrostyErrorMessage from "../../../Core/Common/CustomFrostyErrorMessage";
import { useFormik } from "formik";
import * as Yup from "yup";
import StepProgressBar from "./StepProgressBar";

const Content = () => {
  const dispatch = useAppDispatch();
  const { selectedSDGs, selectedSector, sdgList } = useAppSelector(
    (state) => state.onboarding
  );

  const [error, setError] = useState(false);
  // const sdglist = [
  //   {
  //     id: 1,
  //     value: "sdg-1",
  //   },
  //   {
  //     id: 2,
  //     value: "sdg-2",
  //   },
  //   {
  //     id: 3,
  //     value: "sdg-3",
  //   },
  //   {
  //     id: 4,
  //     value: "sdg-4",
  //   },
  //   {
  //     id: 5,
  //     value: "sdg-5",
  //   },
  //   {
  //     id: 6,
  //     value: "sdg-6",
  //   },
  //   {
  //     id: 7,
  //     value: "sdg-7",
  //   },
  //   {
  //     id: 8,
  //     value: "sdg-8",
  //   },
  //   {
  //     id: 9,
  //     value: "sdg-9",
  //   },
  //   {
  //     id: 10,
  //     value: "sdg-10",
  //   },
  //   {
  //     id: 11,
  //     value: "sdg-11",
  //   },
  //   {
  //     id: 12,
  //     value: "sdg-12",
  //   },
  //   {
  //     id: 13,
  //     value: "sdg-13",
  //   },
  //   {
  //     id: 14,
  //     value: "sdg-14",
  //   },
  //   {
  //     id: 15,
  //     value: "sdg-15",
  //   },
  //   {
  //     id: 16,
  //     value: "sdg-16",
  //   },
  //   {
  //     id: 17,
  //     value: "sdg-17",
  //   },
  // ];

  const arr = sdgList?.filter((item) => selectedSDGs?.includes(item?.id));

  const [hoverIndex, setHoverIndex] = React.useState(null);
  const [expandText, setExpandtext] = useState(false);

  React.useEffect(() => {
    formik.setFieldValue("selectedSDGs", selectedSDGs);
    if (selectedSDGs.length > 0) {
      setError(false);
    }
  }, [selectedSDGs]);

  const onHover = (id) => {
    setHoverIndex(id);
  };

  const formik = useFormik({
    initialValues: {
      selectedSDGs: [],
    },
    validationSchema: Yup.object({
      selectedSDGs: Yup.array()
        .min(1, "Please select at least one of the SDGs to continue")
        .required("Please select at least one of the SDGs to continue")
        .typeError("Please select at least one of the SDGs to continue"),
    }),
    onSubmit: (values) => {
      console.log(values, "values");
      if (selectedSDGs.length === 0) {
        formik.setFieldError(
          "selectedSDGs",
          "Please select at least one of the SDGs to continue"
        );
        console.log("fomik", error);
        setError(true);
      }
      dispatch(changeStep(4));
    },
  });

  return (
    <div className="p-6 w-full h-screen bg-white">
      <div className="pl-14 flex justify-start h-full items-center">
        <StepProgressBar
          currentStep={2}
          totalSteps={5}
          givenElementId="form2"
        />
        <div>
          <CurrentStep currentStep={2} totalStep={5} className="" id="form2" />
          <h1 className="mon-500-20 text-gray-900 mb-2 mt-6">
            Which SDGs best describe your company’s mission?
          </h1>
          {!error ? (
            <div>
              <div className="flex justify-start items-center text-show-animation gap-x-2 mon-400-16 text-gray-500">
                <img
                  src="/images/icons/frosty-icon.svg"
                  className="min-h-[26px] min-w-[26px]"
                  alt="frosty"
                />
                <span className="text-gray-900 mon-500-16">
                  The Sustainable Development Goals (SDGs),
                </span>{" "}
                also known as the Global Goals, were...
                <span
                  className="cursor-pointer"
                  onClick={() => setExpandtext(!expandText)}
                >
                  <img
                    src={
                      expandText
                        ? "/images/icons/chevron-up.svg"
                        : "/images/icons/chevron-right.svg"
                    }
                  />
                </span>
              </div>
              {expandText && (
                <div className="pl-9">
                  <div>
                    <p className="mon-400-16 text-gray-500">
                      adopted by the{" "}
                      <span className="text-gray-900 mon-500-16">
                        United Nations{" "}
                      </span>
                      in 2015 as a universal{" "}
                      <span className="text-gray-900 mon-500-16">
                        call to action{" "}
                      </span>
                      to end poverty, <br /> protect the planet, and ensure that
                      by <span className="text-gray-900 mon-500-16">2030 </span>
                      all people enjoy peace and prosperity.
                    </p>
                  </div>
                  <div className="flex items-center gap-x-2 cursor-pointer mt-2.5 mb-2">
                    <p className=" text-primary-700 mon-500-14 pt-0.5">
                      Know more about SDGs
                    </p>
                    <img
                      src="/images/icons/share-blue.svg"
                      className="w-5 h-5"
                    />
                  </div>
                </div>
              )}
            </div>
          ) : (
            <CustomFrostyErrorMessage text={formik.errors.selectedSDGs} />
          )}

          {/* <div className="flex justify-start items-center mb-6 gap-x-2"></div> */}
          {/* <div className="grid grid-cols-6 gap-x-0 w-[450px] h-[229px] mb-6"> */}
          <div className="flex flex-row flex-wrap gap-2.5 w-[550px] ">
            {sdgList.map((sdg, i) => (
              <div
                onMouseEnter={() => onHover(i)}
                onMouseLeave={() => onHover(null)}
                onClick={() => dispatch(handleSdgSelect(sdg.id))}
                className={`cursor-pointer  rounded-[5px] ${
                  hoverIndex === i ? "scale-[1.15]" : ""
                } ${
                  hoverIndex === i || selectedSDGs?.includes(sdg?.id)
                    ? "opacity-100 "
                    : "opacity-50"
                }`}
                key={i}
              >
                <img
                  src={`/images/icons/sdg-icons/sdg-${sdg.id}.svg`}
                  alt=""
                  className="h-[72px] w-[72px] object-cover shadow-md"
                />
              </div>
            ))}
          </div>

          <div className="flex items-center gap-x-6 mt-6">
            <CustomButton
              onClick={() => {
                if (selectedSDGs.length === 0) {
                  formik.setFieldError(
                    "selectedSDGs",
                    "Please select at least one of the SDGs to continue"
                  );
                  console.log("fomik", error);
                  setError(true);
                } else {
                  formik.handleSubmit();
                }
              }}
              type="submit"
              text={`Continue`}
              width="92px"
            />
            <CustomButton
              className="bg-white border-gray-200 border-[1px]"
              onClick={() => dispatch(changeStep(2))}
              text="Back"
              width="68px"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const Sidebar = () => {
  const [hoverIndex, setHoverIndex] = React.useState(0);
  const dispatch = useAppDispatch();

  const { selectedSector, sectorList, selectedSDGs } = useAppSelector(
    (state) => state.onboarding
  );

  const onHover = (id) => {
    if (id) setHoverIndex(id);
  };

  useEffect(() => {
    console.log(selectedSDGs);
  }, [selectedSDGs]);

  const changeBgColor = () => {
    const finalEle = selectedSector ? selectedSector.length - 1 : 0;
    switch (selectedSector?.[finalEle]) {
      case 1:
        return "bg-opacity-10 bg-success-600";
      case 2:
        return "bg-opacity-10 bg-transport-orange";
      case 3:
        return "bg-opacity-10 bg-farming-green";
      case 4:
        return "bg-opacity-10 bg-energy-yellow";
      case 5:
        return "bg-opacity-10 bg-energy-yellow";
      case 6:
        return "bg-opacity-10 bg-education-red";
      case 7:
        return "bg-opacity-10 bg-building-yellow";
      case 8:
        return "bg-opacity-10 bg-ecommerce-red";
      case 9:
        return "bg-opacity-10 bg-banking-red";
      case 10:
        return "bg-opacity-10 bg-primary-600";
      default:
        return "bg-gray-50";
    }
  };

  const renderHexagon = () => {
    const arr = sectorList?.filter((item) =>
      selectedSector?.includes(item?.id)
    );
    const firstEle = arr?.[0];
    const secondRow = arr?.slice(1, 3);
    const thirdRow = arr?.slice(3, 6);
    const fourthRow = arr?.slice(6, 9);
    const fifthRow = arr?.slice(9, 12);

    switch (selectedSector?.length) {
      case 1: {
        return (
          <div
            className={`hexagon-wrapper transform-new relative bottom-0 ${
              selectedSDGs.length > 0 && " bottom-48"
            }`}
          >
            <div className="hexagon-list flex justify-center clearfix">
              <div
                onMouseEnter={() => onHover(firstEle?.id)}
                onMouseLeave={() => onHover(null)}
                className="hexagon unset-wnh"
              >
                <img
                  src={`/images/icons/sector-icons/${firstEle.icon}-selected.svg`}
                  alt=""
                  className="w-36"
                />
              </div>
            </div>
            <div>{renderGoalCard()}</div>
          </div>
        );
      }
      case 2: {
        return (
          <div
            className={`hexagon-wrapper transform-new relative bottom-0 ${
              selectedSDGs.length > 0 && "bottom-40"
            }`}
          >
            <div className="hexagon-list clearfix">
              {sectorList
                ?.filter((item) => selectedSector?.includes(item?.id))
                .map((s, i) => (
                  <div
                    onMouseEnter={() => onHover(s.id)}
                    onMouseLeave={() => onHover(null)}
                    className="hexagon unset-wnh"
                    key={i}
                  >
                    <img
                      src={`/images/icons/sector-icons/${s.icon}-selected.svg`}
                      alt=""
                      className="w-36"
                    />
                  </div>
                ))}
            </div>
            <div>{renderGoalCard()}</div>
          </div>
        );
      }
      case 3: {
        return (
          <div
            className={`mt-[100px] hexagon-wrapper transform-new relative bottom-0 ${
              selectedSDGs.length > 0 && "bottom-44"
            }`}
          >
            <div className="hexagon-list clearfix">
              <div className="placeholder-hexagon hexagon"></div>
              <div
                onMouseEnter={() => onHover(firstEle.id)}
                onMouseLeave={() => onHover(null)}
                className="hexagon unset-wnh"
              >
                <img
                  src={`/images/icons/sector-icons/${firstEle.icon}-selected.svg`}
                  alt=""
                  className="w-36"
                />
              </div>
            </div>
            <div className="hexagon-list hex-two-row relative -top-10 clearfix">
              {secondRow?.map((s, i) => (
                <div className="hexagon unset-wnh" key={s.id}>
                  <img
                    src={`/images/icons/sector-icons/${s.icon}-selected.svg`}
                    alt=""
                    className="w-36"
                  />
                </div>
              ))}
            </div>
            <div>{renderGoalCard()}</div>
          </div>
        );
      }
      case 4: {
        return (
          <div
            className={`hexagon-wrapper transform-new relative bottom-0 left-6 ${
              selectedSDGs.length > 0 && "bottom-40"
            }`}
          >
            <div className="hexagon-list clearfix">
              <div className="placeholder-hexagon hexagon"></div>
              <div
                onMouseEnter={() => onHover(firstEle.id)}
                onMouseLeave={() => onHover(null)}
                className="hexagon"
              >
                <img
                  src={`/images/icons/sector-icons/${firstEle.icon}-selected.svg`}
                  alt=""
                  className="w-36"
                />
              </div>
            </div>
            <div className="hexagon-list relative -top-6 clearfix">
              {secondRow?.map((s, i) => (
                <div className="hexagon" key={s.id}>
                  <img
                    src={`/images/icons/sector-icons/${s.icon}-selected.svg`}
                    alt=""
                    className="w-36"
                  />
                </div>
              ))}
            </div>
            <div className="hexagon-list relative -top-12 clearfix">
              <div className="placeholder-hexagon hexagon"></div>
              {thirdRow?.map((s, i) => (
                <div className="hexagon" key={s.id}>
                  <img
                    src={`/images/icons/sector-icons/${s.icon}-selected.svg`}
                    alt=""
                    className="w-36"
                  />
                </div>
              ))}
            </div>
            <div>{renderGoalCard()}</div>
          </div>
        );
      }
      case 5: {
        return (
          <div
            className={`hexagon-wrapper relative bottom-0 transform-new right-4 ${
              selectedSDGs.length > 0 && "bottom-48"
            }`}
          >
            <div className="hexagon-list clearfix">
              <div className="placeholder-hexagon hexagon"></div>
              <div
                onMouseEnter={() => onHover(firstEle.id)}
                onMouseLeave={() => onHover(null)}
                className="hexagon"
              >
                <img
                  src={`/images/icons/sector-icons/${firstEle.icon}-selected.svg`}
                  alt=""
                  className="w-36"
                />
              </div>
            </div>
            <div className="hexagon-list relative -top-6 clearfix">
              {secondRow?.map((s, i) => (
                <div className="hexagon" key={s.id}>
                  <img
                    src={`/images/icons/sector-icons/${s.icon}-selected.svg`}
                    alt=""
                    className="w-36"
                  />
                </div>
              ))}
            </div>
            <div className="hexagon-list relative -top-12 clearfix">
              <div className="placeholder-hexagon hexagon"></div>
              {thirdRow?.map((s, i) => (
                <div className="hexagon" key={s.id}>
                  <img
                    src={`/images/icons/sector-icons/${s.icon}-selected.svg`}
                    alt=""
                    className="w-36"
                  />
                </div>
              ))}
            </div>
            <div>{renderGoalCard()}</div>
          </div>
        );
      }
      case 6: {
        return (
          <div
            className={`hexagon-wrapper relative transform-new bottom-0 right-4 ${
              selectedSDGs.length > 0 && "bottom-48"
            }`}
          >
            <div className="hexagon-list clearfix">
              <div className="placeholder-hexagon hexagon"></div>
              <div
                onMouseEnter={() => onHover(firstEle.id)}
                onMouseLeave={() => onHover(null)}
                className="hexagon"
              >
                <img
                  src={`/images/icons/sector-icons/${firstEle.icon}-selected.svg`}
                  alt=""
                  className="w-36"
                />
              </div>
            </div>
            <div className="hexagon-list relative -top-6 clearfix">
              {secondRow?.map((s, i) => (
                <div className="hexagon" key={s.id}>
                  <img
                    src={`/images/icons/sector-icons/${s.icon}-selected.svg`}
                    alt=""
                    className="w-36"
                  />
                </div>
              ))}
            </div>
            <div className="hexagon-list relative -top-12 clearfix">
              <div className="placeholder-hexagon hexagon"></div>
              {thirdRow?.map((s, i) => (
                <div className="hexagon" key={s.id}>
                  <img
                    src={`/images/icons/sector-icons/${s.icon}-selected.svg`}
                    alt=""
                    className="w-36"
                  />
                </div>
              ))}
            </div>
            <div>{renderGoalCard()}</div>
          </div>
        );
      }
      case 7: {
        return (
          <div
            className={`hexagon-wrapper transform-new relative bottom-0 ${
              selectedSDGs.length > 0 && "bottom-32"
            }`}
          >
            <div className="hexagon-list clearfix">
              <div className="placeholder-hexagon hexagon"></div>
              <div
                onMouseEnter={() => onHover(firstEle.id)}
                onMouseLeave={() => onHover(null)}
                className="hexagon"
              >
                <img
                  src={`/images/icons/sector-icons/${firstEle.icon}-selected.svg`}
                  alt=""
                  className="w-36"
                />
              </div>
            </div>
            <div className="hexagon-list relative -top-6 clearfix">
              {secondRow?.map((s) => (
                <div className="hexagon" key={s.id}>
                  <img
                    src={`/images/icons/sector-icons/${s.icon}-selected.svg`}
                    alt=""
                    className="w-36"
                  />
                </div>
              ))}
            </div>
            <div className="hexagon-list relative -top-12 clearfix">
              <div className="placeholder-hexagon hexagon"></div>
              {thirdRow?.map((s) => (
                <div className="hexagon" key={s.id}>
                  <img
                    src={`/images/icons/sector-icons/${s.icon}-selected.svg`}
                    alt=""
                    className="w-36"
                  />
                </div>
              ))}
            </div>
            <div className="hexagon-list relative -top-[72px] clearfix">
              {fourthRow?.map((s) => (
                <div className="hexagon" key={s.id}>
                  <img
                    src={`/images/icons/sector-icons/${s.icon}-selected.svg`}
                    alt=""
                    className="w-36"
                  />
                </div>
              ))}
            </div>
            <div>{renderGoalCard()}</div>
          </div>
        );
      }
      case 8: {
        return (
          <div
            className={`hexagon-wrapper transform-new relative bottom-0 ${
              selectedSDGs.length > 0 && "bottom-32"
            }`}
          >
            <div className="hexagon-list clearfix">
              <div className="placeholder-hexagon hexagon"></div>
              <div
                onMouseEnter={() => onHover(firstEle.id)}
                onMouseLeave={() => onHover(null)}
                className="hexagon"
              >
                <img
                  src={`/images/icons/sector-icons/${firstEle.icon}-selected.svg`}
                  alt=""
                  className="w-36"
                />
              </div>
            </div>
            <div className="hexagon-list relative -top-6 clearfix">
              {secondRow?.map((s) => (
                <div className="hexagon" key={s.id}>
                  <img
                    src={`/images/icons/sector-icons/${s.icon}-selected.svg`}
                    alt=""
                    className="w-36"
                  />
                </div>
              ))}
            </div>
            <div className="hexagon-list relative -top-12 clearfix">
              <div className="placeholder-hexagon hexagon"></div>
              {thirdRow?.map((s) => (
                <div className="hexagon" key={s.id}>
                  <img
                    src={`/images/icons/sector-icons/${s.icon}-selected.svg`}
                    alt=""
                    className="w-36"
                  />
                </div>
              ))}
            </div>
            <div className="hexagon-list relative -top-[72px] clearfix">
              {fourthRow?.map((s) => (
                <div className="hexagon" key={s.id}>
                  <img
                    src={`/images/icons/sector-icons/${s.icon}-selected.svg`}
                    alt=""
                    className="w-36"
                  />
                </div>
              ))}
            </div>
            <div>{renderGoalCard()}</div>
          </div>
        );
      }
      case 9: {
        return (
          <div
            className={`hexagon-wrapper transform-new relative bottom-0 ${
              selectedSDGs.length > 0 && "bottom-32"
            }`}
          >
            <div className="hexagon-list clearfix">
              <div className="placeholder-hexagon hexagon"></div>
              <div
                onMouseEnter={() => onHover(firstEle.id)}
                onMouseLeave={() => onHover(null)}
                className="hexagon"
              >
                <img
                  src={`/images/icons/sector-icons/${firstEle.icon}-selected.svg`}
                  alt=""
                  className="w-36"
                />
              </div>
            </div>
            <div className="hexagon-list relative -top-6 clearfix">
              {secondRow?.map((s) => (
                <div className="hexagon" key={s.id}>
                  <img
                    src={`/images/icons/sector-icons/${s.icon}-selected.svg`}
                    alt=""
                    className="w-36"
                  />
                </div>
              ))}
            </div>
            <div className="hexagon-list relative -top-12 clearfix">
              <div className="placeholder-hexagon hexagon"></div>
              {thirdRow?.map((s) => (
                <div className="hexagon" key={s.id}>
                  <img
                    src={`/images/icons/sector-icons/${s.icon}-selected.svg`}
                    alt=""
                    className="w-36"
                  />
                </div>
              ))}
            </div>
            <div className="hexagon-list relative -top-[72px] clearfix">
              {fourthRow?.map((s) => (
                <div className="hexagon" key={s.id}>
                  <img
                    src={`/images/icons/sector-icons/${s.icon}-selected.svg`}
                    alt=""
                    className="w-36"
                  />
                </div>
              ))}
            </div>
            <div>{renderGoalCard()}</div>
          </div>
        );
      }
      default: {
        <div
          className={`hexagon-wrapper transform-new relative bottom-0 ${
            selectedSDGs.length > 0 && "bottom-32"
          }`}
        >
          <div className="hexagon-list clearfix">
            <div className="placeholder-hexagon hexagon"></div>
            <div
              onMouseEnter={() => onHover(firstEle?.id)}
              onMouseLeave={() => onHover(null)}
              className="hexagon"
            >
              <img
                src={`/images/icons/sector-icons/${firstEle?.icon}-selected.svg`}
                alt=""
                className="w-36"
              />
            </div>
          </div>
          <div className="hexagon-list relative -top-6 clearfix">
            {secondRow?.map((s) => (
              <div className="hexagon" key={s.id}>
                <img
                  src={`/images/icons/sector-icons/${s.icon}-selected.svg`}
                  alt=""
                  className="w-36"
                />
              </div>
            ))}
          </div>
          <div className="hexagon-list relative -top-12 clearfix">
            <div className="placeholder-hexagon hexagon"></div>
            {thirdRow?.map((s) => (
              <div className="hexagon" key={s.id}>
                <img
                  src={`/images/icons/sector-icons/${s.icon}-selected.svg`}
                  alt=""
                  className="w-36"
                />
              </div>
            ))}
          </div>
          <div className="hexagon-list relative -top-[72px] clearfix">
            {fourthRow?.map((s) => (
              <div className="hexagon" key={s.id}>
                <img
                  src={`/images/icons/sector-icons/${s.icon}-selected.svg`}
                  alt=""
                  className="w-36"
                />
              </div>
            ))}
          </div>
          <div className="hexagon-list relative -top-24 clearfix">
            <div className="placeholder-hexagon hexagon"></div>
            <div className="placeholder-hexagon hexagon"></div>
            {fifthRow?.map((s) => (
              <div className="hexagon" key={s.id}>
                <img
                  src={`/images/icons/sector-icons/${s.icon}-selected.svg`}
                  alt=""
                  className="w-36"
                />
              </div>
            ))}
          </div>
          <div>{renderGoalCard()}</div>
        </div>;
      }
    }
  };

  const renderGoalCard = () => {
    console.log(selectedSDGs);

    const firstEle = selectedSDGs?.[0];
    const secondEle = selectedSDGs?.[1];
    const thirdEle = selectedSDGs?.[2];
    // const etcEle =

    return (
      <>
        <div
          className={`transform-new relative top-96 opacity-0 ${
            selectedSDGs.length >= 1 && "-translate-y-96 opacity-100"
          }`}
        >
          <img
            src={`/images/icons/sdg-badges/sdg-${firstEle}.svg`}
            className="object-fit"
            alt=""
          />
        </div>
        <div
          className={` transform-new relative top-80 opacity-0 ${
            selectedSDGs.length >= 2 && "-translate-y-80 opacity-100"
          }`}
        >
          <img
            src={`/images/icons/sdg-badges/sdg-${secondEle}.svg`}
            className="object-fit"
            alt=""
          />
        </div>
        <div
          className={` transform-new relative top-60 opacity-0 ${
            selectedSDGs.length >= 3 && "-translate-y-60 opacity-100"
          }`}
        >
          <img
            src={`/images/icons/sdg-badges/sdg-${thirdEle}.svg`}
            className="object-fit"
            alt=""
          />
        </div>
        <div
          className={` transform-new relative top-40 opacity-0 ${
            selectedSDGs.length >= 4 && "-translate-y-40 opacity-100"
          }`}
        >
          <img
            src={`/images/icons/sdg-badges/goal-num-card.svg`}
            className="object-fit"
            alt=""
          />
        </div>
      </>
    );
  };

  return (
    <>
      <div
        className={`w-full h-screen overflow-hidden relative flex justify-center items-center ${changeBgColor()} `}
      >
        <div className="absolute left-0 top-64 m-auto w-full flex flex-col items-center justify-start">
          <div className="">{renderHexagon()}</div>
        </div>
        <div className="absolute bottom-9 flex items-center gap-x-2 cursor-pointer">
          <p className=" text-primary-700 mon-500-14 pt-0.5">
            Know more about SDGs
          </p>
          <img src="/images/icons/share-blue.svg" className="w-5 h-5" />
        </div>
      </div>
    </>
  );
};
const SDGSelection = () => {
  return (
    <ProductOnboardingLayout content={<Content />} sidebar={<Sidebar />} />
  );
};

export default SDGSelection;
