import { createSlice, current } from "@reduxjs/toolkit";
import { getWorkspaces } from "../api/dashboardAPI";
import {
  getCompletedProjectCount,
  getMyTasks,
  getOngoingProjectCount,
  getTodos,
} from "../api/dashboardAPI";

const initialState = {
  loading: false,
  error: {},
  onGoingProjects: 0,
  completedProjects: 0,
  workspaces: [],
  todoList: [],
  myTasks: [],
 
};

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    resetToInitialDashBoard: (state, action) => {
      Object.keys(initialState).forEach(
        (key) => (state[key] = initialState[key])
      );
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getOngoingProjectCount.pending, (state) => {
        state.loading = true;
      })
      .addCase(getOngoingProjectCount.fulfilled, (state, action) => {
        state.loading = false;
        state.onGoingProjects = action.payload.count;
      })
      .addCase(getOngoingProjectCount.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
      })
      .addCase(getCompletedProjectCount.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCompletedProjectCount.fulfilled, (state, action) => {
        state.loading = false;
        state.completedProjects = action.payload.count;
      })
      .addCase(getCompletedProjectCount.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
      })
      .addCase(getTodos.pending, (state) => {
        state.loading = true;
      })
      .addCase(getTodos.fulfilled, (state, action) => {
        state.loading = false;
        state.todoList = action.payload;
      })
      .addCase(getTodos.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
      })
      .addCase(getMyTasks.pending, (state) => {
        state.loading = true;
      })
      .addCase(getMyTasks.fulfilled, (state, action) => {
        state.loading = false;
        state.myTasks = action.payload;
      })
      .addCase(getMyTasks.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
      })
      .addCase(getWorkspaces.pending, (state) => {
        state.loading = true;
      })
      .addCase(getWorkspaces.fulfilled, (state, action) => {
        state.loading = false;
        state.workspaces = action.payload;
      })
      .addCase(getWorkspaces.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
      });
  },
});

 export const { resetToInitialDashBoard} = dashboardSlice.actions;
export default dashboardSlice.reducer;
