import React, { useState } from "react";
import CustomAvatar from "../../../Core/Common/CustomAvatar";
import CustomModal from "../../../Core/Common/CustomModal";
import CustomSearch from "../../../Core/Common/CustomSearch";
import { getPrivateMessages } from "../../../Core/redux/api/conversationAPI";
import { useAppDispatch, useAppSelector } from "../../../Core/redux/hooks";
import {
  resetConversations,
  updateConversations,
  updateConversationsMultiple,
} from "../../../Core/redux/slices/conversations.slice";

const NewMessageModal = ({ visible, onCancel }) => {
  const [search, setSearch] = useState("");

  const { directMessageUsers: userList } = useAppSelector(
    (state) => state.conversations
  );
  const { user } = useAppSelector((state) => state.userDetails);
  const dispatch = useAppDispatch();

  const joinPrivateThread = async (reciver) => {
    dispatch(
      updateConversationsMultiple([
        {
          value: { ...reciver, type: "private" },
          key: "currentThread",
        },
        { key: "currentThreadMessage", value: [] },
      ])
    );
    dispatch(
      resetConversations(["replyMessage", "editMessage", "newMessageModal"])
    );
    await dispatch(
      getPrivateMessages({
        userId: user.id,
        reciverId: reciver.userId,
        limit: 100,
        offset: 0,
      })
    );
  };

  function filterUsers(userList, search) {
    return userList.filter((user) =>
      user.firstname && (user.lastname || user.email)
        ? user.firstname.toLowerCase().includes(search) ||
          (user.lastname && user.lastname.toLowerCase().includes(search)) ||
          (user.email && user.email.toLowerCase().includes(search))
        : false
    );
  }
  return (
    <CustomModal
      title={<div className="mon-400-12 text-gray-400">New message</div>}
      width={"384px"}
      visible={visible}
      body={
        <div className="max-h-[48.189386056191466vh] overflow-y-scroll px-3 pt-3 pb-7">
          <CustomSearch
            placeholder="Search team member"
            style={{
              border: "1px solid #C0CBD7",
            }}
            search={search}
            setSearch={(e) => setSearch(e.toLowerCase())}
          />
          {search.length > 0 ? (
            <div className="mt-2">
              {filterUsers(userList, search).map((u, i) => {
                return (
                  <div
                    className="flex justify-between items-center py-2.5 px-2 cursor-pointer"
                    key={i}
                    onClick={() => joinPrivateThread(u)}
                  >
                    <div className="flex items-center">
                      <CustomAvatar
                        title={`${u?.firstname ? u?.firstname : u.email}`}
                        size={24}
                        src={u?.profilePicture}
                      />
                      <p className="ml-2 mon-400-12 text-gray-700 capitalize">
                        {u?.firstname
                          ? u?.firstname + " " + u?.lastname
                          : u.email}
                      </p>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <></>
          )}
        </div>
      }
      onCancel={onCancel}
    />
  );
};

export default NewMessageModal;
