import React, { useState } from "react";
import { Checkbox, Segmented } from "antd";
import CustomModal from "../../../Core/Common/CustomModal";
import CustomButton from "../../../Core/Common/CustomButton";
import CustomSearch from "../../../Core/Common/CustomSearch";
import { useAppSelector } from "../../../Core/redux/hooks";
import CustomAvatar from "../../../Core/Common/CustomAvatar";

type ShareModalProps = {
  visible: boolean;
  onClose: () => void;
  onShare: (selectedUsers: any[]) => void;
  type?: string;
};

const ShareModal = ({ visible, onClose, onShare, type }: ShareModalProps) => {
  const [showSegment, setShowSegment] = useState<string | number>("Threads");

  const [selectedUsers, setSelectedUsers] = useState([]);
  const [search, setSearch] = useState("");
  const { directMessageUsers } = useAppSelector((state) => state.conversations);

  const handelChecked = (id) => {
    if (selectedUsers.indexOf(id) !== -1) {
      setSelectedUsers((old) => old.filter((uid) => id !== uid));
    } else {
      setSelectedUsers((old) => [...old, id]);
    }
  };

  console.log(type, "type");
  return (
    <CustomModal
      title={
        <p className="mon-500-12 text-gray-700">
          {type === "shareInMessage"
            ? "Share In Message"
            : type === "shareFile"
            ? "Share File"
            : "Forward Message"}
        </p>
      }
      width={"524px"}
      visible={visible}
      body={
        <div className="max-h-[38.189386056191466vh] overflow-y-scroll">
          <CustomSearch
            className="  mx-4 my-4"
            placeholder="Search members"
            style={{
              border: "1px solid #C0CBD7",
            }}
            search={search}
            setSearch={(e) => setSearch(e.toLowerCase())}
          />
          <div className="max-w-[492px] mx-4 my-4">
            {type === "shareInMessage" && (
              <Segmented
                options={[
                  {
                    label: (
                      <div className="text-center mon-500-14 pt-1.5 text-gray-700">
                        Threads
                      </div>
                    ),
                    value: "Threads",
                  },
                  {
                    label: (
                      <div className="text-center mon-500-14 pt-1.5 text-gray-700">
                        People
                      </div>
                    ),
                    value: "People",
                  },
                ]}
                block={true}
                defaultValue="Threads"
                className="text-center mon-500-14 h-11 text-gray-700 rounded-lg"
                onChange={(value) => {
                  setShowSegment(value);
                }}
              />
            )}
            {type === "shareInMessage" ? (
              <>
                {showSegment === "Threads" && (
                  <div>
                    <div className="mon-400-12 text-gray-700 pt-5">
                      Recent threads
                    </div>
                    <div className="flex items-center justify-between pt-4">
                      <div className="flex items-center ">
                        <Checkbox />
                        <img
                          src="/images/icons/square-filled.svg"
                          alt="avatar"
                          className="w-4 h-4 ml-3"
                        />
                        <p className="mon-400-12 text-gray-700 capitalize ml-3">
                          Ganesh Mohanty
                        </p>
                      </div>
                    </div>
                    <div className="flex items-center justify-between pt-8">
                      <div className="flex items-center ">
                        <Checkbox />
                        <img
                          src="/images/icons/square-filled.svg"
                          alt="avatar"
                          className="w-4 h-4 ml-3"
                        />
                        <p className="mon-400-12 text-gray-700 capitalize ml-3">
                          Ganesh Mohanty
                        </p>
                      </div>
                    </div>
                    <div className="flex items-center justify-between pt-8">
                      <div className="flex items-center ">
                        <Checkbox />
                        <img
                          src="/images/icons/square-filled.svg"
                          alt="avatar"
                          className="w-4 h-4 ml-3"
                        />
                        <p className="mon-400-12 text-gray-700 capitalize ml-3">
                          Ganesh Mohanty
                        </p>
                      </div>
                    </div>
                    <div className="flex items-center justify-between pt-8">
                      <div className="flex items-center ">
                        <Checkbox />
                        <img
                          src="/images/icons/square-filled.svg"
                          alt="avatar"
                          className="w-4 h-4 ml-3"
                        />
                        <p className="mon-400-12 text-gray-700 capitalize ml-3">
                          Ganesh Mohanty
                        </p>
                      </div>
                    </div>
                    <div className="flex items-center justify-between pt-8">
                      <div className="flex items-center ">
                        <Checkbox />
                        <img
                          src="/images/icons/square-filled.svg"
                          alt="avatar"
                          className="w-4 h-4 ml-3"
                        />
                        <p className="mon-400-12 text-gray-700 capitalize ml-3">
                          Ganesh Mohanty
                        </p>
                      </div>
                    </div>
                    <div className="flex items-center justify-between pt-8">
                      <div className="flex items-center ">
                        <Checkbox />
                        <img
                          src="/images/icons/square-filled.svg"
                          alt="avatar"
                          className="w-4 h-4 ml-3"
                        />
                        <p className="mon-400-12 text-gray-700 capitalize ml-3">
                          Ganesh Mohanty
                        </p>
                      </div>
                    </div>
                  </div>
                )}
                {showSegment === "People" && (
                  <div className="mt-2">
                    <div className="flex items-center justify-between px-4 pt-4">
                      <div className="flex items-center ">
                        <Checkbox />
                        <img
                          src="/images/Avatar.png"
                          alt="avatar"
                          className="w-10 h-10  ml-3"
                        />
                        <p className="mon-500-14 text-gray-700 capitalize ml-3">
                          Ganesh Mohanty
                        </p>
                      </div>
                      <p className="mon-500-12 text-gray-500">Developer</p>
                    </div>
                    <div className="flex items-center justify-between px-4 pt-4">
                      <div className="flex items-center ">
                        <Checkbox />
                        <img
                          src="/images/Avatar.png"
                          alt="avatar"
                          className="w-10 h-10  ml-3"
                        />
                        <p className="mon-500-14 text-gray-700 capitalize ml-3">
                          Ganesh Mohanty
                        </p>
                      </div>
                      <p className="mon-500-12 text-gray-500">Developer</p>
                    </div>
                    <div className="flex items-center justify-between px-4 pt-4">
                      <div className="flex items-center ">
                        <Checkbox />
                        <img
                          src="/images/Avatar.png"
                          alt="avatar"
                          className="w-10 h-10  ml-3"
                        />
                        <p className="mon-500-14 text-gray-700 capitalize ml-3">
                          Ganesh Mohanty
                        </p>
                      </div>
                      <p className="mon-500-12 text-gray-500">Developer</p>
                    </div>
                    <div className="flex items-center justify-between px-4 pt-4">
                      <div className="flex items-center ">
                        <Checkbox />
                        <img
                          src="/images/Avatar.png"
                          alt="avatar"
                          className="w-10 h-10  ml-3"
                        />
                        <p className="mon-500-14 text-gray-700 capitalize ml-3">
                          Ganesh Mohanty
                        </p>
                      </div>
                      <p className="mon-500-12 text-gray-500">Developer</p>
                    </div>
                    <div className="flex items-center justify-between px-4 pt-4">
                      <div className="flex items-center ">
                        <Checkbox />
                        <img
                          src="/images/Avatar.png"
                          alt="avatar"
                          className="w-10 h-10  ml-3"
                        />
                        <p className="mon-500-14 text-gray-700 capitalize ml-3">
                          Ganesh Mohanty
                        </p>
                      </div>
                      <p className="mon-500-12 text-gray-500">Developer</p>
                    </div>
                    <div className="flex items-center justify-between px-4 pt-4">
                      <div className="flex items-center ">
                        <Checkbox />
                        <img
                          src="/images/Avatar.png"
                          alt="avatar"
                          className="w-10 h-10  ml-3"
                        />
                        <p className="mon-500-14 text-gray-700 capitalize ml-3">
                          Ganesh Mohanty
                        </p>
                      </div>
                      <p className="mon-500-12 text-gray-500">Developer</p>
                    </div>
                  </div>
                )}
              </>
            ) : (
              <div className="mt-2">
                {directMessageUsers ? (
                  directMessageUsers
                    .filter((u) =>
                      u.firstname
                        ? u.firstname.toLowerCase().includes(search) ||
                          u.lastname.includes(search)
                        : u.email.toLowerCase().includes(search)
                    )
                    .map((user) => (
                      <div
                        className="flex items-center justify-between px-4 pt-4"
                        key={user.userId}
                      >
                        <div className="flex items-center ">
                          <Checkbox
                            onChange={(e) => handelChecked(user.userId)}
                            checked={selectedUsers.indexOf(user.userId) !== -1}
                          />
                          <div className="ml-3">
                            <CustomAvatar
                              title={
                                user.firstname
                                  ? user.firstname + " " + user.lastname
                                  : user.email
                                  ? user.email
                                  : "User"
                              }
                              src={user.profilePicture}
                              size={40}
                            />
                          </div>
                          <p className="mon-500-14 text-gray-700 capitalize ml-3">
                            {user.firstname
                              ? user.firstname + " " + user.lastname
                              : user.email
                              ? user.email
                              : "User"}
                          </p>
                        </div>
                        <p className="mon-500-12 text-gray-500">
                          {user.roleName}
                        </p>
                      </div>
                    ))
                ) : (
                  <></>
                )}
              </div>
            )}
          </div>
        </div>
      }
      footer={
        <div className="flex justify-between py-2.5 px-3">
          <CustomButton
            text="Cancel"
            onClick={() => onClose()}
            className="border border-gray-200 text-gray-700 bg-white mon-500-16 "
            height="44px"
            width="49%"
          />
          <CustomButton
            text={type === "forwardMessage" ? "Forward" : "Share"}
            onClick={() => {
              onShare(selectedUsers);
            }}
            className="mon-500-16 text-white bg-primary-600 "
            height="44px"
            width="49%"
          />
        </div>
      }
      onCancel={() => {
        onClose();
      }}
    />
  );
};

export default ShareModal;
