import React, { useState } from "react";
import dayjs from "dayjs";
import CustomModal from "../../Core/Common/CustomModal";
import CustomButton from "../../Core/Common/CustomButton";
import { Row, Col, Dropdown, Menu, DatePicker, Select } from "antd";
import { useAppDispatch, useAppSelector } from "../../Core/redux/hooks";
import { useParams } from "react-router-dom";
// import Multiselect from "multiselect-react-dropdown";
import { CustomSelectWithInlineSearch } from "../../Core/Common/CustomSelectWithSearch";
import CustomSelect from "../../Core/Common/CustomSelect";
import CustomInputWithEdit from "../../Core/Common/CustomInputWithEdit";
import {
  createGoal,
  getGoalsBySprint,
  getProjectPhases,
  updateGoal,
  updateProgress,
} from "../../Core/redux/api/scheduleAPI";
import NewScopeBadge from "./NewScopeBadge";
import { getProjectDetatis } from "../../Core/redux/api/projectAPI";

type EditGoalModalProps = {
  visible: boolean;
  onCancel: () => void;
  goal: {
    name: string;
    dueDate: string;
    statusId: string | number;
    priorityId?: number | string;
    id?: number | string;
    assignee: string;
    components?: any[];
    isNewGoal?: number | boolean;
  };
};

const EditGoalModal = ({ visible, onCancel, goal }: EditGoalModalProps) => {
  const [newGoal, setNewGoal] = useState({ ...goal });
  const dispatch = useAppDispatch();

  const [isHovering, setIsHovering] = useState(false);
  const [currentDropDown, setCurrentDropDown] = useState("");

  const disptach = useAppDispatch();
  const { projectId } = useParams();

  const { selectedSprint, currentSelectedPhase } = useAppSelector(
    (state) => state.schedule
  );

  const handleMouseOver = () => {
    setIsHovering(true);
  };

  const handleMouseOut = () => {
    setIsHovering(false);
  };
  const hasChanged = () => {
    return (
      newGoal.name !== goal.name ||
      newGoal.priorityId !== goal.priorityId ||
      newGoal.statusId !== goal.statusId ||
      newGoal.dueDate !== goal.dueDate
    );
  };

  const getStatus = () => {
    return (
      <div className="flex items-center">
        {newGoal.statusId === 1 ? (
          <div className="flex items-center bg-gray-100 rounded-2xl">
            <p className="mon-500-14 text-gray-700 px-2 uppercase py-0.5">
              To Do
            </p>
          </div>
        ) : newGoal.statusId === 2 ? (
          <div
            className="flex items-center max-w-max whitespace-nowrap bg-primary-600 rounded-2xl"
            onClick={() => setNewGoal({ ...newGoal, statusId: 2 })}
          >
            <p className="mon-500-14 text-white px-2 uppercase py-0.5">
              In progress
            </p>
          </div>
        ) : newGoal.statusId === 3 ? (
          <div
            className="flex items-center max-w-max whitespace-nowrap bg-success-600 rounded-2xl"
            onClick={() => setNewGoal({ ...newGoal, statusId: 3 })}
          >
            <p className="mon-500-14 text-white px-2 uppercase py-0.5">Done</p>
          </div>
        ) : (
          <div className="flex items-center bg-gray-100 rounded-2xl">
            <p className="mon-500-14 text-gray-700 px-2 uppercase py-0.5">
              To Do
            </p>
          </div>
        )}

        <Dropdown
          getPopupContainer={(trigger) => trigger.parentElement}
          className="mt-1"
          overlay={
            <Menu className="w-[140px]  rounded-lg  pt-2">
              <Menu.Item
                // key="copy"
                className=" hover:bg-[#ffff]"
                // style={{ width: "124px", height: "28px" }}
              >
                <div
                  className="flex items-center max-w-max bg-gray-100 rounded-2xl"
                  onClick={() => setNewGoal({ ...newGoal, statusId: 1 })}
                >
                  <p className="mon-500-14 text-gray-700 px-2 uppercase py-0.5">
                    To Do
                  </p>
                </div>
              </Menu.Item>
              <Menu.Item
                // key="copy"
                className="hover:bg-[#ffff]"
                //style={{ width: "124px", height: "28px" }}
              >
                <div
                  className="flex items-center max-w-max whitespace-nowrap bg-primary-600 rounded-2xl"
                  onClick={() => setNewGoal({ ...newGoal, statusId: 2 })}
                >
                  <p className="mon-500-14 text-white px-2 uppercase py-0.5">
                    In progress
                  </p>
                </div>
              </Menu.Item>
              <Menu.Item
                // key="copy"
                className="hover:bg-[#ffff]"
                //style={{ width: "124px", height: "28px" }}
              >
                <div
                  className="flex items-center max-w-max whitespace-nowrap bg-success-600 rounded-2xl"
                  onClick={() => setNewGoal({ ...newGoal, statusId: 3 })}
                >
                  <p className="mon-500-14 text-white px-2 uppercase py-0.5">
                    Done
                  </p>
                </div>
              </Menu.Item>
            </Menu>
          }
          trigger={["click"]}
        >
          <div className="flex w-5 h-5 ml-2 cursor-pointer">
            <img
              src="/images/icons/chevron-down-gray.svg"
              className="w-full h-full"
            />
          </div>
        </Dropdown>
      </div>
    );
  };

  const getPriorityIcon = (val: number) => {
    switch (val) {
      case 1:
        return (
          <div className="flex items-center ">
            <img src="/images/icons/flag-gray.svg" />
            <p className="mon-400-12 text-gray-400 ml-1.5">Low</p>
          </div>
        );
      case 2:
        return (
          <div className="flex items-center">
            <img src="/images/icons/flag-blue.svg" />
            <p className="mon-400-12 text-primary-600 ml-1.5">Medium</p>
          </div>
        );
      case 3:
        return (
          <div className="flex items-center">
            <img src="/images/icons/flag-red.svg" />
            <p className="mon-400-12 text-error-600 ml-1.5">High</p>
          </div>
        );
      default:
        return (
          <div className="flex items-center">
            <img src="/images/icons/flag-gray.svg" />
            <p className="mon-400-12 text-gray-400 ml-1.5">Low</p>
          </div>
        );
    }
  };

  const additionalFunctionality = () => {
    return (
      <Row>
        <CustomButton
          text="Create a folder"
          icon="/images/icons/folder-plus-blue.svg"
          //onClick={onCancel}
          className=" text-primary-700 bg-primary-50 mon-500-14"
          height="40px"
          width="164px"
        />
        <div className="pl-3">
          <CustomButton
            text="Start a thread"
            icon="/images/icons/message-plus-circle-blue.svg"
            //onClick={onCancel}
            className="text-primary-700 bg-primary-50 mon-500-14"
            height="40px"
            width="158px"
          />
        </div>
        <div className="pl-3">
          <CustomButton
            text="Create event"
            icon="/images/icons/calendar-event-blue.svg"
            //onClick={onCancel}
            className="text-primary-700 bg-primary-50 mon-500-14"
            height="40px"
            width="151px"
          />
        </div>
      </Row>
    );
  };

  return (
    <CustomModal
      title={
        <div className="flex items-center">
          <div
            className="flex w-5 h-5"
            onMouseOver={handleMouseOver}
            onMouseOut={handleMouseOut}
          >
            <img
              src="/images/icons/chevron-left.svg"
              className="w-full h-full"
            />
          </div>
          {isHovering && (
            <div className="flex">
              <p className="mon-500-16 pl-3 text-gray-400">Schedule</p>
              <span className="mon-500-16 pl-3 text-gray-400"> / </span>
              <p className="mon-500-16 pl-3 text-gray-400 capitalize">
                {currentSelectedPhase.phases || "Ideation"}
              </p>
            </div>
          )}

          <span className="mon-500-16 pl-3 text-gray-400"> / </span>
          <p className="mon-500-16 pl-3 text-gray-400 capitalize">
            {selectedSprint.name || "Sprint"}
          </p>
          <span className="pl-3"> / </span>
          <p className="flex mon-500-16 text-black pl-3">Goal</p>
          {goal.isNewGoal ? <NewScopeBadge /> : null}
        </div>
      }
      width={"1064px"}
      visible={visible}
      body={
        <div className="max-h-[480px] h-[90vh] overflow-y-scroll">
          <div className="flex items-center px-5 py-5">
            <img
              src="/images/icons/frosty-help.svg"
              className="h-9 w-9"
              alt="frosty"
            />
            <p className="text-sm font-medium text-primary-600 ml-2">
              Goals are the main milestones within each sprint
            </p>
          </div>
          <Row className="pt-5 pb-4 px-5">
            <Col
              className="goal-title flex items-center mon-500-20 text-gray-400 hover:text-black"
              style={{ width: "500px", height: "50px" }}
            >
              <CustomInputWithEdit
                value={newGoal.name}
                inputClassName="mon-600-20  mon-500-20 text-gray-400 hover:text-black"
                containerClassName="border-0"
                onChange={(e) => {
                  setNewGoal({ ...newGoal, name: e.target.value });
                }}
              />

              {/* <div className="">
                <img src="" className="goal-title-image" />
              </div> */}
            </Col>
            <Col className="flex pl-12">{getStatus()}</Col>

            <Col className="pt-6">
              <div>
                <div>{additionalFunctionality()}</div>
                <p className="mon-500-16 text-black pt-14">Activity</p>
              </div>
            </Col>

            <Col className="pt-6 pl-14">
              <p className="mon-500-10 text-gray-500 uppercase">Due date</p>
              <div className="pt-2">
                <DatePicker
                  className="w-full h-10 bg-transparent rounded-xl border-none"
                  suffixIcon={<></>}
                  format="DD MMM YY"
                  allowClear={true}
                  placeholder="Select Date"
                  value={
                    newGoal.dueDate !== "" && (dayjs(newGoal.dueDate) as any)
                  }
                  onChange={(e) =>
                    setNewGoal({
                      ...newGoal,
                      dueDate: dayjs(e as any).format("YYYY-MM-DD"),
                    })
                  }
                />
              </div>
              <p className="pt-5 mon-500-10 text-gray-500 uppercase">
                Priority
              </p>
              {currentDropDown === "priority" ? (
                <CustomSelect
                  options={[
                    {
                      id: "1",
                      value: 1,
                      render: (value) => <p>{getPriorityIcon(1)}</p>,
                    },

                    {
                      id: "2",
                      value: 2,
                      render: (value) => <p>{getPriorityIcon(2)}</p>,
                    },
                    {
                      id: "3",
                      value: 3,
                      render: (value) => <p>{getPriorityIcon(3)}</p>,
                    },
                  ]}
                  value={newGoal.priorityId}
                  onChange={(e) => {
                    setNewGoal({ ...newGoal, priorityId: e });
                  }}
                  onDropdownVisibleChange={() => setCurrentDropDown("")}
                />
              ) : (
                <p
                  className="pt-3"
                  onClick={() => setCurrentDropDown("priority")}
                >
                  {getPriorityIcon(newGoal.priorityId as number)}
                </p>
              )}

              <p className="pt-5 mon-500-10 text-gray-500 uppercase">
                ASSIGNEE
              </p>
              {currentDropDown === "assignee" ? (
                <CustomSelect
                  onDropdownVisibleChange={() => setCurrentDropDown("")}
                  options={[
                    {
                      id: "1",
                      value: "india",
                      render: (value) => (
                        <div className="flex items-center">
                          <div className="w-6 h-6">
                            <img
                              src="/images/Avatar.png"
                              className="w-full h-full"
                            />
                          </div>
                          <p className="mon-400-14 text-gray-900 ml-2">
                            Ebrahim Kothari
                          </p>
                        </div>
                      ),
                    },

                    {
                      id: "1",
                      value: "india1",
                      render: (value) => (
                        <div className="flex items-center ">
                          <div className="w-6 h-6">
                            <img
                              src="/images/Avatar.png"
                              className="w-full h-full"
                            />
                          </div>
                          <p className="mon-400-14 text-gray-900 ml-2">
                            Ebrahim Kothari
                          </p>
                        </div>
                      ),
                    },
                    {
                      id: "2",
                      value: "india2",
                      render: (value) => (
                        <div className="flex items-center">
                          <div className="w-6 h-6">
                            <img
                              src="/images/Avatar.png"
                              className="w-full h-full"
                            />
                          </div>
                          <p className="mon-400-14 text-gray-900 ml-2">
                            Ebrahim Kothari
                          </p>
                        </div>
                      ),
                    },
                  ]}
                  value="india"
                />
              ) : (
                <div
                  className="flex items-center mt-3 cursor-pointer"
                  onClick={() => setCurrentDropDown("assignee")}
                >
                  <div className="w-6 h-6">
                    <img src="/images/Avatar.png" className="w-full h-full" />
                  </div>
                  <p className="mon-400-14 text-gray-900 ml-2">
                    Ebrahim Kothari
                  </p>
                </div>
              )}

              <p className="pt-5 mon-500-10 text-gray-500 uppercase">
                Component (optional)
              </p>
              {currentDropDown === "addComponent" ? (
                <CustomSelectWithInlineSearch
                  options={[
                    {
                      id: "1",
                      title: "Note",
                      options: [
                        {
                          id: "1",
                          value: "india",
                          render: (value) => (
                            <div className="mon-400-14 pl-2 text-gray-900 capitalize ">
                              india
                            </div>
                          ),
                        },
                      ],
                    },
                    {
                      id: "2",
                      title: "Dev",
                      options: [
                        {
                          id: "1",
                          value: "india1",
                          render: (value) => (
                            <div className="mon-400-14 pl-2 text-gray-900 capitalize ">
                              india1
                            </div>
                          ),
                        },
                        {
                          id: "2",
                          value: "india2",
                          render: (value) => (
                            <div className="mon-400-14 pl-2 text-gray-900 capitalize ">
                              india2
                            </div>
                          ),
                        },
                      ],
                    },
                  ]}
                  value="india"
                />
              ) : (
                <button
                  className="flex items-center bg-gray-25 mt-3 rounded-2xl"
                  style={{ width: "155px", height: "28px" }}
                  onClick={() => setCurrentDropDown("addComponent")}
                >
                  <p className="flex pl-3 mon-500-14 text-gray-700">
                    Add component
                  </p>
                  <div className="flex w-3 h-3 ml-1">
                    <img
                      src="/images/icons/plus-grey.svg"
                      className="w-full h-full"
                    />
                  </div>
                </button>
              )}
            </Col>
          </Row>

          <div></div>
        </div>
      }
      footer={
        <div className="shadow-upward flex justify-end py-4 px-4">
          {hasChanged() ? (
            <>
              <CustomButton
                text="Cancel"
                onClick={() => setNewGoal({ ...goal })}
                className="border-2 border-gray-200 text-black bg-white mon-500-14 "
                height="40px"
                width="81px"
              />
              <div className="pl-3">
                <CustomButton
                  text="Save"
                  onClick={async () => {
                    await disptach(
                      updateGoal({
                        goalId: newGoal.id,
                        priorityId: newGoal.priorityId,
                        statusId: newGoal.statusId,
                        name: newGoal.name,
                        dueDate: newGoal.dueDate,
                        isCompleted: newGoal.statusId === 3 ? 1 : 0,
                        isNewGoal: false,
                        projectId,
                        sprintId: selectedSprint.id,
                        phaseId: currentSelectedPhase.id,
                      })
                    );
                    onCancel();
                    disptach(
                      getGoalsBySprint({
                        projectId,
                        sprintId: selectedSprint.id,
                      })
                    );
                  }}
                  className="mon-500-14 text-white  bg-primary-600 "
                  height="40px"
                  width="79px"
                />
              </div>
            </>
          ) : (
            <CustomButton
              text="Delete"
              className="text-white mon-500-14 bg-error-600"
              height="40px"
              width="79px"
              onClick={async () => {
                await disptach(
                  updateGoal({
                    goalId: newGoal.id,
                    isActive: 0,
                    projectId,
                    sprintId: selectedSprint.id,
                    phaseId: currentSelectedPhase.id,
                  })
                );
                onCancel();
                disptach(
                  getGoalsBySprint({ projectId, sprintId: selectedSprint.id })
                );
                await disptach(
                  updateProgress({
                    phaseId: currentSelectedPhase.id,
                    projectId,
                  })
                );
                disptach(getProjectPhases({ projectId }));
                disptach(getProjectDetatis({ projectId }));
              }}
            />
          )}
        </div>
      }
      onCancel={onCancel}
    />
  );
};

export default EditGoalModal;
