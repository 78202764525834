import React, { useState, useEffect } from "react";
import CustomModal from "../../Core/Common/CustomModal";
import CustomButton from "../../Core/Common/CustomButton";
import CustomInputWithEdit from "../../Core/Common/CustomInputWithEdit";
import { Dropdown, Input, Menu, Spin } from "antd";
import { CustomSelectWithInlineSearch } from "../../Core/Common/CustomSelectWithSearch";
import CustomSearch, {
  CustomSearchWithDropDown,
} from "../../Core/Common/CustomSearch";
import CustomAvatar from "../../Core/Common/CustomAvatar";
import { useAppDispatch, useAppSelector } from "../../Core/redux/hooks";
import PhaseIcon from "../../Core/svg/PhaseIcon";
import CustomSelect from "../../Core/Common/CustomSelect";
import { getGoalsFromPhaseId } from "../../Core/utils/vaultUtils";
import { useParams } from "react-router-dom";
import { appendFileSync } from "fs";
import CustomPhaseGoalsSelector from "../../Core/Common/CustomPhaseGoalsSelector";
import { resetVault } from "../../Core/redux/slices/vault.slice";
import { createLink, getAllLinks } from "../../Core/redux/api/vaultAPI";
import copyTextToClipboard from "../../Core/utils/clipboard";

const OpenLinkModal = ({ visible, onCancel, id }) => {
  const [isActive, setIsActive] = useState(true);

  const { projectId } = useParams();
  const [showSpinner, setShowSpinner] = useState(false);
  const dispatch = useAppDispatch();

  const members = [
    {
      id: 1,
      image: "/images/icons/info-circle.svg",
      text: "Kevin Shah",
      textColor: "text-gray-700",
      access: "Owner",
    },
    {
      id: 2,
      image: "/images/icons/download-icon.svg",
      text: "Ganesh Mohanty",
      textColor: "text-gray-700",
      access: "Can edit",
    },
    {
      id: 3,
      image: "/images/icons/trash.svg",
      text: "Kaustubh Bhagwat",
      textColor: "text-gray-700",
      access: "Can view",
    },
  ];

  const { links, folders } = useAppSelector((state) => state.vault);
  const { phases } = useAppSelector((state) => state.schedule);

  const { userList } = useAppSelector((state) => state.overview);
  const { shareAccessDoc } = useAppSelector((state) => state.vault);

  const [selectedUsers, setSelectedUsers] = useState(
    shareAccessDoc ? [...shareAccessDoc.users] : []
  );

  const [isHovering, setIsHovering] = useState(false);
  const handleMouseOver = () => {
    setIsHovering(true);
  };

  const handleMouseOut = () => {
    setIsHovering(false);
  };

  const link = links.filter((l) => {
    return l.id === id;
  })[0];

  console.log("LIMK: ", link);

  const [linkState, setLinkState] = useState({
    linkName: null,
    description: null,
    url: null,
    username: null,
    password: null,
    isActive: null,
    userId: null,
    linkId: null,
    phaseId: null,
    goalId: null,
    folderId: null,
  });

  console.log(link, "link filter");

  const [sprintGoals, setSprintGoals] = useState([]);

  const getGoals = async (phaseId, callback = undefined) => {
    let goals;
    if (phaseId) {
      goals = await getGoalsFromPhaseId({
        phaseId: phaseId.toString(),
        projectId,
      });
    }
    setShowSpinner(false);
    console.log("GOALS", goals);
    setSprintGoals(goals);
    if (typeof callback === "function") callback(goals);
  };

  useEffect(() => {
    if (!visible) return;

    const {
      name,
      username,
      password,
      description,
      url,
      isActive: linkIsActive,
      id: linkId,
      phaseId,
      folderId,
      createdById: userId,
      goalId,
    } = link;
    getGoals(phaseId);

    const initialLinkState = {
      linkName: name,
      description: description,
      url: url,
      username: username,
      password: password,
      isActive: linkIsActive,
      userId: userId,
      linkId: linkId,
      phaseId,
      goalId,
      folderId,
    };

    // const phaseName = phases.filter((p) => p.id === phaseId)[0].phases;
    setLinkState({ ...initialLinkState });
  }, [id, visible]);

  const onPhaseChange = (p) => {
    const phaseId = p.id.toString();
    getGoals(phaseId);
    setLinkState({
      ...linkState,
      phaseId,
    });
  };
  const { user } = useAppSelector((state) => state.userDetails);

  const onSubmit = async () => {
    const {
      linkName,
      description,
      url,
      username,
      password,
      phaseId,
      goalId,
      folderId,
    } = linkState;
    const payload = {
      updateLink: true,
      linkId: link.id,
      linkName: linkName,
      description: description,
      url: url,
      username: username,
      password: password,
      phaseId: phaseId,
      projectId: projectId,
      senderId: user.id,
      goalId,
      folderId,
    };

    console.log(payload);
    await dispatch(createLink(payload as any)).then(() => onCancel());

    // dispatch(resetVault(["addFolderModal"]));
    dispatch(getAllLinks({ projectId }));
  };

  return (
    visible && (
      <CustomModal
        title={
          <div className="flex gap-x-1">
            <span className="mon-400-12 text-gray-500">Vault</span>
            <img className="w-4 h-4" src="/images/icons/chevron-right.svg" />
            <span className="mon-500-12 text-gray-700">{link?.name}</span>
          </div>
        }
        width={"704px"}
        visible={visible}
        body={
          // <Spin spinning={showSpinner}>
          <div className="max-h-[48.189386056191466vh] overflow-y-scroll">
            <div className="p-4">
              <CustomInputWithEdit
                value={linkState?.linkName}
                label="Name of link"
                placeholder="Add a name"
                onChange={(e) =>
                  setLinkState({
                    ...linkState,
                    linkName: e.target.value,
                  })
                }
              />
              <div className="mt-9">
                <div className="flex items-center">
                  <CustomInputWithEdit
                    width="92%"
                    label="URL"
                    placeholder="Eg. https://www.antarcticaglobal.com"
                    onChange={(e) =>
                      setLinkState({
                        ...linkState,
                        url: e.target.value,
                      })
                    }
                    value={linkState.url}
                  />
                  <img
                    src="/images/icons/copy.svg"
                    className="w-5 h-5 mt-5 ml-5"
                    onClick={() => copyTextToClipboard(linkState.url)}
                  />
                </div>
              </div>
              <div className="mon-500-14 text-gray-700 mt-9">
                Description
                <div className="mt-1.5">
                  <Input.TextArea
                    rows={5}
                    value={link?.description}
                    placeholder="Enter a description..."
                    required={true}
                    className="w-full h-full bg-white rounded-xl border border-gray-300 text-gray-500 pl-4 pt-2  active:outline-none"
                  ></Input.TextArea>
                </div>
              </div>
              <CustomPhaseGoalsSelector
                phaseId={linkState.phaseId}
                goalId={linkState.goalId}
                onLoadingChange={(isLoading) => setShowSpinner(isLoading)}
                onChange={(phaseGoalObj) => {
                  console.log("onCHange phaseGoalObj: ", phaseGoalObj, {
                    ...linkState,
                    ...phaseGoalObj,
                  });
                  setLinkState({ ...linkState, ...phaseGoalObj });
                }}
              />

              <div className="mon-500-14 text-gray-700 mt-9">
                Folder (optional)
                <div className="mt-1.5 w-[336px]">
                  <CustomSelect
                    value={linkState.folderId?.toString()}
                    options={folders.map((item) => {
                      return {
                        id: item.id?.toString(),
                        title: item.folderName,
                        render: (value) => (
                          <div
                            className="flex items-center -mt-0.5"
                            onClick={() =>
                              setLinkState({
                                ...linkState,
                                folderId: item.id?.toString(),
                              })
                            }
                          >
                            <img
                              src="/images/icons/folder-fill.svg"
                              className="h-4 w-4"
                            />
                            <p className="mon-400-14 pl-2 text-gray-900 capitalize ">
                              {item.folderName}
                            </p>
                          </div>
                        ),
                      };
                    })}
                    // placeholder="Select Folder"
                  />
                </div>
                <div className="flex justify-between items-center mt-9 border-b-2">
                  <div className="flex justify-center items-center">
                    <img
                      src="/images/icons/lock-02.svg"
                      className="w-5 h-5 mb-2"
                    />
                    <p className="mon-500-14 text-gray-700 pb-2 pl-2">
                      Credentials (optional)
                    </p>
                  </div>

                  <img
                    src="/images/icons/chevron-down-gray.svg"
                    style={{
                      transition: "transform 0.3s ease-in-out 0s",
                      transform: isActive ? "rotate(-180deg)" : "rotate(0deg)",
                    }}
                    onClick={() => setIsActive(!isActive)}
                    className="w-5 h-5"
                  />
                </div>
                {isActive && (
                  <>
                    <div className="mt-5 flex items-center justify-between">
                      <CustomInputWithEdit
                        width="49%"
                        label="Username"
                        placeholder="Eg. SuperFrosty"
                        onChange={(e) =>
                          setLinkState({
                            ...linkState,
                            username: e.target.value,
                          })
                        }
                        value={linkState.username}
                      />
                      <CustomInputWithEdit
                        inputClassName=""
                        width="49%"
                        label="Password"
                        placeholder="Eg. 1234abcde"
                        onChange={(e) =>
                          setLinkState({
                            ...linkState,
                            password: e.target.value,
                          })
                        }
                        value={linkState.password}
                      />
                    </div>
                    <p className="mon-400-14 text-gray-400 pt-3">
                      A username and password can be anything you set to access
                      the link
                    </p>
                  </>
                )}
              </div>
              <div className="mon-500-14 text-gray-700 mt-9">
                <div className="flex items-center">
                  <img src="/images/icons/users-03.svg" />
                  <p className="mon-500-14 text-gray-700 ml-2">Share access</p>
                </div>
                <p className="mt-3 mon-400-14 text-gray-400">
                  Share with your team members and start a thread for easy
                  collaboration.
                  <br />
                  All your collaborators will automatically get added when you
                  start a thread.
                </p>
                <div className="mt-9">
                  <div className="mt-9">
                    <CustomSearchWithDropDown
                      style={{
                        border: "1px solid #C0CBD7",
                        height: "42px",
                      }}
                      placeholder="Search team members"
                      options={userList
                        .filter(
                          (u) =>
                            selectedUsers?.findIndex(
                              (index) => index.userId === u.userId
                            ) === -1 && u.userId !== u.id
                        )
                        .map((u) => {
                          return {
                            id: u.userId,
                            value: u.userId,
                            label: `${u.firstname} ${u.lastname}`,
                            render: (value) => (
                              <div
                                className="flex items-center -mt-0.5"
                                //key={u.userId}
                                onClick={() =>
                                  setSelectedUsers((oldList) => [
                                    ...oldList,
                                    {
                                      userId: u.userId,
                                      isOwner: 0,
                                      canEdit: 0,
                                      canView: 1,
                                    },
                                  ])
                                }
                              >
                                <CustomAvatar
                                  title={u.firstname ? u.firstname : "User"}
                                  src={u.profilePicture}
                                />

                                <p className="ml-2 mon-400-12 text-gray-700 capitalize">
                                  {u.firstname} {u.lastname}
                                </p>
                              </div>
                            ),
                          };
                        })}
                    />
                  </div>
                  <div className="mt-9 px-3">
                    {userList.map((user) => {
                      if (
                        selectedUsers?.findIndex(
                          (u) => user.userId === u.userId
                        ) !== -1
                      ) {
                        return (
                          <div
                            className="flex justify-between items-center py-2.5"
                            key={user.id}
                          >
                            <div className="flex items-center">
                              <CustomAvatar
                                title={`${user.firstname}`}
                                size={24}
                                src={user.profilePicture}
                              />
                              <p className="ml-2 mon-400-12 text-gray-700 capitalize">
                                {user.firstname} {user.lastname}
                              </p>
                            </div>
                            <div className="flex justify-center items-center">
                              <p className="mon-400-12 text-gray-500 mr-4">
                                {selectedUsers.find(
                                  (u) => u.userId === user.userId
                                ).isOwner === 1
                                  ? "Owner"
                                  : selectedUsers.find(
                                      (u) => u.userId === user.userId
                                    ).canEdit
                                  ? "Can edit"
                                  : "Can view"}
                              </p>
                              <Dropdown
                                overlay={
                                  <Menu className="w-[200px] rounded-lg custom-menu-dropdown  shadow-dropdown">
                                    <Menu.Item
                                      className=" py-2.5 px-4 rounded-lg"
                                      onClick={() =>
                                        setSelectedUsers((oldList) => [
                                          ...oldList.map((old) => {
                                            if (old.userId === user.userId) {
                                              return {
                                                ...old,
                                                canEdit: 1,
                                                canView: 1,
                                              };
                                            }
                                            return old;
                                          }),
                                        ])
                                      }
                                    >
                                      <span className="font-normal mb-2 text-gray-900 whitespace-nowrap text-sm">
                                        Can edit
                                      </span>
                                    </Menu.Item>
                                    <Menu.Item
                                      className=" py-2.5 px-4 rounded-lg border-b-[1px]"
                                      onClick={() =>
                                        setSelectedUsers((oldList) => [
                                          ...oldList.map((old) => {
                                            if (old.userId === user.userId) {
                                              return {
                                                ...old,
                                                canEdit: 0,
                                                canView: 1,
                                              };
                                            }
                                            return old;
                                          }),
                                        ])
                                      }
                                    >
                                      <span className="font-normal mb-2 text-gray-900 whitespace-nowrap text-sm">
                                        Can view
                                      </span>
                                    </Menu.Item>
                                    <Menu.Item
                                      className=" py-2.5 px-4 rounded-lg"
                                      onClick={() =>
                                        setSelectedUsers((oldList) => [
                                          ...oldList.filter(
                                            (old) => old.userId !== user.userId
                                          ),
                                        ])
                                      }
                                      onMouseOver={handleMouseOver}
                                      onMouseOut={handleMouseOut}
                                    >
                                      <span
                                        className={`font-normal mb-2  whitespace-nowrap text-sm ${
                                          isHovering
                                            ? "text-gray-900"
                                            : "text-error-600"
                                        }`}
                                      >
                                        Remove access
                                      </span>
                                    </Menu.Item>
                                  </Menu>
                                }
                                trigger={["click"]}
                              >
                                <button>
                                  <img
                                    src="/images/icons/chevron-down-gray.svg"
                                    className="w-5 h-5"
                                  />
                                </button>
                              </Dropdown>
                            </div>
                          </div>
                        );
                      }
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
          // </Spin>
        }
        footer={
          <div className="flex justify-end py-4 gap-x-3 px-3 shadow-upward">
            <CustomButton
              text="Cancel"
              onClick={onCancel}
              className="border border-gray-200 text-gray-700 bg-white mon-500-14"
              height="40px"
              width="81px"
            />
            <CustomButton
              text="Save"
              onClick={onSubmit}
              className="mon-500-14 text-white bg-primary-600 "
              height="40px"
              width="132px"
            />
          </div>
        }
        onCancel={onCancel}
      />
    )
  );
};

export default OpenLinkModal;
