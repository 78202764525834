import React, { useEffect } from "react";
import Header from "../../../Core/Layout/Header";
import { ProjectOnboardingLayout } from "../../../Core/Layout/OnboardingLayouts";
import { Divider, Layout, Skeleton } from "antd";
import Step4 from "./Step4";
import Step1 from "./Step1";
import Step3 from "./Step3";
import Step5 from "./Step5";
import Step6 from "./Step6";
import { useSearchParams } from "react-router-dom";
import Step2 from "./Step2";
import Step7 from "./Step7";
import SkeletonStructure from "./SkeletonStructure";
import SidebarSkeletonStructure from "./SideBarSkeletonStructure";
import { useAppSelector } from "../../../Core/redux/hooks";

const HeaderItem = ({ image, title }) => {
  return (
    <div className="flex ml-10 items-center">
      <div className="w-5 h-5">
        <img className="icon-hover w-full h-full" src={image} />
      </div>

      <div className={`pl-2 text-xs cursor-default font-normal`}>{title}</div>
    </div>
  );
};

const Content = () => {
  const [searchParams, setSearchParams] = useSearchParams("");
  const { currentStep } = useAppSelector((state) => state.projectOnboarding);

  const getCurrentStep = (step) => {
    switch (step) {
      case 1:
        return <Step1 />;
      case 2:
        return <Step2 />;
      case 3:
        return <Step3 />;
      case 4:
        return <Step4 />;
      case 5:
        return <Step5 />;
      case 6:
        return <Step6 />;
      case 7:
        return <Step7 />;
      default:
        return <></>;
    }
  };
  return (
    <div className="p-6 w-full h-full">
      <div className="w-[81px] h-6">
        <img
          className="w-full h-full cursor-pointer"
          src="/images/icons/waddle-logo.svg"
          alt="waddle logo"
        />
      </div>
      {currentStep && getCurrentStep(currentStep)}
    </div>
  );
};

const Sidebar = () => {
  const {
    projectName,
    projectColor,
    clientName,
    clientImage,
    internalProject,
    clientColor,
    projectImage,
  } = useAppSelector((state) => state.projectOnboarding);
  return (
    <div className="absolute top-24 left-8">
      <div className="w-full h-full relative shadow-box">
        <Layout.Header style={{ position: "fixed", zIndex: 20, width: "100%" }}>
          <div className="bg-[#fff] rounded-t-2xl max-h-14 w-full flex justify-between border-b-[1px] border-[#DDE5ED]">
            <div className="flex items-center ml-4">
              <div className="w-[81px] h-6">
                <img
                  className="w-full h-full cursor-pointer"
                  src="/images/icons/waddle-logo.svg"
                  alt="waddle logo"
                />
              </div>

              <HeaderItem
                title={"Dashboard"}
                image={"/images/icons/dashboard-icon.svg"}
              />
              <div className="flex items-center">
                <HeaderItem
                  title={"Projects"}
                  image={"/images/icons/project-blue-icon.svg"}
                />
                <div className="w-4 h-4 ml-3">
                  <img
                    className="w-full h-full cursor-pointer"
                    src="/images/icons/chevron-down.svg"
                    alt="project expand"
                  />
                </div>
              </div>

              <HeaderItem
                title={"Activity Log"}
                image={"/images/icons/activity-log-icon.svg"}
              />
              <HeaderItem
                title={"Conversations"}
                image={"/images/icons/message-chat-square-gray.svg"}
              />
              <HeaderItem
                title={"Notification"}
                image={"/images/icons/notification-icon.svg"}
              />
            </div>

            <div className="flex items-center mr-7">
              <div className="w-5 h-5">
                <img
                  className="w-full h-full"
                  src="/images/icons/calendar-date-icon.svg"
                  alt="Calendar"
                />
              </div>
              <div className="ml-2.5 font-normal text-xs">
                <span className="font-normal text-xs text-primary-600"></span>{" "}
                {` `}
              </div>

              <div className="flex items-center cursor-pointer">
                <div className="w-5 h-5 ml-9 ">
                  <img
                    className="w-full h-full"
                    src={"/images/icons/settings-icon.svg"}
                    alt="settings"
                  />
                </div>
                <div
                  className={`pl-2 font-normal`}
                  style={{ fontSize: "12px" }}
                >
                  Settings
                </div>
              </div>
            </div>
          </div>
        </Layout.Header>
        <div className="flex">
          <div className="h-screen bg-[#fff] w-[200px]">
            <div className="pt-14">
              <SidebarSkeletonStructure
                logo={projectImage}
                projectName={projectName}
                internalProjectName={clientName}
                color={projectColor}
              />
            </div>
          </div>
          <div className="p-6 pt-20">
            <div className="flex items-center">
              <div className="">
                <p className="mon-500-12 text-primary-600">Summary</p>
                <div className="w-[60px] h-[2px] bg-primary-600 rounded-sm"></div>
              </div>
              <p className="ml-8 mon-400-14 text-gray-500">People</p>
            </div>
            <SkeletonStructure
              width={"332px"}
              height={"402px"}
              title={""}
              body={""}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const ProjectSetup = () => {
  return (
    <ProjectOnboardingLayout content={<Content />} sidebar={<Sidebar />} />
  );
};

export default ProjectSetup;
