import React, { useState, useEffect } from "react";
import CustomModal from "../../../Core/Common/CustomModal";
import CustomButton from "../../../Core/Common/CustomButton";
import { Divider, Dropdown, Menu } from "antd";
import { CustomSelectWithInlineSearch } from "../../../Core/Common/CustomSelectWithSearch";
import { CustomSearchWithDropDown } from "../../../Core/Common/CustomSearch";
import CustomAvatar from "../../../Core/Common/CustomAvatar";
import { useAppDispatch, useAppSelector } from "../../../Core/redux/hooks";
import {
  shareAccess,
  getAllLinks,
  getAllFiles,
  getAllFolders,
} from "../../../Core/redux/api/vaultAPI";
import { resetVault } from "../../../Core/redux/slices/vault.slice";
import { useLocation, useParams } from "react-router-dom";
import CustomInputWithEdit from "../../../Core/Common/CustomInputWithEdit";

const ShareAccessModal = ({ visible, onCancel }) => {
  const dispatch = useAppDispatch();
  const { userList } = useAppSelector((state) => state.overview);
  const { user } = useAppSelector((state) => state.userDetails);
  const { shareAccessDoc } = useAppSelector((state) => state.vault);
  const { selectedProject } = useAppSelector((state) => state.projects);

  const [selectedUsers, setSelectedUsers] = useState(
    []
    //shareAccessDoc ? [...shareAccessDoc.users] : []
  );

  useEffect(() => {
    if (shareAccessDoc.users && shareAccessDoc.users.length > 0) {
      setSelectedUsers([...shareAccessDoc.users]);
    }
  }, [shareAccessDoc]);

  console.log(
    selectedUsers,
    "selected users",
    shareAccessDoc.users,
    "share access"
  );

  const [isHovering, setIsHovering] = useState(false);
  const handleMouseOver = () => {
    setIsHovering(true);
  };

  const handleMouseOut = () => {
    setIsHovering(false);
  };

  const { "*": splat } = useParams();
  let { projectId } = useParams();

  const handleSubmit = async () => {
    const payload = {
      docId: shareAccessDoc.docId,
      users: selectedUsers,
      projectId: +projectId,
      docType: shareAccessDoc.docType,
      name:
        shareAccessDoc.docDetails.folderName ||
        shareAccessDoc.docDetails.fileName ||
        shareAccessDoc.docDetails.linkName ||
        shareAccessDoc.docDetails.name,
      notificationId: shareAccessDoc.notificationId,
    };
    //when shareAccessModal is called while using requestacessmodal, projectId is not available in the url, so getting selected projectID
    if (!payload.projectId) {
      payload.projectId = selectedProject;
      projectId = selectedProject;
      console.log(payload, "requessssssssssssssstttttttttttt");
      await dispatch(shareAccess(payload as any)).then(() => onCancel());
      dispatch(resetVault(["shareAccessDoc"]));
      const queryObject: { projectId: string; folderId?: string } = {
        projectId,
      };

      if (splat && splat.length > 0) {
        const folderId = splat.split("-");
        queryObject.folderId = folderId[folderId.length - 1];
      }
      await dispatch(getAllFiles(queryObject));
      await dispatch(getAllLinks(queryObject));
      await dispatch(getAllFolders(queryObject));
    }
    // setSelectedUsers([]);
  };

  const location = useLocation();

  return (
    <CustomModal
      title={
        <div className="flex gap-x-1">
          <span className="mon-400-12 text-gray-500">
            {location.pathname.includes("vault") ? "Vault" : "Conversations"}
          </span>
          <img className="w-4 h-4" src="/images/icons/chevron-right.svg" />
          <span className="mon-500-12 text-gray-700">Share access</span>
        </div>
      }
      width={"704px"}
      visible={visible}
      body={
        <div className="max-h-[48.189386056191466vh] overflow-y-scroll">
          <div className="p-4">
            {/* <div className="mon-500-14 text-gray-700">
              Phases
              <div className="mt-1.5">
                <CustomSelectWithInlineSearch
                  containerStyle={{
                    width: "336px",
                  }}
                  options={[
                    {
                      id: "1",
                      title: "Ongoing",
                      options: [
                        {
                          id: "1",
                          value: "india",
                          render: (value) => (
                            <div className="flex items-center -mt-0.5">
                              <img
                                src="/images/icons/ideation-phase.svg"
                                className="h-4 w-4"
                              />
                              <p className="mon-400-14 pl-2 text-gray-900 capitalize ">
                                Ideation
                              </p>
                            </div>
                          ),
                        },
                      ],
                    },
                    {
                      id: "2",
                      title: "Yet to begin",
                      options: [
                        {
                          id: "1",
                          value: "india1",
                          render: (value) => (
                            <div className="flex items-center -mt-0.5">
                              <img
                                src="/images/icons/design-phase.svg"
                                className="h-4 w-4"
                              />
                              <p className="mon-400-14 pl-2 text-gray-900 capitalize ">
                                Design
                              </p>
                            </div>
                          ),
                        },
                        {
                          id: "2",
                          value: "india2",
                          render: (value) => (
                            <div className="flex items-center -mt-0.5">
                              <img
                                src="/images/icons/development-phase.svg"
                                className="h-4 w-4"
                              />
                              <p className="mon-400-14 pl-2 text-gray-900 capitalize ">
                                Development
                              </p>
                            </div>
                          ),
                        },
                      ],
                    },
                  ]}
                  value="india"
                />
              </div>
            </div> */}
            <CustomInputWithEdit
              label={`Name of ${shareAccessDoc.docType}`}
              placeholder="Add a name"
              value={
                shareAccessDoc.docType === "folder"
                  ? shareAccessDoc.docDetails.folderName
                  : shareAccessDoc.docDetails.name
              }
            />
            <div className="mon-500-14 text-gray-700 mt-9">
              <div className="flex items-center">
                <img src="/images/icons/users-03.svg" />
                <p className="mon-500-14 text-gray-700 ml-2">Share access</p>
              </div>
              <p className="mt-3 mon-400-14 text-gray-400">
                Share with your team members and start a thread for easy
                collaboration.
                <br />
                All your collaborators will automatically get added when you
                start a thread.
              </p>
            </div>
            <div className="mt-9">
              <CustomSearchWithDropDown
                style={{
                  border: "1px solid #C0CBD7",
                  height: "42px",
                }}
                placeholder="Search team members"
                options={userList
                  .filter(({ userId }) => userId !== user.id)
                  .filter(
                    (u) =>
                      selectedUsers?.findIndex(
                        (index) => index.userId === u.userId
                      ) === -1 && u.userId !== u.id
                  )
                  .map((u) => {
                    return {
                      id: u.userId,
                      value: u.userId,
                      label: `${u.firstname} ${u.lastname}`,
                      render: (value) => (
                        <div
                          className="flex items-center -mt-0.5"
                          //key={u.userId}
                          onClick={() =>
                            setSelectedUsers((oldList) => [
                              ...oldList,
                              {
                                userId: u.userId,
                                isOwner: 0,
                                canEdit: 0,
                                canView: 1,
                              },
                            ])
                          }
                        >
                          <CustomAvatar
                            title={u.firstname ? u.firstname : "User"}
                            src={u.profilePicture}
                          />

                          <p className="ml-2 mon-400-12 text-gray-700 capitalize">
                            {u.firstname} {u.lastname}
                          </p>
                        </div>
                      ),
                    };
                  })}
              />
            </div>
            <div className="mt-9 px-3">
              {userList.map((user) => {
                if (
                  selectedUsers?.findIndex((u) => user.userId == u.userId) !==
                  -1
                ) {
                  return (
                    <div
                      className="flex justify-between items-center py-2.5"
                      key={user.id}
                    >
                      <div className="flex items-center">
                        <CustomAvatar
                          title={`${user.firstname}`}
                          size={24}
                          src={user.profilePicture}
                        />
                        <p className="ml-2 mon-400-12 text-gray-700 capitalize">
                          {user.firstname} {user.lastname}
                        </p>
                      </div>
                      <div className="flex justify-center items-center">
                        <p className="mon-400-12 text-gray-500 mr-4">
                          {selectedUsers.find((u) => u.userId === user.userId)
                            .isOwner === 1
                            ? "Owner"
                            : selectedUsers.find(
                                (u) => u.userId === user.userId
                              ).canEdit
                            ? "Can edit"
                            : "Can view"}
                        </p>
                        <Dropdown
                          overlay={
                            <Menu className="w-[200px] rounded-lg custom-menu-dropdown  shadow-dropdown">
                              <Menu.Item
                                className=" py-2.5 px-4 rounded-lg"
                                onClick={() =>
                                  setSelectedUsers((oldList) => [
                                    ...oldList.map((old) => {
                                      if (old.userId === user.userId) {
                                        return {
                                          ...old,
                                          canEdit: 1,
                                          canView: 1,
                                        };
                                      }
                                      return old;
                                    }),
                                  ])
                                }
                              >
                                <span className="font-normal mb-2 text-gray-900 whitespace-nowrap text-sm">
                                  Can edit
                                </span>
                              </Menu.Item>
                              <Menu.Item
                                className=" py-2.5 px-4 rounded-lg "
                                onClick={() =>
                                  setSelectedUsers((oldList) => [
                                    ...oldList.map((old) => {
                                      if (old.userId === user.userId) {
                                        return {
                                          ...old,
                                          canEdit: 0,
                                          canView: 1,
                                        };
                                      }
                                      return old;
                                    }),
                                  ])
                                }
                              >
                                <span className="font-normal mb-2 text-gray-900 whitespace-nowrap text-sm">
                                  Can view
                                </span>
                              </Menu.Item>
                              <Divider style={{ margin: 0 }} />
                              <Menu.Item
                                className=" py-2.5 px-4 rounded-lg"
                                onClick={() =>
                                  setSelectedUsers((oldList) => [
                                    ...oldList.filter(
                                      (old) => old.userId !== user.userId
                                    ),
                                  ])
                                }
                                onMouseOver={handleMouseOver}
                                onMouseOut={handleMouseOut}
                              >
                                <span
                                  className={`font-normal mb-2  whitespace-nowrap text-sm ${
                                    isHovering
                                      ? "text-gray-900"
                                      : "text-error-600"
                                  }`}
                                >
                                  Remove access
                                </span>
                              </Menu.Item>
                            </Menu>
                          }
                          trigger={["click"]}
                        >
                          <button>
                            <img
                              src="/images/icons/chevron-down-gray.svg"
                              className="w-5 h-5"
                            />
                          </button>
                        </Dropdown>
                      </div>
                    </div>
                  );
                }
              })}
            </div>
          </div>
        </div>
      }
      footer={
        <div className="flex justify-end py-4 gap-x-3 px-3 shadow-upward">
          <CustomButton
            text="Cancel"
            onClick={onCancel}
            className="border border-gray-200 text-gray-700 bg-white mon-500-14"
            height="40px"
            width="81px"
          />
          <CustomButton
            text="Save"
            onClick={handleSubmit}
            className="mon-500-14 text-white bg-primary-600 "
            height="40px"
            width="132px"
          />
        </div>
      }
      onCancel={onCancel}
    />
  );
};

export default ShareAccessModal;
