// export function createNotificationSound() {
//   //   const sound = document.createElement("audio");
//   //   sound.style.cssText += `display: none;`;
//   //   sound.id = "notifierSound";
//   //   sound.src = "https://dxstmhyqfqr1o.cloudfront.net/sound/SendBird-default.mp3";
//   //   return sound;
//   const RINGTONE_MP3 =
//     "https://link.ap1.storjshare.io/s/jxqpzikfjbsd7deh3qwfzxudujrq/mvp-api-test/contents/profile-pictures/relax_message_tone_laxjshwy.mp3-1669443895535.mp3?download=1";
//   const RINGTONE_MR4 =
//     "https://link.ap1.storjshare.io/s/jxqpzikfjbsd7deh3qwfzxudujrq/mvp-api-test/contents/profile-pictures/relax_message_tone_laxjshwz.m4r-1669443895576.m4r?download=1";
//   const RINGTONE_OGG =
//     "https://link.ap1.storjshare.io/s/jxqpzikfjbsd7deh3qwfzxudujrq/mvp-api-test/contents/profile-pictures/relax_message_tone_laxjshx0.ogg-1669443895557.ogg?download=1";
//   const audio = new Audio(RINGTONE_OGG);
//   audio.play();
//   audio.remove();
// }

import axios from "axios";
import JSZip from "jszip";
import { extensions } from "./fileExtentions";

export function debounce<F extends (...args: any[]) => void>(
  fn: F,
  ms: number,
  shouldRunFirst = true,
  shouldRunLast = true
) {
  let waitingTimeout: number | undefined;

  return (...args: Parameters<F>) => {
    if (waitingTimeout) {
      clearTimeout(waitingTimeout);
      waitingTimeout = undefined;
    } else if (shouldRunFirst) {
      return fn(...args);
    }

    // eslint-disable-next-line no-restricted-globals
    waitingTimeout = self.setTimeout(() => {
      if (shouldRunLast) {
        return fn(...args);
      }

      waitingTimeout = undefined;
    }, ms);
  };
}

export async function playNotifySound() {
  const audio = new Audio("/Audio/notification2.mp3");

  audio.setAttribute("mozaudiochannel", "notification");
  console.log("audio [PUSH]");
  try {
    await audio.play();
  } catch (error) {
    // eslint-disable-next-line no-console
    console.warn("[PUSH] Unable to play notification sound");
  }
}

export const playNotifySoundDebounced = debounce(
  playNotifySound,
  1000,
  true,
  false
);

export function xssEscape(target) {
  if (typeof target === "string") {
    return target
      .split("&")
      .join("&amp;")
      .split("#")
      .join("&#35;")
      .split("<")
      .join("&lt;")
      .split(">")
      .join("&gt;")
      .split('"')
      .join("&quot;")
      .split("'")
      .join("&apos;")
      .split("+")
      .join("&#43;")
      .split("-")
      .join("&#45;")
      .split("(")
      .join("&#40;")
      .split(")")
      .join("&#41;")
      .split("%")
      .join("&#37;");
  } else {
    return target;
  }
}

// TODO: nothing

export async function downloadFiles(fileUrls) {
  // Check if there is only one file to download
  if (fileUrls.length === 1) {
    // Download the single file directly
    const singleFile = fileUrls[0];
    // Make an HTTP request to the server to retrieve the file data
    const response = await axios.request({
      url: singleFile,
      method: "get",
      responseType: "blob", // receive the file data as a Blob object
    });
    // Check that the response was successful
    if (response.status !== 200) {
      throw new Error(`Failed to download file: ${response.statusText}`);
    }
    console.log(response);
    // Get the file type from the Content-Type header
    const fileType = response.headers["content-type"];
    // Get the file extension from the extensions mapping
    const fileExtension = extensions[fileType] || "bin";
    // Extract the file name from the Content-Disposition header
    const fileNameMatch = /filename="(.+)"/.exec(
      response.headers["content-disposition"]
    );
    // console.log(fileNameMatch  , response)
    const fileName = fileNameMatch
      ? fileNameMatch[1]
      : fileUrls[0].split("/").pop()
      ? fileUrls[0].split("/").pop()
      : "file";
    // Create a new Blob object containing the file data
    const fileBlob = new Blob([response.data]);
    // Create a URL that can be used to download the file
    const fileUrl = URL.createObjectURL(fileBlob);
    // Create an a element to trigger the file download
    const downloadLink = document.createElement("a");
    // Set the download attribute on the a element to specify the file name and extension
    downloadLink.download = `${fileName}.${fileExtension}`;
    // Set the href attribute on the a element to the file URL
    downloadLink.href = fileUrl;
    // Append the a element to the DOM
    document.body.appendChild(downloadLink);
    // Click the a element to trigger the file download
    downloadLink.click();
    // Remove the a element from the DOM
    downloadLink.remove();
  }
  // else {
  //   // Download multiple files as a zip archive
  //   // Create a new JSZip object to store the files
  //   const zip = new JSZip();
  //   // Make HTTP requests to the server to retrieve the file data
  //   const fileResponses = await Promise.all(
  //     fileUrls.map((fileUrl) =>
  //       axios.request({
  //         url: fileUrl,
  //         method: "get",
  //         responseType: "blob", // receive the file data as a Blob object
  //       })
  //     )
  //   );
  //   // Add the file data to the zip archive
  //   fileResponses.forEach((response, index) => {
  //     // Check that the response was successful
  //     if (response.status !== 200) {
  //       throw new Error(`Failed to download file: ${response.statusText}`);
  //     }
  //     // Get the file name from the file URL
  //     const fileName = fileUrls[index].split("/").pop();
  //     // Add the file data to the zip archive
  //     zip.file(fileName, response.data);
  //   });
  //   // Generate the zip archive data as a Blob object
  //   const zipBlob = await zip.generateAsync({ type: "blob" });
  //   // Create a URL that can be used to download the zip archive
  //   const zipUrl = URL.createObjectURL(zipBlob);
  //   // Create an a element to trigger the zip archive download
  //   const downloadLink = document.createElement("a");
  //   // Set the download attribute on the a element to specify the zip archive name
  //   downloadLink.download = "files.zip";
  //   // Set the href attribute on the a element to the zip archive URL
  //   downloadLink.href = zipUrl;
  //   // Append the a element to the DOM
  //   document.body.appendChild(downloadLink);
  //   // Click the a element to trigger the zip archive download
  //   downloadLink.click();
  //   // Remove the a element from the DOM
  //   downloadLink.remove();
  // }
  else {
    // Download multiple files one by one
    for (let i = 0; i < fileUrls.length; i++) {
      // Make an HTTP request to the server to retrieve the file data
      const response = await axios.request({
        url: fileUrls[i],
        method: "get",
        responseType: "blob", // receive the file data as a Blob object
      });
      // Check that the response was successful
      if (response.status !== 200) {
        throw new Error(`Failed to download file: ${response.statusText}`);
      }
      // Get the file type from the Content-Type header
      const fileType = response.headers["content-type"];
      // Get the file extension from the extensions mapping
      const fileExtension = extensions[fileType] || "bin";
      // Get the file name from the file URL
      const fileName = fileUrls[i].split("/").pop();
      // Create a new Blob object containing the file data
      const fileBlob = new Blob([response.data]);
      // Create a URL that can be used to download the file
      const fileUrl = URL.createObjectURL(fileBlob);
      // Create an a element to trigger the file download
      const downloadLink = document.createElement("a");
      // Set the download attribute on the a element to specify the file name and extension
      downloadLink.download = `${fileName}.${fileExtension}`;
      // Set the href attribute on the a element to the file URL
      downloadLink.href = fileUrl;
      // Append the a element to the DOM
      document.body.appendChild(downloadLink);
      // Click the a element to trigger the file download
      downloadLink.click();
      // Remove the a element from the DOM
      downloadLink.remove();
    }
  }
}

export function removeDuplicates(arr, key) {
  const uniqueItems = {};
  const result = [];

  for (const obj of arr) {
    if (!uniqueItems[obj[key]]) {
      uniqueItems[obj[key]] = true;
      result.push(obj);
    }
  }

  return result;
}
