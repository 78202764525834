import { Modal } from "antd";
import React, { ReactNode } from "react";

type CustomModalProps = {
  visible?: boolean;

  onCancel: any;
  title?: ReactNode | string;
  body?: ReactNode | string;
  //   footer?: ReactNode | string;
  height?: string;
  width?: string;
  header?: boolean;
  menuDropdown?: ReactNode | boolean;
  footer?: ReactNode | boolean;
  mask?: boolean;
  maskStyle?: any;
};

const CustomModal = ({
  visible = false,

  onCancel,
  title,
  body,
  footer,
  height,
  width,
  header,
  menuDropdown,
  mask,
  maskStyle,
}: CustomModalProps) => {
  if (!visible) {
    return <></>;
  }
  return (
    <Modal
      open={visible}
      mask={mask}
      maskStyle={maskStyle}
      centered
      // zIndex={50}
      width={width}
      destroyOnClose
      //footer={footer}
      modalRender={() => (
        <div
          className="w-full h-full bg-white rounded-2xl "
          style={{
            pointerEvents: "all",
            //   maxHeight: height,
            //   minHeight: height,
            maxWidth: width,
            //   minWidth: width,
            //   overflowY: "scroll",
          }}
        >
          <div className="border-gray-200 border-b-[1px] flex  justify-between px-4 py-4">
            <div className="truncate">{title}</div>
            {/* {title} */}
            <div className="flex items-center gap-x-2">
              {menuDropdown}
              <button onClick={() => onCancel()}>
                <img src="/images/icons/x-close.svg" />
              </button>
            </div>
          </div>
          {body}
          {footer}
        </div>
      )}
    />
  );
};

export default CustomModal;
