import React, { useState, useEffect } from "react";

import { updateConversations } from "../../../Core/redux/slices/conversations.slice";

import { getComments } from "../../../Core/redux/api/conversationAPI";
import MainChatWindow from "../../Conversations/MainChatWindow";
import ConversationChatInput from "../../Conversations/ConversationChatInput";
import { useAppDispatch, useAppSelector } from "../../../Core/redux/hooks";

import io from "socket.io-client";
import { updateVault } from "../../../Core/redux/slices/vault.slice";

const Comments = ({ docId, docType, visible, user: notifyUser }) => {
  const { user } = useAppSelector((state) => state.userDetails);
  // const { projectId } = useParams();

  // conection to socket
  const socket = io(`${process.env.REACT_APP_SOCKET_URL}`, {
    transports: ["websocket"],
    path: "/conversation-socket",
    auth: {
      projectId: 161,
      userId: user.id,
    },
    autoConnect: false,
  });

  const dispatch = useAppDispatch();

  const { openCommentsModal } = useAppSelector((state) => state.vault);

  useEffect(() => {
    dispatch(
      updateVault({
        key: "openCommentsModal",
        value: {
          docType: docType,
          docId: docId,
          name: "schedule",
          comments: [],
          users: notifyUser,
        },
      })
    );
  }, []);

  const handelGetComments = async () => {
    await dispatch(
      getComments({
        docId,
        docType,
        limit: 1000,
        offset: 0,
      })
    );
  };
  useEffect(() => {
    if (visible) {
      handelGetComments();
    }
  }, [visible]);
  return (
    <div className="w-full bg-white overflow-hidden flex flex-col content-between justify-between">
      <ConversationChatInput comments />
      <MainChatWindow socket={socket} comments />
    </div>
  );
};

export default Comments;
