import React from "react";
import OtpInput from "react-otp-input";

type Props = {
  value: string;
  onChange: (value) => void;
  className?: string;
  name?: string;
  showError?: boolean;
  errorText?: string;
  formikHook?: any;
  helperText?: string;
};

const CustomOTPInput = ({
  value,
  onChange,
  className,
  formikHook: formik,
  name,
  showError,
  errorText,
  helperText,
}: Props) => {
  if (formik && name) {
    showError = showError || (formik.touched[name] && !!formik.errors[name]);
    errorText = errorText || (formik.touched[name] && formik.errors[name]);
    // valid =
    //   valid ||
    //   (!formik.errors[name] &&
    //     formik.initialValues[name] !== formik.values[name])
  }

  return (
    <div className={className}>
      <OtpInput
        value={value}
        onChange={onChange}
        numInputs={6}
        containerStyle="hello"
        inputStyle="custom-otp-input mon-500-30 text-primary-600 "
      />
      {showError && (
        <div className="mt-1.5 mon-400-14 text-left text-error-500">
          {errorText}
        </div>
      )}
      {helperText && (
        <div className="mt-1.5 mon-400-14 text-left text-error-500">
          {helperText}
        </div>
      )}
    </div>
  );
};

CustomOTPInput.defaultProps = {
  className: "",
};

export default CustomOTPInput;
