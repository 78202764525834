import axios from "axios";
import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";
import { client } from "../Core/utils/axiosClient";

const firebaseConfig = {
  apiKey: "AIzaSyAycPcVV3tf9UuUs2s_UAjhXUAleaDlRt0",
  authDomain: "waddle-28e14.firebaseapp.com",
  databaseURL: "https://waddle-28e14-default-rtdb.firebaseio.com",
  projectId: "waddle-28e14",
  storageBucket: "waddle-28e14.appspot.com",
  messagingSenderId: "136400982224",
  appId: "1:136400982224:web:14aad4b24ae62a302c0ed5",
  measurementId: "G-L5Q07M2M54",
};

const app = initializeApp(firebaseConfig);

const messaging = getMessaging(app);

export function requestPermission(setFCMTokenFound?: (val: boolean) => void) {
  const user = JSON.parse(localStorage.getItem("user"));
  if (!user) {
    console.log("[PUSH]:user is not signed in.");
    return;
  }
  console.log("[PUSH]:Requesting permission...");
  // Check if the browser supports notifications
  if (!("Notification" in window)) {
    alert("This browser does not support desktop notifications.");
  } else if (Notification.permission !== "denied") {
    Notification.requestPermission().then((permission) => {
      if (permission === "granted") {
        console.log("[PUSH]:Notification permission granted.");
        new Notification("Desktop notifications for waddle are enabled!");
        getToken(messaging, {
          vapidKey:
            "BBZxmoxTsjOD9TeehXyXH2dPM88cZmMKCHddbyqlo4d_sVl7g0PNtNXvAPFj_qVNMyNa6TWXmSNUeC_yO31OhBU",
        }).then((currentToken) => {
          if (currentToken) {
            console.log("[PUSH]:currentToken: ", currentToken);
            if (setFCMTokenFound && typeof setFCMTokenFound === "function") {
              setFCMTokenFound(true);
            }
            client.post("/auth/fcm-token", {
              id: user.id,
              fcmToken: currentToken,
            });
          } else {
            console.log("[PUSH]:Can not get token");
            if (setFCMTokenFound && typeof setFCMTokenFound === "function") {
              setFCMTokenFound(false);
            }
          }
        });
      } else {
        console.log("[PUSH]:Do not have permission!");
        if (setFCMTokenFound && typeof setFCMTokenFound === "function") {
          setFCMTokenFound(false);
        }
      }
    });
  } else {
    alert("You have denied permission to show desktop notifications.");
  }
}

// requestPermission();

// export const onMessageListener = () =>
//   new Promise((resolve) => {
//     messaging.onMessage((payload) => {
//       resolve(payload);
//     });
//   });
