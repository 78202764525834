import React, { useEffect, useState } from "react";
import CustomModal from "../../../../src/Core/Common/CustomModal";
import CustomButton from "../../../../src/Core/Common/CustomButton";
import CustomInputWithEdit from "../../../Core/Common/CustomInputWithEdit";
import { DatePicker, Divider, Dropdown, Menu, Progress } from "antd";
import { UpcomingGoalItem } from "../../Overview/Components/UpcomingGoals";
import { useAppDispatch, useAppSelector } from "../../../Core/redux/hooks";
import dayjs from "dayjs";
import {
  getGoalsBySprint,
  getSprintsByPhase,
  updateSprint,
} from "../../../Core/redux/api/scheduleAPI";
import { useParams, useSearchParams } from "react-router-dom";
import {
  setSelectedSprint,
  updateSchedule,
} from "../../../Core/redux/slices/schedule.slice";
import TaskTypeIcon from "../../../Core/svg/TaskTypeIcon";
import DeleteSprintModal from "./DeleteSprintModal";
import CustomAvatar from "../../../Core/Common/CustomAvatar";
import CustomInputWithSaveAndEdit from "../../../Core/Common/CustomInputWithSaveAndEdit";
import CompleteSprintModal from "./CompleteSprintModal";
import StartSprintModal from "./StartSprintModal";
import { setError } from "../../../Core/redux/slices/global.slice";

type SprintDetailsModalProps = {
  visible: boolean;
  onCancel: () => void;
  sprint?: {
    id: string;
    name: string;
    endDate: string;
    startDate: string;
    goals?: any[];
    createdById?: string;
    createdAt?: string;
    status?: number;
  };
};
const SprintDetailsModal = ({
  visible,
  onCancel,
  sprint,
}: SprintDetailsModalProps) => {
  const [oldSprint, setOldSprint] = useState(sprint);
  const [newSprint, setNewSprint] = useState(sprint);
  const dispatch = useAppDispatch();
  const { projectId } = useParams();
  const { currentSelectedPhase, selectedSprint } = useAppSelector(
    (state) => state.schedule
  );
  const [searchParams, setSearchParams] = useSearchParams();
  // const [deleteSprintModal, setDeleteSprintModal] = useState(false);
  const { deleteSprintModal, completeSprintModal, startSprintModal } =
    useAppSelector((state) => state.schedule);
  const { schedule } = useAppSelector((state) => state.projects);
  const { userList } = useAppSelector((state) => state.overview);
  const phaseAndSprints = schedule?.[1]?.[0];
  const [phaseSelected, setPhaseSelected] = useState(true);

  //TODO: should we remove this useEffect as goals are already comming from api
  useEffect(() => {
    if (visible) {
      dispatch(getGoalsBySprint({ projectId, sprintId: sprint.id }));
    }
  }, [sprint.id, visible]);

  console.log(sprint, "sprint nlcudes status");
  const getGoalsCompletePercentage = () => {
    const goals = sprint.goals ? sprint.goals : [];
    const totalGoals = goals.length;
    const completedGoals = goals.filter(
      (g) => g.isCompleted || g.statusId == 3
    ).length;

    if (totalGoals === 0 || completedGoals === 0) return 0;
    return Math.round((completedGoals / totalGoals) * 100);
  };

  const hasChanged = () => {
    return (
      newSprint.name !== sprint.name ||
      newSprint.startDate !== sprint.startDate ||
      newSprint.endDate !== sprint.endDate
    );
  };

  const getStatus = (statusId) => {
    return (
      <div className="flex items-center">
        {statusId === 1 ? (
          <div className="flex items-center bg-gray-100 rounded-2xl">
            <p className="mon-400-12 text-gray-700 px-2 uppercase py-0.5">
              To Do
            </p>
          </div>
        ) : statusId === 2 ? (
          <div className="flex items-center max-w-max whitespace-nowrap bg-primary-600 rounded-2xl">
            <p className="mon-400-12 text-white px-2 uppercase py-0.5">
              In progress
            </p>
          </div>
        ) : statusId === 3 ? (
          <div className="flex items-center max-w-max whitespace-nowrap bg-success-600 rounded-2xl">
            <p className="mon-400-12 text-white px-2 uppercase py-0.5">Done</p>
          </div>
        ) : (
          <div className="flex items-center bg-gray-100 rounded-2xl">
            <p className="mon-400-12 text-gray-700 px-2 uppercase py-0.5">
              To Do
            </p>
          </div>
        )}
      </div>
    );
  };

  const getUserDetails = (id) => {
    if (!id) return null;
    return userList.find((u) => +u.userId === +id);
  };

  const createdByUser = getUserDetails(sprint.createdById);
  return (
    <>
      <CustomModal
        title={
          <div className="flex gap-x-1">
            <span className="mon-400-12 text-gray-500">
              {currentSelectedPhase.phases}
            </span>
            <img className="w-4 h-4" src="/images/icons/chevron-right.svg" />
            <span className="mon-500-12 text-gray-700">{sprint.name}</span>
          </div>
        }
        menuDropdown={
          phaseAndSprints?.delete === 1 && (
            <div
              className="pr-2 cursor-pointer"
              onClick={() => {
                dispatch(
                  updateSchedule({
                    key: "deleteSprintModal",
                    value: {
                      visible: true,
                      sprintId: sprint.id,
                    },
                  })
                );
              }}
            >
              <img
                src="/images/icons/trash-03.svg"
                className="w-5 h-5 "
                alt="add-item"
              />
            </div>
          )
        }
        width={"1064px"}
        height={"608px"}
        visible={visible}
        body={
          <div className="max-h-[48.189386056191466vh] h-[608px] max-w-[1064px] w-[90vw]  px-5 py-5">
            <div className="flex justify-between ">
              <div className="flex-[2]">
                <CustomInputWithSaveAndEdit
                  width="94%"
                  value={newSprint.name || ""}
                  inputClassName="mon-600-20 text-black"
                  borderOnHover={false}
                  onChange={(e) => {
                    setNewSprint({ ...newSprint, name: e.target.value });
                  }}
                  placeholder="Sprint Name"
                  onOk={async () => {
                    await dispatch(
                      updateSprint({
                        sprintId: sprint.id,
                        title: newSprint.name,
                      })
                    );
                  }}
                />
                <div className="mt-5">
                  {phaseAndSprints?.update === 1 && (
                    <DatePicker.RangePicker
                      // disabledDate={(d) => !d || d.isAfter(moment()) || d.isSameOrBefore(moment().subtract(1, 'Y'))}
                      style={{ height: "36px", maxWidth: "140px" }}
                      //size="large"
                      separator={
                        <span className="mon-400-12 text-gray-700 px-2">-</span>
                      }
                      suffixIcon={<></>}
                      allowClear={false}
                      format="DD MMM"
                      className=" custom-range-picker border-0 w-full bg-gray-50 rounded-xl hover:outline-none active:outline-none mon-400-12 text-gray-700"
                      value={
                        newSprint.startDate !== null
                          ? [
                              dayjs(newSprint.startDate) as any,
                              dayjs(newSprint.endDate) as any,
                            ]
                          : ("" as any)
                      }
                      onChange={(val) =>
                        setNewSprint({
                          ...newSprint,
                          startDate: dayjs(val[0] as any).format("YYYY-MM-DD"),
                          endDate: dayjs(val[1] as any).format("YYYY-MM-DD"),
                        })
                      }
                    />
                  )}
                  <div className="mon-500-16 text-black mt-9 pl-0.5">
                    Activity
                  </div>
                  {createdByUser && (
                    <div className="flex justify-between w-full mt-4">
                      <div className="flex items-center">
                        <CustomAvatar
                          src={createdByUser.profilePicture}
                          title={createdByUser.firstname}
                          size={18}
                          fontSize={10}
                        />
                        <p className="mon-400-12 text-primary-600 ml-1.5">
                          {createdByUser.firstname} {createdByUser.lastname}
                        </p>
                      </div>
                      <p className="mon-400-12 text-gray-500 mr-10">
                        Created{" "}
                        <span className="mon-500-12 text-primary-600">
                          {sprint.name}
                        </span>{" "}
                        on{" "}
                        {sprint.createdAt
                          ? dayjs(sprint.createdAt).format("DD MMM YYYY")
                          : "N/A"}
                      </p>
                    </div>
                  )}
                </div>
              </div>
              <div className="w-[50%] ">
                <div className="bg-white flex items-center rounded-xl h-10 ">
                  <div className="text-gray-700 mon-500-14 pr-3 -mb-[2px]">
                    Progress
                  </div>
                  <div className="w-[90%]">
                    <Progress
                      percent={getGoalsCompletePercentage()}
                      status="success"
                      strokeWidth={4}
                      showInfo={false}
                      trailColor="#000000"
                      strokeColor="#165ABF"
                    />
                  </div>
                  <div className="pl-3 mon-500-14 text-gray-700 -mb-[2px] mr-5">
                    {getGoalsCompletePercentage()}%
                  </div>
                  {/* <button
                                  onClick={() => {
                                    onCancel();
                                    dispatch(
                                      updateSchedule({ key: "newGoalModal", value: true })
                                      );
                                      dispatch(
                                        updateSchedule({ key: "selectedSprint", value: sprint })
                                        );
                                      }}
                                      >
                                      <img src="/images/icons/plus-blue.svg" />
                                    </button> */}
                </div>
                <div className="mt-6 border rounded-2xl min-h-[36vh] max-h-[36vh] overflow-y-scroll">
                  <div className="flex justify-between py-2 px-4 border-b items-center">
                    <p className="mon-500-10 text-gray-500 uppercase">Type</p>
                    <p className="mon-500-10 text-gray-500 uppercase">Status</p>
                  </div>
                  {sprint.goals && sprint.goals.length ? (
                    sprint.goals?.map((goal) => (
                      <div
                        className="px-4 flex justify-between py-3.5"
                        key={goal.id}
                      >
                        <div className="mon-500-12 text-gray-900 flex items-center gap-x-2">
                          <TaskTypeIcon
                            type={goal.isNewGoal ? "newscope" : +goal.goalType}
                            width="16"
                            height="16"
                          />
                          <span>{goal.name}</span>
                        </div>
                        {getStatus(goal.statusId)}
                      </div>
                    ))
                  ) : (
                    <div className="flex justify-center items-center m-4 flex-col py-4 rounded-xl bg-primary-50">
                      <p className="mon-600-12 text-gray-700">
                        Add tasks, events, bugs to this board
                      </p>
                      <p className="mon-400-12 text-gray-700 mt-3">
                        Start this sprint to add items{" "}
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        }
        footer={
          <div
            className=" flex justify-end py-4 gap-x-3 px-3 shadow-upward z-10 bg-white relative"
            style={{
              borderBottomRightRadius: "16px",
              borderBottomLeftRadius: "16px",
            }}
          >
            {sprint.status == 3 ? (
              <>
                <div
                  className={
                    "flex items-center justify-center border-[1px] border-gray-200 shadow-xs text-primary-blue bg-white mon-500-14 mr-3  mon-500-14 rounded-xl opacity-50 "
                  }
                  style={{ height: "40px", width: "149px" }}
                >
                  {"Completed"}
                </div>
              </>
            ) : (
              <CustomButton
                text={
                  // phaseSelected
                  //   ? selectedSprint?.status !== 2
                  //     ? "Start Sprint"
                  //     : "Complete Sprint"
                  //   : currentSelectedPhase?.isCompleted === 1 ||
                  //     currentSelectedPhase?.isOngoing === 0
                  //   ? "Start Phase"
                  //   : "End Phase"

                  sprint?.status == 1
                    ? "Start Sprint"
                    : sprint.status == 2
                    ? "Complete Sprint"
                    : "Completed"
                }
                // onClick={() => {
                //   dispatch(updateSchedule({ key: "allactiveSprints", value: [] }));
                //   setAllPhase(!allPhase);
                // }}
                className="border-[1px] border-gray-200 shadow-xs text-primary-blue bg-white mon-500-14 mr-3"
                height="40px"
                width={sprint?.status !== 2 ? "114px" : "149px"}
                onClick={() => {
                  dispatch(setSelectedSprint(sprint));
                  sprint.status == 2
                    ? dispatch(
                        updateSchedule({
                          key: "completeSprintModal",
                          value: {
                            visible: true,
                            sprintId: "1",
                          },
                        })
                      )
                    : dispatch(
                        updateSchedule({
                          key: "startSprintModal",
                          value: true,
                        })
                      );

                  onCancel();
                }}
              />
            )}

            <CustomButton
              text="View Details"
              onClick={() => {
                const updatedSearchParams = new URLSearchParams(
                  searchParams.toString()
                );
                updatedSearchParams.set("selectedSprint", sprint.id);
                setSearchParams(updatedSearchParams.toString());
                dispatch(setSelectedSprint(sprint));
                onCancel();
              }}
              className="mon-500-14 text-white bg-primary-600 "
              height="40px"
              width="120px"
            />
          </div>
        }
        onCancel={onCancel}
      />
    </>
  );
};
export default SprintDetailsModal;
