import { createSlice } from "@reduxjs/toolkit";
import { getProjectDetatis, markSpoc } from "../api/projectAPI";
import { updateProgress } from "../api/scheduleAPI";

const initialState = {
  loading: false,
  error: null,
  status: "idle",
  projectDetails: {
    id: null,
    title: "",
    progress: "0",
    startDate: "0000-00-00",
    endDate: "0000-00-00",
    clientName: "",
    projectColor: "",
    projectImage: "",
    clientImage: "",
    clientColor: "",
    colorCode: "",
    internalProject: false,
    clientLocation: "",
    isEnded: 0,
  },
  userList: [],
};

export const overviewSlice = createSlice({
  name: "overview",
  initialState,
  reducers: {
    resetToInitialOverview: (state, action) => {
      Object.keys(initialState).forEach(
        (key) => (state[key] = initialState[key])
      );
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getProjectDetatis.pending, (state, { payload }) => {
      state.loading = true;
      state.status = "pending";
    });
    builder.addCase(getProjectDetatis.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.status = "fulfilled";
      state.projectDetails = payload.projectDetails;
      state.userList = payload.userList;
      state.error = null;
    });
    builder.addCase(getProjectDetatis.rejected, (state, { payload }) => {
      state.loading = false;
      state.status = "rejected";
      state.error = payload;
    });
    builder.addCase(updateProgress.pending, (state, { payload }) => {
      state.loading = true;
      state.status = "pending";
    });
    builder.addCase(updateProgress.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.status = "fulfilled";
      state.projectDetails.progress = payload.totalProjectProgress;

      state.error = null;
    });
    builder.addCase(updateProgress.rejected, (state, { payload }) => {
      state.loading = false;
      state.status = "rejected";
      state.error = payload;
    });
    builder.addCase(markSpoc.pending, (state, { payload }) => {
      state.loading = true;
      state.status = "pending";
    });
    builder.addCase(markSpoc.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.status = "fulfilled";
      state.userList = state.userList.map((user) => {
        if (+user.userId === +payload.result.userId) {
          return { ...user, spoc: payload.result.spoc };
        }
        return user;
      });
      state.error = null;
    });
    builder.addCase(markSpoc.rejected, (state, { payload }) => {
      state.loading = false;
      state.status = "rejected";
      state.error = payload;
    });
    // active phases in project
  },
});
export const { resetToInitialOverview } = overviewSlice.actions;
export default overviewSlice.reducer;
