import React, { useEffect, useState } from "react";
import { Row, Col, DatePicker } from "antd";
import CustomModal from "../../../Core/Common/CustomModal";
import CustomButton from "../../../Core/Common/CustomButton";
import { useAppDispatch, useAppSelector } from "../../../Core/redux/hooks";
import { getProjectPhases } from "../../../Core/redux/api/scheduleAPI";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";
import { setupPhases } from "../../../Core/redux/api/projectAPI";

const SetupPhaseModal = ({ visible, onCancel, onOk }) => {
  const dispatch = useAppDispatch();
  const { projectId } = useParams();

  const getPhase = async () => {
    await dispatch(getProjectPhases({ projectId }));
  };

  const { phases } = useAppSelector((state) => state.schedule);
  const [allPhases, setAllPhases] = useState(phases);

  useEffect(() => {
    if (visible && phases.length !== 0) {
      getPhase();
      setAllPhases([...phases]);
    }
  }, [visible]);

  const getValue = (id, key) => {
    const val = allPhases.find((p) => +p.phaseId === +id)?.[key];
    return val !== null && typeof val !== "undefined"
      ? (dayjs(val) as any)
      : "";
  };

  const setDates = (id, val) => {
    const startDate = dayjs(val[0]).format("YYYY-MM-DD");
    const endDate = dayjs(val[1]).format("YYYY-MM-DD");
    const oldState = [...allPhases];
    const newState = oldState.map((p) => {
      if (+p.phaseId === +id) {
        return {
          ...p,
          startDate,
          endDate,
        };
      } else {
        return { ...p };
      }
    });
    setAllPhases([...newState]);
    console.log(newState, "newState");
  };

  const isPhaseThere = (id) => {
    const val = phases.findIndex((p) => +p.phaseId === +id);
    if (val === -1) return false;
    return true;
  };
  return (
    <CustomModal
      title={<p className="mon-500-16 text-black">Setup Phases</p>}
      width={"704px"}
      visible={visible}
      body={
        <div className="max-h-[48.189386056191466vh] overflow-y-scroll">
          <Row>
            <Col span={20}>
              <div className="flex items-center px-5 pt-5">
                <img
                  src="/images/icons/frosty-help.svg"
                  className="h-9 w-9"
                  alt="frosty"
                />
                <p className="mon-500-14 text-primary-600 ml-2">
                  You’ve created a new project! Add colleagues to collaborate on
                  this project.
                </p>
              </div>
              <div className="pb-5">
                {isPhaseThere(1) && (
                  <div className="px-5  mt-9">
                    <div className="flex items-center">
                      <img
                        src="/images/icons/ideation-phase.svg"
                        alt="ideation"
                      />
                      <p className="mon-500-14 text-black pl-3">Ideation</p>
                    </div>
                    <div className="mon-500-14 text-gray-700 pt-4 pb-1.5">
                      Duration
                    </div>
                    <DatePicker.RangePicker
                      className="w-full h-10 bg-transparent custom-range-picker-for-phase border-gray-300 rounded-xl shadow-xs"
                      suffixIcon={<></>}
                      format="DD MMM YY"
                      separator={
                        <span className="mon-400-14 text-gray-500 px-0">-</span>
                      }
                      allowClear={true}
                      value={[getValue(1, "startDate"), getValue(1, "endDate")]}
                      onChange={(e) => {
                        setDates(1, e);
                      }}
                    />
                  </div>
                )}
                {isPhaseThere(2) && (
                  <div className="px-5  mt-9">
                    <div className="flex items-center">
                      <img src="/images/icons/design-phase.svg" alt="Design" />
                      <p className="mon-500-14 text-black pl-3">Design</p>
                    </div>
                    <div className="mon-500-14 text-gray-700  pt-4 pb-1.5">
                      Duration
                    </div>
                    <DatePicker.RangePicker
                      className="w-full h-10 bg-transparent custom-range-picker-for-phase border-gray-300 rounded-xl shadow-xs"
                      suffixIcon={<></>}
                      format="DD MMM YY"
                      //value={}
                      separator={
                        <span className="mon-400-14 text-gray-500 px-0">-</span>
                      }
                      value={[getValue(2, "startDate"), getValue(2, "endDate")]}
                      onChange={(e) => {
                        setDates(2, e);
                      }}
                    />
                  </div>
                )}
                {isPhaseThere(3) && (
                  <div className="px-5  mt-9">
                    <div className="flex items-center">
                      <img
                        src="/images/icons/development-phase.svg"
                        alt="Development"
                      />
                      <p className="mon-500-14 text-black pl-3">Development</p>
                    </div>
                    <div className="mon-500-14 text-gray-700  pt-4 pb-1.5">
                      Duration
                    </div>
                    <DatePicker.RangePicker
                      className="w-full h-10 bg-transparent custom-range-picker-for-phase border-gray-300 rounded-xl shadow-xs"
                      suffixIcon={<></>}
                      format="DD MMM YY"
                      //value={}
                      separator={
                        <span className="mon-400-14 text-gray-500 px-0">-</span>
                      }
                      value={[getValue(3, "startDate"), getValue(3, "endDate")]}
                      onChange={(e) => {
                        setDates(3, e);
                      }}
                    />
                  </div>
                )}
                {isPhaseThere(4) && (
                  <div className="px-5  mt-9">
                    <div className="flex items-center">
                      <img
                        src="/images/icons/development-phase.svg"
                        alt="deployment"
                      />
                      <p className="mon-500-14 text-black pl-3">Deployment</p>
                    </div>
                    <div className="mon-500-14 text-gray-700  pt-4 pb-1.5">
                      Duration
                    </div>
                    <DatePicker.RangePicker
                      className="w-full h-10 bg-transparent custom-range-picker-for-phase border-gray-300 rounded-xl shadow-xs"
                      suffixIcon={<></>}
                      format="DD MMM YY"
                      //value={}
                      separator={
                        <span className="mon-400-14 text-gray-500 px-0">-</span>
                      }
                      value={[getValue(4, "startDate"), getValue(4, "endDate")]}
                      onChange={(e) => {
                        setDates(4, e);
                      }}
                    />
                  </div>
                )}
                <div className="px-5  mt-9">
                  <div className="flex items-center">
                    <img
                      src="/images/icons/development-phase.svg"
                      alt="deployment"
                    />
                    <p className="mon-500-14 text-black pl-3">Maintenance</p>
                  </div>
                  <div className="mon-500-14 text-gray-700  pt-4 pb-1.5">
                    Not added to this project
                  </div>
                </div>
              </div>
            </Col>
            <Col span={4} />
          </Row>
        </div>
      }
      footer={
        <div className="flex justify-end py-4 gap-x-3 px-3 shadow-upward">
          <CustomButton
            text="Cancel"
            onClick={onCancel}
            className="border border-gray-200 text-gray-700 bg-white mon-500-14"
            height="40px"
            width="81px"
          />
          <CustomButton
            text="Setup Phases"
            onClick={async () => {
              await dispatch(
                setupPhases({
                  projectId,
                  phases: allPhases.map((p) => {
                    return {
                      id: p.phaseId,
                      startDate: p.startDate,
                      endDate: p.endDate,
                    };
                  }),
                })
              );
              onCancel();
              dispatch(getProjectPhases({ projectId }));
            }}
            className="mon-500-14 text-white bg-primary-600 "
            height="40px"
            width="132px"
          />
        </div>
      }
      onCancel={onCancel}
    />
  );
};

export default SetupPhaseModal;
