import { Divider, Dropdown, Menu } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getNotifications } from "../../Core/redux/api/notificationAPI";
import { useAppDispatch, useAppSelector } from "../../Core/redux/hooks";
import { updateNotification } from "../../Core/redux/slices/notification.slice";
import { client } from "../../Core/utils/axiosClient";
import NotificationComponent from "./NotificationComponent";

const NotificationUnreadCount = ({ unreadCount }) => {
  return (
    <div className="flex justify-center items-center w-5 h-5 rounded-md bg-error-500">
      <p className="text-center text-[10px] font-bold text-white">
        {unreadCount}
      </p>
    </div>
  );
};

// const BreadCrumbComponent = ({ fieldNames }) => {
//   return (
//     <div className="flex items-center gap-x-2">
//       {fieldNames.map((field, index) => (
//         <div className="flex items-center gap-x-2" key={field.id}>
//           <p className="mon-400-10 text-gray-500 cursor-pointer">
//             {field.value}
//           </p>
//           {fieldNames.length !== index + 1 && (
//             <img src="/images/icons/chevron-right.svg" className="w-3 h-3" />
//           )}
//         </div>
//       ))}
//     </div>
//   );
// };

const GeneralTabComponent = ({ dropdownValue, notifications }) => {
  console.log(notifications, "notifications general tab check 1");

  // console.log(projectTitle, " title check");
  return (
    <>
      <div className="max-h-[calc(100vh-190px)] overflow-y-scroll ">
        {notifications
          ?.filter(({ general }) => general === 1)
          ?.filter(
            ({ notificationType, id }) =>
              (dropdownValue === "Assigned" &&
                (notificationType === "2" ||
                  notificationType === "9" ||
                  notificationType === "24")) ||
              ((dropdownValue === "All" || dropdownValue === "Mentions") && id)
          )
          .map((item, index) => (
            <div key={item.id}>
              <div className="px-5">
                {/* <BreadCrumbComponent
                  fieldNames={[
                    { id: 1, value: "Ideation" },
                    { id: 2, value: "Ideation sprint 2" },
                    { id: 3, value: "Task 1" },
                  ]}
                /> */}
              </div>
              <NotificationComponent
                notificationType={item.notificationType}
                notificationObject={item}
                notificationSubDetails={true}
              />
              {notifications.length !== index + 1 && (
                <div className="mt-2 px-4">
                  <Divider />
                </div>
              )}
            </div>
          ))}
      </div>
    </>
  );
};

const RequestTabComponent = ({ dropdownValue, notifications }) => {
  console.log(notifications, "notifications request tab");
  return (
    <div className="max-h-[calc(100vh-190px)] overflow-y-scroll">
      {notifications
        ?.filter(({ general }) => general === 0)
        .filter(
          ({ notificationType, id, isResolved }) =>
            (dropdownValue === "Approved" &&
              (notificationType === "16" ||
                notificationType === "17" ||
                isResolved === 1)) ||
            (dropdownValue === "Denied" && notificationType === "21") ||
            (dropdownValue === "Pending" &&
              notificationType === "19" &&
              isResolved === 0) ||
            (dropdownValue === "All" && id)
        )
        .map((item, index) => (
          <div key={item.id}>
            <div className="px-5">
              {/* <BreadCrumbComponent
                fieldNames={[
                  { id: 1, value: "Ideation" },
                  { id: 2, value: "Ideation sprint 2" },
                  { id: 3, value: "Task 1" },
                ]}
              /> */}
              {/* //21 denied
//19 created req -- pending
//16 17 has shared access -- approved */}
            </div>
            <NotificationComponent
              notificationType={item.notificationType}
              notificationObject={item}
              notificationSubDetails={true}
            />
            {notifications.length !== index + 1 && (
              <div className="mt-2 px-4">
                <Divider />
              </div>
            )}
          </div>
        ))}
    </div>
  );
};

const NotificationBody = ({
  notificationPopup,
}: {
  notificationPopup: boolean;
}) => {
  const navigate = useNavigate();
  const { notificationList, notificationTab } = useAppSelector(
    (state) => state.notification
  );
  const [currentTab, setCurrentTab] = useState(
    notificationTab !== "" ? notificationTab : "General"
  );
  const [dropdownValue, setDropdownValue] = useState("All");
  const dispatch = useAppDispatch();

  const { user } = useAppSelector((state) => state.userDetails);

  const generalNotificationCount = notificationList.filter(
    (data) => data.general === 1 && data.isUnread
  ).length;

  const requestNotificationCount = notificationList.filter(
    (data) => data.general !== 1 && data.isUnread
  ).length;

  //console.log(generalNotificationCount, "unread count for general");

  const readNotification = async () => {
    await client.post(`/notifications/read?userId=${user.id}`);
    dispatch(getNotifications({ userId: user.id }));
  };

  useEffect(() => {
    readNotification();
    dispatch(
      updateNotification({
        key: "notificationTab",
        value: "General",
      })
    );
  }, []);

  return (
    <div>
      <div
        className={`flex justify-between items-center m-6 ${
          notificationPopup && "pt-0"
        }`}
      >
        <div className="flex items-center gap-x-4">
          <div className="flex items-center gap-x-2">
            <div
              onClick={() => {
                setCurrentTab("General");
                setDropdownValue("All");
              }}
              className={`cursor-pointer ${
                currentTab === "General"
                  ? "mon-500-12 text-primary-600 border-b-2 border-primary-600"
                  : "mon-400-12 text-gray-500 border-b-2 border-transparent hover:text-primary-600"
              }`}
            >
              General
            </div>
            {generalNotificationCount > 0 && (
              <NotificationUnreadCount unreadCount={generalNotificationCount} />
            )}
          </div>
          <div className="flex items-center gap-x-2">
            <div
              onClick={() => {
                setCurrentTab("Request");
                setDropdownValue("All");
              }}
              className={` cursor-pointer ${
                currentTab === "Request"
                  ? "mon-500-12 text-primary-600 border-b-2 border-primary-600"
                  : "mon-400-12 text-gray-500 border-b-2 border-transparent hover:text-primary-600"
              }`}
            >
              Request
            </div>
            {requestNotificationCount > 0 && (
              <NotificationUnreadCount unreadCount={requestNotificationCount} />
            )}
          </div>
        </div>
        {notificationPopup ? (
          <p
            className="mon-500-14 text-gray-500 whitespace-nowrap cursor-pointer"
            onClick={() => navigate("/notifications")}
          >
            View all
          </p>
        ) : (
          <Dropdown
            overlay={
              <Menu className="w-[200px] rounded-lg custom-menu-dropdown bg-error-600 shadow-dropdown">
                <Menu.Item
                  className="py-2.5 px-4"
                  onClick={() => setDropdownValue("All")}
                >
                  <div className="flex items-center justify-between gap-y-1 pt-1.5">
                    <p className="mon-400-12 whitespace-nowrap text-gray-900">
                      All
                    </p>
                    {dropdownValue === "All" && (
                      <img src="/images/icons/check.svg" alt="" />
                    )}
                  </div>
                </Menu.Item>
                {currentTab === "General" ? (
                  <>
                    <Menu.Item
                      className="py-2.5 px-4"
                      onClick={() => setDropdownValue("Mentions")}
                    >
                      <div className="flex items-center justify-between gap-y-1">
                        <p className="mon-400-12 whitespace-nowrap text-gray-900">
                          Mentions
                        </p>
                        {dropdownValue === "Mentions" && (
                          <img src="/images/icons/check.svg" alt="" />
                        )}
                      </div>
                    </Menu.Item>
                    <Menu.Item
                      className="py-2.5 px-4"
                      onClick={() => setDropdownValue("Assigned")}
                    >
                      <div className="flex items-center justify-between gap-y-1">
                        <p className="mon-400-12 whitespace-nowrap text-gray-900">
                          Assigned
                        </p>
                        {dropdownValue === "Assigned" && (
                          <img src="/images/icons/check.svg" alt="" />
                        )}
                      </div>
                    </Menu.Item>
                  </>
                ) : (
                  <>
                    <Menu.Item
                      className="py-2.5 px-4"
                      onClick={() => setDropdownValue("Pending")}
                    >
                      <div className="flex items-center justify-between gap-y-1">
                        <p className="mon-400-12 whitespace-nowrap text-gray-900">
                          Pending
                        </p>
                        {dropdownValue === "Pending" && (
                          <img src="/images/icons/check.svg" alt="" />
                        )}
                      </div>
                    </Menu.Item>
                    <Menu.Item
                      className="py-2.5 px-4"
                      onClick={() => setDropdownValue("Approved")}
                    >
                      <div className="flex items-center justify-between gap-y-1">
                        <p className="mon-400-12 whitespace-nowrap text-gray-900">
                          Approved
                        </p>
                        {dropdownValue === "Approved" && (
                          <img src="/images/icons/check.svg" alt="" />
                        )}
                      </div>
                    </Menu.Item>
                    <Menu.Item
                      className="py-2.5 px-4"
                      onClick={() => setDropdownValue("Denied")}
                    >
                      <div className="flex items-center justify-between gap-y-1">
                        <p className="mon-400-12 whitespace-nowrap text-gray-900">
                          Denied
                        </p>
                        {dropdownValue === "Denied" && (
                          <img src="/images/icons/check.svg" alt="" />
                        )}
                      </div>
                    </Menu.Item>
                  </>
                )}
              </Menu>
            }
            trigger={["click"]}
          >
            <div className="flex items-center cursor-pointer gap-x-3 mr-3">
              <p className="mon-500-14 text-gray-500 whitespace-nowrap">
                {dropdownValue}
              </p>
              <img
                src="/images/icons/chevron-down-gray.svg"
                className="w-4 h-4"
              />
            </div>
          </Dropdown>
        )}
      </div>
      <div className="px-4">
        {currentTab === "General" ? (
          <GeneralTabComponent
            dropdownValue={dropdownValue}
            notifications={
              notificationList?.filter((n) => n.notificationType != "14") || []
            }
          />
        ) : (
          <RequestTabComponent
            dropdownValue={dropdownValue}
            notifications={
              notificationList?.filter((n) => n.notificationType != "14") || []
            }
          />
        )}
      </div>
    </div>
  );
};

export default NotificationBody;
