import React, { useEffect, useState } from "react";
import CustomModal from "../../../Core/Common/CustomModal";
import CustomButton from "../../../Core/Common/CustomButton";
import { DatePicker, Radio, Space } from "antd";
import CustomSelect from "../../../Core/Common/CustomSelect";
import TaskTypeIcon from "../../../Core/svg/TaskTypeIcon";
import { useAppDispatch, useAppSelector } from "../../../Core/redux/hooks";
import {
  getGoalsBySprint,
  getSprintsByPhase,
  updateSprint,
} from "../../../Core/redux/api/scheduleAPI";
import { resetSchedule } from "../../../Core/redux/slices/schedule.slice";
import { sliceEventStore } from "@fullcalendar/react";
import dayjs from "dayjs";
import { useParams, useSearchParams } from "react-router-dom";

type CompleteSprintModalProps = {
  visible: boolean;
  onCancel: () => void;
  onOk: () => void;
};

const CompleteSprintModal = ({
  visible,
  onCancel,
  onOk,
}: CompleteSprintModalProps) => {
  const { swimlanes } = useAppSelector((state) => state.schedule);
  const [value, setValue] = useState(null);

  const handleSelect = (e) => {
    setValue(e);
  };

  const [currentStep, setCurrentStep] = useState(1);
  const [searchParams, setSearchParams] = useSearchParams();

  const dispatch = useAppDispatch();

  const { currentSelectedPhase, goals } = useAppSelector(
    (state) => state.schedule
  );
  const { projectId } = useParams();

  const handleCompleteSprint = async () => {
    if (currentStep === 1) {
      setCurrentStep(2);
      return;
    }

    const payload = {
      sprintId: selectedSprint.id,
      isCompleted: 1,
      isOngoing: 0,
      status: 3,
      newSprintId: value,
      swimlaneId: swimlanes.find((s) => s.statusId == 3)?.id,
    };

    //console.log(payload, "payload sprint delete");

    await dispatch(updateSprint(payload));
    await dispatch(
      getSprintsByPhase({
        projectId,
        phaseId: currentSelectedPhase.id,
      })
    );
    onCancel();

    await dispatch(
      getGoalsBySprint({ projectId, sprintId: selectedSprint.id })
    );

    dispatch(resetSchedule(["deleteSprintModal"]));
  };

  const getStatus = (statusId) => {
    return (
      <div className="flex items-center">
        {statusId === 1 ? (
          <div className="flex items-center bg-gray-100 rounded-2xl">
            <p className="mon-400-12 text-gray-700 px-2 uppercase py-0.5">
              To Do
            </p>
          </div>
        ) : statusId === 2 ? (
          <div className="flex items-center max-w-max whitespace-nowrap bg-primary-600 rounded-2xl">
            <p className="mon-400-12 text-white px-2 uppercase py-0.5">
              In progress
            </p>
          </div>
        ) : statusId === 3 ? (
          <div className="flex items-center max-w-max whitespace-nowrap bg-success-600 rounded-2xl">
            <p className="mon-400-12 text-white px-2 uppercase py-0.5">Done</p>
          </div>
        ) : (
          <div className="flex items-center bg-gray-100 rounded-2xl">
            <p className="mon-400-12 text-gray-700 px-2 uppercase py-0.5">
              To Do
            </p>
          </div>
        )}
      </div>
    );
  };

  const { sprints, selectedSprint } = useAppSelector((state) => state.schedule);
  console.log(
    selectedSprint?.goals?.filter(({ isActive, statusId }) => statusId < 3),
    "selected sprint check"
  );

  let activeGoals = [];
  if (searchParams.has("selectedSprint")) {
    activeGoals = goals.filter(({ isActive, statusId }) => statusId < 3);
  } else {
    activeGoals = selectedSprint?.goals?.filter(
      ({ isActive, statusId }) => statusId < 3
    );
  }

  console.log(sprints, "active goals check");

  useEffect(() => {
    if (activeGoals.length === 0) {
      setCurrentStep(2);
    } else {
      setCurrentStep(1);
    }
  }, [activeGoals.length]);

  const isDisabled = activeGoals.length > 0 ? !value : false;
  return (
    <CustomModal
      title={<p className="mon-500-16 text-gray-700">{selectedSprint.name}</p>}
      width={"524px"}
      visible={visible}
      body={
        <div className="max-h-[38.189386056191466vh] overflow-y-scroll">
          {activeGoals.length > 0 && currentStep === 1 ? (
            <>
              <div className=" px-5">
                <div className="mt-6 border border-[#FECDCA]  rounded-2xl overflow-hidden h-[226px]">
                  <div className="text-gray-500 mon-400-14 px-4 pt-[10px] pb-[9px] bg-error-50">
                    You have unfinished items in{" "}
                    <span className="mon-500-14 text-gray-900">
                      {selectedSprint.name}
                    </span>
                  </div>
                  <div className=" flex justify-between py-2 px-4 border-b items-center">
                    <p className="mon-500-10 text-gray-500 uppercase">Type</p>
                    <p className="mon-500-10 text-gray-500 uppercase">Status</p>
                  </div>
                  <div className="h-[calc(226px-74px)] overflow-y-scroll">
                    {activeGoals?.map(
                      ({ name, goalType, isNewGoal, statusId }, index) => (
                        <div
                          className="px-4 flex justify-between py-3.5"
                          key={index}
                        >
                          <div className="flex items-center gap-x-2">
                            <TaskTypeIcon
                              type={`${isNewGoal ? "newscope" : "task"}`}
                              width="16"
                              height="16"
                            />
                            <p className="mon-500-12 text-gray-900">{name}</p>
                          </div>
                          {getStatus(+statusId)}
                        </div>
                      )
                    )}
                  </div>
                </div>
              </div>
              <div className="mon-400-14 text-gray-500 pt-6 px-5">
                Select a sprint to move all the items of this sprint
              </div>
              <div className="pt-4 px-5 ">
                <div>
                  <p className="mon-500-14 text-gray-900">Select a sprint</p>
                  <div className="mt-2 mb-2">
                    <CustomSelect
                      placeholder="Select a sprint"
                      value={value}
                      border={true}
                      options={sprints
                        ?.filter(
                          (s) => s.status < 3 && +s.id != +selectedSprint.id
                        )
                        ?.map((s) => {
                          return {
                            id: s.id,
                            value: s.id,
                            render: () => (
                              <div className="flex justify-between items-center">
                                <p className="text-gray-700 mon-400-12">
                                  {s.name}
                                </p>
                                {s.name === value && (
                                  <img src="/images/icons/check.svg" alt="" />
                                )}
                              </div>
                            ),
                          };
                        })}
                      onChange={(e) => handleSelect(e)}
                    />
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div className="px-4 py-4">
              <p className="mon-400-14 text-gray-500">
                Are you sure you want to complete this sprint today?{" "}
              </p>
              <div className="mt-6 mx-1">
                <div className="mon-500-14 text-gray-700 pb-1.5">
                  Start date
                </div>
                <DatePicker
                  placeholder="dd/mm/yyyy"
                  className="w-full h-10 bg-transparent border-gray-300 rounded-xl shadow-xs"
                  suffixIcon={<></>}
                  format="DD MMM YYYY"
                  value={
                    selectedSprint.startDate
                      ? (dayjs(selectedSprint.startDate) as any)
                      : null
                  }
                  // disabledDate={(current) =>
                  //   ((current && current > (dayjs() as any)) || "") as any
                  // }
                  // onChange={(e) =>
                  //   setSprintDates({
                  //     ...sprintDates,
                  //     startDate: e as any,
                  //   })
                  // }
                  disabled
                />
              </div>
              <div className=" mt-6 mx-1">
                <div className="mon-500-14 text-gray-700 pb-1.5">End date</div>
                <DatePicker
                  placeholder="dd/mm/yyyy"
                  className="w-full h-10 bg-transparent border-gray-300 rounded-xl shadow-xs"
                  suffixIcon={<></>}
                  format="DD MMM YYYY"
                  value={dayjs() as any}
                  // onChange={(e) =>
                  //   setSprintDates({
                  //     ...sprintDates,
                  //     endDate: e as any,
                  //   })
                  // }
                  // disabledDate={(current) =>
                  //   ((current && current < (dayjs() as any)) || "") as any
                  // }
                  disabled
                />
              </div>
            </div>
          )}
        </div>
      }
      footer={
        <div className="flex justify-between pt-8 pb-4 px-3">
          <CustomButton
            text="Cancel"
            onClick={onCancel}
            className="border-2 border-gray-200 text-black bg-white mon-500-16 "
            height="44px"
            width="49%"
          />
          <CustomButton
            text={currentStep === 2 ? "Complete Sprint" : "Next"}
            onClick={() => handleCompleteSprint()}
            className="mon-500-14 text-white bg-primary-600 "
            height="44px"
            width="49%"
            disabled={isDisabled}
          />
        </div>
      }
      onCancel={onCancel}
    />
  );
};

export default CompleteSprintModal;
