import React from "react";
import CustomModal from "../../../Core/Common/CustomModal";
import CustomButton from "../../../Core/Common/CustomButton";
import { useAppDispatch, useAppSelector } from "../../../Core/redux/hooks";
import {
  deleteFileInConv,
  getPrivateMessages,
  getThreadMessages,
} from "../../../Core/redux/api/conversationAPI";

type DeleteFileModalProps = {
  visible: boolean;
  onCancel: () => void;
};

const DeleteFileModal = ({ visible, onCancel }: DeleteFileModalProps) => {
  const { deleteFileInMessage, currentThread } = useAppSelector(
    (state) => state.conversations
  );

  const { user } = useAppSelector((state) => state.userDetails);

  const dispatch = useAppDispatch();

  const handelDelete = async () => {
    if (deleteFileInMessage) {
      await dispatch(deleteFileInConv(deleteFileInMessage));
      if (currentThread.type === "group") {
        dispatch(
          getThreadMessages({
            threadId: currentThread.threadId,
            limit: 20,
            offset: 0,
          })
        );
      } else {
        dispatch(
          getPrivateMessages({
            userId: user.id,
            reciverId: +currentThread.userId,
            limit: 20,
            offset: 0,
          })
        );
      }
    }
    onCancel();
  };
  return (
    <CustomModal
      title={<p className="mon-500-16 text-error-600">Delete file</p>}
      width={"524px"}
      visible={visible}
      body={
        <div className="max-h-[38.189386056191466vh] overflow-y-scroll">
          <div className="mon-400-14 text-gray-500 pt-6 pb-4 px-5">
            Are you sure you want to delete this file? This action cannot be
            undone
          </div>
        </div>
      }
      footer={
        <div className="flex justify-between pt-6 pb-4 px-3">
          <CustomButton
            text="Cancel"
            onClick={onCancel}
            className="border-2 border-gray-200 text-black bg-white mon-500-16 "
            height="44px"
            width="49%"
          />
          <CustomButton
            text="Yes, Delete"
            onClick={handelDelete}
            className="mon-500-16 text-white  bg-error-600 "
            height="44px"
            width="49%"
          />
        </div>
      }
      onCancel={onCancel}
    />
  );
};

export default DeleteFileModal;
