import { env } from "process";
import React from "react";
import GoogleLogin from "react-google-login";
import { client } from "../../../../Core/utils/axiosClient";

const Integrations = () => {
  const responseGoogle = async (response) => {
    console.log(response);

    const { data } = await client.post("/integration/calender/create-tokens", {
      code: response.code,
    });
    console.log(data);
  };

  const responseError = (error) => {
    console.log(error);
  };
  return (
    <div className=" px-4 pt-6">
      <p className="mon-400-12 text-gray-500">
        Integrate with existing tools to make your workflow easier
      </p>
      <p className="mon-500-14 text-gray-900 mt-6">
        Connect your Google calendar account
      </p>

      <div className="w-52">
        <GoogleLogin
          clientId={process.env.REACT_APP_CLIENT_ID}
          render={(renderProps) => (
            <button
              onClick={renderProps.onClick}
              disabled={renderProps.disabled}
              className="my-4 flex justify-between items-center gap-x-1.5 w-52 border rounded-xl border-primary-gray p-2.5"
            >
              <img src="/images/icons/Googlecalendar.svg" alt="" />
              <p className="mon-500-14 text-primary-900">Google Calendar</p>
              <img src="/images/icons/plus-gray.svg" alt="" />
            </button>
          )}
          onSuccess={responseGoogle}
          onFailure={responseError}
          cookiePolicy={"single_host_origin"}
          responseType="code"
          accessType="offl{ine"
          scope={process.env.REACT_APP_SCOPE}
          uxMode="redirect"
        />
      </div>
      <p className="mon-400-12 text-gray-500 w-1/3">
        With this integration you can easily manage your events, get reminders
        on upcoming events and create new events directly from here.
      </p>
    </div>
  );
};

export default Integrations;
