import { Dropdown, Menu } from "antd";
import React, { useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import CustomAvatar from "../../../Core/Common/CustomAvatar";
import CustomBadge from "../../../Core/Common/CustomBadge";
import CustomButton from "../../../Core/Common/CustomButton";
import { getGoalsBySprint } from "../../../Core/redux/api/scheduleAPI";
import { useAppDispatch, useAppSelector } from "../../../Core/redux/hooks";
import {
  resetSchedule,
  updateSchedule,
} from "../../../Core/redux/slices/schedule.slice";
import PhaseIcon from "../../../Core/svg/PhaseIcon";
import PriorityIcon, {
  PriorityIconWithLabel,
} from "../../../Core/svg/PriorityIcon";

const Status = ({ statusId }) => {
  switch (statusId) {
    case 1:
      return (
        <div className="w-[60px] bg-gray-100 rounded-2xl">
          <p className="text-gray-700 uppercase whitespace-nowrap px-2">
            To do
          </p>
        </div>
      );
    case 2:
      return (
        <div className="w-[115px] bg-primary-600 rounded-2xl">
          <p className="text-white uppercase whitespace-nowrap px-2">
            In progress
          </p>
        </div>
      );
    case 3:
      return (
        <div className="w-[60px] bg-success-600 rounded-2xl">
          <p className="text-white uppercase whitespace-nowrap px-2">Done</p>
        </div>
      );
    default:
      return (
        <div className="w-[56px] bg-gray-100 rounded-2xl">
          <p className="text-gray-700 uppercase whitespace-nowrap px-2 py-0.5 ">
            To do
          </p>
        </div>
      );
  }
};

const FilterItem = ({ label, dropdown }: { label: string; dropdown?: any }) => {
  const dispatch = useAppDispatch();
  const { filters } = useAppSelector((state) => state.schedule);

  const [assignee, setAssignee] = useState([]);
  const [priority, setPriority] = useState([]);
  const [phase, setPhase] = useState([]);
  const [reporter, setReporter] = useState([]);
  const [status, setStatus] = useState([]);
  const [category, setCategory] = useState([]);
  const [component, setComponent] = useState([]);

  const handleExist = (arr, setArr, arrName, value) => {
    if (arr.includes(value)) {
      const newArr = arr.filter((item) => item !== value);
      setArr([...newArr]);
      dispatch(
        updateSchedule({
          key: "filters",
          value: { [arrName]: [...newArr] },
        })
      );
    } else {
      setArr([...arr, value]);
      dispatch(
        updateSchedule({
          key: "filters",
          value: { ...filters, [arrName]: [...filters[arrName], value] },
        })
      );
    }
  };

  console.log(filters, " filter list check");

  switch (label) {
    case "assignee":
      return (
        <div>
          <p className="mon-500-14 text-gray-700 capitalize">{label}</p>
          <div>
            <Dropdown
              overlay={
                <Menu className="w-[200px] rounded-lg custom-menu-dropdown bg-error-600 shadow-dropdown">
                  <Menu.Item
                    className="pt-2.5 px-4"
                    onClick={() => setAssignee([])}
                  >
                    <p className="mon-500-12 text-gray-500">Clear selection</p>
                  </Menu.Item>
                  <Menu.Item className="px-2">
                    {dropdown?.map((item) => (
                      <div
                        className="flex items-center justify-between pt-2"
                        key={item.userId}
                        onClick={() =>
                          handleExist(assignee, setAssignee, "assignee", item)
                        }
                      >
                        <div className="flex items-center gap-x-2">
                          <CustomAvatar
                            title={`${item.firstname} ${item.lastname}`}
                            size={24}
                            src={item?.profilePicture}
                          />
                          <p className="mon-400-12 whitespace-nowrap text-gray-900">
                            {`${item.firstname} ${item.lastname}`}
                          </p>
                        </div>
                        <>
                          {assignee?.includes(item) && (
                            <img src="/images/icons/check.svg" alt="" />
                          )}
                        </>
                      </div>
                    ))}
                  </Menu.Item>
                </Menu>
              }
              trigger={["click"]}
            >
              <div
                className="flex items-center justify-between cursor-pointer border-[1px] border-gray-300 rounded-xl gap-x-3 px-[14px] mt-[6px]"
                style={{ width: "230px", height: "40px" }}
              >
                <p className="mon-400-14 text-gray-500 whitespace-nowrap">
                  {assignee.length === 0 ? (
                    <>Select team member</>
                  ) : (
                    <div className="max-w-max">
                      {assignee.map((item) => (
                        <CustomAvatar
                          title={`${item.firstname} ${item.lastname}`}
                          size={24}
                          src={item?.profilePicture}
                          key={item.userId}
                        />
                      ))}
                    </div>
                  )}
                </p>
                {assignee.length === 0 ? (
                  <img
                    src="/images/icons/chevron-down-gray.svg"
                    className="w-4 h-4 mt-0.5"
                  />
                ) : (
                  <></>
                )}
              </div>
            </Dropdown>
          </div>
        </div>
      );
    case "reporter":
      return (
        <div>
          <p className="mon-500-14 text-gray-700 capitalize">{label}</p>
          <div>
            <Dropdown
              overlay={
                <Menu className="w-[200px] rounded-lg custom-menu-dropdown bg-error-600 shadow-dropdown">
                  <Menu.Item
                    className="pt-2.5 px-4"
                    onClick={() => setReporter([])}
                  >
                    <p className="mon-500-12 text-gray-500">Clear selection</p>
                  </Menu.Item>
                  <Menu.Item className="px-2">
                    {dropdown?.map((item) => (
                      <div
                        className="flex items-center justify-between gap-y-1 pt-2"
                        key={item.userId}
                        onClick={() =>
                          handleExist(reporter, setReporter, "reporter", item)
                        }
                      >
                        <div className="flex items-center gap-x-2">
                          <CustomAvatar
                            title={`${item.firstname} ${item.lastname}`}
                            size={24}
                            src={item?.profilePicture}
                          />
                          <p className="mon-400-12 whitespace-nowrap text-gray-900">
                            {`${item.firstname} ${item.lastname}`}
                          </p>
                        </div>
                        <>
                          {reporter?.includes(item) && (
                            <img src="/images/icons/check.svg" alt="" />
                          )}
                        </>
                      </div>
                    ))}
                  </Menu.Item>
                </Menu>
              }
              trigger={["click"]}
            >
              <div
                className="flex items-center justify-between cursor-pointer border-[1px] border-gray-300 rounded-xl gap-x-3 px-[14px] mt-[6px]"
                style={{ width: "230px", height: "40px" }}
              >
                <p className="mon-400-14 text-gray-500 whitespace-nowrap">
                  {reporter.length === 0 ? (
                    <>Select team member</>
                  ) : (
                    <div className="max-w-max">
                      {reporter.map((item) => (
                        <CustomAvatar
                          title={`${item.firstname} ${item.lastname}`}
                          size={24}
                          src={item?.profilePicture}
                          key={item.userId}
                        />
                      ))}
                    </div>
                  )}
                </p>
                {reporter.length === 0 ? (
                  <img
                    src="/images/icons/chevron-down-gray.svg"
                    className="w-4 h-4 mt-0.5"
                  />
                ) : (
                  <></>
                )}
              </div>
            </Dropdown>
          </div>
        </div>
      );
    case "priority":
      return (
        <div>
          <p className="mon-500-14 text-gray-700 capitalize">{label}</p>
          <div>
            <Dropdown
              overlay={
                <Menu className="w-[200px] rounded-lg custom-menu-dropdown bg-error-600 shadow-dropdown">
                  <Menu.Item
                    className="pt-2.5 px-4"
                    onClick={() => setPriority([])}
                  >
                    <p className="mon-500-12 text-gray-500">Clear selection</p>
                  </Menu.Item>
                  <Menu.Item className="py-2.5 px-4">
                    {dropdown?.map((item) => (
                      <div
                        className="flex items-center justify-between gap-y-1 pt-1.5"
                        key={item.id}
                        onClick={
                          () =>
                            handleExist(
                              priority,
                              setPriority,
                              "priority",
                              item.id
                            )
                          //setPriority([...priority, item.id])
                        }
                      >
                        <PriorityIconWithLabel priority={item.id} />
                        <>
                          {priority?.includes(item.id) && (
                            <img src="/images/icons/check.svg" alt="" />
                          )}
                        </>
                      </div>
                    ))}
                  </Menu.Item>
                </Menu>
              }
              trigger={["click"]}
            >
              <div
                className="flex items-center justify-between cursor-pointer border-[1px] border-gray-300 rounded-xl gap-x-3 px-[14px] mt-[6px]"
                style={{ width: "230px", height: "40px" }}
              >
                <p className="mon-400-14 text-gray-500 whitespace-nowrap">
                  {priority.length === 0 ? (
                    `Select ${label}`
                  ) : (
                    <div className="flex items-center gap-x-1 max-w-max">
                      {priority.map((item) => (
                        <PriorityIcon priority={+item} key={item} />
                      ))}
                    </div>
                  )}
                </p>
                {priority.length === 0 ? (
                  <img
                    src="/images/icons/chevron-down-gray.svg"
                    className="w-4 h-4 mt-0.5"
                  />
                ) : (
                  <></>
                )}
              </div>
            </Dropdown>
          </div>
        </div>
      );

    case "phase":
      return (
        <div>
          <p className="mon-500-14 text-gray-700 capitalize">{label}</p>
          <div>
            <Dropdown
              overlay={
                <Menu className="w-[200px] rounded-lg custom-menu-dropdown bg-error-600 shadow-dropdown">
                  <Menu.Item
                    className="pt-2.5 px-4"
                    onClick={() => setPhase([])}
                  >
                    <p className="mon-500-12 text-gray-500">Clear selection</p>
                  </Menu.Item>
                  <Menu.Item className="py-2.5 px-4">
                    {dropdown?.map(({ phaseId, phases }) => (
                      <div
                        className="flex items-center justify-between pt-1.5"
                        key={phaseId}
                        onClick={() =>
                          handleExist(phase, setPhase, "phase", phaseId)
                        }
                      >
                        <div className="flex items-center gap-x-2">
                          <PhaseIcon
                            phaseId={+phaseId}
                            width="16px"
                            height="16px"
                            icon
                          />
                          <p className="mon-400-12 whitespace-nowrap text-gray-900">
                            {phases}
                          </p>
                        </div>
                        <>
                          {phase?.includes(phaseId) && (
                            <img src="/images/icons/check.svg" alt="" />
                          )}
                        </>
                      </div>
                    ))}
                  </Menu.Item>
                </Menu>
              }
              trigger={["click"]}
            >
              <div
                className="flex items-center justify-between cursor-pointer border-[1px] border-gray-300 rounded-xl gap-x-3 px-[14px] mt-[6px]"
                style={{ width: "230px", height: "40px" }}
              >
                <p className="mon-400-14 text-gray-500 whitespace-nowrap">
                  {phase.length === 0 ? (
                    `Select ${label}`
                  ) : (
                    <div className="flex items-center gap-x-1 max-w-max">
                      {phase.map((item) => (
                        <PhaseIcon
                          phaseId={+item}
                          width="16px"
                          height="16px"
                          icon
                          key={item}
                        />
                      ))}
                    </div>
                  )}
                </p>
                {phase.length === 0 ? (
                  <img
                    src="/images/icons/chevron-down-gray.svg"
                    className="w-4 h-4 mt-0.5"
                  />
                ) : (
                  <></>
                )}
              </div>
            </Dropdown>
          </div>
        </div>
      );

    case "status":
      return (
        <div>
          <p className="mon-500-14 text-gray-700 capitalize">{label}</p>
          <div>
            <Dropdown
              overlay={
                <Menu className="w-[200px] rounded-lg custom-menu-dropdown bg-error-600 shadow-dropdown">
                  <Menu.Item
                    className="pt-2.5 px-4"
                    onClick={() => setStatus([])}
                  >
                    <p className="mon-500-12 text-gray-500">Clear selection</p>
                  </Menu.Item>
                  <Menu.Item className="py-2.5 px-4">
                    {dropdown?.map((item, index) => (
                      <div
                        className={`flex items-center justify-between ${
                          index > 0 && "mt-3"
                        }`}
                        key={item.id}
                        onClick={() =>
                          handleExist(status, setStatus, "status", item.id)
                        }
                      >
                        <Status statusId={item.id} />
                        {status?.includes(item.id) && (
                          <img src="/images/icons/check.svg" alt="" />
                        )}
                      </div>
                    ))}
                  </Menu.Item>
                </Menu>
              }
              trigger={["click"]}
            >
              <div
                className="flex items-center justify-between cursor-pointer border-[1px] border-gray-300 rounded-xl gap-x-3 px-[14px] mt-[6px]"
                style={{ width: "230px", height: "40px" }}
              >
                <p className="mon-400-14 text-gray-500 whitespace-nowrap">
                  {status.length === 0 ? (
                    `Select ${label}`
                  ) : (
                    <div className="flex items-center gap-x-1 max-w-max">
                      {status.map((item) => (
                        <Status statusId={+item} key={item} />
                      ))}
                    </div>
                  )}
                </p>
                {status.length === 0 ? (
                  <img
                    src="/images/icons/chevron-down-gray.svg"
                    className="w-4 h-4 mt-0.5"
                  />
                ) : (
                  <></>
                )}
              </div>
            </Dropdown>
          </div>
        </div>
      );

    case "category":
      return (
        <div>
          <p className="mon-500-14 text-gray-700 capitalize">{label}</p>
          <div>
            <Dropdown
              overlay={
                <Menu className="w-[200px] rounded-lg custom-menu-dropdown bg-error-600 shadow-dropdown">
                  <Menu.Item
                    className="pt-2.5 px-4"
                    onClick={() => setCategory([])}
                  >
                    <p className="mon-500-12 text-gray-500">Clear selection</p>
                  </Menu.Item>
                  <Menu.Item className="py-2.5 px-4">
                    {dropdown?.map((item, index) => (
                      <div
                        className={`flex items-center justify-between ${
                          index > 0 && "mt-3"
                        }`}
                        key={item.id}
                        onClick={() =>
                          handleExist(
                            category,
                            setCategory,
                            "category",
                            item.value
                          )
                        }
                      >
                        <p className="mon-400-12 whitespace-nowrap text-gray-900">
                          {item.value}
                        </p>
                        {category?.includes(item.value) && (
                          <img src="/images/icons/check.svg" alt="" />
                        )}
                      </div>
                    ))}
                  </Menu.Item>
                </Menu>
              }
              trigger={["click"]}
            >
              <div
                className="flex items-center justify-between cursor-pointer border-[1px] border-gray-300 rounded-xl gap-x-3 px-[14px] mt-[6px]"
                style={{ width: "230px", height: "40px" }}
              >
                <p className="mon-400-14 text-gray-500 whitespace-nowrap">
                  {category.length === 0 ? (
                    `Select ${label}`
                  ) : (
                    <div className="flex items-center gap-x-1 max-w-max">
                      {category.map((item) => (
                        <CustomBadge color="#E5C503" text={item} key={item} />
                      ))}
                    </div>
                  )}
                </p>
                {category.length === 0 ? (
                  <img
                    src="/images/icons/chevron-down-gray.svg"
                    className="w-4 h-4 mt-0.5"
                  />
                ) : (
                  <></>
                )}
              </div>
            </Dropdown>
          </div>
        </div>
      );

    case "component":
      return (
        <div>
          <p className="mon-500-14 text-gray-700 capitalize">{label}</p>
          <div>
            <Dropdown
              overlay={
                <Menu className="w-[200px] rounded-lg custom-menu-dropdown bg-error-600 shadow-dropdown">
                  <Menu.Item
                    className="pt-2.5 px-4"
                    onClick={() => setComponent([])}
                  >
                    <p className="mon-500-12 text-gray-500">Clear selection</p>
                  </Menu.Item>
                  <Menu.Item className="py-2.5 px-4">
                    {dropdown?.map((item, index) => (
                      <div
                        className={`flex items-center justify-between ${
                          index > 0 && "mt-3"
                        }`}
                        key={item.id}
                        onClick={() =>
                          handleExist(
                            component,
                            setComponent,
                            "component",
                            item.value
                          )
                        }
                      >
                        <p className="mon-400-12 whitespace-nowrap text-gray-900">
                          {item.value}
                        </p>

                        {component?.includes(item.value) && (
                          <img src="/images/icons/check.svg" alt="" />
                        )}
                      </div>
                    ))}
                  </Menu.Item>
                </Menu>
              }
              trigger={["click"]}
            >
              <div
                className="flex items-center justify-between cursor-pointer border-[1px] border-gray-300 rounded-xl gap-x-3 px-[14px] mt-[6px]"
                style={{ width: "230px", height: "40px" }}
              >
                <p className="mon-400-14 text-gray-500 whitespace-nowrap">
                  {component.length === 0 ? (
                    `Select ${label}`
                  ) : (
                    <div className="flex items-center gap-x-1 max-w-max">
                      {component.map((item) => (
                        <CustomBadge color="#E5C503" text={item} key={item} />
                      ))}
                    </div>
                  )}
                </p>
                {component.length === 0 ? (
                  <img
                    src="/images/icons/chevron-down-gray.svg"
                    className="w-4 h-4 mt-0.5"
                  />
                ) : (
                  <></>
                )}
              </div>
            </Dropdown>
          </div>
        </div>
      );
  }
};

const Footer = ({ onCancel }) => {
  const dispatch = useAppDispatch();
  const { projectId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const { filters } = useAppSelector((state) => state.schedule);
  const assignee = filters?.assignee?.map((item) => item.userId);
  const reporter = filters?.reporter?.map((item) => item.userId);
  const status = filters?.status;
  const priority = filters?.priority;
  const phase = filters?.phase;
  // const category = filters.category
  // const component = filters.component

  return (
    <div className="flex items-center justify-end h-[72px] gap-x-3 pr-4 bg-white rounded-b-xl shadow-upward">
      <CustomButton
        text="Cancel"
        className="border-2 border-gray-200 text-black bg-white mon-500-14 "
        height="40px"
        width="81px"
        onClick={() => {
          onCancel();
          dispatch(
            getGoalsBySprint({
              projectId,
              sprintId: searchParams.get("selectedSprint"),
            })
          );
          dispatch(resetSchedule("filters"));
        }}
      />
      <CustomButton
        text="Apply Filter"
        className="mon-500-14 text-white bg-primary-600 "
        height="40px"
        width="114px"
        onClick={() => {
          onCancel();
          dispatch(
            getGoalsBySprint({
              projectId,
              sprintId: searchParams.get("selectedSprint"),
              filters: { assignee, reporter, status, priority, phase },
            })
          );
        }}
      />
    </div>
  );
};

const Filter = ({ onCancel }) => {
  const { userList } = useAppSelector((state) => state.overview);
  const { phases } = useAppSelector((state) => state.schedule);

  const priority = [
    {
      id: 1,
      value: "Low",
    },
    {
      id: 2,
      value: "Medium",
    },
    {
      id: 3,
      value: "High",
    },
  ];

  const status = [
    {
      id: 1,
      value: "To Do",
    },
    {
      id: 2,
      value: "In Progress",
    },
    {
      id: 3,
      value: "Done",
    },
  ];

  const component = [
    {
      id: 1,
      value: "OneNote",
    },
    {
      id: 2,
      value: "Evernote",
    },
    {
      id: 3,
      value: "Figma",
    },
  ];

  const category = [
    {
      id: 1,
      value: "Task",
    },
    {
      id: 2,
      value: "Bug",
    },
    {
      id: 3,
      value: "API",
    },
  ];

  return (
    <div className="w-[789px] h-[406px] rounded-xl border-[1px] bg-white">
      <div className="p-6">
        <p className="mon-500-16 text-gray-900">Filter</p>
        <div className="flex items-center mt-6 gap-x-6">
          <FilterItem label="assignee" dropdown={userList} />
          <FilterItem label="priority" dropdown={priority} />
          <FilterItem label="phase" dropdown={phases} />
        </div>
        <div className="flex items-center mt-6 gap-x-6">
          <FilterItem label="reporter" dropdown={userList} />
          <FilterItem label="status" dropdown={status} />
        </div>
        <div className="flex items-center mt-6 gap-x-6">
          <FilterItem label="category" dropdown={category} />
          <FilterItem label="component" dropdown={component} />
        </div>
      </div>
      <Footer onCancel={onCancel} />
    </div>
  );
};

export default Filter;
