/* eslint-disable no-useless-escape */
import { Divider, Dropdown, Empty, message, Spin, Tooltip } from "antd";
import dayjs from "dayjs";
import React, { useState, useRef, useEffect } from "react";
import { useDispatch } from "react-redux";
import CustomAvatar from "../../Core/Common/CustomAvatar";
import CustomFrostyMessage from "../../Core/Common/CustomFrostyMessage";
import CustomHorizontalIconGroup from "../../Core/Common/CustomHorizontalIconGroup";
import { useAppDispatch, useAppSelector } from "../../Core/redux/hooks";
import {
  resetConversations,
  updateConversations,
  updateConversationsMultiple,
  updatePrivateConversations,
  updateReaction,
  updateThreadMessage,
  updateUnreadCountForProjectThreads,
} from "../../Core/redux/slices/conversations.slice";
import { getRelativeTime, utcToLocal } from "../../Core/utils/date";
import ChatHeader from "./Components/ChatHeader";
import MenuDropdown from "./Components/MenuDropdown";
import ParseMentions from "./ParseMentions";
import { ReactTinyLink } from "react-tiny-link";
import {
  getPrivateMessages,
  getThreadMessages,
  reactToComment,
} from "../../Core/redux/api/conversationAPI";
import { updateVault } from "../../Core/redux/slices/vault.slice";
import { downloadFiles, playNotifySoundDebounced } from "../../Core/utils";
import useIntersectionObserver from "../../Core/hooks/useIntersectionObserver";
import DownloadIcon from "../../Core/svg/DownLoad";
import Album from "../../Core/Common/Album";
import UserDetailsCard from "./Components/UserDetailsCard";

export const Attachments = ({ type, url, thumbnail, fileName }) => {
  if (type.includes("video")) {
    return (
      <div className="px-2 py-2 rounded overflow-hidden">
        <video
          muted
          // onCanPlayThrough={(e) => {
          //   const video = e.target;
          //   setTimeout(() => {
          //     video?.pause();
          //   }, 1000);
          // }}
          autoPlay={false}
          controls
          preload="metadata"
          // poster="/images/thumbnails/video.svg"
          //poster={thumbnail}
        >
          <source src={`${url}#t=0.1`}></source>
        </video>
      </div>
    );
  }

  // const save = (blob) => {
  //   const newurl = window.URL.createObjectURL(blob);
  //   const link = document.createElement("a");
  //   link.href = newurl;
  //   link.setAttribute(
  //     "download",
  //     ""
  //   );

  //   document.body.appendChild(link);
  //   link.click();

  //   link.parentNode.removeChild(link);
  //   window.URL.revokeObjectURL(newurl);
  // };

  // ctx.selectedFiles.forEach(async (url, i) => {
  //   const xhr = new XMLHttpRequest();
  //   xhr.open("GET", url);
  //   xhr.responseType = "blob";
  //   // eslint-disable-next-line func-names
  //   xhr.onload = function () {
  //     console.log(xhr.response, "xhr");
  //     save(xhr.response);
  //   };
  //   xhr.send();
  //   // saveAs(url);
  // });

  return (
    <>
      <div
        className={`mx-2 my-2 cursor-pointer rounded overflow-hidden w-[329px] h-[178px] flex justify-center items-center object-cover relative actions-row bg-white`}
      >
        <img
          className={`${
            thumbnail ? "w-full h-full" : " h-24 w-24 "
          } rounded object-cover`}
          src={thumbnail || "/images/No-Image-Placeholder.svg"}
          // alt={name}
        />
      </div>
      <div className="flex mx-2 my-2 ">
        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            fill="none"
            viewBox="0 0 20 20"
          >
            <rect
              width="15"
              height="15"
              x="2.5"
              y="2.5"
              fill="#E0E7EE"
              rx="4"
            ></rect>
            <path
              stroke="#0D3271"
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M10.833 5.946v1.721c0 .234 0 .35.046.44.04.078.104.142.182.182.089.045.206.045.44.045h1.72m-2.388 3.75h-2.5m3.334-1.667H8.333m5-1.255v3.005c0 .7 0 1.05-.136 1.318a1.25 1.25 0 01-.546.546c-.268.136-.617.136-1.318.136H8.667c-.7 0-1.05 0-1.318-.136a1.25 1.25 0 01-.546-.546c-.136-.268-.136-.618-.136-1.318V7.834c0-.7 0-1.05.136-1.317a1.25 1.25 0 01.546-.547c.268-.136.618-.136 1.318-.136h1.338c.306 0 .459 0 .602.035.128.03.25.08.362.15.126.076.234.185.45.4l1.329 1.33c.216.215.324.323.401.45.069.111.12.233.15.36.034.145.034.298.034.603z"
            ></path>
          </svg>
        </div>
        <p className="mon-500-12 truncate w-[315px] pl-2 ">{fileName}</p>
      </div>
    </>
  );
};

const Message = ({
  position,
  reaction,
  image,
  message,
  reactToMessage,
  deleteMessage,
  comments,
  showSender = true,
}: {
  position: string;
  helloText?: boolean;
  reaction: boolean;
  image: boolean;
  message: any;
  comments?: boolean;
  reactToMessage?: (
    emoji: string,
    messageId: string,
    isAlreadyReacted: boolean
  ) => void;
  deleteMessage?: (id: string) => void;
  showSender?: boolean;
}) => {
  const [hover, setHover] = useState(false);
  const { tenantMembers } = useAppSelector((state) => state.tenant);
  const { user } = useAppSelector((state) => state.userDetails);
  const dispatch = useAppDispatch();
  const alignment =
    position === "end"
      ? "justify-end items-end "
      : "justify-start items-start  ";
  // const paddingAlignment = position === "end" ? "" : "flex-row-reverse";
  const container =
    position === "end"
      ? "items-end pr-4 flex-row-reverse"
      : "justify-start pl-4";

  const regex =
    /((?:(http|https|Http|Https|rtsp|Rtsp):\/\/(?:(?:[a-zA-Z0-9\$\-\_\.\+\!\*\'\(\)\,\;\?\&\=]|(?:\%[a-fA-F0-9]{2})){1,64}(?:\:(?:[a-zA-Z0-9\$\-\_\.\+\!\*\'\(\)\,\;\?\&\=]|(?:\%[a-fA-F0-9]{2})){1,25})?\@)?)?((?:(?:[a-zA-Z0-9][a-zA-Z0-9\-]{0,64}\.)+(?:(?:aero|arpa|asia|a[cdefgilmnoqrstuwxz])|(?:biz|b[abdefghijmnorstvwyz])|(?:cat|com|coop|c[acdfghiklmnoruvxyz])|d[ejkmoz]|(?:edu|e[cegrstu])|f[ijkmor]|(?:gov|g[abdefghilmnpqrstuwy])|h[kmnrtu]|(?:info|int|i[delmnoqrst])|(?:jobs|j[emop])|k[eghimnrwyz]|l[abcikrstuvy]|(?:mil|mobi|museum|m[acdghklmnopqrstuvwxyz])|(?:name|net|n[acefgilopruz])|(?:org|om)|(?:pro|p[aefghklmnrstwy])|qa|r[eouw]|s[abcdeghijklmnortuvyz]|(?:tel|travel|t[cdfghjklmnoprtvwz])|u[agkmsyz]|v[aceginu]|w[fs]|y[etu]|z[amw]))|(?:(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[1-9])\.(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[1-9]|0)\.(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[1-9]|0)\.(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[0-9])))(?:\:\d{1,5})?)(\/(?:(?:[a-zA-Z0-9\;\/\?\:\@\&\=\#\~\-\.\+\!\*\'\(\)\,\_])|(?:\%[a-fA-F0-9]{2}))*)?(?:\b|$)/gi;
  let allUrl = [];
  allUrl = message?.message?.match(regex);

  ///console.log(allUrl, "aaaa");
  const { vault } = useAppSelector((state) => state.projects);
  const commentAccess = vault?.[5]?.[0] || {};

  const isAlreadyReacted = (emoji) =>
    message.reactions &&
    message.reactions[emoji] &&
    message.reactions[emoji].includes(user.id);

  const reactionOverLay = (
    <div className="flex bg-white shadow-bottom items-center rounded-full py-3">
      <button
        className="w-5 h-5 mx-3"
        onClick={() =>
          reactToMessage("😊", message._id, isAlreadyReacted("😊"))
        }
      >
        <span className="text-xl">😊</span>
      </button>
      <button
        className="w-5 h-5 mx-3"
        onClick={() =>
          reactToMessage("😆", message._id, isAlreadyReacted("😆"))
        }
      >
        <span className="text-xl">😆</span>
      </button>
      <button
        className="w-5 h-5 mx-3"
        onClick={() =>
          reactToMessage("❤️", message._id, isAlreadyReacted("❤️"))
        }
      >
        <span className="text-xl">❤️</span>
      </button>
      <button
        className="w-5 h-5 mx-3"
        onClick={() =>
          reactToMessage("👍", message._id, isAlreadyReacted("👍"))
        }
      >
        <span className="text-xl">👍</span>
      </button>
      <button
        className="w-5 h-5 mx-3"
        onClick={() =>
          reactToMessage("👎", message._id, isAlreadyReacted("👎"))
        }
      >
        <span className="text-xl">👎</span>
      </button>
      <button
        className="w-5 h-5 mx-3"
        onClick={() =>
          reactToMessage("👌", message._id, isAlreadyReacted("👌"))
        }
      >
        <span className="text-xl">👌</span>
      </button>
    </div>
  );

  const getHoverMenu = () => {
    if (comments) {
      return (
        <div className="bg-white h-9 border border-gray-100 flex justify-center items-center shadow-box rounded-xl mr-3 mb-auto">
          <Dropdown
            trigger={["click"]}
            //open={true}
            overlay={reactionOverLay}
          >
            <Tooltip title="Add reaction">
              <button className="w-5 h-5 mx-2">
                <img
                  src="/images/icons/face-content.svg"
                  className="h-full w-full"
                />
              </button>
            </Tooltip>
          </Dropdown>
        </div>
      );
    } else {
      return (
        <div className="bg-white h-9 border border-gray-100 flex justify-center items-center shadow-box rounded-xl mr-3 mb-auto">
          <Dropdown
            trigger={["click"]}
            getPopupContainer={(trigger) => trigger.parentElement}
            //open={true}
            overlay={reactionOverLay}
          >
            <Tooltip
              title="Add reaction"
              getPopupContainer={(trigger) => trigger.parentElement}
            >
              <button className="w-5 h-5 mx-2">
                <img
                  src="/images/icons/face-content.svg"
                  className="h-full w-full"
                />
              </button>
            </Tooltip>
          </Dropdown>
          <Tooltip
            title="Reply"
            getPopupContainer={(trigger) => trigger.parentElement}
          >
            <button
              className="w-5 h-5 mx-2"
              onClick={() => {
                dispatch(
                  updateConversations({
                    key: "replyMessage",
                    value: {
                      reply: true,
                      message: message.message,
                      messageObject: message,
                    },
                  })
                );
              }}
            >
              <img
                src="/images/icons/message-reply-light.svg"
                className="h-full w-full"
              />
            </button>
          </Tooltip>
          <Tooltip
            title="Forward"
            getPopupContainer={(trigger) => trigger.parentElement}
          >
            <button
              className="w-5 h-5 mx-2"
              onClick={() => {
                dispatch(
                  updateConversationsMultiple([
                    {
                      key: "shareMessageModal",
                      value: true,
                    },
                    { key: "forwardMessage", value: message },
                    {
                      key: "shareType",
                      value: "forwardMessage",
                    },
                  ])
                );
              }}
            >
              <img src="/images/icons/share-06.svg" className="h-full w-full" />
            </button>
          </Tooltip>
          {message.attachments?.length > 0 ? (
            <Tooltip
              title="Download"
              getPopupContainer={(trigger) => trigger.parentElement}
            >
              <button
                className="w-5 h-5 mx-2"
                onClick={() => {
                  const urls = message.attachments.map((a) => a.url);
                  downloadFiles(urls);
                }}
              >
                <DownloadIcon height={20} width={20} />
              </button>
            </Tooltip>
          ) : (
            <></>
          )}
          {position === "end" ? (
            <Tooltip
              title="More options"
              getPopupContainer={(trigger) => trigger.parentElement}
            >
              <div className="mr-1">
                <MenuDropdown
                  elements={[
                    commentAccess.update || !comments
                      ? {
                          id: 1,
                          image: "/images/icons/edit-01.svg",
                          text: "Edit message",
                          textColor: "text-gray-700",
                          onclick: () => {
                            dispatch(
                              updateConversations({
                                key: "editMessage",
                                value: { edit: true, messageObject: message },
                              })
                            );
                          },
                        }
                      : null,
                    commentAccess.delete || !comments
                      ? {
                          id: 5,
                          image: "/images/icons/trash.svg",
                          text: "Delete",
                          textColor: "text-error-600",
                          onclick: () => {
                            deleteMessage(message._id);
                          },
                        }
                      : null,
                  ]}
                  customFunc={undefined}
                />
              </div>
            </Tooltip>
          ) : (
            <></>
          )}
        </div>
      );
    }
  };

  const getAttachements = (attachments) => {
    if (attachments.length === 0) return;

    const newAttacments = attachments.map((attachment) => {
      return { ...attachment, messageId: message._id };
    });

    return <Album files={newAttacments} />;
  };

  const senderProfilePicture = tenantMembers.find(
    (t) => t.id == message.senderId
  )?.profilePicture;

  return (
    <div
      className={`flex   rounded-lg gap-x-2 ${container}`}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <div
        className={`flex flex-col ${alignment} px-4 ${
          showSender ? "" : ""
        }  rounded-lg`}
      >
        <div className={`flex  gap-x-2 `}>
          {/* <div
            className=""
            style={{
              height: "6px",
              width: "6px",
              backgroundColor: "#667085",
              borderRadius: "50%",
            }}
          /> */}
          {/* {position !== "end" && showSender && (
            <CustomAvatar
              title={position === "end" ? "You" : message.senderName}
              size={40}
            />
          )} */}
          {position !== "end" ? (
            showSender ? (
              <CustomAvatar
                title={position === "end" ? "You" : message.senderName}
                size={40}
                src={senderProfilePicture}
                className="mt-2"
              />
            ) : (
              <div className="w-[40px] h-[40px]"></div>
            )
          ) : (
            <></>
          )}
          <div>
            {showSender && (
              <div
                className={`flex mt-2 gap-x-2  ${
                  position === "end" ? "justify-end" : "justify-start"
                }`}
              >
                <UserDetailsCard
                  userName={position === "end" ? "You" : message.senderName}
                  isDisable={false}
                  userId={position === "end" ? user.id : message.senderId}
                />

                <div className="mon-400-12 text-gray-500 ">
                  {message.createdAt
                    ? dayjs(message.createdAt).utc().local().format("hh:mm a")
                    : "-"}
                </div>
              </div>
            )}

            <div>
              <div
                className={`flex ${showSender ? "mt-2" : "mt-1"} gap-x-2 ${
                  position === "end" ? "flex-row-reverse" : ""
                }`}
              >
                <div
                  className={`mon-400-12 max-w-[32vw] h-auto  whitespace-pre-wrap break-words   float-right ${
                    position === "end"
                      ? "bg-primary-600 text-white"
                      : "bg-gray-50 text-black"
                  }`}
                  style={{
                    borderRadius:
                      position === "end"
                        ? "8px 0px 8px 8px"
                        : "0px 8px 8px 8px",
                  }}
                >
                  {message.replyMessage && message.replyMessage._id && (
                    <div
                      className={` mx-2 my-2 px-1.5 py-1.5  rounded ${
                        position === "end"
                          ? "bg-primary-700 text-gray-200 border-l-4 border-[#0D3271]"
                          : "bg-gray-200 text-gray-500 border-l-4 border-gray-300"
                      }`}
                    >
                      <div className="flex items-center">
                        {" "}
                        <span className="ml-2 mon-400-12  capitalize">
                          {message.replyMessage.senderName}
                        </span>
                      </div>
                      <div className="ml-2 mt-1 ">
                        <ParseMentions
                          value={message.replyMessage.message}
                          className={
                            position === "end"
                              ? "bg-primary-900 text-gray-400"
                              : "bg-gray-300 text-gray-500"
                          }
                        />
                      </div>
                      <div className="ml-2 mt-1">
                        {message.replyMessage.attachments &&
                          message.replyMessage.attachments.length > 0 &&
                          message.replyMessage.attachments.map((att, i) => (
                            <Attachments
                              type={att.type}
                              url={att.url}
                              thumbnail={att.thumbnail}
                              key={i}
                              fileName={att.name}
                            />
                          ))}
                      </div>
                    </div>
                  )}

                  {message.attachments && getAttachements(message.attachments)}
                  <div className="px-2 py-[9px]">
                    <ParseMentions value={message.message} />
                  </div>
                  {allUrl && allUrl !== null && allUrl.length > 0 ? (
                    allUrl.map((url, i) => (
                      <div
                        className="px-2 py-2  rounded overflow-hidden"
                        key={i}
                      >
                        {/* <ReactTinyLink
                          cardSize="large"
                          showGraphic={true}
                          minLine={1}
                          url={url}
                        /> */}
                        <a
                          className="hover:text-primary-900"
                          href={url}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {url}
                        </a>
                      </div>
                    ))
                  ) : (
                    <></>
                  )}
                </div>
                {hover && getHoverMenu()}
              </div>
              <div
                className={`flex items-center ${
                  position === "end" ? "justify-end" : "justify-start"
                } gap-x-2`}
              >
                {message.reactions &&
                  Object.keys(message.reactions).map(
                    (emoji, index) =>
                      message.reactions[emoji].length > 0 && (
                        <button
                          className="flex items-center gap-x-2 pt-2"
                          key={index}
                          onClick={() =>
                            reactToMessage(
                              emoji,
                              message._id,
                              message.reactions[emoji].includes(user.id)
                            )
                          }
                        >
                          <div
                            className="flex items-center  h-6 bg-gray-50 rounded-2xl"
                            style={{
                              padding: "2px, 8px, 2px, 8px",
                            }}
                          >
                            <span className="text-lg">{emoji}</span>
                            {message.reactions[emoji].length > 1 && (
                              <span className="mon-500-14 px-1 text-gray-700">
                                {message.reactions[emoji].length}
                              </span>
                            )}
                          </div>
                        </button>
                      )
                  )}
              </div>
              {image && (
                <div className="pt-5 w-[493px] h-[340px]">
                  <img src="/images/Thumbnail.png" className="w-full h-full" />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Day = ({ type, date, id }) => {
  const formateDate = (date) => {
    if (date === null || typeof date === "undefined") return "-";
    if (dayjs(date).isToday()) return "Today";
    if (dayjs(date).isTomorrow()) return "Tomorrow";
    return dayjs(date).format("DD MMM YYYY");
  };
  return (
    <Divider>
      <p className="mon-400-14 text-gray-500">{formateDate(date)}</p>
    </Divider>
  );
};

const Unread = ({ type, count }) => {
  return (
    <Divider
      //className="border-t-error-500"
      style={{
        borderTopColor: "#F04438",
      }}
    >
      <p className="mon-500-14 text-error-500 text-center w-full ">
        {count} New messages
      </p>
    </Divider>
  );
};

function TypingIndicator({
  typingStatus,
  currentThread,
  reactToMessage,
  deleteMessage,
}) {
  const [isTyping, setIsTyping] = useState(false);
  const isPrivateThread = currentThread.type === "private";
  const isMatchingUser = +typingStatus.userId === +currentThread.userId;
  const isMatchingThread = +typingStatus.threadId === +currentThread.threadId;

  // useEffect(() => {
  //   console.log(isTyping, typingStatus.isTyping, "typingStatus");
  //   if (typingStatus.isTyping) {
  //     setIsTyping(typingStatus.isTyping);
  //   }
  //   if (!typingStatus.isTyping) {
  //     setTimeout(() => {
  //       if (!typingStatus.isTyping) {
  //         setIsTyping(typingStatus.isTyping);
  //       }
  //     }, 5000);
  //   }
  // }, [typingStatus]);

  if (
    !typingStatus.isTyping ||
    (isPrivateThread ? !isMatchingUser : !isMatchingThread)
  ) {
    return <></>;
  }

  if (currentThread.type !== typingStatus.threadType) {
    return <></>;
  }

  return (
    // <Message
    //   position={"right"}
    //   message={{
    //     senderName: typingStatus.userName,
    //     message: `${
    //       currentThread.type === "group" ? typingStatus.userName : ""
    //     } Typing... `,
    //   }}
    //   reaction={false}
    //   image={false}
    //   reactToMessage={reactToMessage}
    //   deleteMessage={deleteMessage}
    //   showSender={false}
    // />
    <div className="pl-24 mt-1.5 mb-0 flex items-center">
      <div className="dot-collision" />

      <span className="ml-7 mon-500-10 italic text-gray-500">
        {currentThread.type === "group"
          ? typingStatus.userName + " " + "is"
          : ""}{" "}
        Typing.....
      </span>
    </div>
  );
}

const MainChatWindow = ({
  socket,
  comments = false,
}: {
  socket?: any;
  comments?: boolean;
}) => {
  const {
    currentThreadMessage,
    currentThread,
    directMessageUsers,
    unreadCount: unreadCountFromRedux,
    projectsWithThreads,
  } = useAppSelector((state) => state.conversations);

  const { openCommentsModal } = useAppSelector((state) => state.vault);

  const chatWindow = React.useRef(null);

  const scrollToChatBottom = () => {
    const chatBoxDiv = chatWindow.current;

    if (chatBoxDiv) {
      chatBoxDiv.scrollTop = chatBoxDiv.scrollHeight;
    }
  };

  const dispatch = useAppDispatch();

  const { user } = useAppSelector((state) => state.userDetails);
  const reactToMessage = (emoji, messageId, isAlreadyReacted) => {
    if (comments) {
      dispatch(
        reactToComment({ emoji, userId: user.id, messageId, comment: true })
      );
      const oldModeMetaComments = openCommentsModal.comments;
      const updateComments = oldModeMetaComments.map((m) => {
        if (m._id === messageId) {
          const reactions = m.reactions || {};
          const userReaction = m.reactions[emoji] ? m.reactions[emoji] : [];
          const updatedReaction = {
            ...reactions,
            [emoji]: [...userReaction, user.id],
          };
          console.log(updatedReaction);
          const newMessage = { ...m, reactions: updatedReaction };
          return newMessage;
        }
        return m;
      });
      const newModelMeta = { ...openCommentsModal, comments: updateComments };
      dispatch(
        updateVault({
          key: "openCommentsModal",
          value: newModelMeta,
        })
      );
      return "";
    }
    socket.emit("reaction", {
      emoji,
      userId: user.id,
      messageId,
      isAlreadyReacted,
      threadId:
        currentThread.type === "group"
          ? currentThread.threadId
          : currentThread.userId,
    });

    const removeOrAdd = (userReaction, userId) => {
      if (userReaction.includes(userId)) {
        console.log(
          userReaction.filter((u) => u != userId),
          "reactions1"
        );
        return userReaction.filter((u) => u != userId);
      } else {
        console.log([...userReaction, userId], "reactions2");
        return [...userReaction, userId];
      }
    };
    const addReaction = (oldMessage) => {
      const message = oldMessage.map((m) => {
        if (m._id === messageId) {
          const reactions = m.reactions || {};
          console.log(reactions, "hvjhvhjj");
          const userReaction = reactions[emoji] ? reactions[emoji] : [];
          const updatedReaction = {
            ...reactions,
            [emoji]: removeOrAdd(userReaction, user.id),
          };
          console.log(updatedReaction);
          const newMessage = { ...m, reactions: updatedReaction };
          return newMessage;
        }
        return m;
      });
      return message;
    };

    if (currentThread.type === "private") {
      const users = directMessageUsers.map((user) => {
        if (+user.userId === +currentThread.userId) {
          const message = addReaction(user.messages);
          return { ...user, messages: [...message] };
        }
        return user;
      });

      dispatch(
        updateConversations({
          key: "directMessageUsers",
          value: [...users],
        })
      );
    } else {
      const message = addReaction(currentThreadMessage);
      console.log(message);
      dispatch(
        updateConversations({ key: "currentThreadMessage", value: message })
      );
    }
  };

  React.useEffect(() => {
    scrollToChatBottom();
  }, [currentThreadMessage, directMessageUsers]);

  const socketOnReaction = ({ messageId, userId, emoji, threadId }) => {
    console.log("socketOnReaction", { messageId, userId, emoji, threadId });
    // const message = currentThreadMessage.map((m) => {
    //   if (m._id === messageId) {
    //     const reactions = m.reactions || {};
    //     const userReaction = m.reactions?.[emoji] || [];
    //     const updatedReaction = {
    //       ...reactions,
    //       [emoji]: [...userReaction, userId],
    //     };
    //     console.log(updatedReaction);
    //     const newMessage = { ...m, reactions: updatedReaction };
    //     return newMessage;
    //   }
    //   return m;
    // });

    // // merge-conflict: below might be part of some other function
    // // console.log(message);
    // dispatch(
    //   updateConversations({ key: "currentThreadMessage", value: message })
    // );

    dispatch(updateReaction({ messageId, userId, emoji, threadId }));
  };

  const [typingStatusState, setTypingStatusState] = useState({
    isTyping: false,
    userName: "",
    threadId: "",
    userId: "",
    threadType: "",
  });

  const socketOnTyping = ({
    isTyping,
    projectId,
    threadId,
    userName,
    userId,
    threadType,
  }) => {
    console.log("socketOnTyping", isTyping, projectId, threadId, userName);
    setTypingStatusState({
      isTyping,
      userName,
      threadId,
      userId,
      threadType,
    });
    scrollToChatBottom();
  };

  // const readMessage = ()=>{
  //   // this function is used to triigre the readMessage event so that socket can decreases the unread amount by one and remove t

  // }

  const socketOnPrivateMessage = ({
    message,
    senderName,
    senderId,
    reciverId,
    createdAt,
    senderProfilePicture,
    replyMessage,
    _id,
    attachments,
  }) => {
    if (reciverId != user.id) {
      console.error("ERROR:[SOCKET] sending message to wrong user ");
      return;
    }
    dispatch(
      updatePrivateConversations({
        message,
        senderName,
        senderId,
        reciverId,
        createdAt,
        senderProfilePicture,
        replyMessage,
        _id,
        attachments,
        reactions: {},
      })
    );
    // sender id id of user who sent this message and reciver id the id of user who is rciving this message
    // in this case we are reciver so our id should be equal to this id
    console.log(
      currentThread.userId,
      senderId,
      reciverId,
      "socketOnPrivateMessage"
    );
    if (currentThread.userId != senderId) {
      playNotifySoundDebounced();
    }
    if (currentThread.userId == senderId) {
      scrollToChatBottom();
      socket.emit("readMessageFromSocket", {
        threadId: currentThread.threadId,
        threadType: currentThread.type,
        userId: user.id,
        reciverId: currentThread.userId,
      });
    }
  };

  const socketOnMessage = (message) => {
    dispatch(updateThreadMessage({ ...message, reactions: {} }));

    if (currentThread.threadId == message.threadId) {
      scrollToChatBottom();
      socket.emit("readMessageFromSocket", {
        threadId: currentThread.threadId,
        threadType: currentThread.type,
        userId: user.id,
        reciverId: currentThread.userId,
      });
    }
  };

  const socketNewThreadMessage = ({ threadId, projectId }) => {
    console.log("updateUnreadCountForProjectThreads", { threadId, projectId });
    dispatch(updateUnreadCountForProjectThreads({ threadId, projectId }));
  };

  React.useEffect(() => {
    socket.on("reaction", socketOnReaction);
    socket.on("userTyping", socketOnTyping);
    socket.on("privateMessage", socketOnPrivateMessage);
    socket.on("message", socketOnMessage);
    socket.on("newThreadMessage", socketNewThreadMessage);
    return () => {
      socket.off("reaction", socketOnReaction);
      socket.off("userTyping", socketOnTyping);
      socket.off("privateMessage", socketOnPrivateMessage);
      socket.off("message", socketOnMessage);
      socket.off("newThreadMessage", socketNewThreadMessage);
    };
  }, [socket, currentThread]);

  const deleteMessage = (id) => {
    const message = {
      messageId: id,
      threadId: currentThread.threadId,
    };
    console.log(message, "deleted message");

    socket.emit("deleteMessage", message);

    if (currentThread.type === "private") {
      const users = directMessageUsers.map((user) => {
        if (+user.userId === +currentThread.userId) {
          const message = user.messages.filter((m) => {
            return m._id !== id;
          });
          return { ...user, messages: [...message] };
        }
        return user;
      });

      dispatch(
        updateConversations({
          key: "directMessageUsers",
          value: [...users],
        })
      );
    } else {
      const messageListCopy = [...currentThreadMessage];

      const updatedMessageListCopy = messageListCopy.filter((m) => {
        return m._id !== id;
      });

      dispatch(
        updateConversations({
          key: "currentThreadMessage",
          value: [...updatedMessageListCopy],
        })
      );
    }
  };

  function groupedDays(messages) {
    return messages.reduce((acc, el, i) => {
      const messageDay = dayjs(el.createdAt).format("YYYY-MM-DD");
      if (acc[messageDay]) {
        return { ...acc, [messageDay]: acc[messageDay].concat([el]) };
      }
      return { ...acc, [messageDay]: [el] };
    }, {});
  }

  function generateItems(messages: any[], unreadCount?: number) {
    const days = groupedDays(messages);
    const sortedDays = Object.keys(days).sort(
      (x, y) => dayjs(y, "YYYY-MM-DD").unix() - dayjs(x, "YYYY-MM-DD").unix()
    );
    const items = sortedDays.reduce((acc, date) => {
      const sortedMessages = days[date].sort(
        (x, y) =>
          new Date(y.createdAt).valueOf() - new Date(x.createdAt).valueOf()
      );
      return acc.concat([...sortedMessages, { type: "day", date, id: date }]);
    }, []);
    if (unreadCount && unreadCount > 0) {
      items.splice(unreadCount, 0, {
        type: "unread",
        count: unreadCount,
      });
    }
    return items.reverse();
  }

  function renderItem(message: any, prevMessage?: any) {
    if (message.type && message.type === "day") {
      return <Day {...message} />;
    }
    if (message.type && message.type === "unread") {
      return <Unread {...message} />;
    }

    let showSender = true;
    // let showReciver = true;

    if (prevMessage && !prevMessage.type && !message.type) {
      // const timeDiff = dayjs(message.createdAt).diff(
      //   prevMessage.createdAt,
      //   "second"
      // );
      if (prevMessage.senderId === message.senderId) {
        showSender = false;
      }
    }

    return (
      <Message
        key={message._id}
        position={message?.senderId === user.id ? "end" : "right"}
        message={message}
        reaction={true}
        image={false}
        reactToMessage={reactToMessage}
        deleteMessage={deleteMessage}
        comments={comments}
        showSender={showSender}
      />
    );
  }

  const getMessage = () => {
    if (comments) {
      if (openCommentsModal.comments.length === 0) return <></>;
      const newMessages = generateItems(openCommentsModal.comments);

      return newMessages?.map((message) => renderItem(message));
    } else {
      if (currentThread.type === "group") {
        if (currentThreadMessage?.length === 0) return <></>;
        const newMessages = generateItems(
          currentThreadMessage,
          unreadCountFromRedux
        );
        return newMessages?.map((message, i) =>
          renderItem(message, newMessages[i - 1])
        );
      } else {
        if (directMessageUsers) {
          const user = directMessageUsers?.find(
            (user) => +user.userId === +currentThread.userId
          );
          const oldmessage = user?.messages;
          const unreadCount = user?.unreadCount;
          console.log(unreadCount, "new");
          if (!oldmessage || oldmessage?.length === 0) return <></>;
          console.log(
            oldmessage,
            !oldmessage || oldmessage?.length === 0,
            "helloo"
          );
          const newMessages = generateItems(oldmessage, unreadCountFromRedux);

          return newMessages.map((message, i) =>
            renderItem(message, newMessages[i - 1])
          );
        }
      }
    }
  };

  const ref = useRef<HTMLDivElement | null>(null);
  // const { isIntersecting } = useIntersectionObserver(ref, {});

  // React.useEffect(() => {
  //   if (isIntersecting) {
  //     if (currentThread.type === "group") {
  //       dispatch(
  //         getThreadMessages({
  //           threadId: currentThread.threadId,
  //           limit: currentThread.limit,
  //           offset: currentThread.offset,
  //         })
  //       );
  //     } else {
  //       dispatch(
  //         getPrivateMessages({
  //           userId: user.id,
  //           reciverId: +currentThread.userId,
  //           limit: currentThread.limit,
  //           offset: currentThread.offset,
  //         })
  //       );
  //     }
  //     dispatch(
  //       updateConversations({
  //         key: "currentThread",
  //         value: { ...currentThread, offset: currentThread.offset + 10 },
  //       })
  //     );
  //   }
  // }, [isIntersecting]);

  // console.log(isIntersecting, "currentThread");

  if (
    currentThread.threadId === "" &&
    currentThread.userId === "" &&
    !comments
  ) {
    return (
      <div className="h-full w-full flex justify-center items-center">
        <Empty description="No Chat Selected" />
      </div>
    );
  }

  return (
    <div
      className={`h-full   overflow-y-scroll overflow-x-hidden relative ${
        comments ? " " : "grid"
      }`}
      ref={chatWindow}
    >
      <div className={`flex flex-col last:mb-2 justify-end self-end`}>
        {comments ? (
          <></>
        ) : (
          <>
            <div className="mt-2 ml-2 w-full" ref={ref}>
              <CustomFrostyMessage shadow={false} close={false}>
                <div className=" text-primary-600 text-xs  whitespace-nowrap font-medium pr-2">
                  This is the very beginning of your conversation{" "}
                  {currentThread.type === "group" ? "in" : "with"}{" "}
                  <span className="capitalize">
                    {currentThread.type === "group"
                      ? currentThread.threadName
                      : `${currentThread.firstname} ${currentThread.lastname}`}
                  </span>
                  . You can share messages and files here.
                </div>
              </CustomFrostyMessage>
            </div>
          </>
        )}
        {getMessage()}

        <TypingIndicator
          typingStatus={typingStatusState}
          currentThread={currentThread}
          reactToMessage={reactToMessage}
          deleteMessage={deleteMessage}
        />
      </div>
    </div>
  );
};

export default MainChatWindow;
