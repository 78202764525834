import { Checkbox, Switch, Upload } from "antd";
import TextArea from "antd/lib/input/TextArea";
import React, { useState } from "react";
import CustomButton from "../../../Core/Common/CustomButton";
import CustomInput from "../../../Core/Common/CustomInput";
import CustomModal from "../../../Core/Common/CustomModal";
import CustomSelect from "../../../Core/Common/CustomSelect";
import { getNotificationActions } from "../../../Core/redux/api/settingsAPI";
import { useAppDispatch, useAppSelector } from "../../../Core/redux/hooks";
import { useFormik } from "formik";
import * as Yup from "yup";
import { DatePicker } from "antd";
import dayjs from "dayjs";
import CustomDatePicker from "../../../Core/Common/CustomDatePicker";
import CustomAddressInput from "../../../Core/Common/CustomAddressInput";
import { CustomPhaseCard } from "../../../Core/Common/CustomPhaseCard";
import PhaseDetails from "./PhaseDetails";
import ComponentDetails from "./ComponentDetails";
import { useParams } from "react-router-dom";
import {
  getProjectDetatis,
  updateProjectDetails,
} from "../../../Core/redux/api/projectAPI";
import axios from "axios";
import AntdImgCrop from "antd-img-crop";
import uniqid from "uniqid";
import CustomAvatar from "../../../Core/Common/CustomAvatar";

const switchTab = (tab, switchChecked, handleSwitchCheck, handleCheck) => {
  const { notificationActions } = useAppSelector((state) => state.settings);
  const dispatch = useAppDispatch();
  const { projectDetails } = useAppSelector((state) => state.overview);
  const { overview } = useAppSelector((state) => state.projects);
  const manageProjects = overview?.[1]?.[0];
  const { projectId } = useParams();

  console.log("uwuwuw", projectDetails);

  const [fileList, setFileList] = useState([]);

  const [fileUploadedList, setFileUploadedList] = useState([]);

  const handleChange = ({ fileList: newFileList }, isProject) => {
    console.log(newFileList, "newFileList dleetetest");
    setFileList(newFileList);
    const fileData = [];

    console.log(newFileList);

    newFileList.forEach((file) => {
      const { name, size, type, lastModified, status } = file;

      if (status !== "done") return;

      file.response.forEach((xfile) => {
        const { fileUrl: url, thumbnail } = xfile;

        fileData.push({
          name,
          url,
          size,
          type,
          lastModified,
          savedInVault: false,
          thumbnail: thumbnail.fileUrl,
        });
      });
    });
    console.log(fileData, "file");
    setFileUploadedList(fileData);

    if (isProject) {
      formik.setFieldValue("projectImage", fileData[0]?.url || "");
    } else {
      formik.setFieldValue("clientImage", fileData[0]?.url || "");
    }
  };

  const uploadImage = (options: any) => {
    // below file element is the file you uploaded
    const { onSuccess, onError, file, onProgress } = options;
    const fmData = new FormData();
    const config = {
      headers: {
        "content-type": "multipart/form-data",
        // Authorization: "Bearer " + userLocal.token,
      },
      onUploadProgress: (event) => {
        onProgress({ percent: (event.loaded / event.total) * 100 });
      },
    };
    console.log("FILE: ", file);
    // create copy file and change the name when it is appending to data
    const data = new FormData();
    data.append(
      "file",
      file,
      uniqid(`${file.name.split(".")[0]}-`, `.${file.name.split(".").at(-1)}`)
    ); // replace all the spaces in the file name with -
    const _file = data.get("file");
    fmData.append("image", _file);
    try {
      // then send the copy file to the server
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "/v1/upload/storej",
          fmData,
          config
        )
        .then((res) => {
          console.log("UPLOADED", res.data);
          onSuccess(res.data);
        });
    } catch (err) {
      onError({ err });
    }
  };

  const handleUpdate = async (values) => {
    const {
      projectName: title,
      startDate,
      endDate,
      clientName,
      clientLocation,
      clientImage,
      projectImage,
    } = values;

    dispatch(
      updateProjectDetails({
        id: projectId,
        title,
        startDate,
        endDate,
        clientName,
        clientLocation,
        projectImage,
        clientImage,
        // projectImage: projectImage,
      })
    ).then(() => {
      dispatch(getProjectDetatis({ projectId }));
    });
  };

  const {
    title,
    startDate,
    endDate,
    clientName,
    clientLocation,
    projectImage,
    clientImage,
  } = projectDetails;

  const formik = useFormik({
    initialValues: {
      projectName: title,
      projectImage,
      startDate: startDate ? dayjs(startDate) : null,
      endDate: endDate ? dayjs(endDate) : null,
      clientName: clientName,
      clientLocation: clientLocation,
      clientImage,
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      projectName: Yup.string()
        .required("This field is required")
        .typeError("This field is required"),

      startDate: Yup.string()
        .required("This field is required")
        .typeError("This field is required"),
      endDate: Yup.string()
        .required("This field is required")
        .typeError("This field is required"),
      clientName: Yup.string()
        .required("This field is required")
        .typeError("This field is required"),
      clientLocation: Yup.string()
        .required("This field is required")
        .typeError("This field is required"),
    }),
    onSubmit: (values) => {
      console.log("values", values);
      handleUpdate(values);
    },
  });
  // const [locationName, setLocationName] = React.useState("");

  switch (tab) {
    case 1:
      return (
        <form
          autoComplete="off"
          // style={{ width: 520 }}
          onSubmit={formik.handleSubmit}
          id="formik"
          className="mt-6"
        >
          <div>
            <div className="flex items-center">
              <CustomInput
                label="Project name"
                labelDefaultCase
                width="w-[325px]"
                name={"projectName"}
                placeholder="Enter project name"
                {...formik.getFieldProps("projectName")}
                formikHook={formik}
              />
              <div className="flex items-center ml-5 mt-[18px]">
                <CustomAvatar
                  title={projectDetails.title}
                  size={32}
                  color={projectDetails.colorCode}
                  whiteText
                  fontSize="16px"
                  src={formik.getFieldProps("projectImage").value}
                />
                <AntdImgCrop rotationSlider modalClassName="image-crop-modal ">
                  <Upload
                    action={process.env.REACT_APP_API_URL + "/v1/upload"}
                    // headers={{ Authorization: "Bearer " + user.token }}
                    customRequest={uploadImage}
                    fileList={fileList}
                    onChange={(data) => handleChange(data, true)}
                    showUploadList={false}
                  >
                    {manageProjects?.update === 1 && (
                      <div className="flex items-center ">
                        <img src="/images/icons/edit-2.svg" className="ml-3" />
                        <p className="mon-500-14 text-primary-600 pl-2">
                          Change Image
                        </p>
                      </div>
                    )}
                  </Upload>
                </AntdImgCrop>
              </div>
            </div>
            <CustomDatePicker
              className="w-full h-10 mt-6 bg-transparent border-gray-300 rounded-xl shadow-xs"
              label="Start Date"
              labelDefaultCase
              suffix={<></>}
              format="DD MMM YYYY"
              value={formik.values.startDate}
              width="w-[325px]"
              name="startDate"
              // {...formik.getFieldProps("startDate")}
              // formikHook={formik}
              onChange={(e) => {
                console.log(e);
                formik.setFieldValue("startDate", dayjs(e));
              }}
            />
            <CustomDatePicker
              className="w-full h-10 mt-6 pt-6 bg-transparent border-gray-300 rounded-xl shadow-xs"
              label="End Date"
              labelDefaultCase
              suffix={<></>}
              format="DD MMM YYYY"
              value={dayjs() as any}
              width="w-[325px]"
              name="endDate"
              {...formik.getFieldProps("endDate")}
              formikHook={formik}
              onChange={(e) => {
                console.log(e, dayjs(e), dayjs(e));
                formik.setFieldValue("endDate", dayjs(e));
              }}
            />
            <div className="flex items-center">
              <CustomInput
                label="Client name"
                labelDefaultCase
                labelClassname="mt-12"
                className="mt-6"
                width="w-[325px]"
                name={"clientName"}
                placeholder="Enter client name"
                {...formik.getFieldProps("clientName")}
                formikHook={formik}
              />
              <div
                className="flex items-center ml-5 "
                style={{
                  marginTop: "96px",
                }}
              >
                <CustomAvatar
                  title={projectDetails.clientName}
                  size={32}
                  color={projectDetails.clientColor}
                  whiteText
                  fontSize="16px"
                  src={formik.getFieldProps("clientImage").value}
                />
                <AntdImgCrop rotationSlider modalClassName="image-crop-modal ">
                  <Upload
                    action={process.env.REACT_APP_API_URL + "/v1/upload"}
                    // headers={{ Authorization: "Bearer " + user.token }}
                    customRequest={uploadImage}
                    fileList={fileList}
                    onChange={(data) => handleChange(data, false)}
                    showUploadList={false}
                  >
                    {manageProjects?.update === 1 && (
                      <div className="flex items-center ">
                        <img src="/images/icons/edit-2.svg" className="ml-3" />
                        <p className="mon-500-14 text-primary-600 pl-2">
                          Change Image
                        </p>
                      </div>
                    )}
                  </Upload>
                </AntdImgCrop>
              </div>
            </div>

            <div className="mon-500-12 text-gray-700 false mb-1.5 mt-6">
              Based in
            </div>
            <CustomAddressInput
              className="border border-grey-200 rounded-lg w-[325px] pr-2"
              placeholder="Select city"
              {...formik.getFieldProps("clientLocation")}
              value={formik.values.clientLocation}
              onChange={(v) => formik.setFieldValue("clientLocation", v)}
              onSelect={(location) => {
                console.log(location);
              }}
              name="clientLocation"
              formikHook={formik}
            />
            <CustomInput
              label="Current time and Time Zone"
              value={`${dayjs().format("hh:MM a")}, ${
                Intl.DateTimeFormat().resolvedOptions().timeZone
              }`}
              labelDefaultCase
              disabled
              className="mt-6"
              width="w-[325px]"
              name={"projectName"}
              placeholder="Enter project name"
              // {...formik.getFieldProps("email")}
              // formikHook={formik}
            />
            {manageProjects?.update === 1 && (
              <div className="mt-5">
                <CustomButton
                  text="Save Changes"
                  disabled={!(formik.isValid && formik.dirty)}
                  type="submit"
                  className={`border border-gray-200 text-white ${
                    !(formik.isValid && formik.dirty)
                      ? "bg-primary-100"
                      : "bg-primary-600"
                  } mon-500-16 mr-6
                 `}
                  height="36px"
                  // width="110px"
                />
                <CustomButton
                  text="Cancel"
                  //   onClick={onCancel}
                  className="border border-gray-200 text-black bg-white mon-500-16 "
                  height="36px"
                  width="110px"
                />
              </div>
            )}
          </div>
        </form>
      );
    case 2:
      return <PhaseDetails />;
    case 3:
      return <ComponentDetails />;
  }
};

const DetailsTab = (props) => {
  const [selectedTab, setSelectedTab] = React.useState(1);
  const { projectId } = useParams();

  const [checked, setChecked] = React.useState(1);
  const [selectedNotifications, setSelectedNotifications] = React.useState([]);
  const [createAlertModal, setCreateAlertModal] = React.useState(false);
  const [selectedFrequency, setSelectedFrequency] = React.useState(1);
  const handleSwitchCheck = () => {
    if (checked === 1) {
      setChecked(2);
    } else {
      setChecked(1);
    }
  };
  const handleCheck = (action) => {
    if (selectedNotifications.includes(action)) {
      const payload = selectedNotifications.filter((i) => i !== action);
      setSelectedNotifications(payload);
    } else {
      const data = [...selectedNotifications, action];
      setSelectedNotifications(data);
    }
  };

  const dispatch = useAppDispatch();

  React.useEffect(() => {
    if (selectedTab === 1) dispatch(getNotificationActions());
    if (projectId) {
      dispatch(getProjectDetatis({ projectId }));
    }
  }, []);

  const frequencyData = [
    {
      id: 1,
      value: "Once",
    },
    {
      id: 2,
      value: "Twice",
    },
    {
      id: 3,
      value: "Thrice",
    },
  ];

  const TABS = [
    { id: 1, title: "Project Info" },
    { id: 2, title: "Phases" },
    { id: 3, title: "Components" },
  ];

  return (
    <div className="px-6 py-3">
      <div className="flex justify-between items-center">
        <div className="flex justify-start items-center gap-x-6">
          {TABS.map(({ id, title }) => (
            <p
              key={id}
              onClick={() => setSelectedTab(id)}
              className={`cursor-pointer ${
                selectedTab === id
                  ? "border-b-2 mon-500-12 text-primary-600 border-primary-600"
                  : "mon-400-12 text-gray-500 mb-1"
              }`}
            >
              {title}
            </p>
          ))}
        </div>
        {/* <div className="flex justify-end items-center gap-x-2">
          <CustomButton
            icon="/images/icons/plus-black.svg"
            text="Create Alert"
            onClick={() => setCreateAlertModal(true)}
            className="mon-500-14 bg-white rounded-xl border border-gray-200"
            width="145px"
          />
        </div> */}
      </div>
      <div className="mt-6">
        {switchTab(selectedTab, checked, handleSwitchCheck, handleCheck)}
      </div>
    </div>
  );
};

export default DetailsTab;
