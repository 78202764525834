import React, { useState, useEffect } from "react";
import { Drawer, Tabs, Steps, Tooltip } from "antd";
import CustomModal from "../../../Core/Common/CustomModal";
import CustomButton from "../../../Core/Common/CustomButton";
import CustomInputWithEdit from "../../../Core/Common/CustomInputWithEdit";
import { Dropdown, Input, Menu } from "antd";
import { CustomSelectWithInlineSearch } from "../../../Core/Common/CustomSelectWithSearch";
import CustomSearch from "../../../Core/Common/CustomSearch";
import CustomAvatar from "../../../Core/Common/CustomAvatar";
import { useAppSelector, useAppDispatch } from "../../../Core/redux/hooks";
import PhaseIcon from "../../../Core/svg/PhaseIcon";
import dayjs from "dayjs";
import CustomPhaseGoalsSelector from "../../../Core/Common/CustomPhaseGoalsSelector";
import CustomSelect from "../../../Core/Common/CustomSelect";
import {
  updateVaultFolder,
  getAllFolders,
} from "../../../Core/redux/api/vaultAPI";
import { useParams } from "react-router-dom";
import { client } from "../../../Core/utils/axiosClient";
import bytesToSize from "../../../Core/utils/bytesToSize";
import { folder } from "jszip";
import { getActivityLogs } from "../../../Core/redux/api/activityLogAPI";
import { TempGoogleImage } from "../../../Core/Common/CustomLink";
import { UserDropdown } from "../../ScheduleNew/Components/TaskResuable";

type ViewFileDetailsDrawerProps = {
  visible: boolean;
  onCancel: () => void;
  details: any;
};

const ViewFileDetailsDrawer = ({
  visible,
  onCancel,
  details,
}: ViewFileDetailsDrawerProps) => {
  const { projectId, "*": splat } = useParams();
  const { TabPane } = Tabs;
  const [currentTab, setCurrentTab] = useState("details");
  const [addMorePeople, setAddMorePeople] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const { userList } = useAppSelector((state) => state.overview);
  const { user } = useAppSelector((state) => state.userDetails);
  const { folders, files, showDocDetailsDrawer } = useAppSelector(
    (state) => state.vault
  );

  const [documentDetails, setDocumentDetails] = useState({
    docId: showDocDetailsDrawer.docDetails["id"],
    docName:
      showDocDetailsDrawer.docType === "folder"
        ? showDocDetailsDrawer.docDetails["folderName"]
        : showDocDetailsDrawer.docDetails["name"],
    phaseId: showDocDetailsDrawer.docDetails["phaseId"],
    goalId: showDocDetailsDrawer.docDetails["goalId"]
      ? showDocDetailsDrawer.docDetails["goalId"]
      : null,
    folderId: showDocDetailsDrawer["docId"]
      ? showDocDetailsDrawer["docId"]
      : null,
  });

  const ownerDetails = userList.filter(
    (user) => user.userId === details?.docDetails?.createdById
  )[0];
  const phaseNameById = (id) => {
    if (id === 1) return "Ideation";
    else if (id === 2) return "Design";
    else if (id === 3) return "Development";
    else if (id === 4) return "Deployment";
    else if (id === 5) return "Maintenance";
    else return "Ideation";
  };

  console.log(showDocDetailsDrawer.docDetails, "show details usesate v2");

  const { Step } = Steps;

  const { vault } = useAppSelector((state) => state.projects);
  const folderFileLink = vault?.[1]?.[0] || {};

  const dispatch = useAppDispatch();

  console.log("egeegeg", details.docDetails.usersWithAccess);

  const viewDetailsImage =
    details.docType === "folder"
      ? details.docDetails?.folderName?.includes(" Space")
        ? "/images/icons/my-space-folder.svg"
        : "/images/icons/folder-fill-vault.svg"
      : `https://www.google.com/s2/favicons?domain=${details.docDetails.url}&sz=256`;

  const [storageSpace, setStorageSpace] = useState(0);

  const getStorageSpace = async (docId) => {
    const result = await client.get(`/repository/get-storage-space`, {
      params: { folderId: docId, projectId: projectId },
    });
    console.log(result.data.result[0].sum, "check storage space");
    setStorageSpace(result.data.result[0].sum);
  };

  const [shareAccess, setShareAccess] = useState([]);

  const activityLogs = async () => {
    await dispatch(getActivityLogs({ userId: [user.id], activityType: [] }));
  };

  useEffect(() => {
    activityLogs();

    details.docType === "folder" && getStorageSpace(details.docId);
    client
      .get(`/activity-log`, {
        params: {
          userId: [user.id],
          activityType: ["21", "24"],
          docId: details.docId,
          docType: details.docType,
        },
      })
      .then((res) => setShareAccess([...res.data.result]));
  }, [details.docId]);

  const Activity = ({ docType }) => {
    const { activityLogs } = useAppSelector((state) => state.activityLogs);
    const [currentFileActivity, setCurrentFileActivity] = useState([
      {
        activityType: "",
        createdAt: "",
        firstname: "",
        id: "",
        lastname: "",
        meta: "",
        profilePicture: "",
        projectId: "",
        projectTitle: " ",
      },
    ]);
    const [currentFolderActivity, setCurrentFolderActivity] = useState([
      {
        activityType: "",
        createdAt: "",
        firstname: "",
        id: "",
        lastname: "",
        meta: "",
        profilePicture: "",
        projectId: "",
        projectTitle: " ",
      },
    ]);
    const [currentLinkActivity, setCurrentLinkActivity] = useState([
      {
        activityType: "",
        createdAt: "",
        firstname: "",
        id: "",
        lastname: "",
        meta: "",
        profilePicture: "",
        projectId: "",
        projectTitle: " ",
      },
    ]);

    const folderActivity = activityLogs.filter(
      (item) =>
        item.activityType == "18" ||
        item.activityType == "19" ||
        item.activityType == "20" ||
        item.activityType == "21" ||
        item.activityType == "24"
    );
    const fileActivity = activityLogs.filter(
      (item) =>
        item.activityType == "19" ||
        item.activityType == "21" ||
        item.activityType == "24"
    );
    const linkActivity = activityLogs.filter(
      (item) =>
        item.activityType == "20" ||
        item.activityType == "21" ||
        item.activityType == "24"
    );

    console.log(folderActivity, "folderActivity");
    useEffect(() => {
      const currentFile = fileActivity.filter(
        (item) =>
          JSON.parse(item.meta)?.id == details.docId ||
          JSON.parse(item.meta)?.folderId == details.docId ||
          JSON.parse(item.meta)?.docId == details.docId
      );
      const currentFolder = folderActivity.filter(
        (item) =>
          JSON.parse(item.meta)?.id == details.docId ||
          JSON.parse(item.meta)?.folderId == details.docId ||
          JSON.parse(item.meta)?.docId == details.docId
      );
      const currentLink = linkActivity.filter(
        (item) =>
          JSON.parse(item.meta)?.id == details.docId ||
          JSON.parse(item.meta)?.docId == details.docId
      );

      setCurrentFileActivity([...currentFile]);
      setCurrentFolderActivity([...currentFolder]);
      setCurrentLinkActivity([...currentLink]);
      console.log(currentFolder, details.docId, "file activity with folder id");
    }, []);

    const removeAccess = shareAccess.filter(
      ({ activityType }) => activityType == "24"
    );

    console.log(removeAccess, "removeAccess with filter");

    const getBody = (activity) => {
      switch (activity.activityType) {
        case "18":
          return (
            <div className="flex items-center gap-x-6" key={activity.id}>
              {" "}
              <img
                src="/images/icons/featured-icon-folder.svg"
                className="mt-6"
              />
              <div className="flex flex-col gap-y-1.5 pt-2">
                <p className="mon-500-12 text-gray-500 uppercase">
                  {activity?.createdAt
                    ? dayjs(activity?.createdAt)
                        // .utc()
                        .local()
                        .format("DD MMM, hh:mm a")
                    : ""}
                </p>

                <p className="mon-500-12 text-gray-900">New Folder uploaded</p>
                <div className="flex items-center gap-x-2">
                  <CustomAvatar
                    title={`${activity?.firstname} ${activity?.lastname}`}
                    src={activity?.profilePicture}
                    size={24}
                  />
                  <p className="mon-400-12 text-gray-700">{`${activity?.firstname} ${activity?.lastname}`}</p>
                </div>
              </div>
            </div>
          );

        case "19":
          return (
            <div className="flex items-center gap-x-6" key={activity.id}>
              {" "}
              <img
                src="/images/icons/featured-icon-folder.svg"
                className="mt-6"
              />
              <div className="flex flex-col gap-y-1.5 pt-2">
                <p className="mon-500-12 text-gray-500 uppercase">
                  {activity?.createdAt
                    ? dayjs(activity?.createdAt)
                        // .utc()
                        .local()
                        .format("DD MMM, hh:mm a")
                    : ""}
                </p>

                <p className="mon-500-12 text-gray-900">New file uploaded</p>
                <div className="flex items-center gap-x-2">
                  <CustomAvatar
                    title={`${activity?.firstname} ${activity?.lastname}`}
                    src={activity?.profilePicture}
                    size={24}
                  />
                  <>
                    {console.log(
                      // currentFileActivity,
                      fileActivity,

                      "yoooooo"
                    )}
                  </>
                  <p className="mon-400-12 text-gray-700">{`${activity?.firstname} ${activity?.lastname}`}</p>
                </div>
              </div>
            </div>
          );
        // remove access Activity

        case "20":
          return (
            <div className="flex items-center gap-x-6 mt-2">
              <img
                src="/images/icons/featured-icon-link.svg"
                className="w-8 h-8"
              />
              <div className="flex flex-col gap-y-1.5">
                <p className="mon-500-12 text-gray-500 uppercase">
                  {activity?.createdAt
                    ? dayjs(activity?.createdAt)
                        .local()
                        .format("DD MMM, hh:mm a")
                    : ""}
                </p>
                <p className="mon-500-12 text-gray-900">New Link uploaded</p>
                <div className="flex items-center gap-x-2">
                  <CustomAvatar
                    title={`${activity?.firstname} ${activity?.lastname}`}
                    src={activity?.profilePicture}
                    size={24}
                  />
                  <p className="mon-400-12 text-gray-700">{`${activity?.firstname} ${activity?.lastname}`}</p>
                </div>
              </div>
            </div>
          );
        case "24":
          return (
            <div className="flex items-center gap-x-6" key={activity.id}>
              <div className="ml-3 h-[92px] border-l-2 border-dashed border-[#DDE5ED]"></div>
              <div className="flex items-center gap-x-6">
                <img
                  src="/images/icons/featured-remove-icon.svg"
                  className="w-8 h-8"
                />
                <div className="flex flex-col gap-y-1.5">
                  <p className="mon-500-12 text-gray-500 uppercase">
                    {dayjs(activity.createdAt)
                      // .utc()
                      .local()
                      .format("DD MMM, hh:mm a")}
                  </p>
                  <p className="mon-500-12 text-gray-900">
                    {`Removed ${
                      JSON.parse(activity.meta)?.users?.length
                    } collaborators`}
                  </p>
                  <div className="flex items-center gap-x-1">
                    <>
                      {JSON.parse(activity.meta)?.users?.map((user) => (
                        <CustomAvatar
                          key={user.id}
                          title={`${user?.firstname} ${user?.lastname}`}
                          src={user?.profilePicture}
                          size={24}
                        />
                      ))}
                    </>
                  </div>
                </div>
              </div>
            </div>
          );
        case "21":
          return (
            <div className="flex items-center gap-x-6" key={activity.id}>
              <>
                {JSON.parse(activity.meta)?.users?.length > 0 ? (
                  <>
                    {/* <div className="ml-3 h-[92px] border-l-2 border-dashed border-[#DDE5ED]"></div> */}
                    <div className="flex items-center gap-x-6">
                      <img
                        src="/images/icons/featured-add-icon.svg"
                        className="w-8 h-8"
                      />
                      <div className="flex flex-col gap-y-1.5">
                        <p className="mon-500-12 text-gray-500 uppercase">
                          {dayjs(activity.createdAt)
                            .local()
                            .format("DD MMM, hh:mm a")}
                        </p>
                        <p className="mon-500-12 text-gray-900">
                          {`Added ${
                            JSON.parse(activity.meta)?.users?.length
                          } new collaborators`}
                        </p>
                        <div className="flex items-center gap-x-1">
                          <>
                            {JSON.parse(activity.meta)?.users?.map((user) => (
                              <CustomAvatar
                                key={user.id}
                                title={`${user?.firstname} ${user?.lastname}`}
                                src={user?.profilePicture}
                                size={24}
                              />
                            ))}
                          </>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </>
            </div>
          );
        default:
          return <></>;
      }
    };

    switch (docType) {
      case "file":
        return (
          <div className="flex flex-col">
            <>{currentFileActivity.map((activity) => getBody(activity))}</>
          </div>
        );

      case "folder":
        return (
          <div className="flex flex-col gap-y-2">
            <>{currentFolderActivity.map((activity) => getBody(activity))}</>
          </div>
        );

      case "link":
        return (
          <div className="flex flex-col">
            <div className="flex items-center gap-x-6 mt-2">
              <img
                src="/images/icons/featured-icon-link.svg"
                className="w-8 h-8"
              />
              <div className="flex flex-col gap-y-1.5">
                <p className="mon-500-12 text-gray-500 uppercase">
                  {currentLinkActivity[0]?.createdAt
                    ? dayjs(currentLinkActivity[0]?.createdAt)
                        .local()
                        .format("DD MMM, hh:mm a")
                    : ""}
                </p>
                <p className="mon-500-12 text-gray-900">New Link uploaded</p>
                <div className="flex items-center gap-x-2">
                  <CustomAvatar
                    title={`${currentLinkActivity[0]?.firstname} ${currentLinkActivity[0]?.lastname}`}
                    src={currentLinkActivity[0]?.profilePicture}
                    size={24}
                  />
                  <p className="mon-400-12 text-gray-700">{`${currentLinkActivity[0]?.firstname} ${currentLinkActivity[0]?.lastname}`}</p>
                </div>
              </div>
            </div>
            {shareAccess.length >= 1 ? (
              <>
                {shareAccess
                  .filter((item) => item.activityType == "21")
                  .map((entry, index) => (
                    <div key={index}>
                      <>
                        {JSON.parse(entry.meta)?.users?.length > 0 ? (
                          <>
                            <div className="ml-3 h-[92px] border-l-2 border-dashed border-[#DDE5ED]"></div>
                            <div className="flex items-center gap-x-6">
                              <img
                                src="/images/icons/featured-add-icon.svg"
                                className="w-8 h-8"
                              />
                              <div className="flex flex-col gap-y-1.5">
                                <p className="mon-500-12 text-gray-500 uppercase">
                                  {dayjs(entry.createdAt)
                                    .local()
                                    .format("DD MMM, hh:mm a")}
                                </p>
                                <p className="mon-500-12 text-gray-900">
                                  {`Added ${
                                    JSON.parse(entry.meta)?.users?.length
                                  } new collaborators`}
                                </p>
                                <div className="flex items-center gap-x-1">
                                  <>
                                    {JSON.parse(entry.meta)?.users?.map(
                                      (user) => (
                                        <CustomAvatar
                                          key={user.id}
                                          title={`${user?.firstname} ${user?.lastname}`}
                                          src={user?.profilePicture}
                                          size={24}
                                        />
                                      )
                                    )}
                                  </>
                                </div>
                              </div>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}
                      </>
                      {/* <div className="ml-3 h-[92px] border-l-2 border-dashed border-[#DDE5ED]"></div>
                                                                                                                    <div className="flex items-center gap-x-6">
                                                                                                                    <img
                                                                                                                    src="/images/icons/featured-add-icon.svg"
                                                                                                                    className="w-8 h-8"
                                                                                                                    />
                                                                                                                    <div className="flex flex-col gap-y-1.5">
                                                                                                                    <p className="mon-500-12 text-gray-500 uppercase">
                                                                                                                    {dayjs(entry.createdAt)
                                                                                                                      .local()
                                                                                                                      .format("DD MMM, hh:mm a")}
                                                                                                                      </p>
                                                                                                                      <p className="mon-500-12 text-gray-900">
                                                                                                                      {`Added ${
                                                                                                                        JSON.parse(entry.meta)?.users?.length
                                                                                                                      } new collaborators`}
                                                                                                                      </p>
                                                                                                                      <div className="flex items-center gap-x-1">
                                                                                                                      {JSON.parse(entry.meta)?.users?.map((user) => (
                                                                                                                        <CustomAvatar
                                                                                                                        key={user.id}
                                                                                                                        title={`${user?.firstname} ${user?.lastname}`}
                                                                                                                        src={user?.profilePicture}
                                                                                                                        size={24}
                                                                                                                        />
                                                                                                                        ))}
                                                                                                                        </div>
                                                                                                                        </div>
                                                                                                                      </div> */}
                    </div>
                  ))}
              </>
            ) : (
              <></>
            )}
            {removeAccess.length >= 1 ? (
              <>
                {removeAccess.map((entry, index) => (
                  <div key={index}>
                    <div className="ml-3 h-[92px] border-l-2 border-dashed border-[#DDE5ED]"></div>
                    <div className="flex items-center gap-x-6">
                      <img
                        src="/images/icons/featured-remove-icon.svg"
                        className="w-8 h-8"
                      />
                      <div className="flex flex-col gap-y-1.5">
                        <p className="mon-500-12 text-gray-500 uppercase">
                          {dayjs(entry.createdAt)
                            .local()
                            .format("DD MMM, hh:mm a")}
                        </p>
                        <p className="mon-500-12 text-gray-900">
                          {`Removed ${
                            JSON.parse(entry.meta)?.users?.length
                          } collaborators`}
                        </p>
                        <div className="flex items-center gap-x-1">
                          <>
                            {JSON.parse(entry.meta)?.users?.map((user) => (
                              <CustomAvatar
                                key={user.id}
                                title={`${user?.firstname} ${user?.lastname}`}
                                src={user?.profilePicture}
                                size={24}
                              />
                            ))}
                          </>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </>
            ) : (
              <></>
            )}
          </div>
        );
      default:
        return (
          <div className="flex flex-col gap-y-2">
            <>{currentLinkActivity.map((activity) => getBody(activity))}</>
          </div>
        );
    }
  };

  const getDocImage = () => {
    switch (details.docType) {
      case "folder":
        return <img src={viewDetailsImage} className={`w-14 h-14 mt-4 `} />;
      case "link":
        return (
          <TempGoogleImage
            src={details.docDetails.url}
            className={`w-14 h-14 mt-4 `}
            fallbackSrc={"/images/icons/link.svg"}
          />
        );
      case "file":
        return (
          <img
            src={
              details?.docDetails?.thumbnail
                ? details?.docDetails?.thumbnail
                : details?.docDetails?.url
            }
            className={`w-14 h-14 mt-4 `}
          />
        );
      default:
        return (
          <img
            src="/images/icons/no-files.svg"
            className={`w-14 h-14 mt-4 ${"rounded-full"}`}
          />
        );
    }
  };

  return (
    <Drawer
      placement="right"
      open={visible}
      closable={false}
      //className="custom-drawer"
      width="33.875rem"
      onClose={onCancel}
    >
      <div>
        <div>
          <button onClick={() => (showEdit ? setShowEdit(false) : onCancel())}>
            <img
              src="/images/icons/chevron-left-double.svg"
              alt="close"
              className={`${visible && "rotate-180 duration-1000"}`}
            />
          </button>

          {showEdit && (
            <div>
              <div className="flex justify-center w-full">{getDocImage()}</div>
              <div className="py-4 mt-4">
                <CustomInputWithEdit
                  value={documentDetails.docName}
                  placeholder="Add a name"
                  onChange={(e) =>
                    setDocumentDetails({
                      ...documentDetails,
                      docName: e.target.value,
                    })
                  }
                />

                {showDocDetailsDrawer.docType === "folder" ? (
                  <>
                    <CustomPhaseGoalsSelector
                      customWidth="w-full"
                      customPhaseTitle="phase"
                      customStyleForPhaseTitle
                      customGoalTitle="goal connected"
                      customStyleForGoalTitle
                      phaseId={documentDetails.phaseId}
                      goalId={documentDetails.goalId}
                      // onLoadingChange={(isLoading) => setShowSpinner(isLoading)}
                      onChange={(phaseGoalObj) =>
                        setDocumentDetails({
                          ...documentDetails,
                          ...phaseGoalObj,
                        })
                      }
                    />
                  </>
                ) : (
                  <>
                    <CustomPhaseGoalsSelector
                      customWidth="w-full"
                      phaseId={documentDetails.phaseId}
                      goalId={documentDetails.goalId}
                      // onLoadingChange={(isLoading) => setShowSpinner(isLoading)}
                      onChange={(phaseGoalObj) =>
                        setDocumentDetails({
                          ...documentDetails,
                          ...phaseGoalObj,
                        })
                      }
                    />
                    <div className="mon-500-14 text-gray-700 mt-9">
                      Folder (optional)
                      <div className="mt-1.5">
                        <div className="mt-1.5 w-full">
                          <CustomSelect
                            value={documentDetails.folderId?.toString()}
                            options={folders.map((item) => {
                              return {
                                id: item.id?.toString(),
                                title: item.folderName,
                                value: documentDetails.folderId?.toString(),
                                render: (value) => (
                                  <div
                                    className="flex items-center -mt-0.5"
                                    onClick={() =>
                                      setDocumentDetails({
                                        ...documentDetails,
                                        // folderName: item.folderName,
                                        folderId: item.id?.toString(),
                                      })
                                    }
                                  >
                                    <img
                                      src="/images/icons/folder-fill.svg"
                                      className="h-4 w-4"
                                    />
                                    <p className="mon-400-14 pl-2 text-gray-900 capitalize ">
                                      {item.folderName}
                                    </p>
                                  </div>
                                ),
                              };
                            })}
                            placeholder="Select folder"
                          />
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>

              <div className="flex items-center justify-end bottom-0 left-0 absolute shadow-upward w-full h-[72px] px-5 gap-x-4">
                <CustomButton
                  text="Cancel"
                  onClick={() => (showEdit ? setShowEdit(false) : onCancel())}
                  className="border border-gray-200 text-gray-700 bg-white mon-500-14"
                  height="40px"
                  width="81px"
                />
                <CustomButton
                  text="Save"
                  onClick={async () => {
                    showEdit ? setShowEdit(false) : onCancel();
                    await dispatch(
                      updateVaultFolder({
                        folderName: documentDetails.docName,
                        folderId: documentDetails.folderId,
                        userId: user.id,
                      })
                    );
                    await dispatch(
                      getAllFolders({
                        projectId: projectId,
                      })
                    );
                  }}
                  className="border border-gray-200 text-white bg-primary-600 mon-500-14"
                  height="40px"
                  width="81px"
                />
              </div>
            </div>
          )}
          {!showEdit && (
            <div>
              <div className="flex flex-col items-center">
                {getDocImage()}
                <Tooltip
                  title={
                    details.docType === "folder"
                      ? details.docDetails?.folderName?.includes(" Space")
                        ? `${user?.firstName}'s Space`
                        : details.docDetails?.folderName
                      : details.docDetails?.name
                  }
                >
                  <div className="text-center capitalize mon-500-18 mt-3 text-black truncate max-w-[25rem] ">
                    {details.docType === "folder"
                      ? details.docDetails?.folderName?.includes(" Space")
                        ? `${user?.firstName}'s Space`
                        : details.docDetails?.folderName
                      : details.docDetails?.name}
                  </div>
                </Tooltip>
              </div>
              {folderFileLink.update &&
                !(
                  details.docType === "folder" &&
                  details.docDetails?.folderName?.includes(" Space")
                ) &&
                currentTab === "details" && (
                  <>
                    {details.docDetails?.isOwner == 1 ||
                    details.docDetails?.canEdit == 1 ? (
                      <div
                        className="flex float-right mt-9 cursor-pointer"
                        onClick={() => setShowEdit(true)}
                      >
                        <img
                          src="/images/icons/new-edit.svg"
                          className="w-5 h-5 ml-2"
                        />
                        <p className="mon-500-14 text-gray-500 pl-1.5">Edit</p>
                      </div>
                    ) : (
                      <></>
                    )}
                  </>
                )}
              <Tabs
                onTabClick={(key) => {
                  setCurrentTab(key);
                }}
                activeKey={currentTab}
                className="overview-tab mt-8"
              >
                <TabPane
                  tab={
                    <div className="flex">
                      <div
                        className={`${
                          currentTab === "details"
                            ? "font-medium text-xs text-primary-600"
                            : "text-gray-500 text-xs font-normal"
                        }`}
                        style={{ fontSize: "12px" }}
                      >
                        Details
                      </div>
                    </div>
                  }
                  key="details"
                >
                  <div className="flex flex-col">
                    {!(
                      details.docType === "folder" &&
                      details.docDetails?.folderName?.includes(" Space")
                    ) && (
                      <div className="mt-4">
                        <p className="uppercase mon-500-10 text-gray-500">
                          phase
                        </p>
                        {details.docDetails?.phaseId === null ? (
                          "--"
                        ) : (
                          <div className="flex items-center pt-3">
                            <PhaseIcon
                              phaseId={+details.docDetails?.phaseId}
                              width="16px"
                              height="16px"
                              icon
                            />
                            <p className="capitalize mon-500-12 text-gray-700 pl-2">
                              {phaseNameById(+details.docDetails?.phaseId)}
                            </p>
                          </div>
                        )}
                      </div>
                    )}
                    {!(details.docType === "folder") && (
                      <div className="mt-9">
                        <p className="uppercase mon-500-10 text-gray-500">
                          folder
                        </p>
                        <p className="mon-500-12 text-gray-700 mt-2 capitalize">
                          {details.docDetails?.folderName
                            ? details.docDetails?.folderName
                            : // : folders.filter(
                              //     (folder) =>
                              //       folder.id === details.docDetails?.folderId
                              //   )[0]?.folderName}
                              "--"}
                        </p>
                      </div>
                    )}
                    {!(
                      details.docType === "folder" &&
                      details.docDetails?.folderName?.includes(" Space")
                    ) && (
                      <div className="mt-9">
                        <p className="uppercase mon-500-10 text-gray-500">
                          task connected
                        </p>
                        <p className="mon-500-12 text-gray-700 mt-2">
                          {details.docDetails?.goalId
                            ? details.docDetails?.goalId
                            : "--"}
                        </p>
                      </div>
                    )}

                    {details.docDetails?.username && (
                      <>
                        <div className="mt-9">
                          <p className="uppercase mon-500-10 text-gray-500">
                            username
                          </p>
                          <p className="mon-500-12 text-gray-700 mt-2">
                            {details.docDetails?.username}
                          </p>
                        </div>
                        <div className="mt-9">
                          <p className="uppercase mon-500-10 text-gray-500">
                            password
                          </p>
                          <p className="mon-500-12 text-gray-700 mt-2">
                            {details.docDetails?.password}
                          </p>
                        </div>
                      </>
                    )}
                    <div className="mt-9">
                      <p className="uppercase mon-500-10 text-gray-500">
                        owner
                      </p>
                      <div className="flex items-center pt-3">
                        <>
                          <CustomAvatar
                            title={`${
                              ownerDetails?.firstname
                                ? ownerDetails.firstname
                                : "User"
                            } ${
                              ownerDetails?.lastname
                                ? ownerDetails.lastname
                                : ""
                            }`}
                            src={ownerDetails?.profilePicture}
                          />
                          <p className="capitalize mon-500-12 text-gray-700 pl-2">
                            {`${
                              ownerDetails?.firstname
                                ? ownerDetails.firstname
                                : "User"
                            } ${
                              ownerDetails?.lastname
                                ? ownerDetails.lastname
                                : ""
                            }`}
                          </p>
                        </>
                      </div>
                      {/* <p className="uppercase mon-500-10 text-primary-600 mt-2 cursor-pointer">
                        Transfer Ownership ?
                      </p>
                      <UserDropdown
                        fieldName="Assignee"
                        task={{}}
                        setTask={(e) => console.log(e)}
                        userList={userList}
                        onEdit={(e) => {
                          console.log(e);
                        }}
                      /> */}
                    </div>
                    <div className="mt-9">
                      <p className="uppercase mon-500-10 text-gray-500">
                        created on
                      </p>
                      <p className="mon-500-12 text-black mt-2">
                        {details.docDetails?.createdAt
                          ? dayjs(details.docDetails?.createdAt)
                              .local()
                              .format("DD MMM YYYY, hh:mm a")
                          : "N/A"}
                      </p>
                    </div>
                    <div className="mt-9">
                      <p className="uppercase mon-500-10 text-gray-500">size</p>
                      <p className="mon-500-12 text-black mt-2">
                        {details.docType !== "link" &&
                        details.docDetails?.fileSize
                          ? `${bytesToSize(details.docDetails?.fileSize)}`
                          : details.docType === "folder" &&
                            +details.docDetails?.filesCount === 0
                          ? "0 KB"
                          : bytesToSize(storageSpace)}
                      </p>
                    </div>
                  </div>
                </TabPane>
                {!(
                  details.docType === "folder" &&
                  details.docDetails?.folderName?.includes(" Space")
                ) && (
                  <TabPane
                    tab={
                      <div className="flex">
                        <div
                          className={`${
                            currentTab === "collaborators"
                              ? "font-medium text-sm text-primary-600"
                              : "text-gray-500 text-xs font-normal"
                          }`}
                          style={{ fontSize: "12px" }}
                        >
                          Collaborators
                        </div>
                      </div>
                    }
                    key="collaborators"
                  >
                    <div>
                      <div className="min-w-min mt-4">
                        {details.docDetails.usersWithAccess?.map((item) => (
                          <div
                            className="flex justify-between items-center py-3"
                            key={item.id}
                          >
                            <div className="flex items-center gap-x-2">
                              <CustomAvatar
                                src={item.profilePicture}
                                title={`${
                                  item.firstname ? item.firstname : ""
                                } ${item.lastname ? item.lastname : ""}`}
                                size={24}
                              />
                              <p className="mon-400-12 text-gray-700">
                                {item.firstname}{" "}
                                {item.lastname ? item.lastname : ""}
                              </p>
                            </div>
                            <p className="mon-400-12 text-gray-500 ml-7">
                              {item.isOwner
                                ? "Owner"
                                : item.canEdit
                                ? "Can edit"
                                : "Can view"}
                            </p>
                          </div>
                        ))}
                      </div>
                      {/* <div className="flex justify-between items-center mt-8 cursor-pointer">
                        <div
                          className="flex"
                          onClick={() => setAddMorePeople(true)}
                        >
                          <img
                            src="/images/icons/add-user.svg"
                            className="w-5 h-5"
                          />
                          <p className="mon-500-14 text-primary-700 pl-2 ">
                            Add more people
                          </p>
                        </div>
                        {addMorePeople && (
                          <div onClick={() => setAddMorePeople(false)}>
                            <img
                              src="/images/icons/x-close.svg"
                              className="w-5 h-5"
                            />
                          </div>
                        )}
                      </div>
                      {addMorePeople && (
                        <div className="pt-7">
                          <CustomSearch placeholder="Search team members" />
                        </div>
                      )} */}
                    </div>
                  </TabPane>
                )}
                <TabPane
                  tab={
                    <div className="flex">
                      <div
                        className={`${
                          currentTab === "activity"
                            ? "font-medium text-xs text-primary-600"
                            : "text-gray-500 text-xs font-normal"
                        }`}
                        style={{ fontSize: "12px" }}
                      >
                        Activity
                      </div>
                    </div>
                  }
                  key="activity"
                >
                  <Activity docType={details.docType} />
                </TabPane>
              </Tabs>
            </div>
          )}
        </div>
      </div>
    </Drawer>
  );
};

export default ViewFileDetailsDrawer;
