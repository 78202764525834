import React, { useState, useEffect } from "react";
import MessageDotsCircleIcon from "../svg/MessageDotsCircleIcon";
import copyTextToClipboard from "../utils/clipboard";
import { Tooltip } from "antd";
import { useAppDispatch, useAppSelector } from "../../Core/redux/hooks";
import { updateConversations } from "../../Core/redux/slices/conversations.slice";
import { updateVault } from "../redux/slices/vault.slice";
import PeopleAccessDropdown from "../../Modules/Vault/PeopleAccessDropdown";
import MenuDropdown from "../../Modules/Conversations/Components/MenuDropdown";
import ShareAccessModal from "../../Modules/Conversations/Components/ShareAccessModal";
import exportToCsv from "../utils/exportToCsv";
import PhaseIcon from "../svg/PhaseIcon";
import CustomImageWithFallBack from "./CustomImageWithFallback";

type Props = {
  title: string;
  link;
  id: number;
  usersWithAccess?: [];
};

const defaultProps = {
  //     title: 'Title',
  //     link: null
};

export const TempGoogleImage = ({ src, fallbackSrc, ...props }) => {
  const [isLoadedSuccessFull, setIsLoadedSuccesFull] = useState(true);
  if (isLoadedSuccessFull) {
    return (
      <img
        src={`https://www.google.com/s2/favicons?domain=${src}&sz=256&fallback_url=/images/icons/link.svg`}
        {...props}
        onLoad={(e) => {
          if (e.currentTarget.naturalHeight == 16) {
            setIsLoadedSuccesFull(false);
          }
        }}
        onError={({ currentTarget }) => {
          setIsLoadedSuccesFull(false);
        }}
      />
    );
  }

  return <img src={fallbackSrc} {...props} />;
};

const CustomLink = ({ title, link, id, usersWithAccess }: Props) => {
  const copyToClipboard = (data) => {
    copyTextToClipboard(data);
  };

  const { vault } = useAppSelector((state) => state.projects);

  const viewAccess = vault?.[1]?.[0] || {};
  const folderFileLink = vault?.[2]?.[0] || {};
  const requestAccess = vault?.[6]?.[0] || {};
  const deleteAll = vault?.[4]?.[0] || {};
  const commentAccess = vault?.[5]?.[0] || {};

  const menuDropdown = [
    {
      id: 1,
      image: "/images/icons/copy.svg",
      text: "Copy username",
      textColor: "text-gray-700",
      onclick: () => {
        copyToClipboard(link.username);
      },
    },

    {
      id: 2,
      image: "/images/icons/copy.svg",
      text: "Copy password",
      textColor: "text-gray-700",
      borderBottom: true,
      onclick: () => {
        copyToClipboard(link.password);
      },
    },

    folderFileLink.read
      ? {
          id: 3,
          image: "/images/icons/info-circle.svg",
          text: "View details",
          textColor: "text-gray-700",
          //  borderBottom: true,
          onclick: () =>
            dispatch(
              updateVault({
                key: "showDocDetailsDrawer",
                value: {
                  visible: true,
                  docId: link.id,
                  docType: "link",
                  docDetails: link,
                },
              })
            ),
        }
      : null,
    folderFileLink.update && (link.isOwner === 1 || link.canEdit === 1)
      ? {
          id: 10,
          image: "/images/icons/edit-01.svg",
          text: "Edit details",
          textColor: "text-gray-700",
          borderBottom: true,
          onclick: () =>
            dispatch(
              updateVault({
                key: "openLinkModal",
                value: {
                  visible: true,
                  link,
                  id: id,
                },
              })
            ),
        }
      : null,
    commentAccess.read
      ? {
          id: 4,
          image: "/images/icons/message-dots-circle-gray.svg",
          text: "Open comments",
          textColor: "text-gray-700",
          onclick: () =>
            dispatch(
              updateVault({
                key: "openCommentsModal",
                value: {
                  visible: true,
                  docType: "link",
                  name: title,
                  docId: id,
                  comments: [],
                  users: usersWithAccess,
                },
              })
            ),
        }
      : null,
    folderFileLink.update && (link.isOwner === 1 || link.canEdit === 1)
      ? {
          id: 5,
          image: "/images/icons/add-user-gray.svg",
          text: "Share access",
          textColor: "text-gray-700",
          onclick: () => {
            setOpenShareAccessModal(true);
            dispatch(
              updateVault({
                key: "shareAccessDoc",
                value: {
                  docId: id,
                  users: usersWithAccess,
                  docType: "link",
                  docDetails: link,
                },
              })
            );
          },
        }
      : null,
    folderFileLink.update && (link.isOwner === 1 || link.canEdit === 1)
      ? {
          id: 6,
          image: "/images/icons/move-to.svg",
          text: "Move to",
          textColor: "text-gray-700",
          onclick: () =>
            dispatch(
              updateVault({
                key: "moveToFolderModal",
                value: {
                  visible: true,
                  docId: id.toString(),
                  docType: "links",
                },
              })
            ),
        }
      : null,
    {
      id: 7,
      image: "/images/icons/copy-dashed.svg",
      text: "Make a copy",
      textColor: "text-gray-700",
      onclick: () =>
        dispatch(
          updateVault({
            key: "copyModal",
            value: {
              visible: true,
              docType: "link",
              id: id.toString(),
            },
          })
        ),
    },
    link.isOwner === 1
      ? {
          id: 10,
          image: "/images/icons/user-right-01.svg",
          text: "Transfer Ownership",
          textColor: "text-gray-700",
          onclick: () => {
            dispatch(
              updateVault({
                key: "transferOwnershipModal",
                value: {
                  visible: true,
                  id: id.toString(),
                  docType: 3,
                  usersWithAccess,
                },
              })
            );
          },
        }
      : null,
    {
      id: 8,
      image: "/images/icons/export.svg",
      text: "Export as .csv",
      textColor: "text-gray-700",
      borderBottom: true,
      onclick: () =>
        exportToCsv(`${new Date()}.csv`, [
          ["Title", "Link", "Username", "Password"],
          [title, link.url, link.username, link.password],
        ]),
    },

    folderFileLink.delete && (link.isOwner === 1 || link.canEdit === 1)
      ? {
          id: 9,
          image: "/images/icons/trash.svg",
          text: "Delete",
          textColor: "text-error-600",
          onclick: () =>
            dispatch(
              updateVault({
                key: "deleteLinkModal",
                value: {
                  visible: true,
                  id: id,
                },
              })
            ),
        }
      : null,
  ];

  const getMenuDropdown = (link) => {
    if (link.username && link.password) {
      return menuDropdown;
    } else {
      return menuDropdown.slice(2, menuDropdown.length);
    }
  };

  const dispatch = useAppDispatch();
  const [openShareAccessModal, setOpenShareAccessModal] = useState(false);
  const { openCommentsModal } = useAppSelector((state) => state.vault);

  const phaseName = (id) => {
    switch (id) {
      case "1":
        return "Ideation phase";
      case "2":
        return "Design phase";
      case "3":
        return "Development phase";
      case "4":
        return "Deployment phase";
      case "5":
        return "Maintenance phase";
      default:
        return "Ideation phase";
    }
  };

  return (
    <div className="actions-row relative w-[173px] h-[182px] cursor-pointer rounded-xl py-[17px] bg-gray-25">
      <div className="px-[17px] pb-5 flex justify-between">
        <div className="flex items-center">
          {link.phaseId && (
            <Tooltip title={phaseName(link.phaseId)}>
              <div>
                <PhaseIcon width="12px" height="12px" phaseId={+link.phaseId} />
              </div>{" "}
            </Tooltip>
          )}
          <Tooltip title="Open comments">
            <div
              className="ml-3"
              onClick={() =>
                dispatch(
                  updateVault({
                    key: "openCommentsModal",
                    value: {
                      visible: true,
                      docType: "link",
                      name: title,
                      docId: id,
                      comments: [],
                      users: usersWithAccess,
                    },
                  })
                )
              }
            >
              <MessageDotsCircleIcon
                redDot={openCommentsModal.comments.length > 0 ? true : false}
              />
            </div>
          </Tooltip>
          {usersWithAccess.length > 1 && (
            <PeopleAccessDropdown elements={usersWithAccess} />
          )}
        </div>
        <div
          className="actions absolute right-3 top-3"
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
          }}
        >
          <MenuDropdown
            elements={getMenuDropdown(link)}
            customFunc={undefined}
          />
        </div>
      </div>
      <div
        className="relative"
        onClick={() => {
          window.open(link.url, "_blank");
        }}
      >
        <div className="flex items-center justify-center mx-auto h-[72px] w-[72px] border-[1px] border-gray-50 rounded-lg">
          <div className="w-12 h-12">
            {/* <img
              src={`https://www.google.com/s2/favicons?domain=${link.url}&sz=256&fallback_url=/images/icons/link.svg`}
              className="w-full h-full"
              // onLoad={(e) => {
              //   if (
              //     e.currentTarget.naturalHeight === 16 &&
              //     e.currentTarget.src !== "/images/icons/link.svg"
              //   ) {
              //     e.currentTarget.src = "/images/icons/link.svg";
              //     e.currentTarget.onload = null;
              //     return true;
              //   }
              // }}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                console.log(
                  currentTarget,
                  "currentTargetcurrentTargetcurrentTargetcurrentTarget"
                );
                currentTarget.src = "/images/icons/link.svg";
              }}
            /> */}
            <TempGoogleImage
              src={link.url}
              className="w-full h-full"
              fallbackSrc={"/images/icons/link.svg"}
            />
          </div>
        </div>
        {/* <div className="absolute top-0 h-full opacity-0 hover:opacity-100 w-[149px] px-3">
          <div className="relative h-full">
            <div className="bg-[#ffffff37] h-full w-[149px] absolute flex justify-center items-center">
              <CustomButton
                text="Copy"
                width="97px"
                height="26px"
                onClick={copyToClipboard}
              />
            </div>
          </div>
        </div> */}
      </div>
      <div className="w-5 h-5 ml-3.5">
        <img src="/images/icons/link-yellow.svg" />
      </div>
      <div
        className="text-center mont-500-12 text-gray-700 px-4 ellipsis-two-lines"
        style={{ width: "172px", wordBreak: "break-all" }}
      >
        {title}
      </div>
      <ShareAccessModal
        visible={openShareAccessModal}
        onCancel={() => setOpenShareAccessModal(false)}
      />
    </div>
  );
};

CustomLink.defaultProps = defaultProps;
export default CustomLink;
