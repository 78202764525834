import React, { useEffect, useState } from "react";
import { DatePicker, Dropdown, Menu, Tooltip } from "antd";

import CustomTable from "../../Core/Common/CustomTable";
import EditSprintModal from "./EditSprintModal";
import NewGoalModal from "./NewGoalModal";
import DeleteSprintModal from "./DeleteSprintModal";
import { useAppDispatch, useAppSelector } from "../../Core/redux/hooks";
import {
  getGoalsBySprint,
  getSprintsByPhase,
  updateSprint,
} from "../../Core/redux/api/scheduleAPI";
import dayjs from "dayjs";
import { updateSchedule } from "../../Core/redux/slices/schedule.slice";
import EditGoalModal from "./EditGoalModal";
import NewScopeBadge from "./NewScopeBadge";
import { formatDateInRelativeTime } from "../../Core/utils/date";

const GoalsTable = ({
  sprint,
  projectId,
  phaseColor,
}: {
  sprint?: any;
  projectId: string;
  phaseColor?: string;
}) => {
  const [currentPageNo, setCurrentPageNo] = useState(1);
  const [showGoals, setShowGoals] = useState(false);
  const [editSprintModal, setEditSprintModal] = useState(false);

  const [editGoalModal, setEditGoalModal] = useState(false);
  const [deleteSprintModal, setDeleteSprintModal] = useState(false);
  const [selectedGoal, setSelectedGoal] = useState({
    id: "",
    name: "",
    dueDate: "",
    statusId: "",
    priorityId: "",
    assignee: "",
    components: [],
  });
  const dispatch = useAppDispatch();
  const [limit, setLimit] = useState(10);
  const { sprintsLoading } = useAppSelector((state) => state.schedule);

  const getPriorityIcon = (val: number) => {
    switch (val) {
      case 1:
        return (
          <div className="flex items-center ">
            <img src="/images/icons/flag-gray.svg" />
            <p className="mon-400-12 text-gray-400 ml-1.5">Low</p>
          </div>
        );
      case 2:
        return (
          <div className="flex items-center">
            <img src="/images/icons/flag-blue.svg" />
            <p className="mon-400-12 text-primary-600 ml-1.5">Medium</p>
          </div>
        );
      case 3:
        return (
          <div className="flex items-center">
            <img src="/images/icons/flag-red.svg" />
            <p className="mon-400-12 text-error-600 ml-1.5">High</p>
          </div>
        );
      default:
        return (
          <div className="flex items-center">
            <img src="/images/icons/flag-gray.svg" />
            <p className="mon-400-12 text-gray-400 ml-1.5">Low</p>
          </div>
        );
    }
  };

  const getStatus = (val: number) => {
    switch (val) {
      case 1:
        return (
          <div className="flex items-center bg-gray-100 rounded-2xl max-w-max">
            <p className="mon-500-12 text-gray-500 px-2 uppercase py-0.5">
              To Do
            </p>
          </div>
        );
      case 2:
        return (
          <div className="flex items-center bg-primary-600 rounded-2xl max-w-max">
            <p className="mon-500-12 text-white px-2 uppercase py-0.5">
              in progress
            </p>
          </div>
        );
      case 3:
        return (
          <div className="flex items-center bg-success-600 rounded-2xl max-w-max">
            <p className="mon-500-12 text-white px-2 uppercase py-0.5">Done</p>
          </div>
        );
      default:
        return (
          <div className="flex items-center bg-gray-100 rounded-2xl max-w-max">
            <p className="mon-500-12 text-gray-500 px-2 uppercase py-0.5">
              To Do
            </p>
          </div>
        );
    }
  };

  const columns = [
    {
      title: () => (
        <div className="mon-500-10 pl-3 text-gray-500 uppercase">Goal Name</div>
      ),
      //width: '12.85%',
      dataIndex: "name",
      defaultSortOrder: "descend",
      sorter: (a, b) => a.id - b.id,
      render: (text, record) => (
        <div className="flex items-center pl-2">
          <img
            src={record.img || "/images/icons/yellow-square.svg"}
            className="h-3 w-3"
            alt={text}
          />
          <div className="mon-500-12 capitalize pl-3 truncate whitespace-nowrap">
            {text}
          </div>
          {record.isNewGoal ? (
            <NewScopeBadge
              style={{
                width: "99px",
                height: "22px",
              }}
              textClassName="mon-500-12"
            />
          ) : (
            ""
          )}
        </div>
      ),
    },
    {
      title: () => (
        <div className="flex items-center">
          <div className="mon-500-10 text-gray-500 uppercase">DUE DATE</div>
          {/* <div>
            <img
              src="/images/icons/more-vertical.svg"
              className="ml-1.5"
              alt="question mark"
            />
          </div> */}
        </div>
      ),
      //width: '12.85%',
      dataIndex: "dueDate",
      defaultSortOrder: "descend",
      sorter: (a, b) => a.id - b.id,
      render: (text, record) => (
        <div
          className={` capitalize ${
            // formatDateInRelativeTime(text) === "Today"
            //   ? "text-primary-600 mon-500-12"
            //   : formatDateInRelativeTime(text) === "Yesterday" &&
            //     record.isCompleted
            //   ? "mon-400-12"
            //   : "mon-500-12 text-error-600"
            formatDateInRelativeTime(text, record.isCompleted).className
          }`}
        >
          {formatDateInRelativeTime(text, record.isCompleted).text}
        </div>
      ),
    },
    {
      title: () => (
        <div className="mon-500-10 text-gray-500 uppercase">ASSIGNEE</div>
      ),
      //width: '12.85%',
      dataIndex: "assignee",
      defaultSortOrder: "descend",
      sorter: (a, b) => a.id - b.id,
      render: (text, record) => (
        <div className="flex items-center justify-between">
          <div className="mon-400-12 text-gray-500">{text}</div>
        </div>
      ),
    },
    {
      title: () => (
        <div className="mon-500-10 text-gray-500 uppercase">PRIORITY</div>
      ),
      //width: '12.85%',
      dataIndex: "priorityId",
      defaultSortOrder: "descend",
      sorter: (a, b) => a.id - b.id,
      render: (text, record) => getPriorityIcon(text),
    },
    {
      title: () => (
        <div className="mon-500-10 text-gray-500 uppercase">STATUS</div>
      ),
      //width: '12.85%',
      dataIndex: "statusId",
      defaultSortOrder: "descend",
      sorter: (a, b) => a.id - b.id,
      render: (text, record) => getStatus(text),
    },
    {
      title: () => (
        <Tooltip title="Create a folder">
          <img src="/images/icons/folder-plus.svg" />
        </Tooltip>
      ),
      width: 70,
      dataIndex: "folder",

      render: (text, record) => (
        <Tooltip title="Open folder">
          <img src="/images/icons/folder-check.svg" alt="folderplus" />
        </Tooltip>
      ),
    },
    {
      title: () => (
        <Tooltip title="Start a thread">
          <img src="/images/icons/message-plus-circle.svg" />
        </Tooltip>
      ),
      width: 70,
      dataIndex: "message",

      render: (text, record) => (
        <Tooltip title="Open thread">
          <img src="/images/icons/message-dots-circle.svg" alt="thread" />
        </Tooltip>
      ),
    },
  ];

  // useEffect(() => {
  //   if (sprintsLoading) {
  //     setShowGoals(false);
  //   }
  // }, [sprintsLoading]);

  useEffect(() => {
    if (showGoals) {
      dispatch(getGoalsBySprint({ projectId, sprintId: sprint.id }));
    }
  }, [showGoals]);

  const completedGoals = () => {
    const totalCompletedGoals = sprint.goals.filter(
      (goal) => goal.isCompleted
    ).length;
    return totalCompletedGoals;
  };

  return (
    <div
      className="bg-white rounded-2xl mt-4 shadow-table border "
      style={{
        borderColor: phaseColor,
      }}
    >
      <div className="px-4 flex justify-between items-center h-[59px]">
        <div className="flex items-center">
          <button
            onClick={() => {
              setShowGoals(!showGoals);
            }}
          >
            <img
              src="/images/icons/chevron-up.svg"
              className={`transform duration-300 ${
                showGoals ? "rotate-180" : "rotate-0"
              }`}
              alt="dropdown"
            />
          </button>
          <img
            src="/images/icons/sprint-white.svg"
            className="px-2"
            alt="dropdown"
          />
          <p
            className={`${
              sprint.isNewSprint
                ? "mon-500-14 text-pink-600"
                : "mon-500-14 text-black "
            }`}
          >
            {sprint.name || "N/A"}
          </p>
          {sprint.isNewSprint ? (
            <NewScopeBadge
              style={{
                width: "120px",
                height: "32px",
              }}
            />
          ) : (
            ""
          )}
        </div>
        <div className="flex items-center">
          <p className="mon-400-12 whitespace-nowrap pr-3 text-gray-700">
            {sprint.goals ? (
              <>
                {completedGoals()} / {sprint.goals.length} goals done
              </>
            ) : (
              <> No Goals Added</>
            )}
          </p>
          <div
            className={`ml-3 h-[34px] rounded-lg ${
              sprint.isNewSprint ? "bg-pink-25" : "bg-gray-50"
            } `}
          >
            <div
              className={`pt-2 pl-1.5 pr-1.5 ${
                sprint.isNewSprint
                  ? "mon-500-12 text-pink-600 "
                  : "mon-400-12 text-gray-700"
              }`}
            >
              {sprint.startDate !== null
                ? dayjs(sprint.startDate).format("DD MMM")
                : "Start Date"}
              <span className=" px-1"> - </span>
              {sprint.endDate !== null
                ? dayjs(sprint.endDate).format("DD MMM")
                : "End Date"}
            </div>
          </div>

          <Dropdown
            getPopupContainer={(trigger) => trigger.parentElement}
            className=""
            overlay={
              <Menu className="w-[200px] rounded-lg custom-menu-dropdown shadow-dropdown">
                <Menu.Item
                  className=" py-2.5 px-4 rounded-t-lg"
                  onClick={() => setEditSprintModal(true)}
                >
                  <span className="mon-400-14 whitespace-nowrap hover:font-medium text-gray-700">
                    Edit
                  </span>
                </Menu.Item>
                <Menu.Item
                  className="py-2.5 px-4 rounded-b-lg"
                  onClick={() => setDeleteSprintModal(true)}
                >
                  <span className="mon-400-14 whitespace-nowrap  hover:font-medium text-error-600">
                    Delete
                  </span>
                </Menu.Item>
              </Menu>
            }
            trigger={["hover"]}
          >
            <div className=" cursor-pointer">
              <img
                src="/images/icons/more-vertical.svg"
                className="w-5 h-5 ml-3 "
                alt="add-item"
              />
            </div>
          </Dropdown>
          <DeleteSprintModal
            visible={deleteSprintModal}
            onCancel={() => setDeleteSprintModal(false)}
            onOk={async () => {
              await dispatch(
                updateSprint({ sprintId: sprint.id, isActive: false })
              );
              setDeleteSprintModal(false);
              dispatch(
                getSprintsByPhase({
                  projectId,
                  phaseId: sprint.phaseId,
                })
              );
            }}
          />
        </div>
      </div>
      <CustomTable
        // customPadding="p-3"
        className="custom-table cursor-pointer"
        rowClassName={(record) => (record.isNewGoal ? "goal-new-scope" : "")}
        scroll={showGoals ? "calc(100vh - 325px)" : "0px"}
        columns={columns}
        data={sprint?.goals}
        dataCount={sprint?.goals?.length}
        current={currentPageNo}
        pageSize={limit}
        setPageSize={setLimit}
        setCurrent={(e) => {
          setCurrentPageNo(e);
        }}
        onRow={(record, rowIndex) => {
          return {
            onClick: () => {
              setSelectedGoal(record);
              setEditGoalModal(true);
              dispatch(
                updateSchedule({ key: "selectedSprint", value: sprint })
              );
            },
          };
        }}
        key={sprint.id}
        customFooter={() => (
          <div className="px-5 py-4 flex items-center">
            <img src="/images/icons/plus-black.svg" alt="add" />
            <button
              className="mon-500-14 text-gray-700 ml-3 "
              onClick={() => {
                dispatch(updateSchedule({ key: "newGoalModal", value: true }));
                dispatch(
                  updateSchedule({ key: "selectedSprint", value: sprint })
                );
              }}
            >
              New goal
            </button>
          </div>
        )}
      />
      {editSprintModal && (
        <EditSprintModal
          visible={editSprintModal}
          onCancel={() => setEditSprintModal(false)}
          sprint={sprint}
        />
      )}

      {editGoalModal && (
        <EditGoalModal
          visible={editGoalModal}
          onCancel={() => setEditGoalModal(false)}
          goal={selectedGoal}
        />
      )}
    </div>
  );
};

export default GoalsTable;
