import React from "react";
import PropTypes from "prop-types";

type FourSquaresProps = {
  color?: string;
  width?: number | string;
  height?: number | string;
};
const defaultProps = {
  color: "#165ABF",
  width: 18,
  height: 18,
};

const FourSquaresIcon = ({ color, width, height }: FourSquaresProps) => {
  return (
    <div className="cursor-pointer">
      {
        <svg
          width={width}
          height={height}
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.33333 1.5H1.5V7.33333H7.33333V1.5Z"
            stroke={color}
            strokeWidth="1.67"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M16.5 1.5H10.6667V7.33333H16.5V1.5Z"
            stroke={color}
            strokeWidth="1.67"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M16.5 10.6667H10.6667V16.5H16.5V10.6667Z"
            stroke={color}
            strokeWidth="1.67"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M7.33333 10.6667H1.5V16.5H7.33333V10.6667Z"
            stroke={color}
            strokeWidth="1.67"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
    </div>
  );
};

FourSquaresIcon.defaultProps = defaultProps;

export default FourSquaresIcon;
