import { createAsyncThunk } from "@reduxjs/toolkit";
import { client, getError } from "../../utils/axiosClient";

export const getSectors = createAsyncThunk(
  "onboarding/getSectors",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await client.get(`/onboarding/get-sectors`);

      return response.data;
    } catch (error) {
      return rejectWithValue(getError(error));
      //return error;
    }
  }
);

export const getSDGs = createAsyncThunk(
  "onboarding/getSDGs",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await client.get(`/onboarding/get-sdgs`);

      return response.data;
    } catch (error) {
      return rejectWithValue(getError(error));
      //return error;
    }
  }
);

export const productOnboarding = createAsyncThunk(
  "onboarding/productOnboarding",
  async (payload: object, { rejectWithValue }) => {
    try {
      const response = await client.post(`/onboarding/`, payload);

      return response.data;
    } catch (error) {
      return rejectWithValue(getError(error));
      //return error;
    }
  }
);
