import React, { useState } from "react";
import CustomModal from "../../../Core/Common/CustomModal";
import CustomButton from "../../../Core/Common/CustomButton";
import { Radio, Space } from "antd";
import CustomSelect from "../../../Core/Common/CustomSelect";
import { useAppDispatch, useAppSelector } from "../../../Core/redux/hooks";
import SprintCard from "../SprintCard";
import {
  getSprintsByPhase,
  updateSprint,
} from "../../../Core/redux/api/scheduleAPI";
import { resetSchedule } from "../../../Core/redux/slices/schedule.slice";
import { CustomSelectWithInlineSearch } from "../../../Core/Common/CustomSelectWithSearch";
import { useParams } from "react-router-dom";

type DeleteSprintModalProps = {
  visible: boolean;
  onCancel: () => void;
  onOk: () => void;
  id: string;
};

const DeleteSprintModal = ({
  visible,
  onCancel,
  onOk,
  id,
}: DeleteSprintModalProps) => {
  const [value, setValue] = useState(1);
  const dispatch = useAppDispatch();
  const [selectedSprint, setSelectedSprint] = useState(null);
  const { deleteSprintModal, sprints, currentSelectedPhase } = useAppSelector(
    (state) => state.schedule
  );

  const { schedule } = useAppSelector((state) => state.projects);
  const phaseAndSprints = schedule?.[1]?.[0];

  const { projectId } = useParams();
  console.log(deleteSprintModal, "delete sprint on delete");

  const onChange = (e) => {
    setValue(e.target.value);
  };

  const handleDelete = async () => {
    const payload: {
      sprintId: string;
      isActive: boolean;
      newSprintId?: string | number;
    } = {
      sprintId: id,
      isActive: false,
    };

    if (value === 2 && selectedSprint !== null) {
      payload.newSprintId = selectedSprint;
    }

    console.log(payload, "payload sprint delete");

    await dispatch(updateSprint(payload)).then(() => onCancel());
    dispatch(
      getSprintsByPhase({
        projectId,
        phaseId: currentSelectedPhase.id,
      })
    );
    dispatch(resetSchedule(["deleteSprintModal", "sprintDetailsModal"]));
  };

  return (
    <CustomModal
      title={<p className="mon-500-16 text-error-600">Delete sprint</p>}
      width={"524px"}
      visible={visible}
      mask={true}
      maskStyle={{ backgroundColor: "rgba(0,0,0,0.05)" }}
      body={
        <div className="max-h-[38.189386056191466vh] overflow-y-scroll">
          <div className="mon-400-14 text-gray-500 pt-6 px-5">
            Are you sure you want to delete this sprint? This action cannot be
            undone
          </div>
          <div className="pt-6 px-5 ">
            <p className="mon-500-14 text-gray-900 ">
              Choose what to do with items inside the sprint:
            </p>
            <div className="mt-4 mb-6">
              <Radio.Group onChange={onChange} value={value}>
                <Space direction="vertical">
                  <Radio value={1}>
                    <span className="mon-400-14 text-gray-700">
                      Delete sprint and the items inside (task, event, bug,
                      scope){" "}
                    </span>
                  </Radio>
                  <Radio value={2}>
                    <span className="mon-400-14 text-gray-700">
                      Move all items to a new sprint
                    </span>
                  </Radio>
                </Space>
              </Radio.Group>
            </div>
            {value === 2 ? (
              <div>
                <p className="mon-500-14 text-gray-900 mb-2.5">
                  Select a sprint to move all items to
                </p>
                <CustomSelect
                  placeholder="Select Sprint"
                  options={sprints.map((s) => {
                    return {
                      id: s.id,
                      title: s.name,
                      value: s.id,
                      render: (value) => <div>{s.name}</div>,
                    };
                  })}
                  onChange={(e) => setSelectedSprint(e)}
                  value={selectedSprint}
                />
                {/* <CustomSelect placeholder="Select a sprint" /> */}
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      }
      footer={
        <div className="flex justify-between pt-8 pb-4 px-3">
          <CustomButton
            text="Cancel"
            onClick={onCancel}
            className="border-2 border-gray-200 text-black bg-white mon-500-16 "
            height="44px"
            width="49%"
          />
          <CustomButton
            text="Yes, Delete"
            onClick={() => handleDelete()}
            className="mon-500-16 text-white  bg-error-600 "
            height="44px"
            width="49%"
          />
        </div>
      }
      onCancel={onCancel}
    />
  );
};

export default DeleteSprintModal;
