import React, { useMemo, useState, useEffect } from "react";
import MenuDropdown from "../../Modules/Conversations/Components/MenuDropdown";
import MessageDotsCircleIcon from "../svg/MessageDotsCircleIcon";
import { useAppDispatch, useAppSelector } from "../../Core/redux/hooks";
import { updateConversations } from "../../Core/redux/slices/conversations.slice";
import { updateVault } from "../redux/slices/vault.slice";
import ShareAccessModal from "../../Modules/Conversations/Components/ShareAccessModal";
import { Tooltip } from "antd";
import PeopleAccessDropdown from "../../Modules/Vault/PeopleAccessDropdown";
import PhaseIcon from "../svg/PhaseIcon";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import slugify from "../utils/slugify";
import { copyFolderAPI } from "../redux/api/vaultAPI";

type Props = {
  title?: string;
  folderId?: string;
  totalFiles?: number;
  totalLinks?: number;
  style?: {
    backgroundColor?: string;
    outline?: string;
  };
  isMySpace;
  phaseId?: number;
  usersWithAccess?: [];
  isDropTarget?: boolean;
};

const PHASE = {
  IDEATION: 1,
  DESIGN: 2,
};

const defaultProps = {
  style: {
    backgroundColor: "#F7FAFD",
  },
  isMySpace: false,
  title: "",
  totalFiles: 0,
  totalLinks: 0,
  isDropTarget: false,
};

const CustomFolder = ({
  style,
  isMySpace,
  title,
  folderId,
  totalFiles,
  totalLinks,
  phaseId,
  usersWithAccess,
  isDropTarget,
}: Props) => {
  const bodyStyle = { ...style };
  if (isMySpace) {
    bodyStyle.backgroundColor = "#EFF8FF";
  }
  if (isDropTarget) {
    bodyStyle.backgroundColor = "#EFF8FF";
    bodyStyle.outline = "4px #D1E9FF solid";
  }

  const renderPhaseColor = () => {
    const { IDEATION, DESIGN } = PHASE;
    switch (phaseId) {
      case IDEATION:
        return "bg-yellow-600";
      case DESIGN:
        return "bg-pink-700";
      default:
        return null;
    }
  };

  const phaseName = (id) => {
    switch (id) {
      case "1":
        return "Ideation phase";
      case "2":
        return "Design phase";
      case "3":
        return "Development phase";
      case "4":
        return "Deployment phase";
      case "5":
        return "Maintenance phase";
      default:
        return "Ideation phase";
    }
  };

  const navigate = useNavigate();
  const location = useLocation();
  const { projectId } = useParams();
  const phaseColor = useMemo(() => renderPhaseColor, [phaseId]);
  const dispatch = useAppDispatch();
  const [openShareAccessModal, setOpenShareAccessModal] = useState(false);

  const { folders, openCommentsModal } = useAppSelector((state) => state.vault);
  const { user } = useAppSelector((state) => state.userDetails);
  const filteredFolder = folders.filter((item) => item.id === folderId)[0];

  const { vault } = useAppSelector((state) => state.projects);

  const viewAccess = vault?.[1]?.[0] || {};
  const folderFileLink = vault?.[2]?.[0] || {};
  const requestAccess = vault?.[6]?.[0] || {};
  const deleteAll = vault?.[4]?.[0] || {};
  const commentAccess = vault?.[5]?.[0] || {};

  return (
    <div
      className="actions-row relative w-[173px] h-[182px] rounded-xl py-[17px] cursor-pointer"
      style={bodyStyle}
    >
      <div className={`px-[17px] pb-5 ${isDropTarget ? "opacity-0" : ""}`}>
        {!isMySpace && (
          <>
            <div className="flex justify-between">
              <div className="flex items-center">
                {phaseId !== null && (
                  <Tooltip title={phaseName(phaseId)}>
                    <div>
                      <PhaseIcon
                        width="12px"
                        height="12px"
                        phaseId={+phaseId}
                      />
                    </div>
                  </Tooltip>
                )}

                <Tooltip title="Open comments">
                  <div
                    className="ml-3"
                    onClick={() => {
                      dispatch(
                        updateVault({
                          key: "openCommentsModal",
                          value: {
                            visible: true,
                            docType: "folder",
                            name: title,
                            docId: folderId,
                            comments: [],
                            users: usersWithAccess,
                          },
                        })
                      );
                    }}
                  >
                    <MessageDotsCircleIcon
                      redDot={
                        openCommentsModal.comments.length > 0 ? true : false
                      }
                    />
                  </div>
                </Tooltip>
                {usersWithAccess.length > 1 && (
                  <PeopleAccessDropdown elements={usersWithAccess} />
                )}
              </div>
              <div className="actions absolute right-3 top-3">
                <MenuDropdown
                  elements={[
                    folderFileLink.read
                      ? {
                          id: 1,
                          image: "/images/icons/info-circle.svg",
                          text: "View details",
                          textColor: "text-gray-700",
                          borderBottom: true,
                          onclick: () =>
                            dispatch(
                              updateVault({
                                key: "showDocDetailsDrawer",
                                value: {
                                  visible: true,
                                  docId: folderId,
                                  docType: "folder",
                                  docDetails: filteredFolder,
                                },
                              })
                            ),
                        }
                      : null,
                    commentAccess
                      ? {
                          id: 2,
                          image: "/images/icons/message-dots-circle-gray.svg",
                          text: "Open comments",
                          textColor: "text-gray-700",
                          onclick: () =>
                            dispatch(
                              updateVault({
                                key: "openCommentsModal",
                                value: {
                                  visible: true,
                                  docType: "folder",
                                  name: title,
                                  docId: folderId,
                                  comments: [],
                                  users: usersWithAccess,
                                },
                              })
                            ),
                        }
                      : null,
                    folderFileLink.update &&
                    (filteredFolder.isOwner === 1 ||
                      filteredFolder.canEdit === 1)
                      ? {
                          id: 3,
                          image: "/images/icons/add-user-gray.svg",
                          text: "Share access",
                          textColor: "text-gray-700",
                          onclick: () => {
                            setOpenShareAccessModal(true);
                            dispatch(
                              updateVault({
                                key: "shareAccessDoc",
                                value: {
                                  docId: folderId,
                                  users: usersWithAccess,
                                  docType: "folder",
                                  docDetails: filteredFolder,
                                },
                              })
                            );
                          },
                        }
                      : null,
                    filteredFolder.isOwner === 1 || filteredFolder.canEdit === 1
                      ? {
                          id: 4,
                          image: "/images/icons/share-gray.svg",
                          text: "Share in message",
                          textColor: "text-gray-700",
                          onclick: () =>
                            dispatch(
                              updateConversations({
                                key: "shareInMessageModal",
                                value: {
                                  type: "folder",
                                  visible: true,
                                  url:
                                    window.location.href +
                                    `/${title}-${folderId}`,
                                  file: [],
                                },
                              })
                            ),
                        }
                      : null,
                    {
                      id: 5,
                      image: "/images/icons/copy-dashed.svg",
                      text: "Make a copy",
                      textColor: "text-gray-700",
                      onclick: () => {
                        dispatch(
                          updateVault({
                            key: "copyModal",
                            value: {
                              visible: true,
                              docType: "folder",
                              id: folderId,
                            },
                          })
                        );
                      },
                    },
                    filteredFolder.isOwner === 1
                      ? {
                          id: 8,
                          image: "/images/icons/user-right-01.svg",
                          text: "Transfer Ownership",
                          textColor: "text-gray-700",
                          onclick: () => {
                            dispatch(
                              updateVault({
                                key: "transferOwnershipModal",
                                value: {
                                  visible: true,
                                  id: folderId,
                                  docType: 1,
                                  usersWithAccess,
                                },
                              })
                            );
                          },
                        }
                      : null,
                    {
                      id: 6,
                      image: "/images/icons/download-icon.svg",
                      text: "Download",
                      textColor: "text-gray-700",
                      borderBottom: true,
                      onclick: () =>
                        dispatch(
                          updateVault({
                            key: "downloadModal",
                            value: {
                              visible: true,
                              type: "folder",
                              id: folderId,
                            },
                          })
                        ),
                    },

                    folderFileLink.delete &&
                    (filteredFolder.isOwner === 1 ||
                      filteredFolder.canEdit === 1)
                      ? {
                          id: 7,
                          image: "/images/icons/trash.svg",
                          text: "Delete",
                          textColor: "text-error-600",
                          onclick: () =>
                            dispatch(
                              updateVault({
                                key: "deleteFolderModal",
                                value: {
                                  visible: true,
                                  id: folderId,
                                },
                              })
                            ),
                        }
                      : null,
                  ]}
                  customFunc={undefined}
                />
              </div>
            </div>
          </>
        )}
        {isMySpace && (
          <div className="actions absolute right-3 top-3">
            <MenuDropdown
              elements={[
                {
                  id: 1,
                  image: "/images/icons/info-circle.svg",
                  text: "View details",
                  textColor: "text-gray-700",
                  onclick: () =>
                    dispatch(
                      updateVault({
                        key: "showDocDetailsDrawer",
                        value: {
                          visible: true,
                          docId: folderId,
                          docType: "folder",
                          docDetails: filteredFolder,
                        },
                      })
                    ),
                },
                {
                  id: 2,
                  image: "/images/icons/download-icon.svg",
                  text: "Download",
                  textColor: "text-gray-700",
                  borderBottom: true,
                  onclick: () =>
                    dispatch(
                      updateVault({
                        key: "downloadModal",
                        value: {
                          visible: true,
                          type: "file",
                          id: folderId,
                        },
                      })
                    ),
                },
              ]}
              customFunc={undefined}
            />
          </div>
        )}
      </div>

      <div
        onClick={() => {
          //const newTitle = slugify(title);
          const newPath = location.pathname + `/${title}-${folderId}`;
          navigate(newPath);
        }}
      >
        <div>
          <div className="flex items-center justify-center mb-4">
            <img
              className={`${
                isMySpace ? "mt-3 h-[34px] w-[34px]" : "h-10 w-10"
              }`}
              src={
                isMySpace
                  ? "/images/icons/my-space-folder.svg"
                  : "/images/icons/folder-fill-vault.svg"
              }
              alt=""
            />
          </div>
          <div
            className={`text-center mont-400-12 text-gray-500 mb-3 ${
              isMySpace ? "mt-[22px]" : ""
            }`}
          >
            {totalFiles} Files, {totalLinks} links
          </div>
        </div>
        <div
          className="text-center mont-500-12 text-gray-700 px-4 ellipsis-two-lines capitalize"
          style={{ width: "172px", wordBreak: "break-all" }}
        >
          {/* {isMySpace
            ? user.firstName === null
              ? "Personal Space"
              : `${user.firstName}'s Space`
            : title} */}
          {title}
        </div>
      </div>
      <ShareAccessModal
        visible={openShareAccessModal}
        onCancel={() => setOpenShareAccessModal(false)}
      />
    </div>
  );
};

CustomFolder.defaultProps = defaultProps;
export default CustomFolder;
