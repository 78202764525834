import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { CustomPhaseCard } from "../../../Core/Common/CustomPhaseCard";
import { client } from "../../../Core/utils/axiosClient";
import PhasesInfo from "../../Overview/Components/PhasesInfo";
import { useAppSelector } from "../../../Core/redux/hooks";

const PhaseDetails = () => {
  const { projectId } = useParams();
  const { overview } = useAppSelector((state) => state.projects);
  const manageProjects = overview?.[1]?.[0];

  const [phaseList, setPhaseList] = useState([]);
  const [unselectedPhaseList, setUnselectedPhaseList] = useState([]);

  const putChangeDate = (phaseId, key, val, callback) => {
    console.log(phaseId, key, val);
    client
      .put("/settings/project-phase-date", {
        projectId,
        phaseId,
        dateType: key,
        value: val,
      })
      .then((resp) => {
        callback();
      });
  };

  const addProjectPhase = (phaseId, callback) => {
    console.log(phaseId);
    client
      .put("/settings/add-project-phase", {
        projectId,
        phaseId,
      })
      .then((resp) => {
        callback();
      });
  };

  const getPhaseTypes = (projectPhaseList) => {
    client.get(`/project/phases`).then(({ data }) => {
      const selectedPhaseIdList = projectPhaseList.map(
        ({ phaseId }) => phaseId
      );
      console.log(data, selectedPhaseIdList);

      const unselectedPhases = data.filter(
        ({ id }) => !selectedPhaseIdList.includes(id)
      );
      setUnselectedPhaseList(unselectedPhases);
    });
  };

  const getProjectPhases = () => {
    client
      .get(`/project/project-phases-with-sprints?projectId=${projectId}`)
      .then(({ data }) => {
        const list = data.map(
          ({ phases: title, startDate, endDate, phaseId, color }) => {
            return {
              title,
              startDate,
              endDate,
              phaseId,
              color,
            };
          }
        );
        setPhaseList(list);
        getPhaseTypes(list);
      });
  };

  console.log("phaseList: ", phaseList);

  useEffect(() => {
    console.log("hwllo");
    getProjectPhases();
  }, []);

  const handleChangeDate = (phaseId, key, val) => {
    const list = [...phaseList];
    const index = phaseList.findIndex(({ phaseId: id }) => +id === +phaseId);
    console.log(index);
    list[index][key] = val;
    console.log("handleChangeDate", list, phaseId, key, val);
    putChangeDate(phaseId, key, val, () => {
      setPhaseList(list);
    });
  };

  const handleAddPhase = ({ color, id, phases: title }) => {
    console.log("hehe");

    addProjectPhase(id, () => {
      const phase = {
        title,
        startDate: null,
        endDate: null,
        phaseId: id,
        color,
      };
      setUnselectedPhaseList(
        unselectedPhaseList.filter(({ id: pId }) => id !== pId)
      );
      setPhaseList([...phaseList, phase]);
    });
  };

  return (
    <div>
      <div className="mont-500-14 text-gray-700">
        Edit dates of existing phases
      </div>
      <div className="flex pt-4 gap-x-6">
        {phaseList
          .sort((a, b) => a.phaseId - b.phaseId)
          .map(({ title, startDate, endDate, phaseId, color }) => (
            <CustomPhaseCard
              key={phaseId}
              startDate={startDate}
              endDate={endDate}
              phaseId={phaseId}
              phaseName={title}
              disabled={false}
              color={color}
              onChange={handleChangeDate}
            />
          ))}
      </div>
      {unselectedPhaseList.length > 0 ? (
        <div className="mt-12">
          <div className="mont-500-14 text-gray-700">
            Add more phases in project
          </div>
          <div className="flex pt-4 gap-x-6">
            {unselectedPhaseList.map(({ color, id, phases: title }) => (
              <div key={id} className="relative">
                {manageProjects?.update === 1 && (
                  <div className="absolute top-0 left-0 z-50 flex justify-center items-center w-[180px] h-[222px] hover:opacity-100 opacity-0">
                    <div
                      className="h-10 bg-primary-600 flex items-center justify-center cursor-pointer px-4 rounded-xl text-white mont-500-14"
                      onClick={() =>
                        handleAddPhase({ color, id, phases: title })
                      }
                    >
                      Add Phase
                    </div>
                  </div>
                )}

                <CustomPhaseCard
                  key={id}
                  startDate={null}
                  endDate={null}
                  phaseId={id}
                  phaseName={title}
                  disabled={true}
                  color={color}
                  onChange={handleChangeDate}
                />
              </div>
            ))}
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default PhaseDetails;
