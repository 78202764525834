import React from "react";
import { Switch } from "antd";
import * as Yup from "yup";
import { useFormik } from "formik";

const Support = () => {
  const formik = useFormik({
    initialValues: {
      tipsFromFrosty: true,
      suggestedThreads: true,
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Please enter a valid email address")
        .required("Please enter the email address")
        .typeError("Please enter the email address"),
    }),
    onSubmit: (values) => {
      console.log(values);
    },
  });

  return (
    <div className=" p-5">
      <p className="mon-400-12 text-gray-500">Help and support</p>
      <div className="flex justify-start items-start gap-x-2 mt-6">
        <Switch
          checked={formik.values.tipsFromFrosty}
          onChange={(checked) =>
            formik.setFieldValue("tipsFromFrosty", checked)
          }
          className={`${
            formik.values.tipsFromFrosty ? "bg-primary-600" : "bg-white"
          }`}
        />
        <div>
          <p className="mon-500-12 text-gray-700">Tips from Frosty</p>
          <p className="mon-400-12 text-gray-500">
            Disable if you don’t want to see the tips on startup
          </p>
        </div>
      </div>
      <div className="flex justify-start items-start gap-x-2 mt-4">
        <Switch
          checked={formik.values.suggestedThreads}
          onChange={(checked) =>
            formik.setFieldValue("suggestedThreads", checked)
          }
          className={`${
            formik.values.suggestedThreads ? "bg-primary-600" : "bg-white"
          }`}
        />
        <div>
          <p className="mon-500-12 text-gray-700">Suggested threads</p>
          <p className="mon-400-12 text-gray-500">
            Disable if you don’t want to see any suggested threads in
            conversations
          </p>
        </div>
      </div>
    </div>
  );
};

export default Support;
