import React, { useEffect } from "react";
import SectorSelection from "./S1-SectorSelection";
import WelcomeScreen from "./WelcomeScreen";
import { useAppSelector } from "../../../Core/redux/hooks";
import MapSelection from "./S3-MapSelection";
import SDGSelection from "./S2-SDGSelection";
import CompanySizeSelection from "./S4-CompanySizeSelection";
import WaddleSelection from "./S5-CreateWaddle";
import LaunchWaddle from "./S6-LaunchWaddle";
import { useNavigate } from "react-router-dom";

const ChangeStep = (step) => {
  switch (step) {
    case 1:
      return <WelcomeScreen />;
    case 2:
      return <SectorSelection />;
    case 3:
      return <SDGSelection />;
    case 4:
      return <MapSelection />;
    case 5:
      return <CompanySizeSelection />;
    case 6:
      return <WaddleSelection />;
    case 7:
      return <LaunchWaddle />;
  }
};

const CompleteForm = () => {
  const { loggedIn } = useAppSelector((state) => state.userDetails);
  const navigate = useNavigate();

  const { currentStep } = useAppSelector((state) => state.onboarding);

  useEffect(() => {
    if (!loggedIn) navigate("/", { replace: true });
  }, []);

  return ChangeStep(currentStep);
};

export default CompleteForm;
