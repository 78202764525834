import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import CustomBadge from "../../../Core/Common/CustomBadge";
import CustomButton from "../../../Core/Common/CustomButton";
import {
  getProjectPhases,
  updatePhase,
} from "../../../Core/redux/api/scheduleAPI";
import { useAppDispatch, useAppSelector } from "../../../Core/redux/hooks";
import { updateProjectOnboarding } from "../../../Core/redux/slices/projectOnboarding.slice";
import { client } from "../../../Core/utils/axiosClient";
import AddComponentModal from "../../Onboarding/Project Onboarding/AddComponentModal";

const AddMore = ({ phaseId, groupId, handelInput }) => {
  const [showInput, setShowInput] = useState(false);
  const [value, setvalue] = useState("");
  return (
    <div>
      {showInput ? (
        <div
          className={`${
            value?.length > 0
              ? "border-b-2 border-primary-600"
              : " border-b-[1px] border-gray-400"
          } w-[109px] mt-2 `}
        >
          <div className="flex justify-between items-center">
            <input
              className="outline-none bg-transparent w-full"
              value={value}
              onChange={(e) => setvalue(e.target.value)}
            />
            <div
              className="cursor-pointer"
              onClick={() => {
                setvalue("");
                setShowInput(false);
                handelInput(phaseId, groupId, value);
              }}
            >
              <img src="/images/icons/input_save.svg" alt="" />
            </div>
            <div
              className="cursor-pointer ml-1"
              onClick={() => {
                setvalue("");
                setShowInput(false);
              }}
            >
              <img src="/images/icons/sector-icons/input-cross.svg" alt="" />
            </div>
          </div>
        </div>
      ) : (
        <CustomButton
          text="Add more"
          width="109px"
          height="28px"
          className="bg-gray-50 text-gray-500 pl-0.5 mt-2"
          icon="/images/icons/plus-gray.svg"
          iconAlignment="right"
          iconSize="w-3 h-3"
          onClick={() => setShowInput(true)}
        />
      )}
    </div>
  );
};

const ComponentDetails = () => {
  const { overview } = useAppSelector((state) => state.projects);
  const manageProjects = overview?.[1]?.[0];
  const { projectId } = useParams();

  const [phaseList, setPhaseList] = useState([]);

  const [addComponent, setAddComponent] = useState({
    visble: false,
    phaseId: 1,
  });
  const dispatch = useAppDispatch();

  const handelInput = async (phaseId, groupId, val) => {
    const oldPhaseDetails = [...phaseList];
    const newPhaseDetails = oldPhaseDetails.map((p) => {
      if (p.phaseId === phaseId) {
        const components = p.components.map((c) => {
          if (c.id === groupId) {
            return {
              ...c,
              content: [
                ...c.content,
                {
                  id: c.content.length + 1,
                  heading: val,
                },
              ],
            };
          }
          return c;
        });
        console.log("streeng: ", phaseId);
        const payload = {
          projectId: projectId,
          phaseId: phaseId.toString(),
          components,
        };
        dispatch(updatePhase(payload as any)).then(getProjectPhases);

        return { ...p, components };
      }
      return p;
    });
  };

  const handleRemove = (phaseId, groupId, contentId) => {
    const oldPhaseDetails = [...phaseList];
    const newPhaseDetails = oldPhaseDetails.map((p) => {
      if (p.phaseId === phaseId) {
        const components = p.components.map((c) => {
          if (c.id === groupId) {
            return {
              ...c,
              content: c.content.filter((con) => con.id !== contentId),
            };
          }
          return c;
        });

        const payload = {
          projectId: projectId,
          phaseId: phaseId.toString(),
          components,
        };
        dispatch(updatePhase(payload as any)).then(getProjectPhases);

        return { ...p, components };
      }
      return p;
    });

    // let newPhase;
    //   dispatch(
    //     updateProjectOnboarding({ key: "phaseDetails", value: newPhaseDetails })
    //   );
  };

  const getProjectPhases = () => {
    client
      .get(`/project/project-phases-with-sprints?projectId=${projectId}`)
      .then(({ data }) => {
        const list = data.map(
          ({
            phases: title,
            startDate,
            endDate,
            phaseId,
            color,
            components,
          }) => {
            return {
              title,
              startDate,
              endDate,
              phaseId,
              color,
              components: components ? JSON.parse(components) : [],
            };
          }
        );
        setPhaseList(list);
      });
  };

  useEffect(() => {
    getProjectPhases();
  }, []);

  return (
    <div>
      <div className="overflow-x-scroll mt-6 flex gap-x-3">
        {phaseList
          .sort((a, b) => a.phaseId - b.phaseId)
          // .filter((phase) => selectedPhase.includes(phase.id))
          .map((phase) => (
            <div
              className="min-w-min h-[216px] border-[1px] border-gray-200 rounded-xl p-3 overflow-x-scroll"
              key={phase.id}
            >
              <div className="flex justify-between">
                <p className="mon-500-12 text-gray-900 pr-2">{phase.title}</p>
                {manageProjects?.update === 1 && (
                  <p
                    className="mon-500-12 text-gray-500 cursor-pointer"
                    onClick={() =>
                      setAddComponent({ visble: true, phaseId: phase.id })
                    }
                  >
                    Add Groups
                  </p>
                )}
              </div>
              <div className=" flex justify-start gap-x-[24px] mt-3">
                {phase.components.map(({ id, title, content }) => (
                  <div className="gap-y-2" key={id}>
                    <p className="mon-500-10 text-gray-500">{title}</p>
                    {content.map((c) => (
                      <div
                        key={c.id}
                        onClick={() => handleRemove(phase.phaseId, id, c.id)}
                      >
                        <CustomBadge
                          text={c.heading}
                          color="#6938EF"
                          className="mt-2 cursor-pointer"
                          icon={
                            <div>
                              {manageProjects?.update === 1 && (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="12"
                                  height="12"
                                  fill="none"
                                  viewBox="0 0 16 16"
                                >
                                  <path
                                    stroke="#6938EF"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="1.5"
                                    d="M12 4l-8 8m0-8l8 8"
                                  ></path>
                                </svg>
                              )}
                            </div>
                          }
                        />
                        {/* <img src="/images/icons/sector-icons/input-cross.svg" /> */}
                      </div>
                    ))}
                    {manageProjects?.update === 1 && (
                      <AddMore
                        handelInput={handelInput}
                        phaseId={phase.phaseId}
                        groupId={id}
                      />
                    )}
                  </div>
                ))}
              </div>
            </div>
          ))}
      </div>
      {addComponent.visble && (
        <AddComponentModal
          visible={addComponent.visble}
          phaseId={addComponent.phaseId}
          onCancel={() => setAddComponent({ visble: false, phaseId: 1 })}
        />
      )}
    </div>
  );
};

export default ComponentDetails;
