import { Tooltip } from "antd";
import React from "react";

const TaskTypeIcon = ({
  type,
  width,
  height,
}: {
  type: string | number;
  width: string;
  height: string;
}) => {
  switch (type) {
    // ideation
    case "task":
      return (
        <Tooltip title="Task">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            fill="none"
            viewBox="0 0 16 16"
          >
            <rect width="16" height="16" fill="#D1E9FF" rx="8"></rect>
            <path
              d="M11.688 5.34133L12.0043 5.60667L11.688 5.34138L11.688 5.34133ZM6.97155 9.89312L11.0922 5.91636L10.9018 6.14351C10.4686 6.66034 9.88849 7.35274 9.30088 8.05496C8.13327 9.4503 6.9161 10.9079 6.7874 11.076L6.77533 11.0917L6.76455 11.1084C6.71162 11.1902 6.61102 11.2467 6.50434 11.2467C6.38611 11.2467 6.2932 11.1919 6.23986 11.112L4.74748 8.68444C4.73305 8.6583 4.73092 8.63384 4.73565 8.61182C4.74039 8.5898 4.7538 8.56296 4.78393 8.53876C4.86759 8.48404 4.95998 8.49958 5.00756 8.53763L6.25929 9.87501L6.60623 10.2457L6.97155 9.89312Z"
              fill="#2E90FA"
              stroke="#344054"
            />
          </svg>
        </Tooltip>
      );
    case 1:
      return (
        <Tooltip title="Task">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            fill="none"
            viewBox="0 0 16 16"
          >
            <rect width="16" height="16" fill="#D1E9FF" rx="8"></rect>
            <path
              d="M11.688 5.34133L12.0043 5.60667L11.688 5.34138L11.688 5.34133ZM6.97155 9.89312L11.0922 5.91636L10.9018 6.14351C10.4686 6.66034 9.88849 7.35274 9.30088 8.05496C8.13327 9.4503 6.9161 10.9079 6.7874 11.076L6.77533 11.0917L6.76455 11.1084C6.71162 11.1902 6.61102 11.2467 6.50434 11.2467C6.38611 11.2467 6.2932 11.1919 6.23986 11.112L4.74748 8.68444C4.73305 8.6583 4.73092 8.63384 4.73565 8.61182C4.74039 8.5898 4.7538 8.56296 4.78393 8.53876C4.86759 8.48404 4.95998 8.49958 5.00756 8.53763L6.25929 9.87501L6.60623 10.2457L6.97155 9.89312Z"
              fill="#2E90FA"
              stroke="#344054"
            />
          </svg>
        </Tooltip>
      );
    case 2:
      return (
        <Tooltip title="Calendar event">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            fill="none"
            viewBox="0 0 16 16"
          >
            <path
              fill="#F9FBFE"
              d="M11.667 4.333H4.333v7.334h7.334V4.333z"
            ></path>
            <path
              fill="#1E88E5"
              d="M8.56 6.973l.336.48.528-.384v2.784H10V6.205h-.48l-.96.768zM7.648 7.915c.208-.191.337-.457.337-.75 0-.582-.51-1.056-1.139-1.056-.534 0-.99.337-1.11.818l.553.14c.055-.221.289-.382.557-.382.314 0 .57.216.57.48 0 .265-.256.48-.57.48h-.332v.576h.332c.36 0 .665.25.665.547 0 .301-.289.547-.644.547-.32 0-.595-.204-.638-.473l-.562.092c.087.545.603.957 1.2.957.669 0 1.213-.504 1.213-1.123 0-.341-.168-.647-.432-.853z"
            ></path>
            <path
              fill="#FBC02D"
              d="M11.333 14H4.667l-.334-1.333.334-1.334h6.666l.334 1.334L11.333 14z"
            ></path>
            <path
              fill="#4CAF50"
              d="M12.667 11.667L14 11.333V4.667l-1.333-.334-1.334.334v6.666l1.334.334z"
            ></path>
            <path
              fill="#1E88E5"
              d="M11.333 4.667l.334-1.334L11.333 2H3a1 1 0 00-1 1v8.333l1.333.334 1.334-.334V4.667h6.666z"
            ></path>
            <path fill="#E53935" d="M11.333 11.333V14L14 11.333h-2.667z"></path>
            <path
              fill="#1565C0"
              d="M13 2h-1.667v2.667H14V3a1 1 0 00-1-1zM3 14h1.667v-2.667H2V13a1 1 0 001 1z"
            ></path>
          </svg>
        </Tooltip>
      );
    case 3:
      return (
        <Tooltip title="Bug">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="none"
            viewBox="0 0 16 16"
          >
            <g clipPath="url(#clip0_8301_33639)">
              <circle cx="8" cy="8" r="8" fill="#FEE4E2"></circle>
              <path
                fill="#7A271A"
                d="M6.085 3.88c0-.302.249-.547.556-.547a.55.55 0 01.537.691.495.495 0 00.054.387.866.866 0 00.769.42.868.868 0 00.768-.42.493.493 0 00.054-.387.55.55 0 01.537-.69c.307 0 .556.244.556.547a.55.55 0 01-.434.535.467.467 0 00-.3.213c-.326.541-.207 1.359-.837 1.412a4.076 4.076 0 01-.686.001c-.634-.051-.512-.87-.84-1.414a.465.465 0 00-.3-.213.55.55 0 01-.434-.534zM8 7.612c-.67 0-1.305-.115-1.895-.343-.515 1.14-.498 4.267 1.895 4.615 2.366-.345 2.423-3.409 1.89-4.617A5.208 5.208 0 018 7.611zm2.457-.9c.055.124.153.177.252.177.152 0 .958-.395 1.15-.395a.402.402 0 01.15.779l-.867.336a.466.466 0 00-.298.519c.035.256.254.409.478.409h.934c.227 0 .41.18.41.403a.407.407 0 01-.41.403h-.928c-.218 0-.447.13-.497.442-.04.251.104.452.297.538l.94.42a.4.4 0 01.205.533.413.413 0 01-.542.202l-.996-.444c-.133-.059-.275-.024-.375.142a3.165 3.165 0 01-2.36 1.492 3.165 3.165 0 01-2.386-1.534c-.078-.133-.22-.159-.35-.1l-.997.443a.413.413 0 01-.542-.202.401.401 0 01.204-.533l.941-.42c.204-.09.336-.285.292-.568a.487.487 0 00-.492-.411h-.928a.405.405 0 01-.41-.403c0-.223.184-.403.41-.403h.935c.214 0 .439-.137.48-.432a.465.465 0 00-.3-.496l-.868-.336a.402.402 0 01-.231-.524.412.412 0 01.531-.227c.09.035.907.367 1 .367.095 0 .196-.048.259-.19.113-.256.426-.33.682-.218a4.373 4.373 0 001.77.353c.625 0 1.21-.118 1.75-.35.266-.114.591-.037.707.228z"
              ></path>
            </g>
            <defs>
              <clipPath id="clip0_8301_33639">
                <path fill="#fff" d="M0 0H16V16H0z"></path>
              </clipPath>
            </defs>
          </svg>
        </Tooltip>
      );
    case "subtask":
      return (
        <Tooltip title="Subtask">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            fill="none"
            viewBox="0 0 12 12"
          >
            <g clipPath="url(#clip0_8305_41755)">
              <circle cx="6" cy="6" r="6" fill="#DDE5ED"></circle>
              <path
                stroke="#667085"
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M3.981 4.97v1.618c0 .552-.028.678.08.89a.983.983 0 00.428.43c.21.107.485.107 1.034.107h1.514M3.981 4.97a.983.983 0 00.982-.985A.983.983 0 003.98 3 .983.983 0 003 3.985c0 .544.44.985.981.985zm3.056 3.045c0 .544.44.985.982.985A.983.983 0 009 8.015a.983.983 0 00-.981-.985.983.983 0 00-.982.985z"
              ></path>
            </g>
            <defs>
              <clipPath id="clip0_8305_41755">
                <path fill="#fff" d="M0 0H12V12H0z"></path>
              </clipPath>
            </defs>
          </svg>
        </Tooltip>
      );
    case 4:
      return (
        <Tooltip title="Subtask">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            fill="none"
            viewBox="0 0 12 12"
          >
            <g clipPath="url(#clip0_8305_41755)">
              <circle cx="6" cy="6" r="6" fill="#DDE5ED"></circle>
              <path
                stroke="#667085"
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M3.981 4.97v1.618c0 .552-.028.678.08.89a.983.983 0 00.428.43c.21.107.485.107 1.034.107h1.514M3.981 4.97a.983.983 0 00.982-.985A.983.983 0 003.98 3 .983.983 0 003 3.985c0 .544.44.985.981.985zm3.056 3.045c0 .544.44.985.982.985A.983.983 0 009 8.015a.983.983 0 00-.981-.985.983.983 0 00-.982.985z"
              ></path>
            </g>
            <defs>
              <clipPath id="clip0_8305_41755">
                <path fill="#fff" d="M0 0H12V12H0z"></path>
              </clipPath>
            </defs>
          </svg>
        </Tooltip>
      );

    case "newscope":
      return (
        <Tooltip title="New scope">
          <div className={`w-[${width}] h-[${height}]`}>
            <img
              src="/images/icons/plus-circle.svg"
              className="w-full h-full"
            />
          </div>
        </Tooltip>
      );

    case 5:
      return (
        <Tooltip title="New scope">
          <div className={`w-[${width}] h-[${height}]`}>
            <img
              src="/images/icons/plus-circle.svg"
              className="w-full h-full"
            />
          </div>
        </Tooltip>
      );

    default:
      break;
  }
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 16 16"
    >
      <rect width="16" height="16" fill="#D1E9FF" rx="8"></rect>
      <path
        fill="#2E90FA"
        stroke="#165ABF"
        strokeWidth="1.67"
        d="M11.934 5.548s0 0 0 0l-.535-.45s0 0 0 0l.536.45zm-5.34 5.175l.231-.223c-.16.195-.269.327-.304.372l-.02.026-.49-.798.004.004.58.619zm5.352-5.165l.058.049-.058-.05h0z"
      ></path>
    </svg>
  );
};

export default React.memo(TaskTypeIcon);
