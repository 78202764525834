import React, { useState } from "react";
import MessageDotsCircleIcon from "../svg/MessageDotsCircleIcon";
import { Tooltip } from "antd";
import PeopleAccessDropdown from "../../Modules/Vault/PeopleAccessDropdown";
import { useAppDispatch, useAppSelector } from "../../Core/redux/hooks";
import { updateConversations } from "../../Core/redux/slices/conversations.slice";
import { updateVault } from "../redux/slices/vault.slice";
import ShareAccessModal from "../../Modules/Conversations/Components/ShareAccessModal";
import MenuDropdown from "../../Modules/Conversations/Components/MenuDropdown";
import { useNavigate, useSearchParams } from "react-router-dom";
import uniqid from "uniqid";
import OpenAndShareAccessFile from "../../Modules/Vault/OpenAndShareAccessFile";
import PhaseIcon from "../svg/PhaseIcon";
import { client } from "../../Core/utils/axiosClient";
import { updatePreviewMultiple } from "../redux/slices/preview.slice";

type Props = {
  title: string;
  thumbnail: string;
  fileId: string;
  phaseId: number;
  usersWithAccess?: [];
  file?: any;
};

const defaultProps = {
  // title: 'Title',
};

const CustomFile = ({
  title,
  phaseId,
  fileId,
  thumbnail,
  file,
  usersWithAccess,
}: // file,
Props) => {
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();

  const dispatch = useAppDispatch();
  const [openShareAccessFileModal, setOpenShareAccessFileModal] =
    useState(false);

  const { openCommentsModal } = useAppSelector((state) => state.vault);
  const [openShareAccessModal, setOpenShareAccessModal] = useState(false);

  const { vault } = useAppSelector((state) => state.projects);

  const viewAccess = vault?.[1]?.[0] || {};
  const folderFileLink = vault?.[2]?.[0] || {};
  const requestAccess = vault?.[6]?.[0] || {};
  const deleteAll = vault?.[4]?.[0] || {};
  const commentAccess = vault?.[5]?.[0] || {};

  const phaseName = (id) => {
    switch (id) {
      case "1":
        return "Ideation phase";
      case "2":
        return "Design phase";
      case "3":
        return "Development phase";
      case "4":
        return "Deployment phase";
      case "5":
        return "Maintenance phase";
      default:
        return "Ideation phase";
    }
  };

  const onDownload = () => {
    const link = document.createElement("a");
    link.download = file.name;
    link.href = file.url;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const addFilePreview = () => {
    const updatedSearchParams = new URLSearchParams(searchParams.toString());
    updatedSearchParams.set("filePreview", "true");
    setSearchParams(updatedSearchParams.toString());
  };

  return (
    <div className="actions-row relative w-[173px] h-[182px]  rounded-xl py-[17px] bg-gray-25 cursor-pointer">
      <div className="px-[17px] pb-[13px]">
        <div className="flex justify-between">
          <div className="flex items-center">
            {phaseId && (
              <Tooltip title={phaseName(phaseId)}>
                {/* <div className="w-3 h-3 rounded-[3px] bg-yellow-600" /> */}
                <div>
                  <PhaseIcon width="12px" height="12px" phaseId={+phaseId} />
                </div>
              </Tooltip>
            )}
            {commentAccess.read === 1 && (
              <Tooltip title="Open comments">
                <div
                  className="ml-3"
                  onClick={() =>
                    dispatch(
                      updateVault({
                        key: "openCommentsModal",
                        value: {
                          visible: true,
                          docType: "file",
                          name: title,
                          docId: fileId,
                          comments: [],
                          users: usersWithAccess,
                        },
                      })
                    )
                  }
                >
                  <MessageDotsCircleIcon
                    redDot={
                      openCommentsModal.comments.length > 0 ? true : false
                    }
                  />
                </div>
              </Tooltip>
            )}
            {usersWithAccess.length > 1 && (
              <PeopleAccessDropdown elements={usersWithAccess} />
            )}
          </div>
          <div className="actions absolute right-3 top-3">
            <MenuDropdown
              elements={[
                folderFileLink.read
                  ? {
                      id: 1,
                      image: "/images/icons/info-circle.svg",
                      text: "View details",
                      textColor: "text-gray-700",
                      borderBottom: true,
                      onclick: () =>
                        dispatch(
                          updateVault({
                            key: "showDocDetailsDrawer",
                            value: {
                              visible: true,
                              docId: fileId,
                              docType: "file",
                              docDetails: file,
                            },
                          })
                        ),
                    }
                  : null,
                commentAccess.read
                  ? {
                      id: 2,
                      image: "/images/icons/message-dots-circle-gray.svg",
                      text: "Open comments",
                      textColor: "text-gray-700",
                      onclick: () =>
                        dispatch(
                          updateVault({
                            key: "openCommentsModal",
                            value: {
                              visible: true,
                              docType: "file",
                              name: title,
                              docId: fileId,
                              comments: [],
                              users: usersWithAccess,
                            },
                          })
                        ),
                    }
                  : null,
                folderFileLink.update &&
                (file.isOwner === 1 || file.canEdit === 1)
                  ? {
                      id: 3,
                      image: "/images/icons/add-user-gray.svg",
                      text: "Share access",
                      textColor: "text-gray-700",
                      onclick: () => {
                        setOpenShareAccessModal(true);
                        dispatch(
                          updateVault({
                            key: "shareAccessDoc",
                            value: {
                              docId: fileId,
                              users: usersWithAccess,
                              docType: "file",
                              docDetails: file,
                            },
                          })
                        );
                      },
                    }
                  : null,
                folderFileLink.update &&
                (file.isOwner === 1 || file.canEdit === 1)
                  ? {
                      id: 4,
                      image: "/images/icons/move-to.svg",
                      text: "Move to",
                      textColor: "text-gray-700",
                      onclick: () =>
                        dispatch(
                          updateVault({
                            key: "moveToFolderModal",
                            value: {
                              visible: true,
                              docId: fileId,
                              docType: "files",
                            },
                          })
                        ),
                    }
                  : null,
                file.isOwner === 1 || file.canEdit === 1
                  ? {
                      id: 5,
                      image: "/images/icons/share-gray.svg",
                      text: "Share in message",
                      textColor: "text-gray-700",
                      onclick: () =>
                        dispatch(
                          updateConversations({
                            key: "shareInMessageModal",
                            value: {
                              type: "file",
                              visible: true,
                              url: "",
                              file: [
                                {
                                  name: file.name,
                                  url: file.url,
                                  size: file.fileSize,
                                  type: file.mimeType,
                                  lastModified: uniqid(`${file.name}-`),
                                  savedInVault: true,
                                },
                              ],
                            },
                          })
                        ),
                    }
                  : null,
                {
                  id: 6,
                  image: "/images/icons/copy-dashed.svg",
                  text: "Make a copy",
                  textColor: "text-gray-700",
                  onclick: () =>
                    dispatch(
                      updateVault({
                        key: "copyModal",
                        value: {
                          visible: true,
                          docType: "file",
                          id: fileId,
                        },
                      })
                    ),
                },
                file.isOwner === 1
                  ? {
                      id: 9,
                      image: "/images/icons/user-right-01.svg",
                      text: "Transfer Ownership ",
                      textColor: "text-gray-700",
                      onclick: () => {
                        dispatch(
                          updateVault({
                            key: "transferOwnershipModal",
                            value: {
                              visible: true,
                              id: fileId,
                              docType: 2,
                              usersWithAccess,
                            },
                          })
                        );
                      },
                    }
                  : null,
                {
                  id: 7,
                  image: "/images/icons/download-icon.svg",
                  text: "Download",
                  textColor: "text-gray-700",
                  borderBottom: true,
                  onclick: () => onDownload(),
                },

                folderFileLink.delete &&
                (file.isOwner === 1 || file.canEdit === 1)
                  ? {
                      id: 8,
                      image: "/images/icons/trash.svg",
                      text: "Delete",
                      textColor: "text-error-600",
                      onclick: () =>
                        dispatch(
                          updateVault({
                            key: "deleteFileModal",
                            value: {
                              visible: true,
                              id: fileId,
                            },
                          })
                        ),
                    }
                  : null,
              ]}
              customFunc={undefined}
            />
          </div>
        </div>
      </div>
      <div
        className="flex items-center justify-center mb-3"
        onClick={(e) => {
          dispatch(
            updatePreviewMultiple([
              {
                key: "docs",
                value: [file],
              },
              {
                key: "visible",
                value: true,
              },
              {
                key: "from",
                value: "vault",
              },
              {
                key: "currentIndex",
                value: 0,
              },
            ])
          );
          addFilePreview();
        }}
      >
        <img className="h-[78px]" src={thumbnail} alt="" />
      </div>
      <div
        className="text-center mont-500-12 text-gray-700 px-4 ellipsis-two-lines"
        style={{ width: "172px", wordBreak: "break-all" }}
      >
        {title}
      </div>

      <ShareAccessModal
        visible={openShareAccessModal}
        onCancel={() => setOpenShareAccessModal(false)}
      />

      {/* <OpenAndShareAccessFile
        visible={openAndShareAccessFile.visible}
        onCancel={() => {
          dispatch(
            updateVault({
              key: "openAndShareAccessFile",
              value: {
                id: fileId,
                visible: false,
              },
            })
          );
        }}
      /> */}
    </div>
  );
};

CustomFile.defaultProps = defaultProps;
export default CustomFile;
