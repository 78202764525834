import React, { useState } from "react";
import dayjs from "dayjs";
import CustomModal from "../../../src/Core/Common/CustomModal";
import CustomButton from "../../../src/Core/Common/CustomButton";
import { Row, Col, Dropdown, Menu, DatePicker, Select } from "antd";
// import Multiselect from "multiselect-react-dropdown";
import { CustomSelectWithInlineSearch } from "../../Core/Common/CustomSelectWithSearch";
import CustomSelect from "../../Core/Common/CustomSelect";
import CustomInputWithEdit from "../../Core/Common/CustomInputWithEdit";
import { useAppDispatch, useAppSelector } from "../../Core/redux/hooks";
import {
  createGoal,
  getGoalsBySprint,
  getProjectPhases,
  updateProgress,
} from "../../Core/redux/api/scheduleAPI";
import { useParams } from "react-router-dom";
import { updateSchedule } from "../../Core/redux/slices/schedule.slice";
import NewScopeBadge from "./NewScopeBadge";
import { getProjectDetatis } from "../../Core/redux/api/projectAPI";

type NewGoalModalProps = {
  visible: boolean;
  onCancel: () => void;
};

const NewGoalModal = ({ visible, onCancel }: NewGoalModalProps) => {
  const [isHovering, setIsHovering] = useState(false);
  const [currentDropDown, setCurrentDropDown] = useState("");
  const [goal, setGoal] = useState({
    names: ["Goal 1"],
    isCompleted: 0,
    statusId: 1,
    componentId: "",
    priorityId: 1,
    dueDate: "",
  });

  const disptach = useAppDispatch();
  const { projectId } = useParams();

  const { selectedSprint, currentSelectedPhase, newGoalScope } = useAppSelector(
    (state) => state.schedule
  );

  const handleMouseOver = () => {
    setIsHovering(true);
  };

  const handleMouseOut = () => {
    setIsHovering(false);
  };
  const getStatus = () => {
    return (
      <div className="flex items-center">
        {goal.statusId === 1 ? (
          <div className="flex items-center bg-gray-100 rounded-2xl">
            <p className="mon-500-14 text-gray-700 px-2 uppercase py-0.5">
              To Do
            </p>
          </div>
        ) : goal.statusId === 2 ? (
          <div
            className="flex items-center max-w-max whitespace-nowrap bg-primary-600 rounded-2xl"
            onClick={() => setGoal({ ...goal, statusId: 2 })}
          >
            <p className="mon-500-14 text-white px-2 uppercase py-0.5">
              In progress
            </p>
          </div>
        ) : goal.statusId === 3 ? (
          <div
            className="flex items-center max-w-max whitespace-nowrap bg-success-600 rounded-2xl"
            onClick={() => setGoal({ ...goal, statusId: 3 })}
          >
            <p className="mon-500-14 text-white px-2 uppercase py-0.5">Done</p>
          </div>
        ) : (
          <div className="flex items-center bg-gray-100 rounded-2xl">
            <p className="mon-500-14 text-gray-700 px-2 uppercase py-0.5">
              To Do
            </p>
          </div>
        )}

        <Dropdown
          getPopupContainer={(trigger) => trigger.parentElement}
          className="mt-1"
          overlay={
            <Menu className="w-[140px]  rounded-lg  pt-2">
              <Menu.Item
                // key="copy"
                className=" hover:bg-[#ffff]"
                // style={{ width: "124px", height: "28px" }}
              >
                <div
                  className="flex items-center max-w-max bg-gray-100 rounded-2xl"
                  onClick={() => setGoal({ ...goal, statusId: 1 })}
                >
                  <p className="mon-500-14 text-gray-700 px-2 uppercase py-0.5">
                    To Do
                  </p>
                </div>
              </Menu.Item>
              <Menu.Item
                // key="copy"
                className="hover:bg-[#ffff]"
                //style={{ width: "124px", height: "28px" }}
              >
                <div
                  className="flex items-center max-w-max whitespace-nowrap bg-primary-600 rounded-2xl"
                  onClick={() => setGoal({ ...goal, statusId: 2 })}
                >
                  <p className="mon-500-14 text-white px-2 uppercase py-0.5">
                    In progress
                  </p>
                </div>
              </Menu.Item>
              <Menu.Item
                // key="copy"
                className="hover:bg-[#ffff]"
                //style={{ width: "124px", height: "28px" }}
              >
                <div
                  className="flex items-center max-w-max whitespace-nowrap bg-success-600 rounded-2xl"
                  onClick={() => setGoal({ ...goal, statusId: 3 })}
                >
                  <p className="mon-500-14 text-white px-2 uppercase py-0.5">
                    Done
                  </p>
                </div>
              </Menu.Item>
            </Menu>
          }
          trigger={["click"]}
        >
          <div className="flex w-5 h-5 ml-2 cursor-pointer">
            <img
              src="/images/icons/chevron-down-gray.svg"
              className="w-full h-full"
            />
          </div>
        </Dropdown>
      </div>
    );
  };

  const getPriorityIcon = (val: number) => {
    switch (val) {
      case 1:
        return (
          <div className="flex items-center ">
            <img src="/images/icons/flag-gray.svg" />
            <p className="mon-400-12 text-gray-400 ml-1.5">Low</p>
          </div>
        );
      case 2:
        return (
          <div className="flex items-center">
            <img src="/images/icons/flag-blue.svg" />
            <p className="mon-400-12 text-primary-600 ml-1.5">Medium</p>
          </div>
        );
      case 3:
        return (
          <div className="flex items-center">
            <img src="/images/icons/flag-red.svg" />
            <p className="mon-400-12 text-error-600 ml-1.5">High</p>
          </div>
        );
      default:
        return (
          <div className="flex items-center">
            <img src="/images/icons/flag-gray.svg" />
            <p className="mon-400-12 text-gray-400 ml-1.5">Low</p>
          </div>
        );
    }
  };

  const additionalFunctionality = () => {
    return (
      <Row>
        <CustomButton
          text="Create a folder"
          icon="/images/icons/folder-plus-blue.svg"
          //onClick={onCancel}
          className=" text-primary-700 bg-primary-50 mon-500-14"
          height="40px"
          width="164px"
        />
        <div className="pl-3">
          <CustomButton
            text="Start a thread"
            icon="/images/icons/message-plus-circle-blue.svg"
            //onClick={onCancel}
            className="text-primary-700 bg-primary-50 mon-500-14"
            height="40px"
            width="158px"
          />
        </div>
        <div className="pl-3">
          <CustomButton
            text="Create event"
            icon="/images/icons/calendar-event-blue.svg"
            //onClick={onCancel}
            className="text-primary-700 bg-primary-50 mon-500-14"
            height="40px"
            width="151px"
          />
        </div>
      </Row>
    );
  };

  return (
    <CustomModal
      title={
        <div className="flex items-center">
          <div
            className="flex w-5 h-5"
            onMouseOver={handleMouseOver}
            onMouseOut={handleMouseOut}
          >
            <img
              src="/images/icons/chevron-left.svg"
              className="w-full h-full"
            />
          </div>
          {isHovering && (
            <div className="flex">
              <p className="mon-500-16 pl-3 text-gray-400">Schedule</p>
              <span className="mon-500-16 pl-3 text-gray-400"> / </span>
              <p className="mon-500-16 pl-3 text-gray-400 capitalize">
                {currentSelectedPhase.phases || "Ideation"}
              </p>
            </div>
          )}

          <span className="mon-500-16 pl-3 text-gray-400"> / </span>
          <p className="mon-500-16 pl-3 text-gray-400 capitalize">
            {selectedSprint.name || "Sprint"}
          </p>
          <span className="pl-3"> / </span>
          <p className="flex mon-500-16 text-black pl-3">Goal</p>
          {newGoalScope ? <NewScopeBadge /> : null}
        </div>
      }
      width={"1064px"}
      visible={visible}
      body={
        <div className="max-h-[480px] h-[90vh] overflow-y-scroll">
          <div className="flex items-center px-5 py-5">
            <img
              src="/images/icons/frosty-help.svg"
              className="h-9 w-9"
              alt="frosty"
            />
            <p className="text-sm font-medium text-primary-600 ml-2">
              Goals are the main milestones within each sprint
            </p>
          </div>
          <Row className="pt-5 pb-4 px-5">
            <Col
              className="goal-title flex items-center mon-500-20 text-gray-400 hover:text-black"
              style={{ width: "500px", height: "50px" }}
            >
              <CustomInputWithEdit
                value={goal.names[0]}
                inputClassName="mon-600-20  mon-500-20 text-gray-400 hover:text-black"
                containerClassName="border-0"
                onChange={(e) => {
                  setGoal({ ...goal, names: [e.target.value] });
                }}
              />

              {/* <div className="">
                <img src="" className="goal-title-image" />
              </div> */}
            </Col>
            <Col className="flex pl-12">{getStatus()}</Col>

            <Col className="pt-6">
              <div>
                <div>{additionalFunctionality()}</div>
                <p className="mon-500-16 text-black pt-14">Activity</p>
              </div>
            </Col>

            <Col className="pt-6 pl-14">
              <p className="mon-500-10 text-gray-500 uppercase">Due date</p>
              <div className="pt-2">
                <DatePicker
                  className="w-full h-10 bg-transparent rounded-xl border-none"
                  suffixIcon={<></>}
                  format="DD MMM YY"
                  allowClear={true}
                  placeholder="Select Date"
                  value={goal.dueDate !== "" && (dayjs(goal.dueDate) as any)}
                  onChange={(e) =>
                    setGoal({
                      ...goal,
                      dueDate: dayjs(e as any).format("YYYY-MM-DD"),
                    })
                  }
                />
              </div>
              <p className="pt-5 mon-500-10 text-gray-500 uppercase">
                Priority
              </p>
              {currentDropDown === "priority" ? (
                <CustomSelect
                  options={[
                    {
                      id: "1",
                      value: 1,
                      render: (value) => <p>{getPriorityIcon(1)}</p>,
                    },

                    {
                      id: "2",
                      value: 2,
                      render: (value) => <p>{getPriorityIcon(2)}</p>,
                    },
                    {
                      id: "3",
                      value: 3,
                      render: (value) => <p>{getPriorityIcon(3)}</p>,
                    },
                  ]}
                  value={goal.priorityId}
                  onChange={(e) => setGoal({ ...goal, priorityId: e })}
                  onDropdownVisibleChange={() => setCurrentDropDown("")}
                />
              ) : (
                <p
                  className="pt-3"
                  onClick={() => setCurrentDropDown("priority")}
                >
                  {getPriorityIcon(goal.priorityId)}
                </p>
              )}

              <p className="pt-5 mon-500-10 text-gray-500 uppercase">
                ASSIGNEE
              </p>
              {currentDropDown === "assignee" ? (
                <CustomSelect
                  onDropdownVisibleChange={() => setCurrentDropDown("")}
                  options={[
                    {
                      id: "1",
                      value: "india",
                      render: (value) => (
                        <div className="flex items-center">
                          <div className="w-6 h-6">
                            <img
                              src="/images/Avatar.png"
                              className="w-full h-full"
                            />
                          </div>
                          <p className="mon-400-14 text-gray-900 ml-2">
                            Ebrahim Kothari
                          </p>
                        </div>
                      ),
                    },

                    {
                      id: "1",
                      value: "india1",
                      render: (value) => (
                        <div className="flex items-center ">
                          <div className="w-6 h-6">
                            <img
                              src="/images/Avatar.png"
                              className="w-full h-full"
                            />
                          </div>
                          <p className="mon-400-14 text-gray-900 ml-2">
                            Ebrahim Kothari
                          </p>
                        </div>
                      ),
                    },
                    {
                      id: "2",
                      value: "india2",
                      render: (value) => (
                        <div className="flex items-center">
                          <div className="w-6 h-6">
                            <img
                              src="/images/Avatar.png"
                              className="w-full h-full"
                            />
                          </div>
                          <p className="mon-400-14 text-gray-900 ml-2">
                            Ebrahim Kothari
                          </p>
                        </div>
                      ),
                    },
                  ]}
                  value="india"
                />
              ) : (
                <div
                  className="flex items-center mt-3 cursor-pointer"
                  onClick={() => setCurrentDropDown("assignee")}
                >
                  <div className="w-6 h-6">
                    <img src="/images/Avatar.png" className="w-full h-full" />
                  </div>
                  <p className="mon-400-14 text-gray-900 ml-2">
                    Ebrahim Kothari
                  </p>
                </div>
              )}

              <p className="pt-5 mon-500-10 text-gray-500 uppercase">
                Component (optional)
              </p>
              {currentDropDown === "addComponent" ? (
                <CustomSelectWithInlineSearch
                  options={[
                    {
                      id: "1",
                      title: "Note",
                      options: [
                        {
                          id: "1",
                          value: "india",
                          render: (value) => (
                            <div className="mon-400-14 pl-2 text-gray-900 capitalize ">
                              india
                            </div>
                          ),
                        },
                      ],
                    },
                    {
                      id: "2",
                      title: "Dev",
                      options: [
                        {
                          id: "1",
                          value: "india1",
                          render: (value) => (
                            <div className="mon-400-14 pl-2 text-gray-900 capitalize ">
                              india1
                            </div>
                          ),
                        },
                        {
                          id: "2",
                          value: "india2",
                          render: (value) => (
                            <div className="mon-400-14 pl-2 text-gray-900 capitalize ">
                              india2
                            </div>
                          ),
                        },
                      ],
                    },
                  ]}
                  value="india"
                />
              ) : (
                <button
                  className="flex items-center bg-gray-25 mt-3 rounded-2xl"
                  style={{ width: "155px", height: "28px" }}
                  onClick={() => setCurrentDropDown("addComponent")}
                >
                  <p className="flex pl-3 mon-500-14 text-gray-700">
                    Add component
                  </p>
                  <div className="flex w-3 h-3 ml-1">
                    <img
                      src="/images/icons/plus-grey.svg"
                      className="w-full h-full"
                    />
                  </div>
                </button>
              )}
            </Col>
          </Row>

          <div></div>
        </div>
      }
      footer={
        <div className="shadow-upward flex justify-end py-4 px-4">
          <CustomButton
            text="Cancel"
            onClick={onCancel}
            className="border-2 border-gray-200 text-black bg-white mon-500-14 "
            height="40px"
            width="81px"
          />
          <div className="pl-3">
            <CustomButton
              text="Create"
              onClick={async () => {
                await disptach(
                  createGoal({
                    ...goal,
                    isNewGoal: newGoalScope,
                    projectId,
                    sprintId: selectedSprint.id,
                    phaseId: currentSelectedPhase.id,
                  })
                );
                onCancel();
                disptach(updateSchedule({ key: "newGoalScope", value: false }));
                disptach(
                  getGoalsBySprint({ projectId, sprintId: selectedSprint.id })
                );
                await disptach(
                  updateProgress({
                    phaseId: currentSelectedPhase.id,
                    projectId,
                  })
                );
                disptach(getProjectPhases({ projectId }));
                disptach(getProjectDetatis({ projectId }));
              }}
              className="mon-500-14 text-white  bg-primary-600 "
              height="40px"
              width="79px"
            />
          </div>
        </div>
      }
      onCancel={onCancel}
    />
  );
};

export default NewGoalModal;
