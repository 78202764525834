import { Progress, Col, Row, Dropdown, Menu, Checkbox, Tooltip } from "antd";
import React, { useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import CustomBadge from "../../../Core/Common/CustomBadge";
import CustomButton from "../../../Core/Common/CustomButton";
import CustomSearch from "../../../Core/Common/CustomSearch";
import { useAppDispatch, useAppSelector } from "../../../Core/redux/hooks";
import NewBugModal from "./NewBugModal";
import NewEventModal from "./NewEventModal";
import NewScopeModal from "./NewScopeModal";
import NewSprintModal from "./NewSprintModal";
import NewSubTaskModal from "./NewSubTaskModal";
import NewTaskModal from "./NewTaskModal";
import DeleteSubTaskModal from "./DeleteSubTaskModal";
import DeleteBugModal from "./DeleteBugModal";
import {
  resetSchedule,
  setSelectedSprint,
  updateSchedule,
} from "../../../Core/redux/slices/schedule.slice";
import CompleteSprintModal from "./CompleteSprintModal";
import EndPhaseModal from "./EndPhaseModal";
import DeleteTaskModal from "./DeleteTaskModal";
import DeleteStatusModal from "./DeleteStatusModal";
import dayjs from "dayjs";
import PhaseIcon from "../../../Core/svg/PhaseIcon";
import StartSprintModal from "./StartSprintModal";
import Filter from "./Filter";
import { useRef } from "react";
import StartPhaseModal from "./StartPhaseModal";
import { getGoalsBySprint } from "../../../Core/redux/api/scheduleAPI";
import CustomFrostyMessage from "../../../Core/Common/CustomFrostyMessage";
import exportToCsv, {
  convertArrayToCsv,
} from "../../../Core/utils/exportToCsv";
import DeleteSprintModal from "./DeleteSprintModal";
import { setError } from "../../../Core/redux/slices/global.slice";
import AddFolderModal from "../../Vault/AddFolderModal";
import {
  resetVault,
  updateVault,
} from "../../../Core/redux/slices/vault.slice";
import DuplicateTaskModal from "./DuplicateTaskModal";
import UserQuickFilters from "./UserQuickFilters";
import getUsersName from "../../../Core/utils/getUserName";
import { getPhaseName } from "../../../Core/utils/getPhaseName";
import MoveSwimlaneModal from "./MoveSwimlaneModal";

const ScheduleHeader = () => {
  const {
    currentSelectedPhase,
    selectedSprint,
    deleteSubTaskModal,
    newTaskModal,
    newBugModal,
    newSubTaskModal,
    newSprintModal,
    newScopeModal,
    newEventModal,
    deleteTaskModal,
    deleteBugModal,
    deleteStatusModal,
    startPhaseModal,
    endPhaseModal,
    completeSprintModal,
    filterGoalParameter,
    phases,
    sprints,
    createdSprintId,
    newSprint,
    filters,
    startSprintModal,
    goals,
    deleteSprintModal,
    duplicateTaskModal,
    moveSwimlaneModal,
  } = useAppSelector((state) => state.schedule);
  const dispatch = useAppDispatch();
  const { schedule } = useAppSelector((state) => state.projects);
  const { addFolderModal } = useAppSelector((state) => state.vault);
  const phaseAndSprints = schedule?.[1]?.[0];
  const TaskEventAndScope = schedule?.[2]?.[0];
  const subTaskAndBug = schedule?.[3]?.[0];

  const [selectType, setSelectType] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [phaseSelected, setPhaseSelected] = useState(true);
  const [phaseDropdown, setPhaseDropdown] = useState("");
  const [sprintDropdown, setSprintDropdown] = useState("");
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const filterRef = useRef(null);
  const { phase, projectId, sprint } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();

  const { projectDetails } = useAppSelector((state) => state.overview);
  const { tenantMembers } = useAppSelector((state) => state.tenant);

  const activeSprints = sprints.filter(({ isCompleted }) => isCompleted === 0);

  // console.log(currentSelectedPhase, "selected phase check");

  React.useEffect(() => {
    if (searchParams.get("selectedPhase")) {
      setPhaseDropdown(searchParams.get("selectedPhase"));
      setPhaseSelected(searchParams.has("selectedSprint"));
    }
    // setPhaseSelected(phase);
  }, [searchParams]);

  React.useEffect(() => {
    if (!searchParams.get("selectedPhase")) {
      setSearchParams({ selectedPhase: "Ideation" });
      setPhaseSelected(searchParams.has("selectedSprint"));
    }
    // setPhaseSelected(phase);
  }, []);

  const baseUrl = `/schedule2/${projectId}`;
  const urlWithPhase = `/schedule2/${projectId}/${phase}`;
  const urlWithSprint = `/schedule2/${projectId}/${phase}/${sprint}`;

  const handleSearch = (e) => {
    const inputText = e.toLowerCase();
    dispatch(
      updateSchedule({
        key: "filterGoalParameter",
        value: { ...filterGoalParameter, searchValue: inputText },
      })
    );
  };

  const handleSelectType = (checkedValues) => {
    setSelectType(checkedValues);
    dispatch(
      updateSchedule({
        key: "filterGoalParameter",
        value: { ...filterGoalParameter, typeDropdown: checkedValues },
      })
    );
  };

  const phaseName = (id) => {
    switch (id) {
      case "1":
        return "Ideation";
      case "2":
        return "Design";
      case "3":
        return "Development";
      case "4":
        return "Deployment";
      case "5":
        return "Maintenance";
      default:
        return "Ideation";
    }
  };

  const type = {
    1: "Task",
    2: "Event",
    3: "Bug",
  };

  return (
    <>
      <Col span={24} className="bg-main-background p-6">
        <Row className="flex justify-between items-center">
          <div className="flex items-center justify-center">
            <div className="">
              <CustomSearch
                placeholder={
                  phaseSelected ? "Search tasks, sub tasks" : "Search sprints"
                }
                search={filterGoalParameter?.searchValue}
                setSearch={(e) => handleSearch(e)}
              />
            </div>
            {searchParams.has("selectedSprint") && (
              <div className="ml-3">
                {" "}
                <UserQuickFilters max={4} />{" "}
              </div>
            )}

            {phaseSelected && (
              <Dropdown
                //getPopupContainer={(trigger) => trigger.parentElement}
                className="checkbox-group"
                overlay={
                  <Checkbox.Group
                    onChange={handleSelectType}
                    className="checkbox-group w-full"
                    value={filterGoalParameter?.typeDropdown}
                  >
                    <Menu className="w-[200px] rounded-lg custom-menu-dropdown shadow-dropdown">
                      <Menu.Item className="bg-white py-2.5 px-4 rounded-t-lg">
                        <div
                          className="flex items-center"
                          //onClick={() => setNewSprintModal(true)}
                        >
                          <Checkbox
                            value={1}
                            checked={filterGoalParameter?.typeDropdown.includes(
                              1
                            )}
                          />
                          {/* {console.log(
                            filterGoalParameter?.typeDropdown,
                            "filterGoalParameter"
                          )} */}
                          <img
                            src="/images/icons/task.svg"
                            className="w-4 h-4  ml-3"
                            alt="add-new-task"
                          />
                          <span className="mon-400-12 ml-2 whitespace-nowrap text-gray-700">
                            Task
                          </span>
                        </div>
                      </Menu.Item>
                      <Menu.Item className=" py-2.5 px-4 rounded-t-lg">
                        <div
                          className="flex items-center"
                          //onClick={() => setNewSprintModal(true)}
                        >
                          <Checkbox
                            value={2}
                            checked={filterGoalParameter?.typeDropdown.includes(
                              2
                            )}
                          />
                          <img
                            src="/images/icons/google-calendar.svg"
                            className="w-4 h-4  ml-3"
                            alt="add-new-event"
                          />
                          <span className="mon-400-12 ml-2 whitespace-nowrap text-gray-700">
                            Event
                          </span>
                        </div>
                      </Menu.Item>
                      <Menu.Item className=" py-2.5 px-4 rounded-t-lg">
                        <div
                          className="flex items-center"
                          //onClick={() => setNewSprintModal(true)}
                        >
                          <Checkbox
                            value={3}
                            checked={filterGoalParameter?.typeDropdown.includes(
                              3
                            )}
                          />
                          <img
                            src="/images/icons/bug.svg"
                            className="w-4 h-4 ml-3"
                            alt="add-new-bug"
                          />
                          <span className="mon-400-12 ml-2 whitespace-nowrap text-gray-700">
                            Bug
                          </span>
                        </div>
                      </Menu.Item>
                    </Menu>
                  </Checkbox.Group>
                }
                trigger={["click"]}
                onOpenChange={() => setDropdownOpen(!dropdownOpen)}
              >
                <div
                  className={`${"bg-white text-gray-500"} cursor-pointer flex justify-center items-center  mon-500-14 rounded-xl ml-3 shadow-sm`}
                  style={{ width: "117px", height: "40px" }}
                >
                  <div className="flex justify-center items-center">
                    <p className="">
                      {selectType.length === 0
                        ? "Type"
                        : selectType.length === 1
                        ? `${type[selectType[0]]}`
                        : `${type[selectType[0]]} +${selectType.length - 1}`}
                    </p>

                    <img
                      src="/images/icons/chevron-down-black.svg"
                      className={`w-5 h-5 ml-2 transform duration-300 ${
                        dropdownOpen ? "rotate-180" : "rotate-0"
                      }`}
                    />
                  </div>
                </div>
              </Dropdown>
            )}

            {phaseSelected ? (
              Object.values(filters).some((item) => item.length > 0) ? (
                <div className="border-2 border-primary-100 rounded-lg ml-3">
                  <div className="w-[150px] h-9 bg-primary-600 rounded-lg flex items-center">
                    <p className="mon-500-14 px-[14px] text-white">
                      Filter applied
                    </p>
                    <img
                      src="/images/icons/x-close-white.svg"
                      className="w-5 h-5 cursor-pointer"
                      onClick={() => {
                        dispatch(resetSchedule(["filters"]));
                        dispatch(
                          getGoalsBySprint({
                            projectId,
                            sprintId: searchParams.get("selectedSprint"),
                          })
                        );
                      }}
                    />
                  </div>
                </div>
              ) : (
                <div
                  className="flex justify-center items-center ml-3.5 cursor-pointer"
                  onClick={() => {
                    setIsFilterOpen(true);
                  }}
                  ref={filterRef}
                >
                  <img
                    className="w-5 h-5"
                    src="/images/icons/Filters-lines.svg"
                  />
                  <p className="mon-500-14 ml-2.5  text-gray-500">Filter</p>
                </div>
              )
            ) : (
              <></>
            )}
          </div>

          <Tooltip
            title={`${Math.round(
              +projectDetails.progress
            )}% of project completed.`}
            placement="bottom"
            overlayStyle={{ maxWidth: "455px", minWidth: "455px" }}
            arrowPointAtCenter={true}
          >
            <div className="bg-white flex items-center rounded-xl h-10 px-2.5 shadow-xs">
              <img
                className="w-[30px] h-[30px]"
                src="/images/icons/project-progress.svg"
              />
              <p className="text-gray-500 mon-500-14 px-3">Progress</p>
              <div className="w-[279px]">
                <Progress
                  percent={Math.round(+projectDetails.progress)}
                  status="success"
                  strokeWidth={4}
                  showInfo={false}
                />
              </div>
              <p className="pl-3 mon-500-14 text-primary-600">
                {Math.round(+projectDetails.progress)}%
              </p>
            </div>
          </Tooltip>
        </Row>
        <Row className="mt-6 flex justify-between items-center">
          <div className="flex items-center">
            {phaseSelected && (
              <div className="flex items-center">
                <p
                  className="mon-400-14 text-gray-500 cursor-pointer"
                  onClick={() => {
                    setPhaseSelected(false);
                    searchParams.delete("selectedSprint");
                    setSearchParams(searchParams.toString());
                  }}
                >
                  {searchParams.get("selectedPhase")}
                </p>
                <img
                  src="/images/icons/chevron-right.svg"
                  className="w-4 h-4 mx-3"
                />
              </div>
            )}
            <Dropdown
              className=""
              overlay={
                <Menu
                  className={`w-[200px] overflow-y-scroll rounded-lg custom-menu-dropdown z-10 shadow-dropdown ${
                    phaseSelected ? "h-[190px]" : ""
                  }`}
                >
                  {phaseSelected
                    ? sprints.map((item) => (
                        <Menu.Item
                          className="py-2.5 px-4 rounded-b-lg"
                          key={item.id}
                          onClick={() => {
                            setSprintDropdown(item.name);
                            const updatedSearchParams = new URLSearchParams(
                              searchParams.toString()
                            );
                            updatedSearchParams.set(
                              "selectedSprint",
                              item.id.toString()
                            );
                            setSearchParams(updatedSearchParams.toString());
                            {
                              dispatch(setSelectedSprint(item));
                            }
                          }}
                        >
                          <div className="flex justify-between items-center">
                            <span className="mon-400-12 whitespace-nowrap text-gray-700 capitalize">
                              {`SP-${item.id}`} {item.name}
                            </span>
                            {sprintDropdown === item.name && (
                              <img src="/images/icons/check.svg" alt="" />
                            )}
                          </div>
                        </Menu.Item>
                      ))
                    : phases.map(({ id, phases }) => (
                        <Menu.Item
                          className="py-2.5 px-4 rounded-b-lg"
                          key={id}
                          onClick={() =>
                            setSearchParams({ selectedPhase: phases })
                          }
                        >
                          <p className="mon-400-12 ml-2 whitespace-nowrap text-gray-700 capitalize">
                            {phases}
                          </p>
                        </Menu.Item>
                      ))}
                </Menu>
              }
              trigger={["click"]}
            >
              <div
                className={`${"bg-white text-gray-900"} cursor-pointer flex justify-center items-center  mon-500-12 rounded-lg h-[34px] px-4`}
              >
                <div className="flex justify-center items-center">
                  <p className="mon-500-12 text-gray-900">
                    {phaseSelected ? (
                      sprintDropdown === "" ? (
                        <div className="mon-500-12 ml-2 whitespace-nowrap text-gray-900 capitalize">
                          <span className="text-[11px] font-semibold text-primary-600">
                            {"SP-"}
                            {
                              sprints.find(
                                (active) =>
                                  +active.id ===
                                  +searchParams.get("selectedSprint")
                              )?.id
                            }{" "}
                          </span>
                          {sprints.find(
                            (active) =>
                              +active.id === +searchParams.get("selectedSprint")
                          )?.name === ""
                            ? "N/A"
                            : sprints.find(
                                (active) =>
                                  +active.id ===
                                  +searchParams.get("selectedSprint")
                              )?.name}
                          <span className="mon-400-12 ml-2 whitespace-nowrap text-gray-500">
                            (
                            {
                              sprints.find(
                                (active) =>
                                  +active.id ===
                                  +searchParams.get("selectedSprint")
                              )?.goals.length
                            }{" "}
                            tasks)
                          </span>
                        </div>
                      ) : (
                        <div className="mon-500-12 ml-2 whitespace-nowrap text-gray-900 capitalize">
                          {sprintDropdown}
                          <span className="mon-500-12 ml-2 whitespace-nowrap text-gray-500">
                            (
                            {
                              activeSprints.find(
                                (active) =>
                                  +active.id ===
                                  +searchParams.get("selectedSprint")
                              )?.goals?.length
                            }{" "}
                            Tasks)
                          </span>
                        </div>
                      )
                    ) : phaseDropdown === "" ? (
                      phases[0].phases
                    ) : (
                      phaseDropdown
                    )}
                  </p>
                  <img
                    src="/images/icons/chevron-down-double.svg"
                    className="w-4 h-4 ml-2"
                  />
                </div>
              </div>
            </Dropdown>
            <div
              className="flex items-center gap-x-2 ml-2 cursor-pointer"
              onClick={() => {
                function formatKeys(obj) {
                  const formattedObj = {};
                  for (const key in obj) {
                    switch (key) {
                      case "id":
                        formattedObj["ID"] = obj[key];
                        break;
                      case "name":
                        formattedObj["NAME"] = obj[key];
                        break;
                      case "statusId":
                        formattedObj["STATUS"] =
                          obj[key] == 1
                            ? "TO DO"
                            : obj[key] == 2
                            ? "IN PROGRESS"
                            : "DONE";
                        break;
                      case "status":
                        formattedObj["STATUS"] =
                          obj[key] == 1
                            ? "TO DO"
                            : obj[key] == 2
                            ? "IN PROGRESS"
                            : "DONE";
                        break;
                      case "priorityId":
                        formattedObj["PRIORITY"] =
                          obj[key] == 1
                            ? "LOW"
                            : obj[key] == 2
                            ? "MEDIUM"
                            : "HIGH";
                        break;
                      // case "isNewGoal":
                      //   formattedObj["NEW SCOPE"] =
                      //     obj[key] == 1 ? "YES" : "NO";
                      //   break;
                      case "projectId":
                        formattedObj["PROJECT ID"] = obj[key];
                        formattedObj["PROJECT NAME"] = projectDetails.title;
                        break;
                      case "goalType":
                        formattedObj["GOAL TYPE"] =
                          obj[key] == 1
                            ? "TASK"
                            : obj[key] == 2
                            ? "EVENT"
                            : obj[key] == 3
                            ? "BUG"
                            : "SUBTASK";
                        break;
                      case "parentGoalId":
                        formattedObj["PARENT GOAL ID"] = obj[key];
                        break;
                      // case "sprintId":
                      //   formattedObj["SPRINT ID"] = obj[key];
                      //   break;
                      // case "assigneeId":
                      //   formattedObj["ASSIGNEE ID"] = obj[key];
                      //   formattedObj["ASSIGNEE NAME"] = getUsersName(
                      //     tenantMembers.find((user) => user.userId == obj[key])
                      //   );
                      //   break;
                      // case "reporterId":
                      //   formattedObj["REPORTER ID"] = obj[key];
                      //   formattedObj["REPORTER NAME"] = getUsersName(
                      //     tenantMembers.find((user) => user.userId == obj[key])
                      //   );
                      //   break;
                      case "phaseId":
                        formattedObj["PHASE"] = getPhaseName(obj[key]);
                        break;
                      case "estimatedTime":
                        formattedObj["ESTIMATED TIME"] = obj[key];
                        break;
                      case "timeLogged":
                        formattedObj["TIME LOGGED"] = obj[key];
                        break;
                      // case "componentName":
                      //   formattedObj["COMPONENT NAME"] = obj[key];
                      //   break;
                      case "linkedGoalId":
                        formattedObj["LINKED GOAL ID"] = obj[key];
                        break;
                      case "createdAt":
                        formattedObj["CREATED AT"] = obj[key]
                          ? dayjs(obj[key]).format("DD MMM YYYY")
                          : "-";
                        break;
                      case "startDate":
                        formattedObj["START DATE"] = obj[key]
                          ? dayjs(obj[key]).format("DD MMM YYYY")
                          : "-";
                        break;
                      case "endDate":
                        formattedObj["END DATE"] = obj[key]
                          ? dayjs(obj[key]).format("DD MMM YYYY")
                          : "-";
                        break;
                      // case "isNewSprint":
                      //   formattedObj["NEW SCOPE"] =
                      //     obj[key] == 1 ? "YES" : "NO";
                      //   break;
                      case "goals":
                        formattedObj["TOALT GOALS COUNT"] = obj[key].length;
                        break;
                      case "createdById":
                        formattedObj["CREATED BY"] = getUsersName(
                          tenantMembers.find((user) => user.userId == obj[key])
                        );
                        break;
                      case "phases":
                        if (obj[key].length > 0) {
                          if (obj[key][0]?.phaseId) {
                            const newObj = obj[key].map((a) => {
                              return getPhaseName(a.phaseId);
                            });
                            formattedObj["PHASE"] = newObj.join(" & ");
                          }
                        }
                        break;
                      default:
                        break;
                    }
                  }
                  return formattedObj;
                }

                const data = [...goals];
                goals.forEach((g) => {
                  if (g.subTasks && g.subTasks.length) {
                    data.push(...g.subTasks);
                  }
                });
                // console.log(sprints.map(formatKeys), "convertArrayToCsv");
                // exportToCsv(
                //   "sprint",
                //   searchParams.has("selectedSprint")
                //     ? data.map(formatKeys)
                //     : sprints.map(formatKeys)
                // );
                convertArrayToCsv(
                  searchParams.has("selectedSprint")
                    ? data.map(formatKeys)
                    : sprints.map(formatKeys)
                );
              }}
            >
              <img src="/images/icons/share-05.svg" />
              <p className="mon-500-14 text-gray-500">Export as .csv</p>
            </div>
          </div>
          <div className="flex items-center">
            {phaseSelected && (
              <div className="flex justify-center items-center bg-white h-[26px] rounded-lg gap-x-2 px-2">
                <p className="mon-400-12 text-black">Ongoing phase : </p>
                {phases
                  .filter(
                    ({ isOngoing, isCompleted }) =>
                      isOngoing === 1 && isCompleted === 0
                  )
                  ?.map(({ phaseId }) => (
                    <Tooltip
                      key={phaseId}
                      title={phaseName(phaseId)}
                      placement="bottom"
                    >
                      <div>
                        <PhaseIcon
                          phaseId={+phaseId}
                          height="10px"
                          width="10px"
                        />
                      </div>
                    </Tooltip>
                  ))}
              </div>
            )}
            {selectedSprint?.status === 3 && phaseSelected ? (
              <div className="bg-gray-300 mx-3 text-gray-700 mon-400-12 px-2 rounded-lg">
                Completed
              </div>
            ) : (
              <CustomBadge
                dot
                text={
                  searchParams.has("selectedSprint")
                    ? selectedSprint.status === 2
                      ? "Active"
                      : "Yet to begin"
                    : searchParams.has("selectedPhase")
                    ? currentSelectedPhase.isCompleted
                      ? "Completed"
                      : currentSelectedPhase.isOngoing
                      ? "Ongoing"
                      : "Yet To Begin"
                    : "Not Added"
                }
                color="#027A48"
                bgColor="#D1FADF"
                className="mon-400-12 mx-3"
              />
            )}
            <div className="flex items-center">
              <img src="/images/icons/clock-black.svg" className="w-3 h-3" />
              <div className="mon-400-12 text-gray-700 ml-2 mr-3">
                {phaseSelected
                  ? `${dayjs(selectedSprint.startDate).format(
                      "DD MMM"
                    )} - ${dayjs(selectedSprint.endDate).format("DD MMM")}`
                  : `${dayjs(currentSelectedPhase?.startDate).format(
                      "DD MMM"
                    )} -
                    ${dayjs(currentSelectedPhase?.endDate).format("DD MMM")}`}
              </div>
            </div>
            {phaseAndSprints?.update === 1 && (
              <CustomButton
                text={
                  phaseSelected
                    ? selectedSprint?.status !== 2
                      ? "Start Sprint"
                      : "Complete Sprint"
                    : currentSelectedPhase?.isCompleted === 1 ||
                      currentSelectedPhase?.isOngoing === 0
                    ? "Start Phase"
                    : "End Phase"
                }
                // onClick={() => {
                //   dispatch(updateSchedule({ key: "allactiveSprints", value: [] }));
                //   setAllPhase(!allPhase);
                // }}
                className="border-[1px] border-gray-200 shadow-xs text-primary-blue bg-white mon-500-14 mr-3"
                height="40px"
                width={
                  phaseSelected
                    ? selectedSprint?.status !== 2
                      ? "114px"
                      : "149px"
                    : currentSelectedPhase?.isOngoing === 0
                    ? "114px"
                    : "108px"
                }
                onClick={() =>
                  phaseSelected
                    ? selectedSprint.status !== 2
                      ? currentSelectedPhase.isCompleted === 1
                        ? dispatch(
                            setError({
                              message: `Please Start the Phase first!`,
                            })
                          )
                        : dispatch(
                            updateSchedule({
                              key: "startSprintModal",
                              value: true,
                            })
                          )
                      : dispatch(
                          updateSchedule({
                            key: "completeSprintModal",
                            value: {
                              visible: true,
                              sprintId: "1",
                            },
                          })
                        )
                    : currentSelectedPhase.isCompleted === 1 ||
                      currentSelectedPhase?.isOngoing === 0
                    ? dispatch(
                        updateSchedule({
                          key: "startPhaseModal",
                          value: true,
                        })
                      )
                    : dispatch(
                        updateSchedule({
                          key: "endPhaseModal",
                          value: true,
                        })
                      )
                }
              />
            )}

            {(phaseAndSprints?.create === 1 ||
              TaskEventAndScope?.create === 1) && (
              <Dropdown
                //getPopupContainer={(trigger) => trigger.parentElement}
                className=""
                overlay={
                  <Menu className="w-[200px] rounded-lg custom-menu-dropdown shadow-dropdown">
                    {phaseAndSprints?.create === 1 && (
                      <Menu.Item
                        className={`py-2.5 px-4 rounded-t-lg ${
                          phaseSelected && "border-b-[1px]"
                        }`}
                        onClick={() =>
                          dispatch(
                            updateSchedule({
                              key: "newSprintModal",
                              value: {
                                visible: true,
                                isNewScope: false,
                                scopeDescription: "",
                              },
                            })
                          )
                        }
                      >
                        <div className="flex items-center">
                          <img
                            src="/images/icons/Sprint.svg"
                            className="w-4 h-4"
                            alt="add-new-sprint"
                          />
                          <span className="mon-400-12 ml-2 whitespace-nowrap text-gray-700">
                            New Sprint
                          </span>
                        </div>
                      </Menu.Item>
                    )}

                    {phaseSelected && selectedSprint.status != 3 ? (
                      <>
                        {TaskEventAndScope?.create === 1 && (
                          <>
                            <Menu.Item
                              className=" py-2.5 px-4 rounded-t-lg"
                              onClick={() =>
                                dispatch(
                                  updateSchedule({
                                    key: "newEventModal",
                                    value: true,
                                  })
                                )
                              }
                            >
                              <div className="flex items-center">
                                <img
                                  src="/images/icons/google-calendar.svg"
                                  className="w-4 h-4"
                                  alt="add-new-event"
                                />
                                <span className="mon-400-12 ml-2 whitespace-nowrap text-gray-700">
                                  New Calendar Event
                                </span>
                              </div>
                            </Menu.Item>
                            <Menu.Item
                              className=" py-2.5 px-4 rounded-t-lg"
                              onClick={() =>
                                dispatch(
                                  updateSchedule({
                                    key: "newTaskModal",
                                    value: {
                                      visible: true,
                                      isNewScope: false,
                                      scopeDescription: "",
                                    },
                                  })
                                )
                              }
                            >
                              <div className="flex items-center">
                                <img
                                  src="/images/icons/task.svg"
                                  className="w-4 h-4"
                                  alt="add-new-task"
                                />
                                <span className="mon-400-12 ml-2 whitespace-nowrap text-gray-700">
                                  New Task
                                </span>
                              </div>
                            </Menu.Item>
                          </>
                        )}
                        {subTaskAndBug?.create === 1 && (
                          <Menu.Item
                            className=" py-2.5 px-4 rounded-t-lg"
                            onClick={() =>
                              dispatch(
                                updateSchedule({
                                  key: "newBugModal",
                                  value: true,
                                })
                              )
                            }
                          >
                            <div className="flex items-center">
                              <img
                                src="/images/icons/bug.svg"
                                className="w-4 h-4"
                                alt="add-new-bug"
                              />
                              <span className="mon-400-12 ml-2 whitespace-nowrap text-gray-700">
                                New Bug
                              </span>
                            </div>
                          </Menu.Item>
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                    {TaskEventAndScope?.create === 1 && (
                      <Menu.Item
                        className="py-2.5 px-4 rounded-b-lg bg-pink-25"
                        onClick={() =>
                          dispatch(
                            updateSchedule({
                              key: "newScopeModal",
                              value: true,
                            })
                          )
                        }
                      >
                        <div className="flex items-center">
                          <img
                            src="/images/icons/plus-circle.svg"
                            alt="add-new-scope"
                            className="w-4 h-4"
                          />
                          <span className="mon-400-12 ml-2 whitespace-nowrap text-pink-700">
                            New Scope
                          </span>
                        </div>
                      </Menu.Item>
                    )}
                  </Menu>
                }
                trigger={["click"]}
              >
                <div
                  className={`${"bg-primary-600 text-white"} cursor-pointer flex justify-center items-center  mon-500-14 rounded-xl `}
                  style={{ width: "90px", height: "40px" }}
                >
                  <div className="flex justify-center items-center">
                    <img src="/images/icons/plus.svg" className="w-5 h-5" />
                    <p className={"ml-2.5"}>Add</p>
                  </div>
                </div>
              </Dropdown>
            )}
          </div>
        </Row>
        <Row>
          {phaseSelected &&
            newSprint[0] &&
            +searchParams.get("selectedSprint") === +createdSprintId && (
              <div className="mt-6">
                <CustomFrostyMessage close>
                  <p className="text-primary-600 mon-500-12">
                    <span className="text-primary-600 mon-500-12 capitalize">{`${newSprint[0].title}`}</span>
                    {` created in ${currentSelectedPhase.phases}`}
                  </p>
                </CustomFrostyMessage>
              </div>
            )}
        </Row>
        {isFilterOpen && (
          <div
            className="absolute z-50 top-16"
            style={{
              left: filterRef
                ? filterRef.current?.getBoundingClientRect()?.left - 200
                : "",
            }}
          >
            <Filter onCancel={() => setIsFilterOpen(false)} />
          </div>
        )}
        {newScopeModal && (
          <NewScopeModal
            visible={newScopeModal}
            onCancel={() =>
              dispatch(updateSchedule({ key: "newScopeModal", value: false }))
            }
          />
        )}
        {newSprintModal?.visible && (
          <NewSprintModal
            visible={newSprintModal.visible}
            onCancel={() =>
              dispatch(
                updateSchedule({
                  key: "newSprintModal",
                  value: {
                    visible: false,
                  },
                })
              )
            }
          />
        )}
        {newEventModal && (
          <NewEventModal
            visible={newEventModal}
            onCancel={() =>
              dispatch(resetSchedule(["newEventModal", "selectedEvent"]))
            }
          />
        )}
        {newTaskModal.visible && (
          <NewTaskModal
            visible={newTaskModal.visible}
            onCancel={() =>
              dispatch(resetSchedule(["selectedTask", "newTaskModal"]))
            }
          />
        )}
        {newBugModal && (
          <NewBugModal
            visible={newBugModal}
            onCancel={() =>
              dispatch(resetSchedule(["newBugModal", "selectedBug"]))
            }
          />
        )}
        {newSubTaskModal?.visible && (
          <NewSubTaskModal
            visible={newSubTaskModal.visible}
            onCancel={() => dispatch(resetSchedule(["newSubTaskModal"]))}
          />
        )}

        {deleteSubTaskModal && (
          <DeleteSubTaskModal
            visible={deleteSubTaskModal}
            onCancel={() =>
              dispatch(
                updateSchedule({ key: "deleteSubTaskModal", value: false })
              )
            }
          />
        )}
        {deleteSubTaskModal && (
          <DeleteSubTaskModal
            visible={deleteSubTaskModal}
            onCancel={() =>
              dispatch(
                updateSchedule({ key: "deleteSubTaskModal", value: false })
              )
            }
          />
        )}
        {deleteTaskModal && (
          <DeleteTaskModal
            visible={deleteTaskModal.visible}
            onCancel={() =>
              dispatch(
                updateSchedule({
                  key: "deleteTaskModal",
                  value: { visible: false },
                })
              )
            }
          />
        )}
        {deleteBugModal && (
          <DeleteBugModal
            visible={deleteBugModal.visible}
            onCancel={() =>
              dispatch(
                updateSchedule({
                  key: "deleteBugModal",
                  value: { visible: false },
                })
              )
            }
          />
        )}
        {startPhaseModal && (
          <StartPhaseModal
            visible={startPhaseModal}
            onCancel={() =>
              dispatch(updateSchedule({ key: "startPhaseModal", value: false }))
            }
          />
        )}
        {endPhaseModal && (
          <EndPhaseModal
            visible={endPhaseModal}
            onCancel={() =>
              dispatch(updateSchedule({ key: "endPhaseModal", value: false }))
            }
            onOk={() =>
              dispatch(updateSchedule({ key: "endPhaseModal", value: false }))
            }
          />
        )}
        {deleteStatusModal && (
          <DeleteStatusModal
            visible={deleteStatusModal}
            onCancel={() =>
              dispatch(
                updateSchedule({ key: "deleteStatusModal", value: false })
              )
            }
            onOk={() =>
              dispatch(
                updateSchedule({ key: "deleteStatusModal", value: false })
              )
            }
          />
        )}
      </Col>
      {completeSprintModal?.visible && (
        <CompleteSprintModal
          visible={completeSprintModal.visible}
          onCancel={() => {
            dispatch(
              updateSchedule({
                key: "completeSprintModal",
                value: {
                  visible: false,
                  sprintId: "1",
                },
              })
            );
          }}
          onOk={() => {
            dispatch(
              updateSchedule({
                key: "completeSprintModal",
                value: {
                  visible: false,
                  sprintId: "1",
                },
              })
            );
          }}
        />
      )}

      {startSprintModal && (
        <StartSprintModal
          visible={startSprintModal}
          onCancel={() =>
            dispatch(updateSchedule({ key: "startSprintModal", value: false }))
          }
        />
      )}

      {deleteSprintModal?.visible && (
        <DeleteSprintModal
          visible={deleteSprintModal.visible}
          id={deleteSprintModal.sprintId}
          onCancel={() => {
            dispatch(
              updateSchedule({
                key: "deleteSprintModal",
                value: {
                  visible: false,
                  sprintId: "",
                },
              })
            );
          }}
          onOk={() => {
            dispatch(
              updateSchedule({
                key: "deleteSprintModal",
                value: {
                  visible: false,
                  sprintId: "",
                },
              })
            );
          }}
        />
      )}

      {addFolderModal && (
        <AddFolderModal
          visible={addFolderModal}
          onCancel={() =>
            dispatch(
              resetVault([
                "addFolderModal",
                "shareAccessDoc",
                "preFilledVaultModalValues",
              ])
            )
          }
        />
      )}

      {duplicateTaskModal && (
        <DuplicateTaskModal
          visible={duplicateTaskModal.visible}
          onCancel={() =>
            dispatch(
              updateSchedule({
                key: "duplicateTaskModal",
                value: { visible: false },
              })
            )
          }
        />
      )}
      {moveSwimlaneModal && (
        <MoveSwimlaneModal
          visible={moveSwimlaneModal}
          onCancel={() =>
            dispatch(
              updateSchedule({
                key: "moveSwimlaneModal",
                value: false,
              })
            )
          }
        />
      )}

      {/* <EndPhaseModal
        visible={endPhaseModal}
        onCancel={() => setEndPhaseModal(false)}
        onOk={() => setEndPhaseModal(false)}
      /> */}
    </>
  );
};

export default ScheduleHeader;
