import React, { useEffect } from "react";
import { Routes, Route, useParams } from "react-router-dom";
import { DatePicker, message, notification, Spin } from "antd";
import { getMessaging, onMessage } from "firebase/messaging";
import OverviewScreen from "./Modules/Overview/OverviewScreen";
import ScheduleScreen from "./Modules/Schedule/ScheduleScreen";
import VaultScreen from "./Modules/Vault/VaultScreen";
import ConversationsScreen from "./Modules/Conversations/ConversationsScreen";
import ScheduleScreenNew from "./Modules/ScheduleNew/ScheduleNewScreen";

import io from "socket.io-client";
import { useAppDispatch, useAppSelector } from "./Core/redux/hooks";
import NewDashboardScreen from "./Modules/DashBoard/newDashboardScreen";
import ProjectsScreen from "./Modules/Projects/ProjectsScreen";
import NewLoginScreen from "./Modules/Login/NewLoginScreen";
import SignUpScreen from "./Modules/Login/SignUpScreen";
import ForgotPasswordScreen from "./Modules/Login/ForgotPasswordScreen";
import CompleteForm from "./Modules/Onboarding/Product Onboarding/CompleteForm";
import ProjectSetup from "./Modules/Onboarding/Project Onboarding/ProjectSetup";
import ResetPassword from "./Modules/Login/ResetPassword";
import SettingScreen from "./Modules/Settings/SettingsScreen";
import MainScreen from "./Modules/Notifications/MainScreen";
import { LoadingOutlined } from "@ant-design/icons";

import "./FireBase/firebaseInit";
import ActivityScreen from "./Modules/ActivityLog/ActivityLogScreen";
import ProductSettingsScreen from "./Modules/Settings/Product Settings/ProductSettingsScreen";
import InviteMembers from "./Modules/Settings/InviteMembers";
import FilePreviewScreenNew from "./Modules/Conversations/FilePreviewScreenNew";
import FilePreviewScreen from "./Modules/Conversations/FilePreviewScreen";
import AppWrapper from "./AppWrapper";
import SwitchWorkspaceScreen from "./Modules/SwitchWorkspace/SwitchWorkspaceScreen";
import { setActiveUsers } from "./Core/redux/slices/tenant.slice";
import PrivacyPolicy from "./Modules/PolicyAndService/PrivacyPolicy";
import TermsAndServices from "./Modules/PolicyAndService/TermsAndServices";

const App: React.FC = () => {
  const { user, loggedIn } = useAppSelector((state) => state.userDetails);
  // const { tenantDetails } = useAppSelector((state) => state.tenant);
  const dispatch = useAppDispatch();

  const socketConnectionObject: {
    transports: string[];
    path?: string;
    auth: {
      token: string;
      userId: string | number;
    };
    autoConnect: boolean;
  } = {
    transports: ["websocket"],
    auth: {
      token: user.token,
      userId: user.id,
    },
    autoConnect: false,
  };

  if (process.env.REACT_APP_ENV !== "local") {
    socketConnectionObject.path = "/conversation-socket";
  }
  // conection to socket
  const socket = io(
    `${process.env.REACT_APP_SOCKET_URL}`,
    socketConnectionObject
  );

  useEffect(() => {
    // socket.auth = {
    //   username: user.id,
    // };

    if (socket && loggedIn) {
      socket.connect();
      socket.emit("join", user.id);
      console.log("mounting");

      socket.on("userPresence", ({ currentActiveUsers }) =>
        dispatch(setActiveUsers(currentActiveUsers))
      );

      // socket.on("connect", () => {
      //   console.log("Connected to the server");
      // });

      // socket.on("disconnect", () => {
      //   console.log("Disconnected from the server");
      // });
    } else {
      socket.off("connect");
      socket.off("disconnect");
      socket.off("pong");
      socket.off("userPresence");
    }

    return () => {
      socket.off("connect");
      socket.off("disconnect");
      socket.off("pong");
      socket.off("userPresence");
      console.log("unmounting");
    };
  }, [loggedIn, user.token, socket]);

  return (
    <AppWrapper>
      <div className="App relative">
        <Routes>
          {/* <Route path="/" element={<LoginScreen />} /> */}
          <Route path="/" element={<NewLoginScreen />} />

          <Route path="/login" element={<NewLoginScreen />} />
          <Route path="/signup" element={<SignUpScreen />} />
          <Route path="/forgot-password" element={<ForgotPasswordScreen />} />
          <Route path="/reset-password" element={<ResetPassword />} />

          {/* <Route path="/onboarding" element={<WelcomeScreen />} /> */}
          <Route path="/onboarding" element={<CompleteForm />} />
          <Route path="/project-onboarding" element={<ProjectSetup />} />
          <Route path="dashboard" element={<NewDashboardScreen />} />

          <Route path="/overview/:projectId" element={<OverviewScreen />} />
          <Route path="/schedule2/:projectId" element={<ScheduleScreen />} />
          <Route path="/schedule/:projectId" element={<ScheduleScreenNew />} />
          <Route path="projects" element={<ProjectsScreen />} />
          <Route path="/settings/:projectId" element={<SettingScreen />} />
          <Route path="/invite-members" element={<InviteMembers />} />
          <Route path="/notifications/" element={<MainScreen />} />
          <Route
            path="/vault/:projectId/*"
            element={<VaultScreen socket={socket} />}
          />
          <Route
            path="/conversations"
            element={<ConversationsScreen socket={socket} />}
          />
          <Route path="/activity-log" element={<ActivityScreen />} />

          <Route path="/product-settings" element={<ProductSettingsScreen />} />

          <Route path="/preview2" element={<FilePreviewScreen />} />
          <Route path="/switch-workspace" element={<SwitchWorkspaceScreen />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-and-services" element={<TermsAndServices />} />
        </Routes>
      </div>
    </AppWrapper>
  );
};

export default App;
