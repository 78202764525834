/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from "react";
import CustomModal from "../../../../src/Core/Common/CustomModal";
import CustomButton from "../../../../src/Core/Common/CustomButton";
import CustomInputWithEdit from "../../../Core/Common/CustomInputWithEdit";
import { DatePicker, Divider } from "antd";
import CustomSelect from "../../../Core/Common/CustomSelect";
import { useAppDispatch, useAppSelector } from "../../../Core/redux/hooks";
import { updateProjectOnboarding } from "../../../Core/redux/slices/projectOnboarding.slice";

type AddComponentModalProps = {
  visible: boolean;
  onCancel: () => void;
  phaseId: number | string;
};
const AddComponentModal = ({
  visible,
  onCancel,
  phaseId,
}: AddComponentModalProps) => {
  const phases = [
    {
      id: 1,
      name: "Ideation",
    },
    {
      id: 2,
      name: "Design",
    },
    {
      id: 3,
      name: "Development",
    },
    {
      id: 4,
      name: "Deployment",
    },
    {
      id: 5,
      name: "Maintenance",
    },
  ];
  const [newComponent, setNewComponent] = useState({
    label: "",
    componentName: "",
  });

  const [groupName, setGroupName] = useState("");

  const [selectedPhase, setSelectedPhase] = useState(phaseId);
  const { components, phaseDetails } = useAppSelector(
    (state) => state.projectOnboarding
  );
  const dispatch = useAppDispatch();

  const handleChange = ({ key, value }) => {
    setNewComponent((old) => {
      return { ...old, [key]: value };
    });
  };

  const handelCreate = () => {
    const oldPhaseDetails = [...phaseDetails];
    const newPhaseDetails = oldPhaseDetails.map((p) => {
      if (p.id === phaseId) {
        const components = [...p.components];
        components.push({
          id: p.components.length + 1,
          title: groupName,
          content: [],
        });
        return { ...p, components };
      }
      return p;
    });

    // let newPhase;
    dispatch(
      updateProjectOnboarding({ key: "phaseDetails", value: newPhaseDetails })
    );
    onCancel();
  };
  return (
    <CustomModal
      title={
        <div className="flex items-center">
          <p className="mon-400-12 text-gray-500">New group</p>
        </div>
      }
      width={"384px"}
      height={"295px"}
      visible={visible}
      body={
        <div className="h-[180px] max-w-[384px] w-[90vw] overflow-y-scroll">
          <div className="px-4">
            <div className="pt-2">
              <CustomSelect
                value={selectedPhase}
                placeholder="Select phase"
                options={phases?.map((s) => {
                  return {
                    id: s.id,
                    value: s.id,
                    render: () => <p>{s.name}</p>,
                  };
                })}
                onChange={(e) => setSelectedPhase(e)}
              />
              <div className="w-full mt-6">
                <CustomInputWithEdit
                  label="Group name"
                  value={groupName}
                  inputClassName="text-gray-900 mon-400-12"
                  width="100%"
                  placeholder="Eg. Design thinking"
                  onChange={(e) => setGroupName(e.target.value)}
                />
              </div>
            </div>
          </div>
        </div>
      }
      footer={
        <div className="flex justify-between py-4 gap-x-3 px-3 shadow-upward">
          <div></div>
          <div className="flex gap-x-3">
            <CustomButton
              text="Cancel"
              onClick={() => onCancel()}
              className="border border-gray-300 text-gray-700 bg-white mon-500-14"
              height="40px"
              width="81px"
            />
            <CustomButton
              text="Create"
              className="mon-500-14 text-white bg-primary-600 "
              height="40px"
              width="85px"
              onClick={handelCreate}
            />
          </div>
        </div>
      }
      onCancel={onCancel}
    />
  );
};
export default AddComponentModal;
