import React, { useState } from "react";
import CustomSearch from "../../../Core/Common/CustomSearch";
import DirectMessageProfileDrawer from "../Components/DirectMessageProfileDrawer";
import ThreadProfileDrawer from "../Components/ThreadProfileDrawer";
import SharedFilesDrawer from "../Components/SharedFilesDrawer";
import CustomAvatar from "../../../Core/Common/CustomAvatar";
import { Tooltip } from "antd";
import { useAppDispatch, useAppSelector } from "../../../Core/redux/hooks";
import {
  resetConversations,
  updateConversationsMultiple,
} from "../../../Core/redux/slices/conversations.slice";
import PhaseIcon from "../../../Core/svg/PhaseIcon";
import {
  getPrivateMessages,
  getThreadMessages,
} from "../../../Core/redux/api/conversationAPI";

const ChatHeader = () => {
  const [showSearch, setShowSearch] = useState(false);
  const [showProfileDrawer, setShowProfileDrawer] = useState(false);
  const { showThreadProfileDrawer } = useAppSelector(
    (state) => state.conversations
  );
  const [showSharedFilesDrawer, setShowSharedFilesDrawer] = useState(false);
  const user = {
    firstname: "Alan",
    lastname: "Baker",
    email: "alanbaker@antarcticaglobal.com",
    mobile: "9876543219",
    roleName: "Full Stack Developer",
    profilePicture: "/images/Avatar.png",
    company: "White Insights",
    addedOn: "10 Oct 2022",
  };
  const details = {
    phase: "Ideation",
    phaseIcon: "/images/icons/ideation-icon.svg",
    goalConnected: "",
    creator: "Alan Baker",
    creatorProfilePicture: "/images/Avatar.png",
    createdOn: "10 Oct 2022, 10:30am",
  };
  const sharedFiles = {
    fileType: "",
    fileName: "",
    size: "",
    saveInVault: false,
  };

  // const handleShowSearch = () => {
  //   setShowSearch(!showSearch);
  // };

  const dispatch = useAppDispatch();
  const { currentThread, threads, threadMembers } = useAppSelector(
    (state) => state.conversations
  );

  const { user: userFromRedux } = useAppSelector((state) => state.userDetails);

  const currentThreadUsersList = threads.filter(
    (t) => t.threadId === currentThread.threadId
  );

  const handelSearch = async (e) => {
    const search = e;
    //if (e.length < 3) search = "";
    if (currentThread.type === "group") {
      await dispatch(
        getThreadMessages({
          threadId: currentThread.threadId,
          limit: 100,
          offset: 0,
          search: search,
        })
      );
    } else {
      await dispatch(
        getPrivateMessages({
          userId: userFromRedux.id,
          reciverId: +currentThread.userId,
          limit: 100,
          offset: 0,
          search: search,
        })
      );
    }
  };

  return (
    <div className="flex justify-between  items-center border-b border-gray-200 stretch bg-white   min-h-[58px] max-h-[58px] ">
      <div className="cursor-pointer hover:bg-gray-50  ml-5 rounded-lg p-2">
        {currentThread?.type === "group" ? (
          <Tooltip title="View details" placement="bottom">
            <div className="flex items-center ">
              {Boolean(+currentThread.phaseId) && (
                <PhaseIcon
                  width={"12px"}
                  height={"12px"}
                  phaseId={+currentThread.phaseId}
                />
              )}

              <div
                className="mon-500-12 text-gray-700 pl-2 cursor-pointer capitalize"
                onClick={() => {
                  //setShowProfileDrawer(true);
                  dispatch(
                    updateConversationsMultiple([
                      {
                        key: "showThreadProfileDrawer",
                        value: true,
                      },
                      {
                        key: "editThreadDetails",
                        value: currentThread,
                      },
                    ])
                  );
                }}
              >
                {currentThread.threadName}
              </div>

              <div className="w-5 h-5 ml-3">
                <img
                  src="/images/icons/chevron-down-black.svg"
                  className="w-full h-full transform -rotate-90"
                />
              </div>
            </div>
          </Tooltip>
        ) : (
          <Tooltip title="View details" placement="bottom">
            <div className="flex items-center">
              <CustomAvatar
                title={
                  currentThread.firstname
                    ? currentThread.firstname + " " + currentThread.lastname
                    : currentThread.email
                }
                src={currentThread.profilePicture}
                size={24}
                badge
                userId={currentThread.userId}
              />
              <div
                className="mon-500-12 text-gray-700 pl-2 cursor-pointer capitalize"
                onClick={() => {
                  setShowProfileDrawer(true);
                  //setShowThreadProfileDrawer(true);
                }}
              >
                {currentThread.firstname
                  ? currentThread.firstname + " " + currentThread.lastname
                  : currentThread.email}
              </div>

              <div className="w-5 h-5 ml-3">
                <img
                  src="/images/icons/chevron-down-black.svg"
                  className="w-full h-full transform -rotate-90"
                />
              </div>
            </div>
          </Tooltip>
        )}
      </div>
      <div className="flex items-center  cursor-pointer">
        {showSearch && (
          <CustomSearch
            placeholder="Search"
            allowClear={true}
            className=""
            onBlur={() => setShowSearch(false)}
            setSearch={(val) => handelSearch(val)}
            style={{
              border: "1px solid #7DC7FF",
              width: "285px",
              boxShadow:
                "0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #D1E9FF",
            }}
          />
        )}
        <div
          className="w-5 h-5 mr-8"
          onClick={() => setShowSearch(!showSearch)}
        >
          {!showSearch && (
            <img src="/images/icons/search.svg" className="w-full h-full" />
          )}
        </div>
        {currentThread?.type === "group" && (
          <div
            className="flex items-center w-5 h-5 cursor-pointer mr-10"
            onClick={() => {
              dispatch(
                updateConversationsMultiple([
                  {
                    key: "showThreadProfileDrawer",
                    value: true,
                  },
                  {
                    key: "editThreadDetails",
                    value: currentThread,
                  },
                  { key: "threadProfileDrawerCurrenTab", value: "people" },
                ])
              );
            }}
          >
            <img src="/images/icons/users.svg" className="w-[20px] h-full" />
            <p className="text-gray-500 mon-500-14 pl-2">
              {threadMembers.length}
            </p>
          </div>
        )}
        <div className="w-5 h-5 mr-2 cursor-pointer">
          <img src="/images/icons/shared-file.svg" className="w-full h-full" />
        </div>
        <div
          className="mon-500-14 text-gray-500 capitalize mr-4"
          onClick={() => {
            console.log("dcsdvdvd");
            setShowSharedFilesDrawer(true);
          }}
        >
          Shared files
        </div>
      </div>
      <DirectMessageProfileDrawer
        visible={showProfileDrawer}
        onClose={() => setShowProfileDrawer(false)}
        user={currentThread}
      />
      <ThreadProfileDrawer
        visible={showThreadProfileDrawer}
        onClose={() => {
          dispatch(
            resetConversations([
              "showThreadProfileDrawer",
              "threadProfileDrawerCurrenTab",
            ])
          );
        }}
        user={user}
        details={details}
      />
      <SharedFilesDrawer
        visible={showSharedFilesDrawer}
        onCancel={() => setShowSharedFilesDrawer(false)}
        sharedFiles={sharedFiles}
      />
    </div>
  );
};

export default ChatHeader;
