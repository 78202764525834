import React from "react";
import { ProductOnboardingLayout } from "../../../Core/Layout/OnboardingLayouts";
import CustomButton from "../../../Core/Common/CustomButton";
import { useAppDispatch, useAppSelector } from "../../../Core/redux/hooks";
import { changeStep } from "../../../Core/redux/slices/onboarding.slice";
import { useNavigate } from "react-router-dom";
import { productOnboarding } from "../../../Core/redux/api/onboardingAPI";
import { changeWorkspace } from "../../../Core/redux/api/dashboardAPI";

const Content = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {
    selectedSector,
    selectedSDGs,
    companyName,
    companyLocation,
    companySize,
    companyMembers,
  } = useAppSelector((state) => state.onboarding);

  const { user } = useAppSelector((state) => state.userDetails);

  const onSubmit = async () => {
    const payload = {
      sectorsList: selectedSector,
      sdgs: selectedSDGs,
      tenantName: companyName,
      tenantLocation: companyLocation,
      tenantSize: companySize,
      users: companyMembers,
      createdById: user.id,
    };

    console.log(payload, "payload for product onboarding");

    await dispatch(productOnboarding(payload)).then(async (res) => {
      const work = await dispatch(
        changeWorkspace({ tenantId: res.payload.tenantId })
      );
      const user = {
        ...JSON.parse(localStorage.getItem("user")),
        token: work.payload.token,
      };
      localStorage.setItem("user", JSON.stringify(user));
      navigate("/dashboard");
    });
  };

  return (
    <div className="p-6 w-full h-screen bg-white">
      <div className="pl-14 w-full h-full bg-white flex justify-start items-center">
        <div>
          <div className="flex items-center">
            <h1 className="mon-500-20 text-gray-900">You’re all set!</h1>
            <img
              src="/images/icons/clinking-glasses.svg"
              className="w-7 h-7 ml-1"
            />
          </div>
          <div className="flex justify-start items-center text-show-animation gap-x-2 mt-2 mon-400-16 text-gray-500">
            <img
              src="/images/icons/frosty-icon.svg"
              className="min-h-[26px] min-w-[26px]"
              alt="frosty"
            />
            Congratulations, ready to change the world?
            <img src="/images/icons/earth-emoji.svg" />
          </div>

          <div className="flex items-center gap-x-6 mt-6">
            <CustomButton
              className="bg-primary-600 text-white"
              onClick={() => {
                onSubmit();
              }}
              text="Launch Waddle"
              width="144px"
            />
            <CustomButton
              className="bg-white border-gray-200 border-[1px]"
              onClick={() => dispatch(changeStep(6))}
              text="Back"
              width="68px"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const Sidebar = () => {
  return (
    <div className="w-full h-screen overflow-hidden relative flex justify-center items-center bg-[#D1FADF]">
      <div className="w-[330px] h-[286px]">
        <img
          className="w-full h-full"
          src="/images/icons/s6-launch-waddle-text.svg"
          alt=""
        />
      </div>
    </div>
  );
};

const MapSelection = () => {
  return (
    <ProductOnboardingLayout content={<Content />} sidebar={<Sidebar />} />
  );
};

export default MapSelection;
