import { DatePicker, Tooltip } from "antd";
import React, { useState, useEffect } from "react";
import CustomButton from "../../../Core/Common/CustomButton";
import { CustomOnboardingDateRangePicker } from "../../../Core/Common/CustomOnboardingDatePicker";
import CustomOnboardingInput from "../../../Core/Common/CustomOnboardingInput";
import { useAppDispatch, useAppSelector } from "../../../Core/redux/hooks";
import {
  updateProjectOnboarding,
  changeStep,
} from "../../../Core/redux/slices/projectOnboarding.slice";
import CurrentStep from "./CurrentStep";
import StepHeading from "./StepHeading";
import StepLayout from "./StepLayout";
import CustomAddressInput from "../../../Core/Common/CustomAddressInput";
import { useFormik } from "formik";
import * as Yup from "yup";

const Step3 = () => {
  const [selectedSoftwareType, setSelectedSoftwareType] = useState(0);
  const dispatch = useAppDispatch();
  const { projectName, projectDuration, clientLocation } = useAppSelector(
    (state) => state.projectOnboarding
  );

  const formik = useFormik({
    initialValues: {
      projectDuration: projectDuration,
      clientLocation,
    },
    validationSchema: Yup.object({
      projectDuration: Yup.array()
        .min(2, "Please select Start Date and end date")
        .required("Please select Start Date and end date")
        .typeError("Please select Start Date and end date"),
      clientLocation: Yup.string()
        .required("This field is required")
        .typeError("This field is required"),
      locationSubName: Yup.string(),
      cords: Yup.object().shape({
        lat: Yup.number(),
        long: Yup.number(),
      }),
    }),
    onSubmit: (values) => {
      console.log(values);
      dispatch(changeStep(4));
    },
  });

  return (
    <StepLayout>
      <CurrentStep className={"mt-20"} currentStep={3} totalStep={7} />
      <StepHeading
        title={
          <p className="text-gray-900 mon-500-20">
            Let’s add more information about &quot;{projectName}&quot;
          </p>
        }
        subTitle={<p className="">Start date, end date and location</p>}
      />

      <div className="mt-[18px]">
        <p className="mon-500-14 text-gray-900 ">Set the duration</p>

        <CustomOnboardingDateRangePicker
          {...formik.getFieldProps("projectDuration")}
          onClear={() =>
            dispatch(
              updateProjectOnboarding({ key: "projectDuration", value: null })
            )
          }
          onChange={(e) => {
            formik.setFieldValue("projectDuration", e);
            dispatch(
              updateProjectOnboarding({ key: "projectDuration", value: e })
            );
          }}
          className="mt-2.5"
          value={projectDuration !== null ? projectDuration : ""}
          formikHook={formik}
        />
      </div>

      <div className="mt-[18px]">
        <p className="mon-500-14 text-gray-900 ">
          Which city is your client based in?
        </p>

        <CustomAddressInput
          className={
            clientLocation?.length > 0
              ? "border-b-2 border-primary-600"
              : " border-b-[1px] border-gray-400"
          }
          value={clientLocation}
          name="clientLocation"
          {...formik.getFieldProps("clientLocation")}
          onChange={(value) => {
            formik.setFieldValue("clientLocation", value);
            dispatch(updateProjectOnboarding({ key: "clientLocation", value }));
          }}
          onSelect={({ locationName, locationSubName, lat, long }) => {
            formik.setFieldValue("clientLocation", locationName);
            formik.setFieldValue("locationSubName", locationSubName);
            formik.setFieldValue("cords", { lat, long });
          }}
          formikHook={formik}
        />
      </div>

      <div className="mt-6 flex gap-x-6">
        <CustomButton
          text="Continue"
          height="40px"
          width="98px"
          className={`bg-primary-600 text-white ${
            !formik.isValid ? "opacity-30" : ""
          }`}
          onClick={() => formik.handleSubmit()}
        />
        <CustomButton
          text="Back"
          className="border border-gray-300 text-gray-700 bg-white mon-500-14"
          height="40px"
          width="68px"
          onClick={() => dispatch(changeStep(2))}
        />
      </div>
    </StepLayout>
  );
};

export default Step3;
