const getImageWeServeUrl = (
  url: string,
  option: {
    w?: string;
    h?: string;
    type?: string;
    dpr?: string;
    fit?: string;
  }
) => {
  if (!url) return "";
  let qs = "";
  if (option.w) {
    qs = `&w=${option.w}`;
  }
  if (option.h) {
    qs = `${qs}&h=${option.h}`;
  }
  if (option.type) {
    qs = `${qs}&output=${option.type}`;
  }
  if (option.dpr) {
    qs = `${qs}&dpr=${option.dpr}`;
  }
  if (option.fit) {
    qs = `${qs}&fit=${option.fit}`;
  }
  return `https://images.weserv.nl/?url=${url}${qs}`;
};

export default getImageWeServeUrl;
