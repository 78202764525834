import React from "react";
import CustomModal from "../../Core/Common/CustomModal";
import CustomButton from "../../Core/Common/CustomButton";
import { useAppDispatch, useAppSelector } from "../../Core/redux/hooks";
import { useParams } from "react-router-dom";
import {
  copyFileAPI,
  copyFolderAPI,
  copyLinkAPI,
  getAllFiles,
  getAllFolders,
  getAllLinks,
} from "../../Core/redux/api/vaultAPI";
import { resetVault } from "../../Core/redux/slices/vault.slice";

type CopyFolderModalProps = {
  visible: boolean;
  id: string;
  docType: string;
  onCancel: () => void;
};

const CopyFolderModal = ({
  visible,
  id,
  docType,
  onCancel,
}: CopyFolderModalProps) => {
  const dispatch = useAppDispatch();
  const { projectId, "*": splat } = useParams();
  const { user } = useAppSelector((state) => state.userDetails);
  const { mySpaceFolder } = useAppSelector((state) => state.vault);

  const DocumentsAfterMove = () => {
    const query: { projectId: string; folderId?: string } = { projectId };

    if (splat && splat.length > 0) {
      const folderId = splat.split("-");
      query.folderId = folderId[folderId.length - 1];
    }

    return query;
  };
  const handleCopyFolder = async () => {
    const queryObject = DocumentsAfterMove();

    if (docType === "folder") {
      const payload = {
        folderId: id,
        projectId,
      };
      await dispatch(copyFolderAPI(payload));

      dispatch(resetVault(["copyModal"]));
      dispatch(getAllFolders(queryObject));
    }
    if (docType === "file") {
      const payload = {
        fileId: id,
        projectId,
      };

      //console.log(payload, "payload check for copy file");
      await dispatch(copyFileAPI(payload));

      dispatch(resetVault(["copyModal"]));
      dispatch(getAllFiles(queryObject));
    }

    if (docType === "link") {
      const payload = {
        linkId: id,
        projectId,
      };
      await dispatch(copyLinkAPI(payload));

      dispatch(resetVault(["copyModal"]));
      dispatch(getAllLinks(queryObject));
    }
  };
  return (
    <CustomModal
      title={<p className="mon-500-16 text-gray-600">Make a copy</p>}
      width={"524px"}
      visible={visible}
      body={
        <div className="max-h-[38.189386056191466vh] overflow-y-scroll">
          <div className="mon-400-14 text-gray-500 pt-6 pb-4 px-5">
            {`A copy of this item will be made in “${user?.firstName}'s Space” Folder.`}
          </div>
        </div>
      }
      footer={
        <div className="flex justify-between pt-8 pb-4 px-3">
          <CustomButton
            text="Cancel"
            onClick={onCancel}
            className="border-2 border-gray-200 text-black bg-white mon-500-16 "
            height="44px"
            width="49%"
          />
          <CustomButton
            text="Yes, Copy"
            onClick={handleCopyFolder}
            className="mon-500-16 text-white  bg-primary-600 "
            height="44px"
            width="49%"
          />
        </div>
      }
      onCancel={onCancel}
    />
  );
};

export default CopyFolderModal;
