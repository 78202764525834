

import React from 'react'
import PropTypes from 'prop-types'

type MessageDotsCircleIconProps = {
  color?: string,
  width?: number | string,
  height?: number | string,
  redDot?: boolean
};
const defaultProps = {
  color: '#667085',
  width: 12,
  height: 12,
  redDot: false
}
// width={width} height={height} 
const MessageDotsCircleIcon = ({ color, width, height, redDot }: MessageDotsCircleIconProps) => {
  return (
    <div className='relative'>
    {redDot && <div className='w-1 h-1 rounded-full bg-error-500 absolute right-0'/> }
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width} height={height} 
        fill="none"
        viewBox="0 0 12 12"
      >
        <path
          fill="#F9FBFE"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M10.5 6a4.5 4.5 0 01-6.192 4.171c-.1-.04-.15-.06-.19-.07a.452.452 0 00-.11-.012c-.041 0-.086.007-.177.022l-1.779.297c-.186.031-.279.046-.346.018a.25.25 0 01-.132-.132c-.029-.067-.013-.16.018-.346l.297-1.78c.015-.09.022-.135.022-.176 0-.04-.003-.07-.012-.11-.01-.04-.03-.09-.07-.19A4.5 4.5 0 1110.5 6z"
        ></path>
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.6"
          d="M3.75 6h.005M6 6h.005M8.25 6h.005M4 6a.25.25 0 11-.5 0A.25.25 0 014 6zm2.25 0a.25.25 0 11-.5 0 .25.25 0 01.5 0zM8.5 6A.25.25 0 118 6a.25.25 0 01.5 0z"
        ></path>
      </svg>
    </div>
    
  )
}

MessageDotsCircleIcon.defaultProps = defaultProps

export default MessageDotsCircleIcon
