import React from "react";
import CustomOnboardingInput from "../../../Core/Common/CustomOnboardingInput";
import { ProductOnboardingLayout } from "../../../Core/Layout/OnboardingLayouts";
import CustomButton from "../../../Core/Common/CustomButton";
import { useAppDispatch, useAppSelector } from "../../../Core/redux/hooks";
import {
  handleCompanyLocation,
  handleCompanyName,
} from "../../../Core/redux/slices/onboarding.slice";
import { changeStep } from "../../../Core/redux/slices/onboarding.slice";
import CurrentStep from "../Project Onboarding/CurrentStep";
import CustomAddressInput from "../../../Core/Common/CustomAddressInput";
import { useFormik } from "formik";
import * as Yup from "yup";
import { client } from "../../../Core/utils/axiosClient";
import StepProgressBar from "./StepProgressBar";

const Content = () => {
  const dispatch = useAppDispatch();
  const [name, setName] = React.useState("");
  const [locationName, setLocationName] = React.useState("");
  const { companyName, companyLocation } = useAppSelector(
    (state) => state.onboarding
  );
  const HandleCompanyDetails = (values) => {
    dispatch(handleCompanyName(values.companyName));
    dispatch(handleCompanyLocation(values.locationName));
  };

  const formik = useFormik({
    initialValues: {
      companyName: companyName,
      stationName: null,
      locationName: companyLocation,
      locationSubName: "",
    },
    validationSchema: Yup.object({
      companyName: Yup.string()
        .required("This field is required")
        .typeError("This field is required"),
      locationName: Yup.string()
        .required("Please type the address")
        .typeError("Please type the address"),
      locationSubName: Yup.string(),
      cords: Yup.object().shape({
        lat: Yup.number(),
        long: Yup.number(),
      }),
    }),
    onSubmit: async (values) => {
      console.log(values);

      const { data } = await client.get(
        "/onboarding/check-if-hostname-exists",
        { params: { tenant: values.companyName } }
      );
      console.log(data);
      if (data.exists) {
        formik.setFieldError("companyName", "Already Exist!");
      } else {
        HandleCompanyDetails(values);
        dispatch(changeStep(5));
      }
    },
  });

  console.log(formik.values);

  return (
    <div className="p-6 w-full h-screen bg-white">
      <StepProgressBar currentStep={3} totalSteps={5} givenElementId="form3" />
      <div className="pl-14 w-full h-full bg-white flex justify-start items-center">
        <form
          autoComplete="off"
          // style={{ width: 520 }}
          onSubmit={formik.handleSubmit}
          id="formik"
        >
          <div>
            <CurrentStep
              currentStep={3}
              totalStep={5}
              className=""
              id="form3"
            />
            <h1 className="mon-500-20 text-gray-900 mb-2 mt-6">
              Excellent! Let’s setup your company and acheive these goals
              together
            </h1>
            <div className="flex justify-start items-center text-show-animation gap-x-2 mon-400-16 text-gray-500">
              <img
                src="/images/icons/frosty-icon.svg"
                className="min-h-[26px] min-w-[26px]"
                alt="frosty"
              />
              Let’s put you on the map!
            </div>
            <div>
              <div className="pt-4 gap-x-2">
                <div className="mon-500-14 text-gray-900 mb-4">
                  Company’s Name
                </div>
                <CustomOnboardingInput
                  className="mon-500-14 text-gray-900 py-1 pl-0.5"
                  placeholder="Type your answer here..."
                  name="companyName"
                  {...formik.getFieldProps("companyName")}
                  formikHook={formik}
                  onClear={() => {
                    formik.setFieldValue("companyName", "");
                  }}
                />
                <div className="w-[341px]"></div>
              </div>
              <div className="pt-10 mb-6 gap-x-2">
                <div className="mon-500-14 text-gray-900 mb-4">
                  Where are you based?
                </div>

                <CustomAddressInput
                  className="mon-500-14 text-gray-900"
                  name="locationName"
                  // value={locationName}
                  {...formik.getFieldProps("locationName")}
                  onChange={(value) =>
                    formik.setFieldValue("locationName", value)
                  }
                  onSelect={({ locationName, locationSubName, lat, long }) => {
                    formik.setFieldValue("locationName", locationName);
                    formik.setFieldValue("locationSubName", locationSubName);
                    formik.setFieldValue("cords", { lat, long });
                  }}
                  formikHook={formik}
                />
                <div className=" w-[341px]"></div>
              </div>
              <div className="flex items-center gap-x-6">
                <CustomButton
                  type="submit"
                  className={`bg-primary-600 text-white ${
                    !formik.isValid ? "opacity-30" : ""
                  }`}
                  text="Continue"
                  width="92px"
                  // disabled={name === "" || locationName === "" ? true : false}
                />
                <CustomButton
                  className="bg-white border-gray-200 border-[1px]"
                  onClick={() => dispatch(changeStep(3))}
                  text="Back"
                  width="68px"
                />
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

const Sidebar = () => {
  return (
    <div className="w-full h-screen overflow-hidden relative flex justify-center items-center">
      <img src="/images/icons/marker-pin.svg" className="w-[130px] h-[130px]" />
    </div>
  );
};

const MapSelection = () => {
  return (
    <ProductOnboardingLayout content={<Content />} sidebar={<Sidebar />} />
  );
};

export default MapSelection;
