import React from "react";
import NotificationBody from "./NotificationBody";

const NotificationPopUp = () => {
  return (
    <div className="w-[40vw] h-[680px] max-h-[85vh] bg-white rounded-2xl shadow-box border ml-10 mt-2 overflow-hidden">
      <NotificationBody notificationPopup={true} />
    </div>
  );
};

export default NotificationPopUp;
