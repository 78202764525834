import { Avatar, Checkbox, Col, Divider, Dropdown, Menu, Row } from "antd";
import dayjs from "dayjs";
import React, { useState } from "react";
import CustomAvatar from "../../Core/Common/CustomAvatar";
import CustomSearch from "../../Core/Common/CustomSearch";
import CustomLayout from "../../Core/Layout/CustomLayout";
import { useAppSelector } from "../../Core/redux/hooks";
import PhaseIcon from "../../Core/svg/PhaseIcon";
import { getPhaseName } from "../../Core/utils/getPhaseName";
import { activities } from "./activities";
import ActivityLogSideBar from "./ActivityLogSidebar";

const CheckboxGroup = Checkbox.Group;

const ActivityBadge = ({ ActivityType, title, body }) => {
  const details = [
    // {
    //   id: 1,
    //   name: "image",
    //   icon: "/images/icons/notifications/image.svg",
    // },
    // {
    //   id: 5,
    //   name: "link",
    //   icon: "/images/icons/notifications/link.svg",
    // },
    // {
    //   id: 3,
    //   name: "video",
    //   icon: "/images/icons/notifications/video.svg",
    // },

    {
      id: 17,
      name: "subtask",
      icon: "/images/icons/notifications/subtask.svg",
    },

    {
      id: 11,
      name: "task",
      icon: "/images/icons/notifications/task.svg",
    },

    {
      id: 5,
      name: "google meet",
      icon: "/images/icons/google-meet.svg",
    },

    {
      id: 14,
      name: "new scope",
      icon: "/images/icons/notifications/scope.svg",
    },
    {
      id: 13,
      name: "bug",
      icon: "/images/icons/notifications/bug.svg",
    },

    {
      id: 10,
      name: "sprint",
      icon: "/images/icons/notifications/sprint.svg",
    },
    {
      id: 12,
      name: "project",
      icon: "/images/icons/notifications/project.svg",
    },
    {
      id: 7,
      name: "thread",
      icon: "/images/icons/notifications/thread.svg",
    },

    {
      id: 22,
      name: "project",
      icon: "/images/icons/notifications/project.svg",
    },
    {
      id: 18,
      name: "folder",
      icon: "/images/icons/notifications/folder.svg",
    },
  ];

  const item = details.find(({ id }) => id === +ActivityType);

  return (
    <div className="flex justify-start gap-x-1.5 h-12 max-h-max max-w-max bg-white  px-1.5 pr-2 items-center rounded-xl hover:shadow-md mt-2">
      <div className="w-10 h-10">
        <img src={item.icon} className=" object-fill" />
      </div>
      <div className="flex flex-col  justify-center">
        <span className="mon-500-12 text-gray-900 capitalize">{title}</span>
        <span className="mon-400-10 text-gray-500">{body}</span>
      </div>
    </div>
  );
};

const UserCard = ({ url, title, body }) => {
  return (
    <div className="flex justify-start gap-x-1.5 h-12 max-h-max max-w-max bg-white  px-1.5 pr-2 items-center rounded-xl hover:shadow-md mt-2">
      <div className="w-10 h-10">
        <CustomAvatar src={url} title={title} size={40} />
      </div>
      <div className="flex flex-col  justify-center">
        <span className="mon-500-12 text-gray-900 capitalize">{title} </span>
        <span className="mon-400-10 text-gray-500">{body}</span>
      </div>
    </div>
  );
};

const PhaseCard = ({ phaseId, body }) => {
  return (
    <div className="flex justify-start gap-x-1.5 h-12 max-h-max max-w-max bg-white  px-1.5 pr-2 items-center rounded-xl hover:shadow-md mt-2">
      <PhaseIcon phaseId={1} width="30px" height="30px" icon />

      <div className="flex flex-col  justify-center">
        <span className="mon-500-12 text-gray-900 capitalize">
          {getPhaseName(phaseId)}
        </span>
        <span className="mon-400-10 text-gray-500">{body}</span>
      </div>
    </div>
  );
};

const ActivityScreen = () => {
  const { activityLogs } = useAppSelector((state) => state.activityLogs);
  const getTitle = (activity, meta) => {
    const activityType = activity.activityType;
    switch (+activityType) {
      case 3:
        return (
          <>
            <span className="mon-400-12 text-gray-700">signed up using </span>
            <span className="text-primary-600 mon-500-12">{meta.email}</span>
          </>
        );
      case 4:
        return (
          <>
            <span className="mon-400-12 text-gray-700">signed in using </span>
            <span className="text-primary-600 mon-500-12">{meta.email}</span>
          </>
        );
      case 7:
        return (
          <>
            <span className="mon-400-12 text-gray-700">
              {meta.type === "deleteThread"
                ? "deleted the thread"
                : "created a new thread"}
            </span>
            <span className="text-primary-600 mon-500-12">
              {activity.projectTitle ? " in " + activity.projectTitle : ""}
            </span>
          </>
        );
      case 8:
        return (
          <>
            <span className="mon-400-12 text-gray-700">
              created a new project{" "}
            </span>
            <span className="text-primary-600 mon-500-12">
              {meta.projectName}
            </span>
          </>
        );
      case 9:
        if (meta.type === "phaseStart") {
          return (
            <>
              <span className="mon-400-12 text-gray-700">
                ended {getPhaseName(meta.phase.phaseId)} phase in{" "}
              </span>
              <span className="text-primary-600 mon-500-12">
                {activity.projectTitle}
              </span>
            </>
          );
        }
        return (
          <>
            <span className="mon-400-12 text-gray-700">
              started {getPhaseName(meta.phase.phaseId)} phase in{" "}
            </span>
            <span className="text-primary-600 mon-500-12">
              {activity.projectTitle}
            </span>
          </>
        );
      case 10:
        if (meta.type === "addSprint") {
          return (
            <>
              <span className="mon-400-12 text-gray-700">
                created a new sprint in {getPhaseName(meta.phaseId)} phase in{" "}
              </span>
              <span className="text-primary-600 mon-500-12">
                {activity.projectTitle}
              </span>
            </>
          );
        }
        return <></>;
      case 11:
        return (
          <>
            <span className="mon-400-12 text-gray-700">
              created a new task in {meta.sprint} in{" "}
            </span>
            <span className="text-primary-600 mon-500-12">
              {activity.projectTitle}
            </span>
          </>
        );

        return <></>;
      case 13:
        return (
          <>
            <span className="mon-400-12 text-gray-700">
              created a new bug in {meta.sprint} in{" "}
            </span>
            <span className="text-primary-600 mon-500-12">
              {activity.projectTitle}
            </span>
          </>
        );

        return <></>;
      case 17:
        return (
          <>
            <span className="mon-400-12 text-gray-700">
              created a new subtask in {meta.sprint} in{" "}
            </span>
            <span className="text-primary-600 mon-500-12">
              {activity.projectTitle}
            </span>
          </>
        );

        return <></>;
      case 14:
        if (meta.type === "sprint") {
          return (
            <>
              <span className="mon-400-12 text-gray-700">
                created a new scope in {getPhaseName(meta.phaseId)} phase in{" "}
              </span>
              <span className="text-primary-600 mon-500-12">
                {activity.projectTitle}
              </span>
            </>
          );
        }
        return (
          <>
            <span className="mon-400-12 text-gray-700">
              created a new scope in {getPhaseName(meta.phaseId)} phase in{" "}
            </span>
            <span className="text-primary-600 mon-500-12">
              {activity.projectTitle}
            </span>
          </>
        );
      case 16:
        return (
          <>
            <span className="mon-400-12 text-gray-700">
              enabled an integration tool{" "}
            </span>
            <span className="text-primary-600 mon-500-12">
              {meta.integrationName}
            </span>
          </>
        );
      case 18:
        return (
          <>
            <span className="mon-400-12 text-gray-700">
              {meta.type === "folderCretate" ? "created" : "deleted"} a new
              folder in{" "}
            </span>
            <span className="text-primary-600 mon-500-12">
              {activity.projectTitle}
            </span>
          </>
        );
      case 19:
        return (
          <>
            <span className="mon-400-12 text-gray-700">
              {meta.type === "fileCreate"
                ? "uploaded a new file in "
                : "deleted file in "}
            </span>
            <span className="text-primary-600 mon-500-12">
              {activity.projectTitle}
            </span>
          </>
        );
      case 20:
        return (
          <>
            <span className="mon-400-12 text-gray-700">
              {meta.type === "linkCreate"
                ? "created a new link in "
                : "deleted link in "}
            </span>
            <span className="text-primary-600 mon-500-12">
              {activity.projectTitle}
            </span>
          </>
        );
      case 21:
        return (
          <>
            <span className="mon-400-12 text-gray-700">
              shared the access of {meta.name} with {meta.users.length} team
              members in{" "}
            </span>
            <span className="text-primary-600 mon-500-12">
              {activity.projectTitle}
            </span>
          </>
        );
      case 22:
        return (
          <>
            <span className="mon-400-12 text-gray-700">
              viewed profile details of{" "}
            </span>
            <span className="text-primary-600 mon-500-12">
              <CustomAvatar
                title={meta.firstname}
                src={meta.profilePicture}
                size={24}
              />{" "}
              {meta.firstname} {meta.lastname}{" "}
            </span>
          </>
        );
      case 23:
        return (
          <>
            <span className="mon-400-12 text-gray-700">
              {meta.type === "addMeta"
                ? "added the Meta data "
                : "removed the Meta data "}
            </span>
            <span className="text-primary-600 mon-500-12">
              {meta.category_name}
            </span>
          </>
        );
      default:
        <></>;
    }
  };

  const getBody = (activity, meta) => {
    // <ActivityBadge ActivityType={1} metaDetails={{}} />
    const activityType = activity.activityType;
    switch (+activityType) {
      case 3:
      case 4:
      case 8:
      case 16:
        return <></>;
      case 9:
        return (
          <PhaseCard
            phaseId={meta.phase.phaseId}
            body={
              <>
                {dayjs(meta.phase.startDate).format("DD MMM")} -{" "}
                {dayjs(meta.phase.endDate).format("DD MMM")}
              </>
            }
          />
        );
      case 10:
        return (
          <ActivityBadge
            ActivityType={+activityType}
            title={meta.sprintName}
            body={
              <>
                {dayjs(meta.startDate).format("DD MMM")} -{" "}
                {dayjs(meta.endDate).format("DD MMM")}
              </>
            }
          />
        );
      case 14:
        return (
          <ActivityBadge
            ActivityType={+activityType}
            title={meta.sprintName || meta.sprint}
            body={
              <>
                {dayjs(meta.startDate).format("DD MMM")} -{" "}
                {dayjs(meta.endDate).format("DD MMM")}
              </>
            }
          />
        );
      case 7:
        return (
          <ActivityBadge
            ActivityType={+activityType}
            title={meta.name}
            body={<>{meta.usersCount} Members</>}
          />
        );
      case 11:
      case 13:
      case 17:
        return (
          <ActivityBadge
            ActivityType={+activityType}
            title={meta.name}
            body={<>{meta.sprint}</>}
          />
        );
      case 18:
        return (
          <ActivityBadge
            ActivityType={+activityType}
            title={meta.folderName}
            body={<>{"O Mb"}</>}
          />
        );
      case 21:
        return (
          <div className="flex gap-2">
            {meta.users &&
              meta.users.length > 0 &&
              meta.users.map((user) => (
                <UserCard
                  url={user.profilePicture}
                  title={user.firstname + " " + user.lastname}
                  body=""
                  key={user.id}
                />
              ))}
          </div>
        );
      default:
        <></>;
    }
  };
  return (
    <CustomLayout page="activity" sidebar={false}>
      <div className="flex max-h-[calc(100vh-56px)] overflow-hidden">
        <ActivityLogSideBar />
        <div className="w-full bg-main-background pb-4 max-h-[calc(100vh-56px)] min-h-[calc(100vh-56px)] overflow-y-scroll flex flex-col px-6  mt-4	 ">
          {activityLogs &&
            activityLogs.length > 0 &&
            activityLogs.map((activity) => (
              <div className="flex items-center w-full p-3" key={activity.id}>
                <>{console.log(activity.meta, "activity object")}</>
                <div className="flex justify-start items-start gap-x-2 w-full">
                  <CustomAvatar
                    title={activity.firstname}
                    src={activity.profilePicture}
                    size={24}
                  />
                  <div className=" w-full ">
                    <p>
                      <span className="mon-500-12 text-gray-900 capitalize">
                        {activity.firstname} {activity.lastname}{" "}
                      </span>
                      {getTitle(activity, JSON.parse(activity.meta))}
                      <span className="px-2">|</span>
                      <span className="mon-400-12 text-gray-500">
                        {activity.createdAt
                          ? dayjs(activity.createdAt)
                              .utc(true)
                              .local()
                              .format("hh:mm a")
                          : ""}
                      </span>
                    </p>

                    {getBody(activity, JSON.parse(activity.meta))}
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </CustomLayout>
  );
};

export default ActivityScreen;
