import { Tooltip } from "antd";
import dayjs from "dayjs";
import React, { useState } from "react";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { PhaseIconPure } from "../svg/PhaseIcon";
import CustomOnboardingDatePicker from "./CustomOnboardingDatePicker";

export const CustomPhaseCard = ({
  phaseId,
  phaseName,
  startDate,
  endDate,
  disabled = false,
  color,
  onChange,
}) => {
  const dispatch = useAppDispatch();
  const [hover, setHover] = useState(false);

  const [focused, setFocused] = useState(false);

  const handleChange = (key, val) => onChange(phaseId, key, val);

  const diff =
    startDate && endDate ? dayjs(endDate).diff(startDate, "days") : 0;

  function createDisableDate(dateType, projectDuration) {
    return (current) => {
      switch (dateType) {
        case "startDate":
          return current > dayjs(endDate);
        case "endDate":
          return current < dayjs(startDate);
      }
    };
  }

  return (
    <div
      className=" rounded-2xl h-[222px] min-w-[180px] w-max-[180px] pt-[18px] pb-[16px] px-4 relative hover:shadow-[0px 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.06)] transform duration-200"
      style={{
        border: `1px solid ${color}`,
        backgroundColor: disabled ? "#F7FAFD" : "#F9FBFE",
        cursor: disabled ? "not-allowed" : "pointer",
        boxShadow: hover
          ? "0px 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.06)"
          : "",
      }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onFocus={() => setFocused(true)}
      // onFocus={()=>conso}
      //onBlur={() => setFocused(false)}
    >
      {disabled ? (
        <Tooltip
          title={
            <p className="mon-500-12">
              Not included.
              <br />
              Add in the previous step
            </p>
          }
          placement="right"
        >
          <div
            className=" rounded-2xl h-[222px] w-[180px] pt-[18px] pb-[16px] px-4 absolute bg-gray-50 z-10 top-0 left-0 "
            style={{
              opacity: 0.5,
            }}
          ></div>
        </Tooltip>
      ) : (
        <></>
      )}
      <div className="flex items-center ">
        <PhaseIconPure
          phaseId={phaseId}
          width="32"
          height="32"
          color={focused ? "#101828" : "#667085"}
        ></PhaseIconPure>

        <p className="mon-500-12 ml-3.5">{phaseName}</p>
      </div>
      <CustomOnboardingDatePicker
        value={startDate}
        onChange={(e) =>
          handleChange("startDate", dayjs(e).format("YYYY-MM-DD"))
        }
        placeholder="Start Date"
        className="mt-3"
        disableDate={createDisableDate("startDate", diff)}
      />
      <CustomOnboardingDatePicker
        value={endDate}
        onChange={(e) => handleChange("endDate", dayjs(e).format("YYYY-MM-DD"))}
        placeholder="End Date"
        className="mt-3"
        disableDate={createDisableDate("endDate", diff)}
      />
      <div className="mon-500-10 text-gray-400 mt-6">Duration</div>
      <div className="mon-500-12 text-gray-700 mt-1.5">{diff} days</div>
    </div>
  );
};
