import React from "react";
import { Dropdown, Menu } from "antd";
import CustomAvatar from "../../Core/Common/CustomAvatar";

const PeopleAccessDropdown = ({ elements }) => {
  return (
    <Dropdown
      getPopupContainer={(trigger) => trigger.parentElement}
      className=""
      overlay={
        <Menu className="w-[320px] rounded-lg custom-menu-dropdown shadow-dropdown">
          <div className="py-2.5 pl-4 mon-500-14 text-gray-500">
            People with access
          </div>
          {elements.map((item) => (
            <Menu.Item
              className={`py-2.5 rounded-t-lg ${
                item.borderBottom && "border-b-[1px] pb-2"
              }`}
              key={item.id}
              onClick={item.onclick}
            >
              <div className="flex items-center justify-between">
                <div className="flex">
                  <div className="w-5 h-5">
                    <CustomAvatar
                      size={24}
                      title={`${item.firstname} ${item.lastname}`}
                      src={item.profilePicture}
                    />
                  </div>
                  <div
                    className={`pl-3 pt-0.5 mon-400-12 whitespace-nowrap ${item.textColor}`}
                  >
                    {`${item.firstname} ${item.lastname}`}
                  </div>
                </div>
                <div className="pt-0.5 mon-400-14 text-gray-500 whitespace-nowrap">
                  {item.isOwner
                    ? "Owner"
                    : item.canEdit
                    ? "Can edit"
                    : "Can view"}
                </div>
              </div>
            </Menu.Item>
          ))}
        </Menu>
      }
      trigger={["click"]}
    >
      <div className="ml-3 cursor-pointer">
        <img src="/images/icons/users.svg" alt="people with access" />
      </div>
    </Dropdown>
  );
};

export default PeopleAccessDropdown;
