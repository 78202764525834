import { createAsyncThunk } from "@reduxjs/toolkit";
import { client, getError } from "../../utils/axiosClient";

export const getTodos = createAsyncThunk(
  "dashboard/getTodos",
  async (
    {
      userId,
    }: {
      userId: number;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await client.get(
        `/dashboard/get-todos?userId=${userId}`
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(getError(error));
      //return error;
    }
  }
);

export const createTodo = createAsyncThunk(
  "dashboard/createTodo",
  async (
    {
      userId,
      title,
    }: {
      userId: number;
      title: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await client.post(`/dashboard/create-todo`, {
        userId,
        title,
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(getError(error));
      //return error;
    }
  }
);

export const updateTodo = createAsyncThunk(
  "dashboard/updateTodo",
  async (
    {
      userId,
      todoId,
      status,
      title,
    }: {
      userId: number;
      todoId: number;
      status: number;
      title: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await client.put(`/dashboard/update-todo`, {
        params: {
          userId,
          todoId,
          status,
          title,
        },
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(getError(error));
      //return error;
    }
  }
);

export const deleteTodo = createAsyncThunk(
  "dashboard/deleteTodo",
  async (
    {
      userId,
      todoId,
    }: {
      userId: number;
      todoId?: number;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await client.delete(`/dashboard/delete-todo`, {
        params: {
          userId,
          todoId,
        },
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(getError(error));
      //return error;
    }
  }
);

export const getOngoingProjectCount = createAsyncThunk(
  "dashboard/getOngoingProjects",
  async (
    {
      userId,
    }: {
      userId: number;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await client.get(
        `/dashboard/project-count?userId=${userId}`
      );

      return response.data[0];
    } catch (error) {
      return rejectWithValue(getError(error));
    }
  }
);

export const getCompletedProjectCount = createAsyncThunk(
  "dashboard/getCompletedProjectCount",
  async (
    {
      userId,
    }: {
      userId: number;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await client.get(
        `/dashboard/completed-project-count?userId=${userId}`
      );

      return response.data[0];
    } catch (error) {
      return rejectWithValue(getError(error));
    }
  }
);

export const getMyTasks = createAsyncThunk(
  "dashboard/getMyTasks",
  async (
    {
      userId,
      status,
      projectId,
    }: {
      userId: number;
      status: number;
      projectId: number | string;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await client.get(
        `/dashboard/my-tasks?userId=${userId}&status=${status}&projectId=${projectId}`
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(getError(error));
    }
  }
);

export const getWorkspaces = createAsyncThunk(
  "dashboard/getWorkspaces",
  async () => {
    try {
      const response = await client.get("/auth/get-workspaces");
      console.log("getWorkspaces:", response.data, response);
      return response.data;
    } catch (err) {
      console.log(err);
    }
  }
);

export const changeWorkspace = createAsyncThunk(
  "dashboard/changeWorkspace",
  async ({ tenantId }: { tenantId: string }, { rejectWithValue }) => {
    try {
      const response = await client.post("/auth/change-workspace", {
        tenantId,
      });
      const user = {
        ...JSON.parse(localStorage.getItem("user")),
        token: response.data.token,
      };
      localStorage.setItem("user", JSON.stringify(user));
      return response.data;
    } catch (error) {
      return rejectWithValue(getError(error));
    }
  }
);
