import { useFormik } from "formik";
import React, { useEffect } from "react";
import CustomButton from "../../Core/Common/CustomButton";
import CustomInput from "../../Core/Common/CustomInput";
import EyeIcon from "../../Core/svg/EyeIcon";
import * as Yup from "yup";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { client } from "../../Core/utils/axiosClient";
import RightScreen from "./RightScreen";

// type Props = {}

const ResetPassword = () => {
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();

  const token = searchParams.get("token");

  useEffect(() => {
    if (!searchParams.has("token")) {
      navigate("/");
    }
  }, [searchParams]);

  const handleResetPassword = async ({
    email,
    confirmPassword,
    password: newPassword,
  }) => {
    try {
      const response = await client.put(`/auth/reset-password`, {
        email,
        isForgot: true,
        token,
        newPassword,
        confirmPassword,
      });

      // const { valid } = response.data;
      // if (valid) {
      //   setCheckingEmail(false);
      //   if (typeof callback === "function") callback();
      // } else {
      //   console.log("hehe");
      //   setIsEmailValid(false);
      // }
      if (response.status === 200) {
        navigate("/");
      }
      console.log(response);
    } catch (error) {
      console.log(error, "eee");
      console.log(error.response?.data?.message);
      formik.setFieldError(
        "email",
        error.response?.data?.message || "Something went wrong"
      );
      //return error;
    } finally {
      // setCheckingEmail(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      email: null,
      password: null,
      confirmPassword: null,
      showPassword: false,
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Please enter a valid email address")
        .required("Please enter the email address")
        .typeError("Please enter the email address"),
      password: Yup.string()
        .required("This field is required")
        .typeError("Please enter the email address"),
      // https://codesandbox.io/s/l2r832l8x7?file=/src/index.js:147-464
      confirmPassword: Yup.string().when("password", {
        is: (val) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref("password")],
          "Both passwords need to be the same"
        ),
      }),
    }),
    onSubmit: (values) => {
      console.log(values);
      const { showPassword } = values;

      if (showPassword) handlePasswordVisible(false);
      handleResetPassword(values);
    },
  });

  const handlePasswordVisible = (toggleState) => {
    formik.setFieldValue("showPassword", toggleState);
  };

  return (
    <div className="flex w-screen h-screen">
      <div className="flex-[6] bg-blue-50">
        <RightScreen />
      </div>
      <div className="flex-[4] flex-center h-screen overflow-y-auto">
        <div className="w-[400px] h-[90vh] flex flex-col justify-start px-4 pt-[10vh]">
          <div>
            <div className="mon-600-24 text-primary-600 mb-6">
              Create new password
            </div>
            <div className="mt-5.5 mon-400-14 text-gray-500">
              Enter a new password
            </div>

            <form
              autoComplete="off"
              // style={{ width: 520 }}
              onSubmit={formik.handleSubmit}
              id="formik"
              className="mt-5.5"
            >
              <CustomInput
                label="Email"
                labelDefaultCase
                name={"email"}
                type="email"
                placeholder="Enter email address"
                {...formik.getFieldProps("email")}
                formikHook={formik}
                className="mt-5.5"
              />

              <CustomInput
                label="New password"
                labelDefaultCase
                name={"password"}
                type={formik.values.showPassword ? "text" : "password"}
                placeholder="Must have atleast 6 characters"
                {...formik.getFieldProps("password")}
                formikHook={formik}
                className="mt-5.5"
                suffix={
                  <EyeIcon
                    color={"#667085"}
                    width={16}
                    show={formik.values.showPassword}
                    onToggle={handlePasswordVisible}
                  />
                }
              />

              <CustomInput
                label="Confirm password"
                labelDefaultCase
                name={"confirmPassword"}
                type={formik.values.showPassword ? "text" : "password"}
                placeholder="Must have atleast 6 characters"
                {...formik.getFieldProps("confirmPassword")}
                formikHook={formik}
                className="mt-5.5"
                suffix={
                  <EyeIcon
                    color={"#667085"}
                    width={16}
                    show={formik.values.showPassword}
                    onToggle={handlePasswordVisible}
                  />
                }
              />

              <CustomButton
                text="Reset Password"
                type="submit"
                height="36px"
                width="100%"
                className={`text-white ${
                  !(formik.isValid && formik.dirty)
                    ? "bg-primary-100"
                    : "bg-primary-600"
                } mon-500-14 mt-12`}
                disabled={!(formik.isValid && formik.dirty)}
              />
            </form>
            <div className="flex items-center justify-between mt-12">
              <div className="mon-400-14 text-gray-500">
                {"Don't have an account?"}
              </div>
              <div>
                <CustomButton
                  text="Sign Up"
                  className="text-primary-700 bg-primary-50 mon-500-14"
                  height="36px"
                  width="110px"
                  onClick={() => navigate("/signup")}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
