import React, { useState } from "react";
import CustomButton from "../../../Core/Common/CustomButton";
import CustomModal from "../../../Core/Common/CustomModal";
import { Dropdown, Menu, Tabs, Input, Checkbox } from "antd";
import {
  resetSchedule,
  updateSchedule,
} from "../../../Core/redux/slices/schedule.slice";
import { useAppDispatch, useAppSelector } from "../../../Core/redux/hooks";

import { useNavigate, useParams } from "react-router-dom";
import {
  createGoal,
  getGoalsBySprint,
  updateGoal,
  updateProgress,
} from "../../../Core/redux/api/scheduleAPI";
import Comments from "./Comments";
import TaskTypeIcon from "../../../Core/svg/TaskTypeIcon";
import CustomDragAndDrop from "../../../Core/Common/CustomDragAndDrop";
import CustomInputWithSaveAndEdit from "../../../Core/Common/CustomInputWithSaveAndEdit";
import TaskPriorityDropDown from "./TaskPriorityDropDown";
import TaskPhaseDropDown from "./TaskPhaseDropDown";
import TimeInput from "./TimeInput";
import InfoModal, {
  CategoryDropDown,
  ComponentDropdown,
  CustomLeft,
  RowLayout,
  StatusDropdown,
  UploadAndAttachments,
  UserDropdown,
} from "./TaskResuable";
import {
  resetVault,
  updateVault,
} from "../../../Core/redux/slices/vault.slice";
import * as Yup from "yup";
import { useFormik } from "formik";

import AddFolderModal from "../../Vault/AddFolderModal";
import { setError } from "../../../Core/redux/slices/global.slice";
import MenuDropdown from "../../Conversations/Components/MenuDropdown";
import { getSprintsByPhase } from "../../../Core/redux/api/scheduleAPI";

type NewTaskModalProps = {
  visible: boolean;
  onCancel: () => void;
};

const NewTaskModal = ({ visible, onCancel }: NewTaskModalProps) => {
  const dispatch = useAppDispatch();
  const { userList } = useAppSelector((state) => state.overview);
  const { user } = useAppSelector((state) => state.userDetails);
  const { selectedSprint, currentSelectedPhase, newTaskModal, phases } =
    useAppSelector((state) => state.schedule);
  const { projectId } = useParams();
  const { TabPane } = Tabs;
  const [currentTab, setCurrentTab] = useState("description");
  const [currentDropDown, setCurrentDropDown] = useState("");

  const { selectedTask } = useAppSelector((state) => state.schedule);
  const [uploadedFileList, setUploadedFileList] = useState([]);
  const [copyForAnotherTask, setCopyForAnotherTask] = useState(false);

  const assignee = userList.find(
    (user) => user.userId === selectedTask?.assigneeId
  );
  const { addFolderModal } = useAppSelector((state) => state.vault);
  const reporter = userList.find(
    (user) => user.userId === selectedTask?.reporterId
  );

  const navigate = useNavigate();
  const onAttachmentClick = () => dispatch(resetSchedule(["newTaskModal"]));

  const formik = useFormik({
    initialValues: {
      name: null,
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required("Please enter the title of the Task")
        .typeError("Please enter the title of the Task"),
    }),
    onSubmit: async (values) => {
      if (!selectedTask) {
        createTask();
      } else {
        await dispatch(
          updateGoal({ name: task.taskName, goalId: selectedTask.id })
        );
      }
      // createBug();
    },
  });

  const editTask = selectedTask
    ? {
        ...selectedTask,

        taskName: selectedTask.name,

        assignee: `${
          assignee?.firstname === null ? "User" : assignee?.firstname
        } ${assignee?.lastname === null ? "User" : assignee?.lastname}`,
        reporter: `${
          reporter?.firstname === null ? "User" : reporter?.firstname
        } ${reporter?.lastname === null ? "User" : reporter?.lastname}`,

        statusId: selectedTask.statusId,
        priorityId: selectedTask.priorityId,
        assigneeProfilePicture: assignee?.profilePicture,
        reporterProfilePicture: reporter?.profilePicture,
        description: selectedTask.description,
        comments: selectedTask.comments,
        estimatedTime: selectedTask.estimatedTime,
        timeLogged: selectedTask?.timeLogged,
        component: selectedTask?.component,
        componentId: selectedTask?.componentId,
        componentName: selectedTask?.componentName,
        categoryId: selectedTask?.categoryId,
        attachments: selectedTask?.attachments
          ? JSON.parse(selectedTask?.attachments)
          : [],
      }
    : {
        goalType: 1,
        taskName: "",
        assigneeId: user.id,
        reporterId: user.id,
        assignee: `${user.firstName === null ? "User" : user.firstName} ${
          user.lastName === null ? "User" : user.lastName
        }`,
        reporter: `${user.firstName === null ? "User" : user.firstName} ${
          user.lastName === null ? "User" : user.lastName
        }`,
        statusId: 1,
        priorityId: 1,
        estimatedTime: "",
        assigneeProfilePicture: user.profilePicture,
        reporterProfilePicture: user.profilePicture,
        description: "",
        comments: "",
        phaseId: currentSelectedPhase.id,
        phaseName: "",
        categoryId: 0,
        categoryName: "",
        categoryColor: "",
        timeLogged: "",
        componentId: "",
        componentName: "",
        attachments: [],
      };

  const [task, setTask] = useState(editTask);
  console.log(selectedSprint, "ssid");

  const createTask = async () => {
    const payload = {
      sprintId: selectedSprint.id,
      projectId: projectId,
      phaseId: task.phaseId,
      names: [task.taskName],
      isCompleted: 0,
      isNewGoal: Number(newTaskModal.isNewScope),
      statusId: task.statusId,
      priorityId: task.priorityId,
      goalType: task.goalType,
      assigneeId: task.assigneeId,
      reporterId: task.reporterId,
      description: task.description,
      estimatedTime: task.estimatedTime,
      timeLogged: task.timeLogged,
      componentId: task.componentId,
      componentName: task.componentName,
      categoryId: task.categoryId,
      attachments: JSON.stringify(task.attachments),
    };

    // console.log(payload, "payload");

    await dispatch(createGoal(payload));

    if (!copyForAnotherTask) {
      onCancel();
      dispatch(resetSchedule(["newTaskModal"]));
    }
    setTask({ ...task, taskName: "" });
    dispatch(getGoalsBySprint({ projectId, sprintId: selectedSprint.id }));
    dispatch(
      updateProgress({
        phaseId: currentSelectedPhase.id,
        projectId,
      })
    );
  };

  return (
    <>
      <CustomModal
        title={
          <div className="flex items-center">
            <div className="flex items-center">
              <p className="mon-400-12 pl-1 text-gray-500">
                {selectedSprint.name}
              </p>
              <img
                src="/images/icons/chevron-right.svg"
                className="w-4 h-4 ml-2"
              />
              <p className="flex mon-500-12 text-gray-700 pl-2">
                {task.taskName}
              </p>
              {newTaskModal.isNewScope && (
                <div className="ml-3 flex items-center bg-pink-25 h-[22px] rounded-xl gap-x-2 px-2">
                  <TaskTypeIcon type="newscope" width="12px" height="12px" />
                  <p className="mon-500-12 text-pink-700">New Scope</p>
                </div>
              )}
            </div>
          </div>
        }
        width="704px"
        visible={visible}
        menuDropdown={
          selectedTask ? (
            <MenuDropdown
              elements={[
                {
                  id: 1,
                  image: "/images/icons/copy-dashed.svg",
                  text: "Duplicate",
                  textColor: "text-gray-700",
                  borderBottom: true,
                  onclick: () =>
                    dispatch(
                      updateSchedule({
                        key: "duplicateTaskModal",
                        value: {
                          visible: true,
                          duplicateTaskDetails: { ...selectedTask },
                        },
                      })
                    ),
                },
                {
                  id: 2,
                  image: "/images/icons/trash.svg",
                  text: "Delete",
                  textColor: "text-error-600",
                  onclick: () =>
                    dispatch(
                      updateSchedule({
                        key: "deleteTaskModal",
                        value: {
                          visible: true,
                          statusId: selectedTask.statusId,
                          goalId: selectedTask.id,
                        },
                      })
                    ),
                },
              ]}
              customFunc={undefined}
            />
          ) : (
            <></>
          )
        }
        height="608px"
        body={
          <div className="pt-4 pl-4 max-h-[55vh] overflow-y-scroll">
            <CustomInputWithSaveAndEdit
              width="98%"
              inputClassName="mon-600-14 text-gray-900"
              placeholder="New Task"
              {...formik.getFieldProps("name")}
              onChange={(e) => {
                setTask({ ...task, taskName: e.target.value });
                formik.setFieldValue("name", e.target.value);
              }}
              onOk={async () => {
                if (selectedTask) {
                  formik.handleSubmit();
                }
              }}
              value={task.taskName}
              formikHook={formik}
            />

            <RowLayout
              customLeft={
                <CustomLeft icon="/images/icons/users.svg" text="Assignee" />
              }
              className="mt-4"
            >
              <UserDropdown
                fieldName="Assignee"
                task={task}
                setTask={setTask}
                userList={userList}
                onEdit={async (id) => {
                  if (selectedTask) {
                    await dispatch(
                      updateGoal({
                        assigneeId: id,
                        goalId: selectedTask.id,
                      })
                    );
                  }
                }}
              />
            </RowLayout>

            <RowLayout
              customLeft={
                <CustomLeft icon="/images/icons/users.svg" text="Reporter" />
              }
              className="mt-4"
            >
              <UserDropdown
                fieldName="Reporter"
                task={task}
                setTask={setTask}
                userList={userList}
                onEdit={async (id) => {
                  if (selectedTask) {
                    await dispatch(
                      updateGoal({
                        reporterId: id,
                        goalId: selectedTask.id,
                      })
                    );
                  }
                }}
              />
            </RowLayout>
            <RowLayout
              customLeft={
                <CustomLeft icon="/images/icons/tag-01.svg" text="Status" />
              }
              className="mt-4"
            >
              <StatusDropdown
                setStatusId={async (e) => {
                  if (selectedSprint && selectedSprint.status < 2) {
                    // const swimlane = swimlanes.find((s)=>s.id == status)
                    // if(s)
                    dispatch(
                      setError({
                        message: `Please Start the Sprint first!`,
                      })
                    );

                    return "";
                  }
                  setTask((oldState) => {
                    const newState = {
                      ...oldState,
                      statusId: e.statusId,
                      swimlaneId: e.swimlaneId,
                    };
                    return newState;
                  });
                  if (selectedTask) {
                    await dispatch(
                      updateGoal({
                        ...e,
                        goalId: selectedTask.id,
                      })
                    );
                    if (e.statusId == 3) {
                      dispatch(
                        updateProgress({
                          phaseId: currentSelectedPhase.id,
                          projectId,
                        })
                      );
                    }
                  }
                }}
                statusId={task.statusId}
                task={task}
              />
            </RowLayout>
            <RowLayout
              customLeft={
                <CustomLeft icon="/images/icons/flag-03.svg" text="Priority" />
              }
              className="mt-2"
            >
              <div className="min-w-[218px]">
                <TaskPriorityDropDown
                  task={task}
                  currentDropDown={currentDropDown}
                  setTask={async (e) => {
                    setTask({ ...task, priorityId: e });
                    if (selectedTask) {
                      await dispatch(
                        updateGoal({
                          priorityId: e,
                          goalId: selectedTask.id,
                        })
                      );
                    }
                  }}
                  setCurrentDropDown={setCurrentDropDown}
                />
              </div>
            </RowLayout>

            <RowLayout
              customLeft={
                <CustomLeft icon="/images/icons/circle-cut.svg" text="Phase" />
              }
              className=""
            >
              <TaskPhaseDropDown
                currentDropDown={currentDropDown}
                task={task}
                setTask={setTask}
                setCurrentDropDown={setCurrentDropDown}
                phases={phases}
                onEdit={async (e) => {
                  if (selectedTask) {
                    await dispatch(
                      updateGoal({
                        phaseId: e,
                        goalId: selectedTask.id,
                      })
                    );
                  }
                }}
              />
            </RowLayout>
            <RowLayout
              customLeft={
                <CustomLeft
                  icon="/images/icons/category.svg"
                  text=" Category"
                />
              }
              className="mt-1"
            >
              <CategoryDropDown
                goalType={1}
                currentDropDown={currentDropDown}
                task={task}
                setTask={setTask}
                setCurrentDropDown={setCurrentDropDown}
                onEdit={async (e) => {
                  if (selectedTask) {
                    await dispatch(
                      updateGoal({
                        categoryId: e,
                        goalId: selectedTask.id,
                      })
                    );
                  }
                }}
              />
            </RowLayout>

            <RowLayout
              customLeft={
                <CustomLeft
                  icon="/images/icons/clock-stopwatch.svg"
                  text="Estimated time"
                >
                  <InfoModal />
                </CustomLeft>
              }
              className="mt-1"
            >
              <TimeInput
                className=""
                value={task.estimatedTime}
                onChange={(e) => setTask({ ...task, estimatedTime: e })}
                onOk={async (newTime: string) => {
                  if (selectedTask) {
                    await dispatch(
                      updateGoal({
                        estimatedTime: newTime,
                        goalId: selectedTask.id,
                      })
                    );
                  }
                }}
              />
            </RowLayout>

            <RowLayout
              customLeft={
                <CustomLeft
                  icon="/images/icons/clock-stopwatch.svg"
                  text="Time logged"
                >
                  <InfoModal />
                </CustomLeft>
              }
              className="mt-1"
            >
              <TimeInput
                className=""
                addNewTimeToPreviousTime
                value={task.timeLogged}
                onChange={(e) => setTask({ ...task, timeLogged: e })}
                onOk={async (newTime: string) => {
                  if (selectedTask) {
                    await dispatch(
                      updateGoal({
                        timeLogged: newTime,
                        goalId: selectedTask.id,
                      })
                    );
                  }
                }}
              />
            </RowLayout>
            <RowLayout
              customLeft={
                <CustomLeft
                  icon="/images/icons/tool-01.svg"
                  text="Component (optional)"
                />
              }
              className="mt-1"
            >
              <ComponentDropdown
                task={task}
                setTask={setTask}
                currentDropDown={currentDropDown}
                setCurrentDropDown={setCurrentDropDown}
                onEdit={async (e) => {
                  if (selectedTask) {
                    await dispatch(
                      updateGoal({
                        ...e,
                        goalId: selectedTask.id,
                      })
                    );
                  }
                }}
              />
            </RowLayout>

            {newTaskModal.isNewScope && (
              <>
                <div className="flex items-center pt-2">
                  <img
                    src="/images/icons/plus-circle.svg"
                    className="w-5 h-5"
                    alt="add-item"
                  />
                  <p className="mon-500-14 text-gray-700 pl-2">
                    Reason for adding a new scope
                  </p>
                </div>
                <Input.TextArea
                  rows={2}
                  placeholder="new scope"
                  required={true}
                  disabled
                  className="w-full h-full mt-3 rounded-xl border border-gray-300 text-gray-900 mon-400-12 pl-4 pt-2 active:outline-none"
                  value={newTaskModal.scopeDescription}
                ></Input.TextArea>
              </>
            )}

            <div className="mt-6 pr-8">
              <Tabs
                onTabClick={(key) => {
                  setCurrentTab(key);
                }}
                activeKey={currentTab}
                className="overview-tab mt-8"
              >
                <TabPane
                  tab={
                    <div className="flex">
                      <div
                        className={`${
                          currentTab === "description"
                            ? "mon-500-14 text-primary-600"
                            : "text-gray-500 mon-400-14"
                        }`}
                      >
                        Description
                      </div>
                    </div>
                  }
                  key="description"
                >
                  <Input.TextArea
                    rows={3}
                    placeholder="Add a description"
                    required={true}
                    className="w-full h-full bg-white rounded-xl border border-gray-300 text-gray-900 mon-400-12 pl-4 pt-2 active:outline-none mb-6"
                    value={task.description}
                    onChange={async (e) => {
                      setTask({ ...task, description: e.target.value });
                      if (selectedTask) {
                        await dispatch(
                          updateGoal({
                            description: e.target.value,
                            goalId: selectedTask.id,
                          })
                        );
                      }
                    }}
                  ></Input.TextArea>
                  <>{console.log(task, "UploadAndAttachments222")}</>
                  <UploadAndAttachments
                    task={task}
                    setTask={setTask}
                    isEdit={selectedTask}
                    onAttachmentClick={onAttachmentClick}
                    onEdit={async (e) => {
                      //if (e.length < 1) return;
                      if (selectedTask) {
                        await dispatch(
                          updateGoal({
                            attachments: JSON.stringify(e),
                            goalId: selectedTask.id,
                          })
                        );
                      }
                    }}
                  />
                </TabPane>
                <TabPane
                  tab={
                    <div className="flex">
                      <div
                        className={`${
                          currentTab === "comment"
                            ? "mon-500-14 text-primary-600"
                            : "text-gray-500 mon-400-14"
                        }`}
                      >
                        Comment
                      </div>
                    </div>
                  }
                  key="comment"
                >
                  <Comments
                    docId={task.id}
                    docType="task"
                    visible={true}
                    user={[{ userId: task.assigneeId }]}
                  />
                </TabPane>
              </Tabs>
            </div>
          </div>
        }
        footer={
          <div className="flex justify-between py-4 px-3 shadow-upward">
            {selectedTask ? (
              <div
                className="flex items-center gap-x-2 px-4 bg-primary-50 rounded-xl cursor-pointer"
                onClick={() => {
                  if (selectedTask.folderId !== null) {
                    navigate(`/vault/${projectId}`);
                    onCancel();
                  } else {
                    dispatch(resetVault(["addFolderModal"]));
                    dispatch(
                      updateVault({ key: "addFolderModal", value: true })
                    );
                    dispatch(resetVault(["preFilledVaultModalValues"]));
                    dispatch(
                      updateVault({
                        key: "preFilledVaultModalValues",
                        value: {
                          goalId: task.id,
                          phaseId: currentSelectedPhase.phaseId,
                        },
                      })
                    );
                    dispatch(
                      getSprintsByPhase({
                        projectId,
                        phaseId: currentSelectedPhase.id,
                      })
                    );
                    onCancel();
                  }
                }}
              >
                <img src="/images/icons/folder-blue.svg" />
                <p className="mon-500-14 text-primary-700">
                  {selectedTask?.folderId === null
                    ? "Create folder"
                    : "View folder"}
                </p>
              </div>
            ) : (
              <div className="flex items-center gap-x-2">
                <Checkbox
                  className="mt-0.5"
                  onChange={() => setCopyForAnotherTask(!copyForAnotherTask)}
                />
                <p className="mon-500-14 text-gray-500">
                  Copy for another task
                </p>
              </div>
            )}
            <div className="flex gap-x-3">
              <CustomButton
                text={selectedTask ? "Close" : "Cancel"}
                onClick={() => {
                  !selectedTask &&
                    newTaskModal.isNewScope &&
                    dispatch(
                      updateSchedule({ key: "newScopeModal", value: true })
                    );
                  onCancel();
                }}
                className="border border-gray-300 text-gray-700 bg-white mon-500-14"
                height="40px"
                width="81px"
              />
              {!selectedTask && (
                <CustomButton
                  text="Create"
                  className="mon-500-14 text-white bg-primary-600 "
                  height="40px"
                  width="79px"
                  onClick={() => {
                    formik.handleSubmit();
                  }}
                />
              )}
            </div>
          </div>
        }
        onCancel={onCancel}
      />

      {/* <>
        {addFolderModal && (
          <AddFolderModal
            visible={addFolderModal}
            onCancel={() =>
              dispatch(updateVault({ key: "addFolderModal", value: false }))
            }
          />
        )}
      </> */}
    </>
  );
};

export default NewTaskModal;
