import React, { useEffect, useState } from "react";
import { Drawer } from "antd";
import ViewFileDetailsDrawer from "./ViewFileDetailsDrawer";
import MenuDropdown from "./MenuDropdown";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../Core/redux/hooks";
import {
  resetConversations,
  updateConversations,
  updateConversationsMultiple,
} from "../../../Core/redux/slices/conversations.slice";
import bytesToSize from "../../../Core/utils/bytesToSize";
import getImageWeServeUrl from "../../../Core/utils/getImageWeServeUrl";
import { downloadFiles } from "../../../Core/utils";
import ShareMessageModal from "../Common/ShareMessageModal";
import { updatePreviewMultiple } from "../../../Core/redux/slices/preview.slice";

type SharedFileseDrawerProps = {
  visible: boolean;
  onCancel: () => void;
  sharedFiles?: {
    fileType: string;
    fileName: string;
    size: string;
    saveInVault: boolean;
  };
};

const SharedFileseDrawer = ({
  visible,
  onCancel,
  sharedFiles,
}: SharedFileseDrawerProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [showFileDetails, setShowFileDetails] = useState(false);

  const [filesSaveInVault, setFilesSaveInVault] = useState([]);
  const [filesNotSaveInVault, setFilesNotSaveInVault] = useState([]);
  const { shareInMessageModal } = useAppSelector(
    (state) => state.conversations
  );
  const { currentThreadMessage, viewFileDetails } = useAppSelector(
    (state) => state.conversations
  );

  const [searchParams, setSearchParams] = useSearchParams();

  const addFilePreview = () => {
    const updatedSearchParams = new URLSearchParams(searchParams.toString());
    updatedSearchParams.set("filePreview", "true");
    setSearchParams(updatedSearchParams.toString());
  };

  const details = {
    phase: "Ideation",
    phaseIcon: "/images/icons/ideation-icon.svg",
    folder: "",
    owner: "Alan Baker",
    ownerProfilePicture: "/images/Avatar.png",
    uploadedOn: "10 Oct 2022, 10:30am",
    size: "24MB",
  };

  const sharedFilesInMessage = [];
  // const message = currentThreadMessage
  //   .filter((m) => m.attachments?.length > 0)
  //   .forEach((newM) => {
  //     const att = newM.attachments.map((m) => {
  //       return { ...m, messageId: newM._id };
  //     });
  //     sharedFilesInMessage.push(...att);
  //   });
  // console.log(sharedFilesInMessage, "messsssssss");

  // const filesSaveInVault = sharedFilesInMessage.filter(
  //   (file) => file.savedInVault
  // );
  // const filesNotSaveInVault = sharedFilesInMessage.filter(
  //   (file) => !file.savedInVault
  // );

  useEffect(() => {
    const attachmentsWithMessageId = currentThreadMessage
      .filter((m) => m.attachments?.length > 0)
      .flatMap((newM) => {
        return newM.attachments.map((m) => ({ ...m, messageId: newM._id }));
      });

    setFilesSaveInVault(
      attachmentsWithMessageId.filter((file) => file.savedInVault)
    );
    setFilesNotSaveInVault(
      attachmentsWithMessageId.filter((file) => !file.savedInVault)
    );
  }, [currentThreadMessage]);

  console.log(currentThreadMessage, "messsssssss");

  const getFileIcon = (file) => {
    if (file.type.includes("image")) {
      return getImageWeServeUrl(file.url, {});
    }
    if (file.type.includes("video")) {
      return "/images/icons/video-icon.svg";
    }
    return "/images/icons/pdf-icon.svg";
  };
  return (
    <Drawer
      placement="right"
      open={visible}
      closable={false}
      className="custom-drawer-shared-files"
      width="33.875rem"
      onClose={onCancel}
    >
      <div className="pb-5">
        <button onClick={onCancel}>
          <img
            src="/images/icons/chevron-right-double.svg"
            alt="close"
            className="m-4"
          />
        </button>
        <div className="flex flex-col items-center">
          <img
            src="/images/icons/file-attachment.svg"
            className="w-12 h-12 rounded-full mt-4 "
          />
          <h4 className="text-center capitalize mon-500-18 mt-6 px-4 text-black">
            Shared Files
          </h4>
        </div>
        <p className="mt-7 mon-500-12 text-gray-700 px-4">Within this thread</p>
        {filesNotSaveInVault.map((file) => (
          <div
            className="flex justify-between items-center px-4 py-1 mt-7 hover:bg-main-background"
            key={file.id}
          >
            <div className="flex items-center">
              <img
                src={getFileIcon(file)}
                className="w-12 h-12 rounded-md object-cover"
              />
              <div className="flex flex-col justify-between pl-4">
                <p className="mon-500-12 text-gray-900 max-w-[200px] truncate">
                  {file.name}
                </p>
                <p className="mon-400-10 text-gray-500 pt-1">
                  {bytesToSize(file.size)}
                </p>
              </div>
            </div>
            <div className="flex">
              {/* {file.saveInVault && (
                <img
                  src="/images/icons/bookmark.svg"
                  className="w-5 h-5 mr-3 cursor-pointer"
                />
              )} */}

              <img
                src="/images/icons/bookmark.svg"
                className="w-5 h-5 mr-3 cursor-pointer"
                onClick={() =>
                  dispatch(
                    updateConversations({
                      key: "saveInVaultModal",
                      value: {
                        visible: true,
                        file,
                      },
                    })
                  )
                }
              />

              <MenuDropdown
                elements={[
                  {
                    id: 1,
                    image: "/images/icons/eye.svg",
                    text: "Open",
                    textColor: "text-gray-700",
                    onclick: () => {
                      dispatch(
                        updatePreviewMultiple([
                          {
                            key: "docs",
                            value: [file],
                          },
                          {
                            key: "visible",
                            value: true,
                          },
                          {
                            key: "from",
                            value: "conversation",
                          },
                          {
                            key: "currentIndex",
                            value: 0,
                          },
                        ])
                      );
                      addFilePreview();
                      onCancel();
                    },
                  },
                  {
                    id: 2,
                    image: "/images/icons/open-window.svg",
                    text: "Open in new window",
                    textColor: "text-gray-700",
                    borderBottom: true,
                    onclick: () => window.open(window.location.href, "_blank"),
                  },
                  {
                    id: 3,
                    image: "/images/icons/share-gray.svg",
                    text: "Share in message",
                    textColor: "text-gray-700",
                    onclick: () =>
                      dispatch(
                        updateConversations({
                          key: "shareInMessageModal",
                          value: {
                            type: "file",
                            visible: true,
                            url: "",
                            file: [
                              {
                                ...file,
                              },
                            ],
                          },
                        })
                      ),
                  },
                  {
                    id: 4,
                    image: "/images/icons/bookmark.svg",
                    text: "Save in vault",
                    textColor: "text-gray-700",
                    onclick: () =>
                      dispatch(
                        updateConversations({
                          key: "saveInVaultModal",
                          value: {
                            visible: true,
                            file,
                          },
                        })
                      ),
                  },

                  {
                    id: 5,
                    image: "/images/icons/download-icon.svg",
                    text: "Download",
                    textColor: "text-gray-700",
                    onclick: () => downloadFiles([file.url]),
                  },

                  {
                    id: 7,
                    image: "/images/icons/trash.svg",
                    text: "Delete",
                    textColor: "text-error-600",
                    onclick: () =>
                      dispatch(
                        updateConversationsMultiple([
                          {
                            key: "deleteFileModal",
                            value: true,
                          },
                          {
                            key: "deleteFileInMessage",
                            value: file,
                          },
                        ])
                      ),
                  },
                ]}
                customFunc={undefined}
              />
            </div>
          </div>
        ))}
        <p className="mt-7 mon-500-12 text-gray-700 px-4">Saved in vault</p>
        {filesSaveInVault.map((file) => (
          <div
            className="flex justify-between items-center px-4 py-1 mt-7 hover:bg-main-background"
            key={file.id}
          >
            <div className="flex items-center">
              <img
                src={getFileIcon(file)}
                className="w-12 h-12 rounded-md object-cover"
              />
              <div className="flex flex-col justify-between pl-4">
                <p className="mon-500-12 text-gray-900 max-w-[200px] truncate">
                  {file.name}
                </p>
                <p className="mon-400-10 text-gray-500 pt-1">
                  {bytesToSize(file.size)}
                </p>
              </div>
            </div>
            <div className="flex">
              <img
                src="/images/icons/file-search.svg"
                className="w-5 h-5 mr-3 cursor-pointer"
              />

              <MenuDropdown
                elements={[
                  {
                    id: 1,
                    image: "/images/icons/eye.svg",
                    text: "Open",
                    textColor: "text-gray-700",
                    onclick: () => {
                      dispatch(
                        updatePreviewMultiple([
                          {
                            key: "docs",
                            value: [file],
                          },
                          {
                            key: "visible",
                            value: true,
                          },
                          {
                            key: "from",
                            value: "conversation",
                          },
                          {
                            key: "currentIndex",
                            value: 0,
                          },
                        ])
                      );
                      addFilePreview();
                      onCancel();
                    },
                  },
                  {
                    id: 2,
                    image: "/images/icons/open-window.svg",
                    text: "Open in new window",
                    textColor: "text-gray-700",
                    borderBottom: true,
                    onclick: () => window.open(window.location.href, "_blank"),
                  },
                  {
                    id: 3,
                    image: "/images/icons/share-gray.svg",
                    text: "Share in message",
                    textColor: "text-gray-700",
                    onclick: () =>
                      dispatch(
                        updateConversations({
                          key: "shareInMessageModal",
                          value: {
                            type: "file",
                            visible: true,
                            url: "",
                            file: [
                              {
                                ...file,
                              },
                            ],
                          },
                        })
                      ),
                  },
                  {
                    id: 4,
                    image: "/images/icons/bookmark.svg",
                    text: "Save in vault",
                    textColor: "text-gray-700",
                    onclick: () =>
                      dispatch(
                        updateConversations({
                          key: "saveInVaultModal",
                          value: true,
                        })
                      ),
                  },

                  {
                    id: 5,
                    image: "/images/icons/download-icon.svg",
                    text: "Download",
                    textColor: "text-gray-700",
                    onclick: () => downloadFiles([file.url]),
                  },

                  {
                    id: 7,
                    image: "/images/icons/trash.svg",
                    text: "Delete",
                    textColor: "text-error-600",
                    onclick: () =>
                      dispatch(
                        updateConversationsMultiple([
                          {
                            key: "deleteFileModal",
                            value: true,
                          },
                          {
                            key: "deleteFileInMessage",
                            value: file,
                          },
                        ])
                      ),
                  },
                ]}
                customFunc={undefined}
              />
            </div>
          </div>
        ))}
      </div>
      {viewFileDetails && (
        <ViewFileDetailsDrawer
          visible={viewFileDetails}
          onCancel={() =>
            dispatch(
              updateConversations({
                key: "viewFileDetails",
                value: false,
              })
            )
          }
          details={details}
        />
      )}
      {shareInMessageModal?.visible && (
        <ShareMessageModal
          visible={shareInMessageModal?.visible}
          onClose={() => dispatch(resetConversations(["shareInMessageModal"]))}
        />
      )}
    </Drawer>
  );
};

export default SharedFileseDrawer;
