import React from "react";
import Picker from "emoji-picker-react";

import { EmojiStyle } from "emoji-picker-react";
import { useState } from "react";
import { Dropdown, Tooltip } from "antd";

const CustomEmojiPicker = ({ onChange, className }) => {
  const [emojiPanelOpen, setEmojiPanelOpen] = useState(false);
  const onEmojiClick = (emojiObject) => {
    onChange(emojiObject);
  };

  return (
    // <div
    //   className="relative"
    //   style={{
    //     transition: "all 0.5s ease",
    //     height: "20px",
    //     width: "20px",
    //   }}
    // >
    //   {emojiPanelOpen ? (
    //     <div
    //       className="h-screen w-screen fixed top-0 left-0 right-0 bottom-0 z-10"
    //       onClick={() => setEmojiPanelOpen(false)}
    //     />
    //   ) : (
    //     <></>
    //   )}
    //   <div
    //     style={{
    //       opacity: emojiPanelOpen ? 1 : 0,
    //       pointerEvents: emojiPanelOpen ? "auto" : "none",
    //       backgroundColor: "white",
    //       zIndex: "11",
    //       position: "absolute",
    //       bottom: "35px",
    //       //maxHeight: emojiPanelOpen ? "1000px" : "0px",
    //       borderRadius: emojiPanelOpen ? "0%" : "50%",
    //       overflow: "hidden",
    //       transition: "all 0.5s ease",
    //     }}
    //   >
    //     <Picker
    //       // preload={true}
    //       emojiStyle={EmojiStyle.NATIVE}
    //       lazyLoadEmojis
    //       onEmojiClick={onEmojiClick}
    //     />
    //   </div>
    //   <Tooltip title="Emoji">
    //     <button
    //       onClick={() => setEmojiPanelOpen(!emojiPanelOpen)}
    //       className={className}
    //     >
    //       <svg
    //         width="20"
    //         height="20"
    //         viewBox="0 0 20 20"
    //         fill="none"
    //         xmlns="http://www.w3.org/2000/svg"
    //       >
    //         <path
    //           d="M6.66669 11.668C6.66669 11.668 7.91669 13.3346 10 13.3346C12.0834 13.3346 13.3334 11.668 13.3334 11.668M14.1667 7.7013C13.8375 8.10547 13.3875 8.33463 12.9167 8.33463C12.4459 8.33463 12.0084 8.10547 11.6667 7.7013M8.33335 7.7013C8.00419 8.10547 7.55419 8.33463 7.08335 8.33463C6.61252 8.33463 6.17502 8.10547 5.83335 7.7013M18.3334 10.0013C18.3334 14.6037 14.6024 18.3346 10 18.3346C5.39765 18.3346 1.66669 14.6037 1.66669 10.0013C1.66669 5.39893 5.39765 1.66797 10 1.66797C14.6024 1.66797 18.3334 5.39893 18.3334 10.0013Z"
    //           stroke="#667085"
    //           strokeLinecap="round"
    //           strokeLinejoin="round"
    //         />
    //       </svg>
    //     </button>
    //   </Tooltip>
    // </div>
    <Dropdown
      overlay={
        <Picker
          // preload={true}
          emojiStyle={EmojiStyle.NATIVE}
          lazyLoadEmojis
          onEmojiClick={onEmojiClick}
        />
      }
      trigger={["click"]}
    >
      <Tooltip title="Emoji">
        <button
          onClick={() => setEmojiPanelOpen(!emojiPanelOpen)}
          className={className}
        >
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6.66669 11.668C6.66669 11.668 7.91669 13.3346 10 13.3346C12.0834 13.3346 13.3334 11.668 13.3334 11.668M14.1667 7.7013C13.8375 8.10547 13.3875 8.33463 12.9167 8.33463C12.4459 8.33463 12.0084 8.10547 11.6667 7.7013M8.33335 7.7013C8.00419 8.10547 7.55419 8.33463 7.08335 8.33463C6.61252 8.33463 6.17502 8.10547 5.83335 7.7013M18.3334 10.0013C18.3334 14.6037 14.6024 18.3346 10 18.3346C5.39765 18.3346 1.66669 14.6037 1.66669 10.0013C1.66669 5.39893 5.39765 1.66797 10 1.66797C14.6024 1.66797 18.3334 5.39893 18.3334 10.0013Z"
              stroke="#667085"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
      </Tooltip>
    </Dropdown>
  );
};

export default React.memo(CustomEmojiPicker);
