import React from "react";
import dayjs from "dayjs";
import { hexToRgb } from "../../../Core/utils/colorConverter";

function ProjectStatus(props) {
  return (
    <div
      className="w-full h-20 mb-7 mt-9 rounded-lg"
      style={{ backgroundColor: hexToRgb(props.color, 0.1) }}
      data-ProjectProgress={props.projectProgress}
      data-dateProgress={props.dateProgress}
    >
      <div className="flex items-center text-gray-700 ml-2">
        <div className="w-5 h-5 mt-2 font-semibold">
          <img
            src="/images/icons/bar-line-chart.svg"
            alt="chart"
            className="w-full h-full"
          />
        </div>
        <div className="pl-3.5 pt-3 text-xs font-semibold text-gray-700 ">
          {props.status}
        </div>
      </div>
      <div className="text-xs ml-2 pt-[11px] font-normal mr-6 text-gray-500">
        {props.description}
      </div>
    </div>
  );
}

export default ProjectStatus;

export function getProjectStatus(
  startDate,
  endDate,
  currentDate,
  projectProgress
) {
  // Convert input dates to Day.js objects
  startDate = dayjs(startDate);
  endDate = dayjs(endDate);
  currentDate = dayjs(currentDate);

  if (projectProgress === 100) {
    return {
      text: "Completed",
      color: "#46C66C",
      render: (
        <ProjectStatus
          status="Completed"
          description="Project is completed."
          color="#46C66C"
        />
      ),
    };
  }

  if (!startDate.isValid() || !endDate.isValid() || !currentDate.isValid()) {
    return {
      text: "Invalid Date",
      color: "#d92d20",
      render: (
        <ProjectStatus
          status="Invalid Date"
          description="One or more of the input dates is invalid."
          color="#d92d20"
        />
      ),
    };
  }

  // Check if end date is before start date
  if (endDate.isBefore(startDate)) {
    return {
      text: "Invalid Date Range",
      color: "#d92d20",
      render: (
        <ProjectStatus
          status="Invalid Date Range"
          description="The end date is before the start date."
          color="#d92d20"
        />
      ),
    };
  }

  // Calculate total number of days in the project
  const totalDays = endDate.diff(startDate, "day");

  // Calculate number of days that have passed since the start of the project
  const daysPassed = currentDate.diff(startDate, "day");

  // Calculate percentage of days that have passed compared to total number of days
  const datePercentage = (daysPassed / totalDays) * 100;

  // Determine project status based on date percentage and project progress

  console.log({ datePercentage, projectProgress }, "projectProgress");

  if (currentDate.isAfter(endDate)) {
    return {
      text: "Stuck",
      color: "#d92d20",
      render: (
        <ProjectStatus
          status="Stuck"
          description="Project is stuck and not making progress."
          color="#d92d20"
          projectProgress={projectProgress}
          dateProgress={datePercentage}
        />
      ),
    };
  } else if (
    datePercentage === projectProgress ||
    datePercentage < projectProgress + 5 ||
    projectProgress === 0
  ) {
    return {
      text: "On Track",
      color: "#194185",
      render: (
        <ProjectStatus
          status="On Track"
          description=" Project is on track to be completed as planned."
          color="#194185"
          projectProgress={projectProgress}
          dateProgress={datePercentage}
        />
      ),
    };
  } else if (
    datePercentage + 10 <= projectProgress &&
    datePercentage + 15 >= projectProgress
  ) {
    return {
      text: "Manageable Delay",
      color: "#F79009",
      render: (
        <ProjectStatus
          status="Manageable Delay"
          description="  Project is experiencing a delay that can still be managed."
          color="#F79009"
          projectProgress={projectProgress}
          dateProgress={datePercentage}
        />
      ),
    };
  } else if (
    datePercentage + 16 <= projectProgress &&
    datePercentage + 25 >= projectProgress
  ) {
    return {
      text: "Potential Delay",
      color: "#FB6514",
      render: (
        <ProjectStatus
          status="Potential Delay"
          description="Project is at risk of experiencing a delay."
          color="#FB6514"
          projectProgress={projectProgress}
          dateProgress={datePercentage}
        />
      ),
    };
  } else if (datePercentage < projectProgress + 25) {
    return {
      text: "On Hold",
      color: "#98a2b3",
      render: (
        <ProjectStatus
          status="On Hold"
          description="Project is on hold and not making progress."
          color="#98a2b3"
          projectProgress={projectProgress}
          dateProgress={datePercentage}
        />
      ),
    };
  }
  if (datePercentage > projectProgress) {
    return {
      text: "Stuck",
      color: "#d92d20",
      render: (
        <ProjectStatus
          status="Stuck"
          description="Project is stuck and not making progress."
          color="#d92d20"
          projectProgress={projectProgress}
          dateProgress={datePercentage}
        />
      ),
    };
  } else {
    return {
      text: " Error",
      color: "#d92d20",
      render: (
        <ProjectStatus
          status="Unexpected Error"
          description="  An unexpected error occurred while determining the project status."
          color="#d92d20"
          projectProgress={projectProgress}
          dateProgress={datePercentage}
        />
      ),
    };
  }
}
