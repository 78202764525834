import * as React from "react";
import { useAppSelector } from "../redux/hooks";
import PanViewer from "./PanViewer";

// box-shadow: 0px 0px 5px 1px #0c0c0c;

type ReactPanZoomProps = {
  image: string;
  alt?: string;
  ref?: any;
  currentSlideIndex?: string | number;
};

const ReactPanZoom = ({
  image,
  alt,
  ref,
  currentSlideIndex,
}: ReactPanZoomProps) => {
  const [dx, setDx] = React.useState(0);
  const [dy, setDy] = React.useState(0);
  const [zoom, setZoom] = React.useState(1);
  const [rotation, setRotation] = React.useState(0);
  const [flip, setFlip] = React.useState(false);

  const { docs } = useAppSelector((state) => state.preview);

  const resetAll = () => {
    setDx(0);
    setDy(0);
    setZoom(1);
    setRotation(0);
    setFlip(false);
  };

  const zoomIn = () => {
    if (zoom + 0.2 <= 4) {
      setZoom(zoom + 0.2);
    } else {
      setZoom(4);
    }
  };

  const zoomOut = () => {
    if (zoom - 0.2 >= 1) {
      setZoom(zoom - 0.2);
    } else {
      setZoom(1);
    }
  };

  const rotateLeft = () => {
    if (rotation === -3) {
      setRotation(0);
    } else {
      setRotation(rotation - 1);
    }
  };

  const flipImage = () => {
    setFlip(!flip);
  };

  const onPan = (dx: number, dy: number) => {
    setDx(dx);
    setDy(dy);
  };

  return (
    <div className="flex justify-center items-center flex-col">
      <div
        style={{
          overflow: "hidden",
          cursor: "grab",
          // position: "absolute",
          inset: "0",
          transition: "transform .3s cubic-bezier(.215,.61,.355,1) 0s",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        className="h-[70vh] max-h-[70vh] w-[70vw] max-w-[70vw] "
      >
        <PanViewer
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 1,
          }}
          zoom={zoom}
          setZoom={setZoom}
          pandx={dx}
          pandy={dy}
          onPan={onPan}
          rotation={rotation}
          key={dx}
          enablePan
        >
          <img
            style={{
              transform: `rotate(${rotation * 90}deg) scaleX(${flip ? -1 : 1})`,
              width: "100%",
            }}
            src={image}
            alt={alt}
            ref={ref}
          />
        </PanViewer>
      </div>
      <div className="bg-primary-900 px-2 h-12 mt-14 rounded-xl flex items-center justify-center">
        {docs.length > 1 && (
          <div className="mon-400-12 text-gray-100 pl-4">
            Page {+currentSlideIndex + 1} of {docs.length}
          </div>
        )}
        <img
          src="/images/icons/zoom-out.svg"
          className="w-5 h-5 ml-3 cursor-pointer"
          onClick={zoomOut}
        />
        <img src="/images/icons/line.svg" className="w-5 h-5 ml-2" />
        <img
          src="/images/icons/zoom-in.svg"
          className="w-5 h-5 ml-2 cursor-pointer"
          onClick={zoomIn}
        />
        <div className="mon-400-12 text-gray-100 ml-3 mr-3">
          {Math.round(zoom * 100)}%
        </div>
      </div>
    </div>
  );
};

export { PanViewer };
export default ReactPanZoom;
