import React, { useEffect, useState } from "react";
import { Dropdown, Popover } from "antd";
import { useAppDispatch, useAppSelector } from "../../../Core/redux/hooks";
import CustomAvatar from "../../../Core/Common/CustomAvatar";
import CustomButton from "../../../Core/Common/CustomButton";
import {
  resetConversations,
  updateConversationsMultiple,
} from "../../../Core/redux/slices/conversations.slice";
import { getPrivateMessages } from "../../../Core/redux/api/conversationAPI";
import useTimezone from "../../../Core/hooks/useTimezone";
import dayjs from "dayjs";

const UserDetailsCard = ({ isDisable, userName, userId }) => {
  const { tenantMembers } = useAppSelector((state) => state.tenant);
  const { user } = useAppSelector((state) => state.userDetails);
  const [open, setOpen] = useState(false);
  const getUserDetails = (id) => {
    if (id) {
      return tenantMembers.find((t) => t.id == id);
    }
    return null;
  };
  const dispatch = useAppDispatch();
  const userDetails = getUserDetails(userId);

  //   useEffect(() => {
  //     const handleClick = (e) => {
  //       if (!e.target.closest(".user-drop-down")) {
  //         setOpen(false);
  //       }
  //     };
  //     document.body.addEventListener("click", handleClick);
  //     return () => {
  //       document.body.removeEventListener("click", handleClick);
  //     };
  //   }, [open]);

  const joinPrivateThread = async () => {
    const reciver = userDetails;
    dispatch(
      updateConversationsMultiple([
        {
          value: { ...reciver, type: "private" },
          key: "currentThread",
        },
        { key: "currentThreadMessage", value: [] },
      ])
    );
    dispatch(
      resetConversations(["replyMessage", "editMessage", "newMessageModal"])
    );
    await dispatch(
      getPrivateMessages({
        userId: user.id,
        reciverId: reciver.userId,
        limit: 100,
        offset: 0,
      })
    );
  };

  if (!userDetails) {
    return <></>;
  }
  const timezone = useTimezone(userDetails.lat, userDetails.long);
  return (
    <div onBlur={() => setOpen(false)} className="user-drop-down">
      <Dropdown
        //openClassName="custom-popover-open"
        //   style={{
        //     transition: "all 0.2s ease-in-out",
        //   }}
        trigger={["click", "hover"]}
        overlay={
          <div
            className="w-[370px] rounded-2xl bg-white border  p-4 mx-4"
            style={{
              boxShadow: "2px 3px 6px rgba(16, 24, 40, 0.06)",
            }}
          >
            <div className="flex items-center ">
              <CustomAvatar
                title={userName}
                src={userDetails.profilePicture}
                size={48}
              />
              <div className="ml-3">
                <p className="mon-500-12 text-gray-700">{userName}</p>
                <p className="mon-400-12 text-gray-500">
                  {userDetails.roleid == "1"
                    ? "Owner"
                    : userDetails.roleid == "2"
                    ? "Admin"
                    : userDetails.roleid == "3"
                    ? "Basic User"
                    : "Basic User"}
                </p>
              </div>
            </div>
            {/* <div className="mon-500-10 text-gray-500 mt-4 uppercase">
            Currently located in
          </div>
          <div className="flex justify-between items-center">
            <p></p>
          </div> */}
            <div className="mt-4">
              <p className="uppercase mon-500-10 text-gray-500">
                Currently located in
              </p>
              <div className="flex justify-between">
                <p className="mon-500-14 text-black mt-2">
                  {userDetails.location || "N/A"}
                </p>
                <div className="flex items-center">
                  <img src="/images/icons/clock.svg" className="w-5 h-5 " />
                  <p className="mon-500-12 text-gray-500  ml-2">
                    {timezone.timeZoneId
                      ? dayjs().tz(timezone.timeZoneId).format("hh:mm a")
                      : "N/A"}{" "}
                    Local time
                  </p>
                </div>
              </div>
            </div>
            <div className="mt-4">
              <CustomButton
                text="Message"
                icon="/images/icons/message-text-circle-02.svg"
                className="mon-500-14 text-primary-700 bg-primary-50  shadow-xs "
                height="36px"
                width="100%"
                onClick={joinPrivateThread}
              />
            </div>
          </div>
        }
        //placement={userName === "You" ? "topCenter" : "topRight"}
        placement="topCenter"
        //open={open}
      >
        <div
          className="mon-400-12 text-gray-900 capitalize cursor-pointer"
          onClick={() => {
            if (!isDisable && userDetails) {
              setOpen(!open);
            }
          }}
          onBlur={() => setOpen(false)}
        >
          {userName}
        </div>
      </Dropdown>
    </div>
  );
};

export default UserDetailsCard;
