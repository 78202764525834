import React, { useEffect, useState } from "react";

export function updateEstimate(prevEstimate, newEstimate) {
  // Extract the values of weeks, days, hours, and minutes from the estimate strings using regular expressions
  const prevMatch = prevEstimate.match(/\d+w|\d+d|\d+h|\d+m/g);
  const newMatch = newEstimate.match(/\d+w|\d+d|\d+h|\d+m/g);

  // Convert the values to a total number of minutes
  let prevMinutes = 0;
  let newMinutes = 0;
  if (prevMatch) {
    prevMatch.forEach((val) => {
      const unit = val.slice(-1);
      const amount = parseInt(val.slice(0, -1));
      console.log(unit, amount, "prevmatch");
      switch (unit) {
        case "w":
          prevMinutes += amount * 7 * 8 * 60;
          break;
        case "d":
          prevMinutes += amount * 8 * 60;
          break;
        case "h":
          prevMinutes += amount * 60;
          break;
        case "m":
          prevMinutes += amount;
          break;
      }
    });
  }

  if (newMatch) {
    newMatch.forEach((val) => {
      const unit = val.slice(-1);
      const amount = parseInt(val.slice(0, -1));
      console.log(unit, amount, "newmatch");
      switch (unit) {
        case "w":
          newMinutes += amount * 7 * 8 * 60;
          break;
        case "d":
          newMinutes += amount * 8 * 60;
          break;
        case "h":
          newMinutes += amount * 60;
          break;
        case "m":
          newMinutes += amount;
          break;
      }
    });
  }

  console.log(prevMinutes, newMinutes);
  // Add the new estimate in minutes to the previous estimate's total
  let totalMinutes = prevMinutes + newMinutes;

  // Convert the total number of minutes back to weeks, days, hours, and minutes
  const weeks = Math.floor(totalMinutes / (7 * 8 * 60));
  totalMinutes %= 7 * 8 * 60;
  const days = Math.floor(totalMinutes / (8 * 60));
  totalMinutes %= 8 * 60;
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;

  console.log(weeks, days, hours, minutes, totalMinutes);
  // Format the estimate string in the desired format "0w 0d 00h 00m"
  let updatedEstimate = "";
  if (weeks) updatedEstimate += `${weeks}w `;
  if (days) updatedEstimate += `${days}d `;
  if (hours) updatedEstimate += `${hours}h `;
  if (minutes) updatedEstimate += `${minutes}m `;

  // Return the updated estimate
  return updatedEstimate;
}

const TimeInput = ({
  value = "",
  onChange,
  className,
  onOk,
  addNewTimeToPreviousTime = false,
  addNewTimeToParentGoalId = false,
}) => {
  const [estimate, setEstimate] = useState("");
  const [prevEstimate, setPrevEstimate] = useState(value);
  const [isEditing, setIsEditing] = useState(false);
  const [error, setError] = useState(false);
  console.log(value, "xyyyyy");

  const handleChange = (event) => {
    const value = event.target.value;
    // Only allow values that contain digits and the characters "w", "d", "h", and "m"
    setEstimate(value);
  };

  // useEffect(() => {
  //   if (
  //     /^(?:\s*\d+w)?(?:\s*\d+d)?(?:\s*\d+h)?(?:\s*\d+m)?\s*$/.test(estimate)
  //   ) {
  //     onChange(estimate);
  //   }
  // }, [estimate]);

  if (isEditing) {
    return (
      <>
        <div
          className={`w-[218px] min-w-[216px]  bg-white rounded-xl border-[1px] ${
            error
              ? "border-error-600 shadow-input-error"
              : "border-[#7DC7FF] shadow-input-focus"
          } ${className}`}
        >
          <div className="flex justify-center items-center">
            <input
              className="outline-none bg-transparent w-full h-full px-2 py-2.5"
              value={estimate}
              onChange={handleChange}
              placeholder="0w 0d 00h 00m"
            />

            <div
              className="cursor-pointer "
              onClick={() => {
                if (
                  /^(?:\s*\d+w)?(?:\s*\d+d)?(?:\s*\d+h)?(?:\s*\d+m)?\s*$/.test(
                    estimate
                  )
                ) {
                  if (addNewTimeToPreviousTime) {
                    const newEstimate = updateEstimate(value, estimate);
                    onChange(newEstimate);
                    onOk(newEstimate, estimate);
                  } else {
                    onChange(estimate);
                    onOk(estimate, estimate);
                  }

                  setIsEditing(false);

                  setError(false);
                  setEstimate("");
                  return;
                }

                setError(true);
              }}
            >
              <img
                src="/images/icons/input_save.svg"
                className="h-5 w-5"
                alt=""
              />
            </div>
            <div
              className="cursor-pointer mx-2 ml-1"
              onClick={() => {
                setEstimate("");
                setIsEditing(false);
              }}
            >
              <img
                src="/images/icons/sector-icons/input-cross.svg"
                className="h-5 w-5"
                alt=""
              />
            </div>
          </div>
        </div>
        {error && (
          <div className="mt-1.5 mon-400-14 text-left text-error-500">
            The time should be in 0w 0d 00h 00m
          </div>
        )}
      </>
    );
  }
  return (
    <div className="min-h-[39px] max-h-[39px] items-center flex">
      <div
        className="h-6 bg-gray-50 rounded-2xl px-2 whitespace-nowrap text-gray-700 mon-400-12 py-0.5 cursor-pointer"
        onClick={() => setIsEditing(true)}
      >
        {value || "0w 0d 00h 00m"}
      </div>
    </div>
  );
};

export default TimeInput;
