import { Divider, Dropdown, Menu } from "antd";
import Upload from "antd/lib/upload/Upload";
import React, { useState } from "react";
import CustomButton from "../../../Core/Common/CustomButton";
import CustomOnboardingInput from "../../../Core/Common/CustomOnboardingInput";
import CurrentStep from "./CurrentStep";
import StepHeading from "./StepHeading";
import StepLayout from "./StepLayout";
import uniqid from "uniqid";
import axios from "axios";
import ImgCrop from "antd-img-crop";
// import "antd/es/modal/style";
// import "antd/es/slider/style";
import { HexColorPicker } from "react-colorful";
import { useAppDispatch, useAppSelector } from "../../../Core/redux/hooks";
import {
  changeStep,
  updateProjectOnboarding,
} from "../../../Core/redux/slices/projectOnboarding.slice";
import CustomAvatar from "../../../Core/Common/CustomAvatar";
import { useFormik } from "formik";
import * as Yup from "yup";

const Step1 = () => {
  const { projectName, projectColor, projectImage } = useAppSelector(
    (state) => state.projectOnboarding
  );
  const { user } = useAppSelector((state) => state.userDetails);
  const dispatch = useAppDispatch();

  const [fileList, setFileList] = useState([]);

  const [fileUploadedList, setFileUploadedList] = useState([]);

  const handleChange = ({ fileList: newFileList }) => {
    console.log(newFileList, "newFileList dleetetest");
    setFileList(newFileList);
    const fileData = [];

    console.log(newFileList);

    newFileList.forEach((file) => {
      const { name, size, type, lastModified, status } = file;

      if (status !== "done") return;

      file.response.forEach((xfile) => {
        const { fileUrl: url, thumbnail } = xfile;

        fileData.push({
          name,
          url,
          size,
          type,
          lastModified,
          savedInVault: false,
          thumbnail: thumbnail.fileUrl,
        });
      });
    });
    console.log(fileData, "file");
    setFileUploadedList(fileData);
    dispatch(
      updateProjectOnboarding({
        key: "projectImage",
        value: fileData[0]?.url || "",
      })
    );
  };

  const uploadImage = (options: any) => {
    // below file element is the file you uploaded
    const { onSuccess, onError, file, onProgress } = options;
    const fmData = new FormData();
    const config = {
      headers: {
        "content-type": "multipart/form-data",
        // Authorization: "Bearer " + userLocal.token,
      },
      onUploadProgress: (event) => {
        onProgress({ percent: (event.loaded / event.total) * 100 });
      },
    };
    console.log("FILE: ", file);
    // create copy file and change the name when it is appending to data
    const data = new FormData();
    data.append(
      "file",
      file,
      uniqid(`${file.name.split(".")[0]}-`, `.${file.name.split(".").at(-1)}`)
    ); // replace all the spaces in the file name with -
    const _file = data.get("file");
    fmData.append("image", _file);
    try {
      // then send the copy file to the server
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "/v1/upload/storej",
          fmData,
          config
        )
        .then((res) => {
          console.log("UPLOADED", res.data);
          onSuccess(res.data);
        });
    } catch (err) {
      onError({ err });
    }
  };

  const formik = useFormik({
    initialValues: {
      projectName,
    },
    validationSchema: Yup.object({
      projectName: Yup.string()
        .required("This field is required")
        .typeError("This field is required"),
    }),
    onSubmit: (values) => {
      console.log(values);
      dispatch(changeStep(2));
    },
  });
  return (
    <StepLayout>
      <CurrentStep className={"mt-20"} currentStep={1} totalStep={7} />
      <StepHeading
        title={
          <p className="text-gray-900 mon-500-20">
            What{"'"}s the name of your
            <span className="text-success-600 mon-500-20 italic">
              {" "}
              #SoftwareForGood
            </span>{" "}
            initiative?
          </p>
        }
        subTitle={
          <>
            Hi {user.firstName[0].toUpperCase() + user.firstName.slice(1)},
            let’s create your first project together!
          </>
        }
      />

      <p className="mt-[18px] mon-500-14 text-gray-900">Project Name</p>
      <div className="mt-[18px]">
        <CustomOnboardingInput
          placeholder="Enter Project name"
          name="projectName"
          //value={projectName}
          {...formik.getFieldProps("projectName")}
          onChange={(e, value) => {
            formik.setFieldValue("projectName", value);
            dispatch(
              updateProjectOnboarding({
                key: "projectName",
                value,
              })
            );
          }}
          onClear={() => {
            dispatch(
              updateProjectOnboarding({
                key: "projectName",
                value: "",
              })
            );
            formik.setFieldValue("projectName", "");
          }}
          formikHook={formik}
        />
      </div>

      <p className="mt-[18px] mon-500-14 text-gray-900">Let’s add a Logo too</p>
      <div className="mt-[18px] flex items-center gap-x-6">
        {projectImage && projectImage.length > 0 ? (
          <img
            src={projectImage}
            className="w-16 h-16 rounded-full cursor-pointer object-cover"
            onClick={async () => {
              // const src = projectImage as string;
              // if (!src) return;
              // const image = new Image();
              // image.src = src;
              // const imgWindow = window.open(src);
              // imgWindow?.document.write(image.outerHTML);
              handleChange({ fileList: [] });
            }}
            onMouseEnter={(e) => {
              e.currentTarget.src =
                "/images/icons/project-onboarding/cancelImage.svg";
            }}
            onMouseLeave={(e) => {
              e.currentTarget.src = projectImage;
            }}
          />
        ) : (
          <img src="/images/icons/add-logo.svg" className="w-16 h-16" />
        )}

        <div>
          <Divider type="vertical" className="h-6" />
          <p className="mon-500-12 text-gray-500 text-center">or</p>
          <Divider type="vertical" className="h-6" />
        </div>
        <CustomAvatar
          title={projectName ? projectName : "P"}
          size={64}
          color={projectColor}
          whiteText
          fontSize="24px"
        />
        {/* <img src="/images/icons/logo-w.svg" className="w-16 h-16" /> */}
        <div className="flex items-center cursor-pointer text-gray-900">
          <p className="mon-500-12 text-gray-500">Change colour</p>
          <Dropdown
            getPopupContainer={(trigger) => trigger.parentElement}
            className=""
            overlay={
              <Menu className="rounded-lg custom-menu-dropdown bg-white  shadow-dropdown">
                <HexColorPicker
                  color={projectColor}
                  onChange={(color) =>
                    dispatch(
                      updateProjectOnboarding({
                        key: "projectColor",
                        value: color,
                      })
                    )
                  }
                />
              </Menu>
            }
            trigger={["click"]}
          >
            <img
              src="/images/icons/chevron-down-gray.svg"
              className="w-5 h-5 ml-2"
            />
          </Dropdown>
        </div>
      </div>
      <ImgCrop rotationSlider modalClassName="image-crop-modal ">
        <Upload
          action={process.env.REACT_APP_API_URL + "/v1/upload"}
          // headers={{ Authorization: "Bearer " + user.token }}
          customRequest={uploadImage}
          fileList={fileList}
          onChange={handleChange}
        >
          <div className="flex items-center mt-5 ml-1">
            <img src="/images/icons/upload.svg" alt="" />
            <p className="pl-2 mon-500-12 text-gray-500">Upload</p>
          </div>
        </Upload>
      </ImgCrop>
      <div className="mt-8">
        <CustomButton
          text="Continue"
          width="98px"
          height="40px"
          className={`bg-primary-600 text-white ${
            !formik.isValid ? "opacity-30" : ""
          }`}
          onClick={() => formik.handleSubmit()}
        />
      </div>
    </StepLayout>
  );
};

export default Step1;
