import React from "react";

const CustomCard = ({
  bgColor,
  icon,
  count,
  title,
  textColor,
  titleColor,
  onClick,
}) => {
  return (
    <div
      className={`w-full h-72 rounded-xl cursor-pointer ${
        bgColor === "blue" ? "radial-blue-card" : "radial-grey-card"
      }`}
      onClick={onClick}
    >
      <div className="p-4 flex flex-col h-full justify-between">
        <div className="w-11 h-11">
          <img src={icon} alt={icon} className="object-fit" />
        </div>

        <div>
          <div className={`mon-600-30 ${textColor}`}>{count}</div>
          <div className={`py-4 mon-500-12 uppercase ${titleColor}`}>
            {title}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomCard;
