import { Checkbox, Progress, Menu, Dropdown, Tooltip, Breadcrumb } from "antd";
import React, { useEffect, useState } from "react";
import CustomHorizontalIconGroup from "../../Core/Common/CustomHorizontalIconGroup";
import CustomSearch from "../../Core/Common/CustomSearch";
import CustomLayout from "../../Core/Layout/CustomLayout";
import { useAppDispatch, useAppSelector } from "../../Core/redux/hooks";
import { resetVault, updateVault } from "../../Core/redux/slices/vault.slice";
import FourSquaresIcon from "../../Core/svg/FourSquaresIcon";
import ListIcon from "../../Core/svg/ListIcon";
import TimelineIcon from "../../Core/svg/TimelineIcon";
import AddFileModal from "./AddFileModal";
import AddFolderModal from "./AddFolderModal";
import AddLinkModal from "./AddLinkModal";
import ListViewTable from "./ListViewTable";
import GridView from "./GridView";
import ViewFileDetailsDrawer from "../Conversations/Components/ViewFileDetailsDrawer";
import ShareMessageModal from "../Conversations/Common/ShareMessageModal";
import DeleteFolderModal from "./DeleteFolderModal";
import { resetConversations } from "../../Core/redux/slices/conversations.slice";
import { updateNotification } from "../../Core/redux/slices/notification.slice";
import RequestAccessModal from "./RequestAccessModal";
import RequestUploadModal from "./RequestUploadModal";
import {
  getAllFolders,
  getAllFiles,
  getAllLinks,
} from "../../Core/redux/api/vaultAPI";

import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import DownloadModal from "./DownloadModal";
import TransferOwnershipModal from "./TransferOwnershipModal";
import CopyFolderModal from "./CopyFolderModal";
import MoveToFolderModal from "./MoveToFolderModal";
import OpenLinkModal from "./OpenLinkModal";
import DeleteFileModal from "./DeleteFileModal";
import DeleteLinkModal from "./DeleteLinkModal";
import slugify from "../../Core/utils/slugify";
import ConversationsDrawer from "../Conversations/Components/ConversationsDrawer";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { copyFolderAPI } from "../../Core/redux/api/vaultAPI";
import { manager } from "../../Core/utils/dnd-utils";
import { client } from "../../Core/utils/axiosClient";
import bytesToSize from "../../Core/utils/bytesToSize";

const VaultScreen = ({ socket }) => {
  const dispatch = useAppDispatch();
  const { projectId, "*": splat } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const { vault } = useAppSelector((state) => state.projects);
  const [storageSpace, setStorageSpace] = useState(0);

  const viewAccess = vault?.[1]?.[0] || {};
  const folderFileLink = vault?.[2]?.[0] || {};
  const requestAccess = vault?.[6]?.[0] || {};
  const deleteAll = vault?.[4]?.[0] || {};
  const commentAccess = vault?.[5]?.[0] || {};

  const {
    addFileModal,
    addFolderModal,
    addLinkModal,
    deleteFolderModal,
    deleteFileModal,
    deleteLinkModal,
    requestAccessModal,
    downloadModal,
    copyModal,
    moveToFolderModal,
    requestUploadModal,
    openLinkModal,
    openCommentsModal,
    transferOwnershipModal,
    showDocDetailsDrawer,
  } = useAppSelector((state) => state.vault);
  const { showThreadProfileDrawer, shareInMessageModal } = useAppSelector(
    (state) => state.conversations
  );

  const { phases } = useAppSelector((state) => state.schedule);
  const { folders, files } = useAppSelector((state) => state.vault);

  const getStorageSpace = async () => {
    const result = await client.get(`/repository/get-storage-space`, {
      params: { projectId: projectId },
    });
    setStorageSpace(result.data.result[0].sum);
  };

  useEffect(() => {
    getStorageSpace();
    dispatch(
      updateVault({
        key: "mySpaceFolder",
        value: folders.filter((item) => item.folderName.includes(" Space"))[0],
      })
    );
  }, [projectId]);

  const storagePercent = (storageSpace / 10737418240) * 100;

  const [viewType, setViewType] = useState(1);
  const [showFolder, setShowFolder] = useState(true);
  const [showFile, setShowFile] = useState(true);
  const [showLink, setShowLink] = useState(true);
  const details = {
    phase: "Ideation",
    phaseIcon: "/images/icons/ideation-icon.svg",
    folder: "",
    owner: "Alan Baker",
    ownerProfilePicture: "/images/Avatar.png",
    uploadedOn: "10 Oct 2022, 10:30am",
    size: "24MB",
  };
  const sharedFiles = {
    fileType: "",
    fileName: "",
    size: "",
    saveInVault: false,
  };

  const getFilesFoldersLinks = (callback = null) => {
    const queryObject: { projectId: string; folderId?: string } = { projectId };

    if (splat.length > 0) {
      const LocationArray = location.pathname.split("/");
      const lastLocation = LocationArray[LocationArray.length - 1].split("-");
      const newId = lastLocation[lastLocation.length - 1];
      if (newId) {
        queryObject.folderId = newId;
      }
    }

    dispatch(getAllFolders(queryObject)).then(() => {
      if (typeof callback === "function") callback("folders");
    });
    dispatch(getAllFiles(queryObject)).then(() => {
      if (typeof callback === "function") callback("files");
    });
    dispatch(getAllLinks(queryObject)).then(() => {
      if (typeof callback === "function") callback("links");
    });
  };

  useEffect(() => {
    getFilesFoldersLinks();
  }, [projectId, splat]);

  // useEffect(() => {
  //   const queryObject: { projectId: string; folderId?: string } = { projectId };

  //   dispatch(getAllFolders(queryObject));
  // }, []);

  const { currentPhaseGoals } = useAppSelector((state) => state.schedule);
  const [searchText, setSearchText] = useState("");

  const renderVault = () => {
    switch (viewType) {
      case 1:
        return (
          <DndProvider manager={manager}>
            <GridView
              searchText={searchText}
              showFolder={showFolder}
              showFile={showFile}
              showLink={showLink}
              onRefresh={getFilesFoldersLinks}
            />
          </DndProvider>
        );
      case 2:
        return <ListViewTable searchText={searchText} />;
      default:
        return <></>;
    }
  };

  const pathSnippets = splat.split("/").filter((i) => i);

  const extraBreadcrumbItems = pathSnippets.map((_, index) => {
    const url = `/vault/${projectId}/${pathSnippets
      .slice(0, index + 1)
      .join("/")}`;
    return (
      <Breadcrumb.Item
        key={url}
        className={url === slugify(location.pathname) ? "hfii" : "kihfhu"}
      >
        <Link to={url}>{_.split("-")[0]}</Link>
      </Breadcrumb.Item>
    );
  });

  const breadcrumbItems = [
    <Breadcrumb.Item key="home">
      <Link to={`/vault/${projectId}`}>Vault</Link>
    </Breadcrumb.Item>,
  ].concat(extraBreadcrumbItems);

  const searchTextvalue = (searchText) => {
    const inputData = searchText.toLowerCase();
    setSearchText(inputData);
  };

  const navigate = useNavigate();

  return (
    <CustomLayout page="vault">
      <div className="bg-main-background p-6">
        {/* schedule header section  */}
        <div className="flex justify-between items-center">
          <div className="flex items-center">
            <CustomSearch
              placeholder="Search"
              setSearch={(e) => searchTextvalue(e)}
            />
            <div className="flex justify-center items-center  ml-3.5">
              <img className="w-5 h-5" src="/images/icons/Filters-lines.svg" />
              <p className="mon-500-14 ml-2.5  text-gray-500">Filter</p>
            </div>
          </div>
          <div className="flex items-center justify-center">
            <CustomHorizontalIconGroup
              options={[
                { id: 1, title: "Grid view", icon: <FourSquaresIcon /> },
                { id: 2, title: "List view", icon: <ListIcon /> },
                // { id: 3, title: "Timeline view", icon: <TimelineIcon /> },
              ]}
              color={{ active: "#165ABF", inactive: "#667085" }}
              onChange={(option) => {
                setViewType(option.id);
              }}
            />
            <Tooltip
              title={`${bytesToSize(storageSpace)
                .split(" ")
                .join("")} of 10GB used`}
            >
              <div className="bg-white flex items-center rounded-xl h-11 px-2.5">
                <img
                  className="w-5 h-5"
                  src="/images/icons/download-cloud.svg"
                />
                <p className="text-gray-500 mon-500-14 px-3">Storage</p>
                <div className=" w-[279px]">
                  <Progress
                    percent={storagePercent}
                    status="success"
                    strokeWidth={4}
                    showInfo={false}
                    strokeColor={"#165ABF"}
                  />
                </div>
                <p className="pl-3 mon-500-14 text-primary-600">
                  {Math.round(storagePercent)}%
                </p>
              </div>
            </Tooltip>
          </div>
        </div>
        <div className="flex justify-between items-center pt-4">
          <Breadcrumb>{breadcrumbItems}</Breadcrumb>
          {/* <h3 className="mon-500-12 text-gray-900">Vault</h3> */}
          <div className="flex justify-center items-center ">
            <div className="flex items-center">
              <span className="mon-400-12 text-gray-500">Sort:</span>

              <Checkbox
                className="ml-3"
                defaultChecked
                onChange={() => setShowFolder(!showFolder)}
              />
              <span className="mon-500-12 text-primary-600 ml-1">Folder</span>
              <Checkbox
                className="ml-3"
                defaultChecked
                onChange={() => setShowFile(!showFile)}
              />
              <span className="mon-500-12 text-primary-600 ml-1">File</span>
              <Checkbox
                className="ml-3"
                defaultChecked
                onChange={() => setShowLink(!showLink)}
              />
              <span className="mon-500-12 text-primary-600 ml-1">Link</span>
            </div>
            <Dropdown
              //getPopupContainer={(trigger) => trigger.parentElement}
              className=""
              overlay={
                <Menu className="w-[200px] rounded-lg custom-menu-dropdown  shadow-dropdown">
                  <Menu.Item className=" py-2.5 px-4 rounded-t-lg">
                    <div
                      className="flex items-center"
                      onClick={() =>
                        dispatch(
                          updateVault({
                            key: "requestAccessModal",
                            value: true,
                          })
                        )
                      }
                    >
                      <img
                        src="/images/icons/lock-unlocked-02.svg"
                        className="w-4 h-4"
                        alt="Request access"
                      />
                      <span className="mon-400-12 ml-2 whitespace-nowrap  text-gray-700">
                        Request access
                      </span>
                    </div>
                  </Menu.Item>
                  <Menu.Item className=" py-2.5 px-4 rounded-t-lg border-b-[1px]">
                    <div
                      className="flex items-center"
                      onClick={() =>
                        dispatch(
                          updateVault({
                            key: "requestUploadModal",
                            value: true,
                          })
                        )
                      }
                    >
                      <img
                        src="/images/icons/file-plus-03.svg"
                        className="w-4 h-4"
                        alt="Request upload"
                      />
                      <span className="mon-400-12 ml-2 whitespace-nowrap  text-gray-700">
                        Request upload
                      </span>
                    </div>
                  </Menu.Item>
                  {((typeof requestAccess === "object" &&
                    requestAccess?.read === 1) ||
                    requestAccess?.isAdmin === true) && (
                    <Menu.Item className="py-2.5 px-4 rounded-b-lg">
                      <div
                        className="flex items-center "
                        onClick={() => {
                          navigate("/notifications");
                          dispatch(
                            updateNotification({
                              key: "notificationTab",
                              value: "Request",
                            })
                          );
                        }}
                      >
                        <img
                          src="/images/icons/flip-backward.svg"
                          alt="add-new-sprint"
                          className="w-4 h-4"
                        />
                        <span className="mon-400-12 ml-2 whitespace-nowrap  text-gray-700">
                          Past requests
                        </span>
                      </div>
                    </Menu.Item>
                  )}
                </Menu>
              }
              trigger={["click"]}
            >
              <div
                className={`${"bg-white "} cursor-pointer flex justify-center items-center  mon-500-14 rounded-xl shadow-xs border border-gray-200 ml-3 `}
                style={{ width: "120px", height: "40px" }}
              >
                <img
                  src="/images/icons/chevron-down-black.svg"
                  className="mt-0.5"
                />
                <p className="mon-500-14 text-gray-700 pl-2">Request</p>
              </div>
            </Dropdown>
            {folderFileLink && folderFileLink?.create === 1 && (
              <Dropdown
                //getPopupContainer={(trigger) => trigger.parentElement}
                className=""
                overlay={
                  <Menu className="w-[200px] rounded-lg custom-menu-dropdown  shadow-dropdown">
                    <Menu.Item
                      className=" py-2.5 px-4 rounded-t-lg"
                      onClick={() =>
                        dispatch(
                          updateVault({ key: "addFolderModal", value: true })
                        )
                      }
                    >
                      <div
                        className="flex items-center"
                        // onClick={() => setNewSprintModal(true)}
                      >
                        <img
                          src="/images/icons/folder-plus-01.svg"
                          className="w-4 h-4"
                          alt="add-new-sprint"
                        />
                        <span className="mon-400-12 ml-2 whitespace-nowrap  text-gray-700">
                          New Folder
                        </span>
                      </div>
                    </Menu.Item>
                    <Menu.Item
                      className="py-2.5 px-4 rounded-b-lg"
                      onClick={() =>
                        dispatch(
                          updateVault({ key: "addFileModal", value: true })
                        )
                      }
                    >
                      <div
                        className="flex items-center "
                        // onClick={() => setNewScopeModal(true)}
                      >
                        <img
                          src="/images/icons/file-plus-01.svg"
                          alt="add-new-sprint"
                          className="w-4 h-4"
                        />
                        <span className="mon-400-12 ml-2 whitespace-nowrap  text-gray-700">
                          New File
                        </span>
                      </div>
                    </Menu.Item>
                    <Menu.Item
                      className="py-2.5 px-4 rounded-b-lg"
                      onClick={() =>
                        dispatch(
                          updateVault({ key: "addLinkModal", value: true })
                        )
                      }
                    >
                      <div
                        className="flex items-center "
                        // onClick={() => setNewScopeModal(true)}
                      >
                        <img
                          src="/images/icons/link.svg"
                          alt="add-new-sprint"
                          className="w-4 h-4"
                        />
                        <span className="mon-400-12 ml-2 whitespace-nowrap  text-gray-700">
                          New Link
                        </span>
                      </div>
                    </Menu.Item>
                  </Menu>
                }
                trigger={["click"]}
              >
                <div
                  className={`${"bg-primary-600 text-white"} cursor-pointer flex justify-center items-center  mon-500-14 rounded-xl ml-3 `}
                  style={{ width: "90px", height: "40px" }}
                >
                  <div className="flex justify-center items-center">
                    <img src="/images/icons/plus.svg" className="w-5 h-5" />
                    <p className={"ml-2.5"}>Add</p>
                  </div>
                </div>
              </Dropdown>
            )}
          </div>
        </div>
        {vault === null ? (
          <div className="h-[calc(100vh-200px)] w-full flex justify-center items-center flex-col">
            <img src="/images/noFiles.svg" />
            <p className="mon-500-12 text-gray-500 mt-3">
              You do not have any view permissions
            </p>
          </div>
        ) : (
          renderVault()
        )}
      </div>

      {addFileModal && (
        <AddFileModal
          visible={addFileModal}
          onCancel={() =>
            dispatch(
              resetVault([
                "addFileModal",
                "shareAccessDoc",
                "preFilledVaultModalValues",
              ])
            )
          }
        />
      )}
      {addFolderModal && (
        <AddFolderModal
          visible={addFolderModal}
          onCancel={() =>
            dispatch(
              resetVault([
                "addFolderModal",
                "shareAccessDoc",
                "preFilledVaultModalValues",
              ])
            )
          }
        />
      )}
      {addLinkModal && (
        <AddLinkModal
          visible={addLinkModal}
          onCancel={() =>
            dispatch(
              resetVault([
                "addLinkModal",
                "shareAccessDoc",
                "preFilledVaultModalValues",
              ])
            )
          }
        />
      )}
      {deleteFolderModal.visible && (
        <DeleteFolderModal
          visible={deleteFolderModal.visible}
          id={deleteFolderModal.id}
          onCancel={() =>
            dispatch(updateVault({ key: "deleteFolderModal", value: false }))
          }
        />
      )}
      {deleteFileModal.visible && (
        <DeleteFileModal
          visible={deleteFileModal.visible}
          id={deleteFileModal.id}
          onCancel={() =>
            dispatch(updateVault({ key: "deleteFileModal", value: false }))
          }
        />
      )}

      {transferOwnershipModal.visible && (
        <TransferOwnershipModal
          visible={transferOwnershipModal.visible}
          id={transferOwnershipModal.id}
          docType={transferOwnershipModal.docType}
          // usersWithAccess={transferOwnershipModal.usersWithAccess}
          onCancel={() =>
            dispatch(
              updateVault({ key: "transferOwnershipModal", value: false })
            )
          }
        />
      )}
      {deleteLinkModal.visible && (
        <DeleteLinkModal
          visible={deleteLinkModal.visible}
          id={deleteLinkModal.id}
          onCancel={() =>
            dispatch(updateVault({ key: "deleteLinkModal", value: false }))
          }
        />
      )}
      {requestAccessModal && (
        <RequestAccessModal
          visible={requestAccessModal}
          onCancel={() =>
            dispatch(updateVault({ key: "requestAccessModal", value: false }))
          }
        />
      )}
      {requestUploadModal && (
        <RequestUploadModal
          visible={requestUploadModal}
          onCancel={() =>
            dispatch(updateVault({ key: "requestUploadModal", value: false }))
          }
        />
      )}
      {downloadModal.visible && (
        <DownloadModal
          visible={downloadModal.visible}
          id={downloadModal.id}
          onCancel={() =>
            dispatch(
              updateVault({
                key: "downloadModal",
                value: {
                  visible: false,
                  type: "",
                  id: null,
                },
              })
            )
          }
        />
      )}
      {openCommentsModal?.visible && (
        <ConversationsDrawer
          visible={openCommentsModal?.visible}
          onCancel={() => dispatch(resetVault(["openCommentsModal"]))}
          socket={socket}
        />
      )}
      {copyModal?.visible && (
        <CopyFolderModal
          visible={copyModal?.visible}
          id={copyModal.id}
          docType={copyModal.docType}
          onCancel={() =>
            dispatch(
              updateVault({
                key: "copyModal",
                value: {
                  visible: false,
                },
              })
            )
          }
        />
      )}
      {moveToFolderModal && (
        <MoveToFolderModal
          visible={moveToFolderModal.visible}
          onCancel={() =>
            dispatch(updateVault({ key: "moveToFolderModal", value: false }))
          }
        />
      )}
      {openLinkModal?.visible && (
        <OpenLinkModal
          visible={openLinkModal.visible}
          id={openLinkModal.id}
          onCancel={() =>
            dispatch(updateVault({ key: "openLinkModal", value: false }))
          }
        />
      )}
      {showDocDetailsDrawer?.visible && (
        <ViewFileDetailsDrawer
          visible={showDocDetailsDrawer?.visible}
          onCancel={() => dispatch(resetVault(["showDocDetailsDrawer"]))}
          details={showDocDetailsDrawer}
        />
      )}
      {shareInMessageModal?.visible && (
        <ShareMessageModal
          visible={shareInMessageModal?.visible}
          onClose={() => dispatch(resetConversations(["shareInMessageModal"]))}
        />
      )}
    </CustomLayout>
  );
};

export default VaultScreen;
