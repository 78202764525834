import React from "react";

const CustomFilterButton = () => {
  return (
    <div className="flex justify-center items-center  ml-3.5">
      <img className="w-5 h-5" src="/images/icons/Filters-lines.svg" />
      <p className="mon-500-14 ml-2.5  text-gray-500">Filter</p>
    </div>
  );
};

export default CustomFilterButton;
