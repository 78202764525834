import React, { useEffect, useState } from "react";
import CustomInput from "../../Core/Common/CustomInput";
import EyeIcon from "../../Core/svg/EyeIcon";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useAppDispatch, useAppSelector } from "../../Core/redux/hooks";
import { useNavigate } from "react-router-dom";
import CustomButton from "../../Core/Common/CustomButton";
import { Divider, Spin } from "antd";
import IdentityProviderSlice from "./IdentityProviderSlice";
import { userAuthenticate } from "../../Core/redux/api/userAPI";
import { addActivityLog } from "../../Core/redux/api/activityLogAPI";
import { resetToInitial } from "../../Core/redux/slices/global.slice";
import axios from "axios";
import RightScreen from "./RightScreen";
import { getWorkspaces } from "../../Core/redux/api/dashboardAPI";

// type Props = {};

const NewLoginScreen = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { user, loggedIn } = useAppSelector((state) => state.userDetails);

  const [loading, setLoading] = useState(false);

  const handlePasswordVisible = (toggleState = !formik.values.showPassword) => {
    formik.setFieldValue("showPassword", toggleState);
  };

  useEffect(() => {
    dispatch(resetToInitial({}));
  }, []);

  const formik = useFormik({
    initialValues: {
      email: null,
      password: null,
      showPassword: false,
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Please enter a valid email address")
        .required("Please enter the email address")
        .typeError("Please enter the email address"),
      password: Yup.string()
        .required("Please enter the password")
        .typeError("Please enter the password"),
      showPassword: Yup.bool(),
    }),
    onSubmit: (values) => {
      const { showPassword } = values;
      if (showPassword) handlePasswordVisible(false);
      handleLogin(values);
    },
  });

  const handleWorkSpaces = async () => {
    const workspaces = await dispatch(getWorkspaces());
    //console.log(workspaces, "workspaces check");
    if (workspaces.payload) {
      if (workspaces.payload.length === 1) {
        navigate("/dashboard");
      } else if (workspaces.payload.length === 0) {
        navigate("/onboarding");
      } else {
        navigate("/switch-workspace", { replace: true });
      }
    }
  };

  const handleRouting = (login?: any) => {
    const hostname = window.location.host;
    const subdomain = hostname.match(/^(.*?)\./)?.[1];
    const domain = hostname.match(/\.(.*?)\./)?.[1];
    const tld = hostname.match(/\.(.*)$/)?.[1];

    const userFromLocal = JSON.parse(localStorage.getItem("user"));

    console.log(hostname, subdomain, domain, tld, "hhhh");

    switch (true) {
      case login && login?.payload?.tenant_id === null:
        navigate("/onboarding", { replace: true });

        break;
      case loggedIn:
        if (
          hostname ===
          `${
            userFromLocal?.tenantDetails?.tenantHostname || ""
          }.${domain}.${tld}`
        ) {
          handleWorkSpaces();
          // navigate("/switch-workspace", { replace: true });
        } else {
          /* eslint-disable no-constant-condition */
          if (
            hostname !== "localhost:3000" &&
            process.env.REACT_APP_ENV &&
            process.env.REACT_APP_ENV !== "qa" &&
            false
          ) {
            axios.defaults.withCredentials = true;
            axios.defaults.xsrfCookieName = "jwt";
            axios.defaults.xsrfHeaderName = "jwt";
            axios.defaults.headers.common["jwt"] = user.token;

            window.location.hostname = `${userFromLocal?.tenantDetails?.tenantHostname}.waddle.ai/switch-workspace`;
          } else {
            handleWorkSpaces();
            //navigate("/switch-workspace", { replace: true });
          }
        }
        break;
    }
  };

  useEffect(() => {
    const userFromLocal = localStorage.getItem("user");
    if (
      loggedIn &&
      user.tenant_id &&
      userFromLocal &&
      JSON.parse(userFromLocal)?.id
    ) {
      // navigate("/dashboard", { replace: true });
      handleRouting();
    }
  }, [loggedIn]);

  const handleLogin = async (values) => {
    const { email, password } = values;

    setLoading(true);
    const login = await dispatch(
      userAuthenticate({ email, password, isMobile: false })
    );
    dispatch(
      addActivityLog({
        title: ``,
        activityType: 4,
        meta: JSON.stringify({
          email,
        }),
      })
    );
    setLoading(false);
    console.log(user, login);

    handleRouting(login);
  };

  return (
    <div className="flex w-screen h-screen overflow-hidden">
      <div className="flex-[6] bg-blue-50">
        <RightScreen />
      </div>
      <div className="flex-[4] flex-center h-screen overflow-y-auto">
        {/* <Spin spinning={loading}> */}
        <div className="w-[400px] h-[90vh] flex flex-col justify-start px-4 pt-[10vh]">
          <div className="mon-600-24 text-primary-600 mb-6">Welcome back!</div>
          <div className="flex items-center justify-between">
            <div className="mon-400-14 text-gray-500">
              {"Don't have an account?"}
            </div>
            <div>
              <CustomButton
                text="Sign Up"
                className="text-primary-700 bg-primary-50 mon-500-14"
                height="36px"
                width="110px"
                onClick={() => navigate("/signup")}
              />
            </div>
          </div>

          <form
            autoComplete="off"
            // style={{ width: 520 }}
            onSubmit={formik.handleSubmit}
            id="formik"
            className="mt-6"
          >
            <CustomInput
              label="Email"
              labelDefaultCase
              name={"email"}
              type="email"
              placeholder="Enter your work email"
              {...formik.getFieldProps("email")}
              formikHook={formik}
              className="mt-5.5"
            />

            <CustomInput
              label="Password"
              labelDefaultCase
              name={"password"}
              type={formik.values.showPassword ? "text" : "password"}
              placeholder="Must have atleast 6 characters"
              {...formik.getFieldProps("password")}
              formikHook={formik}
              className="mt-5.5"
              // suffix={
              //   <img
              //     onClick={() => handlePasswordVisible()}
              //     src={`/images/icons/${
              //       formik.values.showPassword ? "eye-on" : "eye-off"
              //     }.svg`}
              //     className="h-4 w-4 cursor-pointer"
              //     alt="Google"
              //   />
              // }
              suffix={
                <EyeIcon
                  color={"#667085"}
                  width={16}
                  show={formik.values.showPassword}
                  onToggle={handlePasswordVisible}
                />
              }
            />

            <div
              className="mon-400-14 mt-5.5 text-primary-700 cursor-pointer"
              onClick={() => navigate("/forgot-password")}
            >
              Forgot Password?
            </div>

            <CustomButton
              text="Sign In"
              type="submit"
              height="36px"
              width="100%"
              className={`text-white ${
                !(formik.isValid && formik.dirty)
                  ? "bg-primary-500"
                  : "bg-primary-600"
              } mon-500-14 mt-12`}
              disabled={!(formik.isValid && formik.dirty)}
            />
          </form>

          <Divider className="pb-2">
            <p className="mont-500-15 text-gray-500">Or continue with</p>
          </Divider>
          <IdentityProviderSlice />
        </div>
        {/* </Spin> */}
      </div>
    </div>
  );
};

export default NewLoginScreen;
