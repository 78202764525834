import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAppSelector } from "../redux/hooks";
import PhaseIcon from "../svg/PhaseIcon";
import { getGoalsFromPhaseId } from "../utils/vaultUtils";
import { CustomSelectWithInlineSearch } from "./CustomSelectWithSearch";

type Props = {
  phaseId: string | number;
  goalId: string | number;
  onLoadingChange?: (isLoading: boolean) => void;
  onChange: ({
    phaseId,
    goalId,
  }: {
    phaseId?: string | number;
    goalId?: string | number;
  }) => void;
  customWidth?: string | number;
  customPhaseTitle?: string;
  customStyleForPhaseTitle?: boolean;
  customGoalTitle?: string;
  customStyleForGoalTitle?: boolean;
  selectedProjectId?: string | number | null;
};

const CustomPhaseGoalsSelector = ({
  phaseId,
  goalId,
  onLoadingChange,
  onChange,
  customWidth,
  customPhaseTitle,
  customStyleForPhaseTitle,
  customGoalTitle,
  customStyleForGoalTitle,
  selectedProjectId,
}: Props) => {
  const { phases, sprints } = useAppSelector((state) => state.schedule);
  const [sprintGoals, setSprintGoals] = useState([]);
  const { projectId } = useParams();

  //   const [state, setState] = useState({
  //     phaseId,
  //     goalId,
  //   });

  const getGoals = async (phaseId, callback = undefined) => {
    if (typeof onLoadingChange === "function") onLoadingChange(true);
    if (selectedProjectId ? selectedProjectId : projectId) {
      const goals = await getGoalsFromPhaseId({
        phaseId: phaseId?.toString(),
        projectId: selectedProjectId ? selectedProjectId : projectId,
      });

      if (typeof onLoadingChange === "function") onLoadingChange(false);

      setSprintGoals(goals);
      if (typeof callback === "function") callback(goals);
    }
  };

  const onPhaseChange = (p) => {
    const phaseId = p.id.toString();
    getGoals(phaseId);
    onChange({ phaseId, goalId: null });
    // setState({
    //   ...state,
    //   phaseId,
    //   //   phaseName: p.phases,
    // });
  };

  useEffect(() => {
    if (phaseId) {
      getGoals(phaseId);
    }
  }, []);

  const PHASE_OPTION_TYPES = [
    {
      id: "1",
      title: "Ongoing",
      filter: (phase) => phase.isOngoing || phase.isCompleted,
    },
    {
      id: "2",
      title: "Yet to begin",
      filter: (phase) => !phase.isOngoing && !phase.isCompleted,
    },
  ];

  return (
    <div>
      <div
        className={`${
          customStyleForPhaseTitle
            ? "mon-500-10 text-gray-500 uppercase mt-12"
            : "mon-500-14 text-gray-700 mt-9"
        }`}
      >
        {customPhaseTitle ? customPhaseTitle : " Phases (optional)"}
        <div className="mt-1.5">
          <CustomSelectWithInlineSearch
            value={phaseId?.toString()}
            containerStyle={{
              width: customWidth ? customWidth : "336px",
            }}
            options={PHASE_OPTION_TYPES.map(({ id, title, filter }) => {
              return {
                id,
                title,
                options: phases.filter(filter).map((p) => {
                  return {
                    id: p.id.toString(),
                    title: p.phases,
                    render: (value) => (
                      <div
                        className="flex items-center -mt-0.5"
                        key={p.id + "phases"}
                        onClick={() => onPhaseChange(p)}
                      >
                        <PhaseIcon
                          width={"16"}
                          height={"16"}
                          phaseId={+p.id}
                          icon
                        />

                        <p className="mon-400-14 pl-2 text-gray-900 capitalize ">
                          {p.phases}
                        </p>
                      </div>
                    ),
                  };
                }),
              };
            })}
            placeholder="Select phase"
          />
        </div>
      </div>
      <div
        className={`${
          customStyleForGoalTitle
            ? "mon-500-10 text-gray-500 uppercase mt-12"
            : "mon-500-14 text-gray-700 mt-9"
        }`}
      >
        {customGoalTitle ? customGoalTitle : " Goals (optional)"}
        <div className="mt-1.5">
          <CustomSelectWithInlineSearch
            value={goalId?.toString()}
            containerStyle={{
              width: customWidth ? customWidth : "336px",
            }}
            options={sprints
              .filter((s) => s.phaseId == phaseId)
              .map((item) => {
                return {
                  id: item.id?.toString(),
                  title: item.name,
                  options: item.goals?.map((g) => {
                    return {
                      id: g.id?.toString(),
                      title: g?.name,
                      value: g.id?.toString(),
                      render: (value) => (
                        <div
                          className="flex items-center -mt-0.5"
                          onClick={() => onChange({ goalId: g.id, phaseId })}
                          key={g.id}
                        >
                          <PhaseIcon
                            phaseId={+g.phaseId}
                            width={"16"}
                            height={"16"}
                            icon
                          />
                          <p className="mon-400-14 pl-2 text-gray-900 capitalize ">
                            {g?.name}
                          </p>
                        </div>
                      ),
                    };
                  }),
                };
              })}
            placeholder="Select goal"
          />
        </div>
      </div>
    </div>
  );
};

export default CustomPhaseGoalsSelector;
