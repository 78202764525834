import axios from "axios";
import React, { useEffect, useState } from "react";

const CustomImageWithFallBack = ({ src, fallBackImage, ...props }) => {
  const [imageSrc, setImageSrc] = useState(src);
  console.log(src, imageSrc, fallBackImage);
  return (
    <img src={imageSrc} {...props} onError={() => setImageSrc(fallBackImage)} />
  );
};

export default CustomImageWithFallBack;
