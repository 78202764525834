import { createSlice } from "@reduxjs/toolkit";
import { getAlertSeverity, getNotificationActions } from "../api/settingsAPI";
import { getProjectUsers } from "../api/projectAPI";
import {
  getAllMembers,
  getAllRoles,
  getMetaCategories,
} from "../api/settingsAPI";

const initialState = {
  loading: false,
  error: {},
  status: "idle",
  loggedIn: false,
  currentTab: 1,
  tabs: [
    {
      id: 1,
      name: "Details",
    },
    {
      id: 2,
      name: "Notifications",
    },
    {
      id: 3,
      name: "Roles",
    },
    {
      id: 4,
      name: "Team Members",
    },
    {
      id: 5,
      name: "Schedule Metadata",
    },
  ],
  productTabs: [
    {
      id: 1,
      name: "My Account",
    },
    {
      id: 2,
      name: "Integrations",
    },
    {
      id: 3,
      name: "Members",
    },
    {
      id: 4,
      name: "Support",
    },
  ],
  currentProductTab: 1,
  memberList: [],
  roleList: [],
  selectedMembers: [],
  categoryList: [],
  selectedRole: [],
  notificationActions: [],
  alertSeverity: [],
  projectUsers: [],
};

export const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    changeTab: (state, action) => {
      state.currentTab = action.payload;
    },
    changeProductTab: (state, action) => {
      state.currentProductTab = action.payload;
    },
    handleSelect: (state, action) => {
      if (state.selectedMembers.includes(action.payload)) {
        state.selectedMembers = state.selectedMembers.filter(
          (i) => i !== action.payload
        );
      } else {
        state.selectedMembers = [...state.selectedMembers, action.payload];
      }
    },
    resetToInitialSetting: (state, action) => {
      Object.keys(initialState).forEach(
        (key) => (state[key] = initialState[key])
      );
    },
    handleRoleSelect: (state, action) => {
      state.selectedRole = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getAllMembers.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllMembers.fulfilled, (state, action) => {
        state.loading = false;
        state.memberList = action.payload;
      })
      .addCase(getAllMembers.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getAllRoles.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllRoles.fulfilled, (state, action) => {
        state.loading = false;
        state.roleList = action.payload;
      })
      .addCase(getAllRoles.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getMetaCategories.pending, (state) => {
        state.loading = true;
      })
      .addCase(getMetaCategories.fulfilled, (state, action) => {
        state.loading = false;
        console.log(action.payload, "task");
        state.categoryList = action.payload;
      })
      .addCase(getMetaCategories.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getNotificationActions.pending, (state) => {
        state.loading = true;
      })
      .addCase(getNotificationActions.fulfilled, (state, action) => {
        state.loading = false;
        console.log(action.payload, "task");
        state.notificationActions = action.payload;
      })
      .addCase(getNotificationActions.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getAlertSeverity.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAlertSeverity.fulfilled, (state, action) => {
        state.loading = false;
        state.alertSeverity = action.payload;
      })
      .addCase(getAlertSeverity.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getProjectUsers.pending, (state) => {
        state.loading = true;
      })
      .addCase(getProjectUsers.fulfilled, (state, action) => {
        state.loading = false;
        state.projectUsers = action.payload.result;
      })
      .addCase(getProjectUsers.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { changeTab, changeProductTab, handleSelect, handleRoleSelect ,resetToInitialSetting } =
  settingsSlice.actions;
export default settingsSlice.reducer;
