import React, { useEffect, useState } from "react";
import CustomModal from "../../../Core/Common/CustomModal";
import CustomButton from "../../../Core/Common/CustomButton";
import CustomInputWithEdit from "../../../Core/Common/CustomInputWithEdit";
import { Dropdown, Input, Menu } from "antd";
import { CustomSelectWithInlineSearch } from "../../../Core/Common/CustomSelectWithSearch";
import CustomSearch, {
  CustomSearchWithDropDown,
} from "../../../Core/Common/CustomSearch";
import CustomAvatar from "../../../Core/Common/CustomAvatar";
import { useAppDispatch, useAppSelector } from "../../../Core/redux/hooks";
import {
  getGoalsByPhase,
  getProjectPhases,
} from "../../../Core/redux/api/scheduleAPI";
import { useParams } from "react-router-dom";
import PhaseIcon from "../../../Core/svg/PhaseIcon";
import { updateConversations } from "../../../Core/redux/slices/conversations.slice";
import {
  createThread,
  getProjectsWithThreads,
  getThreads,
  getThreadsMembers,
  updateThread,
} from "../../../Core/redux/api/conversationAPI";
import CustomPhaseGoalsSelector from "../../../Core/Common/CustomPhaseGoalsSelector";
import CustomEmojiPicker from "../../../Core/Common/CustomEmojiPicker";
import { client } from "../../../Core/utils/axiosClient";

const ThreadModal = ({ visible, onCancel }) => {
  const {
    editThreadDetails,
    editThread,
    threadMembers,
    directMessageUsers,
    suggestedThread,
    prefillUserInAddThread,
  } = useAppSelector((state) => state.conversations);

  const { projectList } = useAppSelector((state) => state.projects);
  const { user } = useAppSelector((state) => state.userDetails);
  console.log(projectList, "list of projects");

  const { currentPhaseGoals } = useAppSelector((state) => state.schedule);
  const [threadName, setThreadName] = useState(editThreadDetails.threadName);
  const [description, setDescription] = useState(editThreadDetails.description);
  const [selectedPhase, setSelectedPhase] = useState<null | string | number>(
    editThreadDetails.phaseId ? editThreadDetails.phaseId : null
  );
  const [selectedProject, setSelectedProject] = useState(null);
  const [selectedGoals, setSelectedGoals] = useState(null);
  const [selectedUsers, setSelectedUsers] = useState(
    editThread
      ? [
          ...threadMembers
            .map((m) => m.userId || m.id)
            .filter((u) => +u !== +user.id),
        ]
      : []
  );
  const { projectId } = useParams();
  const { phases } = useAppSelector((state) => state.schedule);
  // const { directMessageUsers } = useAppSelector((state) => state.overview);

  //const { selectedUsers } = useAppSelector((state) => state.conversations);
  const dispatch = useAppDispatch();
  const [isHovering, setIsHovering] = useState(false);
  const handleMouseOver = () => {
    setIsHovering(true);
  };

  const handleMouseOut = () => {
    setIsHovering(false);
  };

  useEffect(() => {
    if (selectedProject) {
      const getPhase = async () => {
        await dispatch(getProjectPhases({ projectId: selectedProject }));
      };
      const getProjectMembers = async () => {
        try {
          const { data } = await client.get("/project/project-users", {
            params: { projectId: selectedProject },
          });
          setSelectedUsers(
            data.result
              .map((m) => m.userId || m.id)
              .filter((u) => +u !== +user.id)
          );
        } catch (e) {
          setSelectedUsers([]);
        }
      };
      if (prefillUserInAddThread) {
        getProjectMembers();
      }

      getPhase();
    }
  }, [selectedProject]);

  useEffect(() => {
    if (selectedPhase !== null && selectedProject !== null) {
      dispatch(
        getGoalsByPhase({
          projectId: selectedProject,
          phaseId: selectedPhase.toString(),
        })
      );
    }
  }, [selectedPhase]);

  // useEffect(()=>{
  //   const users = [...selectedUsers]

  // } , editThreadDetails)

  const getThreadsMembersAsync = async () => {
    if (editThreadDetails.threadId)
      await dispatch(
        getThreadsMembers({ threadId: editThreadDetails.threadId })
      );
  };

  useEffect(() => {
    if (editThread && !suggestedThread) {
      getThreadsMembersAsync();
      // setSelectedUsers(threadMembers.map((m) => m.userId));
    }
  }, [editThread, editThreadDetails]);

  useEffect(() => {
    if (editThread) {
      const newThreadMembers = threadMembers
        .map((m) => m.userId || m.id)
        .filter((u) => +u !== +user.id);

      if (editThreadDetails.userToBeAdded) {
        newThreadMembers.push(editThreadDetails.userToBeAdded);
      }
      setSelectedUsers(newThreadMembers);
    }
  }, [threadMembers]);

  return (
    <CustomModal
      title={
        <div className="flex gap-x-1">
          <span className="mon-400-12 text-gray-500">Conversations</span>
          <img className="w-4 h-4" src="/images/icons/chevron-right.svg" />
          <span className="mon-500-12 text-gray-700">Thread</span>
        </div>
      }
      width={"704px"}
      visible={visible}
      body={
        <div className="max-h-[48.189386056191466vh] overflow-y-scroll">
          <div className="p-5">
            <div className="flex justify-center items-center ">
              <CustomInputWithEdit
                label="Name of thread"
                placeholder="Thread Name"
                onChange={(e) => setThreadName(e.target.value)}
                value={threadName}
                width={"100%"}
              />
              <div className="w-10 h-10 ml-2  flex justify-center items-center mt-7">
                <CustomEmojiPicker
                  className=""
                  onChange={(emojiObject) =>
                    setThreadName(`${threadName}${emojiObject.emoji}`)
                  }
                />
              </div>
            </div>

            <div className="mon-500-14 text-gray-700 mt-9">
              Description
              <div className="mt-1.5">
                <Input.TextArea
                  rows={5}
                  placeholder="Enter a description..."
                  required={true}
                  className="w-full h-full bg-white rounded-xl border border-gray-300 text-gray-500 pl-4 pt-2  active:outline-none"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                ></Input.TextArea>
              </div>
            </div>
            <div className="mon-500-14 text-gray-700 mt-9">
              Project (optional)
              <div className="mt-1.5">
                <CustomSelectWithInlineSearch
                  value={selectedProject}
                  containerStyle={{
                    width: "336px",
                  }}
                  onChange={(e) => setSelectedProject(e)}
                  group={false}
                  options={
                    projectList?.length > 0
                      ? projectList.map((item, i) => {
                          return {
                            id: item.id?.toString() + i,
                            title: item.title,
                            value: item.id,
                            render: () => (
                              <p className="mon-400-14 pl-2 text-gray-900 capitalize ">
                                {item.title}
                              </p>
                            ),
                          };
                        })
                      : []
                  }
                  placeholder="Select project"
                />
              </div>
            </div>

            <CustomPhaseGoalsSelector
              phaseId={selectedPhase}
              goalId={selectedGoals}
              selectedProjectId={selectedProject}
              // onLoadingChange={(isLoading) => setShowSpinner(isLoading)}
              onChange={(phaseGoalObj) => {
                setSelectedGoals(phaseGoalObj.goalId);
                setSelectedPhase(phaseGoalObj.phaseId);
              }}
            />
            <div className="mon-500-14 text-gray-700 mt-9">
              <div className="flex items-center">
                <img src="/images/icons/users-03.svg" />
                <p className="mon-500-14 text-gray-700 ml-2">Add members</p>
              </div>
              <p className="mt-3 mon-400-14 text-gray-400">
                Add your team members and start a thread for easy collaboration.
              </p>
              <div className="mt-9">
                <CustomSearchWithDropDown
                  style={{
                    border: "1px solid #C0CBD7",
                    height: "42px",
                  }}
                  placeholder="Search team members"
                  options={directMessageUsers
                    .filter(
                      (u) =>
                        selectedUsers?.indexOf(u.userId) === -1 &&
                        +u.userId !== +user.id
                    )
                    .map((u) => {
                      return {
                        id: u.userId,
                        value: u.userId,
                        label: `${u.firstname} ${u.lastname}`,
                        render: (setSearch) => (
                          <div
                            className="flex items-center -mt-0.5"
                            //key={u.userId}
                            onClick={() => {
                              setSelectedUsers((oldList) => [
                                ...oldList,
                                u.userId,
                              ]);
                              setSearch("");
                            }}
                          >
                            <CustomAvatar
                              title={u.firstname ? u.firstname : "User"}
                              src={u.profilePicture}
                            />

                            <p className="ml-2 mon-400-12 text-gray-700 capitalize">
                              {u.firstname} {u.lastname}
                            </p>
                          </div>
                        ),
                      };
                    })}
                />
              </div>
            </div>
            <div className="mt-9 px-3">
              <div
                className="flex justify-between items-center py-2.5"
                key={user.id}
              >
                <div className="flex items-center">
                  <CustomAvatar
                    title={`${user.firstName}`}
                    size={24}
                    src={user.profilePicture}
                  />
                  <p className="ml-2 mon-400-12 text-gray-700 capitalize">
                    {user.firstName} {user.lastName} (Creator)
                  </p>
                </div>
                <div className="flex justify-center items-center">
                  {/* <p className="mon-400-12 text-gray-500 mr-4">{user.roleId}</p> */}
                  {/* <Dropdown
                          overlay={
                            <Menu className="w-[200px] rounded-lg custom-menu-dropdown  shadow-dropdown">
                              <Menu.Item
                                className=" py-2.5 px-4 rounded-lg"
                                onClick={() =>
                                  setSelectedUsers((oldList) => [
                                    ...oldList.filter(
                                      (old) => old !== user.userId
                                    ),
                                  ])
                                }
                                onMouseOver={handleMouseOver}
                                onMouseOut={handleMouseOut}
                              >
                                <span
                                  className={`font-normal mb-2  whitespace-nowrap text-sm ${
                                    isHovering
                                      ? "text-gray-900"
                                      : "text-error-600"
                                  }`}
                                >
                                  Remove from thread
                                </span>
                              </Menu.Item>
                            </Menu>
                          }
                          trigger={["click"]}
                        >
                          <button>
                            <img
                              src="/images/icons/chevron-down-gray.svg"
                              className="w-5 h-5"
                            />
                          </button>
                        </Dropdown> */}
                </div>
              </div>
              {directMessageUsers.map((user) => {
                if (selectedUsers?.indexOf(user.userId) !== -1) {
                  return (
                    <div
                      className="flex justify-between items-center py-2.5"
                      key={user.userId}
                    >
                      <div className="flex items-center">
                        <CustomAvatar
                          title={`${user.firstname}`}
                          size={24}
                          src={user.profilePicture}
                        />
                        <p className="ml-2 mon-400-12 text-gray-700 capitalize">
                          {user.firstname} {user.lastname}
                        </p>
                      </div>
                      <div className="flex justify-center items-center">
                        <p className="mon-400-12 text-gray-500 mr-4">
                          {user.roleName}
                        </p>
                        <Dropdown
                          overlay={
                            <Menu className="w-[200px] rounded-lg custom-menu-dropdown  shadow-dropdown">
                              <Menu.Item
                                className=" py-2.5 px-4 rounded-lg"
                                onClick={() =>
                                  setSelectedUsers((oldList) => [
                                    ...oldList.filter(
                                      (old) => +old !== +user.userId
                                    ),
                                  ])
                                }
                                onMouseOver={handleMouseOver}
                                onMouseOut={handleMouseOut}
                              >
                                <span
                                  className={`font-normal mb-2  whitespace-nowrap text-sm ${
                                    isHovering
                                      ? "text-gray-900"
                                      : "text-error-600"
                                  }`}
                                >
                                  Remove from thread
                                </span>
                              </Menu.Item>
                            </Menu>
                          }
                          trigger={["click"]}
                        >
                          <button>
                            <img
                              src="/images/icons/chevron-down-gray.svg"
                              className="w-5 h-5"
                            />
                          </button>
                        </Dropdown>
                      </div>
                    </div>
                  );
                }
              })}
            </div>
          </div>
        </div>
      }
      footer={
        <div className="flex justify-end py-4 gap-x-3 px-3 shadow-upward">
          <CustomButton
            text="Cancel"
            onClick={onCancel}
            className="border border-gray-200 text-gray-700 bg-white mon-500-14"
            height="40px"
            width="81px"
          />
          <CustomButton
            text={editThread ? "Save" : "Create"}
            onClick={async () => {
              if (editThread && !suggestedThread) {
                const updateObject: {
                  projectId?: string;
                  name: string;
                  description?: string;
                  users?: number[] | string[];
                  phaseId?: string;
                  threadId: string;
                } = {
                  threadId: editThreadDetails.threadId,
                  name: editThreadDetails.threadName,
                };
                if (threadName !== editThreadDetails.threadName)
                  updateObject.name = threadName;
                if (description !== editThreadDetails.description)
                  updateObject.description = description;
                if (selectedPhase !== editThreadDetails.phaseId)
                  updateObject.phaseId = selectedPhase.toString();

                if (selectedUsers !== threadMembers.map((t) => t.threadId))
                  updateObject.users = [...selectedUsers];
                await dispatch(updateThread(updateObject));
              } else {
                await dispatch(
                  createThread({
                    name: threadName,
                    users: [...selectedUsers],
                    description,
                    projectId: selectedProject,
                    phaseId: selectedPhase ? selectedPhase.toString() : null,
                    goalId: selectedGoals,
                  })
                );
              }
              onCancel();
              dispatch(getThreads({ userId: user.id }));
              dispatch(getProjectsWithThreads({ userId: user.id }));
            }}
            disabled={selectedUsers.length === 0}
            className="mon-500-14 text-white bg-primary-600 "
            height="40px"
            width="79px"
          />
        </div>
      }
      onCancel={onCancel}
    />
  );
};

export default ThreadModal;
