import React from "react";
import CustomModal from "./CustomModal";
import CustomButton from "./CustomButton";

type RemoveMemberModalProps = {
  visible: boolean;
  onCancel: () => void;
};

const RemoveMemberModal = ({ visible, onCancel }: RemoveMemberModalProps) => {
  return (
    <CustomModal
      title={<p className="mon-500-16 text-error-600">Remove member</p>}
      width={"524px"}
      visible={visible}
      body={
        <div className="max-h-[38.189386056191466vh] overflow-y-scroll">
          <div className="flex items-center px-5 py-5">
            <img
              src="/images/icons/frosty-help.svg"
              className="h-9 w-9"
              alt="frosty"
            />
            <p className="text-sm font-medium text-primary-600 ml-2">
              Looks like this user is not added to any other project in Waddle.
            </p>
          </div>
          <div className="mon-400-14 text-gray-500 pt-6 pb-4 px-5">
            Are you sure you want to remove this user from this project? The
            user will still remain in the system without any project access
          </div>
        </div>
      }
      footer={
        <div className="flex justify-between pt-8 pb-4 px-3">
          <CustomButton
            text="Cancel"
            onClick={onCancel}
            className="border-2 border-gray-200 text-black bg-white mon-500-16 "
            height="44px"
            width="49%"
          />
          <CustomButton
            text="Yes, Remove"
            onClick={onCancel}
            className="mon-500-16 text-white  bg-error-600 "
            height="44px"
            width="49%"
          />
        </div>
      }
      onCancel={onCancel}
    />
  );
};

export default RemoveMemberModal;
