import React, { useEffect, useState } from "react";
import CustomInput from "../../Core/Common/CustomInput";
import EyeIcon from "../../Core/svg/EyeIcon";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useAppDispatch } from "../../Core/redux/hooks";
import { useNavigate, useSearchParams } from "react-router-dom";
import CustomButton from "../../Core/Common/CustomButton";
import { Divider, Spin } from "antd";
import IdentityProviderSlice from "./IdentityProviderSlice";
import CustomPopover from "../../Core/Common/CustomPopover";
import CustomOTPInput from "../../Core/Common/CustomOTPInput";
import { client } from "../../Core/utils/axiosClient";

import { addActivityLog } from "../../Core/redux/api/activityLogAPI";
import axios from "axios";
import RightScreen from "./RightScreen";

// type Props = {};

const SignUpScreen = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const token = searchParams.get("token");

  const [passwordFocused, setPasswordFocused] = useState(false);
  const [prevEmail, setPrevEmail] = useState("");
  const [step, setStep] = useState(1);
  const [createdUser, setCreatedUser] = useState(null);

  const [otpInput, setOtpInput] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      firstName: null,
      lastName: null,
      email: null,
      password: null,
      showPassword: false,
    },
    validationSchema: Yup.object({
      firstName: Yup.string()
        .required("Please enter your first name")
        .typeError("Please enter your first name")
        .matches(/^[A-zÀ-ú\s]+$/, "Only alphabets are allowed for this field "),
      lastName: Yup.string()
        .required("Please enter your last name")
        .typeError("Please enter your last name")
        .matches(/^[A-zÀ-ú\s]+$/, "Only alphabets are allowed for this field "),
      email: Yup.string()
        .email("Please enter a valid email address")
        .required("Please enter the email address")
        .typeError("Please enter the email address"),
      password: Yup.string()
        .required("Please enter the password")
        .typeError("Please enter the password")
        .min(8, "Password must be more than 8 characters")
        .test(
          "passwordRequirements",
          "Password does not meet requirements",
          (val) =>
            new RegExp(
              "^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[-+_!@#$%^&*.,?]).+$"
            ).test(val)
        ),
      showPassword: Yup.bool(),
    }),
    onSubmit: async (values) => {
      const { showPassword, email, firstName, lastName, password } = values;
      if (token) {
        // invitee flow
        if (showPassword) handlePasswordVisible(false);
        setStep(2);
      } else {
        //super admin signup flow
        setIsLoading(true);
        try {
          if (prevEmail === email) {
            setStep(2);
            return;
          }
          const emailExists = await client.get("auth/check-if-email-exists", {
            params: {
              email,
            },
          });
          if (emailExists.data.exists) {
            formik.setFieldError("email", "Email already exists in Waddle.");
            return;
          } else {
            const response = await client.post("auth/register", {
              email,
              isMobile: false,
              firstname: firstName,
              lastname: lastName,
              password,
              confirmPassword: password,
            });

            if (response.status === 200) {
              setStep(2);
              setCreatedUser(response.data[0]);
            }
            //console.log(response);
          }
        } finally {
          setIsLoading(false);
        }
      }
    },
  });

  useEffect(() => {
    if (searchParams.has("token")) {
      const decoded = async () => {
        try {
          const { data } = await client.get("/auth/decode-token", {
            params: { token },
          });
          if (data.decode && data.decode.email) {
            formik.setFieldValue("email", data.decode.email);
          }
        } catch (e) {
          console.log(e);
        }
      };
      decoded();
    }
  }, [searchParams]);

  const formikOTP = useFormik({
    initialValues: {
      otpInput: null,
    },
    validationSchema: Yup.object({
      otpInput: Yup.string()
        .required("Please enter the OTP")
        .typeError("Please enter the OTP")
        .matches(/^[0-9]+$/, "Must be only digits")
        .min(6, "Must be exactly 6 digits")
        .max(6, "Must be exactly 6 digits"),
    }),
    onSubmit: async (values) => {
      const { otpInput } = values;
      console.log(token, formik.values, otpInput);
      const { showPassword, ...user } = formik.values;
      const path = token ? "/auth/verify-otp" : "/auth/verify-otp-super-admin";
      try {
        const data = await client.post(path, {
          token,
          otp: otpInput,
          user,
        });
        setStep(3);
      } catch (err) {
        formikOTP.setFieldError("otpInput", "Invalid Otp");
      }

      // .then((res) => {

      //   console.log(res);
      //
      // })
      // .catch((e) => {
      //
      // });
    },
  });

  const handlePasswordVisible = (toggleState) => {
    formik.setFieldValue("showPassword", toggleState);
  };

  const handleLogin = async (values) => {
    const { email, password } = values;
    console.log(values);

    // await dispatch(userAuthenticate({ email, password, isMobile: false }));

    // if (loggedIn) {
    //   navigate("/dashboard", { replace: true });
    // }
  };

  const renderBody = () => {
    switch (step) {
      case 1:
        return (
          <>
            <div className="mon-600-24 text-primary-600 mb-[22px]">
              Create your account
            </div>
            <div className="flex items-center justify-between">
              <div className="mon-400-14 text-gray-500">
                Already have an account?
              </div>
              <div>
                <CustomButton
                  text="Sign in"
                  className="text-primary-700 bg-primary-50 mon-500-14"
                  height="36px"
                  width="110px"
                  onClick={() => navigate("/")}
                />
              </div>
            </div>

            <form
              autoComplete="off"
              // style={{ width: 520 }}
              onSubmit={formik.handleSubmit}
              id="formik"
              className=""
            >
              <CustomInput
                label="First name"
                labelDefaultCase
                name={"firstName"}
                placeholder="Enter first name"
                {...formik.getFieldProps("firstName")}
                formikHook={formik}
                className="mt-5.5"
              />

              <CustomInput
                label="Last name"
                labelDefaultCase
                name={"lastName"}
                placeholder="Enter last name"
                {...formik.getFieldProps("lastName")}
                formikHook={formik}
                className="mt-5.5"
              />

              <CustomInput
                label="Email"
                labelDefaultCase
                name={"email"}
                type="email"
                placeholder="Enter email address"
                {...formik.getFieldProps("email")}
                formikHook={formik}
                className="mt-5.5"
              />

              {/* <CustomInput
              label="Password"
              labelDefaultCase
              name={"password"}
              type={formik.values.showPassword ? "text" : "password"}
              placeholder="Enter password"
              {...formik.getFieldProps("password")}
              formikHook={formik}
              className="mt-5.5"
              suffix={
                <EyeIcon
                  color={"#667085"}
                  width={16}
                  show={formik.values.showPassword}
                  onToggle={handlePasswordVisible}
                />
              }
            /> */}

              <CustomPopover
                title={"Password Requirements"}
                content={
                  <>
                    <div className="text-grey-600">
                      {/* <div>Contains at least 3 of the following 4 types of characters:</div> */}
                      <div>1. At least 8 characters in length</div>
                      <div>2. Lowercase Letters (a-z)</div>
                      <div>3. Uppercase letters (A-Z)</div>
                      <div>4. Numbers (1-9)</div>
                      <div>5. Special characters (!@#$%^&*)</div>
                    </div>
                  </>
                }
                visible={passwordFocused}
                placement={"left"}
              >
                <CustomInput
                  onFocusChange={(isFocused, el = null) => {
                    const elName =
                      el?.nativeEvent?.explicitOriginalTarget?.getAttribute(
                        "name"
                      );
                    if (elName === "show-password" && !isFocused) return;
                    setPasswordFocused(isFocused);
                  }}
                  label="Password"
                  labelDefaultCase
                  name={"password"}
                  type={formik.values.showPassword ? "text" : "password"}
                  placeholder="Enter password"
                  {...formik.getFieldProps("password")}
                  formikHook={formik}
                  className="mt-5.5"
                  suffix={
                    <EyeIcon
                      color={"#667085"}
                      width={16}
                      show={formik.values.showPassword}
                      onToggle={handlePasswordVisible}
                    />
                  }
                />
              </CustomPopover>

              <CustomButton
                text="Sign Up"
                type="submit"
                height="36px"
                width="100%"
                className={`text-white ${
                  !(formik.isValid && formik.dirty)
                    ? "bg-primary-100"
                    : "bg-primary-600"
                } mon-500-14 mt-12`}
                disabled={!(formik.isValid && formik.dirty)}
                // onClick={() => dispatch(resetConversations(["editMessage"]))}
              />
            </form>

            <Divider className="pb-2">
              <p className="mont-500-15 text-gray-500">Or continue with</p>
            </Divider>
            <IdentityProviderSlice />
          </>
        );
      case 2:
        return (
          <>
            <div className="mon-600-24 text-primary-600 mb-6">
              Create your account
            </div>
            <div className="mon-400-14 text-gray-500">
              Enter the 6 character code sent on{" "}
              <span className="mon-500-14 text-gray-900">
                {formik.values.email}
              </span>{" "}
              to verify your email.
            </div>

            <form
              autoComplete="off"
              // style={{ width: 520 }}
              onSubmit={formikOTP.handleSubmit}
              id="formikOTP"
              className="mt-6"
            >
              <CustomOTPInput
                name="otpInput"
                {...formikOTP.getFieldProps("otpInput")}
                value={formikOTP.values.otpInput}
                onChange={(value) => {
                  formikOTP.setFieldValue("otpInput", value);
                }}
                className="mt-5.5"
                formikHook={formikOTP}
              />

              <div
                className="mon-500-14 text-primary-600 mt-5 cursor-pointer"
                onClick={async () => {
                  setIsLoading(true);
                  await client.post("/auth/resend-otp", {
                    userId: createdUser?.id,
                    email: createdUser?.email,
                    token,
                  });
                  setIsLoading(false);
                }}
              >
                Resend code
              </div>

              <CustomButton
                text="Sign Up"
                type="submit"
                height="36px"
                width="100%"
                className={`text-white ${
                  !(formikOTP.isValid && formikOTP.dirty)
                    ? "bg-primary-100"
                    : "bg-primary-600"
                } mon-500-14 mt-12`}
                // disabled={!(formik.isValid && formik.dirty)}
                // onClick={() => dispatch(resetConversations(["editMessage"]))}
              />
              <div
                className="mon-500-14 text-primary-700 mt-6 cursor-pointer"
                onClick={() => {
                  setPrevEmail(formik.values.email);
                  setStep(1);
                }}
              >
                Change Email ID
              </div>
            </form>
          </>
        );
      case 3:
        return (
          <>
            <div className="mon-600-24 text-primary-600 mb-6">Awesome!</div>
            <div className="mon-400-14 text-gray-500 flex">
              Verified as{" "}
              <span className="mon-500-14 text-gray-900 ml-1">
                {" "}
                {formik.values.email}
              </span>{" "}
              <span className="ml-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="#F9FBFE"
                    d="M22 11.086v.92a10 10 0 11-5.93-9.14"
                  ></path>
                  <path fill="#F9FBFE" d="M22 4L12 14.01l-3-3"></path>
                  <path
                    stroke="#DDE5ED"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M22 11.086v.92a10 10 0 11-5.93-9.14M22 4L12 14.01l-3-3"
                  ></path>
                </svg>
              </span>
            </div>

            <div className="mon-500-14 text-gray-900 mt-10">
              We can’t wait for you to get started with Waddle 🐧
            </div>
            <div className="mon-400-12 text-gray-500 mt-3">
              We’ll start by answering some questions, so we can curate your
              experience better.
            </div>
            <CustomButton
              text="Lets Go!"
              type="submit"
              height="36px"
              width="100%"
              className={`text-white 
                   bg-primary-600
               mon-500-14 mt-5`}
              // disabled={!(formik.isValid && formik.dirty)}
              onClick={() => navigate("/", { replace: true })}
            />
          </>
        );
      default:
        return <></>;
    }
  };

  return (
    <div className="flex w-screen h-screen">
      <div className="flex-[6] bg-blue-50">
        <RightScreen />
      </div>
      <div className="flex-[4] flex-center h-screen overflow-y-auto">
        <div className="w-[400px] h-[90vh] flex flex-col justify-start px-4 pt-[10vh]">
          {renderBody()}
        </div>
      </div>
    </div>
  );
};

export default SignUpScreen;
