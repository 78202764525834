import React from "react";
import { Drawer } from "antd";
import CopyButton from "../../../Core/Common/CopyButton";
import CustomBadge from "../../../Core/Common/CustomBadge";
import CustomAvatar from "../../../Core/Common/CustomAvatar";
import dayjs from "dayjs";
import useTimezone from "../../../Core/hooks/useTimezone";

type DirectMessageProfileDrawerProps = {
  visible: boolean;
  onClose: () => void;
  user: {
    firstname?: string;
    lastname?: string;
    email?: string;
    mobile?: string;
    roleName?: string;
    profilePicture?: string;
    company?: string;
    createdAt?: string;
    jobTitle?: string;
    roleid?: string;
    location?: string;
    lat?: number;
    long?: number;
  };
};

const DirectMessageProfileDrawer = ({
  visible,
  onClose,
  user,
}: DirectMessageProfileDrawerProps) => {
  const timezone = useTimezone(user.lat, user.long);
  console.log(user);
  return (
    <Drawer
      placement="right"
      open={visible}
      closable={false}
      className="custom-drawer"
      width="33.875rem"
      onClose={onClose}
    >
      <div>
        <button onClick={onClose}>
          <img src="/images/icons/chevron-right-double.svg" alt="close" />
        </button>
        <div className="flex flex-col items-center">
          <CustomAvatar
            src={user.profilePicture}
            title={user.firstname ? user.firstname + " " + user.lastname : "WD"}
            size={128}
            fontSize={128 / 3}
          />
          <h4 className="text-center capitalize mon-500-18 mt-6 text-black">
            {user.firstname || "N/A"} {user.lastname}
          </h4>
        </div>
        <div>
          <div className="mt-7">
            <p className="uppercase mon-500-10 text-gray-500">email</p>
            <div className="flex gap-x-3 items-center mt-2">
              <p className="mon-500-14 text-black ">{user.email || "N/A"}</p>
              <CopyButton text={user.email} />
            </div>
          </div>
          <div className="mt-9">
            <p className="uppercase mon-500-10 text-gray-500">mobile number</p>
            <p className="mon-500-14 text-black mt-2">{user.mobile || "N/A"}</p>
          </div>
          <div className="mt-9">
            <p className="uppercase mon-500-10 text-gray-500">company</p>
            <p className="mon-500-14 text-black mt-2">
              {user.company || "N/A"}
            </p>
          </div>
          <div className="mt-9">
            <p className="uppercase mon-500-10 text-gray-500">job title</p>
            <p className="mon-500-14 text-black mt-2">
              {user.jobTitle || "N/A"}
            </p>
          </div>
          <div className="mt-9">
            <p className="uppercase mon-500-10 text-gray-500">
              organization role
            </p>
            <CustomBadge
              dot
              text={
                user.roleid == "1"
                  ? "Owner"
                  : user.roleid == "2"
                  ? "Admin"
                  : user.roleid == "3"
                  ? "Basic User"
                  : "Basic User"
              }
              color="#F40849"
              className="mon-500-14 text-black mt-2"
            />
          </div>
          <div className="mt-9">
            <p className="uppercase mon-500-10 text-gray-500">
              Currently located in
            </p>
            <div className="flex justify-between">
              <p className="mon-500-14 text-black mt-2">{user.location}</p>
              <div className="flex items-center">
                <img src="/images/icons/clock.svg" className="w-5 h-5 " />
                <p className="mon-500-12 text-gray-500  ml-2">
                  {timezone.timeZoneId
                    ? dayjs().tz(timezone.timeZoneId).format("hh:mm a")
                    : "N/A"}{" "}
                  Local time
                </p>
              </div>
            </div>
          </div>
          <div className="mt-9">
            <p className="uppercase mon-500-10 text-gray-500">Added on</p>
            <p className="mon-500-14 text-black mt-2">
              {user.createdAt
                ? dayjs(user.createdAt).local().format("DD MMM YYYY, hh:mm a")
                : "N/A"}
            </p>
          </div>
        </div>
      </div>
    </Drawer>
  );
};

export default DirectMessageProfileDrawer;
