import { Dropdown, Menu, Tooltip } from "antd";
import React, { useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import CustomAvatar from "../../Core/Common/CustomAvatar";
import CustomInput from "../../Core/Common/CustomInput";
import CustomInputWithSaveAndEdit from "../../Core/Common/CustomInputWithSaveAndEdit";
import { createGoal, getGoalsBySprint } from "../../Core/redux/api/scheduleAPI";
import { useAppDispatch, useAppSelector } from "../../Core/redux/hooks";
import {
  resetSchedule,
  updateSchedule,
} from "../../Core/redux/slices/schedule.slice";
import PhaseIcon from "../../Core/svg/PhaseIcon";
import PriorityIcon, {
  PriorityIconWithLabel,
} from "../../Core/svg/PriorityIcon";
import TaskTypeIcon from "../../Core/svg/TaskTypeIcon";
import * as Yup from "yup";
import { Formik, useFormik } from "formik";

const SubTask = ({ subTask, index }) => {
  const { userList } = useAppSelector((state) => state.overview);
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const { projectId } = useParams();
  const { currentSelectedPhase } = useAppSelector((state) => state.schedule);

  const assignee = userList.find(
    (user) => +user.userId === +subTask.assigneeId
  );

  const statusStyles = {
    1: "bg-gray-100",
    2: "bg-primary-600",
    3: "bg-success-600",
  };
  return (
    <div
      className="px-3 py-4 rounded-lg mt-3 hover:bg-gray-50"
      style={{
        boxShadow:
          "0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)",
      }}
      onClick={(e) => {
        e.stopPropagation();
        // dispatch(updateSchedule({ key: "newSubTaskModal", value: true }));
        // dispatch(updateSchedule({ key: "selectedSubTask", value: subTask }));
        dispatch(
          updateSchedule({
            key: "newSubTaskModal",
            value: {
              visible: true,
              editTask: true,
              subtask: {
                ...subTask,
                sprintId: searchParams.get("selectedSprint"),
                projectId: projectId,
                phaseId: currentSelectedPhase.phaseId,
                names: [subTask.name],
                isCompleted: 0,
                statusId: subTask.statusId,
                priorityId: subTask.priorityId,
                goalType: subTask.goalType,
                assigneeId: subTask.assigneeId,
                reporterId: subTask.reporterId,
                description: subTask.description,
                parentGoalId: subTask.parentGoalId,
                goalId: subTask.id,
              },
            },
          })
        );
      }}
    >
      <div className="flex items-center gap-x-2 ">
        <TaskTypeIcon type="subtask" width="12" height="12" />
        <Tooltip title={subTask.name} placement="bottom">
          <p className="mon-500-12 text-gray-900 max-w-[80%] truncate">
            {subTask.name}
          </p>
        </Tooltip>
      </div>
      <p className="text-[10px] font-semibold text-primary-600 mt-1">{`ID: ${subTask.id}`}</p>
      <div className="flex justify-end items-center gap-x-2 mt-3">
        <Tooltip
          title={
            subTask.statusId == 1
              ? "TO DO"
              : subTask.statusId == 2
              ? "IN PROGRESS"
              : "DONE"
          }
          placement="bottom"
        >
          <div
            className={`h-3 w-3 rounded-full ${statusStyles[subTask.statusId]}`}
          />
        </Tooltip>
        <PriorityIcon priority={subTask.priorityId} size={"16px"} />
        {assignee && (
          <CustomAvatar
            title={
              assignee.firstname
                ? assignee.firstname + " " + assignee.lastname
                : "User"
            }
            src={assignee.profilePicture}
            size={24}
          />
        )}
      </div>
    </div>
  );
};

const TaskCard = ({ task }: { task?: any }) => {
  const [showSubTask, setShowSubTask] = useState(true);
  const [addSubTask, setAddSubTask] = useState(false);
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const { userList } = useAppSelector((state) => state.overview);
  const { schedule } = useAppSelector((state) => state.projects);
  const subTaskAndBugs = schedule?.[3]?.[0] || {};

  const { selectedSprint, currentSelectedPhase, goals } = useAppSelector(
    (state) => state.schedule
  );

  const assignee = userList.find((user) => +user.userId === +task.assigneeId);

  const reporter = userList.find((user) => +user.userId === +task.reporterId);

  const [subtask, setSubTask] = useState({
    goalType: 4,
    taskName: "",
    assigneeId: "",
    reporterId: reporter?.userId,
    assignee: `${
      assignee?.firstName === null ? "assignee" : assignee?.firstName
    } ${assignee?.lastName === null ? "assignee" : assignee?.lastName}`,
    reporter: `${
      reporter?.firstName === null ? "reporter" : reporter?.firstName
    } ${reporter?.lastName === null ? "reporter" : reporter?.lastName}`,
    statusId: 1,
    priorityId: 0,
    estimatedTime: "",
    componentId: "",
    assigneeProfilePicture: assignee?.profilePicture,
    reporterProfilePicture: reporter?.profilePicture,
    description: "",
    comments: "",
    // estimatedTime: "",
    timeLogged: "",
    // componentId: "",
    componentName: "",
    categoryId: "",
  });

  const assigneeAndReporter = [
    {
      id: 1,
      name: "Kevin Shah",
    },
    {
      id: 2,
      name: "Ganesh Monhanty",
    },
    {
      id: 3,
      name: "kaustubh Bhagwat",
    },
  ];

  const { projectId } = useParams();

  const createSubtask = async () => {
    const payload = {
      sprintId: searchParams.get("selectedSprint"),
      projectId: projectId,
      phaseId: currentSelectedPhase.phaseId,
      names: [subtask.taskName],
      isCompleted: 0,
      statusId: subtask.statusId,
      priorityId: subtask.priorityId,
      goalType: 4,
      assigneeId: subtask.assigneeId || assignee?.userId,
      reporterId: subtask.reporterId,
      description: subtask.description,
      parentGoalId: task.id,
      estimatedTime: subtask.estimatedTime,
      timeLogged: subtask.timeLogged,
      componentId: subtask.componentId,
      componentName: subtask.componentName,
      categoryId: subtask.categoryId,
    };

    setAddSubTask(false);
    const newGoals = goals.map((g) => {
      if (g.id === task.id) {
        return {
          ...g,
          subTasks: [
            ...g.subTasks,
            {
              name: subtask.taskName,
              priorityId: subtask.priorityId,
              assigneeId: subtask.assigneeId,
            },
          ],
        };
      }
      return g;
    });
    dispatch(updateSchedule({ key: "goals", value: newGoals }));

    await dispatch(createGoal(payload));
    dispatch(resetSchedule(["newTaskModal"]));
    dispatch(getGoalsBySprint({ projectId, sprintId: selectedSprint.id }));
  };
  const formik = useFormik({
    initialValues: {
      name: null,
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required("Please enter the title of the subtask")
        .typeError("Please enter the title of the subtask"),
    }),
    onSubmit: async (values) => {
      createSubtask();
      // createBug();
    },
  });

  const height = task.goalType === 2 ? "168px" : "136px";

  const getPhaseNameById = (id) => {
    switch (id) {
      case "1":
        return "Ideation phase";
      case "2":
        return "Design phase";
      case "3":
        return "Development phase";
      case "4":
        return "Deployment phase";
      case "5":
        return "Maintenance phase";
      default:
        return "N/A";
    }
  };

  const handleTaskClick = (e, task) => {
    e.stopPropagation();
    switch (+task.goalType) {
      case 1:
        dispatch(
          updateSchedule({
            key: "newTaskModal",
            value: {
              visible: true,
              isNewScope: false,
              scopeDescription: "",
            },
          })
        );
        dispatch(updateSchedule({ key: "selectedTask", value: task }));
        break;
      case 2:
        dispatch(updateSchedule({ key: "newEventModal", value: true }));
        dispatch(updateSchedule({ key: "selectedEvent", value: task }));
        break;
      case 3:
        dispatch(updateSchedule({ key: "newBugModal", value: true }));
        dispatch(updateSchedule({ key: "selectedBug", value: task }));
        break;
      default:
        break;
    }
  };
  return (
    <div
      className="bg-white px-4 py-[18px] rounded-xl mt-4 max-h-max cursor-pointer "
      style={{ maxHeight: showSubTask || addSubTask ? "min-content" : height }}
    >
      <div className="flex justify-between">
        <div
          className="flex flex-1 w-[80%]"
          onClick={(e) => {
            handleTaskClick(e, task);
          }}
        >
          <TaskTypeIcon
            type={task.isNewGoal ? "newscope" : +task.goalType}
            width="16"
            height="16"
          />
          <Tooltip title={task.name} placement="bottom">
            <h6 className="mon-500-12 text-gray-900 ml-2 w-[80%] truncate">
              {task.name}
            </h6>
          </Tooltip>
        </div>
        <div className="flex items-center">
          {task.phaseId && (
            <Tooltip title={getPhaseNameById(task.phaseId)} placement="bottom">
              <div>
                <PhaseIcon phaseId={+task.phaseId} height="10px" width="10px" />
              </div>
            </Tooltip>
          )}
          {task.subTasks?.length !== 0 && (
            <button
              onClick={(e) => {
                e.stopPropagation();
                setShowSubTask(!showSubTask);
              }}
              className="ml-1"
            >
              <img
                src="/images/icons/chevron-down-gray.svg"
                className={`w-5 h-5 ml-3 transform duration-300 ${
                  showSubTask ? "rotate-180" : "rotate-0"
                }`}
              />
            </button>
          )}
        </div>
      </div>
      {task.goalType === "2" && (
        <div
          className="flex items-center gap-x-2 mt-3.5"
          onClick={(e) => {
            e.stopPropagation();
            window.open(task.meetLink, "_blank", "noopener,noreferrer");
          }}
        >
          <img src="/images/icons/google-meet.svg" alt="meet" />
          <div className="mon-500-12 text-primary-600 hover:text-primary-600 underline">
            Join with Google Meet
          </div>
        </div>
      )}
      <div className="mt-4">
        <div className="flex justify-between">
          <div className="flex items-center gap-x-1">
            <p className="text-[10px] font-semibold text-primary-600">
              ID: {`${task.linkedGoalId || task.id}`}
            </p>
            {task.goalType !== "2" && task.subTasks?.length !== 0 && (
              <p className="mon-400-12 text-gray-500">
                ({task.subTasks?.length} Sub task)
              </p>
            )}
          </div>
          <div className="flex items-center gap-x-2 ">
            <PriorityIcon priority={task.priorityId} size={"16px"} />
            {assignee && (
              <Tooltip
                title={`Assignee: ${
                  assignee?.firstname
                    ? assignee?.firstname + " " + assignee?.lastname
                    : "User"
                }`}
                placement="bottom"
              >
                <div>
                  <CustomAvatar
                    title={
                      assignee?.firstname
                        ? assignee?.firstname + " " + assignee?.lastname
                        : "User"
                    }
                    src={assignee?.profilePicture}
                    size={24}
                  />
                </div>
              </Tooltip>
            )}
          </div>
        </div>
      </div>
      {showSubTask && task.subTasks?.length > 0 && (
        <div className="">
          {task.subTasks.map((subtask, i) => (
            <SubTask
              subTask={{ ...subtask, parentTaskName: task.name }}
              key={subtask.id}
              index={i + 1}
            />
          ))}
        </div>
      )}

      <div
        className=""
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        {addSubTask ? (
          <div className=" w-full py-3 bg-gray-50 rounded-lg px-3 mt-2">
            <div className="flex items-center justify-between ">
              <div className="flex items-center gap-x-2">
                <img src="/images/icons/subtask.svg" />
                <p className="mon-400-12 text-gray-500">Subtask</p>
              </div>
              <div className="flex items-center gap-x-2">
                <img
                  src="/images/icons/expand-01.svg"
                  onClick={(e) => {
                    e.stopPropagation();
                    setAddSubTask(false);
                    dispatch(
                      updateSchedule({
                        key: "newSubTaskModal",
                        value: {
                          visible: true,
                          editTask: false,

                          subtask: {
                            sprintId: searchParams.get("selectedSprint"),
                            projectId: projectId,
                            phaseId: currentSelectedPhase.phaseId,
                            names: [subtask.taskName],
                            isCompleted: 0,
                            statusId: subtask.statusId,
                            priorityId: subtask.priorityId,
                            goalType: subtask.goalType,
                            assigneeId: subtask.assigneeId,
                            reporterId: subtask.reporterId,
                            description: subtask.description,
                            parentGoalId: task.id,
                            goalId: task.id,
                            parentTaskName: task.name,
                          },
                        },
                      })
                    );
                  }}
                />
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    setAddSubTask(false);
                  }}
                >
                  <img className="h-3 w-3" src="/images/icons/x-close.svg" />
                </button>
              </div>
            </div>
            <div onClick={(e) => e.stopPropagation()} className="mt-3">
              <CustomInputWithSaveAndEdit
                placeholder="What needs to be done?"
                //className="rounded-xl mt-3"
                //wrapperClassName="rounded-xl"
                inputClassName="rounded-xl"
                // value={subtask.taskName}
                onClick={(e) => e.stopPropagation()}
                {...formik.getFieldProps("name")}
                onChange={(e) => {
                  setSubTask({ ...subtask, taskName: e.target.value });
                  formik.setFieldValue("name", e.target.value);
                }}
                onPressEnter={() => formik.handleSubmit()}
                onOk={() => formik.handleSubmit()}
                formikHook={formik}
              />
            </div>
            <div className="flex items-center gap-x-2 mt-3">
              <Dropdown
                className=""
                overlay={
                  <Menu className="w-[200px] rounded-lg custom-menu-dropdown  shadow-dropdown">
                    {assigneeAndReporter.map(({ id }, i) => (
                      <Menu.Item
                        className="py-2.5 px-4 rounded-t-lg"
                        key={id}
                        onClick={(e) => {
                          // e.stopPropagation();
                          setSubTask({ ...subtask, priorityId: id });
                        }}
                      >
                        <PriorityIconWithLabel priority={id} />
                      </Menu.Item>
                    ))}
                  </Menu>
                }
                trigger={["click"]}
              >
                <button>
                  {subtask.priorityId ? (
                    <PriorityIcon
                      priority={+subtask.priorityId}
                      size={"16px"}
                    />
                  ) : (
                    <img
                      className="h-4 w-4"
                      src="/images/icons/priority-dash.svg"
                    />
                  )}
                </button>
              </Dropdown>

              <Dropdown
                className=""
                overlay={
                  <Menu className="w-[200px] rounded-lg custom-menu-dropdown  shadow-dropdown">
                    {userList.map((user) => (
                      <Menu.Item
                        className="py-2.5 px-4 rounded-t-lg"
                        key={user.userId}
                        onClick={(e) => {
                          // e.stopPropagation();
                          setSubTask({ ...subtask, assigneeId: user.userId });
                        }}
                      >
                        <div className="flex items-center">
                          <CustomAvatar
                            size={24}
                            title={
                              user.firstname
                                ? user.firstname + " " + user.lastname
                                : "User"
                            }
                            src={user.profilePicture}
                          />
                          <span className="mon-400-12 ml-2 whitespace-nowrap text-gray-700">
                            {user.firstname
                              ? user.firstname + " " + user.lastname
                              : "User"}
                          </span>
                        </div>
                      </Menu.Item>
                    ))}
                  </Menu>
                }
                trigger={["click"]}
              >
                <button onClick={(e) => e.stopPropagation()}>
                  {userList.find(
                    (user) => +user.userId === +subtask.assigneeId
                  ) ? (
                    <CustomAvatar
                      title={
                        userList.find(
                          (user) => +user.userId === +subtask.assigneeId
                        )?.firstname
                          ? userList.find(
                              (user) => +user.userId === +subtask.assigneeId
                            )?.firstname +
                            " " +
                            userList.find(
                              (user) => +user.userId === +subtask.assigneeId
                            )?.lastname
                          : "User"
                      }
                      src={
                        userList.find(
                          (user) => +user.userId === +subtask.assigneeId
                        )?.profilePicture
                      }
                      size={24}
                    />
                  ) : (
                    <img
                      className="h-6 w-6"
                      src="/images/icons/plus-dash.svg"
                    />
                  )}
                </button>
              </Dropdown>
            </div>
          </div>
        ) : (
          <>
            {subTaskAndBugs?.create === 1 && task.goalType !== "2" && (
              <button
                className="flex justify-center items-center gap-x-2 w-full py-2 hover:bg-gray-50 rounded-lg mt-2"
                onClick={(e) => {
                  e.stopPropagation();
                  setAddSubTask(true);
                }}
              >
                <img src="/images/icons/plus-gray.svg" className="w-5 h-5" />
                <span className="mon-500-14 text-gray-500">Add Subtask</span>
              </button>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default React.memo(TaskCard);
