import { Spin, notification } from "antd";
import React from "react";
import { useSearchParams } from "react-router-dom";
import Error from "./Core/Common/Error";
import SuccessMessage from "./Core/Common/SuccessMessage";
import { useAppSelector } from "./Core/redux/hooks";
import FilePreviewScreenNew from "./Modules/Conversations/FilePreviewScreenNew";
import { getMessaging, onMessage } from "firebase/messaging";
import AllowNotificationPopup from "./Core/Common/AllowNotificationPopup";

const AppWrapper = ({ children }) => {
  const { currentThread } = useAppSelector((state) => state.conversations);
  const { activeRequests, loading, success } = useAppSelector(
    (state) => state.global
  );
  const { visible: filePreviewVisible } = useAppSelector(
    (state) => state.preview
  );
  const [searchParams, setSearchParams] = useSearchParams();

  const isLoading = activeRequests > 0 && loading;

  const openNotification = (title, body) => {
    notification.open({
      message: <p className="mon-500-14 text-gray-900">{title}</p>,
      description: <p className="mon-500-12 text-gray-500">{body}</p>,
      duration: 5,
      onClick: () => {
        console.log("Notification Clicked!");
      },
      className: "rounded-lg",
      closeIcon: <img src="/images/icons/x-close.svg" />,
      icon: <img src="/images/w.svg" />,
    });
  };

  const messaging = getMessaging();
  onMessage(messaging, (payload) => {
    const currentUrl = window.location.href;

    if (payload.data && currentUrl.includes("conversations")) {
      if (
        payload.data.threadId &&
        payload.data.threadId == currentThread.threadId
      ) {
        return;
      }
      if (
        payload.data.senderId &&
        payload.data.senderId == currentThread.userId
      ) {
        return;
      }
    } else {
      openNotification(payload.notification.title, payload.notification.body);
    }
  });

  return (
    <div className="relative">
      {filePreviewVisible && searchParams.has("filePreview") && (
        <FilePreviewScreenNew visible={filePreviewVisible} />
      )}
      <Error />
      <Spin spinning={isLoading} style={{ maxHeight: "100vh", zIndex: 9999 }}>
        <AllowNotificationPopup />
        {success?.message?.length !== 0 && <SuccessMessage />}
        {children}
      </Spin>
    </div>
  );
};

export default AppWrapper;
