import React from "react";
import { useAppDispatch } from "../../../Core/redux/hooks";
import { compeleteResetProjectOnboarding } from "../../../Core/redux/slices/projectOnboarding.slice";
import { useNavigate } from "react-router-dom";

const StepLayout = ({ children }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  return (
    <div className="absolute top-24 left-24">
      <div
        onClick={() => {
          dispatch(compeleteResetProjectOnboarding({}));
          navigate("/dashboard");
        }}
        className="flex items-center cursor-pointer"
      >
        <img src="/images/icons/x-close.svg" className="w-5 h-5" />
        <p className="mon-500-14 text-gray-500 pl-2">Cancel</p>
      </div>
      {children}
    </div>
  );
};

export default StepLayout;
