import { Checkbox, Divider, Row, Col } from "antd";
import React, { useState } from "react";
import CustomBadge from "../../../Core/Common/CustomBadge";
import CustomButton from "../../../Core/Common/CustomButton";
import CurrentStep from "./CurrentStep";
import StepHeading from "./StepHeading";
import StepLayout from "./StepLayout";
import AddComponentModal from "./AddComponentModal";
import {
  changeStep,
  compeleteResetProjectOnboarding,
  updateProjectOnboarding,
} from "../../../Core/redux/slices/projectOnboarding.slice";
import { useAppDispatch, useAppSelector } from "../../../Core/redux/hooks";
import { createProject } from "../../../Core/redux/api/projectAPI";
import { client } from "../../../Core/utils/axiosClient";
import { useNavigate } from "react-router-dom";

const AddMore = ({ phaseId, groupId, handelInput }) => {
  const [showInput, setShowInput] = useState(false);
  const [value, setvalue] = useState("");
  return (
    <div>
      {showInput ? (
        <div
          className={`${
            value?.length > 0
              ? "border-b-2 border-primary-600"
              : " border-b-[1px] border-gray-400"
          } w-[109px] mt-2 `}
        >
          <div className="flex justify-between items-center">
            <input
              className="outline-none bg-transparent w-full"
              value={value}
              onChange={(e) => setvalue(e.target.value)}
            />
            <div
              className="cursor-pointer"
              onClick={() => {
                setvalue("");
                setShowInput(false);
                handelInput(phaseId, groupId, value);
              }}
            >
              <img src="/images/icons/input_save.svg" alt="" />
            </div>
            <div
              className="cursor-pointer ml-1"
              onClick={() => {
                setvalue("");
                setShowInput(false);
              }}
            >
              <img src="/images/icons/sector-icons/input-cross.svg" alt="" />
            </div>
          </div>
        </div>
      ) : (
        <CustomButton
          text="Add more"
          width="109px"
          height="28px"
          className="bg-gray-50 text-gray-500 pl-0.5 mt-2"
          icon="/images/icons/plus-gray.svg"
          iconAlignment="right"
          iconSize="w-3 h-3"
          onClick={() => setShowInput(true)}
        />
      )}
    </div>
  );
};

const Step7 = () => {
  const [addComponent, setAddComponent] = useState({
    visble: false,
    phaseId: 1,
  });
  const navigate = useNavigate();

  const {
    projectName,
    projectImage,
    projectColor,

    clientName,
    clientImage,
    internalProject,
    clientColor,

    projectDuration,
    clientLocation,
    // step 4
    softwareType,
    // step 5
    selectedPhase,
    phaseDetails,
    components,
  } = useAppSelector((state) => state.projectOnboarding);

  const design = [
    {
      id: 1,
      title: "Figma",
    },
    {
      id: 2,
      title: "Adobe XD",
    },
  ];

  const development = [
    {
      id: 1,
      title: "Back-end",
      content: [
        {
          id: 1,
          heading: "Node JS",
        },
      ],
    },
    {
      id: 2,
      title: "Front-end",
      content: [
        {
          id: 1,
          heading: "React JS",
        },
        {
          id: 2,
          heading: "Next JS",
        },
      ],
    },
    {
      id: 3,
      title: "Testing",
      content: [
        {
          id: 1,
          heading: "BrowserStack",
        },
      ],
    },
  ];

  const dispatch = useAppDispatch();

  const handelCreateProject = async () => {
    const phases = phaseDetails.filter((p) => selectedPhase.includes(p.id));

    const project = {
      title: projectName,
      //components,
      startDate: projectDuration[0],
      endDate: projectDuration[1],
      projectColor,
      projectImage,
      clientName,
      clientImage,
      clientLocation,
      projectType: softwareType,
      phases,
      internalProject,
      clientColor,
    };
    const { data } = await client.post(`/project/create`, project);
    if (data.projectId) {
      dispatch(compeleteResetProjectOnboarding({}));
      navigate(`/overview/${data.projectId}`);
    }
  };

  const handelInput = (phaseId, groupId, val) => {
    const oldPhaseDetails = [...phaseDetails];
    const newPhaseDetails = oldPhaseDetails.map((p) => {
      if (p.id === phaseId) {
        const components = p.components.map((c) => {
          if (c.id === groupId) {
            return {
              ...c,
              content: [
                ...c.content,
                {
                  id: c.content.length + 1,
                  heading: val,
                },
              ],
            };
          }
          return c;
        });

        return { ...p, components };
      }
      return p;
    });

    // let newPhase;
    dispatch(
      updateProjectOnboarding({ key: "phaseDetails", value: newPhaseDetails })
    );
  };

  const handelRemove = (phaseId, groupId, contentId) => {
    const oldPhaseDetails = [...phaseDetails];
    const newPhaseDetails = oldPhaseDetails.map((p) => {
      if (p.id === phaseId) {
        const components = p.components.map((c) => {
          if (c.id === groupId) {
            return {
              ...c,
              content: c.content.filter((con) => con.id !== contentId),
            };
          }
          return c;
        });

        return { ...p, components };
      }
      return p;
    });

    // let newPhase;
    dispatch(
      updateProjectOnboarding({ key: "phaseDetails", value: newPhaseDetails })
    );
  };
  const handelRemoveGroup = (phaseId, groupId) => {
    const oldPhaseDetails = [...phaseDetails];
    const newPhaseDetails = oldPhaseDetails.map((p) => {
      if (p.id === phaseId) {
        const components = p.components.filter((c) => {
          return c.id !== groupId;
        });

        return { ...p, components };
      }

      return p;
    });

    // let newPhase;
    dispatch(
      updateProjectOnboarding({ key: "phaseDetails", value: newPhaseDetails })
    );
  };

  return (
    <StepLayout>
      <CurrentStep className={"mt-20"} currentStep={7} totalStep={7} />
      <StepHeading
        title={
          <p className="text-gray-900 mon-500-20">Great, We’re almost there!</p>
        }
        subTitle={
          <p className="mon-400-16 text-gray-500 pl-2 w-[595px]">
            This is the last step in creating a project. Here’s a list of
            components, curated for{" "}
            <span className="mon-500-16 text-gray-900">Web App.</span> You can
            also change these later in settings
          </p>
        }
        // frostyAlignment="mt-1"
      />

      <div className="w-[50vw] overflow-x-scroll mt-6 h-[220px] flex gap-x-3">
        {phaseDetails
          .filter((phase) => selectedPhase.includes(phase.id))
          .map((phase) => (
            <div
              className="min-w-min h-[216px] border-[1px] border-gray-200 rounded-xl p-3 overflow-x-scroll"
              key={phase.id}
            >
              <div className="flex justify-between">
                <p className="mon-500-12 text-gray-900">{phase.phaseName}</p>
                <p
                  className="mon-500-12 text-gray-500 cursor-pointer"
                  onClick={() =>
                    setAddComponent({ visble: true, phaseId: phase.id })
                  }
                >
                  Add Groups
                </p>
              </div>
              <div className=" flex justify-start gap-x-[24px] mt-3">
                {phase.components.map(({ id, title, content }) => (
                  <div
                    key={id}
                    onClick={() => handelRemoveGroup(phase.id, id)}
                    className="cursor-pointer"
                  >
                    <div className="gap-y-2" key={id}>
                      <div className="flex gap-x-2 items-center">
                        <p className="mon-500-10 text-gray-500">{title}</p>
                        <img
                          src="/images/icons/sector-icons/input-cross.svg"
                          className="h-3 w-3"
                        />
                      </div>
                      {content.map((c) => (
                        <div
                          key={c.id}
                          onClick={() => handelRemove(phase.id, id, c.id)}
                          className="cursor-pointer"
                        >
                          <CustomBadge
                            text={c.heading}
                            color="#6938EF"
                            className="mt-2"
                            icon={
                              <>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="12"
                                  height="12"
                                  fill="none"
                                  viewBox="0 0 16 16"
                                >
                                  <path
                                    stroke="#6938EF"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="1.5"
                                    d="M12 4l-8 8m0-8l8 8"
                                  ></path>
                                </svg>
                              </>
                            }
                          />
                        </div>
                      ))}

                      <AddMore
                        handelInput={handelInput}
                        phaseId={phase.id}
                        groupId={id}
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
      </div>
      {/* <div className="flex items-center mt-[18px]">
        <Checkbox onClick={() => setDoLater(!doLater)} />
        <p className="mon-400-12 text-gray-700 pl-2">I’ll do it later</p>
      </div> */}
      <div className="mt-6 flex gap-x-6">
        <CustomButton
          text="Create Project"
          width="134px"
          height="40px"
          onClick={() => handelCreateProject()}
        />
        <CustomButton
          text="Back"
          // onClick={() => onCancel()}
          className="border border-gray-300 text-gray-700 bg-white mon-500-14"
          height="40px"
          width="68px"
          onClick={() => dispatch(changeStep(6))}
        />
      </div>
      {addComponent.visble && (
        <AddComponentModal
          visible={addComponent.visble}
          phaseId={addComponent.phaseId}
          onCancel={() => setAddComponent({ visble: false, phaseId: 1 })}
        />
      )}
    </StepLayout>
  );
};

export default Step7;
