import React, { useEffect } from "react";
import Header from "./Header";
import SideBar from "./SideBar";
import { Layout } from "antd";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { useParams } from "react-router-dom";
import { getProjectDetatis } from "../redux/api/projectAPI";
import { getTenatMembers } from "../redux/api/tenantAPI";
import { getWorkspaces } from "../redux/api/dashboardAPI";

type CustomProps = {
  children: React.ReactNode;
  page: string;
  sidebar?: boolean;
  // any props that come into the component
};

const CustomLayout = ({ children, page, sidebar = true }: CustomProps) => {
  const { projectId } = useParams();
  const dispatch = useAppDispatch();
  const { currentTenantId } = useAppSelector((state) => state.tenant);

  useEffect(() => {
    if (projectId) {
      dispatch(getProjectDetatis({ projectId }));
    }
  }, [projectId]);
  //TODO
  useEffect(() => {
    dispatch(getWorkspaces());
    dispatch(getTenatMembers({}));
  }, [currentTenantId]);

  return (
    <Layout hasSider>
      <Header page={page} />

      {sidebar && <SideBar page={page} />}
      <Layout.Content className="mt-14 min-h-[calc(100vh-56px)]">
        {children}
      </Layout.Content>
    </Layout>
  );
};

export default CustomLayout;
