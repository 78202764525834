import React, { useState, useEffect } from "react";
import CustomModal from "../../Core/Common/CustomModal";
import CustomButton from "../../Core/Common/CustomButton";
import CustomInputWithEdit from "../../Core/Common/CustomInputWithEdit";
import { Dropdown, Input, Menu } from "antd";
import { CustomSelectWithInlineSearch } from "../../Core/Common/CustomSelectWithSearch";
import CustomSearch, {
  CustomSearchWithDropDown,
} from "../../Core/Common/CustomSearch";
import * as Yup from "yup";
import { useFormik } from "formik";
import CustomAvatar from "../../Core/Common/CustomAvatar";
import { useAppDispatch, useAppSelector } from "../../Core/redux/hooks";
import { useParams } from "react-router-dom";
import PhaseIcon from "../../Core/svg/PhaseIcon";
import { createLink, getAllLinks } from "../../Core/redux/api/vaultAPI";
import { resetVault } from "../../Core/redux/slices/vault.slice";
import CustomSelect from "../../Core/Common/CustomSelect";
import CustomPhaseGoalsSelector from "../../Core/Common/CustomPhaseGoalsSelector";
import CustomInputWithSaveAndEdit from "../../Core/Common/CustomInputWithSaveAndEdit";
import copyTextToClipboard from "../../Core/utils/clipboard";
import { removeDuplicates } from "../../Core/utils";
import { client } from "../../Core/utils/axiosClient";
import CustomTreeSelect from "../../Core/Common/CustomTreeSelect";

const AddLinkModal = ({ visible, onCancel }) => {
  const [isActive, setIsActive] = useState(true);

  const { folders, preFilledVaultModalValues } = useAppSelector(
    (state) => state.vault
  );
  const initialLinkState = {
    linkName: null,
    description: null,
    url: null,
    username: null,
    password: null,
    isActive: null,
    userId: null,
    linkId: null,
    phaseId: preFilledVaultModalValues?.phaseId || null,
    phaseName: null,
    goalId: null,
    goalName: null,
    folderName: null,
    folderId: null,
  };

  const [linkState, setLinkState] = useState({ ...initialLinkState });

  const { currentPhaseGoals, phases } = useAppSelector(
    (state) => state.schedule
  );

  const { user } = useAppSelector((state) => state.userDetails);
  const dispatch = useAppDispatch();
  const { projectId, "*": splat } = useParams();
  const { userList } = useAppSelector((state) => state.overview);
  const { shareAccessDoc } = useAppSelector((state) => state.vault);

  const [selectedUsers, setSelectedUsers] = useState([]);

  useEffect(() => {
    if (shareAccessDoc.users.length > 0) {
      setSelectedUsers([...shareAccessDoc.users]);
    }
    if (
      preFilledVaultModalValues &&
      preFilledVaultModalValues?.users?.length > 0
    ) {
      setSelectedUsers([...preFilledVaultModalValues.users]);
    }
  }, [shareAccessDoc, preFilledVaultModalValues]);

  const [isHovering, setIsHovering] = useState(false);
  const handleMouseOver = () => {
    setIsHovering(true);
  };

  const handleMouseOut = () => {
    setIsHovering(false);
  };

  const formik = useFormik({
    initialValues: {
      name: null,
      url: null,
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required("Please enter the name of the link")
        .typeError("Please enter the name of the link"),
      url: Yup.string()
        .required("Please enter the url")
        .typeError("Please enter the url")
        .url("Please enter a valid url"),
    }),
    onSubmit: (values) => {
      onSubmit();
    },
  });

  const onSubmit = async () => {
    // formik.handleSubmit();
    const {
      linkName,
      description,
      url,
      username,
      password,
      phaseId,
      goalId,
      folderId,
    } = linkState;
    const payload = {
      linkName: linkName,
      description: description,
      url: url,
      username: username,
      password: password,
      phaseId: phaseId,
      projectId: projectId,
      senderId: user.id,
      goalId,
      folderId,
      users: selectedUsers,
      notificationId: null,
    };

    if (preFilledVaultModalValues.notificationId) {
      payload.notificationId = preFilledVaultModalValues.notificationId;
    }

    const queryObject: { projectId: string; folderId?: string } = { projectId };

    if (splat.length > 0) {
      const splatFolderId = splat.split("-");
      folderId !== null
        ? folderId
        : (payload.folderId = splatFolderId[splatFolderId.length - 1]);
      queryObject.folderId = splatFolderId[splatFolderId.length - 1];
    }

    await dispatch(createLink(payload as any));

    dispatch(resetVault(["addFolderModal", "shareAccessDoc"]));
    dispatch(getAllLinks(queryObject));
    setSelectedUsers([]);
    onCancel();
  };

  useEffect(() => {
    setLinkState(initialLinkState);
  }, [visible]);

  const pathSnippets = splat.split("/").filter((i) => i);

  const extraBreadcrumbItems = pathSnippets.map((_, index) => {
    const url = `/vault/${projectId}/${pathSnippets
      .slice(0, index + 1)
      .join("/")}`;
    return _.split("-")[0];
  });

  const breadcrumbItems = ["Vault"].concat(extraBreadcrumbItems);

  return (
    <CustomModal
      title={
        <div className="flex gap-x-1">
          {breadcrumbItems.map((item, index) => {
            return (
              <>
                <span className="mon-400-12 text-gray-500">{item}</span>

                <img
                  className="w-4 h-4"
                  src="/images/icons/chevron-right.svg"
                />
              </>
            );
          })}

          <span className="mon-500-12 text-gray-700">Link</span>
        </div>
      }
      width={"704px"}
      visible={visible}
      body={
        <div className="max-h-[48.189386056191466vh] overflow-y-scroll">
          <div className="p-4">
            <CustomInputWithSaveAndEdit
              label="Name of link"
              placeholder="Add a name"
              {...formik.getFieldProps("name")}
              onChange={(e) => {
                setLinkState({
                  ...linkState,
                  linkName: e.target.value,
                });
                formik.setFieldValue("name", e.target.value);
              }}
              value={linkState.linkName}
              formikHook={formik}
            />

            <div className="mt-9">
              <div className="flex items-center">
                <CustomInputWithEdit
                  width="92%"
                  label="URL"
                  placeholder="Eg. https://www.antarcticaglobal.com"
                  {...formik.getFieldProps("url")}
                  onChange={(e) => {
                    setLinkState({
                      ...linkState,
                      url: e.target.value,
                    });
                    formik.setFieldValue("url", e.target.value);
                  }}
                  value={linkState.url}
                  formikHook={formik}
                />
                <img
                  src="/images/icons/copy.svg"
                  className="w-5 h-5 mt-5 ml-5 cursor-pointer"
                  onClick={() => copyTextToClipboard(linkState.url)}
                />
              </div>
            </div>
            <div className="mon-500-14 text-gray-700 mt-9">
              Description
              <div className="mt-1.5">
                <Input.TextArea
                  rows={5}
                  placeholder="Enter a description..."
                  required={true}
                  className="w-full h-full bg-white rounded-xl border border-gray-300 text-gray-500 pl-4 pt-2  active:outline-none"
                  onChange={(e) =>
                    setLinkState({
                      ...linkState,
                      description: e.target.value,
                    })
                  }
                  value={linkState.description}
                ></Input.TextArea>
              </div>
            </div>
            <CustomPhaseGoalsSelector
              phaseId={linkState.phaseId}
              goalId={linkState.goalId}
              // onLoadingChange={(isLoading) => setShowSpinner(isLoading)}
              onChange={(phaseGoalObj) =>
                setLinkState({ ...linkState, ...phaseGoalObj })
              }
            />
            <div className="mon-500-14 text-gray-700 mt-9">
              Folder (optional)
              {/* <div className="mt-1.5 w-[336px]">
                <CustomSelect
                  value={linkState.folderId?.toString()}
                  options={folders.map((item) => {
                    return {
                      id: item.id?.toString(),
                      title: item.folderName,
                      render: (value) => (
                        <div
                          className="flex items-center -mt-0.5"
                          onClick={() =>
                            setLinkState({
                              ...linkState,
                              // folderName: item.folderName,
                              folderId: item.id?.toString(),
                            })
                          }
                        >
                          <img
                            src="/images/icons/folder-fill.svg"
                            className="h-4 w-4"
                          />
                          <p className="mon-400-14 pl-2 text-gray-900 capitalize ">
                            {item.folderName}
                          </p>
                        </div>
                      ),
                    };
                  })}
                  placeholder="Select folder"
                />
              </div>
             */}
              <div className="mt-1.5 w-[336px]">
                <CustomTreeSelect
                  placeholder="Select folder"
                  options={removeDuplicates(folders, "id").map((item, i) => {
                    return {
                      id: item.id?.toString(),
                      pId: 0,
                      value: item.id?.toString(),
                      isLeaf: item.hasChildFolder ? false : true,
                      title: (
                        <div
                          className="flex items-center -mt-0.5"
                          onClick={() =>
                            setLinkState({
                              ...linkState,
                              // folderName: item.folderName,
                              folderId: item.id?.toString(),
                            })
                          }
                        >
                          <img
                            src="/images/icons/folder-fill.svg"
                            className="h-4 w-4"
                          />
                          <p className="mon-400-14 pl-2 text-gray-900 capitalize ">
                            {item.folderName}
                          </p>
                        </div>
                      ),
                    };
                  })}
                  onLoadData={async ({ id: parentChildId }, updateTreeData) => {
                    const chilFolders = await client.get(`/repository/folder`, {
                      params: { folderId: parentChildId, projectId },
                    });

                    if (chilFolders.data?.result?.length > 0) {
                      const data = removeDuplicates(
                        chilFolders.data?.result,
                        "id"
                      ).map((item) => {
                        return {
                          id: item.id?.toString(),
                          pId: parentChildId,
                          value: item.id?.toString(),
                          isLeaf: item.hasChildFolder ? false : true,
                          title: (
                            <div
                              className="flex items-center -mt-0.5"
                              onClick={() =>
                                setLinkState({
                                  ...linkState,
                                  // folderName: item.folderName,
                                  folderId: item.id?.toString(),
                                })
                              }
                            >
                              <img
                                src="/images/icons/folder-fill.svg"
                                className="h-4 w-4"
                              />
                              <p className="mon-400-14 pl-2 text-gray-900 capitalize ">
                                {item.folderName}
                              </p>
                            </div>
                          ),
                        };
                      });

                      updateTreeData((oldTreeData) => {
                        const newData = [...oldTreeData, ...data];

                        return newData;
                      });
                    }
                  }}
                />
              </div>
              <div className="flex justify-between items-center mt-9 border-b-2">
                <div className="flex justify-center items-center">
                  <img
                    src="/images/icons/lock-02.svg"
                    className="w-5 h-5 mb-2"
                  />
                  <p className="mon-500-14 text-gray-700 pb-2 pl-2">
                    Credentials (optional)
                  </p>
                </div>

                <img
                  src="/images/icons/chevron-down-gray.svg"
                  style={{
                    transition: "transform 0.3s ease-in-out 0s",
                    transform: isActive ? "rotate(-180deg)" : "rotate(0deg)",
                  }}
                  onClick={() => setIsActive(!isActive)}
                  className="w-5 h-5"
                />
              </div>
              {isActive && (
                <>
                  <div className="mt-5 flex items-center justify-between">
                    <CustomInputWithEdit
                      width="49%"
                      label="Username"
                      placeholder="Eg. SuperFrosty"
                      onChange={(e) =>
                        setLinkState({
                          ...linkState,
                          username: e.target.value,
                        })
                      }
                      value={linkState.username}
                    />
                    <CustomInputWithEdit
                      inputClassName=""
                      width="49%"
                      label="Password"
                      placeholder="Eg. 1234abcde"
                      onChange={(e) =>
                        setLinkState({
                          ...linkState,
                          password: e.target.value,
                        })
                      }
                      value={linkState.password}
                    />
                  </div>
                  <p className="mon-400-14 text-gray-400 pt-3">
                    A username and password can be anything you set to access
                    the link
                  </p>
                </>
              )}
            </div>
            <div className="mon-500-14 text-gray-700 mt-9">
              <div className="flex items-center">
                <img src="/images/icons/users-03.svg" />
                <p className="mon-500-14 text-gray-700 ml-2">Share access</p>
              </div>
              <p className="mt-3 mon-400-14 text-gray-400">
                Share with your team members and start a thread for easy
                collaboration.
                <br />
                All your collaborators will automatically get added when you
                start a thread.
              </p>
              <div className="mt-9">
                <CustomSearchWithDropDown
                  style={{
                    border: "1px solid #C0CBD7",
                    height: "42px",
                  }}
                  placeholder="Search team members"
                  options={userList
                    .filter(({ userId }) => userId !== user.id)
                    .filter(
                      (u) =>
                        selectedUsers?.findIndex(
                          (index) => index.userId === u.userId
                        ) === -1 && u.userId !== u.id
                    )
                    .map((u) => {
                      return {
                        id: u.userId,
                        value: u.userId,
                        label: `${u.firstname} ${u.lastname}`,
                        render: (value) => (
                          <div
                            className="flex items-center -mt-0.5"
                            //key={u.userId}
                            onClick={() =>
                              setSelectedUsers((oldList) => [
                                ...oldList,
                                {
                                  userId: u.userId,
                                  isOwner: 0,
                                  canEdit: 0,
                                  canView: 1,
                                },
                              ])
                            }
                          >
                            <CustomAvatar
                              title={u.firstname ? u.firstname : "User"}
                              src={u.profilePicture}
                            />
                            <p className="ml-2 mon-400-12 text-gray-700 capitalize">
                              {u.firstname} {u.lastname}
                            </p>
                          </div>
                        ),
                      };
                    })}
                />
              </div>
            </div>
            <div className="mt-9 px-3">
              <div className="flex justify-between items-center py-2.5">
                <div className="flex items-center">
                  <CustomAvatar
                    title={`${user.firstName}`}
                    size={24}
                    src={user.profilePicture}
                  />
                  <p className="ml-2 mon-400-12 text-gray-700 capitalize">
                    {user.firstName} {user.lastName}
                  </p>
                </div>
                <p className="mon-400-12 text-gray-500">Owner</p>
              </div>
              {userList.map((user) => {
                if (
                  selectedUsers?.findIndex((u) => user.userId === u.userId) !==
                  -1
                ) {
                  return (
                    <div
                      className="flex justify-between items-center py-2.5"
                      key={user.id}
                    >
                      <div className="flex items-center">
                        <CustomAvatar
                          title={`${user.firstname}`}
                          size={24}
                          src={user.profilePicture}
                        />
                        <p className="ml-2 mon-400-12 text-gray-700 capitalize">
                          {user.firstname} {user.lastname}
                        </p>
                      </div>
                      <div className="flex justify-center items-center">
                        <p className="mon-400-12 text-gray-500 mr-4">
                          {selectedUsers.find((u) => u.userId === user.userId)
                            .isOwner === 1
                            ? "Owner"
                            : selectedUsers.find(
                                (u) => u.userId === user.userId
                              ).canEdit
                            ? "Can edit"
                            : "Can view"}
                        </p>
                        <Dropdown
                          overlay={
                            <Menu className="w-[200px] rounded-lg custom-menu-dropdown  shadow-dropdown">
                              <Menu.Item
                                className=" py-2.5 px-4 rounded-lg"
                                onClick={() =>
                                  setSelectedUsers((oldList) => [
                                    ...oldList.map((old) => {
                                      if (old.userId === user.userId) {
                                        return {
                                          ...old,
                                          canEdit: 1,
                                          canView: 1,
                                        };
                                      }
                                      return old;
                                    }),
                                  ])
                                }
                              >
                                <span className="font-normal mb-2 text-gray-900 whitespace-nowrap text-sm">
                                  Can edit
                                </span>
                              </Menu.Item>
                              <Menu.Item
                                className=" py-2.5 px-4 rounded-lg border-b-[1px]"
                                onClick={() =>
                                  setSelectedUsers((oldList) => [
                                    ...oldList.map((old) => {
                                      if (old.userId === user.userId) {
                                        return {
                                          ...old,
                                          canEdit: 0,
                                          canView: 1,
                                        };
                                      }
                                      return old;
                                    }),
                                  ])
                                }
                              >
                                <span className="font-normal mb-2 text-gray-900 whitespace-nowrap text-sm">
                                  Can view
                                </span>
                              </Menu.Item>
                              <Menu.Item
                                className=" py-2.5 px-4 rounded-lg"
                                onClick={() =>
                                  setSelectedUsers((oldList) => [
                                    ...oldList.filter(
                                      (old) => old.userId !== user.userId
                                    ),
                                  ])
                                }
                                onMouseOver={handleMouseOver}
                                onMouseOut={handleMouseOut}
                              >
                                <span
                                  className={`font-normal mb-2  whitespace-nowrap text-sm ${
                                    isHovering
                                      ? "text-gray-900"
                                      : "text-error-600"
                                  }`}
                                >
                                  Remove access
                                </span>
                              </Menu.Item>
                            </Menu>
                          }
                          trigger={["click"]}
                        >
                          <button>
                            <img
                              src="/images/icons/chevron-down-gray.svg"
                              className="w-5 h-5"
                            />
                          </button>
                        </Dropdown>
                      </div>
                    </div>
                  );
                }
              })}
            </div>
          </div>
        </div>
      }
      footer={
        <div className="flex justify-end py-4 gap-x-3 px-3 shadow-upward">
          <CustomButton
            text="Cancel"
            onClick={onCancel}
            className="border border-gray-200 text-gray-700 bg-white mon-500-14"
            height="40px"
            width="81px"
          />
          <CustomButton
            text="Upload"
            onClick={() => formik.handleSubmit()}
            className="mon-500-14 text-white bg-primary-600 "
            height="40px"
            width="132px"
            // disabled={!(formik.isValid && formik.dirty)}
          />
        </div>
      }
      onCancel={onCancel}
    />
  );
};

export default AddLinkModal;
