import type { TreeSelectProps } from "antd";
import { TreeSelect } from "antd";
import type { DefaultOptionType } from "antd/es/select";
import React, { useEffect, useState } from "react";

type CustomTreeSelectProps = {
  options?: any;

  onDropdownVisibleChange?: any;

  onMouseOver?: (e) => void;
  placeholder?: string;
  border?: boolean;
  backgroundColor?: string;
  disabled?: boolean;
  formikHook?: any;
  name?: string;
  showError?: boolean;
  errorText?: string;
  helperText?: string;
  wrapperClassName?: string;
  onFocusChange?: (isTrue, e) => void;
  showBorderOnlyOnFocused?: boolean;
  defaultOpen?: boolean;
  onLoadData: (e, callback) => any;
};

const CustomTreeSelect = ({
  options = [],

  onDropdownVisibleChange,

  placeholder,
  border = true,
  onMouseOver,
  backgroundColor = "bg-white",
  disabled,
  formikHook: formik,
  name,
  showError,
  errorText,
  wrapperClassName,
  onFocusChange,
  helperText,
  showBorderOnlyOnFocused = false,
  defaultOpen = false,
  onLoadData,
}: CustomTreeSelectProps) => {
  const [value, setValue] = useState<string>();
  //   const [treeData, setTreeData] = useState<Omit<DefaultOptionType, "label">[]>([
  //     {
  //       id: 1,
  //       pId: 0,
  //       value: "1",
  //       title: (
  //         <span className="mon-600-13 text-primary-600"> Expand to load</span>
  //       ),
  //     },
  //     { id: 2, pId: 0, value: "2", title: "Expand to load" },
  //     { id: 3, pId: 0, value: "3", title: "Tree Node", isLeaf: true },
  //   ]);

  const [treeData, setTreeData] = useState([...options]);
  console.log(treeData, treeData.length, "optionssss");

  // useEffect(() => {
  //   setTreeData([...options]);
  // }, [options]);
  const [focused, setFocused] = useState(defaultOpen);

  if (formik && name) {
    showError = showError || (formik.touched[name] && !!formik.errors[name]);
    errorText = errorText || (formik.touched[name] && formik.errors[name]);
    // valid =
    //   valid ||
    //   (!formik.errors[name] &&
    //     formik.initialValues[name] !== formik.values[name])
  }

  const onChange = (newValue: string) => {
    console.log(newValue);
    setValue(newValue);
  };

  return (
    <div
      className={`
transition-shadow duration-300 
${border ? "border" : ""} 
${showBorderOnlyOnFocused && focused ? "border" : ""}  
${focused ? "border" : ""} 
${
  showError
    ? "border border-error-600 shadow-input-error"
    : !border && !showBorderOnlyOnFocused
    ? ""
    : focused
    ? "border-primary-500 shadow-input-focus"
    : disabled
    ? "border-grey-200"
    : "border-grey-200"
} 
bg-white rounded-lg h-11 flex items-center w-full ${wrapperClassName}
`}
    >
      <div
        className={`
        rounded-xl w-full bg-transparent  h-11`}
      >
        <TreeSelect
          treeDataSimpleMode
          className={`w-full h-11 flex items-center`}
          bordered={false}
          onFocus={(e) => {
            setFocused(true);
            if (typeof onFocusChange === "function") onFocusChange(true, e);
          }}
          onBlur={(e) => {
            setFocused(false);
            if (typeof onFocusChange === "function") onFocusChange(false, e);
          }}
          getPopupContainer={(trigger) => trigger.parentElement}
          style={{ width: "100%" }}
          value={value}
          defaultOpen={defaultOpen}
          dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
          //placeholder="Please select"
          onChange={onChange}
          loadData={(e) => onLoadData(e, setTreeData)}
          treeData={treeData}
          placeholder={placeholder}
          disabled={disabled}
          suffixIcon={
            <img src="/images/icons/chevron-down-gray.svg" alt="down-arrow" />
          }
          //open={true}
          popupClassName={`truncate max-h-[320px] ${
            border ? "border border-gray-100" : ""
          }   rounded-lg cutom-tree-select-popup`}
          dropdownRender={(menu) => (
            <>
              <div className="  hidden-scrollbar ">{menu}</div>
            </>
          )}
          onDropdownVisibleChange={(open) => {
            setFocused(open);
            if (typeof onDropdownVisibleChange === "function") {
              onDropdownVisibleChange(open);
            }
          }}
        />
        {showError && (
          <div className="mt-1.5 mon-400-14 text-left text-error-500">
            {errorText}
          </div>
        )}
        {helperText && (
          <div className="mt-1.5 mon-400-14 text-left text-error-500">
            {helperText}
          </div>
        )}
      </div>
    </div>
  );
};

export default CustomTreeSelect;
