import React, { useState } from "react";
import CustomModal from "../../Core/Common/CustomModal";
import CustomButton from "../../Core/Common/CustomButton";
import CustomInput from "../../Core/Common/CustomInput";
import { setCurrentSelectedPhase } from "../../Core/redux/slices/schedule.slice";
import { useAppSelector } from "../../Core/redux/hooks";
import CustomSelect from "../../Core/Common/CustomSelect";

type AddNewStatusModalProps = {
  visible: boolean;
  onCancel: () => void;
  onOk: (val: string, id: number) => void;
};

const AddNewStatusModal = ({
  visible,
  onCancel,
  onOk,
}: AddNewStatusModalProps) => {
  const [name, setName] = useState("");
  const [status, setStatus] = useState(null);
  const { currentSelectedPhase } = useAppSelector((state) => state.schedule);
  return (
    <CustomModal
      title={
        <div className="flex gap-x-1">
          <span className="mon-400-12 text-gray-500">
            {/* {currentSelectedPhase?.phases} */}
          </span>
          {/* <img className="w-4 h-4" src="/images/icons/chevron-right.svg" /> */}
          <span className="mon-500-12 text-gray-700">New Swimlane</span>
        </div>
      }
      width={"524px"}
      visible={visible}
      body={
        <div className="max-h-[38.189386056191466vh] overflow-y-scroll">
          <div className="mon-400-14 text-gray-500 pt-6 pb-4 px-5">
            <CustomInput
              label="Add a new title for swimlane"
              placeholder="Add title"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="rounded-xl"
              wrapperClassName="rounded-xl"
              inputClassName="rounded-xl"
            />
            <p className="mt-6 mon-500-14 text-gray-700 pb-1.5">
              Associate to Status
            </p>
            <CustomSelect
              placeholder="Select status"
              options={[
                {
                  id: "1",
                  value: 1,
                  render: (value) => (
                    <div className="flex items-center bg-gray-100  max-w-max whitespace-nowrap rounded-2xl">
                      <p className="mon-500-12 text-gray-700 px-2 uppercase py-0">
                        To Do
                      </p>
                    </div>
                  ),
                },

                {
                  id: "2",
                  value: 2,
                  render: (value) => (
                    <div className="flex items-center max-w-max whitespace-nowrap bg-primary-600 rounded-2xl">
                      <p className="mon-500-12 text-white px-2 uppercase py-0">
                        In progress
                      </p>
                    </div>
                  ),
                },
              ]}
              value={status}
              onChange={(e) => {
                setStatus(e);
              }}
            />
          </div>
        </div>
      }
      footer={
        <div className="flex justify-between pt-8 pb-4 px-3">
          <CustomButton
            text="Cancel"
            onClick={onCancel}
            className="border-2 border-gray-200 text-black bg-white mon-500-16 "
            height="44px"
            width="49%"
          />

          <CustomButton
            text="Add New Status"
            onClick={() => onOk(name, status)}
            className="mon-500-14 text-white bg-primary-600 "
            height="44px"
            width="49%"
          />
        </div>
      }
      onCancel={onCancel}
    />
  );
};

export default AddNewStatusModal;
