import React from "react";
import { Dropdown, Menu } from "antd";

const MenuDropdown = ({ elements, customFunc }) => {
  const newElements = elements.filter((i) => i !== null);
  return (
    <Dropdown
      getPopupContainer={(trigger) => trigger.parentElement}
      className=""
      destroyPopupOnHide
      // onOpenChange={(open) => {
      //   if (open) {
      //     document.getElementById("menu-item-focus")?.focus();
      //   }
      // }}
      overlay={
        <Menu
          className="w-[200px] rounded-lg custom-menu-dropdown shadow-dropdown"
          //defaultSelectedKeys={[newElements[0]?.id]}
        >
          <input
            type="text"
            autoFocus
            value={null}
            contentEditable={false}
            className="h-0 absolute"
          />

          {/* <div tabIndex={0} id="menu-item-focus" /> */}

          {newElements.map((item) => (
            <Menu.Item
              className={`py-2.5 rounded-t-lg ${
                item.borderBottom && "border-b-[1px] pb-2"
              }`}
              key={item.id}
              onClick={item.onclick || customFunc}
            >
              <div className="flex items-center">
                <div className="w-4 h-4">
                  <img src={item.image} className="w-full h-full" />
                </div>
                <div
                  className={`pl-2 mon-400-12 whitespace-nowrap hover:font-medium ${item.textColor}`}
                >
                  {item.text}
                </div>
              </div>
            </Menu.Item>
          ))}
        </Menu>
      }
      trigger={["click"]}
    >
      <div className="cursor-pointer" onClick={(e) => e.stopPropagation()}>
        <img
          src="/images/icons/more-vertical.svg"
          className="w-5 h-5"
          alt="add-item"
        />
      </div>
    </Dropdown>
  );
};

export default MenuDropdown;
