import { Tooltip } from "antd";

import React, { useState } from "react";

import CustomTable from "../../../Core/Common/CustomTable";

import CustomBadge from "../../../Core/Common/CustomBadge";

const AlertsTable = ({ alertData }) => {
  const [currentPageNo, setCurrentPageNo] = useState(1);
  const [limit, setLimit] = useState(10);

  const Status = (statusId) => {
    switch (statusId) {
      case 0:
        return (
          <CustomBadge text="Inactive" color="#344054" bgColor="#EDF3F9" />
        );
      case 1:
        return <CustomBadge text="Active" color="#027A48" />;

      default:
        return <CustomBadge text="Active" color="#027A48" bgColor="#ECFDF3" />;
    }
  };

  const Severity = (severityId) => {
    switch (severityId) {
      case 1:
        return (
          <div className="flex items-center gap-x-1 bg-gray-400 px-2 py-1 max-w-max rounded-2xl">
            <img src="/images/icons/flag-white.svg" />
            <p className="mon-500-12 text-white">Low</p>
          </div>
        );
      case 2:
        return (
          <div className="flex items-center gap-x-1 bg-primary-600 px-2 py-1 max-w-max rounded-2xl">
            <img src="/images/icons/flag-white.svg" />
            <p className="mon-500-12 text-white">Medium</p>
          </div>
        );
      case 3:
        return (
          <div className="flex items-center gap-x-1 bg-error-600 px-2 py-1 max-w-max rounded-2xl">
            <img src="/images/icons/flag-white.svg" />
            <p className="mon-500-12 text-white">High</p>
          </div>
        );
      default:
        return (
          <div className="flex items-center gap-x-1 px-2 py-1 max-w-max rounded-2xl">
            <img src="/images/icons/flag-white.svg" />
            <p className="mon-500-12 text-white">Low</p>
          </div>
        );
    }
  };

  const columns = [
    {
      title: () => (
        <div className="mon-500-10 pl-3 text-gray-500 uppercase">Title</div>
      ),
      // width: "7%",
      dataIndex: "title",
      sorter: (a, b) => a?.title.localeCompare(b?.title),
      render: (text, record) => (
        <div className="mon-500-12 text-gray-900 pl-3">{text}</div>
      ),
    },
    {
      title: () => (
        <div className="mon-500-10 pl-3 text-gray-500 uppercase">
          Description
        </div>
      ),
      width: "12%",
      dataIndex: "description",
      sorter: (a, b) => a.description.localeCompare(b.description),
      render: (text, record) => (
        <Tooltip
          title={
            <div className="m-3">
              <p className="mon-500-12">Description</p>
              <p className="pt-1 mon-400-12">{text}</p>
            </div>
          }
        >
          <div className="ml-3.5 flex justify-start items-center gap-x-1">
            <div className="mon-500-12 text-[#175CD3]">View</div>
            <img src="/images/icons/chevron-down.svg" className="w-3 h-3" />
          </div>
        </Tooltip>
      ),
    },
    {
      title: () => (
        <div className="mon-500-10 pl-3 text-gray-500 uppercase">
          Recurrence
        </div>
      ),
      width: "20%",
      dataIndex: "recurrence",
      render: (text, record) => (
        <div className="pl-3 mon-400-12 text-gray-500 capitalize">{text}</div>
      ),
    },
    {
      title: () => (
        <div className="mon-500-10 pl-3 text-gray-500 uppercase">Severity</div>
      ),
      // width: "7%",
      dataIndex: "severityId",
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (text, record) => (
        <div className="pl-4 mon-500-12 text-gray-900">{Severity(text)}</div>
      ),
    },
    {
      title: () => (
        <div className="mon-500-10 pl-3 text-gray-500 uppercase">Status</div>
      ),
      // width: "7%",
      dataIndex: "statusId",
      render: (text, record) => (
        <p className="mon-500-12 text-gray-700 pl-3">{Status(text)}</p>
      ),
    },
    {
      title: () => (
        <div className="mon-500-10 pl-3 text-gray-500 uppercase">Sent to</div>
      ),
      // width: "7%",
      dataIndex: "sentTo",
      render: (text, record) => (
        <div className="flex items-center gap-x-2 pl-3">
          <p className="mon-500-12 text-gray-500">{text} people</p>
          <img src="/images/icons/chevron-down-gray.svg" className="w-3 h-3" />
        </div>
      ),
    },
  ];
  return (
    <div className=" mb-6 pt-6 bg-white rounded-xl shadow-box">
      <CustomTable
        // customPadding="p-3"
        className="custom-table cursor-pointer"
        //scroll={projects ? "calc(100vh - 520px)" : "0px"}
        columns={columns}
        data={alertData}
        dataCount={alertData.length}
        current={currentPageNo}
        pageSize={limit}
        setPageSize={setLimit}
        setCurrent={(e) => {
          setCurrentPageNo(e);
        }}
      />
    </div>
  );
};

export default AlertsTable;
