import React from "react";

type DownloadIconProps = {
  color?: string;
  width?: number | string;
  height?: number | string;
};
const defaultProps = {
  color: "#667085",
  width: 18,
  height: 12,
};
// width={width} height={height}
const DownloadIcon = ({ color, width, height }: DownloadIconProps) => {
  return (
    <div className="cursor-pointer">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        fill="none"
        viewBox="0 0 20 20"
      >
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M17.5 12.5v1c0 1.4 0 2.1-.273 2.635a2.5 2.5 0 01-1.092 1.092c-.535.273-1.235.273-2.635.273h-7c-1.4 0-2.1 0-2.635-.273a2.5 2.5 0 01-1.093-1.092C2.5 15.6 2.5 14.9 2.5 13.5v-1m11.667-4.167L10 12.5m0 0L5.833 8.333M10 12.5v-10"
        ></path>
      </svg>
    </div>
  );
};

DownloadIcon.defaultProps = defaultProps;

export default DownloadIcon;
