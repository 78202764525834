import { createAsyncThunk } from "@reduxjs/toolkit";
import { client, getError } from "../../utils/axiosClient";

export const getProjectPhases = createAsyncThunk(
  "schedule/getPhases",
  async (
    {
      projectId,
    }: {
      projectId: string;
    },
    { rejectWithValue }
  ) => {
    try {
      if (!projectId) throw Error();
      const response = await client.get(
        `/project/project-phases?projectId=${projectId}`
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(getError(error));
      //return error;
    }
  }
);

export const getProjectPhasesWithSprints = createAsyncThunk(
  "schedule/getPhasesWithSprints",
  async (
    {
      projectId,
    }: {
      projectId: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await client.get(
        `/project/project-phases-with-sprints?projectId=${projectId}`
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(getError(error));
      //return error;
    }
  }
);

export const updatePhase = createAsyncThunk(
  "schedule/updatePhase",
  async (payload: object, { rejectWithValue }) => {
    try {
      const response = await client.put(`project/project-phases`, payload);

      return response.data;
    } catch (error) {
      return rejectWithValue(getError(error));
      //return error;
    }
  }
);

export const getSprintsByPhase = createAsyncThunk(
  "schedule/getSprints",
  async (
    {
      projectId,
      phaseId,
    }: {
      projectId: string;
      phaseId: number;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await client.get(
        `/schedule/sprints?phaseId=${phaseId}&projectId=${projectId}`
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(getError(error));
      //return error;
    }
  }
);

export const getAllSprintsByPhase = createAsyncThunk(
  "schedule/getAllSprints",
  async (
    {
      projectId,
      phaseId,
    }: {
      projectId: string;
      phaseId: number;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await client.get(
        `/schedule/sprints?phaseId=${phaseId}&projectId=${projectId}`
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(getError(error));
      //return error;
    }
  }
);

export const getGoalsBySprint = createAsyncThunk(
  "schedule/getGoals",
  async (
    {
      projectId,
      sprintId,
      filters,
    }: {
      projectId: string;
      sprintId: string;
      filters?: object;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await client.get(
        `schedule/goals?sprintId=${+sprintId}&projectId=${+projectId}`,
        { params: filters }
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(getError(error));
      //return error;
    }
  }
);

export const getGoalsByPhase = createAsyncThunk(
  "schedule/getGoalsByPhase",
  async (
    {
      projectId,
      phaseId,
    }: //phaseId,
    {
      projectId: string;
      phaseId: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await client.get(
        `schedule/ongoing-goals?phaseId=${phaseId}&projectId=${projectId}`
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(getError(error));
      //return error;
    }
  }
);

export const getSwimlanes = createAsyncThunk(
  "schedule/getSwimlanes",
  async (
    {
      projectId,
    }: //phaseId,
    {
      projectId: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await client.get(
        `schedule/swimlane?projectId=${projectId}`
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(getError(error));
      //return error;
    }
  }
);

export const createSwimlane = createAsyncThunk(
  "schedule/createSwimlane",
  async (payload: object, { rejectWithValue }) => {
    try {
      const response = await client.post(`schedule/swimlane`, payload);

      return response.data;
    } catch (error) {
      return rejectWithValue(getError(error));
      //return error;
    }
  }
);

export const updateSwimlane = createAsyncThunk(
  "schedule/updateSwimlane",
  async (payload: object, { rejectWithValue }) => {
    try {
      const response = await client.put(`schedule/swimlane`, payload);

      return response.data;
    } catch (error) {
      return rejectWithValue(getError(error));
      //return error;
    }
  }
);

export const removeSwimlane = createAsyncThunk(
  "schedule/removeSwimlane",
  async (payload: object, { rejectWithValue }) => {
    try {
      const response = await client.delete(`schedule/swimlane`, {
        params: payload,
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(getError(error));
      //return error;
    }
  }
);

export const createSprint = createAsyncThunk(
  "schedule/createSprint",
  async (payload: object, { rejectWithValue }) => {
    try {
      const response = await client.post(`schedule/create-sprint`, payload);

      return response.data;
    } catch (error) {
      return rejectWithValue(getError(error));
      //return error;
    }
  }
);

export const createEvent = createAsyncThunk(
  "schedule/createEvent",
  async (payload: object, { rejectWithValue }) => {
    try {
      const response = await client.post(
        `/integration/calender/create-events`,
        payload
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(getError(error));
      //return error;
    }
  }
);

export const updateSprint = createAsyncThunk(
  "schedule/updateSprint",
  async (payload: object, { rejectWithValue }) => {
    try {
      const response = await client.put(`schedule/update-sprint`, payload);

      return response.data;
    } catch (error) {
      return rejectWithValue(getError(error));
      //return error;
    }
  }
);

export const createGoal = createAsyncThunk(
  "schedule/createGoal",
  async (payload: object, { rejectWithValue }) => {
    try {
      const response = await client.post(`schedule/create-goal`, payload);

      return response.data;
    } catch (error) {
      return rejectWithValue(getError(error));
      //return error;
    }
  }
);

export const updateGoal = createAsyncThunk(
  "schedule/updateGoal",
  async (payload: object, { rejectWithValue }) => {
    try {
      const response = await client.put(`schedule/update-goal`, payload);
      return response.data;
    } catch (error) {
      return rejectWithValue(getError(error));
      //return error;
    }
  }
);

export const updateProgress = createAsyncThunk(
  "schedule/updateProgress",
  async (payload: object, { rejectWithValue }) => {
    try {
      const response = await client.put(`schedule/update-progress`, payload);
      return response.data;
    } catch (error) {
      return rejectWithValue(getError(error));
      //return error;
    }
  }
);
