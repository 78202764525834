import { Input } from "antd";
import React, { useState } from "react";

type CustomInputWithSaveAndEditProps = {
  label?: string;
  type?: string;
  disabled?: boolean;
  placeholder?: string;
  value?: string | number;
  inputClassName?: string;
  maxLength?: number;
  width?: string;
  borderOnHover?: boolean;

  onChange?: (e) => void;
  onPressEnter?: (e) => void;
  onClick?: (e) => void;
  onOk?: () => void;
  onCancel?: () => void;
  focused?: boolean;
  formikHook?: any;
  showError?: boolean;
  errorText?: string;
  name?: string;
};

const CustomInputWithSaveAndEdit = ({
  label,
  type = "input",
  disabled = false,
  placeholder,
  value,
  onChange,
  inputClassName,
  maxLength,
  width,
  borderOnHover = true,
  onPressEnter,
  onClick,
  onOk,
  onCancel,
  focused = false,
  formikHook: formik,
  showError,
  errorText,
  name,
}: CustomInputWithSaveAndEditProps) => {
  const [clickOn, setClickOn] = useState(focused);
  const [isHovering, setIsHovering] = useState(false);

  const handleMouseOver = () => {
    setIsHovering(true);
  };

  const handleMouseOut = () => {
    setIsHovering(false);
  };

  if (formik && name) {
    showError = showError || (formik.touched[name] && !!formik.errors[name]);
    errorText = errorText || (formik.touched[name] && formik.errors[name]);
    // valid =
  }
  return (
    <div
      className=""
      style={{
        width,
      }}
    >
      <div className="mon-500-14 text-gray-700">{label}</div>
      <div
        className={`flex justify-between mt-1.5 ${
          ""
          //   clickOn && "border-2 border-primary-300 rounded-xl"
        }`}
      >
        <div
          className={`rounded-xl border-[1px] ${
            showError
              ? "border-error-600 shadow-input-error"
              : "border-gray-300"
          } shadow-xs bg-white w-full flex items-center`}
          style={
            clickOn
              ? {
                  border: "1px solid #7DC7FF",

                  boxShadow:
                    "0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #D1E9FF",
                }
              : {}
          }
          onMouseOver={handleMouseOver}
          onMouseOut={handleMouseOut}
          //onClick={() => setClickOn(true)}
        >
          <div className={`w-full `}>
            <Input
              onPressEnter={onPressEnter}
              type={type}
              bordered={false}
              disabled={disabled}
              placeholder={placeholder}
              value={value}
              onChange={(e) => {
                setClickOn(true);
                onChange(e);
              }}
              className={`font-medium h-10 ${inputClassName} ${
                clickOn ? "pl-3" : "pl-3"
              }`}
              maxLength={maxLength}
              onClick={onClick}
            />
          </div>
          <div className="mr-3.5 flex items-center gap-x-2">
            {/* {isHovering && !clickOn && (
              <img
                src="/images/icons/edit-2-gray.svg"
                className="w-4 h-4 cursor-pointer"
                alt=""
                onClick={() => setClickOn(true)}
              />
            )} */}
            {clickOn && (
              <>
                <img
                  src="/images/icons/check.svg"
                  className="w-4 h-4 cursor-pointer"
                  alt=""
                  onClick={() => {
                    setClickOn(false);
                    if (typeof onOk === "function") onOk();
                  }}
                />
                <img
                  src="/images/icons/x-close-gray.svg"
                  className="w-4 h-4 cursor-pointer"
                  alt=""
                  onClick={() => {
                    setClickOn(false);
                    if (typeof onCancel === "function") onCancel();
                  }}
                />
              </>
            )}
          </div>
        </div>
      </div>
      {showError && (
        <div className="mt-1.5 mon-400-14 text-left text-error-500">
          {errorText}
        </div>
      )}
    </div>
  );
};

export default CustomInputWithSaveAndEdit;
