import dayjs from "dayjs";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../redux/hooks";
import CustomAvatar from "./CustomAvatar";

const CustomEvent = ({ event }) => {
  const navigate = useNavigate();
  const meetMetaData = event.meetMetaData ? JSON.parse(event.meetMetaData) : {};
  const { tenantDetails } = useAppSelector((state) => state.tenant);
  return (
    <div
      className="mt-3 cursor-pointer"
      onClick={() =>
        window.open(event.meetLink, "_blank", "noopener,noreferrer")
      }
    >
      <div className="flex justify-start items-center gap-x-2">
        <div className="bg-gray-300 rounded-full w-2 h-2" />
        <div className="text-gray-900 mon-400-12">
          {meetMetaData.start?.dateTime
            ? dayjs(meetMetaData.start?.dateTime).format("hh:mm a")
            : "N/A"}{" "}
          -{" "}
          {meetMetaData.end?.dateTime
            ? dayjs(meetMetaData.end?.dateTime).format("hh:mm a")
            : "N/A"}
        </div>
      </div>
      <div className="bg-gray-50 rounded-xl p-3 mt-1.5">
        <div className={`mon-500-12 text-gray-400`}>{event.name}</div>
        <div className=" mt-3 flex justify-start gap-x-2">
          <CustomAvatar
            src={event.projectImage ? event.projectImage : ""}
            title={event.projectName ? event.projectName : "WD"}
            size={24}
          />

          <div className="mon-500-10 text-gray-500 uppercase">
            {event.projectName ? event.projectName : tenantDetails.tenantName}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomEvent;
