import { Row, Tabs, Col, Checkbox, Spin, Table, Tag } from "antd";
import TabPane from "antd/lib/tabs/TabPane";
import React, { useEffect, useState } from "react";
import CustomButton from "../../Core/Common/CustomButton";
import CustomInputWithEdit from "../../Core/Common/CustomInputWithEdit";
import CustomSearch from "../../Core/Common/CustomSearch";
import CustomLayout from "../../Core/Layout/CustomLayout";
import { client } from "../../Core/utils/axiosClient";
import { useAppSelector, useAppDispatch } from "../../Core/redux/hooks";
import { changeTab } from "../../Core/redux/slices/settings.slice";
import CustomModal from "../../Core/Common/CustomModal";
import { CustomSelectWithInlineSearch } from "../../Core/Common/CustomSelectWithSearch";
import CustomSelect from "../../Core/Common/CustomSelect";
import { getAllMembers, getAllRoles } from "../../Core/redux/api/settingsAPI";
import ScheduleMetadata from "./Setting Tabs/ScheduleMetadata";
import NotificationsTab from "./Setting Tabs/NotificationsTab";
import { ColumnsType } from "antd/lib/table";
import DetailsTab from "./Setting Tabs/DetailsTab";
import { useNavigate, useParams } from "react-router-dom";
import AddMembersModal from "../Overview/Components/AddMembersModal";
import { setSuccess } from "../../Core/redux/slices/global.slice";
import dayjs from "dayjs";
import ImportRolesModal from "./ImportRolesModal";
import CustomTable from "../../Core/Common/CustomTable";

const SettingsTabs = ({ currentTab = "1", children, tabs }) => {
  const dispatch = useAppDispatch();

  return (
    <Tabs
      onTabClick={(key) => {
        dispatch(changeTab(key));
      }}
      activeKey={currentTab}
      className="overview-tab mt-4"
    >
      {tabs?.map((tab) => (
        <TabPane
          tab={
            <div className="flex">
              <div
                className={`${
                  currentTab === tab.id
                    ? "mon-500-14 text-primary-600"
                    : "text-gray-500 mon-400-14"
                }`}
              >
                {tab.name}
              </div>
            </div>
          }
          key={tab.id}
        >
          {children}
        </TabPane>
      ))}
    </Tabs>
  );
};

const RolesTab = () => {
  const [rolesList, setRoleList] = React.useState([]);
  const [addNew, setAddNew] = React.useState(false);
  const [roleInput, setRoleInput] = React.useState(null);
  const [editing, setEditing] = React.useState(false);
  const [selectedRoleId, setSelectedRoleId] = useState(null);
  const { overview } = useAppSelector((state) => state.projects);
  const [importRolesModal, setImportRolesModal] = React.useState(false);

  const manageProjects = overview?.[1]?.[0];
  const manageMembers = overview?.[2]?.[0];

  const dispatch = useAppDispatch();

  const [permissionTypes, setPermissionTypes] = useState([]);
  const { projectId } = useParams();

  useEffect(() => {
    client.get("/permissions/get-permission-types").then((res) => {
      console.log(res.data.result);
    });
    getRoleList();
  }, []);

  const getRoleList = () => {
    client
      .get("permissions/role-list", {
        params: {
          projectId,
        },
      })
      .then((res) => {
        console.log(res.data.result);
        setRoleList(res.data.result);
      });
  };

  const CRUD_TYPE = {
    CREATE: "create",
    READ: "read",
    UPDATE: "update",
    DELETE: "delete",
    ALL: "all",
    NULL: null,
    IS_ADMIN: "isAdmin",
  };

  const overviewTypes = [
    {
      permId: 1,
      name: "Manage Projects",
      info: "Edit and delete projects",
      disabled: false,
      permType: CRUD_TYPE.ALL,
    },
    {
      permId: 2,
      name: "Manage Members",
      checked: false,
      info: "Add, edit and delete members",
      disabled: false,
      permType: CRUD_TYPE.ALL,
    },
    {
      permId: 3,
      name: "View only",
      info: "Cannot add, edit or delete anything",
      disabled: false,
      permType: CRUD_TYPE.READ,
    },
  ];

  const allPermissionTypes = [
    {
      id: null,
      type: CRUD_TYPE.CREATE,
      name: "Add",
    },
    {
      id: null,
      type: CRUD_TYPE.UPDATE,
      name: "Edit",
    },
    {
      id: null,
      type: CRUD_TYPE.DELETE,
      name: "Delete",
    },
    // {
    //   id: null,
    //   type: CRUD_TYPE.READ,
    //   name: "View only",
    // },
  ];
  const allSchedulePermissions = [
    {
      id: 1,
      name: "Phase & Sprint",
    },
    {
      id: 2,
      name: "Task, Event & New scope",
    },
    {
      id: 3,
      name: "Sub-task & Bug",
    },
    {
      id: 4,
      name: "Comment",
    },
    {
      id: 5,
      name: "Log Time",
    },
  ];

  interface fileFolderLinkPermissionsInterface {
    name: string;
    type: string;
    id?: number;
  }

  const fileFolderLinkPermissions: fileFolderLinkPermissionsInterface[] = [
    {
      id: null,
      name: "Upload",
      type: CRUD_TYPE.CREATE,
    },
    {
      id: null,

      name: "Edit",
      type: CRUD_TYPE.UPDATE,
    },
    {
      id: 4,
      name: "Delete All",
      type: CRUD_TYPE.IS_ADMIN,
    },
    {
      id: null,
      name: "Delete Own",
      type: CRUD_TYPE.DELETE,
    },
    // {
    //   id: null,
    //   name: "View only",
    //   type: CRUD_TYPE.READ,
    // },
  ];

  const vaultRequestPermissions = [
    {
      id: 3,
      type: CRUD_TYPE.ALL,
      name: "View all past requests",
    },
    {
      id: 6,
      type: CRUD_TYPE.ALL,
      name: "View own past requests",
    },
  ];

  const allVaultPermissions = [
    {
      id: 2,
      name: "Folder, File & Link",
      options: fileFolderLinkPermissions,
    },
    {
      id: 5,
      name: "Comment",
      options: allPermissionTypes,
    },
    {
      id: 0,
      name: "Request",
      options: vaultRequestPermissions,
    },
  ];

  const MODULE_TYPE = {
    OVERVIEW: 1,
    SCHEDULE: 2,
    VAULT: 3,
  };

  // const [permissionList, setPermissionList] = useState([]);
  const [activePermissionList, setActivePermissionList] = useState([]);
  // const [overviewPermissions, setOverviewPermissions] = useState([]);
  // const [schedulePermissions, setSchedulePermissions] = useState([]);
  // const [vaultPermissions, setVaultPermissions] = useState([]);
  const [loadingPermissionList, setLoadingPermissionList] = useState(false);

  const handlePermissionChange = (
    isAllowed,
    moduleId,
    permissionId,
    permissionType = CRUD_TYPE.NULL,
    permissionSubId = null
  ) => {
    const seed: {
      moduleId: number;
      permissionId: number;
      update?: number;
      delete?: number;
      read?: number;
      create?: number;
      is_admin?: boolean;
      roleId?: number;
      isAdmin?: boolean;
    } = {
      moduleId,
      permissionId,
    };

    const defaultAccess = {
      update: 0,
      delete: 0,
      read: 0,
      create: 0,
    };

    let newPermissionList = [...activePermissionList];

    if (moduleId === MODULE_TYPE.OVERVIEW) {
      // OVERVIEW READ ONLY
      if (permissionId === 3) {
        seed.read = +isAllowed;

        newPermissionList = newPermissionList.filter(
          ({ moduleId }) => +moduleId !== +MODULE_TYPE.OVERVIEW
        );

        newPermissionList = [
          ...newPermissionList,
          {
            moduleId: MODULE_TYPE.OVERVIEW,
            permissionId: 1,
            ...defaultAccess,
            read: 1,
          },
          {
            moduleId: MODULE_TYPE.OVERVIEW,
            permissionId: 2,
            ...defaultAccess,
            read: 1,
          },
        ];
        console.log("readOnly permList: ", newPermissionList);
        setActivePermissionList(newPermissionList);
        return;
      } else {
        seed.update = +isAllowed;
        seed.delete = +isAllowed;
        seed.read = +isAllowed;
        seed.create = +isAllowed;
      }

      // setOverviewTypes(newState);
    } else if (moduleId === MODULE_TYPE.VAULT) {
      // MY SPACE
      if ([0].includes(+permissionId) || [3, 6].includes(+permissionSubId)) {
        // My Space
        seed.update = +isAllowed;
        seed.delete = +isAllowed;
        seed.read = +isAllowed;
        seed.create = +isAllowed;
      } else {
        seed[permissionType] = +isAllowed;
      }
      if (permissionType === CRUD_TYPE.READ && isAllowed) {
        const { NULL, ALL, READ, ...OTHER_CRUD } = CRUD_TYPE;
        Object.values(OTHER_CRUD).forEach(
          (CRUD_UNIT) => (seed[CRUD_UNIT] = +!isAllowed)
        );
        if (permissionId === 2) seed[CRUD_TYPE.IS_ADMIN] = +!isAllowed;
      } else if (isAllowed) {
        seed[CRUD_TYPE.READ] = 1;
      }
    } else {
      //  Else = Schedule for now

      if (permissionId === 0) {
        // console.log("VIEW ONLY SCHEDULE");
        const permList = [];
        allSchedulePermissions.forEach(({ id: pId }) => {
          const perm = {
            ...defaultAccess,
            read: +isAllowed,
            isAdmin: false,
            moduleId: MODULE_TYPE.SCHEDULE,
            permissionId: pId,
          };
          permList.push(perm);
        });
        let newList = [...activePermissionList];
        newList = newList.filter(
          ({ moduleId }) => moduleId !== MODULE_TYPE.SCHEDULE
        );
        setActivePermissionList([...newList, ...permList]);

        return;
      }

      console.log("READD: ");
      if (permissionType === CRUD_TYPE.READ && isAllowed) {
        const { NULL, ALL, READ, ...OTHER_CRUD } = CRUD_TYPE;
        Object.values(OTHER_CRUD).forEach(
          (CRUD_UNIT) => (seed[CRUD_UNIT] = +!isAllowed)
        );
      } else if (isAllowed) {
        seed[CRUD_TYPE.READ] = 1;
      }
      seed[permissionType] = +isAllowed;
    }

    const index = newPermissionList.findIndex(
      ({ moduleId: modId, permissionId: perId }) =>
        modId === moduleId && perId === permissionId
    );

    console.log(index);

    if (index === -1) {
      setActivePermissionList([
        ...newPermissionList,
        {
          ...defaultAccess,
          ...seed,
        },
      ]);
    } else {
      const newList = [...newPermissionList];
      newList[index] = {
        ...defaultAccess,
        ...newList[index],
        ...seed,
      };
      setActivePermissionList(newList);
      console.log(newList);
    }
  };

  const onSave = () => {
    console.log("save", activePermissionList);

    client
      .post("/permissions/create-permissions", {
        permissions: activePermissionList,
        roleId: selectedRoleId,
      })
      .then((res) => {
        dispatch(setSuccess({ message: res.data.message }));
      })
      .finally(() => {
        setEditing(false);
      });
  };

  const onCancel = () => {
    console.log("cancel");
    setEditing(false);
  };

  const handleAddNewRole = () => {
    client
      .post("/permissions/add-role", { title: roleInput, projectId })
      .then((res) => {
        // const updatedRolesList = [...rolesList, roleInput];
        // setRoleList(updatedRolesList);
        setRoleInput(null);
        setAddNew(false);
        getRoleList();
      });
  };

  const handleDeleteRole = (id) => {
    client
      .delete("/permissions/remove-role", { params: { roleId: id } })
      .then((res) => {
        getRoleList();
        setSelectedRoleId(null);
        setEditing(false);
      });
  };

  const handleRoleSelect = (id) => {
    setSelectedRoleId(id);
    setLoadingPermissionList(true);
    client
      .get("/permissions/permission-list", {
        params: {
          id,
        },
      })
      .then((res) => {
        console.log(res.data.result, "result console");
        setActivePermissionList([...res.data.result]);
      })
      .catch((err) => {
        console.log("ERROR: ", err);
        setSelectedRoleId(null);
      })
      .finally(() => {
        setLoadingPermissionList(false);
      });
  };

  console.log("activePermissionList: ", activePermissionList);

  // const overviewPermissions = activePermissionList[0]?.filter(
  //   ({ moduleId }) => moduleId === "1"
  // );
  // const schedulePermissions = activePermissionList[0]?.filter(
  //   ({ moduleId }) => moduleId === "2"
  // );
  // const vaultPermissions = activePermissionList[0]?.filter(
  //   ({ moduleId }) => moduleId === "3"
  // );

  console.log(activePermissionList, "AP");

  const getChecked = (
    moduleId,
    permissionId,
    permissionType,
    permissionSubId = null
  ) => {
    // overview read only
    if (moduleId === MODULE_TYPE.OVERVIEW && permissionId === 3) {
      const overviewRules = activePermissionList.filter(
        ({ moduleId: mId }) => mId === moduleId
      );
      if (overviewRules.length === 0) return true;
      return overviewRules.every(
        ({ create, update, delete: del }) => (create || update || del) === 0
      );
    } else if (moduleId === MODULE_TYPE.SCHEDULE && permissionId === 0) {
      const scheduleRules = activePermissionList.filter(
        ({ moduleId: mId }) => mId === moduleId
      );
      if (scheduleRules.length === 0) return false;
      return scheduleRules.every(
        ({ create, update, delete: del, read }) =>
          (create || update || del) === 0 && read === 1
      );
      return true;
    }

    const permission = activePermissionList.filter(
      ({ moduleId: mId, permissionId: pid }) =>
        +mId === +moduleId && +pid === +permissionId
    )[0];

    if (!permission) return false;
    if (permissionType === CRUD_TYPE.ALL) return !!permission.create;
    return permission[permissionType];
  };

  console.log("WHY: ", rolesList, selectedRoleId);

  const getDisableVault = () => {
    return false;
    const index = activePermissionList.findIndex(
      ({ moduleId, permissionId }) =>
        moduleId === MODULE_TYPE.VAULT && permissionId === 1
    );
    if (index === -1) return false;
    return activePermissionList[index].create || false;
    // return activePermissionLis
  };

  return (
    <div className="p-4">
      <div className="flex justify-between items-center">
        <div>
          {/* <div className="mon-500-16 text-black">Role names</div> */}
          <div className="mon-400-12 text-gray-500">
            Manage all project roles here
          </div>
        </div>
        <div className="flex gap-x-4">
          <CustomSearch
            style={{
              backgroundColor: "#F0F5FA",
            }}
            onChange={(e, v) => {
              console.log(v);
            }}
            placeholder="Search Roles"
          />
          <CustomButton
            icon="/images/icons/import.svg"
            iconSize="w-[15px] w-min-[15px]"
            iconPadding="ml-2.5"
            text="Import"
            onClick={() => {
              setImportRolesModal(true);
            }}
            className="mon-500-14 text-black border  border-gray  bg-white"
            height="40px"
            width="100px"
            // disabled={showError || addGoal}
            // icon="/images/icons/featured-thread-icon.svg"
          />
        </div>
      </div>
      <div className=" mt-8">
        <Row>
          <Col span={7}>
            <div className="mb-3 pr-4">
              {rolesList.map(({ id, title }, i) => (
                <div
                  key={i}
                  className={`flex justify-between items-center mb-2 ${
                    selectedRoleId === id ? "bg-primary-50" : ""
                  }  rounded-lg py-2.5 px-3.5 cursor-pointer`}
                  onClick={() => {
                    if (selectedRoleId === id) return;
                    handleRoleSelect(id);
                  }}
                >
                  <div
                    className={`mon-500-14 ${
                      selectedRoleId === id ? "text-primary-600" : "text-black"
                    }`}
                  >
                    {title}
                  </div>
                  {manageMembers?.update === 1 && (
                    <div
                      onClick={() => handleDeleteRole(id)}
                      className="w-[15px] h-[15px]"
                    >
                      <img
                        src="/images/icons/delete-blue-icon.svg"
                        alt=""
                        className="object-fit"
                      />
                    </div>
                  )}
                </div>
              ))}

              {!addNew ? (
                <>
                  {manageMembers?.update === 1 && (
                    <div
                      onClick={() => setAddNew(true)}
                      className="flex justify-start items-center gap-x-2 py-2.5 px-3.5 cursor-pointer"
                    >
                      <div className="w-5 h-5">
                        <img
                          src="/images/icons/big-grey-plus.svg"
                          alt=""
                          className="object-fill"
                        />
                      </div>
                      <div className="mon-500-14 text-gray-500">Add Role</div>
                    </div>
                  )}
                </>
              ) : (
                <CustomInputWithEdit
                  placeholder="Role name"
                  value={roleInput}
                  autoFocus
                  onChange={(e) => {
                    setRoleInput(e.target.value);
                  }}
                  onPressEnter={handleAddNewRole}
                  suffix={
                    roleInput && (
                      <div className="flex">
                        <div>
                          <img
                            src="/images/icons/input_save.svg"
                            className="mx-2 cursor-pointer"
                            onClick={handleAddNewRole}
                          />
                        </div>
                        <div>
                          <img
                            src="/images/icons/input_clear.svg"
                            className="mx-2 cursor-pointer"
                            onClick={() => {
                              setRoleInput(null);
                              setAddNew(false);
                            }}
                          />
                        </div>
                      </div>
                    )
                  }
                />
              )}
            </div>
          </Col>
          <Col span={17}>
            {(rolesList && rolesList.length === 0) ||
            selectedRoleId === null ? (
              <div>
                <div className="h-[calc(100vh-240px)] border-l border-gray-200 flex justify-center items-center">
                  <div className="flex flex-col gap-y-2.5 bg-primary-50 rounded-lg p-2 text-left w-80">
                    <div className="mon-600-12 text-gray-700">
                      {selectedRoleId === null
                        ? "Select a role or Add new role"
                        : "No roles added"}
                    </div>
                    <div className="mon-400-12 text-gray-500">
                      Create roles to manage permissions for all the team
                      members of this project.
                    </div>
                    {manageMembers?.update === 1 && (
                      <CustomButton
                        icon="/images/icons/plus.svg"
                        text="Create Role"
                        onClick={() => {
                          setAddNew(true);
                          // onCancel();
                          // dispatch(
                          //   updateSchedule({ key: "newSprintModal", value: true })
                          // );
                          // dispatch(
                          //   updateSchedule({ key: "newSprintScope", value: true })
                          // );
                        }}
                        className="mon-500-14 text-white  bg-primary-600"
                        height="40px"
                        width="300px"
                        // disabled={showError || addGoal}
                        // icon="/images/icons/featured-thread-icon.svg"
                      />
                    )}
                  </div>
                </div>
              </div>
            ) : (
              // <Spin spinning={loadingPermissionList}>
              <div className="border-l border-gray-200 overflow-y-scroll h-[calc(100vh-240px)]  pl-4">
                <div className="flex justify-between items-center">
                  <div className="mon-500-14 text-black">
                    Modules and role permissions
                  </div>
                  {manageMembers?.update === 1 && (
                    <>
                      {!editing && (
                        <div
                          onClick={() => setEditing(true)}
                          className="flex cursor-pointer justify-start items-center gap-x-2"
                        >
                          <img src="/images/icons/edit-blue.svg" alt="" />
                          <div className="mon-500-14 text-primary-700">
                            Edit Permissions
                          </div>
                        </div>
                      )}
                    </>
                  )}
                </div>
                <div
                  className={`mt-6 ${
                    editing ? "border-gray-200 border" : "bg-primary-25"
                  } rounded-xl p-3`}
                >
                  <div className="flex justify-start items-center gap-x-2">
                    <div className="mon-600-12 text-black">Overview</div>
                    {!editing && (
                      <div>
                        <img src="/images/icons/lock-black.svg" alt="" />
                      </div>
                    )}
                  </div>
                  <div className="mt-5 w-full">
                    <div className="flex justify-between pr-2 items-center">
                      {overviewTypes.map(
                        ({ permId, name, info, disabled, permType }) => (
                          <div
                            className="flex justify-start items-start gap-x-2"
                            key={permId}
                          >
                            <Checkbox
                              value={permId}
                              checked={getChecked(
                                MODULE_TYPE.OVERVIEW,
                                permId,
                                permType
                              )}
                              disabled={disabled || !editing}
                              onChange={(e) => {
                                handlePermissionChange(
                                  e.target.checked,
                                  MODULE_TYPE.OVERVIEW,
                                  permId,
                                  permType
                                );
                              }}
                            />
                            <div>
                              <div className="mon-500-12 text-gray-700">
                                {name}
                              </div>
                              <div className="mon-400-12 text-gray-500">
                                {info}
                              </div>
                            </div>
                          </div>
                        )
                      )}
                    </div>
                  </div>
                </div>
                <div
                  className={`mt-6 ${
                    editing ? "border-gray-200 border" : "bg-primary-25"
                  } rounded-xl p-3`}
                >
                  <div className="flex justify-start items-center gap-x-2">
                    <div className="mon-600-12 text-black">Schedule</div>
                    {!editing && (
                      <div>
                        <img src="/images/icons/lock-black.svg" alt="" />
                      </div>
                    )}
                  </div>
                  <div className="flex justify-start items-start gap-x-2 mt-5">
                    <Checkbox
                      value={1}
                      checked={getChecked(MODULE_TYPE.SCHEDULE, 0, 0)}
                      disabled={!editing}
                      onChange={(e) => {
                        handlePermissionChange(
                          e.target.checked,
                          MODULE_TYPE.SCHEDULE,
                          0
                        );
                      }}
                    />
                    <div>
                      <div className="mon-500-12 text-gray-700">View Only</div>
                      <div className="mon-400-12 text-gray-500">
                        Cannot add,edit or delete anything in schedule
                      </div>
                    </div>
                  </div>
                  <div className="grid grid-cols-3">
                    {allSchedulePermissions.map((p) => (
                      <div
                        // disabled={!editing}
                        key={p.id}
                        className="mt-5 w-full"
                      >
                        <div>
                          <div className="mon-600-12 text-black">{p.name}</div>
                          {allPermissionTypes.map((permission, index) => (
                            <div
                              className="flex justify-start items-center mt-3 gap-x-2"
                              key={permission.type}
                            >
                              <Checkbox
                                value={permission.type}
                                id={`schedule-${p.id}-${permission.type}`}
                                checked={getChecked(
                                  MODULE_TYPE.SCHEDULE,
                                  p.id,
                                  permission.type
                                )}
                                onChange={(e) => {
                                  handlePermissionChange(
                                    e.target.checked,
                                    MODULE_TYPE.SCHEDULE,
                                    p.id,
                                    permission.type
                                  );
                                }}
                                disabled={!editing}
                              />
                              <label
                                htmlFor={`schedule-${p.id}-${permission.type}`}
                                className="mon-500-12 text-gray-700 cursor-pointer"
                              >
                                {permission.name}
                              </label>
                            </div>
                          ))}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <div
                  className={`mt-6 ${
                    editing ? "border-gray-200 border" : "bg-primary-25"
                  } rounded-xl p-3`}
                >
                  <div className="flex justify-start items-center gap-x-2">
                    <div className="mon-600-12 text-black">Vault</div>
                    {!editing && (
                      <div>
                        <img src="/images/icons/lock-black.svg" alt="" />
                      </div>
                    )}
                  </div>
                  <div className="flex justify-start items-start gap-x-2 mt-5">
                    <Checkbox
                      value={1}
                      disabled={!editing}
                      onChange={(e) => {
                        handlePermissionChange(
                          e.target.checked,
                          MODULE_TYPE.VAULT,
                          1
                        );
                      }}
                    />
                    <div>
                      <div className="mon-500-12 text-gray-700">My Space</div>
                      <div className="mon-400-12 text-gray-500">
                        Only add, edit, delete access to user’s own Space folder
                      </div>
                    </div>
                  </div>
                  <div className="grid grid-cols-3">
                    {allVaultPermissions.map((p) => (
                      <div key={p.id} className="mt-5 w-full">
                        <div>
                          <div className="mon-600-12 text-black">{p.name}</div>
                          {p.options.map((permission) => (
                            <div
                              className="flex justify-start items-center mt-3 gap-x-2"
                              key={permission.name}
                            >
                              <Checkbox
                                checked={getChecked(
                                  MODULE_TYPE.VAULT,
                                  permission?.id || p.id,
                                  permission.type,
                                  permission?.id
                                )}
                                onChange={(e) => {
                                  handlePermissionChange(
                                    e.target.checked,
                                    MODULE_TYPE.VAULT,
                                    permission?.id || p.id,
                                    permission.type,
                                    permission.id
                                  );
                                }}
                                disabled={!editing || getDisableVault()}
                              />
                              <div className="mon-500-12 text-gray-700">
                                {permission.name}
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    ))}
                  </div>
                  {/* <div className="grid grid-cols-3">
                    {allSchedulePermissions.map((p) => (
                      <Checkbox.Group
                        disabled={!editing}
                        key={p.id}
                        className="mt-5 w-full"
                      >
                        <div>
                          <div className="mon-600-12 text-black">{p.name}</div>
                          {allPermissionTypes.map((permission) => (
                            <div
                              className="flex justify-start items-center mt-3 gap-x-2"
                              key={permission.id}
                            >
                              <Checkbox value={permission.id} />
                              <div className="mon-500-12 text-gray-700">
                                {permission.name}
                              </div>
                            </div>
                          ))}
                        </div>
                      </Checkbox.Group>
                    ))}
                  </div> */}
                </div>
                {editing && manageMembers && (
                  <div className="mt-5 flex items-center gap-x-6">
                    <CustomButton
                      text="Save Settings"
                      disabled={activePermissionList.length === 0}
                      onClick={onSave}
                      className={`border-2 border-gray-200 text-white ${
                        activePermissionList.length === 0
                          ? "bg-primary-100"
                          : "bg-primary-600"
                      } mon-500-16
                  `}
                      height="36px"
                      // width="110px"
                    />
                    <CustomButton
                      text="Cancel"
                      onClick={onCancel}
                      className="border-2 border-gray-200 text-black bg-white mon-500-16 "
                      height="36px"
                      width="110px"
                    />
                    <div
                      className="mon-500-16 text-error-600 cursor-pointer"
                      onClick={() => handleDeleteRole(selectedRoleId)}
                    >
                      Delete Role
                    </div>
                  </div>
                )}
              </div>
              // </Spin>
            )}
          </Col>
        </Row>
      </div>
      {importRolesModal && (
        <ImportRolesModal
          visible={importRolesModal}
          onCancel={() => setImportRolesModal(false)}
          onApply={() => {
            getRoleList();
            setImportRolesModal(false);
          }}
        />
      )}
    </div>
  );
};

const TeamMembersRole = () => {
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.userDetails);
  const { selectedProject, overview } = useAppSelector(
    (state) => state.projects
  );
  const manageMembers = overview?.[2]?.[0];

  const getTeamMembersInfo = () => {
    dispatch(getAllMembers({ projectId: selectedProject, userId: user.id }));
  };

  const { memberList } = useAppSelector((state) => state.settings);

  const [roleList, setRoleList] = useState([]);
  const { projectId } = useParams();

  useEffect(() => {
    client
      .get("permissions/role-list", {
        params: {
          projectId,
        },
      })
      .then((res) => {
        setRoleList(res.data.result);
      });
  }, []);

  const [modalState, setModalState] = useState({
    memberId: null,
    roleId: null,
  });

  const [memberRoles, setMemberRoles] = useState([]);

  const getMemberWithRoles = async () => {
    try {
      const response = await client.get(
        `/settings/get-all-members?projectId=${selectedProject}`
      );

      setMemberRoles(response.data.result);
    } catch (error) {
      console.log(error);
      //return error;
    }
  };

  useEffect(() => {
    // getMemberWithRoles();
    getTeamMembersInfo();
  }, []);

  const [selectedMemberList, setSelectedMemberList] = useState([]);

  const handleCheckMember = (id, checked) => {
    const memberIndex = selectedMemberList.findIndex(
      ({ id: memberId }) => id === memberId
    );
    const members = [...selectedMemberList];
    members[memberIndex] = {
      ...members[memberIndex],
      checked,
    };
    setSelectedMemberList(members);
  };

  const [addMemberModal, setAddMemberModal] = useState({
    visible: false,
  });

  const onSubmit = () => {
    let list = selectedMemberList.map((member) => {
      const roleName = roleList.filter(({ id }) => +id === +member.roleId)[0]
        .title;

      return {
        ...member,
        roleName: roleName,
      };
    });
    list = list
      .filter(({ checked }) => checked)
      .map(({ id, roleId, roleName }) => {
        return {
          userId: id,
          roleId,
          roleName,
        };
      });

    client
      .put("/settings/member-assign-roles", {
        projectId: selectedProject,
        memberList: list,
      })
      .then((res) => {
        console.log(res.data);
      })
      .finally(() => {
        setAddMemberModal({ ...addMemberModal, visible: false });
        // getMemberWithRoles();
        getTeamMembersInfo();
        setSelectedMemberList([]);
      });
  };

  interface DataType {
    firstname: string;
    lastname: string;
    id: string;
    roleId: string;
  }

  const columns: ColumnsType<DataType> = [
    {
      title: "NAME",
      dataIndex: "name",
      key: "name",
      width: "20vw",
      render: (text, { firstname, lastname }) => (
        <div className="mon-400-12 text-gray-500">
          {firstname} {lastname}
        </div>
      ),
    },
    {
      title: "DATE ADDED",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text) => (
        <div className="mon-400-12 text-gray-500">
          {dayjs(text).format("DD MMM YYYY")}
        </div>
      ),
    },
    {
      title: "LAST ACTIVE",
      dataIndex: "lastActive",
      key: "lastActive",
      render: (text) => (
        <div className="mon-400-12 text-gray-500">
          {text ? dayjs(text).format("hh mm a DD/MM/YY") : ""}
        </div>
      ),
    },
  ];

  const renderDataSource = (roleId) => {
    return memberList.filter(({ roleId: id }) => +id === +roleId);
  };

  const [inviteMembers, setInviteMembers] = useState(false);

  return (
    <div className="p-4">
      <div className="flex justify-between items-center">
        <div>
          {/* <div className="mon-500-16 text-black">Role names</div> */}
          <div className="mon-400-12 text-gray-500">
            Manage your team members and their role permissions here
          </div>
        </div>
        <div className="flex justify-end items-center gap-x-2">
          <CustomSearch
            style={{
              backgroundColor: "#F0F5FA",
            }}
            placeholder="Search Roles"
          />
          {manageMembers?.update === 1 && (
            <CustomButton
              icon="/images/icons/plus-black.svg"
              text="Invite Members"
              onClick={() => {
                setModalState({
                  memberId: null,
                  roleId: null,
                });
                setInviteMembers(true);

                //navigate("/invite-members");
                // setAddNew(true);
                // onCancel();
                // dispatch(
                //   updateSchedule({ key: "newSprintModal", value: true })
                // );
                // dispatch(
                //   updateSchedule({ key: "newSprintScope", value: true })
                // );
              }}
              className="mon-500-14 bg-white rounded-xl border border-gray-200"
              // height="40px"
              width="180px"
            />
          )}
        </div>
      </div>
      <div className=" mt-8">
        {Array.isArray(roleList) && roleList.length === 0 ? (
          <Row>
            <Col span={24}>
              <div className="h-[calc(100vh-200px)] flex justify-center items-center">
                <div className="flex flex-col gap-y-2.5 bg-primary-50 rounded-lg p-2 text-left w-80">
                  <div className="mon-600-12 text-gray-700">
                    No members assigned
                  </div>
                  <div className="mon-400-12 text-gray-500">
                    Create a project role first to assign members to the roles
                    created
                  </div>
                  {manageMembers?.update === 1 && (
                    <CustomButton
                      icon="/images/icons/plus.svg"
                      text="Create Role"
                      onClick={() => {
                        setAddMemberModal({
                          ...addMemberModal,
                          visible: true,
                        });
                      }}
                      className="mon-500-14 text-white  bg-primary-600"
                      height="40px"
                      width="300px"
                      // disabled={showError || addGoal}
                      // icon="/images/icons/featured-thread-icon.svg"
                    />
                  )}
                </div>
              </div>
            </Col>
            {/* <Col span={7}>
            <div className="mb-3 pr-4"></div>
            </Col>
          <Col span={17}></Col> */}
          </Row>
        ) : (
          <div className="h-[calc(100vh-200px)] overflow-y-scroll">
            {Array.isArray(roleList) &&
              roleList.map((role) => (
                <Row key={role.id} className="border-b border-gray-200">
                  <Col span={10}>
                    <div className=" pl-2.5 pt-6">
                      <div className="mon-500-14 text-gray-900">
                        {role.title}
                      </div>
                      <div
                        className="mon-500-14 text-primary-700 mt-3 cursor-pointer"
                        onClick={() => dispatch(changeTab("3"))}
                      >
                        View role settings
                      </div>
                      <div></div>
                    </div>
                  </Col>

                  <Col span={14}>
                    <div className=" flex justify-center pb-4 items-center">
                      <Table
                        columns={columns}
                        dataSource={renderDataSource(role.id)}
                        className={`table-shadow custom-roles-table w-full min-h-[300px] table-min-height`}
                        footer={() => <></>}
                        pagination={false}
                      />
                      {/* <CustomTable
                        columns={columns}
                        data={renderDataSource(role.id)}
                        className={`table-shadow custom-roles-table w-full min-h-[300px]`}
                        customFooter={() => <></>}
                        //pagination={false}
                        current={1}
                        pageSize={10}
                        setCurrent={() => console.log("sdvsd")}
                        setPageSize={() => console.log("vsfv")}
                      /> */}
                    </div>

                    <div>
                      {manageMembers?.update === 1 && (
                        <CustomButton
                          text="Assign more members"
                          icon="/images/icons/plus-blue.svg"
                          onClick={() => {
                            setModalState({
                              ...modalState,
                              roleId: role.id,
                            });
                            // setAddMemberModal({
                            //   ...addMemberModal,
                            //   visible: true,
                            // });
                            setInviteMembers(true);
                          }}
                          className=" text-primary-700 bg-transparent mon-500-14"
                          height="40px"
                          width="100%"
                        />
                      )}
                    </div>
                  </Col>
                </Row>
              ))}
          </div>
        )}
      </div>
      {/* <CustomModal
        title={<p className="mon-400-12 text-gray-500">Add Members</p>}
        width={"724px"}
        visible={addMemberModal.visible}
        body={
          <div className="h-[48.189386056191466vh] overflow-y-scroll p-4">
            <p className="mon-500-14 text-gray-700">Select members</p>
            <div>
              <div className="grid grid-cols-6 gap-x-4 mt-1.5">
                <div className="col-span-4">
                  <CustomSelectWithInlineSearch
                    value={modalState.memberId}
                    margin="mt-0"
                    group={false}
                    placeholder="Search member"
                    options={memberList?.map((s, i) => {
                      return {
                        id: s.id,
                        value: s.id,
                        render: () => (
                          <div className="flex justify-between items-center">
                            <p className=" capitalize">
                              {s.firstname} {s.lastname}
                            </p>
                            {selectedMemberList?.findIndex(
                              ({ id }) => id === s.id
                            ) !== -1 && (
                              <img src="/images/icons/check.svg" alt="" />
                            )}
                          </div>
                        ),
                      };
                    })}
                    onChange={(e) => {
                      console.log(e);

                      const isSelectedAlready =
                        selectedMemberList.findIndex(({ id }) => id === e) !==
                        -1;
                      if (isSelectedAlready) {
                        const newList = [...selectedMemberList].filter(
                          ({ id }) => id !== e
                        );
                        setSelectedMemberList(newList);
                        return;
                      }

                      if (modalState.roleId) {
                        const newList = [...selectedMemberList];
                        const member = memberList.filter(
                          ({ id }) => +id === +e
                        )[0];

                        const newMember = {
                          ...member,
                          roleId: modalState.roleId,
                          checked: true,
                        };

                        newList.push(newMember);
                        setSelectedMemberList(newList);
                        setModalState({
                          ...modalState,
                          memberId: null,
                          roleId: null,
                        });
                      } else {
                        setModalState({
                          ...modalState,
                          memberId: e,
                        });
                      }
                    }}
                  />
                </div>
                <div className="col-span-2">
                  <CustomSelect
                    // border={false}
                    value={modalState.roleId}
                    placeholder="Select your sector"
                    backgroundColor="bg-primary-50 text-primary-700 border border-primary-50"
                    options={
                      Array.isArray(roleList) &&
                      roleList?.map((s) => {
                        return {
                          id: s.id,
                          value: s.id,
                          render: () => (
                            <div className="flex justify-between items-center">
                              <p>{s.title}</p>
                            </div>
                          ),
                        };
                      })
                    }
                    onChange={(e) => {
                      console.log(e);
                      if (modalState.memberId) {
                        const newList = [...selectedMemberList];
                        const member = memberList.filter(
                          ({ id }) => +id === +modalState.memberId
                        )[0];
                        const newMember = {
                          ...member,
                          roleId: e,
                          checked: true,
                        };
                        newList.push(newMember);
                        setSelectedMemberList(newList);
                        setModalState({
                          ...modalState,
                          memberId: null,
                          roleId: null,
                        });
                      } else {
                        setModalState({
                          ...modalState,
                          roleId: e,
                        });
                      }
                    }}
                  />
                </div>
              </div>
              <div className="mt-5">
                {selectedMemberList
                  // ?.filter((item) => selectedMemberList?.includes(item?.id))
                  ?.map((member, i) => (
                    <div
                      className="flex justify-between items-center"
                      key={member.id}
                    >
                      <div className="flex justify-start items-center gap-x-2">
                        <Checkbox
                          checked={member.checked}
                          onChange={(e) => {
                            handleCheckMember(member.id, e.target.checked);
                          }}
                        />
                        <img
                          src={member.profilePicture}
                          className=" object-cover w-10 h-10 rounded-full"
                        />
                        <div className="capitalize mon-500-14 text-gray-700">
                          {member.firstname} {member.lastname}
                        </div>
                      </div>
                      <div>
                        {member.roleId &&
                          roleList.filter(({ id }) => id === member.roleId)[0]
                            .title}
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        }
        footer={
          <div className="flex justify-between pt-8 pb-4 px-3">
            <CustomButton
              text="Cancel"
              onClick={() => {
                setAddMemberModal({
                  ...addMemberModal,
                  visible: false,
                });
              }}
              className="border-2 border-gray-200 text-black bg-white mon-500-16 "
              height="44px"
              width="49%"
            />
            <CustomButton
              text="Assign Role"
              onClick={onSubmit}
              className="mon-500-16 text-white  bg-primary-600 "
              height="44px"
              width="49%"
            />
          </div>
        }
        onCancel={() => {
          console.log("onCancel");
          setAddMemberModal({
            ...addMemberModal,
            visible: false,
          });
        }}
      /> */}
      <AddMembersModal
        visible={inviteMembers}
        onClose={() => {
          setInviteMembers(false);
          getTeamMembersInfo();
        }}
        prefiledValue={modalState}
      />
    </div>
  );
};

const tabData = (currentTab) => {
  switch (+currentTab) {
    case 1:
      return <DetailsTab />;
    case 2:
      return <NotificationsTab />;
    case 3:
      return <RolesTab />;
    case 4:
      return <TeamMembersRole />;
    case 5:
      return <ScheduleMetadata />;
  }
};

const Content = () => {
  const { currentTab } = useAppSelector((state) => state.settings);
  return (
    <div className="p-6">
      <div className="w-full min-h-[calc(100vh-136px)] bg-white rounded-xl shadow-hex">
        {tabData(currentTab)}
      </div>
    </div>
  );
};

const SettingScreen = () => {
  const { overview } = useAppSelector((state) => state.projects);
  const manageProjects = overview?.[1]?.[0];
  // if (manageProjects.read === 0) {
  //   return (

  //   )
  // }
  return (
    <CustomLayout page="settings">
      <div className="w-full h-full bg-main-background">
        <Content />
      </div>
    </CustomLayout>
  );
};

export default SettingScreen;
