import React from "react";
import CustomButton from "../../../Core/Common/CustomButton";
import { ProductOnboardingLayout } from "../../../Core/Layout/OnboardingLayouts";
import { useAppDispatch, useAppSelector } from "../../../Core/redux/hooks";
import { changeStep } from "../../../Core/redux/slices/onboarding.slice";

const Content = () => {
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.userDetails);
  return (
    <div className="p-6 w-full h-full">
      <div className="w-[81px] h-6">
        <img
          className="w-full h-full cursor-pointer"
          src="/images/icons/waddle-logo.svg"
          alt="waddle logo"
        />
      </div>
      <div className="pl-14 flex justify-start h-full items-center">
        <div>
          <h1 className="mon-500-20 text-gray-900 mb-6">
            We can’t wait for you to get started with Waddle 🐧
          </h1>
          <div className="flex justify-start items-center gap-x-2 text-show-animation mon-400-16 text-gray-500">
            <img
              src="/images/icons/frosty-icon.svg"
              className="min-h-[26px] min-w-[26px]"
              alt="frosty"
            />
            Hi {user.firstName} {user.lastName}! Let’s start by answering some
            questions, so we can curate your experience.
          </div>
          <CustomButton
            onClick={() => dispatch(changeStep(2))}
            text={`Let's Go!`}
            width="92px"
          />
        </div>
      </div>
    </div>
  );
};

const Sidebar = () => {
  return (
    <div className="w-full h-full max-h-screen">
      <img
        src="/images/penguins-welcome.svg"
        alt="/"
        className="object-cover dimensions-inherit"
      />
    </div>
  );
};

const WelcomeScreen = () => {
  return (
    <ProductOnboardingLayout content={<Content />} sidebar={<Sidebar />} />
  );
};

export default WelcomeScreen;
