import React, { useEffect, useState } from "react";
import { Row, Col, DatePicker } from "antd";
import CustomModal from "../../../Core/Common/CustomModal";
import CustomButton from "../../../Core/Common/CustomButton";
import SetupPhaseModal from "./SetupPhaseModal";
import { useAppDispatch, useAppSelector } from "../../../Core/redux/hooks";
import { getProjectPhases } from "../../../Core/redux/api/scheduleAPI";
import { useNavigate, useParams } from "react-router-dom";
import { client } from "../../../Core/utils/axiosClient";

const PhaseInfoItem = ({ text, backgroundColor, image, phase }) => {
  const [hover, setHover] = useState(false);
  const navigate = useNavigate();
  const { projectId } = useParams();
  const dispatch = useAppDispatch();

  const addProjectPhase = (phaseId, callback) => {
    console.log(phaseId);
    client
      .put("/settings/add-project-phase", {
        projectId,
        phaseId,
      })
      .then((resp) => {
        dispatch(getProjectPhases({ projectId }));
        console.log("added");
      });
  };
  const grayPhases = [
    {
      id: 1,
      text: "Ideation",
    },
    {
      id: 2,

      text: "Design",
    },
    {
      id: 3,

      text: "Development",
    },
    {
      id: 4,

      text: "Deployment",
    },
    {
      id: 5,

      text: "Maintenance",
    },
  ];

  const getButton = () => {
    let status = {
      text: "Add Phase",
      allowHover: false,
      allowClick: false,
      bgClassName: hover
        ? "bg-primary-600 text-primary-50"
        : "bg-primary-50 text-primary-600",
      arrowColor: hover ? "#ffff" : "#165ABF",
    };
    console.log(phase, "phaseIS");
    if (phase) {
      if (phase.isCompleted) {
        status = {
          text: "Completed",
          allowHover: false,
          allowClick: true,
          bgClassName: "bg-success-600 text-white",
          arrowColor: "#ffff",
        };
      } else if (phase.isOngoing) {
        status = {
          text: "In Progress",
          allowHover: false,
          allowClick: true,
          bgClassName: "bg-primary-600 text-white",
          arrowColor: "#ffff",
        };
      } else {
        status = {
          text: "Yet to begin",
          allowHover: false,
          allowClick: true,
          bgClassName: hover
            ? "bg-primary-600 text-primary-50"
            : "bg-primary-50 text-primary-600",
          arrowColor: hover ? "#ffff" : "#165ABF",
        };
      }
    } else {
      status = {
        text: "Add Phase",
        allowHover: false,
        allowClick: false,
        bgClassName: hover
          ? "bg-primary-600 text-primary-50"
          : "bg-primary-50 text-primary-600",
        arrowColor: hover ? "#ffff" : "#165ABF",
      };
    }

    return (
      <button
        className={` h-6 rounded-2xl text-xs font-medium ${status.bgClassName}`}
        onClick={() => {
          if (status.allowClick) {
            navigate(`/schedule/${projectId}?selectedPhase=${phase.phases}`);
          } else {
            const { id } = grayPhases.find((p) => p.text === text);
            addProjectPhase(id, () => status.allowClick === true);
          }
        }}
      >
        <div className="flex px-2 items-center">
          <div className="pr-1 ">{status.text}</div>

          <svg
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4.5 9L7.5 6L4.5 3"
              stroke={status.arrowColor}
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      </button>
    );
  };

  return (
    <div
      className="flex justify-between py-4 px-6 hover:bg-main-background"
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <div className="flex items-center">
        <div
          className="flex justify-center items-center w-11 h-11 rounded-lg "
          style={{ backgroundColor }}
        >
          <div className="w-[26.67px] h-[26.67px]">
            <img src={image} className="w-full h-full" />
          </div>
        </div>
        <div className="text-black text-xs font-medium ml-3 my-auto">
          {text}
        </div>
      </div>
      <div className="flex items-center">{getButton()}</div>
    </div>
  );
};

const PhasesInfo: React.FC = () => {
  const [phaseActive, setPhaseActive] = useState(false);
  const [setupPhaseModal, setSetupPhaseModal] = useState(false);

  const { phases } = useAppSelector((state) => state.schedule);

  const dispatch = useAppDispatch();
  const { overview } = useAppSelector((state) => state.projects);
  const managePhases = overview?.[2]?.[0] || {};

  const { projectId } = useParams();

  useEffect(() => {
    dispatch(getProjectPhases({ projectId }));
  }, [projectId]);

  const phaseAlreadySetup = phases.some((phase) => phase.startDate);

  const grayPhases = [
    {
      id: 1,
      icon: "/images/icons/bulb-icon.svg",
      text: "Ideation",
    },
    {
      id: 2,
      icon: "/images/icons/brush-icon.svg",
      text: "Design",
    },
    {
      id: 3,
      icon: "/images/icons/development-icon.svg",
      text: "Development",
    },
    {
      id: 4,
      icon: "/images/icons/deployment-icon.svg",
      text: "Deployment",
    },
    {
      id: 5,
      icon: "/images/icons/maintenance-icon.svg",
      text: "Maintenance",
    },
  ];

  return (
    <>
      <div className="shadow-box w-full h-[492px]  bg-white rounded-2xl">
        <div className="flex justify-between items-center pt-6 pb-3 px-7">
          <div
            className=" text-base font-medium text-black cursor-pointer"
            onClick={() => {
              setPhaseActive(!phaseActive);
            }}
          >
            Phases
          </div>
          {/* {phaseAlreadySetup && managePhases.update === 1 ? ( */}

          <button
            onClick={() => setSetupPhaseModal(true)}
            className="shadow-box border-1 w-[129px] h-10 bg-primary-600 rounded-xl capitalize text-white text-sm font-medium cursor-pointer"
          >
            {!phaseAlreadySetup ? "Setup phases" : "Edit"}
          </button>
        </div>
        {phaseAlreadySetup ? (
          <div>
            <PhaseInfoItem
              text="Ideation"
              image="/images/icons/bulb-icon.svg"
              backgroundColor="#FFDB01"
              phase={phases.find((p) => +p.id === 1)}
            />
            <PhaseInfoItem
              text="Design"
              image="/images/icons/brush-icon.svg"
              backgroundColor="#D05439"
              phase={phases.find((p) => +p.id === 2)}
            />
            <PhaseInfoItem
              text="Development"
              image="/images/icons/development-icon.svg"
              backgroundColor="#2175E1"
              phase={phases.find((p) => +p.id === 3)}
            />
            <PhaseInfoItem
              text="Deployment"
              image="/images/icons/deployment-icon.svg"
              backgroundColor="#33A553"
              phase={phases.find((p) => +p.id === 4)}
            />
            <PhaseInfoItem
              text="Maintenance"
              image="/images/icons/maintenance-icon.svg"
              backgroundColor="#6536ED"
              phase={phases.find((p) => +p.id === 5)}
            />
          </div>
        ) : (
          <div className="opacity-[0.2] px-7">
            {grayPhases.map((p) => (
              <div className="flex pt-8 " key={p.id}>
                <div className="flex justify-center items-center w-11 h-11 rounded-lg bg-gray-300">
                  <div className="w-[26.67px] h-[26.67px]">
                    <img src={p.icon} className="w-full h-full object-cover" />
                  </div>
                </div>
                <div className="text-black text-xs font-medium ml-3 my-auto">
                  {p.text}
                </div>
              </div>
            ))}

            {/* <div className="flex pt-9 pr-7 my-auto">
              <button className="w-[104px] h-6 bg-primary-50 rounded-2xl ">
                <div className="flex justify-start">
                  <div className="flex pl-2 text-xs text-primary-600 font-medium">
                    Not Added
                  </div>
                  <div className="flex w-4 h-4 pl-1">
                    <img
                      src="/images/icons/chevron-right.svg"
                      className="w-full h-full fill-white"
                    />
                  </div>
                </div>
              </button>
            </div> */}
          </div>
        )}
      </div>
      <SetupPhaseModal
        visible={setupPhaseModal}
        onCancel={() => setSetupPhaseModal(false)}
        onOk={() => setSetupPhaseModal(false)}
      />
    </>
  );
};

export default PhasesInfo;
