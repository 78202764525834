import React from "react";
import CustomModal from "../../../Core/Common/CustomModal";
import CustomButton from "../../../Core/Common/CustomButton";
import { useAppDispatch, useAppSelector } from "../../../Core/redux/hooks";
import { updateGoal } from "../../../Core/redux/api/scheduleAPI";
import { resetSchedule } from "../../../Core/redux/slices/schedule.slice";
import { useParams } from "react-router-dom";
import { getGoalsBySprint } from "../../../Core/redux/api/scheduleAPI";

type DeleteSubTaskModalProps = {
  visible: boolean;
  onCancel: () => void;
  //onOk: () => void;
};

const DeleteSubTaskModal = ({ visible, onCancel }: DeleteSubTaskModalProps) => {
  const { newSubTaskModal, selectedSprint } = useAppSelector(
    (state) => state.schedule
  );
  console.log(newSubTaskModal.subtask, "subtask");
  const dispatch = useAppDispatch();
  const { projectId } = useParams();

  const handelDelete = async () => {
    await dispatch(
      updateGoal({
        isActive: 0,
        goalId: newSubTaskModal.subtask.goalId,
      })
    );
    dispatch(resetSchedule(["newSubTaskModal"]));
    onCancel();
    dispatch(getGoalsBySprint({ projectId, sprintId: selectedSprint.id }));
  };
  return (
    <CustomModal
      title={<p className="mon-500-16 text-error-600">Delete subtask</p>}
      width={"524px"}
      visible={visible}
      body={
        <div className="max-h-[38.189386056191466vh] overflow-y-scroll">
          <div className="mon-400-14 text-gray-500 pt-6 pb-4 px-5">
            Are you sure you want to this subtask?{"   "}
            <span className="underline"> This action cannot be undone.</span>
          </div>
        </div>
      }
      footer={
        <div className="flex justify-between pt-8 pb-4 px-3">
          <CustomButton
            text="Cancel"
            onClick={onCancel}
            className="border-2 border-gray-200 text-black bg-white mon-500-16 "
            height="44px"
            width="49%"
          />
          <CustomButton
            text="Delete subtask"
            onClick={handelDelete}
            className="mon-500-16 text-white  bg-error-600 "
            height="44px"
            width="49%"
          />
        </div>
      }
      onCancel={onCancel}
    />
  );
};

export default DeleteSubTaskModal;
