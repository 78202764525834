import { MentionsInput, Mention } from "react-mentions";
import React, { useRef, useState } from "react";
import CustomAvatar from "../../Core/Common/CustomAvatar";
import CustomButton from "../../Core/Common/CustomButton";
import Picker from "emoji-picker-react";
import { EmojiStyle } from "emoji-picker-react";
import { Divider, Dropdown, Menu, Progress, Tooltip, Upload, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import { useAppDispatch, useAppSelector } from "../../Core/redux/hooks";
import {
  resetConversations,
  updateConversations,
} from "../../Core/redux/slices/conversations.slice";
import ParseMentions from "./ParseMentions";
import { ObjectID } from "bson";
import axios from "axios";
import uniqid from "uniqid";
import { useParams } from "react-router-dom";
import { updateVault } from "../../Core/redux/slices/vault.slice";
import { saveComments } from "../../Core/redux/api/conversationAPI";
import { Attachments } from "./MainChatWindow";
import CustomEmojiPicker from "../../Core/Common/CustomEmojiPicker";
import UploadFromVault from "./Common/UploadFromVault";

const ConversationsChatInput = ({
  socket,
  comments = false,
}: {
  socket?: any;
  comments?: boolean;
}) => {
  const {
    editMessage: { edit, messageObject },
    //deleteMessage: { delete, deleteMessageObject },
    currentThread,
    currentThreadMessage,
    replyMessage,
    directMessageUsers,
    threadMembers,
    projectsWithThreads,
    threads,
  } = useAppSelector((state) => state.conversations);

  const { openCommentsModal } = useAppSelector((state) => state.vault);

  const [moreOptions, setMoreOptions] = useState(false);
  const [value, setValue] = useState(edit ? messageObject.message : "");
  const [isFileUploading, setIsFileUploading] = useState(false);
  const { projectId } = useParams();
  const [uploadFromVault, setUploadFromVault] = useState(false);
  const [timeoutId, setTimeoutId] = useState(null);

  React.useEffect(() => {
    if (edit) {
      setValue(messageObject.message);
      setMoreOptions(true);
    } else {
      setValue("");
    }
  }, [edit]);

  React.useEffect(() => {
    if (replyMessage.reply) {
      setMoreOptions(true);
    }
  }, [replyMessage]);
  const [emojiPanelOpen, setEmojiPanelOpen] = useState(false);
  const [fileList, setFileList] = useState([]);

  const [fileUploadedList, setFileUploadedList] = useState([]);

  const handleChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
    const fileData = [];

    console.log(newFileList);

    newFileList.forEach((file) => {
      const { name, size, type, lastModified, status } = file;

      if (status !== "done") return;

      file.response.forEach((xfile) => {
        const { fileUrl: url, thumbnail } = xfile;

        fileData.push({
          name,
          url,
          size,
          type,
          lastModified,
          savedInVault: false,
          thumbnail: thumbnail.fileUrl,
        });
      });
    });
    console.log(fileData, "file");
    setFileUploadedList(fileData);
  };

  // const handleFileUploaded = (response, file) => {
  //   const fileData = [];

  //   const { name, size, type, lastModified } = file;

  //   const { fileUrl: url } = response;

  //   fileData.push({
  //     name,
  //     url,
  //     size,
  //     type,
  //     lastModified,
  //   });

  // };

  let className = "flex items-center border-gray-200";
  if (moreOptions || edit || replyMessage.reply) {
    className = "";
  }
  if (value !== "") {
    if (edit) className = className + "border-[#FEC84B]";
    else className = className + "border-[#7DC7FF]";
  }

  const users = directMessageUsers.map((user) => {
    return {
      id: user.userId,
      display: user.firstname
        ? user.firstname + " " + user.lastname
        : user.email,
    };
  });

  const uploadRef = useRef();
  const uploadButton = useRef<HTMLButtonElement>(null);

  const onEmojiClick = (emojiObject) => {
    const { emoji } = emojiObject;
    const newText = `${value || ""} ${emoji}`;
    setValue(newText);
  };
  const dispatch = useAppDispatch();

  const { user } = useAppSelector((state) => state.userDetails);

  const userLocal = JSON.parse(localStorage.getItem("user"));

  // const uploadImage = async (options) => {
  //   // below file element is the file you uploaded
  //   const { onSuccess, onError, file, onProgress } = options;
  //   const fmData = new FormData();
  //   const config = {
  //     headers: {
  //       "content-type": "multipart/form-data",
  //       Authorization: "Bearer " + userLocal.token,
  //     },
  //     onUploadProgress: (event) => {
  //       onProgress({ percent: (event.loaded / event.total) * 100 });
  //     },
  //   };
  //   // create copy file and change the name when it is appending to data
  //   const data = new FormData();
  //   data.append("file", file, uniqid(`${file.name}-`)); // replace all the spaces in the file name with -
  //   const _file = data.get("file");
  //   fmData.append("image", _file);
  //   try {
  //     // then send the copy file to the server
  //     const res = await axios.post(
  //       process.env.REACT_APP_API_BASE_URL + "/v1/upload/storej",
  //       fmData,
  //       config
  //     );
  //     console.log("UPLOADED", res.data);
  //     onSuccess(res.data);
  //   } catch (err) {
  //     onError({ err });
  //   }
  // };

  const uploadImage = (options: any) => {
    // below file element is the file you uploaded
    setIsFileUploading(true);
    const { onSuccess, onError, file, onProgress } = options;
    const fmData = new FormData();
    const config = {
      headers: {
        "content-type": "multipart/form-data",
        Authorization: "Bearer " + userLocal.token,
      },
      onUploadProgress: (event) => {
        onProgress({ percent: (event.loaded / event.total) * 100 });
      },
    };
    console.log("FILE: ", file);
    // create copy file and change the name when it is appending to data
    const data = new FormData();
    data.append(
      "file",
      file,
      uniqid(`${file.name.split(".")[0]}-`, `.${file.name.split(".").at(-1)}`)
    ); // replace all the spaces in the file name with -
    const _file = data.get("file");
    fmData.append("image", _file);
    try {
      // then send the copy file to the server
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "/v1/upload/storej",
          fmData,
          config
        )
        .then((res) => {
          console.log("UPLOADED", res.data);
          onSuccess(res.data);
          setIsFileUploading(false);
        });
    } catch (err) {
      onError({ err });
    }
  };

  const sendMessage = () => {
    const notifyToUsers = threadMembers
      .filter((m) => m.userId !== user.id)
      .map((m) => {
        return { userId: m.userId };
      });
    if (isFileUploading) return;
    if (value?.toString().trim().length === 0 && fileList.length === 0) return;

    const { firstName, lastName, id, profilePicture, tenant_id } = user;
    const objectID = new ObjectID();

    const message = {
      _id: objectID.toString(),
      threadId: currentThread.threadId,
      message: value.trim(),
      senderName: `${firstName} ${lastName}`,
      senderId: id,
      createdAt: dayjs().utc().format(),
      senderProfilePicture: profilePicture,
      replyMessage: null,
      reactions: {},
      attachments: fileUploadedList,
      notifyToUsers,
      projectId: currentThread.projectId,
      threadName: currentThread.threadName,
      tenantId: tenant_id,
    };
    console.log(message, "message");

    if (replyMessage.reply) {
      message.replyMessage = {
        ...replyMessage.messageObject,
        replyMessage: undefined,
      };
    }
    socket.emit("message", message);

    const messageListCopy = [...currentThreadMessage];

    messageListCopy.push(message);

    dispatch(
      updateConversations({
        key: "currentThreadMessage",
        value: [...messageListCopy],
      })
    );

    if (projectId) {
      const newProjectsWithThreads = projectsWithThreads.map((project) => {
        if (currentThread.projectId == project.id) {
          let newThreads = [];
          if (project.threads) {
            newThreads = project.threads.map((thread) => {
              if (thread.threadId == currentThread.threadId) {
                return { ...thread, messages: [...messageListCopy] };
              }
              return thread;
            });
          }
          return { ...project, threads: newThreads };
        }
        return project;
      });
      dispatch(
        updateConversations({
          key: "projectsWithThreads",
          value: newProjectsWithThreads,
        })
      );
    } else {
      const newThreads = threads.map((thread) => {
        if (thread.threadId == currentThread.threadId) {
          return { ...thread, messages: [...messageListCopy] };
        }
        return thread;
      });
      dispatch(
        updateConversations({
          key: "threads",
          value: newThreads,
        })
      );
    }

    // setMessageList(messageListCopy);

    setValue("");
    setFileList([]);
    setFileUploadedList([]);
    dispatch(resetConversations(["replyMessage"]));
    // scrollToChatBottom();
    setEmojiPanelOpen(false);
  };

  const editMessage = () => {
    if (value?.toString().trim().length === 0) return;

    const message = {
      messageId: messageObject._id,
      threadId: currentThread.threadId,
      message: value.trim(),
    };

    const editMessage = (oldMessages) => {
      const newMessages = oldMessages.map((m) => {
        if (m._id === messageObject._id) {
          return {
            ...m,
            message: value,
          };
        }
        return m;
      });
      return newMessages;
    };

    socket.emit("editMessage", message);

    if (currentThread.type === "private") {
      const users = directMessageUsers.map((user) => {
        if (+user.userId === +currentThread.userId) {
          const message = editMessage(user.messages);
          return { ...user, messages: [...message] };
        }
        return user;
      });

      dispatch(
        updateConversations({
          key: "directMessageUsers",
          value: [...users],
        })
      );
    } else {
      const messageListCopy = [...currentThreadMessage];

      const edittedMessage = editMessage(messageListCopy);

      dispatch(
        updateConversations({
          key: "currentThreadMessage",
          value: [...edittedMessage],
        })
      );
    }

    // setMessageList(messageListCopy);
    setValue("");
    dispatch(resetConversations(["replyMessage"]));
    // scrollToChatBottom();
    setEmojiPanelOpen(false);
  };

  const sendPrivateMessage = () => {
    console.log(isFileUploading, "xxxxxxxx", "isFile");
    if (isFileUploading) return;
    if (value?.toString().trim().length === 0 && fileList.length === 0) return;

    const { firstName, lastName, id, profilePicture, tenant_id } = user;
    const objectID = new ObjectID();

    const message = {
      _id: objectID.toString(),
      //threadId: currentThread.threadId,
      message: value.trim(),
      senderName: `${firstName} ${lastName}`,
      senderId: id,
      reciverId: currentThread.userId,
      createdAt: dayjs().utc().format(),
      senderProfilePicture: profilePicture,
      replyMessage: null,
      reactions: {},
      attachments: fileUploadedList,
      tenantId: tenant_id,
    };

    if (replyMessage.reply) {
      message.replyMessage = {
        ...replyMessage.messageObject,
        replyMessage: undefined,
      };
    }
    //console.log(currentThread.userId);
    socket.emit("privateMessage", message);

    console.log(message, "privateMessage");

    const users = directMessageUsers.map((user) => {
      if (+user.userId === +currentThread.userId) {
        return { ...user, messages: [...user.messages, message] };
      }
      return user;
    });

    dispatch(
      updateConversations({
        key: "directMessageUsers",
        value: [...users],
      })
    );
    // setMessageList(messageListCopy);
    setValue("");
    setFileList([]);
    setFileUploadedList([]);
    dispatch(resetConversations(["replyMessage"]));
    // scrollToChatBottom();
    setEmojiPanelOpen(false);
  };

  const sendCommentsMessage = async () => {
    console.log("clicked");
    if (value?.toString().trim().length === 0 && fileList.length === 0) return;

    const { firstName, lastName, id, profilePicture } = user;
    const objectID = new ObjectID();

    const message = {
      _id: objectID.toString(),
      //threadId: currentThread.threadId,
      docId: openCommentsModal.docId,
      docType: openCommentsModal.docType,

      message: value.trim(),
      senderName: `${firstName} ${lastName}`,
      senderId: id,

      createdAt: dayjs().utc().format(),
      senderProfilePicture: profilePicture,
      replyMessage: null,
      reactions: {},
      attachments: fileUploadedList,
      users: openCommentsModal.users.map((u) => {
        return {
          userId: u.id || u.userId,
        };
      }),
      name: openCommentsModal.name,
    };

    if (replyMessage.reply) {
      message.replyMessage = {
        ...replyMessage.messageObject,
        replyMessage: undefined,
      };
    }
    //console.log(currentThread.userId);
    // socket.emit("privateMessage", message);
    const oldComments = openCommentsModal.comments;
    const newModelMeta = {
      ...openCommentsModal,
      comments: [...oldComments, message],
    };

    dispatch(
      updateVault({
        key: "openCommentsModal",
        value: newModelMeta,
      })
    );
    // setMessageList(messageListCopy);
    setValue("");
    setFileList([]);
    setFileUploadedList([]);
    dispatch(resetConversations(["replyMessage"]));
    // scrollToChatBottom();
    setEmojiPanelOpen(false);
    await dispatch(saveComments({ message }));
  };

  const handleUserTyping = (isTyping) => {
    if (comments) return;
    const { firstName, lastName } = user;

    const payload = {
      isTyping,
      projectId: 1,
      threadId:
        currentThread.type === "group"
          ? currentThread.threadId
          : currentThread.userId,
      userName: `${firstName} ${lastName}`,
      userId: user.id,
      threadType: currentThread.type,
    };

    socket.emit("user-typing", payload);
  };

  React.useEffect(() => setValue(""), [currentThread]);

  const handelUploadFromVault = (arr) => {
    const newArr = arr.map((a, i) => {
      const file = {
        uid: `${a.name}-${i}`,
        name: a.name,
        status: "done",
        url: a.url,
        thumbUrl: a.thumbnail,
      };
      return file;
    });
    setFileList(newArr);
    const uploadArr = arr.map((a, i) => {
      const file = {
        name: a.name,
        url: a.url,
        size: a.fileSize,
        type: a.mimeType,
        lastModified: "",
        savedInVault: true,
        thumbnail: a.thumbnail,
      };
      return file;
    });
    setFileUploadedList(uploadArr);
  };

  const handleScroll = () => {
    const selectedElement = document.querySelector('[aria-selected="true"]');

    if (selectedElement) {
      selectedElement.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "nearest",
      });
    }
  };

  return (
    <div
      className={`min-h-[52px] w-full bg-white border transition-all  rounded-2xl ${className}`}
      onClick={() => setMoreOptions(true)}
    >
      {/* Message Reply section start */}
      {replyMessage.reply && (
        <div className="bg-primary-50 mx-2 my-2 px-1.5 py-1.5 rounded-lg relative">
          <div className="flex items-center">
            {" "}
            <img src="/images/icons/message-reply.svg" />
            <span className="ml-2 mon-400-12 text-gray-700 capitalize">
              {replyMessage.messageObject.senderName}
            </span>
          </div>

          <div className="ml-7 mt-2">
            <ParseMentions value={replyMessage.message} />
          </div>
          <div className="ml-7 mt-2">
            {replyMessage.messageObject.attachments &&
              replyMessage.messageObject.attachments.length > 0 &&
              replyMessage.messageObject.attachments.map((att, i) => (
                <Attachments
                  type={att.type}
                  url={att.url}
                  thumbnail={att.thumbnail}
                  key={i}
                  fileName={att.fileName}
                />
              ))}
          </div>
          <div
            className="absolute top-1 right-1 cursor-pointer"
            onClick={() => dispatch(resetConversations(["replyMessage"]))}
          >
            <img
              src="/images/icons/x-close.svg"
              className="min-h-[20px] min-w-[20px]"
            />
          </div>
        </div>
      )}

      <Upload
        listType="picture-card"
        multiple
        action={process.env.REACT_APP_API_URL + "/v1/upload"}
        headers={{ Authorization: "Bearer " + user.token }}
        customRequest={uploadImage}
        ref={uploadRef}
        fileList={fileList}
        onChange={handleChange}
        itemRender={(originNode, file, fileList, actions) => {
          const { response, status, type, percent } = file;
          const fileUrl =
            response && response.length > 0
              ? response[0]?.thumbnail?.fileUrl || ""
              : "";

          return (
            <div
              className="h-[56px] w-[56px] rounded-lg relative flex justify-center items-center"
              style={{
                background: `linear-gradient(0deg, rgba(16, 24, 40, 0.2), rgba(16, 24, 40, 0.2)), url(${fileUrl})`,
              }}
            >
              {percent > 100 && status !== "done" ? (
                <Progress
                  type="circle"
                  trailColor="#e6f4ff"
                  percent={percent}
                  strokeWidth={10}
                  width={40}
                  format={() => ""}
                  className="progress-upload"
                />
              ) : percent === 100 && status !== "done" ? (
                <Spin
                  indicator={<LoadingOutlined style={{ fontSize: 40 }} />}
                />
              ) : (
                <></>
              )}
              <div
                className="absolute bg-gray-900 h-5 w-5 rounded-full -right-1 -top-1 cursor-pointer flex justify-center items-center"
                onClick={actions.remove}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="15"
                  height="15"
                  fill="none"
                  viewBox="0 0 20 20"
                >
                  <path
                    stroke="#fff"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15 5L5 15M5 5l10 10"
                  ></path>
                </svg>
              </div>
            </div>
          );
        }}
        style={
          fileList.length === 0 && {
            display: "none",
          }
        }
        className={`${
          fileList.length === 0 ? "custom-upload-hide" : "custom-upload"
        } `}
      >
        {fileList.length === 0 ? (
          <button
            className=""
            style={{
              display: "none",
            }}
            ref={uploadButton}
          />
        ) : (
          <div>
            <img src="/images/icons/plus-gray.svg" />
          </div>
        )}
      </Upload>
      {uploadFromVault && (
        <UploadFromVault
          visible={uploadFromVault}
          onClose={() => setUploadFromVault(false)}
          onChange={(arr) => handelUploadFromVault(arr)}
        />
      )}
      {/* Message Reply section end */}
      {/* {replyMessage.reply && <Divider className="bg-[#EDF3F9] my-0 px-4" />} */}

      {/* main input box start */}

      <MentionsInput
        value={value}
        className="comments-textarea min-h-[52px]"
        onChange={(e) => {
          setValue(e.target.value);
          handleUserTyping(true);

          clearTimeout(timeoutId);

          setTimeoutId(
            setTimeout(() => {
              console.log("timeoutIdinset", timeoutId);
              handleUserTyping(false);
            }, 3000)
          );
        }}
        onBlur={() => {
          handleUserTyping(false);
        }}
        autoFocus={true}
        id="conversation-chat-input"
        onPaste={(e) => {
          function getFileFromPasteEvent(event) {
            const items = (
              event.clipboardData || event.originalEvent.clipboardData
            ).items;
            for (const index in items) {
              const item = items[index];
              if (item.kind === "file") {
                return item.getAsFile();
              }
            }
          }
          const file = getFileFromPasteEvent(e);
          if (!file) {
            return;
          } else {
            console.log(file, "onpaste file");
          }
        }}
        // onKeyUp={() => {
        //   handleUserTyping(false);
        // }}
        placeholder={comments ? "Add a comment" : "Message"}
        allowSuggestionsAboveCursor
        onKeyDown={(e) => {
          //handleUserTyping(true);
          if (e.key === "Enter" && (e.ctrlKey || e.shiftKey)) {
            setValue((val) => val + "\n");
            return true;
          }
          if (e.key === "Enter" && !isFileUploading) {
            dispatch(resetConversations(["unreadCount"]));
            e.preventDefault();
            comments
              ? sendCommentsMessage()
              : currentThread.type === "group"
              ? sendMessage()
              : sendPrivateMessage();
            return true;
          }
        }}
      >
        <Mention
          trigger="@"
          data={users}
          markup="@@@____id__^^____display__@@@^^^"
          style={{
            background: "linear-gradient(90deg, #7F56D9 0%, #9E77ED 100%)",
            opacity: 0.3,
            borderRadius: 5,
            color: "",
          }}
          //forceSuggestionsAboveCursor={true}
          displayTransform={(id, text) => `@${text}`}
          renderSuggestion={(
            entry,
            search,
            highlightedDisplay,
            index,
            focused
          ) => {
            // if (focused) {
            //   handleScroll();
            // }
            return (
              <div
                key={entry.display}
                onMouseEnter={(e) => e.stopPropagation()}
                className="flex  items-center"
              >
                <CustomAvatar title={entry.display} size={25} />
                <span className="ml-2 mon-400-12 text-gray-700 capitalize">
                  {entry.display}
                </span>
              </div>
            );
          }}
        />
      </MentionsInput>
      {!moreOptions && (
        <div className={`min-w-[20px] min-h-[20px] mr-4 `}>
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M18.3333 1.66797L9.16667 10.8346M18.3333 1.66797L12.5 18.3346L9.16667 10.8346M18.3333 1.66797L1.66667 7.5013L9.16667 10.8346"
              stroke="#667085"
              strokeWidth="1.67"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      )}

      {/* main input box end */}

      {/* bootom bar or more section start */}

      <div
        className={` justify-between items-center px-2 pb-2 ${
          moreOptions || edit || replyMessage.reply ? "flex" : "hidden"
        }`}
      >
        <div className="flex items-center gap-x-4 pl-2">
          <Tooltip title="Mention someone">
            <button onClick={() => setValue(`${value} @`)}>
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.3334 6.66745V10.8341C13.3334 11.4972 13.5968 12.133 14.0656 12.6019C14.5344 13.0707 15.1703 13.3341 15.8334 13.3341C16.4964 13.3341 17.1323 13.0707 17.6011 12.6019C18.07 12.133 18.3334 11.4972 18.3334 10.8341V10.0008C18.3332 8.11998 17.6969 6.29452 16.5278 4.82123C15.3586 3.34794 13.7255 2.31347 11.8939 1.88603C10.0623 1.45859 8.13997 1.66332 6.43949 2.46692C4.739 3.27053 3.36036 4.62575 2.52774 6.31222C1.69513 7.99869 1.4575 9.91723 1.8535 11.7559C2.2495 13.5945 3.25584 15.2451 4.70889 16.4393C6.16193 17.6335 7.97622 18.3011 9.85675 18.3334C11.7373 18.3657 13.5735 17.761 15.0667 16.6175M13.3334 10.0008C13.3334 11.8417 11.841 13.3341 10 13.3341C8.15908 13.3341 6.6667 11.8417 6.6667 10.0008C6.6667 8.15984 8.15908 6.66745 10 6.66745C11.841 6.66745 13.3334 8.15984 13.3334 10.0008Z"
                  stroke="#667085"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </Tooltip>

          <CustomEmojiPicker onChange={onEmojiClick} className="" />
          <Dropdown
            //getPopupContainer={(trigger) => trigger.parentElement}
            className=""
            overlay={
              <Menu className="w-[225px] rounded-lg custom-menu-dropdown  shadow-dropdown">
                <Menu.Item
                  className=" py-2.5 px-4 rounded-t-lg"
                  key="from-vault"
                >
                  <div
                    className="flex items-center"
                    onClick={() => setUploadFromVault(true)}
                  >
                    <img
                      src="/images/icons/folder-dark-border.svg"
                      className="w-4 h-4"
                      alt="add-new-sprint"
                    />
                    <span className="mon-400-12 ml-2 whitespace-nowrap  text-gray-700">
                      From Vault
                    </span>
                  </div>
                </Menu.Item>
                <Menu.Item
                  className="py-2.5 px-4 rounded-b-lg"
                  key="from-computer"
                >
                  <div
                    className="flex items-center "
                    onClick={() => {
                      if (uploadButton && uploadButton.current) {
                        uploadButton.current.click();
                      }
                    }}
                  >
                    <img
                      src="/images/icons/monitor-01.svg"
                      alt="add-new-sprint"
                      className="w-4 h-4"
                    />
                    <span className="mon-400-12 ml-2 whitespace-nowrap  text-gray-700">
                      Upload From your computer
                    </span>
                  </div>
                </Menu.Item>
              </Menu>
            }
            trigger={["click"]}
          >
            <Tooltip title="Attachment">
              <button>
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14.5834 4.38115V13.7513C14.5834 16.2826 12.5313 18.3346 10 18.3346C7.46872 18.3346 5.41669 16.2826 5.41669 13.7513V4.72352C5.41669 3.03599 6.78471 1.66797 8.47224 1.66797C10.1598 1.66797 11.5278 3.03599 11.5278 4.72352V13.7061C11.5278 14.5499 10.8438 15.2339 10 15.2339C9.15625 15.2339 8.47224 14.5499 8.47224 13.7061V5.54394"
                    stroke="#667085"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>
            </Tooltip>
          </Dropdown>
        </div>
        {edit ? (
          <div className="flex justify-end gap-x-3">
            <CustomButton
              text="Cancel"
              className="border border-gray-200 text-gray-700 bg-white mon-500-14"
              height="36px"
              width="81px"
              onClick={() => dispatch(resetConversations(["editMessage"]))}
            />
            <CustomButton
              text="Save"
              className="mon-500-14 text-white bg-primary-600 "
              height="36px"
              width="62px"
              onClick={() => {
                editMessage();
                dispatch(resetConversations(["editMessage"]));
              }}
            />
          </div>
        ) : (
          <button
            className={`min-w-[36px] min-h-[36px] flex items-center justify-center ${
              value !== "" ? "bg-primary-600 rounded-xl" : ""
            }`}
            onClick={() => {
              dispatch(resetConversations(["unreadCount"]));
              comments && !isFileUploading
                ? sendCommentsMessage()
                : currentThread.type === "group"
                ? sendMessage()
                : sendPrivateMessage();
            }}
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M18.3333 1.66797L9.16667 10.8346M18.3333 1.66797L12.5 18.3346L9.16667 10.8346M18.3333 1.66797L1.66667 7.5013L9.16667 10.8346"
                stroke={value !== "" ? "#ffff" : "#667085"}
                strokeWidth="1.67"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
        )}
      </div>

      {/* bootom bar or more section end */}
    </div>
  );
};

export default React.memo(ConversationsChatInput);
