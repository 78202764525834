import React from "react";
import CustomModal from "../../Core/Common/CustomModal";
import CustomButton from "../../Core/Common/CustomButton";

type DeleteSprintModalProps = {
  visible: boolean;
  onCancel: () => void;
  onOk: () => void;
};

const DeleteSprintModal = ({
  visible,
  onCancel,
  onOk,
}: DeleteSprintModalProps) => {
  return (
    <CustomModal
      title={<p className="mon-500-16 text-error-600">Delete sprint</p>}
      width={"524px"}
      visible={visible}
      body={
        <div className="max-h-[38.189386056191466vh] overflow-y-scroll">
          <div className="mon-400-14 text-gray-500 pt-6 pb-4 px-5">
            Are you sure you want to this sprint?
          </div>
        </div>
      }
      footer={
        <div className="flex justify-between pt-8 pb-4 px-3">
          <CustomButton
            text="Cancel"
            onClick={onCancel}
            className="border-2 border-gray-200 text-black bg-white mon-500-16 "
            height="44px"
            width="49%"
          />
          <CustomButton
            text="Yes, Delete"
            onClick={onOk}
            className="mon-500-16 text-white  bg-error-600 "
            height="44px"
            width="49%"
          />
        </div>
      }
      onCancel={onCancel}
    />
  );
};

export default DeleteSprintModal;
