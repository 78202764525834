import React from "react";
const ParseMentions = ({ value = "", className = "bg-primary-500" }) => {
  let newMessage = value;
  //id
  newMessage = newMessage
    .split(`@@@__`)
    .join(
      `<span class="mon-500-12 text-white ${className} px-2 rounded-full text-center h-[18px] mx-0.5 " data-user-id=`
    );
  //username
  newMessage = newMessage.split(`^^__`).join('">@');
  newMessage = newMessage.split(`@@@^^^`).join("</span>");
  newMessage = newMessage.replace(/\n\r?/g, `<br />`);
  return (
    <p
      className=" mon-400-12"
      dangerouslySetInnerHTML={{ __html: newMessage }}
    ></p>
  );
};

export default React.memo(ParseMentions);
