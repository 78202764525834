import { Dropdown, Input, Menu, Progress, Tabs } from "antd";
import TabPane from "antd/lib/tabs/TabPane";
import React, { useEffect, useState } from "react";
import PhaseIcon from "../svg/PhaseIcon";
import TaskTypeIcon from "../svg/TaskTypeIcon";
import CustomSelect from "./CustomSelect";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { changeProject } from "../redux/slices/projects.slice";
import CustomTable from "./CustomTable";
import MenuDropdown from "../../Modules/Conversations/Components/MenuDropdown";
import { getMyTasks } from "../redux/api/dashboardAPI";
import CustomButton from "./CustomButton";
import { compeleteResetProjectOnboarding } from "../redux/slices/projectOnboarding.slice";
import { useNavigate } from "react-router";
import CustomAvatar from "../Common/CustomAvatar";

const CustomTaskTable = ({
  title,
  tabs,
  tabData,

  dropdownData,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { user } = useAppSelector((state) => state.userDetails);
  const { projectList } = useAppSelector((state) => state.projects);
  const [currentPageNo, setCurrentPageNo] = useState(1);
  const [currentTab, setCurrentTab] = useState(1);
  const [limit, setLimit] = useState(10);
  const [currentSelectedProject, setCurrentSelectedProject] = useState({
    id: 0,
    value: "All",
  });
  const statusText = (statusId) => {
    switch (statusId) {
      case 1:
        return "To Do";
      case 2:
        return "In Progress";
      case 3:
        return "Completed";
      default:
        return "To Do";
    }
  };

  console.log(projectList, "projectList check");

  const statusStyles = {
    1: "bg-gray-100",
    2: "bg-primary-600",
    3: "bg-success-600",
  };

  const statusTextStyles = {
    1: "text-gray-700",
    2: "text-white",
    3: "text-white",
  };

  useEffect(() => {
    dispatch(
      getMyTasks({
        userId: user.id,
        status: currentTab,
        projectId: currentSelectedProject.id,
      })
    );
  }, [currentTab, currentSelectedProject]);

  const columns = [
    {
      title: () => (
        <div className="mon-500-10 pl-3 text-gray-500 uppercase">NAME</div>
      ),
      // width: "7%",
      dataIndex: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (text, record) => (
        <div className="flex justify-start items-center gap-x-2">
          <div className="bg-gray-50 min-w-[40px] h-10 rounded-[10px] flex items-center justify-center">
            <TaskTypeIcon type={+record.goalType} height="20px" width="20px" />
          </div>
          <div className="mon-500-12  text-gray-900 truncate">{text}</div>
        </div>
      ),
    },
    {
      title: () => (
        <div className="mon-500-10 pl-3 text-gray-500 uppercase">Project</div>
      ),
      // width: "7%",
      dataIndex: "projectName",
      sorter: (a, b) => a.projectName.localeCompare(b.projectName),
      render: (text, record) => (
        <div className="flex justify-start items-center gap-x-2">
          <div className="w-6 h-6">
            {/* <img
              src="/images/icons/projects-icon.svg"
              alt=""
              className="object-fit"
            /> */}
            <CustomAvatar
              src={record.projectImage}
              title={record.projectName}
              size={24}
            />
          </div>
          <div className="mon-400-12 text-gray-500">{text}</div>
        </div>
      ),
    },
    {
      title: () => (
        <div className="mon-500-10 pl-3 text-gray-500 uppercase">Phase</div>
      ),
      // width: "7%",
      sorter: (a, b) => a.phaseName.localeCompare(b.phaseName),
      dataIndex: "phaseName",
      render: (text, record) => (
        <div className="flex justify-start items-center gap-x-2">
          <PhaseIcon
            width="24px"
            height="24px"
            phaseId={+record.phaseId}
            icon
          />
          <div className="mon-400-12 text-gray-500">{text} Phase</div>
        </div>
      ),
    },
    {
      title: () => (
        <div className="mon-500-10 pl-3 text-gray-500 uppercase">Status</div>
      ),
      // width: "7%",
      dataIndex: "statusId",
      render: (text, record) => (
        <p className={`${statusTextStyles[+text]} mon-500-12 `}>
          <span
            className={`${
              statusStyles[+text]
            } rounded-2xl py-0.5 px-2 uppercase`}
          >
            {statusText(+text)}
          </span>
        </p>
      ),
    },
  ];
  return (
    <div className=" mb-6 pt-6 bg-white rounded-xl shadow-box ">
      <div className="flex justify-between items-center ml-6 ">
        <div className="mon-500-16 text-gray-900">{title}</div>
        <div className="max-w-max mr-6">
          <Dropdown
            overlay={
              <Menu
                className="w-[200px] rounded-lg custom-menu-dropdown  shadow-dropdown "
                style={{
                  border: "1px solid #EDF3F9",
                }}
              >
                <div className=" bg-white max-h-[202px] overflow-y-scroll rounded-lg">
                  <Menu.Item
                    className=" py-2.5 px-4 rounded-lg"
                    onClick={() =>
                      setCurrentSelectedProject({ id: 0, value: "All" })
                    }
                  >
                    <div className="mon-400-12 text-gray-500">All</div>
                  </Menu.Item>
                  {dropdownData ? (
                    dropdownData?.map((s) => (
                      <Menu.Item
                        className=" py-2.5 px-4 rounded-lg"
                        key={s.id}
                        onClick={() =>
                          setCurrentSelectedProject({
                            id: s.id,
                            value: s.title,
                          })
                        }
                      >
                        <div className="mon-400-12 text-gray-500">
                          {s.title}
                        </div>
                      </Menu.Item>
                    ))
                  ) : (
                    <></>
                  )}
                </div>
              </Menu>
            }
            trigger={["click"]}
            placement="bottomRight"
          >
            <div className="flex justify-center ">
              {currentSelectedProject.value}
              <button className="ml-2">
                <img src="/images/icons/chevron-down-black.svg" />
              </button>
            </div>
          </Dropdown>
        </div>
      </div>
      <div className="flex justify-start items-center gap-x-4 ml-6 mb-5 mt-3">
        <div
          onClick={() => setCurrentTab(1)}
          className={` cursor-pointer ${
            currentTab === 1
              ? "mon-500-12 text-primary-600 border-b-2 border-primary-600"
              : "mon-400-12 text-gray-500 border-b-2 border-transparent"
          }`}
        >
          To Do
        </div>
        <div
          onClick={() => setCurrentTab(2)}
          className={` cursor-pointer ${
            currentTab === 2
              ? "mon-500-12 text-primary-600 border-b-2 border-primary-600"
              : "mon-400-12 text-gray-500 border-b-2 border-transparent"
          }`}
        >
          In Progress
        </div>
        <div
          onClick={() => setCurrentTab(4)}
          className={` cursor-pointer ${
            currentTab === 4
              ? "mon-500-12 text-primary-600 border-b-2 border-primary-600"
              : "mon-400-12 text-gray-500 border-b-2 border-transparent"
          }`}
        >
          All
        </div>
      </div>

      {projectList.length === 0 ? (
        <div className="max-w-max max-h-max mx-auto pt-[62px] pb-[62px]">
          <div className="flex flex-col gap-y-2.5 bg-primary-50 rounded-lg p-2 text-left w-80">
            <div className="mon-600-12 text-gray-700">No tasks for you!</div>
            <div className="mon-400-12 text-gray-500">
              Looks like you have no tasks assigned, create a project to add
              your tasks!
            </div>
            <CustomButton
              icon="/images/icons/plus.svg"
              text="Create New Project"
              onClick={() => {
                dispatch(compeleteResetProjectOnboarding({}));
                navigate("/project-onboarding");
              }}
              className="mon-500-14 text-white  bg-primary-600"
              height="40px"
              width="300px"
            />
          </div>
        </div>
      ) : (
        <CustomTable
          // customPadding="p-3"
          className="custom-table cursor-pointer"
          //scroll={projects ? "calc(100vh - 520px)" : "0px"}
          columns={columns}
          data={tabData}
          dataCount={tabData.length}
          current={currentPageNo}
          pageSize={limit}
          setPageSize={setLimit}
          setCurrent={(e) => {
            setCurrentPageNo(e);
          }}
        />
      )}
      <>{console.log(tabData)}</>
    </div>
  );
};

export default CustomTaskTable;
