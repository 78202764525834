import React, { useCallback, useRef, useState } from "react";
import { InboxOutlined } from "@ant-design/icons";
import { Progress, Spin, UploadProps } from "antd";
import { message, Upload } from "antd";
import axios from "axios";
import uniqid from "uniqid";
import { LoadingOutlined } from "@ant-design/icons";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import {
  setFulfilled,
  setRejected,
  setRequest,
} from "../redux/slices/global.slice";

const { Dragger } = Upload;

const CustomDragAndDrop = ({ onUploaded, defaultFileList = [] }) => {
  const { user } = useAppSelector((state) => state.userDetails);
  const userLocal = user;

  const dispatch = useAppDispatch();

  const uploadImage = (options: any) => {
    // below file element is the file you uploaded
    const { onSuccess, onError, file, onProgress } = options;
    const fmData = new FormData();
    const config = {
      headers: {
        "content-type": "multipart/form-data",
        Authorization: "Bearer " + userLocal.token,
      },
      onUploadProgress: (event) => {
        onProgress({ percent: (event.loaded / event.total) * 100 });
      },
    };
    // create copy file and change the name when it is appending to data
    const data = new FormData();
    data.append(
      "file",
      file,
      uniqid(`${file.name.split(".")[0]}-`, `.${file.name.split(".").at(-1)}`)
    ); // replace all the spaces in the file name with -
    const _file = data.get("file");
    fmData.append("image", _file);
    try {
      // then send the copy file to the server
      dispatch(setRequest({ endPoint: "/storej" }));
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "/v1/upload/storej",
          fmData,
          config
        )
        .then((res) => {
          dispatch(setFulfilled({}));
          onSuccess(res.data);
        });
    } catch (err) {
      dispatch(setRejected({ error: err, from: "CustomDragAndDrop" }));
      onError({ err });
    }
  };

  const props: UploadProps = {
    //   name: "file",
    //   multiple: true,
    //   //action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
    //   onChange(info) {
    //     const { status } = info.file;
    //     if (status !== "uploading") {
    //       console.log(info.file, info.fileList);
    //     }
    //     if (status === "done") {
    //       message.success(`${info.file.name} file uploaded successfully.`);
    //     } else if (status === "error") {
    //       message.error(`${info.file.name} file upload failed.`);
    //     }
    //   },
    //   onDrop(e) {
    //     console.log("Dropped files", e.dataTransfer.files);
    //   },
  };
  const [fileList, setFileList] = useState(defaultFileList);
  const [fileUploadedList, setFileUploadedList] = useState([]);

  const handleChange = useCallback(
    ({ fileList: newFileList }) => {
      console.log(newFileList, "NewFileList");
      setFileList(newFileList);
      const fileData = [];

      newFileList.forEach((file) => {
        const { name, size, type, lastModified, status } = file;

        if (status !== "done") return;

        file.response.forEach((xfile) => {
          const { fileUrl: url, thumbnail } = xfile;

          fileData.push({
            name,
            url,
            size,
            type,
            lastModified,
            thumbnail: thumbnail.fileUrl,
          });
        });
      });
      console.log(fileData, "NewFileList222");

      if (defaultFileList) {
        fileData.push(...defaultFileList);
      }
      const uniqueArray = fileData.filter(
        (obj, index, self) => index === self.findIndex((t) => t.url === obj.url)
      );
      setFileUploadedList(uniqueArray);
      onUploaded(uniqueArray);
    },
    [fileList]
  );

  const ref = useRef();

  return (
    <Dragger
      customRequest={uploadImage}
      fileList={fileList}
      onChange={handleChange}
      className="custom-drag-drop"
      multiple
    >
      <div className="flex justify-center">
        <img src="/images/icons/file-upload.svg" className="w-14 h-14" />
      </div>

      <div className="mon-600-14 text-gray-500 pt-2">
        <span className="mon-600-14 text-primary-600 underline">
          Click to upload
        </span>{" "}
        or drag and drop
      </div>
    </Dragger>
  );
};

export default CustomDragAndDrop;
