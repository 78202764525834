import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import {
  Row,
  Col,
  Progress,
  Dropdown,
  Menu,
  Divider,
  Skeleton,
  Spin,
} from "antd";
import CustomLayout from "../../Core/Layout/CustomLayout";
import CustomSearch from "../../Core/Common/CustomSearch";
import CustomButton from "../../Core/Common/CustomButton";
import GoalsTable from "./GoalsTable";
import NewSprintModal from "./NewSprintModal";
import EditSprintModal from "./EditSprintModal";
import CustomFrostyMessage from "../../Core/Common/CustomFrostyMessage";
import NewScopeModal from "./NewScopeModal";
import EndPhaseModal from "./EndPhaseModal";
import RemovePhaseModal from "./RemovePhaseModal";
import { useAppDispatch, useAppSelector } from "../../Core/redux/hooks";
import {
  getAllSprintsByPhase,
  getProjectPhases,
  getSprintsByPhase,
  updatePhase,
} from "../../Core/redux/api/scheduleAPI";
import {
  setCurrentSelectedPhase,
  updateSchedule,
} from "../../Core/redux/slices/schedule.slice";
import NewGoalModal from "./NewGoalModal";
import SetupPhaseModal from "../Overview/Components/SetupPhaseModal";
import { getProjectDetatis } from "../../Core/redux/api/projectAPI";
import dayjs from "dayjs";
import CustomBadge from "../../Core/Common/CustomBadge";

const PhaseDropDownButton = ({
  title,
  color,
  currentPhase,
  setCurrentPhase,
  phase,
  setAllPhase,
}) => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const textColor: string = currentPhase === title ? "#F9FBFE" : color;
  const bgColor: string = currentPhase === title ? color : "#F9FBFE";
  const [endPhaseModal, setEndPhaseModal] = useState(false);
  const [removePhaseModal, setRemovePhaseModal] = useState(false);

  const { phases } = useAppSelector((state) => state.schedule);
  const dispatch = useAppDispatch();
  const { projectId } = useParams();

  const handleUpdatePhase = async (payload: object, callback?: () => void) => {
    await dispatch(
      updatePhase({ projectId, phaseId: phase.phaseId.toString(), ...payload })
    );
    if (callback && typeof callback === "function") callback();
    dispatch(getProjectPhases({ projectId }));
  };

  const handlePhaseStatus = (phases) => {
    const phaseStatus = {
      status: "0",
      statusText: "Yet to begin",
      color: "#C0CBD7",
      overlay: (
        <Menu className="w-[200px] rounded-lg custom-menu-dropdown  shadow-dropdown">
          <Menu.Item className="py-2.5 px-4 rounded-t-lg">
            <span
              className="font-normal pb-2 whitespace-nowrap text-sm text-gray-900"
              onClick={() =>
                handleUpdatePhase({ isOngoing: true, isCompleted: false })
              }
            >
              Start Phase
            </span>
          </Menu.Item>
          <Menu.Item className="py-2.5 px-4 rounded-b-lg">
            <span
              className="font-normal mb-2  whitespace-nowrap text-sm text-error-600 "
              onClick={() => setRemovePhaseModal(true)}
            >
              Remove Phase
            </span>
          </Menu.Item>
          <Divider className="my-2" />
          <Menu.Item className="py-2.5 px-4 rounded-b-lg">
            <span className="font-normal   whitespace-nowrap text-sm text-gray-500">
              View Details
            </span>
          </Menu.Item>
        </Menu>
      ),
    };

    if (phases.isCompleted) {
      phaseStatus.status = "2";
      phaseStatus.statusText = "Ended";
      phaseStatus.color = "#039855";
      phaseStatus.overlay = (
        <Menu className="w-[200px] rounded-lg custom-menu-dropdown  shadow-dropdown">
          <Menu.Item className=" py-2.5 px-4 rounded-t-lg">
            <span
              className="font-normal whitespace-nowrap text-sm text-gray-900"
              onClick={() => setEndPhaseModal(true)}
            >
              End Phase
            </span>
          </Menu.Item>
          <Divider className="my-0" />
          <Menu.Item className="py-2.5 px-4 rounded-b-lg">
            <span className="font-normal   whitespace-nowrap text-sm text-gray-500">
              View Details
            </span>
          </Menu.Item>
        </Menu>
      );
    }
    if (phases.isOngoing) {
      phaseStatus.status = "1";
      phaseStatus.statusText = "In Progress";
      phaseStatus.color = "#165ABF";
      phaseStatus.overlay = (
        <Menu className="w-[200px] rounded-lg custom-menu-dropdown  shadow-dropdown">
          <Menu.Item className=" py-2.5 px-4 rounded-t-lg">
            <span
              className="font-normal whitespace-nowrap text-sm text-gray-900"
              onClick={() => setEndPhaseModal(true)}
            >
              End Phase
            </span>
          </Menu.Item>
          <Divider className="my-0" />
          <Menu.Item className="py-2.5 px-4 rounded-b-lg">
            <span className="font-normal   whitespace-nowrap text-sm text-gray-500">
              View Details
            </span>
          </Menu.Item>
        </Menu>
      );
    }
    if (phase.notAdded) {
      phaseStatus.status = "3";
      phaseStatus.statusText = "Not added";
      phaseStatus.color = "#C0CBD7";
      phaseStatus.overlay = (
        <Menu className="w-[200px] rounded-lg custom-menu-dropdown  shadow-dropdown">
          <Menu.Item className=" py-2.5 px-4 rounded-t-lg">
            <span
              className="font-normal whitespace-nowrap text-sm text-gray-900"
              onClick={() => setEndPhaseModal(true)}
            >
              End Phase
            </span>
          </Menu.Item>
          <Divider className="my-0" />
          <Menu.Item className="py-2.5 px-4 rounded-b-lg">
            <span className="font-normal   whitespace-nowrap text-sm text-gray-500">
              View Details
            </span>
          </Menu.Item>
        </Menu>
      );
    }
    return phaseStatus;
  };

  useEffect(() => {
    if (
      searchParams.get("phase") &&
      phase.phases === searchParams.get("phase")
    ) {
      setCurrentPhase(searchParams.get("phase"));
      dispatch(setCurrentSelectedPhase(phase));
    }
  }, [searchParams.get("phase")]);

  return (
    <div className="relative">
      <div
        className="flex items-center px-3 py-1.5 rounded-xl "
        style={{
          backgroundColor: bgColor,
        }}
      >
        <button
          onClick={() => {
            setAllPhase();
            setCurrentPhase(title);
            dispatch(setCurrentSelectedPhase(phase));
            navigate(`/schedule/${projectId}?phase=${title}`);
          }}
        >
          <p
            className="pr-1 mon-500-14 "
            style={{
              color: textColor,
            }}
          >
            {title}
          </p>
        </button>
        <Dropdown
          getPopupContainer={(trigger) => trigger.parentElement}
          className=""
          overlay={handlePhaseStatus(phase).overlay}
          //placement="bottom"
          trigger={["click"]}
        >
          <svg
            width="12"
            height="12"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="cursor-pointer"
          >
            <path
              d="M4 6L8 10L12 6"
              stroke={textColor}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </Dropdown>
        <EndPhaseModal
          visible={endPhaseModal}
          onCancel={() => setEndPhaseModal(false)}
          onOk={() =>
            handleUpdatePhase({ isOngoing: false, isCompleted: true }, () =>
              setEndPhaseModal(false)
            )
          }
        />
        <RemovePhaseModal
          visible={removePhaseModal}
          onCancel={() => setRemovePhaseModal(false)}
          onOk={() =>
            handleUpdatePhase({ isActive: false, isCompleted: false }, () =>
              setRemovePhaseModal(false)
            )
          }
        />
      </div>
      <div
        className="mon-500-12 text-center  pt-2 absolute w-full "
        style={{
          color: handlePhaseStatus(phase).color,
        }}
      >
        {handlePhaseStatus(phase).statusText}
      </div>
    </div>
  );
};

const ScheduleScreen = () => {
  const [currentPhase, setCurrentPhase] = useState("");
  const [allPhase, setAllPhase] = useState(false);
  const [setupPhaseModal, setSetupPhaseModal] = useState(false);
  const { projectDetails } = useAppSelector((state) => state.overview);

  const { projectId } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const {
    phases,
    currentSelectedPhase,
    sprintsLoading,
    sprints,
    newSprintModal,
    newGoalModal,
    newScopeModal,
    phaseLoading,
    allSprints,
  } = useAppSelector((state) => state.schedule);
  useEffect(() => {
    dispatch(getProjectPhases({ projectId }));
  }, [projectId]);

  useEffect(() => {
    if (currentPhase === searchParams.get("phase")) {
      dispatch(
        getSprintsByPhase({
          projectId,
          phaseId: currentSelectedPhase.id,
        })
      );
    }
  }, [projectId, currentPhase]);

  useEffect(() => {
    const found = phases.findIndex(
      (p) => p.phases === searchParams.get("phase")
    );
    if (found === -1)
      navigate(`/schedule/${projectId}?phase=${phases[0].phases}`);
  }, [searchParams]);

  const [filteredSprints, setFilteredSprints] = useState([...sprints]);

  const handleSearch = (searchText) => {
    const inputData = searchText.toLowerCase();
    const allSprints = [...sprints];
    if (inputData === "") {
      setFilteredSprints(allSprints);
      return;
    }
    const filteredData = allSprints.filter((item) => {
      if (item.name !== null) {
        return item.name.toLowerCase().includes(inputData);
      }
    });
    setFilteredSprints(filteredData);
    return;
  };

  useEffect(() => {
    setFilteredSprints([...sprints]);
  }, [sprints]);

  const setNewSprintModal = (val) => {
    dispatch(updateSchedule({ key: "newSprintModal", value: val }));
  };

  const setNewGoalModal = (val) => {
    dispatch(updateSchedule({ key: "newGoalModal", value: val }));
  };
  const setNewScopeModal = (val) => {
    dispatch(updateSchedule({ key: "newScopeModal", value: val }));
  };

  const getGoalsTable = (phase) => {
    const { phases } = phase;
    switch (currentPhase) {
      case phases:
        return (
          // <Spin spinning={sprintsLoading}>
          <>
            {filteredSprints.map((sprint) => {
              return (
                <GoalsTable
                  key={sprint.id}
                  sprint={sprint}
                  projectId={projectId}
                />
              );
            })}
          </>
          // </Spin>
        );
      case "Maintenance":
        return (
          <div className="mt-4">
            <CustomFrostyMessage close shadow>
              <p className="mon-500-12 text-primary-600">
                <span className="text-[#6938EF]"> Maintenance phase </span> is
                not added for this project. Don’t worry, you can add it any
                time.
              </p>
            </CustomFrostyMessage>
            <div
              className="px-4 py-4 rounded-2xl mt-8 "
              style={{
                background:
                  "linear-gradient(268.45deg, #165ABF 34.33%, #2E90FA 100%)",
              }}
            >
              <div className="flex items-center">
                <img src="/images/icons/antarctica-logo.svg" />
                <p className="ml-2 mon-500-14 text-white">Connect with us!</p>
              </div>
              <p className=" mt-[18px] mon-600-12 text-white">
                What will you get?
              </p>
              <p className="mon-500-12 text-white mt-4">
                We help business do more valuable work, faster.With a team of
                experienced designers, coders and testers we are ready to take
                your product to the next level. Let’s start today!
              </p>
              <div className="bg-white h-10 w-[155px] rounded-xl justify-center mt-4 flex items-center">
                <p className="mon-500-14 text-primary-600">Connect now</p>
                <img
                  className=" transform  "
                  src="/images/icons/chevron-down.svg"
                  style={{
                    transform: "rotate(-90deg)",
                  }}
                />
              </div>
              <div className="flex justify-end mt-4">
                <img src="/images/icons/antarctica-logo-blue.svg" />
              </div>
            </div>
          </div>
        );
      default:
        return (
          <div className="flex  mt-14 gap-x-3">
            <CustomFrostyMessage shadow close>
              <p className="text-sm font-medium text-primary-600 ml-2">
                Looks like no phases are started for this project yet
              </p>
            </CustomFrostyMessage>
            <CustomButton
              text="Setup Phases"
              height="44px "
              width="147px"
              onClick={() => setSetupPhaseModal(true)}
            />
          </div>
        );
    }
  };

  useEffect(() => {
    if (allPhase) {
      phases.map(async (phase) => {
        await dispatch(getAllSprintsByPhase({ projectId, phaseId: phase.id }));
      });
    }
  }, [allPhase]);

  let diff = 0;
  if (dayjs(currentSelectedPhase.endDate)?.isValid()) {
    if (dayjs(currentSelectedPhase.endDate).isSame(dayjs(), "day")) {
      diff = 1;
    } else {
      diff = dayjs(currentSelectedPhase.endDate).diff(dayjs(), "days");
      diff = diff < 0 ? 0 : diff + 1;
    }
  }

  return (
    <CustomLayout page="schedule">
      <Row className="min-h-[calc(100vh-56px)]">
        <Col span={24} className="bg-main-background p-6">
          {/* schedule header section  */}
          <div className="flex justify-between items-center">
            <div className="flex items-center">
              <CustomSearch
                placeholder="Search tasks, sub tasks"
                setSearch={(e) => handleSearch(e)}
              />

              <div className="flex items-center justify-center ml-3 w-[110px] h-11 rounded-xl shadow-sm bg-white cursor-pointer">
                <p className="text-gray-500 mon-500-14">Type</p>
                <div className="pl-2 pt-0.5">
                  <img src="/images/icons/chevron-down-black.svg" />
                </div>
              </div>
              <div className="flex justify-center items-center  ml-3.5">
                <img
                  className="w-5 h-5"
                  src="/images/icons/Filters-lines.svg"
                />
                <p className="mon-500-14 ml-2.5  text-gray-500">Filter</p>
              </div>
            </div>
            <div className="bg-white flex items-center rounded-xl h-10 px-2.5">
              <img className="w-5 h-5" src="/images/icons/line-chart-up.svg" />
              <p className="text-gray-500 mon-500-14 px-3">Progress</p>
              <div className=" w-[279px]">
                <Progress
                  percent={+projectDetails.progress}
                  status="success"
                  strokeWidth={4}
                  showInfo={false}
                />
              </div>
              <p className="pl-3 mon-500-14 text-primary-600">
                {Math.round(+projectDetails.progress)}%
              </p>
            </div>
          </div>
          {/* schedule phase section  */}

          <div className="p-6 pb-12 bg-white rounded-2xl shadow-box mt-4">
            <div className="flex items-center justify-between">
              {phases.map((phase, i) => {
                if (i === 0) {
                  return (
                    <React.Fragment key={phase.id}>
                      <img src="/images/icons/sprint-blue.svg" alt="sprint" />
                      {/* //w-[3.3823529411764706vw] */}
                      <div className="w-full h-0 border-b-[1px] border-dashed border-gray-300" />
                      <PhaseDropDownButton
                        title={phase.phases}
                        color={phase.color}
                        currentPhase={allPhase ? phase.phases : currentPhase}
                        setCurrentPhase={setCurrentPhase}
                        phase={phase}
                        setAllPhase={() => setAllPhase(false)}
                      />
                    </React.Fragment>
                  );
                } else if (i === phases.length - 1) {
                  return (
                    <React.Fragment key={phase.id}>
                      {/* w-[5.9823529411764706vw] */}
                      <div className="w-full h-0 border-b-[1px] border-dashed border-gray-300" />
                      <img src="/images/icons/rounded-arrow.svg" alt="arrow" />
                      {/* w-[5.9823529411764706vw] */}
                      <div className="w-full h-0 border-b-[1px] border-dashed border-gray-300" />
                      <PhaseDropDownButton
                        title={phase.phases}
                        color={phase.color}
                        currentPhase={allPhase ? phase.phases : currentPhase}
                        setCurrentPhase={setCurrentPhase}
                        phase={phase}
                        setAllPhase={() => setAllPhase(false)}
                      />
                    </React.Fragment>
                  );
                } else {
                  return (
                    <React.Fragment key={phase.id}>
                      <div className="w-full  h-0 border-b-[1px] border-dashed border-gray-300" />
                      <img src="/images/icons/rounded-arrow.svg" alt="arrow" />
                      <div className="w-full h-0 border-b-[1px] border-dashed border-gray-300" />
                      <PhaseDropDownButton
                        title={phase.phases}
                        color={phase.color}
                        currentPhase={allPhase ? phase.phases : currentPhase}
                        setCurrentPhase={setCurrentPhase}
                        phase={phase}
                        setAllPhase={() => setAllPhase(false)}
                      />
                    </React.Fragment>
                  );
                }
              })}

              <div className="w-full h-0 border-b-[1px] border-dashed border-gray-300" />
              <div className=" h-5 pr-4 border-r-[1px] border-gray-300">
                <img
                  src="/images/icons/rounded-flag.svg"
                  className="h-5 w-5 min-w-[20px]"
                  alt="flag"
                />
              </div>
              <div className="ml-4">
                <PhaseDropDownButton
                  title="Maintenance"
                  color={"#C0CBD7"}
                  currentPhase={allPhase ? "Maintenance" : currentPhase}
                  setCurrentPhase={setCurrentPhase}
                  phase={{ isOngoing: 0, isCompleted: 0, notAdded: 1 }}
                  setAllPhase={() => setAllPhase(false)}
                />
              </div>
            </div>
          </div>

          {/* schedule progress section  */}
          <div className="mt-4 flex justify-between">
            {allPhase ? (
              <div className="flex items-center h-10">
                {phases.map((phase) => {
                  return (
                    <div className="flex items-center" key={phase.id}>
                      <div className=" w-[9vw] ">
                        <Progress
                          percent={+phase.phaseProgress}
                          status="success"
                          strokeWidth={4}
                          showInfo={false}
                          strokeColor={phase.color}
                        />
                      </div>
                      <p className="px-3 mon-500-14 text-gray-700">
                        {phase.phaseProgress}%
                      </p>
                    </div>
                  );
                })}
              </div>
            ) : (
              <div className=" flex items-center  h-10 ">
                <p className="text-gray-700 mon-500-14 pr-3">
                  {currentPhase || "Ideation"}
                </p>
                <div className=" w-[40vw]">
                  <Progress
                    percent={currentSelectedPhase?.phaseProgress}
                    status="success"
                    strokeWidth={4}
                    showInfo={false}
                  />
                </div>
                <p className="px-3 mon-500-14 text-gray-700">
                  {currentSelectedPhase?.phaseProgress}%
                </p>
                <img src="/images/icons/clock.svg" alt="clock" />
                <p className="text-gray-700 mon-500-14 pr-3 pl-2">
                  {diff} days remaining
                </p>
              </div>
            )}

            <div className="flex items-center">
              <CustomBadge
                dot
                text="Active"
                color="#027A48"
                className="mon-400-12 pt-0.5 mx-3"
              />
              <div className="flex">
                <img src="/images/icons/clock-black.svg" className="w-4 h-4 " />
                <div className="mon-400-12 text-gray-700 mx-3">
                  01 Jun - 22 Jun
                </div>
              </div>

              <CustomButton
                text="All Phases"
                onClick={() => {
                  dispatch(updateSchedule({ key: "allSprints", value: [] }));
                  setAllPhase(!allPhase);
                }}
                className="border border-white shadow-xs text-primary-blue bg-white mon-500-14 mr-3 "
                icon="/images/icons/bar-chart.svg"
                height="40px"
                width="133px"
              />
              <Dropdown
                //getPopupContainer={(trigger) => trigger.parentElement}
                className=""
                overlay={
                  <Menu className="w-[200px] rounded-lg custom-menu-dropdown  shadow-dropdown">
                    <Menu.Item className=" py-2.5 px-4 rounded-t-lg">
                      <div
                        className="flex items-center"
                        onClick={() => setNewSprintModal(true)}
                      >
                        <img
                          src="/images/icons/Sprint.svg"
                          className="w-4 h-4"
                          alt="add-new-sprint"
                        />
                        <span className="font-normal ml-2 whitespace-nowrap text-sm text-gray-900">
                          New Sprint
                        </span>
                      </div>
                    </Menu.Item>
                    <Menu.Item className="py-2.5 px-4 rounded-b-lg">
                      <div
                        className="flex items-center "
                        onClick={() => setNewScopeModal(true)}
                      >
                        <img
                          src="/images/icons/plus-circle.svg"
                          alt="add-new-sprint"
                          className="w-4 h-4"
                        />
                        <span className="font-normal  ml-2 whitespace-nowrap text-sm font-gray-900 text-pink-700">
                          New Scope
                        </span>
                      </div>
                    </Menu.Item>
                  </Menu>
                }
                trigger={["click"]}
              >
                <div
                  className={`${"bg-primary-600 text-white"} cursor-pointer flex justify-center items-center  mon-500-14 rounded-xl `}
                  style={{ width: "90px", height: "40px" }}
                >
                  <div className="flex justify-center items-center">
                    <img src="/images/icons/plus.svg" className="w-5 h-5" />
                    <p className={"ml-2.5"}>Add</p>
                  </div>
                </div>
              </Dropdown>
            </div>
          </div>
          {/* goals table  */}
          {allPhase ? (
            // <Spin spinning={sprintsLoading}>
            <div className="min-h-[calc(100vh-300px)]">
              {allSprints.length > 0 &&
                allSprints.map((sprint) => {
                  return (
                    <GoalsTable
                      key={sprint.id}
                      sprint={sprint}
                      projectId={projectId}
                      phaseColor={
                        phases.find((ph) => ph.id === sprint.phaseId).color
                      }
                    />
                  );
                })}
            </div>
          ) : (
            // </Spin>
            getGoalsTable(currentSelectedPhase)
          )}
        </Col>
      </Row>
      {newSprintModal && (
        <NewSprintModal
          visible={newSprintModal.visible}
          onCancel={() => {
            dispatch(updateSchedule({ key: "newSprintScope", value: false }));
            setNewSprintModal(false);
          }}
        />
      )}
      {newScopeModal && (
        <NewScopeModal
          visible={newScopeModal}
          onCancel={() => setNewScopeModal(false)}
        />
      )}
      {newGoalModal && (
        <NewGoalModal
          visible={newGoalModal}
          onCancel={() => {
            dispatch(updateSchedule({ key: "newGoalScope", value: false }));
            setNewGoalModal(false);
          }}
        />
      )}
      {setupPhaseModal && (
        <SetupPhaseModal
          visible={setupPhaseModal}
          onCancel={() => {
            setSetupPhaseModal(false);
          }}
          onOk={() => setSetupPhaseModal(false)}
        />
      )}
    </CustomLayout>
  );
};

export default ScheduleScreen;
