
import dayjs from "dayjs";
import isToday from "dayjs/plugin/isToday";
import isTomorrow from "dayjs/plugin/isTomorrow";
import isYesterday from "dayjs/plugin/isYesterday";
import relativeTime from "dayjs/plugin/relativeTime";
import utc from "dayjs/plugin/utc";
import timezone from 'dayjs/plugin/timezone'

dayjs.extend(isToday);
dayjs.extend(isYesterday);
dayjs.extend(isTomorrow);
dayjs.extend(relativeTime);
dayjs.extend(utc);
dayjs.extend(timezone)


dayjs().isToday(); // true

export const formatDateInRelativeTime = (date, isCompleted = 1) => {
  if (date === null || typeof date === "undefined")
    return {
      text: "-",
      className: "mon-400-12",
    };
  if (dayjs(date).isToday())
    return { text: "Today", className: "text-primary-600 mon-500-12" };
  if (dayjs(date).isTomorrow())
    return { text: "Tomorrow", className: "mon-400-12" };
  if (dayjs(date).isYesterday())
    return {
      text: "Yesterday",
      className: isCompleted ? "mon-400-12" : "mon-500-12 text-error-600",
    };

  return {
    text: dayjs(date).format("DD MMM"),
    className:
      dayjs().isAfter(date, "days") && !isCompleted
        ? "mon-500-12 text-error-600"
        : "mon-400-12",
  };
};

export const getRelativeTime = (dayjsObj) => {
  if (dayjsObj === null || typeof dayjsObj === "undefined" || dayjsObj === "-")
    return "-";
  return dayjsObj.fromNow();
};

// dateString eg: 2020-05-03 22:15:01
export const utcToLocal = (dateString) => {
  if (dateString === null || typeof dateString === "undefined") return "-";
  return dayjs(dateString).utc(true).local();
};
