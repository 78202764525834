import React from "react";
import CustomModal from "../../Core/Common/CustomModal";
import CustomButton from "../../Core/Common/CustomButton";

type EndPhaseModalProps = {
  visible: boolean;
  onCancel: () => void;
  onOk: () => void;
};

const EndPhaseModal = ({ visible, onCancel, onOk }: EndPhaseModalProps) => {
  return (
    <CustomModal
      title={<p className="mon-500-16 text-black">End phase</p>}
      width={"524px"}
      visible={visible}
      body={
        <div className="max-h-[38.189386056191466vh] overflow-y-scroll">
          <div className="flex items-center px-5 py-5">
            <img
              src="/images/icons/frosty-help.svg"
              className="h-9 w-9"
              alt="frosty"
            />
            <p className="text-sm font-medium text-primary-600 ml-2">
              You have 20 days till the end of the phase.
            </p>
          </div>
          <div className="mon-400-14 text-gray-500 pt-5 pb-4 px-5">
            Are you sure you want to end this phase today?
          </div>
        </div>
      }
      footer={
        <div className="flex justify-between pt-6 pb-4 px-3">
          <CustomButton
            text="Cancel"
            onClick={onCancel}
            className="border-2 border-gray-200 text-black bg-white mon-500-16 "
            height="44px"
            width="49%"
          />
          <CustomButton
            text="Yes, End now"
            onClick={onOk}
            className="mon-500-16 text-white  bg-primary-600 "
            height="44px"
            width="49%"
          />
        </div>
      }
      onCancel={onCancel}
    />
  );
};

export default EndPhaseModal;
