import React, { useEffect } from "react";
import { CustomSelectWithInlineSearch } from "../../../Core/Common/CustomSelectWithSearch";
import { useAppSelector } from "../../../Core/redux/hooks";
import PhaseIcon from "../../../Core/svg/PhaseIcon";

function TaskPhaseDropDown({
  currentDropDown,
  task,
  setTask,
  setCurrentDropDown,
  phases,
  onEdit,
}) {
  const { currentSelectedPhase } = useAppSelector((state) => state.schedule);
  const renderPhaseOption = (p) => (
    <div
      className="flex items-center -mt-0.5"
      key={p.id + "phases"}
      onClick={() => {
        setTask({
          ...task,
          phaseId: p.id.toString(),
          phaseName: p.phases,
        });
        onEdit(p.id);
      }}
    >
      <PhaseIcon width={"16"} height={"16"} phaseId={+p.id} icon />

      <p className="mon-400-14 pl-2 text-gray-900 capitalize ">{p.phases}</p>
    </div>
  );

  const ongoingOptions = phases
    .filter((phase) => phase.isOngoing)
    .map((p) => ({
      id: p.id,
      value: p.id,
      render: () => renderPhaseOption(p),
    }));

  const yetToBeginOptions = phases
    .filter((phase) => !phase.isOngoing && !phase.isCompleted)
    .map((p) => ({
      id: p.id,
      value: p.id,
      render: () => renderPhaseOption(p),
    }));

  const phase = phases.find((p) => +p.phaseId === +task.phaseId);

  const isDisabled = ongoingOptions.length < 2;

  useEffect(() => {
    if (isDisabled) {
      setTask({
        ...task,
        phaseId: currentSelectedPhase.id.toString(),
        phaseName: currentSelectedPhase.phases,
      });
    }
  }, [isDisabled]);

  return (
    <div className="min-h-[44px] max-h-[44px] items-center flex">
      {currentDropDown === "phases" ? (
        <div className="">
          <CustomSelectWithInlineSearch
            containerStyle={{
              width: "200px",
            }}
            options={[
              {
                id: "1",
                title: "Ongoing",
                options: ongoingOptions,
              },
              // {
              //   id: "2",
              //   title: "Yet to begin",
              //   options: yetToBeginOptions,
              // },
            ]}
            placeholder="Select phase"
            value={task.phaseId}
            onDropdownVisibleChange={() => setCurrentDropDown("")}
          />
        </div>
      ) : (
        <div
          className="flex items-center cursor-pointer"
          onClick={() => !isDisabled && setCurrentDropDown("phases")}
        >
          <PhaseIcon
            width="16px"
            height="16px"
            phaseId={+task.phaseId}
            icon={true}
          />
          <p className="mon-400-12 text-gray-700 whitespace-nowrap px-2">
            {phase?.phases || "Phase"}
          </p>
          <img src="/images/icons/chevron-down-gray.svg" className="w-3 h-3" />
        </div>
      )}
    </div>
  );
}

export default TaskPhaseDropDown;
