import React, { useState, useEffect } from "react";
import { Checkbox, Segmented } from "antd";
import CustomModal from "../../../Core/Common/CustomModal";
import CustomButton from "../../../Core/Common/CustomButton";
import CustomSearch from "../../../Core/Common/CustomSearch";
import { useAppDispatch, useAppSelector } from "../../../Core/redux/hooks";
import {
  forwardMessageToUsers,
  getThreads,
} from "../../../Core/redux/api/conversationAPI";
import { useParams } from "react-router-dom";
import CustomAvatar from "../../../Core/Common/CustomAvatar";
import PhaseIcon from "../../../Core/svg/PhaseIcon";
import { ObjectID } from "bson";
import { resetConversations } from "../../../Core/redux/slices/conversations.slice";

type ShareMessageModalProps = {
  visible: boolean;
  onClose: () => void;
};

const ShareMessageModal = ({ visible, onClose }: ShareMessageModalProps) => {
  const [showSegment, setShowSegment] = useState<string | number>("Threads");
  const dispatch = useAppDispatch();
  const { projectId } = useParams();
  const { user } = useAppSelector((state) => state.userDetails);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedThreads, setSelectedThreads] = useState([]);

  useEffect(() => {
    if (visible) {
      dispatch(getThreads({ userId: user.id }));
    }
  }, [visible]);

  const { directMessageUsers, threads, shareInMessageModal } = useAppSelector(
    (state) => state.conversations
  );

  const handelChecked = (id, type = "private") => {
    if (type === "thread") {
      if (selectedThreads.indexOf(id) !== -1) {
        setSelectedThreads((old) => old.filter((uid) => id !== uid));
      } else {
        setSelectedThreads((old) => [...old, id]);
      }
    } else {
      if (selectedUsers.indexOf(id) !== -1) {
        setSelectedUsers((old) => old.filter((uid) => id !== uid));
      } else {
        setSelectedUsers((old) => [...old, id]);
      }
    }
  };

  const onShare = async () => {
    const { firstName, lastName, id, profilePicture } = user;

    const newPrivateMessages = selectedUsers.map((userId) => {
      const objectID = new ObjectID();
      return {
        message:
          shareInMessageModal.type === "folder" ||
          shareInMessageModal.type === "link"
            ? shareInMessageModal.url
            : "",
        senderName: `${firstName} ${lastName}`,
        createdAt: new Date(),
        senderProfilePicture: profilePicture,
        replyMessage: {},
        attachments:
          shareInMessageModal.type === "file" ? shareInMessageModal.file : [],
        reciverId: userId.toString(),
        senderId: user.id.toString(),
        _id: objectID.toString(),
      };
    });
    const newThreadsMessages =
      selectedThreads.length > 0
        ? selectedThreads.map((threadId) => {
            const objectID = new ObjectID();
            return {
              threadId: +threadId,
              message:
                shareInMessageModal.type === "folder" ||
                shareInMessageModal.type === "link"
                  ? shareInMessageModal.url
                  : "",
              senderName: `${firstName} ${lastName}`,
              createdAt: new Date(),
              senderProfilePicture: profilePicture,
              replyMessage: {},
              attachments:
                shareInMessageModal.type === "file"
                  ? shareInMessageModal.file
                  : [],

              senderId: user.id,
              _id: objectID.toString(),
            };
          })
        : [];
    console.log(newPrivateMessages, "newPrivate");
    await dispatch(
      forwardMessageToUsers({
        messages: [...newPrivateMessages, ...newThreadsMessages],
      })
    );
    dispatch(
      resetConversations([
        "forwardMessage",
        "shareMessageModal",
        "shareInMessageModal",
      ])
    );
  };
  return (
    <CustomModal
      title={<p className="mon-500-12 text-gray-700">Share in message</p>}
      width={"524px"}
      visible={visible}
      body={
        <div className="max-h-[38.189386056191466vh] overflow-y-scroll">
          <CustomSearch
            className=" border-2 border-gray-300 mx-4 my-4"
            placeholder="Search thread, people"
          />
          <div className="max-w-[492px] mx-4 my-4">
            <Segmented
              options={[
                {
                  label: (
                    <div className="text-center mon-500-14 pt-1.5 text-gray-700">
                      Threads
                    </div>
                  ),
                  value: "Threads",
                },
                {
                  label: (
                    <div className="text-center mon-500-14 pt-1.5 text-gray-700">
                      People
                    </div>
                  ),
                  value: "People",
                },
              ]}
              block={true}
              defaultValue="Threads"
              className="text-center mon-500-14 h-11 text-gray-700 rounded-lg"
              onChange={(value) => {
                setShowSegment(value);
              }}
            />
            {showSegment === "Threads" && (
              <div>
                <div className="mon-400-12 text-gray-700 pt-5">
                  Recent threads
                </div>
                {threads ? (
                  threads.map((thread) => (
                    <div
                      className="flex items-center justify-between pt-4"
                      key={thread.threadId}
                    >
                      <div className="flex items-center ">
                        <Checkbox
                          onChange={(e) =>
                            handelChecked(thread.threadId, "thread")
                          }
                          checked={
                            selectedThreads.indexOf(thread.threadId) !== -1
                          }
                        />
                        <div className="ml-2">
                          <PhaseIcon
                            width="12px"
                            height="12px"
                            phaseId={thread.phaseId}
                          />
                        </div>
                        <p className="mon-400-12 text-gray-700 capitalize ml-3">
                          {thread.threadName}
                        </p>
                      </div>
                    </div>
                  ))
                ) : (
                  <></>
                )}
              </div>
            )}
            {showSegment === "People" && (
              <div className="mt-2">
                {directMessageUsers ? (
                  directMessageUsers.map((user) => (
                    <div
                      className="flex items-center justify-between pt-4"
                      key={user.userId}
                    >
                      <div className="flex items-center ">
                        <Checkbox
                          onChange={(e) => handelChecked(user.userId)}
                          checked={selectedUsers.indexOf(user.userId) !== -1}
                        />
                        <div className="ml-3">
                          <CustomAvatar
                            title={
                              user.firstname
                                ? user.firstname + " " + user.lastname
                                : user.email
                                ? user.email
                                : "User"
                            }
                            src={user.profilePicture}
                            size={30}
                          />
                        </div>
                        <p className="mon-500-14 text-gray-700 capitalize ml-3">
                          {user.firstname
                            ? user.firstname + " " + user.lastname
                            : user.email
                            ? user.email
                            : "User"}
                        </p>
                      </div>
                      <p className="mon-500-12 text-gray-500">
                        {user.roleName}
                      </p>
                    </div>
                  ))
                ) : (
                  <></>
                )}
              </div>
            )}
          </div>
        </div>
      }
      footer={
        <div className="flex justify-between py-2.5 px-3">
          <CustomButton
            text="Cancel"
            onClick={() => onClose()}
            className="border border-gray-200 text-gray-700 bg-white mon-500-16 "
            height="44px"
            width="49%"
          />
          <CustomButton
            text="Share"
            onClick={() => onShare()}
            className="mon-500-16 text-white bg-primary-600 "
            height="44px"
            width="49%"
          />
        </div>
      }
      onCancel={() => {
        onClose();
      }}
    />
  );
};

export default ShareMessageModal;
