import React from "react";
import CustomModal from "../../Core/Common/CustomModal";
import CustomButton from "../../Core/Common/CustomButton";
import { useAppDispatch, useAppSelector } from "../../Core/redux/hooks";
import { deleteLink, getAllLinks } from "../../Core/redux/api/vaultAPI";
import { resetVault } from "../../Core/redux/slices/vault.slice";
import { useParams } from "react-router-dom";

type DeleteLinkModalProps = {
  visible: boolean;
  id: string;
  onCancel: () => void;
};

const DeleteLinkModal = ({ visible, id, onCancel }: DeleteLinkModalProps) => {
  const dispatch = useAppDispatch();

  const { projectId, "*": splat } = useParams();
  const { user } = useAppSelector((state) => state.userDetails);

  const handleDelete = async () => {
    await dispatch(deleteLink({ links: [id], userId: user.id }));

    const queryObject: { projectId: string; folderId?: string } = { projectId };

    if (splat && splat.length > 0) {
      const folderId = splat.split("-");
      queryObject.folderId = folderId[folderId.length - 1];
    }

    dispatch(resetVault(["deleteLinkModal"]));
    dispatch(getAllLinks(queryObject));
  };
  return (
    <CustomModal
      title={<p className="mon-500-16 text-error-600">Delete link</p>}
      width={"524px"}
      visible={visible}
      body={
        <div className="max-h-[38.189386056191466vh] overflow-y-scroll">
          <div className="mon-400-14 text-gray-500 pt-6 pb-4 px-5">
            Are you sure you want to delete this link? This action cannot be
            undone
          </div>
        </div>
      }
      footer={
        <div className="flex justify-between pt-8 pb-4 px-3">
          <CustomButton
            text="Cancel"
            onClick={onCancel}
            className="border-2 border-gray-200 text-black bg-white mon-500-16 "
            height="44px"
            width="49%"
          />
          <CustomButton
            text="Yes, Delete"
            onClick={handleDelete}
            className="mon-500-16 text-white  bg-error-600 "
            height="44px"
            width="49%"
          />
        </div>
      }
      onCancel={onCancel}
    />
  );
};

export default DeleteLinkModal;
