import React from "react";
import CustomAvatar from "../../../Core/Common/CustomAvatar";

const ListItem = () => {
  return (
    <div className="flex items-center pt-[22px]">
      <div className="w-4 h-4 bg-gray-200 rounded-md"></div>
      <div
        className="bg-gray-200 rounded-2xl ml-[14px]"
        style={{ width: "124px", height: "11px" }}
      ></div>
    </div>
  );
};

const SidebarSkeletonStructure = ({
  logo,
  projectName,
  internalProjectName,
  color,
}) => {
  return (
    <div className="mt-9 px-3">
      <div className="flex items-center gap-x-[5px]">
        {logo.length > 0 ? (
          <img
            src={logo}
            className="w-9 h-9 rounded-full cursor-pointer object-cover"
            onClick={async () => {
              const src = logo as string;
              if (!src) return;
              const image = new Image();
              image.src = src;
              const imgWindow = window.open(src);
              imgWindow?.document.write(image.outerHTML);
            }}
          />
        ) : (
          <CustomAvatar
            title={projectName ? projectName : "P"}
            size={36}
            color={color}
            whiteText
            fontSize="16px"
          />
        )}

        <div>
          <div className="mon-500-12 text-primary-600">
            {projectName !== "" ? (
              projectName
            ) : (
              <div
                className="bg-gray-300 rounded-2xl"
                style={{ width: "124px", height: "17px" }}
              ></div>
            )}
          </div>
          <div className="mon-400-12 text-gray-400">
            {internalProjectName !== "" ? (
              internalProjectName
            ) : (
              <div
                className="bg-gray-200 mt-[6px] rounded-2xl"
                style={{ width: "105px", height: "11px" }}
              ></div>
            )}
          </div>
        </div>
      </div>
      <div className="mt-[45px] ml-[10px]">
        <ListItem />
        <ListItem />
        <ListItem />
        <ListItem />
      </div>
    </div>
  );
};

export default SidebarSkeletonStructure;
