import { Tooltip } from "antd";
import React from "react";

const PriorityIcon = ({
  priority,
  size = "16px",
}: {
  priority: number;
  size?: string;
}) => {
  switch (priority) {
    case 1:
      return (
        <Tooltip title="Priority: Low" placement="bottom">
          <div
            className=" pt-0.5"
            style={{
              height: `${size}`,
              width: `${size}`,
            }}
          >
            <img
              src="/images/icons/grey-priority-flag.svg"
              alt="priority"
              className="w-full h-full"
            />
          </div>
        </Tooltip>
      );
    case 2:
      return (
        <Tooltip title="Priority: Medium" placement="bottom">
          <div
            className=" pt-0.5"
            style={{
              height: `${size}`,
              width: `${size}`,
            }}
          >
            <img
              src="/images/icons/blue-priority-flag.svg"
              alt="priority"
              className="w-full h-full"
            />
          </div>
        </Tooltip>
      );
    case 3:
      return (
        <Tooltip title="Priority: High" placement="bottom">
          <div
            className=" pt-0.5"
            style={{
              height: `${size}`,
              width: `${size}`,
            }}
          >
            <img
              src="/images/icons/red-priority-flag.svg"
              alt="priority"
              className="w-full h-full"
            />
          </div>
        </Tooltip>
      );
    default:
      return (
        <Tooltip title="Priority: Low" placement="bottom">
          <div
            className=" pt-0.5"
            style={{
              height: `${size}`,
              width: `${size}`,
            }}
          >
            <img
              src="/images/icons/grey-priority-flag.svg"
              alt="priority"
              className="w-full h-full"
            />
          </div>
        </Tooltip>
      );
  }
};

export const PriorityIconWithLabel = ({ priority }: { priority: number }) => {
  switch (priority) {
    case 1:
      return (
        <div className="flex items-center">
          <img src="/images/icons/flag-gray.svg" />
          <p className="mon-400-12 text-gray-400 ml-1.5">Low</p>
        </div>
      );
    case 2:
      return (
        <div className="flex items-center">
          <img src="/images/icons/flag-blue.svg" />
          <p className="mon-400-12 text-primary-600 ml-1.5">Medium</p>
        </div>
      );
    case 3:
      return (
        <div className="flex items-center">
          <img src="/images/icons/flag-red.svg" />
          <p className="mon-400-12 text-error-600 ml-1.5">High</p>
        </div>
      );
    default:
      return (
        <div className="flex items-center">
          <img src="/images/icons/flag-gray.svg" />
          <p className="mon-400-12 text-gray-400 ml-1.5">Low</p>
        </div>
      );
  }
};

export default React.memo(PriorityIcon);
