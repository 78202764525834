import React, { useState } from "react";
import { Select, Input, Divider, Empty } from "antd";
import SearchOutlined from "@ant-design/icons/SearchOutlined";

type CustomSelectProps = {
  options?: any;
  value?: any;
  onDropdownVisibleChange?: any;
  onChange?: (e) => void;
  onMouseOver?: (e) => void;
  placeholder?: string;
  border?: boolean;
  backgroundColor?: string;
  disabled?: boolean;
  formikHook?: any;
  name?: string;
  showError?: boolean;
  errorText?: string;
  helperText?: string;
  wrapperClassName?: string;
  onFocusChange?: (isTrue, e) => void;
  showBorderOnlyOnFocused?: boolean;
  defaultOpen?: boolean;
  showSearch?: boolean;
};

const CustomSelect = ({
  options = [],
  value,
  onDropdownVisibleChange,
  onChange,
  placeholder,
  border = true,
  onMouseOver,
  backgroundColor = "bg-white",
  disabled,
  formikHook: formik,
  name,
  showError,
  errorText,
  wrapperClassName,
  onFocusChange,
  helperText,
  showBorderOnlyOnFocused = false,
  defaultOpen = false,
  showSearch = false,
}: CustomSelectProps) => {
  const [focused, setFocused] = useState(defaultOpen);

  if (formik && name) {
    showError = showError || (formik.touched[name] && !!formik.errors[name]);
    errorText = errorText || (formik.touched[name] && formik.errors[name]);
    // valid =
    //   valid ||
    //   (!formik.errors[name] &&
    //     formik.initialValues[name] !== formik.values[name])
  }

  return (
    <div
      className={`
  transition-shadow duration-300 
  ${border ? "border" : ""} 
  ${showBorderOnlyOnFocused && focused ? "border" : ""} 
  ${focused ? "border" : ""} 
  ${
    showError
      ? "border border-error-600 shadow-input-error"
      : !border && !showBorderOnlyOnFocused
      ? ""
      : focused
      ? "border-primary-500 shadow-input-focus"
      : disabled
      ? "border-grey-200"
      : "border-grey-200"
  } 
  bg-white rounded-lg h-11 flex items-center w-full ${wrapperClassName}
`}
    >
      <div
        className={`
          rounded-xl w-full bg-transparent  h-11`}
      >
        <Select
          //id={id}
          showSearch={showSearch}
          className={`w-full h-11 flex items-center`}
          bordered={false}
          onFocus={(e) => {
            setFocused(true);
            if (typeof onFocusChange === "function") onFocusChange(true, e);
          }}
          onBlur={(e) => {
            setFocused(false);
            if (typeof onFocusChange === "function") onFocusChange(false, e);
          }}
          getPopupContainer={(trigger) => trigger.parentElement}
          //   defaultValue={

          //   }
          //open={true}
          defaultOpen={defaultOpen}
          value={value}
          placeholder={placeholder}
          disabled={disabled}
          suffixIcon={
            <img src="/images/icons/chevron-down-gray.svg" alt="down-arrow" />
          }
          //open={true}
          popupClassName={`truncate max-h-[320px] ${
            border ? "border border-gray-100" : ""
          }  bg-white rounded-lg`}
          dropdownRender={(menu) => (
            <>
              <div className="  hidden-scrollbar ">{menu}</div>
            </>
          )}
          onDropdownVisibleChange={(open) => {
            setFocused(open);
            if (typeof onDropdownVisibleChange === "function") {
              onDropdownVisibleChange(open);
            }
          }}
          onChange={onChange}
          filterOption={(input, option) => {
            const optionLabel = `${option.label || option.value}`.toLowerCase();
            return optionLabel.includes(input?.toLowerCase());
          }}
        >
          {options.length > 0 ? (
            options?.map((option, index) => (
              <Select.Option
                //className={}
                key={option.id}
                value={option.value}
                label={option.value}
                className="py-2.5"
                onMouseOver={onMouseOver}
                //   key={
                //     searchableSelect
                //       ? option?.TransferId || option?.Id
                //       : option?.Id
                //   }
                //   value={
                //     searchableSelect
                //       ? option?.TransferId || option?.Value
                //       : option?.Value
                //   }
              >
                {option.render(value)}
              </Select.Option>
            ))
          ) : (
            <Empty />
          )}
        </Select>
        {showError && (
          <div className="mt-1.5 mon-400-14 text-left text-error-500">
            {errorText}
          </div>
        )}
        {helperText && (
          <div className="mt-1.5 mon-400-14 text-left text-error-500">
            {helperText}
          </div>
        )}
      </div>
    </div>
  );
};

export default CustomSelect;
