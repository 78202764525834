import React, { useState } from "react";
import { Drawer } from "antd";
import CustomFrostyMessage from "../../../Core/Common/CustomFrostyMessage";
import { useAppDispatch, useAppSelector } from "../../../Core/redux/hooks";
import {
  updateConversations,
  updateConversationsMultiple,
} from "../../../Core/redux/slices/conversations.slice";

const SuggestedThreadSidebar = () => {
  const dispatch = useAppDispatch();
  const { threadModal, threads } = useAppSelector(
    (state) => state.conversations
  );
  const { tenantMembers } = useAppSelector((state) => state.tenant);

  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  let suggestion = [
    {
      id: "1",
      title: "General",
      text: "Includes everyone in the organization",
    },
    {
      id: "2",
      title: "Project management",
      text: "Includes all the stakeholders",
    },
    {
      id: "3",
      title: "Project team",
      text: "Includes all in the organization",
    },
  ];

  suggestion = suggestion.filter(
    (obj) =>
      !threads.some(
        (t) => t?.threadName?.toLowerCase() === obj.title.toLowerCase()
      )
  );

  const membersToBeAdded = (id) => {
    if (id === "1") {
      return tenantMembers;
    }
    if (id === "2") {
      return tenantMembers.filter((member) => +member.roleid < 3);
    }

    return [];
  };

  return (
    <div className="w-9 max-h-[calc(100vh-56px)] min-h-[calc(100vh-56px)] bg-white border-r-[1px] shadow-lg z-50">
      <div className="flex flex-col items-center justify-center">
        <div
          className="w-5 h-5 mx-auto pt-2 cursor-pointer"
          onClick={() => showDrawer()}
        >
          <img src="/images/icons/chevron-right-double.svg" />
        </div>

        <p className=" mt-20 mr-1 text-gray-700 mon-500-12 whitespace-nowrap transform rotate-[270deg]">
          Suggested Threads
        </p>
      </div>

      <Drawer
        className="z-50"
        title={
          <div className="flex items-center justify-between">
            <div className="text-gray-700 mon-500-12">Suggested Threads</div>
            <img
              src="/images/icons/chevron-left-double.svg"
              className="cursor-pointer"
              onClick={onClose}
            />
          </div>
        }
        headerStyle={{ border: "none", backgroundColor: "#F9FBFE" }}
        placement="left"
        onClose={onClose}
        open={open}
        closable={false}
        getContainer={false}
      >
        <div className="suggestion-thread-container">
          <CustomFrostyMessage shadow={false} close={false}>
            <p className="mon-500-12 text-primary-600 bg-white">
              Hey, I’m back! Here’s some threads I think will help you in this
              phase
            </p>
          </CustomFrostyMessage>
          {suggestion.map((s) => (
            <div
              className="px-2 py-2 mb-4 border border-gray-100 rounded-lg"
              key={s.id}
            >
              <p className="mon-500-12 text-gray-900">{s.title}</p>
              <div className="flex justify-between items-center mt-2">
                <p className="mon-400-12 text-gray-400">{s.text}</p>
                <button
                  onClick={() =>
                    dispatch(
                      updateConversationsMultiple([
                        {
                          key: "threadModal",
                          value: true,
                        },
                        {
                          key: "editThreadDetails",
                          value: {
                            threadName: s.title,
                            description: s.text,
                          },
                        },
                        {
                          key: "editThread",
                          value: true,
                        },
                        {
                          key: "suggestedThread",
                          value: true,
                        },
                        {
                          key: "prefillUserInAddThread",
                          value: s.id === "3",
                        },

                        { key: "threadMembers", value: membersToBeAdded(s.id) },
                      ])
                    )
                  }
                  className="flex justify-center items-center"
                >
                  <img src="/images/icons/plus-black.svg" className="w-4 h-4" />
                  <p className="mon-500-12 ml-2.5">Add</p>
                </button>
              </div>
            </div>
          ))}
        </div>
      </Drawer>
    </div>
  );
};

export default SuggestedThreadSidebar;
