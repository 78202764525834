import React from "react";
import CustomModal from "../../../Core/Common/CustomModal";
import CustomButton from "../../../Core/Common/CustomButton";
import { useAppDispatch, useAppSelector } from "../../../Core/redux/hooks";
import {
  getGoalsBySprint,
  updateGoal,
  updateProgress,
} from "../../../Core/redux/api/scheduleAPI";
import { useParams } from "react-router-dom";
import { resetSchedule } from "../../../Core/redux/slices/schedule.slice";

type DeleteTaskModalProps = {
  visible: boolean;
  onCancel: () => void;
  //onOk: () => void;
};

const DeleteTaskModal = ({ visible, onCancel }: DeleteTaskModalProps) => {
  const { deleteTaskModal } = useAppSelector((state) => state.schedule);

  const getStatus = (statusId) => {
    switch (statusId) {
      case 1:
        return (
          <div className="w-[60px] bg-gray-100 rounded-2xl">
            <p className="text-gray-700 uppercase whitespace-nowrap px-2">
              To do
            </p>
          </div>
        );
      case 2:
        return (
          <div className="w-[115px] bg-primary-600 rounded-2xl">
            <p className="text-white uppercase whitespace-nowrap px-2">
              In progress
            </p>
          </div>
        );
      case 3:
        return (
          <div className="w-[60px] bg-success-600 rounded-2xl">
            <p className="text-white uppercase whitespace-nowrap px-2">Done</p>
          </div>
        );
      default:
        return (
          <div className="w-[56px] bg-gray-100 rounded-2xl">
            <p className="text-gray-700 uppercase whitespace-nowrap px-2 py-0.5 ">
              To do
            </p>
          </div>
        );
    }
  };

  const dispatch = useAppDispatch();
  const { selectedSprint, currentSelectedPhase } = useAppSelector(
    (state) => state.schedule
  );
  const { projectId } = useParams();

  const onSubmit = () => {
    dispatch(updateGoal({ goalId: deleteTaskModal.goalId, isActive: 0 }));
    dispatch(resetSchedule(["newTaskModal"]));
    dispatch(getGoalsBySprint({ projectId, sprintId: selectedSprint.id }));
    dispatch(
      updateProgress({
        phaseId: currentSelectedPhase.id,
        projectId,
      })
    );
    onCancel();
  };

  return (
    <CustomModal
      title={<p className="mon-500-16 text-error-600">Delete task</p>}
      width={"524px"}
      visible={visible}
      body={
        <div className="max-h-[38.189386056191466vh] overflow-y-scroll pt-6 pb-4 px-5">
          <p className="mon-400-14 text-gray-500 ">
            Are you sure you want to delete this task?{"   "}
            <span className="underline"> This action cannot be undone.</span>
          </p>
          <div className="flex items-center mt-6 gap-x-[6px]">
            <img src="/images/icons/alert-octagon.svg" className="w-6 h-6" />
            <div className="flex items-center gap-x-1">
              <p className="mon-500-14 text-gray-900">The task is still in</p>
              <p>{getStatus(deleteTaskModal.statusId)}</p>
              <p className="mon-500-14 text-gray-900 ">state.</p>
            </div>
          </div>
        </div>
      }
      footer={
        <div className="flex justify-between pt-8 pb-4 px-3">
          <CustomButton
            text="Cancel"
            onClick={onCancel}
            className="border-2 border-gray-200 text-black bg-white mon-500-16 "
            height="44px"
            width="49%"
          />
          <CustomButton
            text="Delete task"
            onClick={onSubmit}
            className="mon-500-16 text-white  bg-error-600 "
            height="44px"
            width="49%"
          />
        </div>
      }
      onCancel={onCancel}
    />
  );
};

export default DeleteTaskModal;
