import React, { useState } from "react";
import CustomModal from "../../../Core/Common/CustomModal";
import CustomButton from "../../../Core/Common/CustomButton";
import CustomInputWithEdit from "../../../Core/Common/CustomInputWithEdit";
import { Row, Col, DatePicker, Upload } from "antd";
import CustomSelectWithSearch from "../../../Core/Common/CustomSelectWithSearch";
import CustomFrostyMessage from "../../../Core/Common/CustomFrostyMessage";
import { useAppDispatch, useAppSelector } from "../../../Core/redux/hooks";
import dayjs from "dayjs";
import {
  getProjectDetatis,
  updateProjectDetails,
} from "../../../Core/redux/api/projectAPI";
import { useParams } from "react-router-dom";
import CustomAvatar from "../../../Core/Common/CustomAvatar";
import AntdImgCrop from "antd-img-crop";
import uniqid from "uniqid";
import axios from "axios";

const ProjectInfoItem = ({
  fieldName,
  image,
  fieldValue,
}: {
  fieldName: string;
  image: string;
  fieldValue: string;
}) => {
  return (
    <ul className="mt-7">
      <li className="text-gray-500 uppercase text-[10px] font-medium">
        {fieldName}
      </li>
      <li className="text-black text-xs font-medium capitalize mt-2">
        {fieldValue}
      </li>
    </ul>
  );
};

// const projectDetails = {
//   projectPicture: "",
//   "Project Name": "battery & fleet management system",
//   "Start Date": "20 May 2022",
//   "End Date": "20 November 2022",
//   "Client Name": "Mazi Mobility",
//   Location: "Nairobi, Kenya",
//   Timezone: "08:00AM, East Africa Time",
// };

const ProjectInfo: React.FC = () => {
  const [projectInfoModal, setProjectInfoModal] = useState(false);
  const { projectDetails } = useAppSelector((state) => state.overview);
  const [newProjectDetails, setNewProjectDetails] = useState(projectDetails);
  const dispatch = useAppDispatch();
  const { overview } = useAppSelector((state) => state.projects);
  const manageProjects = overview?.[1]?.[0];
  const { projectId } = useParams();

  const [fileList, setFileList] = useState([]);

  const [fileUploadedList, setFileUploadedList] = useState([]);

  const handleChange = ({ fileList: newFileList }, isProject) => {
    console.log(newFileList, "newFileList dleetetest");
    setFileList(newFileList);
    const fileData = [];

    console.log(newFileList);

    newFileList.forEach((file) => {
      const { name, size, type, lastModified, status } = file;

      if (status !== "done") return;

      file.response.forEach((xfile) => {
        const { fileUrl: url, thumbnail } = xfile;

        fileData.push({
          name,
          url,
          size,
          type,
          lastModified,
          savedInVault: false,
          thumbnail: thumbnail.fileUrl,
        });
      });
    });
    console.log(fileData, "file");
    setFileUploadedList(fileData);

    if (isProject) {
      setNewProjectDetails({
        ...newProjectDetails,
        projectImage: fileData[0]?.url || "",
      });
    } else {
      setNewProjectDetails({
        ...newProjectDetails,
        clientImage: fileData[0]?.url || "",
      });
    }
  };

  const uploadImage = (options: any) => {
    // below file element is the file you uploaded
    const { onSuccess, onError, file, onProgress } = options;
    const fmData = new FormData();
    const config = {
      headers: {
        "content-type": "multipart/form-data",
        // Authorization: "Bearer " + userLocal.token,
      },
      onUploadProgress: (event) => {
        onProgress({ percent: (event.loaded / event.total) * 100 });
      },
    };
    console.log("FILE: ", file);
    // create copy file and change the name when it is appending to data
    const data = new FormData();
    data.append(
      "file",
      file,
      uniqid(`${file.name.split(".")[0]}-`, `.${file.name.split(".").at(-1)}`)
    ); // replace all the spaces in the file name with -
    const _file = data.get("file");
    fmData.append("image", _file);
    try {
      // then send the copy file to the server
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "/v1/upload/storej",
          fmData,
          config
        )
        .then((res) => {
          console.log("UPLOADED", res.data);
          onSuccess(res.data);
        });
    } catch (err) {
      onError({ err });
    }
  };
  return (
    <div className="shadow-box w-full h-full  bg-white rounded-2xl">
      <div className="flex justify-between pt-6 ml-7 mr-7">
        <div className="flex text-base font-medium text-black">
          Project Info
        </div>
        {manageProjects?.update === 1 && (
          <button
            className="flex text-[14px] leading-5 font-medium text-primary-600"
            onClick={() => setProjectInfoModal(true)}
          >
            Edit
          </button>
        )}
      </div>
      <div className="mt-5 ml-7 mr-7">
        {/* <ProjectInfoItem
          fieldName={"Project Name"}
          image={"/images/icons/mazi.png"}
          fieldValue={"battery & fleet management system"}
        /> */}
        <ul className="mt-7">
          <li className="text-gray-500 uppercase text-[10px] font-medium">
            Project Name
          </li>
          <li className="flex items-center mt-2">
            <CustomAvatar
              title={projectDetails.title}
              size={24}
              color={projectDetails.colorCode}
              whiteText
              fontSize="16px"
              src={projectDetails.projectImage}
            />
            <div className="flex text-black text-xs font-medium capitalize ml-2">
              {projectDetails.title}
            </div>
          </li>
        </ul>
        <ProjectInfoItem
          fieldName={"Start Date"}
          image={null}
          fieldValue={dayjs(projectDetails.startDate).format("DD MMM YYYY")}
        />
        <ProjectInfoItem
          fieldName={"End Date"}
          image={null}
          fieldValue={dayjs(projectDetails.endDate).format("DD MMM YYYY")}
        />
        {/* <ProjectInfoItem
          fieldName={"Client Name"}
          image={"/images/icons/mazi.png"}
          fieldValue={"Mazi Mobility"}
        /> */}
        <ul className="mt-5">
          <li className="text-gray-500 uppercase text-[10px] font-medium">
            Client Name
          </li>
          <li className="flex items-center mt-2">
            <CustomAvatar
              title={projectDetails.clientName}
              size={24}
              color={projectDetails.clientColor}
              whiteText
              fontSize="16px"
              src={projectDetails.clientImage}
            />
            <div className="flex text-black text-xs font-medium capitalize ml-2">
              {projectDetails.clientName}
            </div>
          </li>
        </ul>
        <ProjectInfoItem
          fieldName={"Based In"}
          image={null}
          fieldValue={projectDetails.clientLocation}
        />
        <ProjectInfoItem
          fieldName={"CURRENT TIME AND TIMEZONE"}
          image={null}
          fieldValue={`${dayjs.tz.guess()}`}
        />
      </div>

      <CustomModal
        title={<p className="mon-500-16 text-black">Project Info</p>}
        width={"1064px"}
        visible={projectInfoModal}
        body={
          <div className="max-h-[48.189386056191466vh] overflow-y-scroll">
            <Row>
              <Col span={16}>
                <div className=" px-5 pt-4">
                  <CustomFrostyMessage shadow close>
                    <p className="text-sm font-medium text-primary-600 ml-2">
                      You’ve created a new project! Add colleagues to
                      collaborate on this project.
                    </p>
                  </CustomFrostyMessage>
                </div>
                <div className="px-5  mt-9">
                  <CustomInputWithEdit
                    label="Project name"
                    value={newProjectDetails.title}
                    inputClassName="text-gray-500 mon-400-14"
                    onChange={(e) =>
                      setNewProjectDetails({
                        ...newProjectDetails,
                        title: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="px-5  mt-9">
                  <div className="mon-500-14 text-gray-700">Start date</div>
                  <DatePicker
                    className="w-full h-10 bg-transparent border-gray-300 rounded-xl shadow-xs"
                    suffixIcon={<></>}
                    format="DD MMM YYYY"
                    value={dayjs(newProjectDetails.startDate) as any}
                    onChange={(e) =>
                      setNewProjectDetails({
                        ...newProjectDetails,
                        startDate: dayjs(e as any).format("YYYY-MM-DD"),
                      })
                    }
                  />
                </div>
                <div className="px-5  mt-9">
                  <div className="mon-500-14 text-gray-700">End date</div>
                  <DatePicker
                    className="w-full h-10 bg-transparent border-gray-300 rounded-xl shadow-xs"
                    suffixIcon={<></>}
                    format="DD MMM YYYY"
                    value={dayjs(newProjectDetails.endDate) as any}
                    onChange={(e) =>
                      setNewProjectDetails({
                        ...newProjectDetails,
                        endDate: dayjs(e as any).format("YYYY-MM-DD"),
                      })
                    }
                  />
                </div>
                <div className="px-5  mt-9 ">
                  <Row gutter={[16, 0]}>
                    <Col span={15}>
                      <div className="mon-500-14 text-gray-700">
                        Based in (Select country)
                      </div>
                      <CustomSelectWithSearch
                        recentOptions={[
                          {
                            key: "1",
                            value: "india",
                            render: (value) => (
                              <div className="flex justify-between items-center ">
                                <div className="flex items-center">
                                  <img
                                    src="/images/icons/Avatar.svg"
                                    alt="ava"
                                  />
                                  <div className="mon-400-14 pl-2 text-gray-900 capitalize ">
                                    india
                                  </div>
                                </div>
                              </div>
                            ),
                          },
                        ]}
                        options={[
                          {
                            key: "1",
                            value: "india1",
                            render: (value) => (
                              <div className="flex justify-between items-center ">
                                <div className="flex items-center">
                                  <img
                                    src="/images/icons/Avatar.svg"
                                    alt="ava"
                                  />
                                  <div className="mon-400-14 pl-2 text-gray-900 capitalize ">
                                    india1
                                  </div>
                                </div>
                              </div>
                            ),
                          },
                          {
                            key: "2",
                            value: "india2",
                            render: (value) => (
                              <div className="flex justify-between items-center ">
                                <div className="flex items-center">
                                  <img
                                    src="/images/icons/Avatar.svg"
                                    alt="ava"
                                  />
                                  <div className="mon-400-14 pl-2 text-gray-900 capitalize ">
                                    india2
                                  </div>
                                </div>
                              </div>
                            ),
                          },
                        ]}
                        value="india"
                      />
                    </Col>
                    <Col span={9}>
                      <div className="mon-500-14 text-gray-700">City</div>
                      <CustomSelectWithSearch
                        recentOptions={[
                          {
                            key: "1",
                            value: "india",
                            render: (value) => (
                              <div className="mon-400-14 pl-2 text-gray-900 capitalize ">
                                india
                              </div>
                            ),
                          },
                        ]}
                        options={[
                          {
                            key: "1",
                            value: "india1",
                            render: (value) => (
                              <div className="mon-400-14 pl-2 text-gray-900 capitalize ">
                                india1
                              </div>
                            ),
                          },
                          {
                            key: "2",
                            value: "india2",
                            render: (value) => (
                              <div className="mon-400-14 pl-2 text-gray-900 capitalize ">
                                india2
                              </div>
                            ),
                          },
                        ]}
                        value="india"
                      />
                    </Col>
                  </Row>
                </div>
                <div className="px-5  mt-9">
                  <CustomInputWithEdit
                    label="Client name"
                    value={newProjectDetails.clientName}
                    inputClassName="text-gray-500 mon-400-14"
                    onChange={(e) =>
                      setNewProjectDetails({
                        ...newProjectDetails,
                        clientName: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="px-5  mt-9 pb-5">
                  <CustomInputWithEdit
                    label="Current time & Time zone"
                    value="Battery & Fleet Management System"
                    inputClassName="text-gray-500 mon-400-14 max-w-[300px]"
                    disabled
                    width="300px"
                  />
                </div>
              </Col>
              <Col span={8}>
                <div className="flex items-center mt-32">
                  <CustomAvatar
                    title={newProjectDetails.title}
                    size={32}
                    color={newProjectDetails.colorCode}
                    whiteText
                    fontSize="16px"
                    src={newProjectDetails.projectImage}
                  />
                  <AntdImgCrop
                    rotationSlider
                    modalClassName="image-crop-modal "
                  >
                    <Upload
                      action={process.env.REACT_APP_API_URL + "/v1/upload"}
                      // headers={{ Authorization: "Bearer " + user.token }}
                      customRequest={uploadImage}
                      fileList={fileList}
                      onChange={(data) => handleChange(data, true)}
                      showUploadList={false}
                    >
                      <div className="flex items-center ">
                        <img src="/images/icons/edit-2.svg" className="ml-3" />
                        <p className="mon-500-14 text-primary-600 pl-2">
                          Change Image
                        </p>
                      </div>
                    </Upload>
                  </AntdImgCrop>
                </div>
                <div
                  className="flex items-center"
                  style={{
                    marginTop: "380px",
                  }}
                >
                  <CustomAvatar
                    title={newProjectDetails.clientName}
                    size={32}
                    color={newProjectDetails.clientColor}
                    whiteText
                    fontSize="16px"
                    src={newProjectDetails.clientImage}
                  />
                  <AntdImgCrop
                    rotationSlider
                    modalClassName="image-crop-modal "
                  >
                    <Upload
                      action={process.env.REACT_APP_API_URL + "/v1/upload"}
                      // headers={{ Authorization: "Bearer " + user.token }}
                      customRequest={uploadImage}
                      fileList={fileList}
                      onChange={(data) => handleChange(data, false)}
                      showUploadList={false}
                    >
                      <div className="flex items-center ">
                        <img src="/images/icons/edit-2.svg" className="ml-3" />
                        <p className="mon-500-14 text-primary-600 pl-2">
                          Change Image
                        </p>
                      </div>
                    </Upload>
                  </AntdImgCrop>
                </div>
              </Col>
            </Row>
          </div>
        }
        footer={
          <div className="flex justify-end py-4 gap-x-3 px-3 shadow-upward">
            <CustomButton
              text="Cancel"
              onClick={() => {
                setNewProjectDetails(projectDetails);
                setProjectInfoModal(false);
              }}
              className="border border-gray-200 text-gray-700 bg-white mon-500-14"
              height="40px"
              width="81px"
            />
            <CustomButton
              text="Save Changes"
              onClick={async () => {
                await dispatch(
                  updateProjectDetails({
                    id: projectId,
                    title: newProjectDetails.title,
                    startDate: newProjectDetails.startDate,
                    endDate: newProjectDetails.endDate,
                    clientName: newProjectDetails.clientName,
                    projectImage: newProjectDetails.projectImage,
                  })
                );
                setProjectInfoModal(false);
                dispatch(getProjectDetatis({ projectId }));
              }}
              className="mon-500-14 text-white bg-primary-600 "
              height="40px"
              width="132px"
            />
          </div>
        }
        onCancel={() => {
          setNewProjectDetails(projectDetails);
          setProjectInfoModal(false);
        }}
      />
    </div>
  );
};
export default ProjectInfo;
