/* eslint-disable @typescript-eslint/no-explicit-any */
import generatePicker from "antd/lib/date-picker/generatePicker";
import { Dayjs } from "dayjs";
import dayjsGenerateConfig from "rc-picker/lib/generate/dayjs";

import React, { useState } from "react";

type InputProps = {
  className?: string;
  inputClassName?: string;
  rootStyle?: object;
  prefix?: React.ReactNode;
  suffix?: React.ReactNode;
  placeholder?: string;
  value?: Dayjs;
  onChange?: (e) => void;
  disabled?: boolean;
  maxLength?: number;
  name?: string;
  showError?: boolean;
  errorText?: string;
  helperText?: string;
  width?: string;
  valid?: boolean;
  formikHook?: any;
  type?: string;
  formatter?: any;
  parser?: any;
  mask?: any;
  label?: string;
  labelDefaultCase?: boolean;
  onFocus?: (e) => void;
  onBlur?: (e) => void;
  onFocusChange?: (toggle, el) => void;
  onPressEnter?: (e) => void;
  wrapperClassName?: string;
  onClick?: (e) => void;
  format: string;
};

export default function CustomDatePicker({
  width,
  label,
  labelDefaultCase,
  className,
  inputClassName,
  rootStyle,
  prefix,
  suffix,
  placeholder,
  value,
  onChange,
  disabled,
  maxLength,
  name,
  showError,
  errorText,
  helperText,
  valid,
  formikHook: formik,
  type,
  formatter,
  parser,
  onFocus,
  onBlur,
  onFocusChange,
  onPressEnter,
  wrapperClassName,
  onClick,
  format,
}: InputProps) {
  const [focused, setFocused] = useState(false);

  if (formik && name) {
    showError = showError || (formik.touched[name] && !!formik.errors[name]);
    errorText = errorText || (formik.touched[name] && formik.errors[name]);
    // valid =
    //   valid ||
    //   (!formik.errors[name] &&
    //     formik.initialValues[name] !== formik.values[name])
  }

  const DatePicker = generatePicker<Dayjs>(dayjsGenerateConfig);

  return (
    <div
      className={`flex flex-col items-start ${width} ${className}`}
      style={rootStyle}
    >
      {label && (
        <div
          className={`mon-500-12 text-gray-700 ${
            !labelDefaultCase && "uppercase"
          } mb-1.5`}
        >
          {label}
        </div>
      )}
      <div
        className={`transition-shadow duration-300 border ${
          showError
            ? "border-error-600 shadow-input-error"
            : focused
            ? "border-primary-500  shadow-input-focus"
            : disabled
            ? "border-grey-200"
            : "border-grey-200"
        } bg-white rounded-lg h-11 flex w-full ${wrapperClassName}`}
      >
        {prefix && (
          <div
            className={`h-full px-2.5 flex-center text-grey-200 mon-400-13 border-r ${
              disabled ? "border-r-grey-100" : "border-r-grey-200"
            } bg-background rounded-l-lg`}
          >
            {prefix}
          </div>
        )}
        <div
          className={`border-0 ${
            disabled ? "bg-background" : "bg-white"
          } rounded-lg w-full flex`}
        >
          <DatePicker
            className="w-full"
            suffixIcon={suffix}
            format={format}
            value={value}
            onChange={onChange}
            bordered={false}
          />
        </div>
        {/* {
          <div
            className={`input-suffix px-2.5 flex-center text-grey-200 mon-400-14 rounded-r-lg`}
          >
            {showError ? (
              <img src="/images/icons/error.svg" alt="⚠️" />
            ) : valid ? (
              <img className="w-5" src="/images/icons/check.svg" alt="⚠️" />
            ) : (
              suffix
            )}
          </div>
        } */}
      </div>
      {showError && (
        <div className="mt-1.5 mon-400-14 text-left text-error-500">
          {errorText}
        </div>
      )}
      {helperText && (
        <div className="mt-1.5 mon-400-14 text-left text-error-500">
          {helperText}
        </div>
      )}
    </div>
  );
}
