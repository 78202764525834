import { Col, Dropdown, Menu, Row, Spin } from "antd";
import React, { useEffect, useState } from "react";
import CustomCard from "../../Core/Common/CustomCard";
import CustomLayout from "../../Core/Layout/CustomLayout";
import CustomTodo from "../../Core/Common/CustomTodo";
import CustomTaskTable from "../../Core/Common/CustomTaskTable";
import { Link, useNavigate, useParams } from "react-router-dom";
import CustomEvent from "../../Core/Common/CustomEvent";
import CustomInputWithEdit from "../../Core/Common/CustomInputWithEdit";
import { chatClient, client } from "../../Core/utils/axiosClient";
import { useAppSelector } from "../../Core/redux/hooks";
import dayjs from "dayjs";
import { useDispatch } from "react-redux";
import {
  getOngoingProjectCount,
  getCompletedProjectCount,
  getTodos,
  createTodo,
  updateTodo,
  deleteTodo,
} from "../../Core/redux/api/dashboardAPI";
import { AppDispatch } from "../../Core/redux/store";
import { getMyTasks } from "../../Core/redux/api/dashboardAPI";
import MyTaskDrawer from "./MyTaskDrawer";
import { updateConversationsMultiple } from "../../Core/redux/slices/conversations.slice";
import CustomAvatar from "../../Core/Common/CustomAvatar";
import UpcomingEvents from "../Overview/Components/UpcomingEvents";

const NewDashboardScreen = () => {
  const [currentTab, setCurrentTab] = React.useState(1);
  const tabs = ["To Do", "In Progress"];
  const params = useParams();
  const dispatch = useDispatch<AppDispatch>();
  const [todoInput, setTodoInput] = useState(null);
  const navigate = useNavigate();

  const { onGoingProjects, completedProjects, todoList, myTasks } =
    useAppSelector((state) => state.dashboard);
  const { user } = useAppSelector((state) => state.userDetails);
  const { projectList } = useAppSelector((state) => state.projects);
  const [todoLoading, setTodoLoading] = useState(false);
  const [myTaskDrawer, setMyTaskDrawer] = useState(false);
  const [projectName, setProjectName] = useState("");
  const fetchFreshTodos = (loadingFalse = false) => {
    dispatch(
      getTodos({
        userId: user.id,
      })
    ).finally(() => {
      if (loadingFalse) setTodoLoading(false);
    });
  };

  useEffect(() => {
    dispatch(
      getOngoingProjectCount({
        userId: user.id,
      })
    );
    dispatch(
      getCompletedProjectCount({
        userId: user.id,
      })
    );
    fetchFreshTodos();
  }, [user.id]);

  const [catchUpList, setCatchUpList] = useState([]);
  const { tenantMembers } = useAppSelector((state) => state.tenant);
  const { userList } = useAppSelector((state) => state.overview);
  const { dashboardCount } = useAppSelector((state) => state.global);

  const suggestion = [
    {
      id: "1",
      title: "General",
      text: "Includes everyone in the organization",
    },
    {
      id: "2",
      title: "Project management",
      text: "Includes all the stakeholders",
    },
    {
      id: "3",
      title: "Project team",
      text: "Includes all in the organization",
    },
  ];

  const getUserById = (id) => {
    const user = userList.filter(({ userId }) => +userId === +id)?.[0];

    if (!user) return { name: undefined, profilePicture: undefined };
    const name = `${user.firstname} ${user.lastname}`;
    const profilePicture = user.profilePicture;

    return { name, profilePicture };
  };

  const getRecentConversations = async () => {
    try {
      const response = await chatClient.get(
        `/conversation/get-catchup-latest-conversations?userId=${user.id}`
      );

      setCatchUpList(response.data.result);

      // return response.data;
    } catch (error) {
      // return rejectWithValue(getError(error));
      //return error;
    }
  };

  useEffect(() => {
    getRecentConversations();
  }, []);

  const handleSaveTodo = () => {
    if (todoInput?.trim()?.length === 0) {
      setTodoInput(null);
      return;
    }
    setTodoLoading(true);
    dispatch(
      createTodo({
        userId: user.id,
        title: todoInput,
      })
    ).finally(() => {
      fetchFreshTodos(true);
    });
    setTodoInput(null);
  };

  const handleUpdateTodo = (id: number, status: number, title?: string) => {
    setTodoLoading(true);
    dispatch(
      updateTodo({
        userId: user.id,
        todoId: id,
        // status: status === 0 ? 1 : 0,
        status: status,
        title: title,
      })
    ).finally(() => {
      fetchFreshTodos(true);
    });
  };

  const handleDeleteTodo = (id: number) => {
    setTodoLoading(true);
    dispatch(
      deleteTodo({
        userId: user.id,
        todoId: id,
      })
    ).finally(() => {
      fetchFreshTodos(true);
    });
  };
  const handleClearAll = () => {
    setTodoLoading(true);
    dispatch(
      deleteTodo({
        userId: user.id,
        todoId: 0,
      })
    ).finally(() => {
      fetchFreshTodos(true);
    });
  };

  return (
    <CustomLayout page="dashboard" sidebar={false}>
      <Row className="flex bg-main-background">
        <Col span={18} className="max-h-[calc(100vh-56px)] overflow-scroll">
          <div className="p-6 max-h-[calc(100vh-56px)]">
            <h1 className="mon-600-20">
              {`${dashboardCount ? "Hi," : "Welcome back,"}`} {user.firstName}{" "}
              {user.lastName} 👋
            </h1>
            <Row gutter={24} className="py-4">
              <Col span={6}>
                <CustomCard
                  bgColor="blue"
                  icon="/images/icons/blue-card-icon.svg"
                  count={onGoingProjects}
                  title="Ongoing projects"
                  textColor="text-white"
                  titleColor="text-white"
                  onClick={() => navigate("/projects?tab=Ongoing")}
                />
              </Col>
              <Col span={6}>
                <CustomCard
                  bgColor="grey"
                  icon="/images/icons/grey-card-icon.svg"
                  count={completedProjects}
                  title="Completed projects"
                  textColor="text-black"
                  titleColor="text-gray-500"
                  onClick={() => navigate("/projects?tab=Completed")}
                />
              </Col>
              <Col span={12}>
                <div className="flex items-center justify-between">
                  <div className="mon-500-16 text-gray-900">My Notes</div>
                  <div className="flex items-center">
                    <button
                      onClick={handleClearAll}
                      className="mon-500-14 text-gray-500 cursor-pointer mr-4"
                    >
                      Clear all
                    </button>
                    <button onClick={() => setMyTaskDrawer(true)}>
                      <img
                        src="/images/icons/expand.svg"
                        className="cursor-pointer"
                      />
                    </button>
                  </div>
                </div>

                <div className="mb-3">
                  <CustomInputWithEdit
                    placeholder="Jot down your notes..."
                    value={todoInput}
                    onChange={(e) => {
                      setTodoInput(e.target.value);
                    }}
                    onPressEnter={(e) => {
                      handleSaveTodo();
                    }}
                    suffix={
                      todoInput && (
                        <div className="flex">
                          <div>
                            <img
                              src="/images/icons/input_save.svg"
                              className="mx-2 cursor-pointer"
                              onClick={handleSaveTodo}
                            />
                          </div>
                          <div>
                            <img
                              src="/images/icons/input_clear.svg"
                              className="mx-2 cursor-pointer"
                              onClick={() => setTodoInput(null)}
                            />
                          </div>
                        </div>
                      )
                    }
                  />
                </div>
                <CustomTodo
                  tasks={todoList}
                  userId={user.id}
                  updateTodo={handleUpdateTodo}
                  deleteTodo={handleDeleteTodo}
                />
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <CustomTaskTable
                  title="My Tasks"
                  tabs={tabs}
                  tabData={myTasks}
                  dropdownData={projectList}
                />
              </Col>
            </Row>
          </div>
        </Col>
        <Col span={6} className="max-h-[calc(100vh-56px)] overflow-hidden">
          <div className="bg-white w-full h-[calc(100vh-56px)] p-6 flex flex-col gap-y-4 overflow-y-scroll">
            {
              <div>
                <div className="flex justify-between items-center">
                  <div className="mon-500-16 text-black">Quick catch-up</div>
                  <Link
                    to={`/conversations`}
                    className="mon-500-14 text-gray-500 cursor-pointer"
                  >
                    View All
                  </Link>
                </div>
                <div className="mon-400-12 text-gray-500 mt-1.5">
                  Your most recent conversations
                </div>
                {catchUpList.length > 0 ? (
                  catchUpList.map(
                    (
                      {
                        message,
                        senderName,
                        threadId,
                        threadName,
                        createdAt,
                        senderId,
                      },
                      index
                    ) => (
                      <div className="mt-3" key={index}>
                        <div className="border border-gray-200 rounded-xl p-3">
                          <div className="flex justify-start gap-x-2 items-center">
                            <div className="mon-500-12 text-gray-900">
                              {threadName || senderName}
                            </div>
                            <p className="mon-400-12 text-gray-500">
                              {dayjs(createdAt).format("hh:mm a")}
                            </p>
                          </div>
                          <div className="flex justify-start gap-x-2 items-center mt-3">
                            <CustomAvatar
                              title={senderName}
                              src={getUserById(senderId).profilePicture}
                              size={24}
                            />
                            <div className="mon-400-12 text-gray-500 ">
                              {message}
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  )
                ) : (
                  <div className="bg-primary-50 mt-3 rounded-lg p-2">
                    <p className="mon-600-12 text-gray-700">No messages yet!</p>
                    <p className="pt-[10px] mon-400-12 text-gray-500">
                      A few threads to get you started
                    </p>
                    {suggestion.map((s) => (
                      <div
                        className="mr-[15px] px-2 py-2 mt-4 border border-gray-100 bg-white rounded-lg"
                        key={s.id}
                      >
                        <p className="mon-500-12 text-gray-900">{s.title}</p>
                        <div className="flex justify-between items-center mt-2">
                          <p className="mon-400-12 text-gray-500">{s.text}</p>
                          <button
                            onClick={() =>
                              dispatch(
                                updateConversationsMultiple([
                                  {
                                    key: "threadModal",
                                    value: true,
                                  },
                                  {
                                    key: "editThreadDetails",
                                    value: {
                                      threadName: s.title,
                                      description: s.text,
                                    },
                                  },
                                  {
                                    key: "editThread",
                                    value: true,
                                  },
                                  {
                                    key: "suggestedThread",
                                    value: true,
                                  },
                                  {
                                    key: "threadMembers",
                                    value: tenantMembers,
                                  },
                                ])
                              )
                            }
                            className="flex justify-center items-center"
                          >
                            <img
                              src="/images/icons/plus-black.svg"
                              className="w-4 h-4"
                            />
                            <p className="mon-500-12 ml-2.5">Add</p>
                          </button>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            }
            <div className="mt-6">
              <UpcomingEvents />
            </div>
          </div>
        </Col>
      </Row>
      <MyTaskDrawer
        visible={myTaskDrawer}
        onClose={() => setMyTaskDrawer(false)}
        todoList={todoList}
        todoInput={todoInput}
        setTodoInput={setTodoInput}
        todoLoading={todoLoading}
        handleDeleteTodo={handleDeleteTodo}
        handleSaveTodo={handleSaveTodo}
        handleUpdateTodo={handleUpdateTodo}
        handleClearAll={handleClearAll}
      />
    </CustomLayout>
  );
};

export default NewDashboardScreen;
