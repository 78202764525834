import React, { useEffect, useState } from "react";

import CustomModal from "../../Core/Common/CustomModal";
import CustomButton from "../../Core/Common/CustomButton";
import CustomSearch from "../../Core/Common/CustomSearch";
import { CustomSelectWithInlineSearch } from "../../Core/Common/CustomSelectWithSearch";
import { useAppSelector } from "../../Core/redux/hooks";
import CustomSelect from "../../Core/Common/CustomSelect";
import { client } from "../../Core/utils/axiosClient";
import PhaseIcon from "../../Core/svg/PhaseIcon";
import { Breadcrumb, Checkbox, Empty } from "antd";
import { getAllLinks } from "../../Core/redux/api/vaultAPI";

type SelectDocModalProps = {
  visible: boolean;
  onClose: () => void;
  onChange: (arr) => void;
  docType: string;
};

const SelectDocModal = ({
  visible,
  onClose,
  onChange,
  docType,
}: SelectDocModalProps) => {
  const [selectedProject, setSelectedProject] = useState(null);
  const { projectList } = useAppSelector((state) => state.projects);

  const [search, setSearch] = useState("");

  const [folders, setFolders] = useState(null);
  const [files, setFiles] = useState(null);
  const [links, setLinks] = useState(null);
  const [error, setError] = useState(null);
  const [selectedFiles, setSelectedFiles] = useState([]);

  const [foldersArray, setFoldersArray] = useState([]);

  const getFolders = async (payload) => {
    const response = await client.get(`/repository/folder`, {
      params: payload,
    });
    return response.data;
  };

  const getFiles = async (payload) => {
    const response = await client.get(`/repository/all-files`, {
      params: payload,
    });
    return response.data;
  };

  const getLinks = async (payload) => {
    const response = await client.get(`/repository/get-all-links`, {
      params: payload,
    });
    return response.data;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const payload: { projectId: string; folderId?: string } = {
          projectId: selectedProject,
        };

        if (foldersArray && foldersArray.length > 0) {
          payload.folderId = foldersArray[foldersArray.length - 1].id;
        }
        const folderResponse = await getFolders(payload);

        if (docType === "File") {
          const fileResponse = await getFiles(payload);
          setFiles(fileResponse.result);
        }

        if (docType === "Link") {
          const liksResponse = await getLinks(payload);
          setLinks(liksResponse.result);
        }

        setFolders(folderResponse.result);
      } catch (err) {
        setError(err);
      }
    };
    if (selectedProject) {
      fetchData();
    }
  }, [selectedProject, foldersArray]);

  const ownedByMeFolders = folders
    ? folders.filter(
        (folder) =>
          folder.canEdit === 1 &&
          folder.folderName.toLowerCase().includes(search)
      )
    : [];

  const ownedByMeFiles = files
    ? files.filter(
        (file) => file.canEdit === 1 && file.name.toLowerCase().includes(search)
      )
    : [];

  const ownedByMeLInks = links
    ? links.filter(
        (link) => link.canEdit === 1 && link.name.toLowerCase().includes(search)
      )
    : [];

  const handelAddFile = (checked, file) => {
    if (checked) {
      setSelectedFiles((files) => [file]);
    } else {
      setSelectedFiles((files) => files.filter((f) => f.id != file.id));
    }
  };

  const extraBreadcrumbItems = foldersArray.map((_, index) => {
    return (
      <Breadcrumb.Item key={_.id}>
        <span
          className="mon-500-12 cursor-pointer text-gray-700"
          onClick={() => {
            const updatedArray = [...foldersArray]; // create a copy of the original array
            updatedArray.splice(index + 1); // remove all items after the index
            setFoldersArray(updatedArray); // update the state
          }}
        >
          {_.folderName}
        </span>
      </Breadcrumb.Item>
    );
  });

  const breadcrumbItems = [
    <Breadcrumb.Item key="home">
      <span
        className="mon-500-12 cursor-pointer text-gray-700"
        onClick={() => setFoldersArray([])}
      >
        Vault
      </span>
    </Breadcrumb.Item>,
  ].concat(extraBreadcrumbItems);

  return (
    <CustomModal
      title={<Breadcrumb>{breadcrumbItems}</Breadcrumb>}
      width={"384px"}
      visible={visible}
      body={
        <div className="max-h-[30vh] overflow-y-scroll">
          <CustomSearch
            className=" border-2 border-gray-300 mx-4 my-4"
            placeholder="Search folder"
            style={{
              borderWidth: "1px",
              borderRadius: "8px",
            }}
            search={search}
            setSearch={(e) => setSearch(e.toLowerCase())}
          />

          <div className="mt-1.5 mx-4">
            <CustomSelect
              value={selectedProject}
              onChange={(e) => {
                setFoldersArray([]);
                setSelectedProject(e);
              }}
              options={
                projectList?.length > 0
                  ? projectList.map((item, i) => {
                      return {
                        id: item.id?.toString() + i,
                        title: item.title,
                        value: item.id,
                        render: () => (
                          <p className="mon-400-14 pl-2 text-gray-900 capitalize ">
                            {item.title}
                          </p>
                        ),
                      };
                    })
                  : []
              }
              placeholder="Select project"
            />
          </div>
          {selectedProject ? (
            <></>
          ) : (
            <div className="h-full w-full flex my-2 justify-center items-center">
              <Empty
                description={
                  <div className="mon-400-12 text-gray-500 ">
                    No Project Selected
                  </div>
                }
              />
            </div>
          )}
          {ownedByMeFiles.length === 0 &&
          ownedByMeFolders.length === 0 &&
          ownedByMeLInks.length === 0 &&
          selectedProject ? (
            <div className="h-full w-full flex my-2 justify-center items-center">
              <Empty
                description={
                  <div className="mon-400-12 text-gray-500 ">
                    No Files Or Folders Available
                  </div>
                }
              />
            </div>
          ) : (
            <div className="max-w-[492px] mx-4 my-4">
              <div>
                {ownedByMeFolders?.map((folder) => (
                  <div
                    className="flex items-center cursor-pointer pt-4"
                    key={folder.id}
                    onClick={() => setFoldersArray((arr) => [...arr, folder])}
                  >
                    {folder.phaseId && (
                      <div className="mr-2">
                        <PhaseIcon
                          phaseId={1}
                          width="12px"
                          height="12px"
                          icon={false}
                        />
                      </div>
                    )}

                    <img
                      src="/images/icons/folder-fill-vault.svg"
                      alt="avatar"
                      className="w-5 h-5 "
                    />
                    <p className="mon-400-12 text-gray-700 capitalize ml-2">
                      {folder.folderName}
                    </p>
                  </div>
                ))}
                {ownedByMeFiles?.map((file) => (
                  <div className="flex items-center pt-4" key={file.id}>
                    <Checkbox
                      value={file.id}
                      onChange={(e) => handelAddFile(e.target.checked, file)}
                      checked={
                        selectedFiles.findIndex((f) => f.id === file.id) !== -1
                      }
                    />
                    <p className="mon-400-12 text-gray-700  ml-2">
                      {file.name}
                    </p>
                  </div>
                ))}
                {ownedByMeLInks?.map((file) => (
                  <div className="flex items-center pt-4" key={file.id}>
                    <Checkbox
                      value={file.id}
                      onChange={(e) => handelAddFile(e.target.checked, file)}
                      checked={
                        selectedFiles.findIndex((f) => f.id === file.id) !== -1
                      }
                    />
                    <p className="mon-400-12 text-gray-700  ml-2">
                      {file.name}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      }
      footer={
        <div className="flex justify-between py-2.5 px-3">
          <CustomButton
            text="Grant Access"
            onClick={() => {
              onChange(selectedFiles);
              onClose();
            }}
            className="mon-500-16 text-white bg-primary-600 "
            height="44px"
            width="100%"
            disabled={!selectedProject || selectedFiles.length == 0}
          />
        </div>
      }
      onCancel={() => {
        onClose();
      }}
    />
  );
};

export default SelectDocModal;
