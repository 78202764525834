import React from "react";

const FrostyErrorMessage = ({ visible, messgae, className = "" }) => {
  if (visible) {
    return (
      <div className={`flex  items-center ${className}`}>
        <img
          src="/images/icons/frosty-icon.svg"
          className={`min-h-[26px] min-w-[26px]`}
          alt="frosty"
        />
        <p className="mon-400-16 text-error-500 pl-2">{messgae}</p>
      </div>
    );
  }
  return <></>;
};

export default FrostyErrorMessage;
