import React, { useState } from "react";
import CustomModal from "../../../Core/Common/CustomModal";
import CustomButton from "../../../Core/Common/CustomButton";
import { useAppDispatch, useAppSelector } from "../../../Core/redux/hooks";
import { updateSchedule } from "../../../Core/redux/slices/schedule.slice";
import CustomSelect from "../../../Core/Common/CustomSelect";
import { Input } from "antd";

type NewScopeModalProps = {
  visible: boolean;
  onCancel: () => void;
};

const NewScopeModal = ({ visible, onCancel }: NewScopeModalProps) => {
  const [showError, setShowError] = useState(false);
  const [newSprintModal, setNewSprintModal] = useState(false);
  const [reason, setReason] = useState("");

  const [addGoal, setAddGoal] = useState(false);

  const { sprints, selectedSprint, currentSelectedPhase } = useAppSelector(
    (state) => state.schedule
  );

  const dispatch = useAppDispatch();

  const handleChange = (e) => {
    setReason(e.target.value);
    if (e.target.value.length < 1) return setShowError(true);
    setShowError(false);
  };

  return (
    <div>
      <CustomModal
        title={
          <div className="flex items-center gap-x-2">
            <p className="mon-400-12 text-gray-500">
              {currentSelectedPhase.phases}
            </p>
            <img src="/images/icons/chevron-right.svg" className="w-4 h-4" />
            <p className="mon-500-12 text-gray-700">New Scope</p>
          </div>
        }
        width={"524px"}
        visible={visible}
        body={
          <div className=" max-h-[60vh]  overflow-y-scroll">
            <div className="flex items-center px-5 pt-5">
              <img
                src="/images/icons/frosty-help.svg"
                className="h-9 w-9"
                alt="frosty"
              />
              <p className="text-sm font-medium text-primary-600 ml-2">
                What would you like to add as a new scope in this phase?
              </p>
            </div>
            {addGoal ? (
              <div className=" mt-6 px-4">
                <p className="mon-500-14 text-gray-700 pb-1.5">Select sprint</p>
                <div>
                  <CustomSelect
                    border
                    showBorderOnlyOnFocused
                    options={sprints.map((s) => {
                      return {
                        id: s.id,
                        value: s.id,
                        render: () => <p>{s.name}</p>,
                      };
                    })}
                    value={selectedSprint.id}
                    onChange={(e) => {
                      dispatch(
                        updateSchedule({
                          key: "selectedSprint",
                          value: sprints.find((s) => s.id === e),
                        })
                      );
                    }}
                  />
                </div>
              </div>
            ) : (
              <></>
            )}

            <div className="mon-500-14 text-gray-700 pt-6 pb-4 px-5">
              Reason
              <span className=" pl-1 text-error-600">(required)</span>
            </div>
            <div
              className="pl-5 pr-2 bg-white rounded-2xl border-error-600"
              style={{ width: "510px" }}
            >
              <Input.TextArea
                rows={5}
                placeholder="Enter a description..."
                required={true}
                className="w-full h-full bg-white rounded-xl border border-gray-300 shadow-none text-gray-500 pl-4 pt-2 hover:outline-none active:outline-none focus:outline-none"
                value={reason}
                onChange={(e) => {
                  handleChange(e);
                }}
              ></Input.TextArea>

              {showError ? (
                <p className="text-sm font-medium text-error-600 ml-2">
                  It is important to add a reason here
                </p>
              ) : (
                <p className="text-sm font-medium text-gray-400 pt-1.5">
                  Adding a reason helps keep a record for everyone.
                </p>
              )}
            </div>
          </div>
        }
        footer={
          <div className="flex justify-between pt-8 pb-4 px-3">
            <CustomButton
              text="Create New Task"
              onClick={() => {
                if (addGoal) {
                  onCancel();

                  dispatch(
                    updateSchedule({
                      key: "newTaskModal",
                      value: {
                        visible: true,
                        isNewScope: true,
                        scopeDescription: reason,
                      },
                    })
                  );
                } else {
                  setAddGoal(true);
                }
              }}
              className="border-2 border-gray-200 text-black bg-white mon-500-16 "
              height="44px"
              width="49%"
              disabled={showError || reason.length === 0}
            />
            <CustomButton
              text="Create New Sprint"
              onClick={() => {
                onCancel();
                dispatch(
                  updateSchedule({
                    key: "newSprintModal",
                    value: {
                      visible: true,
                      isNewScope: true,
                      scopeDescription: reason,
                    },
                  })
                );
                dispatch(
                  updateSchedule({ key: "newSprintScope", value: true })
                );
              }}
              className={`mon-500-16 text-white  bg-primary-600 ${
                showError || reason.length === 0 ? "opacity-50" : ""
              }`}
              height="44px"
              width="49%"
              disabled={showError || addGoal || reason.length === 0}
            />
          </div>
        }
        onCancel={onCancel}
      />
    </div>
  );
};
export default NewScopeModal;
