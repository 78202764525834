import React, { useState } from "react";
import CustomModal from "../../../Core/Common/CustomModal";
import CustomButton from "../../../Core/Common/CustomButton";
import { useAppDispatch, useAppSelector } from "../../../Core/redux/hooks";

import { useNavigate, useParams } from "react-router-dom";
import CustomFrostyMessage from "../../../Core/Common/CustomFrostyMessage";
import { DatePicker } from "antd";
import axios from "axios";
import { client } from "../../../Core/utils/axiosClient";
import dayjs from "dayjs";

type CompleteProjectModalProps = {
  visible: boolean;

  onCancel: () => void;
};

const CompleteProjectModal = ({
  visible,

  onCancel,
}: CompleteProjectModalProps) => {
  const dispatch = useAppDispatch();

  const { projectId } = useParams();
  const { projectList } = useAppSelector((state) => state.projects);
  const { projectDetails } = useAppSelector((state) => state.overview);
  const [completeProjectModal, setCompleteProjectModal] = useState(false);
  const { phases } = useAppSelector((state) => state.schedule);

  const isOngoingPhase = phases.findIndex((p) => p.isOngoing === 1);

  const navigate = useNavigate();

  const handleCompleteProject = async () => {
    await client.put("project/complete-project", { projectId });
    navigate("/projects?tab=Completed");
  };

  const currentProject = projectList.find(({ id }) => id == projectId);
  // console.log(currentProject, "current project check");

  return (
    <>
      <CustomModal
        title={
          <p className="mon-500-16 text-gray-700">Complete this project</p>
        }
        width={"524px"}
        visible={visible}
        body={
          <div className="max-h-[38.189386056191466vh] overflow-y-scroll">
            {isOngoingPhase !== -1 && (
              <div className="mon-400-14 text-gray-500 pt-3 px-5">
                <CustomFrostyMessage close={false} shadow={false}>
                  <div className="mon-500-14 text-primary-600">
                    Uh oh, looks like one or more phases are still ongoing
                  </div>
                </CustomFrostyMessage>
              </div>
            )}
            <div className="mon-400-14 text-gray-500 pt-5 pb-4 px-5">
              Are you sure you want to complete this project now? This action
              cannot be undone.
            </div>
          </div>
        }
        footer={
          <div className="flex justify-between pt-8 pb-4 px-3">
            <CustomButton
              text="View phases"
              onClick={() =>
                navigate(
                  `/schedule/${projectId}?selectedPhase=${
                    phases[0]?.phases || ""
                  }`
                )
              }
              className="border-2 border-gray-200 text-black bg-white mon-500-16 "
              height="44px"
              width="49%"
            />
            <CustomButton
              text="Yes, Continue"
              // onClick={handleDelete}
              //className="mon-500-16 text-white  bg-error-600 "
              onClick={() => {
                setCompleteProjectModal(true);
                onCancel();
              }}
              height="44px"
              width="49%"
            />
          </div>
        }
        onCancel={onCancel}
      />{" "}
      <CustomModal
        title={
          <p className="mon-500-16 text-gray-700">Complete this project</p>
        }
        width={"524px"}
        visible={completeProjectModal}
        body={
          <div className="max-h-[38.189386056191466vh] overflow-y-scroll">
            <div className="mon-400-14 text-gray-500 pt-5 px-5">
              Completing the project before the end date entered originally.
              Completed project will not be deleted from Waddle. You can view
              them in{" "}
              <span
                onClick={() => navigate("/projects?tab=Completed")}
                className="text-primary-600 cursor-pointer underline"
              >
                projects {">"} completed
              </span>
            </div>
            <div className="px-5  mt-5">
              <div className="mon-500-14 text-gray-700">Start date</div>
              <DatePicker
                // value={dayjs(currentProject?.startDate) as any}
                value={
                  projectDetails.startDate
                    ? (dayjs(projectDetails.startDate) as any)
                    : null
                }
                className="w-full h-10 bg-transparent border-gray-300 rounded-xl shadow-xs"
                suffixIcon={<></>}
                format="DD/MM/YYYY"
                disabled
              />
            </div>
            <div className="px-5  mt-5">
              <div className="mon-500-14 text-gray-700">
                End date{" "}
                <span className="text-gray-500 mon-400-14">(Original)</span>
              </div>
              <DatePicker
                // value={dayjs(currentProject?.endDate) as any}
                value={
                  projectDetails.endDate
                    ? (dayjs(projectDetails.endDate) as any)
                    : null
                }
                className="w-full h-10 bg-transparent border-gray-300 rounded-xl shadow-xs"
                suffixIcon={<></>}
                format="DD/MM/YYYY"
                disabled
              />
            </div>
          </div>
        }
        footer={
          <div className="flex justify-between pt-8 pb-4 px-3">
            <CustomButton
              text="Cancel"
              onClick={() => {
                onCancel();
                setCompleteProjectModal(false);
              }}
              className="border-2 border-gray-200 text-black bg-white mon-500-16 "
              height="44px"
              width="49%"
            />
            <CustomButton
              text="Complete Project"
              onClick={handleCompleteProject}
              //className="mon-500-16 text-white  bg-error-600 "
              height="44px"
              width="49%"
            />
          </div>
        }
        onCancel={() => setCompleteProjectModal(false)}
      />
    </>
  );
};

export default CompleteProjectModal;
