import React, { Children, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { CustomResTooltip } from "../../../Core/Common/CustomResTooltip";
import { getMyTasks } from "../../../Core/redux/api/dashboardAPI";
import { useAppDispatch, useAppSelector } from "../../../Core/redux/hooks";
import TaskTypeIcon from "../../../Core/svg/TaskTypeIcon";
import { formatDateInRelativeTime } from "../../../Core/utils/date";

const upcomingGoals = [
  {
    id: 1,
    phaseIcon: "/images/icons/yellow-square.svg",
    goalName: "Creating IA",
    dueDate: "Yesterday",
    dueDateColor: "text-error-600",
    priorityIcon: "/images/icons/red-priority-flag.svg",
  },
  {
    id: 2,
    phaseIcon: "/images/icons/yellow-square.svg",
    goalName: "User Stories for 3 main roles",
    dueDate: "Today",
    dueDateColor: "text-primary-600",
    priorityIcon: "/images/icons/blue-priority-flag.svg",
  },
  {
    id: 3,
    phaseIcon: "/images/icons/pink-square.svg",
    goalName: "Creating moodboard",
    dueDate: "Tomorrow",
    dueDateColor: "text-gray-600",
    priorityIcon: "/images/icons/blue-priority-flag.svg",
  },
  {
    id: 4,
    phaseIcon: "/images/icons/pink-square.svg",
    goalName: "User Stories for 3 main roles",
    dueDate: "11 Jun",
    dueDateColor: "text-gray-600",
    priorityIcon: "/images/icons/grey-priority-flag.svg",
  },
  {
    id: 5,
    phaseIcon: "/images/icons/google-calendar.svg",
    goalName: "Mazi x Antarctica Project",
    dueDate: "14 Jun",
    dueDateColor: "text-gray-600",
    priorityIcon: "/images/icons/grey-priority-flag.svg",
  },
  {
    id: 6,
    phaseIcon: "/images/icons/pink-square.svg",
    goalName: "Setting up design style",
    dueDate: "15 jun",
    dueDateColor: "text-gray-600",
    priorityIcon: "/images/icons/blue-priority-flag.svg",
  },
];

export const UpcomingGoalItem = ({
  goals = [],
  text,
  title,
  children,
}: {
  goals: any[];
  text: string;
  title: string;
  children?: React.ReactNode;
}) => {
  const getPriorityIcon = (val) => {
    switch (val) {
      case 1:
        return (
          <div className="w-3 h-3 pt-0.5">
            <img
              src="/images/icons/grey-priority-flag.svg"
              alt="priority"
              className="w-full h-full"
            />
          </div>
        );
      case 2:
        return (
          <div className="w-3 h-3 pt-0.5">
            <img
              src="/images/icons/blue-priority-flag.svg"
              alt="priority"
              className="w-full h-full"
            />
          </div>
        );
      case 3:
        return (
          <div className="w-3 h-3 pt-0.5">
            <img
              src="/images/icons/red-priority-flag.svg"
              alt="priority"
              className="w-full h-full"
            />
          </div>
        );
      default:
        return (
          <div className="w-3 h-3 pt-0.5">
            <img
              src="/images/icons/grey-priority-flag.svg"
              alt="priority"
              className="w-full h-full"
            />
          </div>
        );
    }
  };
  const getGoalsCompletePercentage = () => {
    const totalGoals = goals.length;
    const completedGoals = goals.filter((g) => g.isCompleted).length;
    return Math.round((completedGoals / totalGoals) * 100);
  };

  const getColor = (text, isCompleted) => {
    if (text === "Today") return "text-primary-600 mon-500-12";
    if (text === "Yesterday" && isCompleted) {
      return "";
    } else if (text === "Yesterday" && !isCompleted) {
      return "text-error-600 mon-500-12";
    }
    return "";
  };
  if (goals.length > 0) {
    return (
      <div className="mt-3">
        {goals.map((item) => (
          <div
            className="flex justify-between pt-4 pb-4 pl-7 pr-7 hover:bg-main-background"
            key={item.id}
          >
            <div className="flex my-auto">
              <TaskTypeIcon
                type={item.isNewGoal ? "newscope" : +item.goalType}
                width="12"
                height="12"
              />
              <div className="text-xs font-medium ml-3 text-black my-auto">
                {item.name}
              </div>
              {/* <CustomResTooltip
                  text={item.goalName}
                  textClassName="text-xs font-medium ml-3 text-black my-auto max-w-[20vw] truncate"
                /> */}
            </div>

            <div className="flex items-center justify-start my-auto">
              {/* <div
                className={`text-xs font-normal capitalize ${
                  //   getColor(
                  //   formatDateInRelativeTime(item.dueDate),
                  //   item.isCompleted
                  // )
                  formatDateInRelativeTime(item.dueDate, item.isCompleted)
                    .className
                }`}
              >
                {formatDateInRelativeTime(item.dueDate, item.isCompleted).text}
              </div> */}
              <div className="w-[3.8vw] flex items-center justify-end pr-2">
                {getPriorityIcon(item.priorityId)}
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  } else {
    return (
      <div className="pt-6 pl-7 pr-7">
        <div className="w-full  rounded-lg bg-primary-50">
          <div className="flex items-center text-gray-700 ml-2">
            <div className="w-5 h-5 mt-2 font-semibold">
              <img
                src="/images/icons/upcoming-goals-icon.svg"
                alt="chart"
                className="w-full h-full"
              />
            </div>
            <div className="pl-3.5 pt-3 text-xs font-semibold text-gray-700 ">
              {title}
            </div>
          </div>
          <div className="text-xs ml-2 pt-[11px] font-normal mr-6 text-gray-500">
            {text}
          </div>
          {children}
        </div>
      </div>
    );
  }
};

const UpcomingGoals: React.FC = () => {
  const { projectId } = useParams();
  const { phases } = useAppSelector((state) => state.schedule);
  const { myTasks } = useAppSelector((state) => state.dashboard);
  const { user } = useAppSelector((state) => state.userDetails);
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(
      getMyTasks({
        userId: user.id,
        status: 1,
        projectId: projectId,
      })
    );
  }, [projectId]);
  return (
    <div className="shadow-box w-full h-full  bg-white rounded-2xl max-h-[425px] ">
      <div className="flex justify-between pt-6 ml-7 mr-7">
        <div className="flex text-base font-medium text-black">
          Upcoming Tasks
        </div>
        <Link
          to={`/schedule/${projectId}?phase=${phases[0]?.phases}`}
          className="flex text-sm font-medium text-primary-600"
        >
          View all
        </Link>
      </div>
      <div className="flex justify-between pt-6 ml-7 mr-7">
        <div className="flex uppercase text-[10px] font-medium text-gray-500 max-w-[19vw] min-w-[10vw]">
          Task name
        </div>
        <div className="flex items-center">
          {/* <div className="uppercase font-medium text-[10px] text-gray-500 float-left">
            Due Date
          </div> */}
          <div className="uppercase font-medium text-[10px] text-gray-500 pl-6 ">
            Priority
          </div>
        </div>
      </div>
      <div className="max-h-[330px] overflow-y-scroll">
        <UpcomingGoalItem
          goals={myTasks}
          text="Looks like no phases are started for this project yet"
          title="No upcoming tasks for you!"
        />
      </div>
    </div>
  );
};

export default UpcomingGoals;
