import { Divider } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CustomAvatar from "../../Core/Common/CustomAvatar";
import CustomButton from "../../Core/Common/CustomButton";
import CustomSelect from "../../Core/Common/CustomSelect";
import {
  changeWorkspace,
  getWorkspaces,
} from "../../Core/redux/api/dashboardAPI";
import { getTenantDetails } from "../../Core/redux/api/tenantAPI";
import { useAppDispatch, useAppSelector } from "../../Core/redux/hooks";
import { changeTenant } from "../../Core/redux/slices/tenant.slice";

const SwitchWorkspaceScreen = () => {
  const { currentTenantId } = useAppSelector((state) => state.tenant);
  const dispatch = useAppDispatch();

  const handleWorkSpaces = async () => {
    const workspaces = await dispatch(getWorkspaces());
    if (workspaces.payload) {
      if (workspaces.payload.length === 1) {
        navigate("/dashboard");
      } else if (workspaces.payload.length === 0) {
        navigate("/onboarding");
      }
    }
  };

  useEffect(() => {
    handleWorkSpaces();
  }, []);
  const [tenantId, setTenantId] = useState(currentTenantId);
  const { workspaces } = useAppSelector((state) => state.dashboard);
  const { user } = useAppSelector((state) => state.userDetails);

  const navigate = useNavigate();
  const handleSwitchWorkSpace = async () => {
    // dispatch(resetToInitialActivityLogs({}));
    // dispatch(resetToInitialConversation({}));
    // dispatch(resetToInitialDashBoard({}));
    // dispatch(resetToInitialNotification({}));
    // dispatch(resetToInitialOnboarding({}));
    // dispatch(resetToInitialGlobal({}));
    // dispatch(resetToInitialOverview({}));
    // dispatch(resetToInitialPreview({}));
    // dispatch(resetToInitialProjectOnboarding({}));
    // dispatch(resetToInitialProjects({}));
    // dispatch(resetToInitialSchedule({}));
    // dispatch(resetToInitialSetting({}));
    // dispatch(resetToInitialVault({}));

    dispatch(changeTenant(tenantId));

    await dispatch(changeWorkspace({ tenantId }));
    //location.reload();
    dispatch(getTenantDetails({}));
    navigate("/dashboard");
  };

  const tenantName = workspaces
    ? workspaces.find((work) => work.tenantId == user.tenant_id)?.tenantName ||
      ""
    : "";
  return (
    <div className="p-6 w-full h-full">
      <div className="w-[107px] h-[33px]">
        <img
          className="w-full h-full "
          src="/images/icons/waddle-logo.svg"
          alt="waddle logo"
        />
      </div>

      <div className="flex justify-center mt-12 ">
        <div className="w-[407px]">
          <div className="mon-600-24 text-primary-600 mb-6">
            Select your Workspace
          </div>
          <div className="mon-500-14 text-gray-700 mb-1.5"> Workspace</div>
          <div className=" mb-6">
            <CustomSelect
              value={tenantId === "" ? null : tenantId}
              border={true}
              placeholder="Select the workspace"
              showBorderOnlyOnFocused
              options={workspaces?.map((p) => {
                return {
                  id: p.tenantId,
                  title: p.tenantName,
                  value: p.tenantId,
                  render: (value) => (
                    <div
                      className="flex items-center -mt-0.5"
                      key={p.id + "phases"}
                    >
                      <CustomAvatar size={14} title={p.tenantName} />

                      <p className="mon-400-14 pl-2 text-gray-900 capitalize ">
                        {p.tenantName}
                      </p>
                    </div>
                  ),
                };
              })}
              onChange={(e) => setTenantId(e)}
            />
          </div>

          <CustomButton
            text="Continue"
            className="mon-500-14 text-white bg-primary-600 "
            height="36px"
            width="100%"
            disabled={tenantId === ""}
            onClick={() => {
              handleSwitchWorkSpace();
            }}
          />
          <Divider />

          <div className="mon-400-14 text-gray-500">Recent login</div>
          <div
            className="flex justify-between items-center shadow-xs rounded-xl bg-[#EFF8FF] mt-6 p-[10px] px-4 cursor-pointer "
            onClick={() => {
              navigate("/dashboard");
            }}
          >
            <div className="mon-500-14 text-primary-700">{tenantName}</div>
            <div>
              <img src="/images/icons/chevron-right.svg" />
            </div>
          </div>
          <Divider>
            <div className="mon-400-14 text-gray-500">Or</div>
          </Divider>
          <CustomButton
            text="Create Your Own"
            className="mon-500-14 text-gray-700 bg-white border shadow-xs "
            height="36px"
            width="100%"
            onClick={() => {
              navigate("/onboarding");
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default SwitchWorkspaceScreen;
