import { Collapse, Divider, Empty, Spin } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import CustomAvatar from "../../Core/Common/CustomAvatar";
import CustomSearch from "../../Core/Common/CustomSearch";
import CustomLayout from "../../Core/Layout/CustomLayout";
import { useAppDispatch, useAppSelector } from "../../Core/redux/hooks";
import {
  muteThread,
  resetConversations,
  updateConversations,
  updateConversationsMultiple,
} from "../../Core/redux/slices/conversations.slice";
import LeaveThreadModal from "./Common/LeaveThreadModal";
import ChatHeader from "./Components/ChatHeader";
import MenuDropdown from "./Components/MenuDropdown";
import NewMessageModal from "./Components/NewMessageModal";
import ThreadModal from "./Components/ThreadModal";
import ConversationsChatInput from "./ConversationChatInput";
import FloatButton from "./FloatButton";
import ShareModal from "./Common/ShareModal";
import SaveInVaultModal from "./Common/SaveInVaultModal";
import { ObjectID } from "bson";

const { Panel } = Collapse;
import MainChatWindow from "./MainChatWindow";
import DeleteFileModal from "./Common/DeleteFileModal";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import {
  forwardMessageToUsers,
  getPrivateMessages,
  getProjectsWithThreads,
  getThreadMessages,
  getThreads,
  getThreadsMembers,
  getUnreadCountDm,
} from "../../Core/redux/api/conversationAPI";
import PhaseIcon from "../../Core/svg/PhaseIcon";
import { send } from "process";
import dayjs from "dayjs";
import SuggestedThreadSidebar from "./Components/SuggestedThreadSidebar";
import DeleteThreadModal from "./Common/DeleteThreadModal";
import {
  getOrganizationUsers,
  getProjectUsers,
} from "../../Core/redux/api/projectAPI";
import io from "socket.io-client";
import { debounce } from "../../Core/utils";
import { chatClient, client } from "../../Core/utils/axiosClient";
import { resetVault } from "../../Core/redux/slices/vault.slice";
import { updateGlobal } from "../../Core/redux/slices/global.slice";

const ConversationsScreen = ({ socket }) => {
  const {
    threadModal,
    leaveThread,
    deleteThread,
    newMessageModal,
    shareMessageModal,
    deleteFileModal,
    saveInVaultModal,
    shareType,
    threads,
    getMessageLoading,
    directMessageUsers,
    forwardMessage,
    currentThread,
    projectsWithThreads,
  } = useAppSelector((state) => state.conversations);

  const { user } = useAppSelector((state) => state.userDetails);

  useEffect(() => {
    socket.on("error", (error) => {
      console.log("SOCKET_ERROR:", error);
    });
    socket.on("connect", () => {
      console.log("SOCKET_ERROR:Connected to the server");
    });

    socket.on("disconnect", () => {
      console.log("SOCKET_ERROR:Disconnected from the server");
    });
  }, [socket]);

  const { userList } = useAppSelector((state) => state.overview);
  const dispatch = useAppDispatch();

  const { projectId } = useParams();
  const [threadSeeMoreActive, setThreadSeeMoreActive] = useState(false);
  const [projectSeeMoreActive, setProjectSeeMoreActive] = useState(false);
  const [threadIdForColor, setThreadIdForColor] = useState(0);

  const getDM = async () => {
    await dispatch(getOrganizationUsers({ userId: user.id }));
    await dispatch(getUnreadCountDm({ userId: user.id }));
  };

  const updateUnreadCount = async () => {
    try {
      const { data } = await chatClient.put(
        `/conversation/update-unread-count?userId=${user.id}`
      );
      dispatch(updateGlobal({ key: "unreadConversationCount", value: 0 }));
      console.log(data);
    } catch (e) {
      dispatch(updateGlobal({ key: "unreadConversationCount", value: 0 }));
      console.log("some Error Occured");
    }
  };

  useEffect(() => {
    updateUnreadCount();
    return () => {
      dispatch(resetConversations(["currentThread"]));
    };
  }, []);

  const [search, setSearch] = useState("");
  useEffect(() => {
    dispatch(getProjectsWithThreads({ userId: user.id }));
    dispatch(getThreads({ userId: user.id }));
    getDM();
  }, [user.id]);

  useEffect(() => {
    dispatch(getProjectsWithThreads({ userId: user.id }));
  }, []);

  const joinThread = async (thread, projectId) => {
    const threadMessageOlder =
      thread.messages && thread.messages?.length > 0 ? thread.messages : [];

    dispatch(
      updateConversationsMultiple([
        {
          value: { ...thread, type: "group", limit: 20, offset: 0, projectId },
          key: "currentThread",
        },
        {
          key: "currentThreadMessage",
          value: threadMessageOlder,
        },
      ])
    );

    dispatch(resetConversations(["replyMessage", "editMessage"]));
    socket.emit("joinroom", {
      newThread: thread.threadId,
      prevThread: currentThread.threadId,
      joiningUserId: user.id,
    });
    console.log("[SOCKET]:joined room event");

    await dispatch(getThreadsMembers({ threadId: thread.threadId }));
    // scrollToChatBottom();
  };
  const joinPrivateThread = async (reciver) => {
    dispatch(
      updateConversationsMultiple([
        {
          value: { ...reciver, type: "private", limit: 20, offset: 0 },
          key: "currentThread",
        },
        {
          key: "currentThreadMessage",
          value:
            reciver.messages && reciver.messages?.lengrh > 0
              ? reciver.messages
              : [],
        },
      ])
    );

    dispatch(resetConversations(["replyMessage", "editMessage"]));
  };

  useEffect(() => {
    document.getElementById("conversation-chat-input")?.focus();
    if (currentThread.type === "private" && currentThread.userId) {
      dispatch(
        getPrivateMessages({
          userId: user.id,
          reciverId: +currentThread.userId,
          limit: 20,
          offset: 0,
        })
      );
    } else if (currentThread.threadId) {
      dispatch(
        getThreadMessages({
          threadId: currentThread.threadId,
          limit: 20,
          offset: 0,
        })
      );
    }
  }, [currentThread]);

  const handelForwardMessage = async (selectedUsers) => {
    const { firstName, lastName, id, profilePicture } = user;

    const newMessages = selectedUsers.map((userId) => {
      const objectID = new ObjectID();
      return {
        ...forwardMessage,
        reciverId: userId,
        senderId: user.id,
        threadId: null,
        senderName: user.firstName + " " + user.lastName,
        _id: objectID.toString(),
      };
    });
    await dispatch(forwardMessageToUsers({ messages: newMessages }));
    dispatch(resetConversations(["forwardMessage", "shareMessageModal"]));
  };

  // const handelSearch = (array, parentKey, childSearchKey) => {
  //   const Arr = array.filter((a) => {
  //     if (Object.prototype.hasOwnProperty.call(a, parentKey)) {
  //       const chlidArray = a[parentKey].filter(
  //         (child) => child[childSearchKey] === search
  //       );
  //       console.log(chlidArray, a[parentKey], "xxxxx2");
  //       return chlidArray.length > 0;
  //     }
  //   });
  //   return Arr;
  // };

  const handelUserSearch = (arr) => {
    const searchArray = directMessageUsers.filter((user) => {
      if (user.firstname) {
        return (
          user.firstname.toLowerCase().includes(search.toLowerCase()) ||
          user.lastname.toLowerCase().includes(search.toLowerCase()) ||
          user.email.toLowerCase().includes(search.toLowerCase())
        );
      }
    });
    return searchArray;
  };

  // function sortUsers(originalArray, user) {
  //   try {
  //     const sortedArray = originalArray.sort((a, b) => {
  //       if (a.userId === user.id) {
  //         return -1;
  //       } else if (b.userId === user.id) {
  //         return 1;
  //       } else {
  //         if (a.messages && b.messages) {
  //           const dateA = new Date(
  //             a.messages[a.messages.length - 1].createdAt
  //           ) as any;
  //           const dateB = new Date(
  //             b.messages[b.messages.length - 1].createdAt
  //           ) as any;
  //           return dateB - dateA;
  //         } else {
  //           return b.unreadCount - a.unreadCount;
  //         }
  //       }
  //     });
  //     return sortedArray;
  //   } catch (err) {
  //     console.error("An error occurred while sorting the array: ", err);
  //     return originalArray;
  //   }
  // }

  // function sortUsers(originalArray, user) {
  //   const duplicateArray = [...originalArray];

  //   try {
  //     const sortedArray = duplicateArray.sort((a, b) => {
  //       if (a.userId === user.id) {
  //         return -1;
  //       } else if (b.userId === user.id) {
  //         return 1;
  //       } else {
  //         if (a.messages?.length && b.messages?.length) {
  //           const dateA = new Date(
  //             a.messages[a.messages.length - 1].createdAt
  //           ) as any;
  //           const dateB = new Date(
  //             b.messages[b.messages.length - 1].createdAt
  //           ) as any;
  //           return dateB - dateA;
  //         } else if (a.unreadCount && b.unreadCount) {
  //           return (b.unreadCount || 0) - (a.unreadCount || 0);
  //         } else {
  //           return 1;
  //         }
  //       }
  //     });
  //     return sortedArray;
  //   } catch (err) {
  //     console.error("An error occurred while sorting the array: ", err);
  //     return originalArray;
  //   }
  // }

  const sortUsers = useCallback(
    (originalArray, user) => {
      const duplicateArray = [...originalArray];
      console.log(duplicateArray, "duplicateArray");
      try {
        const sortedArray = duplicateArray.sort((a, b) => {
          if (a.userId === user.id) {
            return -1;
          } else if (b.userId === user.id) {
            return 1;
          } else {
            if (a.messages?.length && b.messages?.length) {
              const dateA = new Date(
                a.messages[a.messages.length - 1].createdAt
              ) as any;
              const dateB = new Date(
                b.messages[b.messages.length - 1].createdAt
              ) as any;
              return dateB - dateA;
            } else if (a.unreadCount || b.unreadCount) {
              if (a.unreadCount && !b.unreadCount) {
                return -1;
              } else if (b.unreadCount && !a.unreadCount) {
                return 1;
              } else if (a.unreadCount && b.unreadCount) {
                return (b.unreadCount || 0) - (a.unreadCount || 0);
              }
            } else {
              if (a.messages?.length) {
                return -1;
              } else if (b.messages?.length) {
                return 1;
              } else {
                return 0;
              }
            }
          }
        });
        return sortedArray;
      } catch (err) {
        console.error("An error occurred while sorting the array: ", err);
        return originalArray;
      }
    },
    [directMessageUsers]
  );

  const sortThreads = useCallback(
    (originalArray) => {
      const duplicateArray = [...originalArray];
      try {
        const sortedArray = duplicateArray.sort((a, b) => {
          if (
            a.messages &&
            b.messages &&
            a.messages.length &&
            b.messages.length
          ) {
            const dateA = new Date(
              a.messages[a.messages.length - 1].createdAt
            ) as any;
            const dateB = new Date(
              b.messages[b.messages.length - 1].createdAt
            ) as any;
            return dateB - dateA;
          } else if (a.unreadCount || b.unreadCount) {
            if (a.unreadCount && !b.unreadCount) {
              return -1;
            } else if (b.unreadCount && !a.unreadCount) {
              return 1;
            } else if (a.unreadCount && b.unreadCount) {
              return (b.unreadCount || 0) - (a.unreadCount || 0);
            }
          } else {
            if (a.messages?.length) {
              return -1;
            } else if (b.messages?.length) {
              return 1;
            } else {
              return 0;
            }
          }
        });
        return sortedArray;
      } catch (err) {
        console.error("An error occurred while sorting the array: ", err);
        return originalArray;
      }
    },
    [threads, projectsWithThreads]
  );

  const handelProjectSearch = (arr) => {
    //let arr = []
    const searchArr = projectsWithThreads.map((project) => {
      const projThreds = project.threads.filter((t) =>
        t.threadName.toLowerCase().includes(search.toLowerCase())
      );
      const newP = { ...project, threads: sortThreads(projThreds) };
      if (projThreds.length > 0) {
        return newP;
      }
    });

    return searchArr.filter((a) => typeof a !== "undefined");
  };

  const handelProjectSearchMemo = React.useMemo(
    () => handelProjectSearch(projectsWithThreads),
    [search, projectsWithThreads]
  );

  // console.log(handelProjectSearchMemo, "xxxxx");
  const projectSection = () => {
    if (handelProjectSearchMemo.length < 1) return <></>;
    return (
      <Panel
        header={
          <div className="flex items-center">
            <p className="mon-500-14 text-gray-500 ml-1.5">Projects</p>
          </div>
        }
        key="projects"
      >
        {handelProjectSearchMemo.map((p) => (
          <div key={p.id} className="px-3 cursor-pointer">
            <Collapse
              // defaultActiveKey={["1"]}
              ghost
              expandIconPosition={"start"}
              expandIcon={({ isActive }) => (
                <button>
                  <img
                    src="/images/icons/chevron-up.svg"
                    className={`transform duration-300 w-4 h-4 ${
                      isActive ? "rotate-0" : "rotate-180"
                    }`}
                    style={{
                      transitionDuration: "300ms",
                    }}
                    alt="dropdown"
                  />
                </button>
              )}
              className={`custom-collapse`}
            >
              <Panel
                header={
                  <div className="mon-400-12 capitalize pl-1 text-gray-900 truncate whitespace-nowrap">
                    {p.title}
                  </div>
                }
                key={p.id}
              >
                {p.threads
                  .slice(0, projectSeeMoreActive ? p.threads.length : 3)
                  .map((t) => (
                    <div
                      key={t.threadId}
                      className={`flex items-center justify-between actions-row relative  hover:bg-primary-50 px-3 ml-5 py-1.5 my-2 rounded-lg cursor-pointer ${
                        threadIdForColor === t.threadId ? "bg-[#D1E9FF]" : ""
                      }`}
                      onClick={() => {
                        setThreadIdForColor(t.threadId);
                        joinThread(t, p.id);
                      }}
                    >
                      <div className="flex items-center ml-6">
                        <PhaseIcon
                          width="12px"
                          height="12px"
                          phaseId={+t.phaseId}
                        />

                        <div className="mon-400-12 capitalize pl-2.5 text-gray-900 truncate whitespace-nowrap">
                          {t.threadName}
                        </div>
                      </div>
                      <div className="flex items-center  ">
                        {t.unreadCount && t.unreadCount > 0 ? (
                          <div className="flex items-center justify-center bg-[#F04438]  rounded-md w-5 h-5 mr-4">
                            <span className="font-nunito-sans text-white pt-0.5 font-bold text-[10px]">
                              {t.unreadCount}
                            </span>
                          </div>
                        ) : (
                          <></>
                        )}
                        <div className="actions absolute right-1">
                          <div className="flex items-center rounded-md w-5 h-5 ml-2">
                            <MenuDropdown
                              customFunc={undefined}
                              elements={[
                                {
                                  id: 1,
                                  image: "/images/icons/info-circle.svg",
                                  text: "View details",
                                  textColor: "text-gray-700",
                                  // borderBottom: true,
                                  onclick: () =>
                                    dispatch(
                                      updateConversationsMultiple([
                                        {
                                          key: "showThreadProfileDrawer",
                                          value: true,
                                        },
                                        {
                                          key: "editThreadDetails",
                                          value: t,
                                        },
                                      ])
                                    ),
                                },
                                {
                                  id: 2,
                                  image: "/images/icons/open-window.svg",
                                  text: "Open in new window",
                                  textColor: "text-gray-700",
                                  borderBottom: true,
                                  onclick: () =>
                                    window.open(window.location.href, "_blank"),
                                },
                                {
                                  id: 3,
                                  image:
                                    t.isMute === 0
                                      ? "/images/icons/bell-off-01.svg"
                                      : "/images/icons/bell-ringing-01.svg",
                                  text:
                                    t.isMute === 0
                                      ? "Mute thread"
                                      : "Unmute thread",
                                  textColor: "text-gray-700",
                                  onclick: async () => {
                                    dispatch(
                                      muteThread({
                                        threadId: t.threadId,
                                        projectId: p.id,
                                        isProjectThread: true,
                                        isMute: +!t.isMute,
                                      })
                                    );
                                    await client.put(
                                      "/conversation/mute-thread",
                                      {
                                        threadId: t.threadId,
                                        userId: user.id,
                                        isMute: +!t.isMute,
                                      }
                                    );
                                  },
                                },

                                {
                                  id: 4,
                                  image:
                                    "/images/icons/message-x-circle-gray.svg",
                                  text: "Leave thread",
                                  textColor: "text-gray-700",
                                  borderBottom: true,
                                  onclick: () =>
                                    dispatch(
                                      updateConversationsMultiple([
                                        {
                                          key: "leaveThread",
                                          value: true,
                                        },
                                        {
                                          key: "leaveThreadDetails",
                                          value: {
                                            threadId: t.threadId,
                                          },
                                        },
                                      ])
                                    ),
                                },
                                t.createdById === user.id
                                  ? {
                                      id: 5,
                                      image: "/images/icons/delete.svg",
                                      text: "Delete thread",
                                      textColor: "text-error-600",
                                      onclick: () =>
                                        dispatch(
                                          updateConversationsMultiple([
                                            {
                                              key: "deleteThread",
                                              value: true,
                                            },
                                            {
                                              key: "leaveThreadDetails",
                                              value: {
                                                threadId: t.threadId,
                                              },
                                            },
                                          ])
                                        ),
                                    }
                                  : null,
                              ]}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                <div className="flex items-center cursor-pointer my-6">
                  <img
                    src={
                      projectSeeMoreActive
                        ? "/images/icons/minus.svg"
                        : "/images/icons/plus-gray.svg"
                    }
                    //className="w-4 h-4 ml-3"
                    className="h-3 w-3 ml-3"
                  />
                  <p
                    className="mon-400-12 pl-2.5 text-gray-900"
                    onClick={() => {
                      setProjectSeeMoreActive(!projectSeeMoreActive);
                    }}
                  >
                    {projectSeeMoreActive ? "See less" : "See more"}
                  </p>
                </div>
              </Panel>
            </Collapse>
          </div>
        ))}
      </Panel>
    );
  };

  const { user: thisUser } = useAppSelector((state) => state.userDetails);

  return (
    <CustomLayout page="conversations" sidebar={false}>
      <div className="flex">
        <div className="relative min-w-[379px] bg-main-background">
          {user && user.roleId < 3 ? (
            <div className="fixed z-50">
              <SuggestedThreadSidebar />
            </div>
          ) : (
            <></>
          )}

          <div
            className={`max-h-[calc(100vh-56px)] overflow-scroll min-h-[calc(100vh-56px)] px-6 py-4 ${
              user && user.roleId < 3 ? "ml-8" : "ml-0"
            } `}
          >
            <CustomSearch
              placeholder="Search threads, people"
              setSearch={setSearch}
              search={search}
            />

            <div className="mt-3">
              <Collapse
                defaultActiveKey={["Threads", "DirectMessages"]}
                ghost
                expandIconPosition={"start"}
                expandIcon={({ isActive }) => (
                  <button>
                    <img
                      src="/images/icons/chevron-up-filled.svg"
                      className={`transform duration-100 ${
                        isActive ? "rotate-0" : "rotate-180"
                      }`}
                      alt="dropdown"
                      style={{
                        transitionDuration: "300ms",
                      }}
                    />
                  </button>
                )}
                className={`custom-collapse`}
              >
                <Panel
                  header={
                    <div className="flex items-center">
                      <img src="/images/icons/message-chat-circle.svg" />
                      <p className="mon-500-14 text-gray-500 ml-2.5">Threads</p>
                    </div>
                  }
                  key="Threads"
                >
                  {sortThreads(threads)
                    .filter((t) =>
                      t.threadName.toLowerCase().includes(search.toLowerCase())
                    )
                    .slice(0, threadSeeMoreActive ? threads.length : 3)
                    .map((t) => (
                      <div
                        key={t.threadId}
                        className={`flex items-center justify-between actions-row relative  hover:bg-primary-50 px-3 ml-5 py-1.5 my-2 rounded-lg cursor-pointer ${
                          currentThread.threadId === t.threadId
                            ? "bg-[#D1E9FF]"
                            : ""
                        }`}
                        onClick={() => {
                          setThreadIdForColor(t.threadId);
                          joinThread(t, "");
                        }}
                      >
                        <div className="flex items-center ml-3">
                          {/* <PhaseIcon
                            width="12px"
                            height="12px"
                            phaseId={t.phaseId}
                          /> */}

                          <div className="mon-400-12 capitalize  text-gray-900 truncate whitespace-nowrap">
                            {t.threadName}
                          </div>
                        </div>
                        <div className="flex items-center  ">
                          {t.unreadCount && t.unreadCount > 0 ? (
                            <div className="flex items-center justify-center bg-[#F04438]  rounded-md w-5 h-5 mr-4">
                              <span className="font-nunito-sans text-white pt-0.5 font-bold text-[10px]">
                                {t.unreadCount}
                              </span>
                            </div>
                          ) : (
                            <></>
                          )}
                          <div className=" absolute right-1">
                            <div className="flex items-center rounded-md w-5 h-5 ml-2">
                              <MenuDropdown
                                elements={[
                                  {
                                    id: 1,
                                    image: "/images/icons/info-circle.svg",
                                    text: "View details",
                                    textColor: "text-gray-700",

                                    onclick: () =>
                                      dispatch(
                                        updateConversationsMultiple([
                                          {
                                            key: "showThreadProfileDrawer",
                                            value: true,
                                          },
                                          {
                                            key: "editThreadDetails",
                                            value: t,
                                          },
                                        ])
                                      ),
                                  },
                                  {
                                    id: 2,
                                    image: "/images/icons/open-window.svg",
                                    text: "Open in new window",
                                    textColor: "text-gray-700",
                                    borderBottom: true,
                                    onclick: () =>
                                      window.open(
                                        window.location.href,
                                        "_blank"
                                      ),
                                  },
                                  {
                                    id: 3,
                                    image:
                                      t.isMute === 0
                                        ? "/images/icons/bell-off-01.svg"
                                        : "/images/icons/bell-ringing-01.svg",
                                    text:
                                      t.isMute === 0
                                        ? "Mute thread"
                                        : "Unmute thread",
                                    textColor: "text-gray-700",
                                    onclick: async () => {
                                      console.log("MMMMMUUUUUUU");
                                      dispatch(
                                        muteThread({
                                          threadId: t.threadId,
                                          projectId: null,
                                          isProjectThread: false,
                                          isMute: +!t.isMute,
                                        })
                                      );
                                      await client.put(
                                        "/conversation/mute-thread",
                                        {
                                          threadId: t.threadId,
                                          userId: user.id,
                                          isMute: +!t.isMute,
                                        }
                                      );
                                    },
                                  },
                                  {
                                    id: 4,
                                    image:
                                      "/images/icons/message-x-circle-gray.svg",
                                    text: "Leave thread",
                                    textColor: "text-gray-700",
                                    borderBottom: true,
                                    onclick: () =>
                                      dispatch(
                                        updateConversationsMultiple([
                                          {
                                            key: "leaveThread",
                                            value: true,
                                          },
                                          {
                                            key: "leaveThreadDetails",
                                            value: {
                                              threadId: t.threadId,
                                            },
                                          },
                                        ])
                                      ),
                                  },
                                  +t.createdById === +user.id ||
                                  +thisUser.roleId === 1
                                    ? {
                                        id: 5,
                                        image: "/images/icons/edit-2.svg",
                                        textColor: "text-gray-700",
                                        text: "Edit thread",
                                        onclick: () =>
                                          dispatch(
                                            updateConversationsMultiple([
                                              {
                                                key: "threadModal",
                                                value: true,
                                              },
                                              {
                                                key: "editThreadDetails",
                                                value: t,
                                              },
                                              {
                                                key: "editThread",
                                                value: true,
                                              },
                                              {
                                                key: "suggestedThread",
                                                value: false,
                                              },
                                              {},
                                            ])
                                          ),
                                      }
                                    : null,
                                  +t.createdById === +user.id ||
                                  +thisUser.roleId === 1
                                    ? {
                                        id: 6,
                                        image: "/images/icons/delete.svg",
                                        text: "Delete thread",
                                        textColor: "text-error-600",
                                        onclick: () =>
                                          dispatch(
                                            updateConversationsMultiple([
                                              {
                                                key: "deleteThread",
                                                value: true,
                                              },
                                              {
                                                key: "leaveThreadDetails",
                                                value: {
                                                  threadId: t.threadId,
                                                },
                                              },
                                            ])
                                          ),
                                      }
                                    : null,
                                ]}
                                customFunc={undefined}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  <div className="flex items-center cursor-pointer ml-11 my-6">
                    <img
                      src={
                        threadSeeMoreActive
                          ? "/images/icons/minus.svg"
                          : "/images/icons/plus-gray.svg"
                      }
                      className="h-3 w-3"
                    />
                    <p
                      className="mon-400-12 pl-2.5 text-gray-900"
                      onClick={() => {
                        setThreadSeeMoreActive(!threadSeeMoreActive);
                      }}
                    >
                      {threadSeeMoreActive ? "See less" : "See more"}
                    </p>
                  </div>
                </Panel>

                {projectSection()}

                <Divider className="w-full" />
                {handelUserSearch(directMessageUsers).length > 0 ? (
                  <Panel
                    header={
                      <div className="flex items-center">
                        <img src="/images/icons/message-text-square.svg" />
                        <p className="mon-500-14 text-gray-500 ml-2.5">
                          Direct Messages
                        </p>
                      </div>
                    }
                    key="DirectMessages"
                  >
                    {sortUsers(handelUserSearch(directMessageUsers), user).map(
                      (t, i) => (
                        <div
                          key={`${String(t.userId)}${String(i)}`}
                          className={`flex items-center justify-between  hover:bg-primary-50 px-2 py-1.5 my-2 rounded-lg cursor-pointer 
                        ${
                          currentThread.userId === t.userId
                            ? "bg-[#D1E9FF]"
                            : ""
                        }`}
                          onClick={() => joinPrivateThread(t)}
                        >
                          <div className="flex items-center">
                            <CustomAvatar
                              size={24}
                              title={
                                t.firstname
                                  ? t.firstname + " " + t.lastname
                                  : t.email
                              }
                              src={t.profilePicture}
                              badge
                              userId={t.userId}
                            />

                            <div className="mon-400-12 capitalize pl-1 text-gray-900 truncate whitespace-nowrap">
                              {t.firstname
                                ? t.firstname + " " + t.lastname
                                : t.email}
                            </div>
                          </div>
                          {t.unreadCount && t.unreadCount > 0 ? (
                            <div className="flex items-center justify-center bg-[#F04438]  rounded-md w-5 h-5">
                              <span className="font-nunito-sans text-white pt-0.5 font-bold text-[10px]">
                                {t.unreadCount}
                              </span>
                            </div>
                          ) : (
                            <></>
                          )}
                        </div>
                      )
                    )}
                  </Panel>
                ) : (
                  <></>
                )}

                {/* <Panel header="This is panel header 3" key="3">
                <p></p>
              </Panel> */}
              </Collapse>
            </div>
          </div>
          <FloatButton />
        </div>

        <div className="w-full bg-white max-h-[calc(100vh-56px)] min-h-[calc(100vh-56px)] overflow-hidden flex flex-col content-between justify-between	 ">
          {currentThread.threadId === "" && currentThread.userId === "" ? (
            <></>
          ) : (
            <ChatHeader />
          )}
          <MainChatWindow socket={socket} />
          {currentThread.threadId === "" && currentThread.userId === "" ? (
            <></>
          ) : (
            <div className="mx-5 mb-5 mt-0">
              <ConversationsChatInput socket={socket} />
            </div>
          )}
        </div>
      </div>
      {threadModal && (
        <ThreadModal
          visible={threadModal}
          onCancel={() =>
            dispatch(
              updateConversationsMultiple([
                { key: "threadModal", value: false },
                {
                  key: "editThreadDetails",
                  value: {
                    threadName: "",
                    description: "",
                    phaseId: "",
                    users: ["0"],
                  },
                },
                { key: "editThread", value: false },
                { key: "suggestedThread", value: false },
                { key: "prefillUserInAddThread", value: false },
              ])
            )
          }
        />
      )}

      <LeaveThreadModal
        visible={leaveThread}
        onCancel={() =>
          dispatch(
            updateConversationsMultiple([
              { key: "leaveThread", value: false },
              // {
              //   key: "leaveThreadDetails",
              //   value: {
              //     threadId: "",
              //   },
              // },
            ])
          )
        }
      />
      <DeleteThreadModal
        visible={deleteThread}
        onCancel={() =>
          dispatch(
            updateConversationsMultiple([
              { key: "deleteThread", value: false },
              {
                key: "leaveThreadDetails",
                value: {
                  threadId: "",
                },
              },
            ])
          )
        }
      />

      <NewMessageModal
        visible={newMessageModal}
        onCancel={() =>
          dispatch(
            updateConversations({ key: "newMessageModal", value: false })
          )
        }
      />
      {shareMessageModal && (
        <ShareModal
          visible={shareMessageModal}
          onClose={() =>
            dispatch(
              updateConversations({ key: "shareMessageModal", value: false })
            )
          }
          onShare={(selectedUsers: any[]) =>
            handelForwardMessage(selectedUsers)
          }
          type={shareType}
        />
      )}

      {saveInVaultModal.visible && (
        <SaveInVaultModal
          visible={saveInVaultModal.visible}
          file={saveInVaultModal.file}
          onClose={() =>
            dispatch(dispatch(resetConversations(["saveInVaultModal"])))
          }
        />
      )}

      {deleteFileModal && (
        <DeleteFileModal
          visible={deleteFileModal}
          onCancel={() =>
            dispatch(
              updateConversations({ key: "deleteFileModal", value: false })
            )
          }
        />
      )}
    </CustomLayout>
  );
};

export default ConversationsScreen;
