import { DatePicker } from "antd";
import dayjs from "dayjs";
import React from "react";

export const CustomOnboardingDateRangePicker = ({
  value,
  onChange,
  className,
  onClear,
  name,
  showError,
  errorText,
  formikHook: formik,
  helperText,
}: {
  value?: string;
  onChange: (e) => void;
  className?: string;
  onClear: (e) => void;
  name?: string;
  showError?: boolean;
  errorText?: string;
  helperText?: string;
  formikHook?: any;
}) => {
  if (formik && name) {
    showError = showError || (formik.touched[name] && !!formik.errors[name]);
    errorText = errorText || (formik.touched[name] && formik.errors[name]);
    // valid =
    //   valid ||
    //   (!formik.errors[name] &&
    //     formik.initialValues[name] !== formik.values[name])
  }
  const dates =
    value && value[0] && value[1]
      ? [dayjs(value[0]), dayjs(value[1])]
      : [null, null];
  return (
    <div>
      <div
        className={`
    ${
      showError
        ? "border-b-2 border-error-600"
        : value?.length > 0
        ? "border-b-2 border-primary-600"
        : " border-b-[1px] border-gray-400"
    }
    ${className}`}
      >
        <div className="flex justify-between items-center">
          <DatePicker.RangePicker
            className="w-full bg-transparent custom-range-picker-for-phase custom-range-picker-for-onboarding "
            suffixIcon={<></>}
            format="DD MMM YY"
            separator={<span className="mon-400-14 text-gray-500 px-0">-</span>}
            allowClear={false}
            bordered={false}
            onChange={onChange}
            value={dates as any}

            // value={[getValue(1, "startDate"), getValue(1, "endDate")]}
            // onChange={(e) => {
            //   setDates(1, e);
            // }}
          />
          {/* {value.length > 0 && (
          <div className="cursor-pointer" onClick={onClear}>
            <img src="/images/icons/sector-icons/input-cross.svg" alt="" />
          </div>
        )} */}
        </div>
      </div>
      {showError && (
        <div className="mt-1.5 mon-400-14 text-left text-error-500">
          {errorText}
        </div>
      )}
      {helperText && (
        <div className="mt-1.5 mon-400-14 text-left text-error-500">
          {helperText}
        </div>
      )}
    </div>
  );
};

const CustomOnboardingDatePicker = ({
  value,
  onChange,
  className,
  placeholder,
  disableDate,
}: {
  value?: string;
  onChange: (e) => void;
  className?: string;
  placeholder?: string;
  disableDate?: any;
}) => {
  const date = value ? dayjs(value) : null;
  return (
    <div
      className={`${
        value?.length > 0
          ? "border-b-2 border-primary-600"
          : " border-b-[1px] border-gray-400"
      } ${className}`}
    >
      <DatePicker
        className="w-full bg-transparent custom-range-picker-for-onboarding "
        suffixIcon={<></>}
        format="DD MMM YY"
        allowClear={false}
        bordered={false}
        placeholder={placeholder}
        onChange={onChange}
        disabledDate={disableDate as any}
        value={date as any}

        // value={[getValue(1, "startDate"), getValue(1, "endDate")]}
        // onChange={(e) => {
        //   setDates(1, e);
        // }}
      />
    </div>
  );
};

export default CustomOnboardingDatePicker;
