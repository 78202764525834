import { Tooltip } from "antd";
import React, { useState } from "react";

interface onChangeProps {
  id: number;
}

type CustomHorizontalIconGroupProps = {
  options?: {
    id: number;
    icon: string | React.ReactNode;
    title: string;
  }[];
  color: {
    active: string;
    inactive: string;
  };
  onChange?: (option: onChangeProps) => void;
};

const CustomHorizontalIconGroup = ({
  options,
  onChange,
  color,
}: CustomHorizontalIconGroupProps) => {
  const [value, setValue] = useState(options[0].id);

  const handleChange = (option) => {
    setValue(option.id);
    onChange(option);
  };

  // https://stackoverflow.com/a/63509948
  interface SVGProps {
    color?: string;
  }
  const getProps = (id: number) => {
    const partialProps: Partial<SVGProps> = {
      color: id === value ? color.active : color.inactive,
    };
    const props: SVGProps = partialProps;
    return props;
  };

  return (
    <div className="bg-white h-11 flex justify-center items-center shadow-xs rounded-xl mr-3">
      {options.map((option) => (
        <div key={option.id}>
          <Tooltip
            title={option.title}
            color={"#0D3271"}
            placement={"bottom"}
            overlayStyle={{
              borderRadius: "8px",
            }}
          >
            <button
              className={`px-3.5 ${
                option.id === 2 && "h-5 border-r-[1px] border-[#EDF3F9]"
              }`}
              key={option.id}
              onClick={() => handleChange(option)}
            >
              {typeof option.icon === "string" ? (
                <img
                  className="w-5 min-w-5"
                  src={`/images/icons/${option.icon}`}
                  alt="grid"
                />
              ) : (
                <>
                  {React.isValidElement(option.icon) &&
                    React.cloneElement(option.icon, getProps(option.id))}
                </>
              )}
            </button>
          </Tooltip>
        </div>
      ))}
    </div>
  );
};

export default CustomHorizontalIconGroup;
