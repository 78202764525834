import React, { useState } from "react";
import CustomOnboardingInput from "../../../Core/Common/CustomOnboardingInput";
import { ProductOnboardingLayout } from "../../../Core/Layout/OnboardingLayouts";
import CustomButton from "../../../Core/Common/CustomButton";
import { useAppDispatch, useAppSelector } from "../../../Core/redux/hooks";
import {
  changeStep,
  handleCompanyMembers,
} from "../../../Core/redux/slices/onboarding.slice";
import CurrentStep from "../Project Onboarding/CurrentStep";
import { Dropdown, Menu } from "antd";
import StepProgressBar from "./StepProgressBar";

const Content = () => {
  const { companyMembers } = useAppSelector((state) => state.onboarding);
  const [memberList, setMemberList] = useState(companyMembers);

  const defineRole = (roleId, id) => {
    if (memberList.length <= id) {
      setMemberList((old) => [
        ...old,
        {
          id: old.length + 1,
          email: "",
          roleId: 0,
        },
      ]);
    }

    const oldMemberList = (oldList) => {
      const list = oldList.map((m) => {
        if (m.id === id) {
          return {
            ...m,
            roleId: roleId,
          };
        }
        return m;
      });
      return list;
    };
    setMemberList((old) => [...oldMemberList(old)]);
  };

  const setEmail = (email, id) => {
    const oldMemberList = memberList.map((m) => {
      if (m.id === id) {
        return {
          ...m,
          email: email,
        };
      }
      return m;
    });

    setMemberList(oldMemberList);
  };

  const clearData = (id) => {
    const clearList = memberList.map((m) => {
      if (m.id === id) {
        return {
          ...m,
          email: "",
          roleId: 0,
        };
      }
      return m;
    });

    setMemberList(clearList);
  };

  console.log(memberList, "member list");
  const finalList = memberList.filter(
    ({ email, roleId }) => email !== "" && roleId !== 0
  );
  console.log(finalList, "finalList");
  const dispatch = useAppDispatch();
  return (
    <div className="p-6 w-full h-screen bg-white">
      <div className="pl-14 w-full h-full bg-white flex justify-start mt-[107px]">
        <StepProgressBar
          currentStep={5}
          totalSteps={5}
          givenElementId="form5"
        />
        <div>
          <CurrentStep currentStep={5} totalStep={5} className="" id="form5" />
          <h1 className="mon-500-20 text-gray-900 mb-2 mt-6">
            Create your waddle
          </h1>
          <div className="flex justify-start items-center text-show-animation gap-x-2 mon-400-16 text-gray-500">
            <img
              src="/images/icons/frosty-icon.svg"
              className="min-h-[26px] min-w-[26px]"
              alt="frosty"
            />
            Start by adding team members. You can edit these later.
          </div>
          <div className="flex flex-col gap-y-6">
            {memberList.map((m, index) => (
              <div className="flex items-center gap-x-2" key={index}>
                <CustomOnboardingInput
                  className="w-[500px]"
                  placeholder="name@example.com"
                  value={m.email}
                  onChange={(e, value) => setEmail(value, m.id)}
                  customEnd={
                    <div>
                      <Dropdown
                        overlay={
                          <Menu className="w-[367px] rounded-lg custom-menu-dropdown bg-error-600 shadow-dropdown">
                            <Menu.Item className="py-2.5 px-4">
                              <div
                                className="gap-y-1 pt-1.5"
                                onClick={() => defineRole(1, m.id)}
                              >
                                <p className="mon-500-14 whitespace-nowrap text-gray-900">
                                  Owner
                                </p>
                                <p className="mon-400-12 text-gray-500 whitespace-nowrap">
                                  Can manage all admins, users, product settings
                                  and bills
                                </p>
                              </div>
                            </Menu.Item>
                            <Menu.Item
                              className="py-2.5 px-4"
                              onClick={() => defineRole(2, m.id)}
                            >
                              <div className="gap-y-1">
                                <p className="mon-500-14 whitespace-nowrap text-gray-900">
                                  Admin
                                </p>
                                <p className="mon-400-12 text-gray-500 whitespace-nowrap">
                                  Has permissions for all administrative
                                  features
                                </p>
                              </div>
                            </Menu.Item>
                            <Menu.Item
                              className="py-2.5 px-4"
                              onClick={() => defineRole(3, m.id)}
                            >
                              <div className="gap-y-1">
                                <p className="mon-500-14 whitespace-nowrap text-gray-900">
                                  Basic user
                                </p>
                                <p className="mon-400-12 text-gray-500 whitespace-nowrap">
                                  Do not have permissions for administrative
                                  features
                                </p>
                              </div>
                            </Menu.Item>
                          </Menu>
                        }
                        trigger={["click"]}
                      >
                        <div className="flex items-center cursor-pointer gap-x-3 mr-3">
                          <p className="mon-400-14 text-gray-500 whitespace-nowrap">
                            {m.roleId === 0
                              ? "Select role"
                              : m.roleId === 1
                              ? "Owner"
                              : m.roleId === 2
                              ? "Admin"
                              : "Basic user"}
                          </p>
                          <img
                            src="/images/icons/chevron-down-gray.svg"
                            className="w-4 h-4"
                          />
                        </div>
                      </Dropdown>
                    </div>
                  }
                />
                {m.email || m.roleId ? (
                  <div
                    className="cursor-pointer"
                    onClick={() => {
                      clearData(m.id);
                    }}
                  >
                    <img
                      src="/images/icons/sector-icons/input-cross.svg"
                      alt=""
                      className="w-4 h-4 ml-4"
                    />
                  </div>
                ) : (
                  ""
                )}
              </div>
            ))}
          </div>
          <div className="flex items-center gap-x-6 mt-6">
            <CustomButton
              className="bg-primary-600 text-white"
              onClick={() => {
                dispatch(handleCompanyMembers(finalList));
                dispatch(changeStep(7));
              }}
              text="Continue"
              width="92px"
            />
            <CustomButton
              className="bg-white border-gray-200 border-[1px]"
              onClick={() => dispatch(changeStep(5))}
              text="Back"
              width="68px"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const Sidebar = () => {
  return (
    <div className="w-full h-screen overflow-hidden  flex flex-col justify-center items-center bg-gradient-to-b from-[#E6F5F8] to-[#D7EEF4]">
      <img
        src="/images/icons/company-size-frosty.svg"
        className="w-[188px] h-[277px]"
      />
    </div>
  );
};

const MapSelection = () => {
  return (
    <ProductOnboardingLayout content={<Content />} sidebar={<Sidebar />} />
  );
};

export default MapSelection;
