import { Tooltip } from "antd";
import dayjs from "dayjs";
import React, { useState, useEffect } from "react";
import CustomButton from "../../../Core/Common/CustomButton";
import CustomOnboardingDatePicker from "../../../Core/Common/CustomOnboardingDatePicker";
import { useAppDispatch, useAppSelector } from "../../../Core/redux/hooks";
import {
  changeStep,
  updateProjectOnboarding,
} from "../../../Core/redux/slices/projectOnboarding.slice";
import { PhaseIconPure } from "../../../Core/svg/PhaseIcon";
import CurrentStep from "./CurrentStep";
import StepHeading from "./StepHeading";
import StepLayout from "./StepLayout";

const PhaseCard = ({ phaseId, phaseName, disabled, color }) => {
  const dispatch = useAppDispatch();
  const [hover, setHover] = useState(false);

  const { phaseDetails, projectDuration } = useAppSelector(
    (state) => state.projectOnboarding
  );

  const [focused, setFocused] = useState(false);

  const handelChange = (key, val) => {
    const newPhaseDetails = phaseDetails.map((p) => {
      if (p.id === phaseId) {
        const newP = { ...p };
        newP[key] = val;
        return newP;
      }
      return p;
    });
    dispatch(
      updateProjectOnboarding({
        key: "phaseDetails",
        value: newPhaseDetails,
      })
    );
  };

  const phase = phaseDetails.find((phase) => phase.id == phaseId);
  const { startDate, endDate } = phase;
  const diff =
    startDate && endDate ? dayjs(endDate).diff(startDate, "days") : 0;

  function createDisableDate(phaseDetails, phaseId, projectDuration) {
    return (current) => {
      const phaseStartDate = phaseDetails.find(
        (p) => p.id === phaseId
      )?.startDate;
      const phaseEndDate = phaseDetails.find((p) => p.id === phaseId)?.endDate;
      return (
        (current &&
          (current < dayjs(projectDuration[0]) ||
            current > dayjs(projectDuration[1]) ||
            (phaseStartDate && current < dayjs(phaseStartDate)) ||
            (phaseEndDate && current > dayjs(phaseEndDate)))) ||
        ""
      );
    };
  }

  return (
    <div
      className=" rounded-2xl h-[222px] min-w-[180px] pt-[18px] pb-[16px] px-4 relative hover:shadow-[0px 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.06)] transform duration-200"
      style={{
        border: focused
          ? `1px solid ${color}`
          : hover
          ? "1px solid #F9FBFE"
          : "1px solid #DDE5ED",
        backgroundColor: disabled ? "#F7FAFD" : "#F9FBFE",
        cursor: disabled ? "not-allowed" : "pointer",
        boxShadow: hover
          ? "0px 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.06)"
          : "",
      }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onFocus={() => setFocused(true)}
      // onFocus={()=>conso}
      //onBlur={() => setFocused(false)}
    >
      {disabled ? (
        <Tooltip
          title={
            <p className="mon-500-12">
              Not included.
              <br />
              Add in the previous step
            </p>
          }
          placement="right"
        >
          <div
            className=" rounded-2xl h-[222px] w-[180px] pt-[18px] pb-[16px] px-4 absolute bg-gray-50 z-10 top-0 left-0 "
            style={{
              opacity: 0.5,
            }}
          ></div>
        </Tooltip>
      ) : (
        <></>
      )}
      <div className="flex items-center ">
        <PhaseIconPure
          phaseId={phaseId}
          width="32"
          height="32"
          color={focused ? "#101828" : "#667085"}
        ></PhaseIconPure>

        <p className="mon-500-12 ml-3.5">{phaseName}</p>
      </div>
      <CustomOnboardingDatePicker
        value={phaseDetails.find((p) => p.id === phaseId)?.startDate || ""}
        onChange={(e) =>
          handelChange("startDate", dayjs(e).format("YYYY-MM-DD"))
        }
        placeholder="Start Date"
        className="mt-3"
        disableDate={createDisableDate(phaseDetails, phaseId, projectDuration)}
      />
      <CustomOnboardingDatePicker
        value={phaseDetails.find((p) => p.id === phaseId)?.endDate || ""}
        onChange={(e) => handelChange("endDate", dayjs(e).format("YYYY-MM-DD"))}
        placeholder="End Date"
        className="mt-3"
        disableDate={createDisableDate(phaseDetails, phaseId, projectDuration)}
      />
      <div className="mon-500-10 text-gray-400 mt-6">Duration</div>
      <div className="mon-500-12 text-gray-700 mt-1.5">{diff} days</div>
    </div>
  );
};

const Step6 = () => {
  const { selectedPhase, phaseDetails } = useAppSelector(
    (state) => state.projectOnboarding
  );
  const dispatch = useAppDispatch();
  const color = [
    { id: 1, color: "#FFDB01" },
    { id: 2, color: "#F92B63" },
    { id: 3, color: "#6938EF" },
    { id: 4, color: "#33B7FF" },
    { id: 5, color: "#46C66C" },
  ];

  const sortedPhases = [...phaseDetails].sort((a, b) => {
    return selectedPhase.includes(b.id) ? 1 : -1;
  });

  return (
    <StepLayout>
      <CurrentStep className={"mt-20"} currentStep={6} totalStep={7} />
      <StepHeading
        title={<p className="text-gray-900 mon-500-20">Set it up!</p>}
        subTitle={
          <p className="mon-400-16 text-gray-500 pl-2">
            Setting up phases will ensure you finish the project within your
            timeline.
          </p>
        }
      />

      <div className=" mt-6 flex gap-x-6 overflow-x-scroll py-2 max-w-[50vw] scrollbar-hide">
        {sortedPhases.map((phase) => (
          <PhaseCard
            key={phase.id}
            phaseId={phase.id}
            phaseName={phase.phaseName}
            disabled={!selectedPhase.includes(phase.id)}
            color={color[phase.id - 1].color}
          />
        ))}
      </div>

      <div className="flex justify-between items-center mt-6">
        <div className=" flex gap-x-6">
          <CustomButton
            text="Setup phases"
            className="mon-500-14 text-white bg-primary-600 "
            height="40px"
            width="128px"
            onClick={() => dispatch(changeStep(7))}
          />
          <CustomButton
            text="Back"
            className="border border-gray-300 text-gray-700 bg-white mon-500-14"
            height="40px"
            width="68px"
            onClick={() => dispatch(changeStep(5))}
          />
        </div>
        <div
          className="mon-500-14 text-primary-600 cursor-pointer pr-10 "
          onClick={() => dispatch(changeStep(7))}
        >
          {" "}
          I’ll do it later
        </div>
      </div>
    </StepLayout>
  );
};

export default Step6;
