import { createAsyncThunk } from "@reduxjs/toolkit";
import { client, getError } from "../../utils/axiosClient";

export const getTenatMembers = createAsyncThunk(
    "tenant/getTenantMembers",
    async (
        payload: any,
        { rejectWithValue }
    ) => {
        try {
            const response = await client.get(`/tenant/members`);

            return response.data;
        } catch (error) {
            return rejectWithValue(getError(error));
            //return error;
        }
    }
);


export const getTenantDetails = createAsyncThunk(
    "tenant/getTenantDetails",
    async (
        payload: any,
        { rejectWithValue }
    ) => {
        try {
            const response = await client.get(`/tenant/tenant-details`);

            return response.data;
        } catch (error) {
            return rejectWithValue(getError(error));
            //return error;
        }
    }
);




