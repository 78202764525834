import React, { useEffect, useRef, useState } from "react";
import { Avatar, Dropdown, Layout, Menu, Popover } from "antd";
import { Link, useNavigate, useParams } from "react-router-dom";
import dayjs from "dayjs";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { getProjectList, getProjectPermission } from "../redux/api/projectAPI";
import NotificationPopUp from "../../Modules/Notifications/NotificationPopUp";
import CustomAvatar from "../Common/CustomAvatar";
import CustomButton from "../Common/CustomButton";
import CustomSelectWithSearch, {
  CustomSelectWithInlineSearch,
} from "../Common/CustomSelectWithSearch";
import {
  changeTenant,
  resetToInitialTenant,
} from "../redux/slices/tenant.slice";
import {
  changeProject,
  resetToInitialProjects,
} from "../redux/slices/projects.slice";
import { getTenantDetails } from "../redux/api/tenantAPI";
import { getNotifications } from "../redux/api/notificationAPI";
import CustomSelect from "../Common/CustomSelect";
import { changeWorkspace } from "../redux/api/dashboardAPI";
import {
  resetToInitialGlobal,
  updateDashboardCount,
  updateGlobal,
} from "../redux/slices/global.slice";
import Cookies from "js-cookie";
import { resetToInitialActivityLogs } from "../redux/slices/activityLog.slice";
import { resetToInitialConversation } from "../redux/slices/conversations.slice";
import { resetToInitialDashBoard } from "../redux/slices/dashboard.slice";
import { resetToInitialNotification } from "../redux/slices/notification.slice";
import { resetToInitialOnboarding } from "../redux/slices/onboarding.slice";
import { resetToInitialOverview } from "../redux/slices/overview.slice";
import { resetToInitialPreview } from "../redux/slices/preview.slice";
import { resetToInitialProjectOnboarding } from "../redux/slices/projectOnboarding.slice";
import { resetToInitialSchedule } from "../redux/slices/schedule.slice";
import { resetToInitialSetting } from "../redux/slices/settings.slice";
import { resetToInitialVault } from "../redux/slices/vault.slice";
import axios from "axios";
import { chatClient, client } from "../utils/axiosClient";
import { getPhaseName } from "../utils/getPhaseName";

const HeaderItem = ({
  title,
  image,
  hoverImage,
  onClick,
  isSelected,
  suffix,
}: {
  title: string;
  image: string;
  hoverImage: string;
  onClick?: () => void;
  isSelected?: boolean;
  suffix?: any;
}) => {
  const [isHovering, setIsHovering] = useState(false);

  const handleMouseOver = () => {
    setIsHovering(true);
  };

  const handleMouseOut = () => {
    setIsHovering(false);
  };
  return (
    <div
      className="flex items-center ml-10"
      onClick={onClick}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
    >
      <div
        className={`flex  items-center hover:text-primary-600 ${
          isSelected && "text-primary-600"
        }`}
      >
        <div className="w-5 h-5">
          <img
            className="icon-hover w-full h-full cursor-pointer"
            src={isSelected ? hoverImage : isHovering ? hoverImage : image}
            alt={title}
          />
        </div>

        <div
          className={`pl-2 text-xs cursor-pointer ${
            isHovering || isSelected ? "font-medium" : "font-normal"
          }`}
        >
          {title}
        </div>
      </div>
      {suffix && typeof suffix === "function" ? (
        suffix(isHovering || isSelected)
      ) : (
        <></>
      )}
    </div>
  );
};

const NotificationCountBadge = () => {
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.userDetails);
  const { notificationList } = useAppSelector((state) => state.notification);
  const [unreadCount, setUnreadCount] = useState(0);
  //const intervalId = useRef(null);

  useEffect(() => {
    dispatch(getNotifications({ userId: user.id }));
    const intervalId = setInterval(() => {
      dispatch(getNotifications({ userId: user.id }));
    }, 300000); // 5 minutes in milliseconds

    return () => clearInterval(intervalId);
  }, []);

  console.log("ccccccccccccccc");

  // const getNotificationForUser = async () => {
  //   console.log("ccccccccccccccc");
  //   await dispatch(getNotifications({ userId: user.id }));
  // };

  // useEffect(() => {
  //   getNotificationForUser();
  // }, []);
  useEffect(() => {
    const c = notificationList.filter((n) => n.isUnread);
    console.log(c, "ccccc", notificationList);
    setUnreadCount(c.length);
  }, [notificationList]);

  if (unreadCount === 0) return <></>;

  return (
    <div className="flex justify-center items-center w-5 h-5 rounded-md bg-error-500">
      <p className="text-center text-[10px] font-bold text-white">
        {unreadCount}
      </p>
    </div>
  );
};

const UnreadMessageCountBadge = () => {
  const { user } = useAppSelector((state) => state.userDetails);
  const dispatch = useAppDispatch();
  const { unreadConversationCount } = useAppSelector((state) => state.global);
  //const [unreadCount, setUnreadCount] = useState(0);
  //const intervalId = useRef(null);

  const getUnreadCount = async () => {
    try {
      const { data } = await chatClient.get(
        "/conversation/unread-count-total",
        {
          params: {
            userId: user.id,
          },
        }
      );
      console.log(data);
      if (data.count) {
        dispatch(
          updateGlobal({ key: "unreadConversationCount", value: data.count })
        );
      }
    } catch (e) {
      dispatch(updateGlobal({ key: "unreadConversationCount", value: 0 }));
    }
  };

  useEffect(() => {
    getUnreadCount();
    const intervalId = setInterval(() => {
      getUnreadCount();
    }, 300000); // 5 minutes in milliseconds

    return () => clearInterval(intervalId);
  }, []);

  console.log(unreadConversationCount);
  if (unreadConversationCount === 0) return <></>;

  return (
    <div className="flex justify-center items-center w-5 h-5 rounded-md bg-error-500">
      <p className="text-center text-[10px] font-bold text-white">
        {unreadConversationCount}
      </p>
    </div>
  );
};

const CurrentTime = () => {
  const [time, setTime] = useState(dayjs().format("DD MMM YYYY, hh:mm"));
  useEffect(() => {
    const TimeId = setInterval(
      () => setTime(dayjs().format("DD MMM YYYY, hh:mm")),
      1000
    );
    return () => {
      clearInterval(TimeId);
    };
  });
  return (
    <>
      <div className="w-5 h-5">
        <img
          className="w-full h-full"
          src="/images/icons/calendar-date-icon.svg"
          alt="Calendar"
        />
      </div>
      <div className="ml-2.5 font-normal text-xs">
        {time}
        {` `}
        {dayjs().format("A")}
      </div>
    </>
  );
};

const Header = ({ page }: { page: string }) => {
  const dispatch = useAppDispatch();
  const { projectId } = useParams();
  const notificationRef = useRef(null);
  const { projectList, loading } = useAppSelector((state) => state.projects);
  const { user } = useAppSelector((state) => state.userDetails);
  const { dashboardCount } = useAppSelector((state) => state.global);
  React.useEffect(() => {
    dispatch(getProjectList(user.id));
  }, []);

  const [isHovering, setIsHovering] = useState({ item: "", value: false });
  const [showNotificationPopUp, setShowNotificationPopUp] = useState(false);
  const { workspaces } = useAppSelector((state) => state.dashboard);
  const { currentTenantId, tenantDetails } = useAppSelector(
    (state) => state.tenant
  );
  const [activeSprints, setActiveSprints] = useState([]);
  const [tenantId, setTenantId] = useState(tenantDetails?.tenantId);
  const handleMouseOver = (item, value) => {
    setIsHovering({ item, value });
  };
  const navigate = useNavigate();

  let recentProjects = projectList?.slice(0, 4);

  const getProjectPermissionForUser = async () => {
    dispatch(changeProject(projectId));
    await dispatch(
      getProjectPermission({
        projectId,
        userId: user?.id,
      })
    );
  };

  useEffect(() => {
    if (projectId) {
      getProjectPermissionForUser();
    }
  }, [projectId]);

  useEffect(() => {
    dispatch(getTenantDetails({}));
  }, [currentTenantId]);

  useEffect(() => {
    getActiveSprint();
  }, [tenantDetails?.tenantId, projectList]);

  const handleSwitchWorkSpace = async () => {
    // dispatch(resetToInitialActivityLogs({}));
    // dispatch(resetToInitialConversation({}));
    // dispatch(resetToInitialDashBoard({}));
    // dispatch(resetToInitialNotification({}));
    // dispatch(resetToInitialOnboarding({}));
    // dispatch(resetToInitialGlobal({}));
    // dispatch(resetToInitialOverview({}));
    // dispatch(resetToInitialPreview({}));
    // dispatch(resetToInitialProjectOnboarding({}));
    // dispatch(resetToInitialProjects({}));
    // dispatch(resetToInitialSchedule({}));
    // dispatch(resetToInitialSetting({}));
    // dispatch(resetToInitialVault({}));

    dispatch(changeTenant(tenantId));

    await dispatch(changeWorkspace({ tenantId }));
    //location.reload();
    navigate("/projects");
  };

  const getActiveSprint = async () => {
    if (!recentProjects || recentProjects.length < 1) return;
    const { data } = await client.get("/schedule/activeSprint", {
      params: { projectId: recentProjects.map((p) => p.id) },
    });
    if (data) {
      console.log(data, "setActiveSprints");
      setActiveSprints(data?.activeSprint);
    }
  };

  if (activeSprints && activeSprints.length > 0) {
    recentProjects = recentProjects.map((project) => {
      const activeSprint = activeSprints.find((a) => a.projectId == project.id);
      let scheduleUrl = `/schedule/${project.id}`;
      if (activeSprint) {
        const phaseName = getPhaseName(activeSprint.phaseId);
        scheduleUrl = `/schedule/${project.id}?selectedPhase=${phaseName}&selectedSprint=${activeSprint.id}`;
      }
      return { ...project, activeSprint, scheduleUrl };
    });
  }

  console.log(recentProjects, "recentProjects");
  return (
    <div className="relative">
      <Layout.Header style={{ position: "fixed", zIndex: 20, width: "100%" }}>
        <div className="max-h-14 w-full flex justify-between border-b-[1px] py-2 h-[56px] items-center border-[#DDE5ED] px-4">
          <div className="flex items-center ">
            <div
              className="w-[81px] h-6"
              onClick={() => {
                navigate("/dashboard");
                dispatch(
                  updateDashboardCount({
                    key: "dashboardCount",
                    value: true,
                  })
                );
              }}
            >
              <img
                className="w-full h-full cursor-pointer"
                src="/images/icons/waddle-logo.svg"
                alt="waddle logo"
              />
            </div>

            <HeaderItem
              title={"Dashboard"}
              image={"/images/icons/dashboard-icon.svg"}
              hoverImage={"/images/icons/dashboard-blue-icon.svg"}
              onClick={() => {
                navigate("/dashboard");
                dispatch(
                  updateDashboardCount({
                    key: "dashboardCount",
                    value: true,
                  })
                );
              }}
              isSelected={page.toLowerCase() === "dashboard"}
            />
            <Dropdown
              overlay={
                <Menu className="w-[200px] rounded-lg   shadow-dropdown">
                  {recentProjects.map((p, index) => (
                    <>
                      <Menu.Item
                        className={`py-2.5 px-4 rounded-t-lg  custom-menu-dropdown ${
                          recentProjects.length === index + 1
                            ? "border-[#EDF3F9] border-b-[1px]"
                            : ""
                        }`}
                        key={p.id}
                        onClick={() => navigate(p.scheduleUrl)}
                      >
                        <div className="flex items-center truncate whitespace-nowrap">
                          <div className="mr-3">
                            <CustomAvatar
                              title={p.title}
                              size={24}
                              color={p.colorCode}
                              whiteText
                              fontSize="9px"
                              src={p.projectImage}
                            />
                          </div>
                          <div className="mont-400-12 text-gray-500">
                            {p.title}
                          </div>
                        </div>
                      </Menu.Item>
                    </>
                  ))}

                  <Menu.Item
                    className=" py-2.5 px-4 rounded-t-lg"
                    onClick={() => navigate("/projects")}
                  >
                    <div className="flex items-center gap-x-3">
                      <img
                        src="/images/icons/chevron-right-double-gray.svg"
                        className="w-5 h-5"
                      />
                      <p className="mon-500-12 text-gray-500">
                        View all projects
                      </p>
                    </div>
                  </Menu.Item>
                  <Menu.Item
                    className="bg-primary-50 py-2.5 px-4"
                    onClick={() => navigate("/project-onboarding")}
                  >
                    <div className="flex items-center gap-x-3 ">
                      <img
                        src="/images/icons/plus-blue.svg"
                        className="w-5 h-5"
                      />
                      <p className="text-primary-600 mon-500-12">
                        Create New Project
                      </p>
                    </div>
                  </Menu.Item>
                </Menu>
              }
              trigger={["hover"]}
              placement="bottomLeft"
              getPopupContainer={(trigger) => trigger.firstElementChild as any}
            >
              {/* <div
                className="flex items-center"
                onMouseOver={() => handleMouseOver("projects", true)}
                onMouseOut={() => handleMouseOver("projects", false)}
              > */}
              <div>
                <HeaderItem
                  title={"Projects"}
                  image={"/images/icons/project-icon.svg"}
                  hoverImage={"/images/icons/project-blue-icon.svg"}
                  onClick={() => navigate("/projects")}
                  isSelected={page.toLowerCase() === "projects"}
                  suffix={(hover) => (
                    <div className="w-4 h-4 ml-2.5">
                      <img
                        className="w-full h-full cursor-pointer"
                        src={
                          hover
                            ? "/images/icons/chevron-down.svg"
                            : "/images/icons/chevron-down-gray.svg"
                        }
                        alt="project expand"
                      />
                    </div>
                  )}
                />
              </div>

              {/* </div> */}
            </Dropdown>

            {user?.roleId === "1" && (
              <HeaderItem
                title={"Activity Log"}
                image={"/images/icons/activity-log-icon.svg"}
                hoverImage={"/images/icons/activity-log-blue-icon.svg"}
                isSelected={page.toLowerCase() === "activity"}
                onClick={() => navigate("/activity-log")}
              />
            )}
            <div className="flex justify-center items-center gap-x-2">
              <HeaderItem
                title={"Conversations"}
                image={"/images/icons/message-chat-square-gray.svg"}
                hoverImage={"/images/icons/message-chat-square.svg"}
                onClick={() => navigate("/conversations")}
                isSelected={page.toLowerCase() === "conversations"}
              />

              <UnreadMessageCountBadge />
            </div>
            <Dropdown
              overlay={<NotificationPopUp />}
              trigger={["click"]}
              destroyPopupOnHide
            >
              <div
                className="flex justify-center items-center gap-x-2"
                ref={notificationRef}
              >
                <HeaderItem
                  title={"Notification"}
                  image={"/images/icons/notification-icon.svg"}
                  hoverImage={"/images/icons/notification-blue-icon.svg"}
                  onClick={() =>
                    setShowNotificationPopUp(!showNotificationPopUp)
                  }
                />
                <NotificationCountBadge />
              </div>
            </Dropdown>
          </div>

          <div className="flex items-center ">
            <CurrentTime />
            {/* <Link
              to=
              className="flex items-center cursor-pointer"
              onMouseOver={() => handleMouseOver("settings", true)}
              onMouseOut={() => handleMouseOver("settings", false)}
            >
              <div className="w-5 h-5 ml-9 ">
                <img
                  className="w-full h-full"
                  src={
                    (isHovering.item === "settings" && isHovering.value) ||
                    page.toLowerCase() === "product-settings"
                      ? "/images/icons/settings-blue-icon.svg"
                      : "/images/icons/settings-icon.svg"
                  }
                  alt="settings"
                />
              </div>
              <div
                className={`pl-2 ${
                  (isHovering.item === "settings" && isHovering.value) ||
                  page.toLowerCase() === "product-settings"
                    ? "font-medium text-primary-600"
                    : "font-normal"
                }`}
                style={{ fontSize: "12px" }}
              >
                Settings
              </div>
            </Link> */}
            <HeaderItem
              title={"Settings"}
              image={"/images/icons/settings-icon.svg"}
              hoverImage={"/images/icons/settings-blue-icon.svg"}
              onClick={() => navigate("/product-settings")}
              isSelected={page.toLowerCase() === "Settings"}
            />
            <div className="ml-5">
              <div className="flex items-center gap-x-2">
                {/* <img src="/images/Avatar.svg" /> */}
                <CustomAvatar
                  src={user.profilePicture}
                  title={(user.firstName || "w") + " " + (user.lastName || "d")}
                  size={32}
                  badge
                  userId={user.id}
                />
                <Popover
                  openClassName="custom-popover-open"
                  style={{
                    transform: "rotate(360deg)",
                    transition: "all 0.2s ease-in-out",
                  }}
                  placement="bottomLeft"
                  content={
                    <div>
                      <div>Select Workspace</div>
                      <div className="w-60 mb-5">
                        <CustomSelect
                          value={tenantId}
                          // containerStyle={{
                          //   width: "336px",
                          // }}
                          border={true}
                          placeholder="Select the workspace"
                          showBorderOnlyOnFocused
                          options={workspaces?.map((p) => {
                            return {
                              id: p.tenantId,
                              title: p.tenantName,
                              value: p.tenantId,
                              render: (value) => (
                                <div
                                  className="flex items-center -mt-0.5"
                                  key={p.id + "phases"}
                                >
                                  <CustomAvatar
                                    size={14}
                                    title={p.tenantName}
                                  />

                                  <p className="mon-400-14 pl-2 text-gray-900 capitalize ">
                                    {p.tenantName}
                                  </p>
                                </div>
                              ),
                            };
                          })}
                          onChange={(e) => setTenantId(e)}
                        />
                      </div>

                      <CustomButton
                        text="Switch Workspace"
                        className="mon-500-14 text-white bg-primary-600 "
                        height="36px"
                        width="100%"
                        onClick={() => {
                          handleSwitchWorkSpace();
                        }}
                      />
                      <div
                        className="mt-5 cursor-pointer w-max"
                        onClick={() => {
                          // if ("serviceWorker" in navigator) {
                          //   navigator.serviceWorker
                          //     .getRegistrations()
                          //     .then(function (registrations) {
                          //       for (const registration of registrations) {
                          //         registration.unregister();
                          //       }
                          //     });
                          // }
                          localStorage.clear();
                          Cookies.remove("jwt");
                          location.reload();
                        }}
                      >
                        Log out
                      </div>
                    </div>
                  }
                  trigger="click"
                >
                  <img
                    className="w-full h-full cursor-pointer min-w-4"
                    src="/images/icons/chevron-down-gray.svg"
                    alt="project expand"
                  />
                </Popover>
              </div>
            </div>
          </div>
        </div>
      </Layout.Header>

      {/* {showNotificationPopUp && (
        <div
          className="absolute z-50 top-12"
          style={{
            left: notificationRef
              ? notificationRef.current.getBoundingClientRect()?.left
              : "",
          }}
        >
          <NotificationPopUp />
        </div>
      )} */}
    </div>
  );
};

export default Header;
