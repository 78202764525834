import React from "react";
import { Checkbox, Switch, Dropdown, Menu } from "antd";
import { useAppDispatch, useAppSelector } from "../../../Core/redux/hooks";
import {
  getNotificationActions,
  getAlertSeverity,
  createAlert,
} from "../../../Core/redux/api/settingsAPI";
import CustomButton from "../../../Core/Common/CustomButton";
import CustomModal from "../../../Core/Common/CustomModal";
import CustomInput from "../../../Core/Common/CustomInput";
import TextArea from "antd/lib/input/TextArea";
import CustomSelect from "../../../Core/Common/CustomSelect";
import CustomDayToggle from "../../../Core/Common/CustomDayToggle";
import { getProjectUsers } from "../../../Core/redux/api/projectAPI";
import AlertsTable from "./AlertsTable";

const switchTab = (tab, switchChecked, handleSwitchCheck, handleCheck) => {
  const { notificationActions } = useAppSelector((state) => state.settings);
  const alertData = [
    {
      title: "Task Creation",
      description: "Creation of task",
      recurrence: "once everyday until 1st Jan 2023",
      severityId: 1,
      statusId: 1,
      sentTo: 5,
    },
    {
      title: "Sub task Creation",
      description: "Creation of sub-task",
      recurrence: "once everyday until 1st Jan 2023",
      severityId: 2,
      statusId: 0,
      sentTo: 10,
    },
    {
      title: "Bug Creation",
      description: "Creation of bug",
      recurrence: "once everyday until 1st Jan 2023",
      severityId: 3,
      statusId: 1,
      sentTo: 5,
    },
  ];
  switch (tab) {
    case 1:
      return (
        <div className="px-6">
          <div className="flex justify-start items-start gap-x-2">
            <Switch
              checked={switchChecked === 1}
              onChange={() => handleSwitchCheck()}
              className={`${
                switchChecked === 1 ? "bg-primary-600" : "bg-gray-100"
              }`}
            />
            <div>
              <p className="mon-500-12 text-gray-700">All Notifications</p>
              <p className="mon-400-12 text-gray-500">
                Receive notifications of all the activities in this project
              </p>
            </div>
          </div>
          <div className="flex justify-start items-start gap-x-2 mt-4">
            <Switch
              checked={switchChecked === 2}
              onChange={() => handleSwitchCheck()}
              className={`${
                switchChecked === 2 ? "bg-primary-600" : "bg-gray-100"
              }`}
            />
            <div>
              <p className="mon-500-12 text-gray-700">Custom Notifications</p>
              <p className="mon-400-12 text-gray-500">
                I only want to receive notifications when someone...
              </p>
            </div>
          </div>
          <Checkbox.Group
            disabled={switchChecked === 1}
            className="mt-6 ml-12 h-[60vh] overflow-y-scroll hide-scrollbar"
          >
            {notificationActions?.map((action) => (
              <div
                key={action.actionId}
                className="flex justify-start items-center gap-x-2 mt-2"
              >
                <Checkbox value={action.actionId} />
                <p className="mon-400-12 capitalize text-gray-700">
                  {action.description}
                </p>
              </div>
            ))}
          </Checkbox.Group>
        </div>
      );
    case 2:
      return (
        <div className="h-[calc(100vh-240px)] flex justify-center items-center">
          <div className="flex flex-col gap-y-2.5 bg-primary-50 rounded-lg p-2 text-left w-80">
            <div className="mon-600-12 text-gray-700">No alerts created</div>
            <div className="mon-400-12 text-gray-500">
              Create alerts to send customized notifications to selected team
              members in your project
            </div>
            <CustomButton
              icon="/images/icons/plus.svg"
              text="Create Alert"
              //onClick={() => setCreateAlertModal(true)}
              className="mon-500-14 text-white  bg-primary-600"
              height="40px"
              width="300px"
            />
          </div>
        </div>
      );
    case 3:
      return <AlertsTable alertData={alertData} />;
  }
};

const NotificationsTab = () => {
  const [selectedTab, setSelectedTab] = React.useState(1);
  const [checked, setChecked] = React.useState(1);
  const [selectedNotifications, setSelectedNotifications] = React.useState([]);
  const [createAlertModal, setCreateAlertModal] = React.useState(false);
  const [selectedFrequency, setSelectedFrequency] = React.useState(1);
  const [selectedUsers, setSelectedUsers] = React.useState([]);
  const [createAlertForm, setCreateAlertForm] = React.useState({
    title: "",
    description: "",
    frequency: 1,
    severity_id: 1,
    sent_to: [],
    status_id: 1,
  });
  const [selectedDays, setSelectedDays] = React.useState([]);
  const { alertSeverity, projectUsers } = useAppSelector(
    (state) => state.settings
  );
  const { selectedProject } = useAppSelector((state) => state.projects);
  const { user } = useAppSelector((state) => state.userDetails);

  const handleSwitchCheck = () => {
    if (checked === 1) {
      setChecked(2);
    } else {
      setChecked(1);
    }
  };

  const handleCheck = (action) => {
    if (selectedNotifications.includes(action)) {
      const payload = selectedNotifications.filter((i) => i !== action);
      setSelectedNotifications(payload);
    } else {
      const data = [...selectedNotifications, action];
      setSelectedNotifications(data);
    }
  };

  const dispatch = useAppDispatch();

  React.useEffect(() => {
    if (selectedTab === 1) dispatch(getNotificationActions());
    dispatch(getAlertSeverity({ projectId: selectedProject }));
    dispatch(
      getProjectUsers({
        projectId: selectedProject,
        userId: user.id,
      })
    );
  }, []);

  const handleDaySelect = (action) => {
    const data = selectedDays.filter((i) => i !== action);
    if (selectedDays.includes(action)) {
      setSelectedDays(data);
    } else {
      const payload = [...selectedDays, action];
      setSelectedDays(payload);
    }
  };

  const frequencyData = [
    {
      id: 1,
      value: "Once",
    },
    {
      id: 2,
      value: "Twice",
    },
    {
      id: 3,
      value: "Thrice",
    },
  ];

  const severityData = [
    {
      id: 1,
      value: "Low",
    },
    {
      id: 2,
      value: "Medium",
    },
    {
      id: 3,
      value: "High",
    },
  ];

  const handleSelect = (action) => {
    console.log(action, "tas");
    const data = selectedUsers.filter((i) => i !== action);
    if (selectedUsers.includes(action)) {
      setSelectedUsers(data);
    } else {
      const payload = [...selectedUsers, action];
      setSelectedUsers(payload);
    }

    {
      console.log(selectedUsers, "tas");
    }
  };

  return (
    <div className="py-3">
      <div className="flex justify-between px-6 items-center">
        <div className="flex justify-start items-center gap-x-6">
          <p
            onClick={() => setSelectedTab(1)}
            className={`cursor-pointer ${
              selectedTab === 1
                ? "border-b-2 mon-500-12 text-primary-600 border-primary-600"
                : "mon-400-12 text-gray-500 mb-1"
            }`}
          >
            Notifications
          </p>
          <p
            onClick={() => setSelectedTab(2)}
            className={`cursor-pointer ${
              selectedTab === 2
                ? "border-b-2 mon-500-12 text-primary-600 border-primary-600"
                : "mon-400-12 text-gray-500 mb-1"
            }`}
          >
            My alerts
          </p>
        </div>
        <div className="flex justify-end items-center gap-x-2">
          <CustomButton
            icon="/images/icons/plus-black.svg"
            text="Create Alert"
            onClick={() => setCreateAlertModal(true)}
            className="mon-500-14 bg-white rounded-xl border border-gray-200"
            width="145px"
          />
        </div>
      </div>
      <div className="mt-6">
        {switchTab(selectedTab, checked, handleSwitchCheck, handleCheck)}
      </div>
      <CustomModal
        title={<p className="mon-400-12 text-gray-500">Create alert</p>}
        width={"600px"}
        visible={createAlertModal}
        body={
          <div
            className={`${
              selectedTab === 3
                ? "h-[25.189386056191466vh]"
                : "h-[38.189386056191466vh]"
            } overflow-y-scroll p-4`}
          >
            <p className="mon-500-14 text-gray-700">Creating alert for</p>
            <CustomInput
              // onChange={(e) => {

              // }}
              maxLength={40}
              //   placeholder="Eg. Design"
              className=" h-10 mt-1.5"
            />
            <p className="mon-500-14 text-gray-700 mt-9">
              What is your alert about?
            </p>
            <CustomInput
              onChange={(e) => {
                setCreateAlertForm({
                  ...createAlertForm,
                  title: e.target.value,
                });
              }}
              value={createAlertForm.title}
              maxLength={40}
              placeholder="Add a title"
              className=" h-10 mt-1.5"
            />
            <p className="mon-500-14 text-gray-700 mt-9">Description</p>
            <TextArea
              onChange={(e) => {
                setCreateAlertForm({
                  ...createAlertForm,
                  description: e.target.value,
                });
              }}
              value={createAlertForm.description}
              maxLength={40}
              rows={4}
              style={{ resize: "none" }}
              placeholder="Explain in short what your alert is about..."
              className=" h-10 mt-1.5 bg-transparent border-gray-300 rounded-lg border"
            />
            <div>
              <div className="flex justify-start items-center gap-x-2.5 mt-9">
                <img src="/images/icons/refresh-cw-01.svg" alt="" />
                <p className="mon-500-14 text-gray-700">Recurrence</p>
              </div>
              <p className="mon-400-14 text-gray-500 mt-3">
                Set how many times this alert will be sent as a notification to
                the selected team members to keep reminding them
              </p>
              <div className="grid grid-cols-11 mt-4">
                <p className="col-span-5 flex justify-start items-center gap-x-2 mon-500-14 text-gray-500 border-r border-gray-200">
                  Repeat{" "}
                  <span>
                    <div className=" w-24">
                      <CustomSelect
                        value={createAlertForm.frequency}
                        placeholder="Once"
                        options={frequencyData?.map((s) => {
                          return {
                            id: s.id,
                            value: s.id,
                            render: () => (
                              <div
                                onChange={() => {
                                  setCreateAlertForm({
                                    ...createAlertForm,
                                    frequency: s.id,
                                  });
                                }}
                                className="flex justify-between items-center"
                              >
                                <p>{s.value}</p>
                                {/* {selectedFrequency === s.id && (
                                  <img src="/images/icons/check.svg" alt="" />
                                )} */}
                              </div>
                            ),
                          };
                        })}
                        //   onChange={(e) => dispatch(handleSelect(e))}
                      />
                    </div>
                  </span>
                  a day
                </p>
                <div className="col-span-6 px-4">
                  <p className="mon-500-12 text-gray-500">Select days</p>
                  <CustomDayToggle
                    selectedDays={selectedDays}
                    setSelectedDays={handleDaySelect}
                  />
                </div>
              </div>
              <div className="mt-9">
                <p className="mon-500-14 text-gray-700">Repeat till</p>
              </div>
              <div className="mt-9">
                <p className="mon-500-14 text-gray-700">Alert severity</p>
                <div className="w-1/2 mt-1.5">
                  <CustomSelect
                    // value={createAlertForm.severity_id}
                    placeholder="Medium"
                    options={severityData?.map((s) => {
                      return {
                        id: s.id,
                        value: s.id,
                        render: () => (
                          <div
                            onChange={() => {
                              setCreateAlertForm({
                                ...createAlertForm,
                                severity_id: s.id,
                              });
                            }}
                            className="flex justify-between items-center"
                          >
                            <p>{s.value}</p>
                            {/* {selectedFrequency === s.id && (
                              <img src="/images/icons/check.svg" alt="" />
                            )} */}
                          </div>
                        ),
                      };
                    })}
                    //   onChange={(e) => dispatch(handleSelect(e))}
                  />
                </div>
              </div>
              <div className="mt-9">
                <p className="mon-500-14 text-gray-700">Raising an alert to</p>
                <div className="mt-1.5">
                  <CustomSelect
                    value={null}
                    placeholder="Select team member"
                    options={projectUsers?.map((s) => {
                      return {
                        id: s.id,
                        value: s.id,
                        render: () => (
                          <div
                            onClick={() => handleSelect(s)}
                            className="flex justify-between items-center"
                          >
                            <p>
                              {s.firstname} {s.lastname}
                            </p>
                            {selectedUsers.includes(s) && (
                              <img src="/images/icons/check.svg" alt="" />
                            )}
                          </div>
                        ),
                      };
                    })}
                    // onChange={(e) => handleSelect(e)}
                  />
                  {/* {projectUsers
                    ?.filter((item) => selectedUsers?.includes(item?.id))
                    ?.map((s, i) => (
                      <div
                        className="mt-2 mon-500-14 text-gray-900 flex justify-between items-center w-full"
                        key={i}
                      >
                        <div>
                          {s?.firstname} {s?.lastname}
                        </div>
                        <div
                          className="cursor-pointer"
                          onClick={() => handleSelect(s?.id)}
                        >
                          <img
                            src="/images/icons/sector-icons/input-cross.svg"
                            alt=""
                          />
                        </div>
                      </div>
                    ))} */}
                  {selectedUsers?.map((user) => (
                    <div
                      key={user?.id}
                      className=" flex justify-between items-center mt-4"
                    >
                      <div>
                        <p>
                          {user?.firstname} {user?.lastname}
                        </p>
                      </div>
                      <div
                        className="cursor-pointer"
                        onClick={() => handleSelect(user)}
                      >
                        <img src="/images/icons/cross.svg" alt="" />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        }
        footer={
          <div className="flex justify-end p-3">
            <div className="w-2/4 flex justify-end gap-x-2">
              <CustomButton
                text="Cancel"
                onClick={() => setCreateAlertModal(false)}
                className="border-2 border-gray-200 text-gray-700 bg-white mon-500-14 "
                height="40px"
                width="81px"
              />
              <CustomButton
                text="Create Alert"
                onClick={async () => {
                  await dispatch(
                    createAlert({
                      ...createAlertForm,
                      days: [...selectedDays],
                    })
                  );
                  setCreateAlertModal(false);
                  setSelectedTab(3);
                }}
                className="mon-500-14 text-white  bg-primary-600 "
                height="40px"
                width="117px"
              />
            </div>
          </div>
        }
        onCancel={undefined}
      />
    </div>
  );
};

export default NotificationsTab;
