import React, { useState } from "react";
import { useAppDispatch } from "../../Core/redux/hooks";
import { updateConversations } from "../../Core/redux/slices/conversations.slice";

const FloatButton = () => {
  const [isOpen, setIsOpen] = useState(false);
  const sideBarLength = 200;
  const dispatch = useAppDispatch();

  return (
    <div className="absolute bottom-5 right-5 flex flex-col justify-end items-end">
      {isOpen && (
        <>
          <button
            className="flex items-center h-[38px] w-[200px] pl-3.5 bg-primary-600 rounded-lg mb-3"
            onClick={() => {
              setIsOpen(!isOpen);
              dispatch(
                updateConversations({
                  key: "threadModal",
                  value: true,
                })
              );
            }}
          >
            <img src="/images/icons/folder-plus-white.svg" />
            <p className="text-white mon-400-12 ml-2">New Thread</p>
          </button>
          <button
            className="flex items-center h-[38px] w-[200px] pl-3.5 bg-primary-600 rounded-lg mb-3"
            onClick={() => {
              setIsOpen(!isOpen);
              dispatch(
                updateConversations({ key: "newMessageModal", value: true })
              );
            }}
          >
            <img src="/images/icons/file-plus-white.svg" />
            <p className="text-white mon-400-12 ml-2">New Message</p>
          </button>
        </>
      )}

      <button
        onClick={() => setIsOpen(!isOpen)}
        className={`h-11 w-11 rounded-xl bg-primary-600  shadow-lg flex justify-center items-center transform duration-500 ${
          isOpen ? "rotate-[225deg]" : "rotate-0"
        }`}
      >
        <img src="/images/icons/plus.svg" className="w-5 h-5" />
      </button>
    </div>
  );
};

export default FloatButton;
