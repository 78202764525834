import React, { useState } from "react";
import { Dropdown, Menu } from "antd";

/**
 * @param  {String} text
 * @param  {String} width
 * @param   {String} height
 * @param  {Function} onClick
 * @param  {String} icon
 * */

type CopyButtonProps = {
  text?: string;
  className?: string;
  width?: string;
  height?: string;
};

export default function CopyButton({
  text,
  width = "64px",
  height = "28px",
  className,
}: CopyButtonProps) {
  const [open, setOpen] = useState(false);
  return (
    <Dropdown
      getPopupContainer={(trigger) => trigger.parentElement}
      className=""
      overlay={
        <Menu className="w-[110px] rounded-lg custom-menu-dropdown bg-white  shadow-dropdown">
          <Menu.Item key="copy" className=" py-2.5 px-4 rounded-t-lg">
            <span className="font-normal ml-2 whitespace-nowrap text-sm text-gray-900">
              Copied
            </span>
          </Menu.Item>
        </Menu>
      }
      open={open}
      trigger={["click"]}
    >
      <button
        onClick={async () => {
          setOpen(true);
          await navigator.clipboard.writeText(text);
          setTimeout(() => setOpen(false), 1000);
        }}
        className={`${className} bg-primary-600 text-white  mon-500-14 rounded-lg `}
        style={{ width, height }}
      >
        <div className="flex justify-center items-center">
          <p>Copy</p>
        </div>
      </button>
    </Dropdown>
  );
}
