import React, { useState, useEffect, useRef } from "react";
import { useDrop } from "react-dnd";

const KanbanColumn = ({ status, changeTaskStatus, children, className }) => {
  const ref = useRef(null);
  const [, drop] = useDrop({
    accept: "card",
    drop: (item) => {
      console.log("dropped");
      changeTaskStatus(item, status);
    },
  });
  drop(ref);
  return (
    <div className={`h-full ${className}`} ref={ref}>
      {" "}
      {children}
    </div>
  );
};

export default KanbanColumn;
