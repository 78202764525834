import { Col, Row } from "antd";
import React from "react";

export const ProductOnboardingLayout = ({
  content,
  sidebar,
  biggerSiderbar = false,
}) => {
  return (
    <Row className="h-full w-full">
      <Col span={biggerSiderbar ? 16 : 16}>{content}</Col>
      <Col span={biggerSiderbar ? 8 : 8}>{sidebar}</Col>
    </Row>
  );
};

export const ProjectOnboardingLayout = ({content, sidebar}) => {
  return (
    <Row className="h-screen max-h-screen w-full overflow-hidden bg-white">
      <Col span={13}>{content}</Col>
      <Col span={11}>{sidebar}</Col>
    </Row>
  );
};
