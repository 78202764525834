import { message, Tooltip } from "antd";
import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { setSuccess } from "../redux/slices/global.slice";
import copyTextToClipboard from "../utils/clipboard";

function SuccessMessage() {
  const { success, status } = useAppSelector((state) => state.global);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (success?.message !== "" && status === "fulfilled") {
      message.success({
        content: (
          <Tooltip
            title="Click To Copy the Error"
            onOpenChange={(visible) => {
              if (!visible) {
                dispatch(setSuccess({ message: "" }));
              }
            }}
          >
            <span
              className="mon-500-14 text-gray-900 cursor-pointer"
              onClick={() => {
                copyTextToClipboard(success.message);
                dispatch(setSuccess({ message: "" }));
              }}
            >
              {success.message}
            </span>
          </Tooltip>
        ),
        className: "custom-message",
        //duration: 10000,
        style: {
          // marginTop: '20vh',
          borderRadius: "10px",
        },
        onClose: () => dispatch(setSuccess({ message: "" })),
      });
    }

    return () => {
      dispatch(setSuccess({ message: "" }));
    };
  }, [success, status]);

  // rest of the component's code goes here
  return <></>;
}

export default SuccessMessage;
