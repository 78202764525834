import React, { useEffect, useState } from "react";
import CustomModal from "../../../src/Core/Common/CustomModal";
import CustomButton from "../../../src/Core/Common/CustomButton";
import CustomInputWithEdit from "../../Core/Common/CustomInputWithEdit";
import { DatePicker, Divider, Progress } from "antd";
import { UpcomingGoalItem } from "../Overview/Components/UpcomingGoals";
import { useAppDispatch, useAppSelector } from "../../Core/redux/hooks";
import dayjs from "dayjs";
import {
  getGoalsBySprint,
  getSprintsByPhase,
  updateSprint,
} from "../../Core/redux/api/scheduleAPI";
import { useParams } from "react-router-dom";
import { updateSchedule } from "../../Core/redux/slices/schedule.slice";

type EditSprintModalProps = {
  visible: boolean;
  onCancel: () => void;
  sprint?: {
    id: string;
    name: string;
    endDate: string;
    startDate: string;
    goals?: [];
  };
};
const EditSprintModal = ({
  visible,
  onCancel,
  sprint,
}: EditSprintModalProps) => {
  const [oldSprint, setOldSprint] = useState(sprint);
  const [newSprint, setNewSprint] = useState(sprint);
  const dispatch = useAppDispatch();
  const { projectId } = useParams();
  const { currentSelectedPhase } = useAppSelector((state) => state.schedule);

  useEffect(() => {
    if (visible) {
      dispatch(getGoalsBySprint({ projectId, sprintId: sprint.id }));
    }
  }, [sprint.id, visible]);

  const getGoalsCompletePercentage = () => {
    const goals = sprint.goals ? sprint.goals : [];
    const totalGoals = goals.length;
    const completedGoals = goals.filter((g) => g.isCompleted).length;

    if (totalGoals === 0 || completedGoals === 0) return 0;
    return Math.round((completedGoals / totalGoals) * 100);
  };

  const hasChanged = () => {
    return (
      newSprint.name !== sprint.name ||
      newSprint.startDate !== sprint.startDate ||
      newSprint.endDate !== sprint.endDate
    );
  };
  return (
    <CustomModal
      title={
        <div className="flex items-center">
          <p className="mon-400-16 pl-3 text-gray-400">Schedule</p>
          <span className="mon-400-16 pl-3 text-gray-400"> / </span>
          <p className="mon-400-16 pl-3 text-gray-400">
            {" "}
            {currentSelectedPhase.phases}
          </p>
          <span className="pl-3 text-black"> / </span>
          <p className="flex mon-500-16 text-black pl-3">Sprint</p>
        </div>
      }
      width={"1064px"}
      height={"608px"}
      visible={visible}
      body={
        <div className="max-h-[48.189386056191466vh] h-[608px] max-w-[1064px] w-[90vw] overflow-y-scroll px-5 py-5">
          <div className="flex justify-between items-center">
            <CustomInputWithEdit
              value={newSprint.name || ""}
              inputClassName="mon-600-20 text-black"
              containerClassName="border-0"
              onChange={(e) => {
                setNewSprint({ ...newSprint, name: e.target.value });
              }}
              placeholder="Sprint Name"
            />
            <div className="bg-white flex items-center rounded-xl h-10 px-2.5">
              <div className="text-gray-700 mon-500-14 px-3 -mb-[2px]">
                Goals
              </div>
              <div className=" w-[279px]">
                <Progress
                  percent={getGoalsCompletePercentage()}
                  status="success"
                  strokeWidth={4}
                  showInfo={false}
                  trailColor="#EDF3F9"
                />
              </div>
              <div className="pl-3 mon-500-14 text-gray-700 -mb-[2px] mr-5">
                {getGoalsCompletePercentage()}%
              </div>
              <button
                onClick={() => {
                  onCancel();
                  dispatch(
                    updateSchedule({ key: "newGoalModal", value: true })
                  );
                  dispatch(
                    updateSchedule({ key: "selectedSprint", value: sprint })
                  );
                }}
              >
                <img src="/images/icons/plus-blue.svg" />
              </button>
            </div>
          </div>
          <div className="flex justify-between">
            <div>
              <div className="mt-5">
                <DatePicker.RangePicker
                  // disabledDate={(d) => !d || d.isAfter(moment()) || d.isSameOrBefore(moment().subtract(1, 'Y'))}
                  style={{ height: "36px", maxWidth: "140px" }}
                  //size="large"
                  separator={
                    <span className="mon-400-12 text-gray-700 px-2">-</span>
                  }
                  suffixIcon={<></>}
                  allowClear={false}
                  format="DD MMM"
                  className=" custom-range-picker border-0 w-full bg-gray-50 rounded-xl   mon-400-12 text-gray-700"
                  value={
                    newSprint.startDate !== null
                      ? [
                          dayjs(newSprint.startDate) as any,
                          dayjs(newSprint.endDate) as any,
                        ]
                      : ("" as any)
                  }
                  onChange={(val) =>
                    setNewSprint({
                      ...newSprint,
                      startDate: dayjs(val[0] as any).format("YYYY-MM-DD"),
                      endDate: dayjs(val[1] as any).format("YYYY-MM-DD"),
                    })
                  }
                />
                <div className="mon-500-16 text-black mt-9">Activity</div>
              </div>
            </div>
            <div className="w-[50%] border rounded-2xl min-h-[358px]">
              <div className="flex justify-between pt-6 ml-7 mr-7">
                <div className="flex uppercase text-[10px] font-medium text-gray-500 max-w-[19vw] min-w-[10vw]">
                  Goal name
                </div>
                <div className="flex items-center">
                  <div className="uppercase font-medium text-[10px] text-gray-500 float-left">
                    Due Date
                  </div>
                  <div className="uppercase font-medium text-[10px] text-gray-500 pl-6 ">
                    Priority
                  </div>
                </div>
              </div>
              <div>
                <UpcomingGoalItem
                  goals={sprint.goals}
                  text="Jump right in - Add new goals now!"
                  title="No goals added yet"
                >
                  <div className="ml-4 mt-2.5 pb-4">
                    <CustomButton
                      icon="/images/icons/plus-black.svg"
                      text="New goal"
                      className="bg-white border-gray-200"
                      height="36px"
                      width="123px"
                      onClick={() => {
                        onCancel();
                        dispatch(
                          updateSchedule({ key: "newGoalModal", value: true })
                        );
                        dispatch(
                          updateSchedule({
                            key: "selectedSprint",
                            value: sprint,
                          })
                        );
                      }}
                    />
                  </div>
                </UpcomingGoalItem>
              </div>
            </div>
          </div>
        </div>
      }
      footer={
        <div className="flex justify-end py-4 gap-x-3 px-3 shadow-upward">
          {hasChanged() ? (
            <>
              <CustomButton
                text="Cancel"
                onClick={() => setNewSprint(sprint)}
                className="border border-gray-300 text-gray-700 bg-white mon-500-14"
                height="40px"
                width="81px"
              />
              <CustomButton
                text="Save"
                onClick={async () => {
                  await dispatch(
                    updateSprint({
                      sprintId: sprint.id,
                      title: newSprint.name,
                      startDate: newSprint.startDate,
                      endDate: newSprint.endDate,
                    })
                  );
                  onCancel();
                  dispatch(
                    getSprintsByPhase({
                      projectId,
                      phaseId: currentSelectedPhase.id,
                    })
                  );
                }}
                className="mon-500-14 text-white bg-primary-600 "
                height="40px"
                width="85px"
              />
            </>
          ) : (
            <CustomButton
              text="Delete"
              className="text-white mon-500-14 bg-error-600"
              height="40px"
              width="79px"
              onClick={async () => {
                await dispatch(
                  updateSprint({ sprintId: sprint.id, isActive: false })
                );
                onCancel();
                dispatch(
                  getSprintsByPhase({
                    projectId,
                    phaseId: currentSelectedPhase.id,
                  })
                );
              }}
            />
          )}
        </div>
      }
      onCancel={onCancel}
    />
  );
};
export default EditSprintModal;
