import { Avatar, Checkbox, Col, Dropdown, Input, Row, Tooltip } from "antd";
import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";
import CustomAvatar from "../../../Core/Common/CustomAvatar";
import { useAppDispatch, useAppSelector } from "../../../Core/redux/hooks";
import { updateSchedule } from "../../../Core/redux/slices/schedule.slice";
import getUsersName from "../../../Core/utils/getUserName";

const CheckboxGroup = Checkbox.Group;
const UserQuickFilters = ({ max = 4 }) => {
  const { userList } = useAppSelector((state) => state.overview);
  const { user } = useAppSelector((state) => state.userDetails);
  const [search, setSearch] = useState("");
  const dispatch = useAppDispatch();
  const { filterGoalParameter } = useAppSelector((state) => state.schedule);

  if (userList.length === 0) {
    return <></>;
  }

  const toggleSelectedUser = (id) => {
    const { assignee } = filterGoalParameter;
    //const [searchParams, setSearchParams] = useSearchParams();
    let newAssignee = [...assignee];
    if (newAssignee.includes(id)) {
      newAssignee = newAssignee.filter((userId) => userId !== id);
    } else {
      newAssignee = [...newAssignee, id];
    }
    dispatch(
      updateSchedule({
        key: "filterGoalParameter",
        value: { ...filterGoalParameter, assignee: newAssignee },
      })
    );
  };

  const newUser = [...userList];

  const sortedUser = newUser.sort((a, b) => {
    if (a.userId == user.id) return -1;
    if (b.userId == user.id) return 1;
    return 0;
  });

  const avatarUser = sortedUser.slice(0, max);
  const otherUsers = sortedUser.slice(max);
  return (
    <div className="">
      <div className="avatar-group">
        {avatarUser.map((user) => (
          <Tooltip
            title={getUsersName(user)}
            key={user.userId}
            placement="bottom"
          >
            <span
              className="avatar cursor-pointer"
              key={user.userId}
              style={{
                border: filterGoalParameter.assignee?.includes(user.userId)
                  ? "2px solid #165ABF"
                  : "2px solid #fff",
              }}
              onClick={() => toggleSelectedUser(user.userId)}
            >
              <CustomAvatar
                src={user.profilePicture}
                title={getUsersName(user)}
                size={34}
              />
            </span>
          </Tooltip>
        ))}
        {otherUsers.length > 0 && (
          <span
            className="avatar bg-primary-gray z-[1]"
            style={{
              border: otherUsers
                .map((m) => m.userId)
                .some((el) => filterGoalParameter.assignee?.includes(el))
                ? "2px solid #165ABF"
                : "2px solid #D8E1EF",
            }}
          >
            <Dropdown
              trigger={["click"]}
              overlay={
                <div className="shadow-box border min-w-[200px] max-w-[200px] rounded-lg overflow-hidden bg-white ">
                  <div className="sticky top-0 z-10  bg-gray-50">
                    <Input
                      className="w-full rounded-lg bg-white focus:outline-none mon-500-12  h-10 "
                      prefix={<img src="/images/icons/search.svg" />}
                      bordered={false}
                      //onChange={handleSearch}
                      onChange={(e) => setSearch(e.target.value)}
                      placeholder="Search User"
                    />
                  </div>
                  <div className="px-3">
                    <CheckboxGroup
                      value={filterGoalParameter.assignee}
                      className="max-h-[300px] min-h-[100px] overflow-y-scroll overflow-x-hidden"
                    >
                      <Row gutter={[10, 10]}>
                        {otherUsers
                          .filter(
                            (user) =>
                              user.firstName
                                ?.toLowerCase()
                                .includes(search.toLowerCase()) ||
                              user.lastName
                                ?.toLowerCase()
                                .includes(search.toLowerCase()) ||
                              user.email
                                ?.toLowerCase()
                                .includes(search.toLowerCase())
                          )
                          .map((user, i) => (
                            <Col span={24} key={user.userId + i}>
                              {" "}
                              <Checkbox
                                value={user.userId}
                                onChange={() => toggleSelectedUser(user.userId)}
                                checked={filterGoalParameter.assignee?.includes(
                                  user.userId
                                )}
                              >
                                <p className="mon-400-12 text-gray-700 ml-1 capitalize">
                                  {user.firstname} {user.lastname}
                                </p>{" "}
                              </Checkbox>{" "}
                            </Col>
                          ))}
                      </Row>
                    </CheckboxGroup>
                  </div>
                </div>
              }
            >
              <span className="h-full cursor-pointer w-full flex justify-center items-center mon-500-14 text-gray-700">
                +{otherUsers.length}
              </span>
            </Dropdown>
          </span>
        )}
      </div>
    </div>
  );
};

export default UserQuickFilters;
