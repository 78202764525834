import React from "react";

const CustomDayToggle = ({ selectedDays, setSelectedDays }) => {
  const weekDays = [
    {
      id: 7,
      value: "Sunday",
    },
    {
      id: 1,
      value: "Monday",
    },
    {
      id: 2,
      value: "Tuesday",
    },
    {
      id: 3,
      value: "Wednesday",
    },
    {
      id: 4,
      value: "Thursday",
    },
    {
      id: 5,
      value: "Friday",
    },
    {
      id: 6,
      value: "Saturday",
    },
  ];
  return (
    <div className="flex justify-start items-center gap-x-1">
      {weekDays.map((day) => (
        <div
          onClick={() => setSelectedDays(day.id)}
          key={day.id}
          className={`${
            selectedDays.includes(day.id)
              ? "text-white bg-primary-700"
              : "text-gray-500 bg-gray-50"
          } capitalize mon-500-12  w-6 flex justify-center cursor-pointer py-0.5 rounded-full`}
        >
          {day.value[0]}
        </div>
      ))}
    </div>
  );
};

export default CustomDayToggle;
