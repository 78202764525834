import React, { useEffect, useState } from "react";
import CustomButton from "../../Core/Common/CustomButton";
import CustomModal from "../../Core/Common/CustomModal";
import { Checkbox, Row, Col } from "antd";

import { useAppDispatch, useAppSelector } from "../../Core/redux/hooks";

import { useParams } from "react-router-dom";

import CustomSearch from "../../Core/Common/CustomSearch";
import { client } from "../../Core/utils/axiosClient";
import { useFormik } from "formik";
import * as Yup from "yup";

type NewTaskModalProps = {
  visible: boolean;
  onCancel: () => void;
  onApply: () => void;
};

const ImportRolesModal = ({
  visible,
  onCancel,
  onApply,
}: NewTaskModalProps) => {
  const { user } = useAppSelector((state) => state.userDetails);

  const [projectList, setProjectList] = useState([]);
  const [selectedProjectId, setSelectedProjectId] = useState(null);
  const [search, setSearch] = useState(null);
  const [roleList, setRoleList] = useState([]);
  const { projectId } = useParams();

  useEffect(() => {
    console.log("hello");
    client.get(`/project/project-list?id=${user.id}`).then((res) => {
      console.log(res);
      const list = res.data.filter(({ id }) => +id !== +projectId);
      setProjectList(list);
    });
  }, []);

  const formik = useFormik({
    initialValues: {
      roleList: [],
    },
    validationSchema: Yup.object({
      roleList: Yup.array()
        .min(1, "Select at least one role")
        .required("Please enter the email address")
        .typeError("Please enter the email address"),
    }),
    onSubmit: (values) => {
      console.log(values);
      const { roleList } = values;
      client
        .post("permissions/import-role", {
          projectId,
          roleProjectId: selectedProjectId,
          roleIdList: roleList,
        })
        .then(() => {
          onApply();
        });
    },
  });

  const setCheckedRoleList = (value) => formik.setFieldValue("roleList", value);

  const handleProjectSelect = (projectId) => {
    setSelectedProjectId(projectId);
    setCheckedRoleList([]);
    client
      .get("permissions/role-list", {
        params: {
          projectId,
        },
      })
      .then((res) => {
        console.log(res.data.result);
        setRoleList(res.data.result);
      });
  };

  const handleSearch = (list) => {
    if (!search) return list;
    return list.filter(({ title }) =>
      title.toLowerCase().includes(search?.toLowerCase())
    );
  };

  const onCheckboxChange = (list) => {
    console.log("onCheckboxChange", list);
    setCheckedRoleList(list);
  };

  return (
    <>
      <CustomModal
        title={
          <div className="flex items-center">
            <div className="flex items-center">
              <p className="mon-400-12 pl-1 text-gray-500">Import role</p>
            </div>
          </div>
        }
        width="704px"
        visible={visible}
        height="608px"
        body={
          <div>
            <Row>
              <Col span={12}>
                <div className="px-4 mt-4 border-r border-gray-200">
                  <CustomSearch
                    search={search}
                    setSearch={setSearch}
                    className="border border-gray-300"
                  />
                  <div className="mt-4 ">
                    <p className="mon-500-10 text-gray-500 px-[14px]">
                      List of projects
                    </p>
                    {handleSearch(projectList).map(({ id, title }) => (
                      <div
                        key={id}
                        onClick={() => handleProjectSelect(id)}
                        className={`flex justify-between items-center mb-2 ${
                          selectedProjectId === id
                            ? "text-primary-600 bg-primary-100"
                            : "text-black hover:bg-gray-50"
                        }  mt-4 rounded-lg py-2.5 px-3.5 cursor-pointer`}
                      >
                        <div className={`mon-500-14 `}>{title}</div>
                      </div>
                    ))}
                  </div>
                </div>
              </Col>
              <Col span={12}>
                <div className="px-5 mt-4 ">
                  <div className="mt-7">
                    <p className="mon-500-10 text-gray-500 uppercase">
                      Select a role to import
                    </p>
                    <Checkbox.Group
                      className=" mt-12"
                      onChange={onCheckboxChange}
                      value={formik.values.roleList}
                    >
                      <div className="flex flex-col">
                        {roleList.map(({ id, title }) => (
                          <div key={id} className="mb-3">
                            <Checkbox key={id} value={id}>
                              <p className="mon-400-12 text-gray-700">
                                {title}
                              </p>
                            </Checkbox>
                          </div>
                        ))}
                      </div>
                    </Checkbox.Group>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        }
        footer={
          <div className="flex justify-between py-4 px-3 shadow-upward">
            <div className="flex gap-x-3 justify-between w-full">
              <CustomButton
                // text={selectedTask ? "Close" : "Cancel"}
                //       onClick={() => {
                //         !selectedTask &&
                //           ImportRolesModal
                // .isNewScope &&
                //           dispatch(
                //             updateSchedule({ key: "newScopeModal", value: true })
                //           );
                //         onCancel();
                //       }}
                text="Cancel"
                className="border border-gray-300 text-gray-700 bg-white mon-500-14"
                height="40px"
                onClick={onCancel}
                width="50%"
              />
              {/* {!selectedTask && ( */}
              <CustomButton
                text={
                  formik.values.roleList.length > 1
                    ? "Import Roles"
                    : "Import Role"
                }
                disabled={!(formik.isValid && formik.dirty)}
                className={`mon-500-14 text-white bg-primary-600 `}
                height="40px"
                width="50%"
                onClick={() => formik.handleSubmit()}
                // onClick={() => createTask()}
              />
            </div>
          </div>
        }
        onCancel={onCancel}
      />

      {/* <>
        {addFolderModal && (
          <AddFolderModal
            visible={addFolderModal}
            onCancel={() =>
              dispatch(updateVault({ key: "addFolderModal", value: false }))
            }
          />
        )}
      </> */}
    </>
  );
};

export default ImportRolesModal;
