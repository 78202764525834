import { createAsyncThunk } from "@reduxjs/toolkit";
import { client, getError } from "../../utils/axiosClient";

export const getProjectDetatis = createAsyncThunk(
  "project/getProjectDetatis",
  async (
    {
      projectId,
    }: {
      projectId: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await client.get(`/project?projectId=${projectId}`);

      return response.data;
    } catch (error) {
      return rejectWithValue(getError(error));
      //return error;
    }
  }
);

export const createProject = createAsyncThunk(
  "project/createProject",
  async (
    {
      title,
      //components,
      startDate,
      endDate,
      projectColor,
      projectImage,
      clientName,
      clientImage,
      clientLocation,
      projectType,
      phases,
      internalProject,
    }: {
      title?: string;
      // components,
      startDate: string;
      endDate: string;
      projectColor: string;
      projectImage: string;
      clientName?: string;
      clientImage?: string;
      clientLocation?: string;
      projectType: number | string;
      phases?: {
        id: string | number;
        startDate: string;
        endDate: string;
      }[];
      internalProject: boolean;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await client.post(`/project/create`, {
        title,
        description: "",
        components: null,
        startDate,
        endDate,
        projectColor,
        projectImage,
        clientName,
        clientImage,
        clientLocation,
        projectType,
        phases,
        internalProject,
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(getError(error));
      //return error;
    }
  }
);

export const updateProjectDetails = createAsyncThunk(
  "schedule/updateProjectDetails",
  async (payload: object, { rejectWithValue }) => {
    try {
      const response = await client.put(`project/update-project`, payload);

      return response.data;
    } catch (error) {
      return rejectWithValue(getError(error));
      //return error;
    }
  }
);

export const setupPhases = createAsyncThunk(
  "project/setupPhases",
  async (
    {
      projectId,
      phases,
    }: {
      projectId: string;
      phases: any[];
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await client.put(`/project/setup-phases`, {
        projectId,
        phases,
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(getError(error));
      //return error;
    }
  }
);

export const getProjectUsers = createAsyncThunk(
  "project/getProjectUsers",
  async (
    {
      projectId,
      userId,
    }: {
      projectId: string;
      userId?: number;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await client.get(
        `/project/project-users?projectId=${projectId}`
      );
      response.data.userId = userId;
      return response.data;
    } catch (error) {
      return rejectWithValue(getError(error));
      //return error;
    }
  }
);

export const getOrganizationUsers = createAsyncThunk(
  "project/getOrganizationUsers",
  async (
    {
      userId,
    }: {
      userId?: number;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await client.get(`/tenant/members`);
      response.data.userId = userId;
      return response.data;
    } catch (error) {
      return rejectWithValue(getError(error));
      //return error;
    }
  }
);

export const endPhase = createAsyncThunk(
  "project/endPhase",
  async (
    {
      projectId,
      phaseId,
      isCompleted,
      isOngoing,
      isActive,
      endDate,
    }: {
      projectId: string;
      phaseId?: string;
      isCompleted?: boolean;
      isOngoing?: boolean;
      isActive?: boolean;
      endDate?: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await client.put(`/project/project-phases?projectId`, {
        projectId,
        phaseId,
        isCompleted,
        isOngoing,
        isActive,
        endDate,
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(getError(error));
      //return error;
    }
  }
);

export const getProjectList = createAsyncThunk(
  "project/getProjectList",
  async (userId: number, { rejectWithValue }) => {
    try {
      const response = await client.get(`/project/project-list?id=${userId}`);
      console.log(response.data, "data");
      return response.data;
    } catch (error) {
      return rejectWithValue(getError(error));
      //return error;
    }
  }
);

export const deleteProject = createAsyncThunk(
  "project/deleteProject",
  async (
    {
      projectId,
    }: {
      projectId: string;
    },

    { rejectWithValue }
  ) => {
    try {
      const response = await client.delete(
        `/project/delete-project?projectId=${projectId}`
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(getError(error));
      //return error;
    }
  }
);

export const getProjectPermission = createAsyncThunk(
  "project/getProjectPermission",
  async (
    {
      projectId,
      userId,
    }: {
      projectId: string;
      userId: number;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await client.get(
        `/project/project-permissions?projectId=${projectId}&userId=${userId}`
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(getError(error));
      //return error;
    }
  }
);



export const markSpoc = createAsyncThunk(
  "project/markSpoc",
  async (
    {
      projectId,
      userId,
      spoc
    }: {
      projectId: string;
      userId: number;
      spoc:number
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await client.put(
        `/project/mark-spoc` , {
          projectId,
          userId,
          spoc
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(getError(error));
      //return error;
    }
  }
);