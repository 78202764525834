import { Col, Row } from "antd";
import React, { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAppDispatch } from "../redux/hooks";
import { updatePreviewMultiple } from "../redux/slices/preview.slice";

import DownloadIcon from "../svg/DownLoad";

export const Attachments = ({
  type,
  url,
  thumbnail,
  showName = false,
  className = "w-[329px] h-[178px]",
  fileName = "",
  onClick,
}) => {
  if (type.includes("video")) {
    return (
      <div className="px-2 py-2 rounded overflow-hidden">
        <video
          muted
          //   onCanPlayThrough={(e) => {
          //     const video = e.target;
          //     setTimeout(() => {
          //       video?.pause();
          //     }, 1000);
          //   }}
          autoPlay={false}
          controls
          preload="metadata"
          //poster={thumbnail}
        >
          <source src={`${url}#t=0.1`}></source>
        </video>
      </div>
    );
  }

  const [showThumnail, setShowThumnail] = useState(true);

  return (
    <>
      <div
        className={`mx-2 my-2 border border-black cursor-pointer rounded overflow-hidden ${className} flex justify-center items-center object-cover relative actions-row bg-white`}
        onClick={onClick}
      >
        {showThumnail && (
          <img
            className={`${
              thumbnail ? "w-full h-full" : " h-24 w-24 "
            } rounded object-cover`}
            src={thumbnail || "/images/No-Image-Placeholder.svg"}
            // alt={name}
          />
        )}
        <img
          className={`${
            url ? "w-full h-full" : " h-24 w-24 "
          } rounded object-cover ${showThumnail ? "hidden" : ""}`}
          src={url || "/images/No-Image-Placeholder.svg"}
          // alt={name}
          onLoad={() => setShowThumnail(false)}
        />
      </div>
      {showName && (
        <div className="flex mx-2 my-2 ">
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              fill="none"
              viewBox="0 0 20 20"
            >
              <rect
                width="15"
                height="15"
                x="2.5"
                y="2.5"
                fill="#E0E7EE"
                rx="4"
              ></rect>
              <path
                stroke="#0D3271"
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M10.833 5.946v1.721c0 .234 0 .35.046.44.04.078.104.142.182.182.089.045.206.045.44.045h1.72m-2.388 3.75h-2.5m3.334-1.667H8.333m5-1.255v3.005c0 .7 0 1.05-.136 1.318a1.25 1.25 0 01-.546.546c-.268.136-.617.136-1.318.136H8.667c-.7 0-1.05 0-1.318-.136a1.25 1.25 0 01-.546-.546c-.136-.268-.136-.618-.136-1.318V7.834c0-.7 0-1.05.136-1.317a1.25 1.25 0 01.546-.547c.268-.136.618-.136 1.318-.136h1.338c.306 0 .459 0 .602.035.128.03.25.08.362.15.126.076.234.185.45.4l1.329 1.33c.216.215.324.323.401.45.069.111.12.233.15.36.034.145.034.298.034.603z"
              ></path>
            </svg>
          </div>
          <p className="mon-500-12 truncate w-[315px] pl-2 ">{fileName}</p>
        </div>
      )}
    </>
  );
};

const Album = ({ files }) => {
  // const  = (files) => {
  //   return files.map((f) => {
  //     const newf = {
  //       title: f?.name,
  //       phaseId: 0,
  //       fileId: 0,
  //       thumbnail: f?.thumbnail,
  //       file: f,
  //       orginal: f.url,
  //       usersWithAccess: [],
  //     };
  //     return newf;
  //   });
  // };

  const [searchParams, setSearchParams] = useSearchParams();

  const addFilePreview = () => {
    const updatedSearchParams = new URLSearchParams(searchParams.toString());
    updatedSearchParams.set("filePreview", "true");
    setSearchParams(updatedSearchParams.toString());
  };

  const dispatch = useAppDispatch();
  const renderAlbum = (layout) => {
    if (layout > 4) {
      const firstFours = files.slice(0, 3);
      const fourth = files.slice(3, 4);
      return (
        <Row className="relative">
          {firstFours.map((f, i) => (
            <Col span={12} key={i}>
              <Attachments
                type={f.type}
                url={f.url}
                thumbnail={f.thumbnail}
                className="w-[15vw] h-[178px] max-w-[15vw] max-h-[178px]"
                onClick={() => {
                  dispatch(
                    updatePreviewMultiple([
                      {
                        key: "docs",
                        value: files,
                      },
                      {
                        key: "visible",
                        value: true,
                      },
                      {
                        key: "from",
                        value: "conversation",
                      },
                      {
                        key: "currentIndex",
                        value: i,
                      },
                    ])
                  );
                  addFilePreview();
                }}
              />
            </Col>
          ))}
          <Col span={12}>
            <div
              className={`mx-2 my-2 cursor-pointer rounded overflow-hidden w-[187px] h-[178px] flex justify-center items-center object-cover relative actions-row bg-white`}
              style={{
                backgroundColor: "rgba(0,0,0,0.4)",
              }}
              onClick={() => {
                dispatch(
                  updatePreviewMultiple([
                    {
                      key: "docs",
                      value: files,
                    },
                    {
                      key: "visible",
                      value: true,
                    },
                    {
                      key: "from",
                      value: "conversation",
                    },
                  ])
                );
                addFilePreview();
              }}
            >
              <img
                className={`${
                  fourth.thumbnail
                    ? " w-[15vw] h-[178px] max-w-[15vw] max-h-[178px]"
                    : " h-24 w-24 "
                } rounded object-cover `}
                src={fourth.thumbnail || "/images/No-Image-Placeholder.svg"}
                // alt={name}
              />
              <div className="">
                <div className="absolute z-10 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2  items-center justify-center w-[40px] h-[40px] rounded-full bg-[#344054] flex mon-500-14  ">
                  +{files.length - 4}
                </div>
              </div>
            </div>
          </Col>
        </Row>
      );
    }
    switch (layout) {
      case 1:
        return (
          <Attachments
            type={files[0]?.type}
            url={files[0]?.url}
            thumbnail={files[0]?.thumbnail}
            fileName={files[0]?.name}
            showName
            // onClick={() => {}}
            onClick={() => {
              dispatch(
                updatePreviewMultiple([
                  {
                    key: "docs",
                    value: files,
                  },
                  {
                    key: "visible",
                    value: true,
                  },
                  {
                    key: "from",
                    value: "conversation",
                  },
                ])
              );
              addFilePreview();
            }}
          />
        );

      case 4:
      case 2:
        return (
          <Row>
            {files.map((f, i) => (
              <Col span={12} key={i}>
                <Attachments
                  type={f.type}
                  url={f.url}
                  thumbnail={f.thumbnail}
                  className="w-[15vw] h-[178px] max-w-[15vw] max-h-[178px]"
                  onClick={() => {
                    dispatch(
                      updatePreviewMultiple([
                        {
                          key: "docs",
                          value: files,
                        },
                        {
                          key: "visible",
                          value: true,
                        },
                        {
                          key: "from",
                          value: "conversation",
                        },
                        {
                          key: "currentIndex",
                          value: i,
                        },
                      ])
                    );
                    addFilePreview();
                  }}
                />
              </Col>
            ))}
          </Row>
        );
      case 3: {
        const f1 = files[0];
        const f2 = files[1];
        const f3 = files[2];
        return (
          <Row>
            <Col span={24}>
              <Attachments
                type={f1.type}
                url={f1.url}
                thumbnail={f1.thumbnail}
                className="h-[278px] max-w-[30vw] w-[30vw]"
                onClick={() => {
                  dispatch(
                    updatePreviewMultiple([
                      {
                        key: "docs",
                        value: files,
                      },
                      {
                        key: "visible",
                        value: true,
                      },
                      {
                        key: "from",
                        value: "conversation",
                      },
                      {
                        key: "currentIndex",
                        value: 0,
                      },
                    ])
                  );
                  addFilePreview();
                }}
              />
            </Col>
            <Col span={12}>
              <Attachments
                type={f2.type}
                url={f2.url}
                thumbnail={f2.thumbnail}
                className="h-[178px] max-w-[15vw] w-[15vw] max-h-[178px]"
                onClick={() => {
                  dispatch(
                    updatePreviewMultiple([
                      {
                        key: "docs",
                        value: files,
                      },
                      {
                        key: "visible",
                        value: true,
                      },
                      {
                        key: "from",
                        value: "conversation",
                      },
                      {
                        key: "currentIndex",
                        value: 1,
                      },
                    ])
                  );
                  addFilePreview();
                }}
              />
            </Col>
            <Col span={12}>
              <Attachments
                type={f3.type}
                url={f3.url}
                thumbnail={f3.thumbnail}
                className="h-[178px] max-w-[15vw] w-[15vw] max-h-[178px]"
                onClick={() => {
                  dispatch(
                    updatePreviewMultiple([
                      {
                        key: "docs",
                        value: files,
                      },
                      {
                        key: "visible",
                        value: true,
                      },
                      {
                        key: "from",
                        value: "conversation",
                      },
                      {
                        key: "currentIndex",
                        value: 2,
                      },
                    ])
                  );
                  addFilePreview();
                }}
              />
            </Col>
          </Row>
        );
      }
      default:
        <></>;
    }
  };
  return <>{renderAlbum(files.length)}</>;
};

export default Album;
