const getUsersName = (user) => {
    const firstName = user.firstName || user.firstname;
    const lastName = user.lastName || user.lastname;
    if (firstName && lastName) return `${firstName} ${lastName}`;
    else if (firstName) return firstName;
    else if (lastName) return lastName;
    else if (user.email) return user.email;
    else return "User name not found";
  };

export default getUsersName