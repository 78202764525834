import React, { useState } from "react";
import { Row, Col } from "antd";
import CustomBadge from "../../../Core/Common/CustomBadge";
import { useAppSelector } from "../../../Core/redux/hooks";
import { useLocation } from "react-router-dom";

const ComponentsDrawer = ({ bgColor = "bg-white" }: { bgColor?: string }) => {
  const componentsData = [
    {
      id: 1,
      title: "Documents",
    },
    {
      id: 2,
      title: "Infrastructure",
    },
    {
      id: 3,
      title: "Design",
    },
    {
      id: 4,
      title: "Development",
    },
    {
      id: 5,
      title: "Database",
    },
    {
      id: 6,
      title: "Deployment",
    },
    {
      id: 7,
      title: "Testing",
    },
  ];

  const {
    projectName,
    projectImage,
    projectColor,

    clientName,
    clientImage,
    internalProject,
    clientColor,

    projectDuration,
    clientLocation,
    // step 4
    softwareType,
    // step 5
    selectedPhase,
    phaseDetails,

    components,
  } = useAppSelector((state) => state.projectOnboarding);

  const { phases } = useAppSelector((state) => state.schedule);

  const location = useLocation();
  const newPhaseDetails = location.pathname.includes("overview")
    ? phases
    : phaseDetails.filter((p) => selectedPhase.includes(p.id));

  const getComponents = (c) =>
    location.pathname.includes("overview") ? JSON.parse(c) : c;
  console.log(location, "locations");
  return (
    <div className="bg-white p-6 rounded-2xl shadow-box overflow-x-scroll mt-6 h-[220px] flex gap-x-3">
      {newPhaseDetails.map((phase) => (
        <div
          className="min-w-min h-[166px] border-[1px] border-gray-200 rounded-xl p-3 overflow-x-scroll"
          key={phase.id}
        >
          <div className="flex justify-between">
            <p className="mon-500-12 text-gray-900">
              {phase.phaseName || phase.phases}
            </p>
          </div>
          <div className=" flex justify-start gap-x-[24px] mt-3">
            {phase.components &&
              getComponents(phase.components).map(({ id, title, content }) => (
                <div className="gap-y-2" key={id}>
                  <p className="mon-500-10 text-gray-500">{title}</p>
                  {content.map((c) => (
                    <div
                      key={c.id}
                      // onClick={() => handelRemove(phase.id, id, c.id)}
                      className="cursor-pointer w-24"
                    >
                      <CustomBadge
                        text={c.heading}
                        color="#6938EF"
                        className="mt-2"
                      />
                    </div>
                  ))}
                </div>
              ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default ComponentsDrawer;
