import React, { useState } from "react";
import { Col, Drawer, Spin, Tabs } from "antd";

import { useAppDispatch, useAppSelector } from "../../Core/redux/hooks";

import CustomInputWithEdit from "../../Core/Common/CustomInputWithEdit";
import { useEffect } from "react";
import CustomTodo from "../../Core/Common/CustomTodo";

type MyTaskDrawerProps = {
  visible: boolean;
  onClose: () => void;
  todoLoading: boolean;
  todoInput: string;
  setTodoInput: (e) => void;
  handleSaveTodo: (e) => void;
  todoList: any[];
  handleUpdateTodo: (id, e, title) => void;
  handleDeleteTodo: (e) => void;
  handleClearAll: () => void;
};

const MyTaskDrawer = ({
  visible,
  onClose,
  todoInput,
  setTodoInput,
  todoLoading,
  handleSaveTodo,
  todoList,
  handleUpdateTodo,
  handleDeleteTodo,
  handleClearAll,
}: MyTaskDrawerProps) => {
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.userDetails);

  return (
    <Drawer
      placement="right"
      open={visible}
      closable={false}
      className="custom-drawer"
      width="33.875rem"
      onClose={onClose}
      destroyOnClose
    >
      <div>
        <button onClick={() => onClose()}>
          <img src="/images/icons/chevron-right-double.svg" alt="close" />
        </button>
        <div className="flex items-center justify-between mt-3">
          <div className="mon-500-16 text-gray-900">My Notes</div>
          <div className="flex items-center">
            <button
              onClick={handleClearAll}
              className="mon-500-14 text-gray-500 cursor-pointer mr-4"
            >
              Clear all
            </button>
            {/* <img src="/images/icons/expand.svg" className="cursor-pointer" /> */}
          </div>
        </div>
        {/* <Spin spinning={todoLoading}> */}
        <div className="mb-3">
          <CustomInputWithEdit
            placeholder="Jot down your notes..."
            value={todoInput}
            onChange={(e) => {
              setTodoInput(e.target.value);
            }}
            onPressEnter={(e) => {
              handleSaveTodo(e);
            }}
            suffix={
              todoInput && (
                <div className="flex">
                  <div>
                    <img
                      src="/images/icons/input_save.svg"
                      className="mx-2 cursor-pointer"
                      onClick={handleSaveTodo}
                    />
                  </div>
                  <div>
                    <img
                      src="/images/icons/input_clear.svg"
                      className="mx-2 cursor-pointer"
                      onClick={() => setTodoInput(null)}
                    />
                  </div>
                </div>
              )
            }
          />
        </div>
        <CustomTodo
          tasks={todoList}
          userId={user.id}
          updateTodo={handleUpdateTodo}
          deleteTodo={handleDeleteTodo}
        />
        {/* </Spin> */}
      </div>
    </Drawer>
  );
};

export default MyTaskDrawer;
