import React, { useState } from "react";
import CustomModal from "../../Core/Common/CustomModal";
import CustomButton from "../../Core/Common/CustomButton";
import CustomSearch from "../../Core/Common/CustomSearch";
import { useAppDispatch, useAppSelector } from "../../Core/redux/hooks";
import { client } from "../../Core/utils/axiosClient";
import { useParams } from "react-router-dom";
import {
  getAllFiles,
  getAllFolders,
  getAllLinks,
} from "../../Core/redux/api/vaultAPI";

type MoveToFolderModalProps = {
  visible: boolean;
  onCancel: () => void;
};

const MoveToFolderModal = ({ visible, onCancel }: MoveToFolderModalProps) => {
  const dispatch = useAppDispatch();
  const { folders, moveToFolderModal } = useAppSelector((state) => state.vault);
  const { projectId, "*": splat } = useParams();
  const [search, setSearch] = useState("");
  const [moveToFolderId, setMoveToFolderId] = useState("");

  const ownedByMeFolders = folders.filter(
    (folder) =>
      folder.isOwner === 1 && folder.folderName.toLowerCase().includes(search)
  );

  const sharedWithYouFolders = folders.filter(
    (folder) =>
      folder.isOwner !== 1 && folder.folderName.toLowerCase().includes(search)
  );

  const DocumentsAfterMove = () => {
    const query: { projectId: string; folderId?: string } = { projectId };

    if (splat && splat.length > 0) {
      const folderId = splat.split("-");
      query.folderId = folderId[folderId.length - 1];
    }

    //setQueryObject({ ...queryObject, folderId: query });
    return query;
  };

  const onsubmit = async () => {
    const payload = {
      itemId: moveToFolderModal.docId,
      itemType: moveToFolderModal.docType,
      folderId: moveToFolderId,
    };

    const response = await client
      .put(`/repository/move-item-to-folder`, payload)
      .then(() => onCancel());

    const queryObject = DocumentsAfterMove();

    await dispatch(getAllFolders(queryObject));
    await dispatch(getAllFiles(queryObject));
    await dispatch(getAllLinks(queryObject));
  };

  return (
    <CustomModal
      title={<p className="mon-500-12 text-gray-700">Vault</p>}
      width={"384px"}
      visible={visible}
      body={
        <div className="max-h-[30vh] overflow-y-scroll">
          <CustomSearch
            className=" border-2 border-gray-300 mx-4 my-4"
            placeholder="Search folder"
            search={search}
            setSearch={(e) => setSearch(e.toLowerCase())}
          />
          <div className="max-w-[492px]  my-4">
            <div>
              <div className="mon-400-12 text-gray-500 px-4 pt-3">
                Owned by you
              </div>
              <>
                {ownedByMeFolders.map((item, index) => (
                  <div
                    className={`flex items-center px-4 pt-3 pb-[10px] hover:bg-main-background ${
                      ownedByMeFolders.length === index + 1
                        ? "border-b-[1px]"
                        : ""
                    } ${
                      moveToFolderId === item.id ? "bg-main-background" : ""
                    }`}
                    key={item.id}
                    onClick={() => setMoveToFolderId(item.id)}
                  >
                    <img
                      src={
                        item.folderName.includes(" Space")
                          ? "/images/icons/my-space-folder.svg"
                          : "/images/icons/folder-fill-vault.svg"
                      }
                      className={`${
                        item.folderName.includes(" Space")
                          ? "w-4 h-4"
                          : "w-5 h-5"
                      }`}
                    />
                    <p className="mon-400-12 text-gray-700 capitalize ml-2">
                      {item.folderName}
                    </p>
                  </div>
                ))}
              </>
              <div className="mon-400-12 text-gray-500 px-4 pt-3">
                Shared with you
              </div>
              <>
                {sharedWithYouFolders.map((item, index) => (
                  <div
                    className="flex items-center px-4 py-3 hover:bg-main-background"
                    key={item.id}
                    onClick={() => setMoveToFolderId(item.id)}
                  >
                    <img
                      src="/images/icons/folder-fill-vault.svg"
                      className="w-5 h-5"
                    />
                    <p className="mon-400-12 text-gray-700 capitalize ml-2">
                      {item.folderName}
                    </p>
                  </div>
                ))}
              </>
            </div>
          </div>
        </div>
      }
      footer={
        <div className="flex justify-between py-2.5 px-3">
          <CustomButton
            text="Cancel"
            onClick={() => onCancel()}
            className="border border-gray-200 text-gray-700 bg-white mon-500-16 "
            height="44px"
            width="49%"
          />
          <CustomButton
            text="Move Here"
            onClick={() => onsubmit()}
            className="mon-500-16 text-white bg-primary-600"
            height="44px"
            width="49%"
          />
        </div>
      }
      onCancel={() => {
        onCancel();
      }}
    />
  );
};

export default MoveToFolderModal;
