import React, { useEffect, useState } from "react";
import CustomModal from "../../Core/Common/CustomModal";
import CustomButton from "../../Core/Common/CustomButton";
import { Checkbox, Spin } from "antd";
import CustomFrostyMessage from "../../Core/Common/CustomFrostyMessage";
import { client } from "../../Core/utils/axiosClient";
import { useAppSelector } from "../../Core/redux/hooks";

const DownloadModal = ({ visible, id, onCancel }) => {
  const [folderState, setFolderState] = useState({
    files: [],
    links: [],
  });

  const [loading, setLoading] = useState(false);

  const [fileCheckedList, setFileCheckedList] = useState([]);
  const [linkCheckedList, setLinkCheckedList] = useState([]);

  const getFilesLinksFromFolderId = (folderId) => {
    client
      .get("/repository/get-file-links-from-folder-id", {
        params: { folderId: id },
      })
      .then((res) => {
        console.log(res.data.result);

        const files = res.data.result.filter(({ size }) => !!size);
        const links = res.data.result.filter(({ size }) => !size);

        setFolderState({ files, links });
        setFileCheckedList(files.map(({ value }) => value));
        setLinkCheckedList(links.map(({ value }) => value));
      });
  };

  useEffect(() => {
    console.log("ID: ", id);
    getFilesLinksFromFolderId(id);
  }, []);

  const onChange = (checkedValues, type) => {
    switch (type) {
      case "files":
        setFileCheckedList(checkedValues);
        break;
      case "links":
        setLinkCheckedList(checkedValues);
        break;
    }
  };

  const b64toBlob = function (b64Data, contentType, sliceSize = 512) {
    contentType = contentType || "";
    sliceSize = sliceSize || 512;

    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  };

  function download(filename, text) {
    const pom = document.createElement("a");
    pom.setAttribute(
      "href",
      "data:text/plain;charset=utf-8," + encodeURIComponent(text)
    );
    pom.setAttribute("download", filename);

    if (document.createEvent) {
      const event = document.createEvent("MouseEvents");
      event.initEvent("click", true, true);
      pom.dispatchEvent(event);
    } else {
      pom.click();
    }
  }

  const onDownload = () => {
    setLoading(true);
    client
      .post(
        "/repository/download-multiple-files",
        {
          folderId: id,
          files: fileCheckedList,
        },
        {
          responseType: "blob",
        }
      )
      // .then((res) => res.data.blob())
      // .then((blob) => saveAs(blob, "Auto Photos.zip")) // saveAs is a function from the file-saver package.
      .then((res) => {
        const headerLine = res.headers["content-disposition"];
        const filename = headerLine.replace(/[\w; ]+filename=/g, "").slice(1);
        const Name = filename.substring(0, filename.length - 1);
        console.log(Name, "responding");
        // Now 'res.data' is Blob, not a string
        // https://stackoverflow.com/a/74482074
        const file = window.URL.createObjectURL(res.data);
        const link = document.createElement("a");
        link.href = file;
        link.download = `${Name}.zip`;
        link.click();
        // window.location.assign(file);
      })
      .catch((err) => {
        console.log(err.message);
      })
      .finally(() => {
        setLoading(false);
      });

    // fetch(`your url`)
    //   .then((res) => res.blob())
    //   .then((blob) => saveAs(blob, "Auto Photos.zip")) // saveAs is a function from the file-saver package.
    //   .catch((err) => {
    //     console.log(err.message);
    //   });
  };

  const { folders } = useAppSelector((state) => state.vault);
  const folderToDownload = folders.find((item) => item.id === id);

  return (
    <CustomModal
      title={
        <div className="flex gap-x-1">
          <span className="mon-500-12 text-black">
            {folderToDownload?.folderName}
          </span>
        </div>
      }
      width={"524px"}
      visible={visible}
      body={
        // <Spin spinning={loading}>
        <div className="max-h-[48.189386056191466vh] overflow-y-scroll">
          <div className="p-4">
            <CustomFrostyMessage shadow={false} close={false}>
              <p className="mon-500-12 text-primary-600 ">
                Select the items you want to download. We&#39;ll export your
                links <br /> in .csv format
              </p>
            </CustomFrostyMessage>

            <p className="mon-400-12 text-gray-700 pt-4">Files</p>

            <Checkbox.Group
              onChange={(values) => onChange(values, "files")}
              value={fileCheckedList}
            >
              {folderState.files.map(({ value, label }) => (
                <div className="flex items-center mt-4" key={value}>
                  <Checkbox value={value} />
                  <p className="mon-400-12 text-gray-700 pl-3 ">{label}</p>
                </div>
              ))}
            </Checkbox.Group>

            <p className="mon-400-12 text-gray-700 pt-4">Links</p>
            <Checkbox.Group
              onChange={(values) => onChange(values, "links")}
              value={linkCheckedList}
            >
              {folderState.links.map(({ value, label }) => (
                <div className="flex items-center mt-4" key={value}>
                  <Checkbox value={value} />
                  <p className="mon-400-12 text-gray-700 pl-3 ">{label}</p>
                </div>
              ))}
            </Checkbox.Group>
          </div>
        </div>
        // </Spin>
      }
      footer={
        <div className="flex justify-end py-4 gap-x-3 px-3 shadow-upward">
          <CustomButton
            text="Cancel"
            onClick={onCancel}
            className="border border-gray-200 text-gray-700 bg-white mon-500-14"
            height="40px"
            width="48%"
          />
          <CustomButton
            text="Download Selected"
            onClick={onDownload}
            className="mon-500-14 px-2 text-white bg-primary-600 "
            height="40px"
            width="48%"
          />
        </div>
      }
      onCancel={onCancel}
    />
  );
};

export default DownloadModal;
