import { Checkbox, Radio, Space } from "antd";
import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { updateTodo } from "../redux/api/dashboardAPI";
import { updateProjectOnboarding } from "../redux/slices/projectOnboarding.slice";
import { AppDispatch } from "../redux/store";
import CustomOnboardingInput from "./CustomOnboardingInput";

const CustomTodo = ({ tasks, userId, updateTodo, deleteTodo }) => {
  const dispatch = useDispatch<AppDispatch>();
  const [isEditing, setIsEditing] = useState({
    value: false,
    id: 0,
    text: " ",
  });
  const [newTitle, setNewTitle] = useState(" ");

  return (
    <div className="h-[205px]">
      <div className="h-[205px] bg-white pb-4 rounded-xl overflow-y-auto py-1.5">
        <Checkbox.Group
          value={tasks.filter(({ status }) => status === 1).map(({ id }) => id)}
          className="w-full "
        >
          {tasks.map(({ id, title, status }, index) =>
            isEditing.value && isEditing.id == id ? (
              <div
                className="custom-todo-item  border-t border-gray-100 py-1.5 flex items-center justify-between px-4  w-full"
                style={{
                  borderBottom: "1px",
                  borderBottomColor: "blue",
                }}
                key={id}
              >
                <input
                  // name={name}
                  className="outline-none bg-transparent w-full ml-6 "
                  autoFocus
                  // placeholder={placeholder}
                  value={newTitle || title}
                  onChange={(e) => {
                    setIsEditing({
                      value: true,
                      id: id,
                      text: e.target.value,
                    });
                    setNewTitle(e.target.value);
                  }}
                />
                <div className="hidden custom-todo-flex items-center ">
                  <div
                    className="custom-todo-delete  cursor-pointer "
                    onClick={() => null}
                  >
                    <img
                      src="/images/icons/check.svg"
                      className="mx-2 cursor-pointer h-4"
                      onClick={(e) => {
                        setIsEditing({
                          value: false,
                          id,
                          text: isEditing.text,
                        });
                        updateTodo(id, status, isEditing.text);
                      }}
                    />
                  </div>

                  <div
                    className="custom-todo-delete  cursor-pointer"
                    onClick={() => {
                      setIsEditing({ value: false, id: 0, text: " " });
                    }}
                  >
                    <img
                      src="/images/icons/input_clear.svg "
                      className="mx-2 cursor-pointer "
                      onClick={() => null}
                    />
                  </div>
                </div>
              </div>
            ) : (
              <div
                className={`custom-todo-item hover:bg-main-background flex items-center justify-between px-4  w-full ${
                  index !== 0 ? "border-t border-gray-100 py-1.5 " : "py-1.5"
                }`}
                key={id}
              >
                <Checkbox
                  value={id}
                  onClick={() => {
                    if (status == 0) {
                      status = 1;
                    } else {
                      status = 0;
                    }
                    updateTodo(id, status);
                  }}
                >
                  <span
                    className={`mon-500-12 ${
                      status === 1
                        ? "text-gray-400 line-through"
                        : "text-gray-700"
                    }`}
                  >
                    {title}
                  </span>
                </Checkbox>
                <div className="hidden custom-todo-flex ">
                  <div
                    className="custom-todo-delete  cursor-pointer "
                    onClick={() =>
                      setIsEditing({ value: true, id: id, text: title })
                    }
                  >
                    <img
                      src="/images/icons/edit-2-gray.svg"
                      className="mx-2 cursor-pointer"
                      onClick={() => setNewTitle(title)}
                    />
                  </div>

                  <div
                    className="custom-todo-delete  cursor-pointer"
                    onClick={() => deleteTodo(id)}
                  >
                    <img
                      src="/images/icons/input_clear.svg"
                      className="mx-2 cursor-pointer"
                      onClick={() => null}
                    />
                  </div>
                </div>
              </div>
            )
          )}
        </Checkbox.Group>
      </div>
    </div>
  );
};

export default CustomTodo;
