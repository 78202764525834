import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { useAppSelector } from "../../../Core/redux/hooks";
import dayjs from "dayjs";
import { date } from "yup";
import { getProjectStatus } from "./ProjectStatus";

ChartJS.register(ArcElement, Tooltip, Legend);

const ProjectOverview: React.FC = () => {
  const { projectDetails } = useAppSelector((state) => state.overview);
  //const { sprints } = useAppSelector((state) => state.schedule);
  //console.log(sprints, "sprints");
  //console.log(sprints.goals.length, "goals");

  const projectStatus = getProjectStatus(
    projectDetails.startDate,
    projectDetails.endDate,
    new Date(),
    +projectDetails.progress
  );

  // const projectStatus = getProjectStatus(
  //   "2023-01-23 12:13:22.745",
  //   "2023-03-31 12:13:22.745",
  //   new Date(),
  //   23.33
  // );

  const data = {
    datasets: [
      {
        data: [
          projectDetails.progress,
          100 - parseInt(projectDetails.progress),
        ],
        backgroundColor: [projectStatus.color, "#EDF3F9"],
        borderColor: [projectStatus.color, "#F9FBFE"],
        borderWidth: 4,
      },
    ],
  };

  const centerText = {
    id: "centerText",
    beforeDatasetsDraw: function (chart, args, options) {
      const {
        ctx,
        chartArea: { left, top, right, bottom, width, height },
      } = chart;
      ctx.save();
      ctx.font = "bold 15px Poppins";
      ctx.fillStyle = "#12B76A";
      ctx.fillText("78%", 100, 100);
    },
  };

  // Disabling all onHover actions
  const options = {
    spacing: 2,
    cutout: "65%",
    borderWidth: 0,
    borderRadius: 3,
    maintainAspectRatio: false,
    events: [],
    plugins: {
      ...centerText,
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
    },
  };

  return (
    <div className="shadow-box pl-7 pr-7  w-full h-full bg-white rounded-2xl max-h-[425px]">
      <div className="justify-between pt-6 mr-7">
        <div className=" flex text-base font-medium text-black">
          Project Progress
        </div>
      </div>

      <div
        className="w-52 h-52 mt-6 pb-7 mx-auto flex items-center justify-center"
        id="centerText"
      >
        <Doughnut
          data={data as any}
          width={192}
          height={192}
          options={options}

          // plugins={plugins}
        />
        <div className="absolute flex justify-center flex-col items-center">
          <p
            className="text-primary-600 text-[24px] font-[700] font-[Inter]"
            style={{
              color: projectStatus.color,
            }}
          >
            {Math.round(+projectDetails.progress)}
            <span className="text-[12px]">%</span>
          </p>
          <div
            className="text-xs text-primary-600 font-[Inter] uppercase"
            style={{
              color: projectStatus.color,
            }}
          >
            {<>{projectStatus.text}</>}
          </div>
        </div>
      </div>
      <>{projectStatus.render}</>
    </div>
  );
};

export default ProjectOverview;
