import React, { useRef, useState, useEffect } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import ImageGallery from "react-image-gallery";
import { Layout, Tooltip } from "antd";
import MenuDropdown from "./Components/MenuDropdown";
import ShareAccessModal from "./Components/ShareAccessModal";
import ConversationsDrawer from "./Components/ConversationsDrawer";
import { useAppDispatch, useAppSelector } from "../../Core/redux/hooks";
import { resetToInitial } from "../../Core/redux/slices/preview.slice";
import CustomMediaSlider from "../../Core/Common/CustomMediaSlider";
import { throttle } from "lodash";
import { Carousel } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import ReactPanZoom from "../../Core/Common/CustomReactImageZoom";
import { downloadFiles } from "../../Core/utils";
import CustomButton from "../../Core/Common/CustomButton";
import {
  resetConversations,
  updateConversations,
  updateConversationsMultiple,
} from "../../Core/redux/slices/conversations.slice";
import ShareMessageModal from "./Common/ShareMessageModal";
import DeleteFileModal from "./Common/DeleteFileModal";
import {
  resetSchedule,
  updateSchedule,
} from "../../Core/redux/slices/schedule.slice";

// function ZoomableImage({ src, currentID }) {
//   const imageRef = useRef(null);
//   const containerRef = useRef(null);
//   const [zoomLevel, setZoomLevel] = useState(1);
//   const [offset, setOffset] = useState({ x: 0, y: 0 });

//   const [dragData, setDragData] = useState({
//     dx: 0,
//     dy: 0,
//     x: 0,
//     y: 0,
//   });

//   let rafId;

//   const handleMouseMove = (event) => {
//     // Make sure the left mouse button is being pressed
//     console.log("clampedX2", event);
//     if (event.button !== 0) return;

//     // Calculate the maximum X and Y values that the image can be offset to
//     const containerRect = containerRef.current.getBoundingClientRect();
//     const imageRect = imageRef.current.getBoundingClientRect();
//     const maxX = (imageRect.width * zoomLevel - containerRect.width) / 2;
//     const maxY = (imageRect.height * zoomLevel - containerRect.height) / 2;

//     // Update the image offset based on the mouse movement and the current drag position
//     setOffset((prevOffset) => {
//       const newX = prevOffset.x + (event.pageX - dragData.x);
//       const newY = prevOffset.y + (event.pageY - dragData.y);
//       // Clamp the new offset values so that the image stays within the container bounds
//       const clampedX = Math.min(Math.max(newX, -maxX), maxX);
//       const clampedY = Math.min(Math.max(newY, -maxY), maxY);
//       console.log(clampedX, clampedY, "clampedX");
//       return { x: clampedX, y: clampedY };
//     });

//     // Update the current drag position
//     setDragData((prevDragData) => ({
//       ...prevDragData,
//       x: event.pageX,
//       y: event.pageY,
//     }));

//     event.stopPropagation();
//     event.preventDefault();

//     containerRef.current.addEventListener("mousedown", (event) => {
//       rafId = requestAnimationFrame(() => {
//         imageRef.current.style.transition = "none";
//       });
//     });
//   };

//   const handleMouseUp = () => {
//     console.log("movinnnnnn2");
//     document.removeEventListener("mousemove", handleMouseMove);
//     document.removeEventListener("mouseup", handleMouseUp);
//     setDragData({
//       x: 0,
//       y: 0,
//       dx: 0,
//       dy: 0,
//     });
//     cancelAnimationFrame(rafId);
//   };

//   // useEffect(() => {

//   // }, [offset, zoomLevel]);

//   const handleZoomIn = () => {
//     setZoomLevel((prevZoomLevel) => prevZoomLevel + 0.1);
//     console.log(zoomLevel, "zoom");
//   };

//   const handleZoomOut = () => {
//     setZoomLevel((prevZoomLevel) => Math.max(prevZoomLevel - 0.1, 1));
//   };

//   return (
//     <div className="flex justify-center items-center flex-col">
//       <div
//         ref={containerRef}
//         style={{
//           overflow: "hidden",
//           cursor: "grab",
//           // position: "absolute",
//           inset: "0",
//           transition: "transform .3s cubic-bezier(.215,.61,.355,1) 0s",
//           display: "flex",
//           justifyContent: "center",
//           alignItems: "center",
//         }}
//         className="h-[70vh] max-h-[70vh] w-[70vw] max-w-[70vw] "
//       >
//         <img
//           ref={imageRef}
//           src={src}
//           style={{
//             transform: `scale(${zoomLevel}) translate3d(${offset.x}px, ${offset.y}px, 0)`,
//             transition: "transform 0.2s ease-out",
//             // pointerEvents: "none",
//           }}
//           onMouseMove={handleMouseMove}
//           onMouseUp={handleMouseUp}
//           // onDrag={handleMouseMove}
//           // onDragEnd={handleMouseUp}
//         />
//       </div>

//       <div className="bg-primary-900 w-60 h-12 mt-14 rounded-xl flex items-center justify-center">
//         <div className="mon-400-12 text-gray-100 pl-4">Page 1 of 4</div>
//         <img
//           src="/images/icons/zoom-out.svg"
//           className="w-5 h-5 ml-3 cursor-pointer"
//           onClick={handleZoomOut}
//         />
//         <img src="/images/icons/line.svg" className="w-5 h-5 ml-2" />
//         <img
//           src="/images/icons/zoom-in.svg"
//           className="w-5 h-5 ml-2 cursor-pointer"
//           onClick={handleZoomIn}
//         />
//         <div className="mon-400-12 text-gray-100 ml-3 mr-3">100%</div>
//       </div>
//     </div>
//   );
// }

const FilePreviewScreenNew = (props) => {
  const { docs, currentIndex } = useAppSelector((state) => state.preview);
  const [openShareAccessModal, setOpenShareAccessModal] = useState(false);
  const [conversationDrawer, setConversationDrawer] = useState(false);
  const [currentSlideIndex, setCurrentSlideIndex] = useState(currentIndex);
  const [deleteFileModal, setDeleteFileModal] = useState(false);
  const { selectedBug, selectedTask } = useAppSelector(
    (state) => state.schedule
  );
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const location = useLocation();

  const [searchParams, setSearchParams] = useSearchParams();

  const removeFilePreview = () => {
    const updatedSearchParams = new URLSearchParams(searchParams.toString());
    updatedSearchParams.delete("filePreview");
    setSearchParams(updatedSearchParams.toString());
  };
  // ...

  const refImg = useRef(null);

  console.log(location.pathname, "pathname");
  const { shareInMessageModal } = useAppSelector(
    (state) => state.conversations
  );

  const isVault =
    location.pathname !== "/conversations" &&
    location.pathname.includes("vault");

  const dropDown = [
    {
      id: 1,
      image: "/images/icons/info-circle.svg",
      text: "View details",
      textColor: "text-gray-700",
    },
    // {
    //   id: 2,
    //   image: "/images/icons/open-window.svg",
    //   text: "Open in new window",
    //   borderBottom: true,
    //   textColor: "text-gray-700",
    // },
    {
      id: 3,
      image: "/images/icons/message-dots-circle-gray.svg",
      text: "Open thread",
      textColor: "text-gray-700",
      onclick: () => setConversationDrawer(true),
    },
    {
      id: 4,
      image: "/images/icons/add-user-gray.svg",
      text: "Share access",
      textColor: "text-gray-700",
      onclick: () => setOpenShareAccessModal(true),
    },
    {
      id: 5,
      image: "/images/icons/share-gray.svg",
      text: "Share in message",
      textColor: "text-gray-700",
      onclick: () =>
        dispatch(
          updateConversations({
            key: "shareInMessageModal",
            value: {
              type: "file",
              visible: true,
              url: "",
              file: [
                {
                  ...docs[currentSlideIndex],
                },
              ],
            },
          })
        ),
    },
    {
      id: 6,
      image: "/images/icons/download-icon.svg",
      text: "Download",
      textColor: "text-gray-700",
      onclick: () => {
        downloadFiles([docs[currentSlideIndex].url]);
      },
      borderBottom: true,
    },
    {
      id: 7,
      image: "/images/icons/trash.svg",
      text: "Delete",
      textColor: "text-error-600",
      onclick: () => {
        setDeleteFileModal(true);
        dispatch(
          updateConversationsMultiple([
            {
              key: "deleteFileInMessage",
              value: docs[currentSlideIndex],
            },
          ])
        );
      },
    },
  ];

  useEffect(() => {
    if (refImg.current) {
      refImg.current.goTo(currentSlideIndex);
    }
  }, []);

  const NextArrow = (props) => {
    const { className, style, onClick, currentSlide } = props;

    setCurrentSlideIndex(currentSlide);
    return (
      <div
        className={className}
        style={{
          ...style,
          color: "black",
          fontSize: "15px",
          lineHeight: "1.5715",
        }}
        onClick={onClick}
      >
        <img src="/images/icons/arrow-right.svg" />
      </div>
    );
  };

  const PrevArrow = (props) => {
    const { className, style, onClick, currentSlide } = props;
    setCurrentSlideIndex(currentSlide);
    return (
      <div
        className={className}
        style={{
          ...style,
          color: "black",
          fontSize: "15px",
          lineHeight: "1.5715",
        }}
        onClick={onClick}
      >
        <img
          src="/images/icons/arrow-right.svg"
          style={{ transform: "rotate(180deg)" }}
        />
      </div>
    );
  };

  const getFile = (file) => {
    const type = file.mimeType || file.type;
    switch (type) {
      case "image/png":
      case "image/jpeg":
      case "image/gif":
      case "image/svg+xml":
      case "image/bmp":
      case "image/webp":
      case "image/tiff":
      case "image/x-icon":
        return (
          <ReactPanZoom
            image={file.url}
            currentSlideIndex={currentSlideIndex}
          />
        );
      case "video/mp4":
      case "video/webm":
      case "video/ogg":
      case "video/quicktime":
      case "video/x-msvideo":
      case "video/x-flv":
      case "video/x-ms-wmv":
      case "video/x-matroska":
        return (
          <div className="h-[90vh] w-[90vw] m-auto mt-2">
            <video
              muted
              // onCanPlayThrough={(e) => {
              //   const video = e.target;
              //   setTimeout(() => {
              //     video?.pause();
              //   }, 1000);
              // }}
              autoPlay={false}
              controls
              // poster="/images/thumbnails/video.svg"
              //poster={file.thumbnail}
              preload="metadata"
              className="h-full w-full"
            >
              <source src={`${file.url}#t=0.1`}></source>
            </video>
          </div>
        );
      default:
        return (
          <div className="h-screen w-full flex justify-center items-center flex-col">
            <img src="/images/noFiles.svg" />
            <p className="mon-500-12 text-gray-500 mt-3">
              No preview available
            </p>
            <div className="mt-5">
              <CustomButton
                text="Download File"
                icon="/images/icons/download-01.svg"
                className=""
                onClick={() => downloadFiles([docs[currentSlideIndex].url])}
              />
            </div>
          </div>
        );
    }
  };

  const getPreview = () => {
    if (docs.length > 1) {
      return (
        <Carousel
          dots={false}
          arrows
          autoplay={false}
          nextArrow={<NextArrow />}
          prevArrow={<PrevArrow />}
          ref={(ref) => {
            console.log(ref, "refImg?.current?.getCurrentIndex()");
            refImg.current = ref;
          }}
        >
          {docs.map((file, i) => (
            <React.Fragment key={i}>{getFile(file)}</React.Fragment>
          ))}
        </Carousel>
      );
    } else {
      return <>{getFile(docs[0])}</>;
    }
  };

  const getDropDownMenu = () => {
    const newDrop = dropDown.filter((item) => ![1, 2, 3, 4].includes(item.id));
    return newDrop;
  };

  return (
    <>
      <div
        className="bg-main-background max-h-[100vh] min-h-[100vh] min-w-[100vw] max-w-[100vw] fixed top-0 bottom-0 left-0 right-0 "
        style={{
          zIndex: "999",
        }}
      >
        <Layout.Header
          style={{ width: "100%", maxHeight: "56px", pointerEvents: "auto" }}
        >
          <div className="max-h-14 w-full flex justify-between border-b-[1px] border-[#DDE5ED]">
            <div className="flex items-center ml-4">
              <img
                src="/images/icons/chevron-left.svg"
                className="w-5 h-5 cursor-pointer"
                onClick={() => {
                  removeFilePreview();
                  dispatch(resetToInitial({}));
                  //if there is a selected bug or task on clicking back cta make the bug/task modal visible
                  if (selectedBug) {
                    dispatch(
                      updateSchedule({ key: "newBugModal", value: true })
                    );
                    dispatch(
                      updateSchedule({
                        key: "selectedBug",
                        value: selectedBug,
                      })
                    );
                  } else if (selectedTask) {
                    dispatch(
                      updateSchedule({
                        key: "newTaskModal",
                        value: {
                          visible: true,
                          isNewScope: false,
                          scopeDescription: "",
                        },
                      })
                    );
                    dispatch(
                      updateSchedule({
                        key: "selectedTask",
                        value: selectedTask,
                      })
                    );
                  }
                }}
              />
              <img src="/images/icons/pdf-icon.svg" className="w-5 h-5 ml-3" />
              <p className="mon-500-12 text-gray-700 pl-2">
                {docs[currentSlideIndex].title || docs[currentSlideIndex].name}
              </p>
            </div>
            <div className="flex items-center mr-4">
              {isVault && (
                <Tooltip title="Share access">
                  <button
                    onClick={() => setOpenShareAccessModal(true)}
                    className="w-5 h-5 mr-4 cursor-pointer"
                  >
                    <img src="/images/icons/add-user-gray.svg" />
                  </button>
                </Tooltip>
              )}

              <Tooltip title="Download">
                <button
                  onClick={() => downloadFiles([docs[currentSlideIndex].url])}
                  className="mr-4"
                >
                  <img
                    src="/images/icons/download-icon.svg"
                    className="w-5 h-5  cursor-pointer"
                  />
                </button>
              </Tooltip>
              {isVault && (
                <Tooltip title="Open Thread">
                  <button
                    onClick={() => setConversationDrawer(true)}
                    className="w-5 h-5 mr-4 cursor-pointer"
                  >
                    <img src="/images/icons/message-dots-circle-light-gray.svg" />
                  </button>
                </Tooltip>
              )}

              <MenuDropdown
                elements={getDropDownMenu()}
                customFunc={undefined}
              />
            </div>
          </div>
        </Layout.Header>

        {/* <img
              src="/images/icons/file-preview.png"
              className="w-full h-full"
            /> */}
        <div className="bg-main-background" style={{ maxWidth: "calc(100vw)" }}>
          {getPreview()}
        </div>

        <ShareAccessModal
          visible={openShareAccessModal}
          onCancel={() => setOpenShareAccessModal(false)}
        />
        {conversationDrawer && (
          <ConversationsDrawer
            visible={conversationDrawer}
            onCancel={() => setConversationDrawer(false)}
          />
        )}
        {shareInMessageModal?.visible && (
          <ShareMessageModal
            visible={shareInMessageModal?.visible}
            onClose={() =>
              dispatch(resetConversations(["shareInMessageModal"]))
            }
          />
        )}
        {deleteFileModal && (
          <DeleteFileModal
            visible={deleteFileModal}
            onCancel={() => {
              dispatch(
                updateConversations({ key: "deleteFileModal", value: false })
              );
              setDeleteFileModal(false);
              removeFilePreview();
              dispatch(resetToInitial({}));
            }}
          />
        )}
      </div>
    </>
  );
};

export default FilePreviewScreenNew;
