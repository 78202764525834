import React, { useCallback } from "react";
import { Calendar, Tooltip } from "antd";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import { useState, useRef } from "react";
import { hexToRgb } from "../../../Core/utils/colorConverter";
import { useAppSelector } from "../../../Core/redux/hooks";
import CustomButton from "../../../Core/Common/CustomButton";
import { current } from "@reduxjs/toolkit";
import { setCurrentSelectedPhase } from "../../../Core/redux/slices/schedule.slice";

dayjs.extend(isBetween);

const CustomCalendar = () => {
  const [date, setDate] = useState(dayjs());
  //const calender = useRef();
  const { phases } = useAppSelector((state) => state.schedule);

  const dateFullCellRender = (value) => {
    const selectedDate = dayjs(date).isSame(value, "day");

    const getBorderAndBg = () => {
      const currentPhase = phases.find((phase) => {
        return (
          dayjs(value).isBetween(phase.startDate, phase.endDate, "day", "[]") ||
          dayjs(phase.startDate).isSame(value, "day")
        );
      });

      if (currentPhase) {
        return {
          backgroundColor: hexToRgb(currentPhase.color, 0.1),
          borderLeftClass: dayjs(currentPhase.startDate).isSame(value, "day")
            ? `border-l-2 `
            : "",
          borderRightClass: dayjs(currentPhase.endDate).isSame(value, "day")
            ? `border-r-2 `
            : "",
          borderColor: currentPhase.color,
          marginLeft: dayjs(currentPhase.startDate).isSame(value, "day")
            ? "2px"
            : "",
          marginRight: dayjs(currentPhase.endDate).isSame(value, "day")
            ? "2px"
            : "",
          phaseName: currentPhase.phases,
        };
      } else {
        return {
          backgroundColor: "",
          borderRightClass: "",
          borderLeftClass: "",
          phaseName: "",
        };
      }
    };
    return (
      <Tooltip title={getBorderAndBg().phaseName}>
        <div
          className={`${getBorderAndBg().borderRightClass} ${
            getBorderAndBg().borderLeftClass
          } h-full flex items-center justify-center `}
          style={{
            backgroundColor: getBorderAndBg().backgroundColor,
            borderColor: getBorderAndBg().borderColor,
            marginRight: getBorderAndBg().marginRight,
            marginLeft: getBorderAndBg().marginLeft,
          }}
        >
          <div
            className="flex justify-center items-center"
            onClick={() => setDate(value)}
          >
            {selectedDate ? (
              <div className="bg-primary-600 rounded-full flex justify-center  items-center h-[22px] w-[22px] text-white text-xs text-center ">
                <span>{value.date()}</span>
              </div>
            ) : (
              <div className="flex justify-center  items-center h-[22px] w-[22px]">
                {value.date()}
              </div>
            )}
          </div>
        </div>
      </Tooltip>
    );
  };
  return (
    <div className="custom-calendar">
      <Calendar
        fullscreen={false}
        value={date as any}
        dateFullCellRender={dateFullCellRender}
        headerRender={({ value, type, onChange, onTypeChange }) => {
          const start = 0;
          const end = 12;
          const monthOptions = [];
          const year = value.year();
          const month = value.month();

          const current = value.clone();
          const localeData = value.localeData();

          return (
            <div>
              <div className="flex justify-between items-center">
                <h3 className="mon-500-16 text-black">Calendar</h3>
                <CustomButton
                  text="Today"
                  height="36px"
                  width="70px"
                  className="bg-white border-gray-200 mon-500-14 text-gray-700 border"
                  onClick={() => setDate(dayjs())}
                />
              </div>
              <div className="flex justify-between mt-4 mb-4">
                <button onClick={() => setDate(date.add(-1, "months"))}>
                  <img
                    src="/images/icons/chevron-left.svg"
                    className="h-5 w-5"
                  />
                </button>
                <p className="mon-500-14 text-black">
                  {localeData.months(current)} {year}
                </p>
                <button onClick={() => setDate(date.add(1, "months"))}>
                  <img
                    src="/images/icons/chevron-right.svg"
                    className="h-5 w-5"
                  />
                </button>
              </div>
            </div>
          );
        }}
        className="bg-white"
      />{" "}
    </div>
  );
};

export default CustomCalendar;
