import { DatePicker } from "antd";
import dayjs from "dayjs";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import CustomButton from "../../../Core/Common/CustomButton";
import CustomModal from "../../../Core/Common/CustomModal";
import {
  getProjectPhases,
  updatePhase,
} from "../../../Core/redux/api/scheduleAPI";
import { useAppDispatch, useAppSelector } from "../../../Core/redux/hooks";
import { resetSchedule } from "../../../Core/redux/slices/schedule.slice";

type StartPhaseModalProps = {
  visible: boolean;
  onCancel: () => void;
};

const StartPhaseModal = ({ visible, onCancel }: StartPhaseModalProps) => {
  const { projectId } = useParams();
  const { currentSelectedPhase } = useAppSelector((state) => state.schedule);
  const dispatch = useAppDispatch();
  const [dateInput, setDateInput] = useState({
    startDate: "",
    endDate: "",
  });
  const currentDate = dayjs();

  console.log(currentDate, "current date in dd mm yyyy");

  const handleChange = ({ key, value }) => {
    const oldData = { ...dateInput };
    oldData[key] = value;
    setDateInput({ ...oldData });
  };

  const handleSubmit = async () => {
    const payload = {
      projectId: projectId,
      phaseId: currentSelectedPhase.phaseId.toString(),
      isOngoing: 1,
      isCompleted: 0,
      startDate: dayjs().format("YYYY-MM-DD"),
      endDate: dayjs(dateInput.endDate).format("YYYY-MM-DD"),
    };

    await dispatch(updatePhase(payload as any));
    await dispatch(getProjectPhases({ projectId }));
    onCancel();
    dispatch(resetSchedule(["startPhaseModal"]));
  };

  console.log(dateInput, "date input check");
  return (
    <div>
      <CustomModal
        title={
          <p className="mon-500-16 text-gray-700">
            {currentSelectedPhase.phases}
          </p>
        }
        width={"524px"}
        visible={visible}
        body={
          <div className="px-5 pt-4">
            <p className="mon-400-14 text-gray-500">
              Starting this phase today. View and edit end date for this phase
            </p>
            <div className="mt-4">
              <div className="mt-6 mx-1">
                <div className="mon-500-14 text-gray-700 pb-1.5">
                  Start date
                </div>
                <DatePicker
                  placeholder="dd/mm/yyyy"
                  className="w-full h-10 bg-transparent border-gray-300 rounded-xl shadow-xs"
                  //suffixIcon={<></>}
                  format="DD/MM/YYYY"
                  value={currentDate as any}
                  disabled
                />
              </div>
              <div className="mt-6 mx-1">
                <div className="mon-500-14 text-gray-700 pb-1.5">End date</div>
                <DatePicker
                  placeholder="dd/mm/yyyy"
                  className="w-full h-10 bg-transparent border-gray-300 rounded-xl shadow-xs"
                  suffixIcon={<></>}
                  format="DD/MM/YYYY"
                  value={dateInput["endDate"] as any}
                  onChange={(e) =>
                    handleChange({
                      key: "endDate",
                      value: e,
                    })
                  }
                />
              </div>
            </div>
          </div>
        }
        footer={
          <div className="flex justify-between pt-8 pb-4 px-3">
            <CustomButton
              text="Cancel"
              onClick={() => {
                onCancel();
              }}
              className="border-2 border-gray-200 text-black bg-white mon-500-16 "
              height="44px"
              width="49%"
            />
            <CustomButton
              text="Start Phase"
              onClick={() => {
                handleSubmit();
              }}
              className="mon-500-16 text-white  bg-primary-600 "
              height="44px"
              width="49%"
            />
          </div>
        }
        onCancel={onCancel}
      />
    </div>
  );
};

export default StartPhaseModal;
