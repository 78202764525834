import { useState, useEffect } from 'react';
import axios from 'axios';

const useTimezone = (lat, lng) => {
  const [timezone, setTimezone] = useState({timeZoneId:"" , timeZoneName:""});

  useEffect(() => {
    const handleGetTimezone = async () => {
      const API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;
      try {
        const result = await axios.get(
          `https://maps.googleapis.com/maps/api/timezone/json?location=${lat},${lng}&timestamp=1331161200&key=${API_KEY}`
        );
        setTimezone(result.data);
      } catch (e) {
        console.log("Error while getting timzone");
      }
    }
    handleGetTimezone();
  }, [lat, lng]);

  return timezone;
}


export default useTimezone