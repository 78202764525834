import { createSlice } from "@reduxjs/toolkit";
import { changeWorkspace } from "../api/dashboardAPI";
import { updateUserProfile, userAuthenticate, userAuthenticateGoogle } from "../api/userAPI";

//We do not recommend the object notation for extraReducers any more, especially with TypeScript you should be using the builder notation --by redux toolkit team.

const initialState = {
  loading: false,
  error: {},
  status: "idle",
  loggedIn: false,
  user: {
    id: 0,
    roleId: null,
    firstName: "user",
    lastName: "user",
    profilePicture: "",
    mobile: null,
    email: null,
    logMe: null,
    token: "",
    tenant_id: "",
    company: "",
    jobTitle:"",
    location:"",
    lat:0,
    long:0
  },
};

export const userSlice = createSlice({
  name: "userDetails",
  initialState,
  reducers: {
    updateUser: (state, action) => {
      state[action.payload.key] = action.payload.value;
    },
    // setUser: (state, action) => {
    //   state.user = action.payload;
    //   state.loading = false;
    // },
    updateUserData: (state, action) => {
      action.payload.map((ele) => {
        state[ele.key] = ele.value;
      });
    },
    updateUserToken:(state,action)=>{
      state.user.token = action.payload
    },
    updateParentKeyUserData: (state, action) => {
      action.payload.map((ele) => {
        state[ele.parentkey][ele.key] = ele.value;
      });
    },
    resetToInitialUser: (state, action) => {
      Object.keys(initialState).forEach(
        (key) => (state[key] = initialState[key])
      );
    },
  },
  extraReducers: (builder) => {
    builder.addCase(userAuthenticate.pending, (state, { payload }) => {
      state.loading = true;
      state.status = "pending";
    });
    builder.addCase(userAuthenticate.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.status = "fulfilled";
      state.loggedIn = true;
      state.user = payload;
      state.error = null;
      console.log("AUTTTH: ", payload);
    });
    builder.addCase(userAuthenticate.rejected, (state, { payload }) => {
      state.loading = false;
      state.status = "rejected";
      state.error = payload;
      state.user = {
        ...initialState.user,
      };
      state.loggedIn = false;
    });
    builder.addCase(userAuthenticateGoogle.pending, (state, { payload }) => {
      state.loading = true;
      state.status = "pending";
    });
    builder.addCase(userAuthenticateGoogle.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.status = "fulfilled";
      state.loggedIn = true;
      state.user = payload;
      state.error = null;
      console.log("AUTTTH: ", payload);
    });
    builder.addCase(userAuthenticateGoogle.rejected, (state, { payload }) => {
      state.loading = false;
      state.status = "rejected";
      state.error = payload;
      state.user = {
        ...initialState.user,
      };
      state.loggedIn = false;
    });
    builder.addCase(changeWorkspace.pending, (state, { payload }) => {
      state.loading = true;
      state.status = "pending";
    });
    builder.addCase(changeWorkspace.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.status = "fulfilled";
      state.loggedIn = true;
      state.user.tenant_id = payload.tenantId;
      state.user.token = payload.token;
      state.error = null;
      console.log("AUTTTH: ", payload);
    });
    builder.addCase(changeWorkspace.rejected, (state, { payload }) => {
      state.loading = false;
      state.status = "rejected";
      state.error = payload;
      state.user = {
        ...initialState.user,
      };
      state.loggedIn = false;
    });
    builder.addCase(updateUserProfile.pending, (state, { payload }) => {
      state.loading = true;
      state.status = "pending";
    });
    builder.addCase(updateUserProfile.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.status = "fulfilled";
      // const user = {...state.user}
      // user.firstName = payload.firstName
      state.user = {...state.user , ...payload.result}
      state.error = null;
      
    });
    builder.addCase(updateUserProfile.rejected, (state, { payload }) => {
      state.loading = false;
      state.status = "rejected";
      state.error = payload;
     
    });
  },
});

export const { updateUserData , resetToInitialUser ,updateUserToken } = userSlice.actions;
export default userSlice.reducer;
