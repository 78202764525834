import React, { useEffect, useState } from "react";
import CustomTable from "../../Core/Common/CustomTable";
import { Tooltip } from "antd";
import MenuDropdown from "../Conversations/Components/MenuDropdown";
import { useAppDispatch, useAppSelector } from "../../Core/redux/hooks";
import { updateConversations } from "../../Core/redux/slices/conversations.slice";
import { updateVault } from "../../Core/redux/slices/vault.slice";
import CustomAvatar from "../../Core/Common/CustomAvatar";
import dayjs from "dayjs";
import { formatBytes } from "../../Core/utils/vaultUtils";
import PhaseIcon from "../../Core/svg/PhaseIcon";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ShareAccessModal from "../Conversations/Components/ShareAccessModal";
import uniqid from "uniqid";
import bytesToSize from "../../Core/utils/bytesToSize";

const ListViewTable = ({ searchText }) => {
  const { projectId, "*": splat } = useParams();
  const [limit, setLimit] = useState(10);
  const [currentPageNo, setCurrentPageNo] = useState(1);
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const { links, files, folders } = useAppSelector((state) => state.vault);

  const [listData, setListData] = useState([]);
  const { userList } = useAppSelector((state) => state.overview);
  const { user } = useAppSelector((state) => state.userDetails);
  const { vault } = useAppSelector((state) => state.projects);

  const [openShareAccessModal, setOpenShareAccessModal] = useState(false);

  const viewAccess = vault?.[0]?.[0] || {};
  const folderFileLink = vault?.[1]?.[0] || {};
  const requestAccess = vault?.[2]?.[0] || {};
  const deleteAll = vault?.[3]?.[0] || {};
  const commentAccess = vault?.[5]?.[0] || {};
  const requestMedia = vault?.[5]?.[0] || {};

  console.log(user, "user v2");
  const getUserNameById = (id) => {
    const user = userList.filter(({ userId }) => +userId === +id)[0];
    const name = `${user?.firstname || "-"} ${user?.lastname || ""}`;
    const profilePicture = user?.profilePicture;
    return [name, profilePicture];
  };
  getUserNameById(48);

  console.log(userList, "user list 12");

  useEffect(() => {
    console.log("LIMKS: ", { links, files, folders });

    const folderList = folders.map(
      ({
        id,
        folderName,
        createdAt,
        usersWithAccess,
        phaseId,
        canView,
        canEdit,
        isOwner,
      }) => {
        return {
          id: `folder-${id}`,
          type: "folder",
          name: folderName,
          // size: "-",
          phaseId,
          folderId: id,
          dateUploaded: dayjs(createdAt).format("DD MMM"),
          owner: folderName.includes(" Space")
            ? user.firstName + " " + user.lastName
            : getUserNameById(
                usersWithAccess.filter(({ isOwner }) => isOwner === 1)[0]
                  ?.userId
              )[0],
          ownerProfilePicture: getUserNameById(
            usersWithAccess.filter(({ isOwner }) => isOwner === 1)[0]?.userId
          )[1],
          collaborators: `${usersWithAccess.length} people`,
          usersWithAccess: usersWithAccess,
          canView: canView,
          canEdit: canEdit,
          isOwner: isOwner,
        };
      }
    );

    console.log(folders, "folder list check");
    console.log(links, "link list check");

    const fileList = files.map(
      ({
        id,
        name,
        fileSize,
        createdAt,
        usersWithAccess,
        phaseId,
        ownerFirstName,
        ownerLastName,
        canView,
        canEdit,
        isOwner,
        url,
        createdById,
      }) => {
        return {
          id: `file-${id}`,
          type: "file",
          name: name,
          phaseId,
          fileSize: fileSize,
          dateUploaded: dayjs(createdAt).format("DD MMM"),
          // owner: getUserNameById(
          //   usersWithAccess.filter(({ isOwner }) => isOwner === 1)[0]?.userId
          // )[0],
          owner: ownerFirstName + " " + ownerLastName,
          // ownerProfilePicture: getUserNameById(
          //   usersWithAccess.filter(({ isOwner }) => isOwner === 1)[0]?.userId
          // )[1],
          ownerProfilePicture: ownerFirstName + " " + ownerLastName,
          collaborators: `${usersWithAccess.length} people`,
          usersWithAccess: usersWithAccess,
          canView: canView,
          canEdit: canEdit,
          isOwner: isOwner,
          createdById: createdById,
          createdAt: createdAt,
          fileId: id,
          url: url,
        };
      }
    );

    const linkList = links.map(
      ({
        id,
        name,
        createdAt,
        usersWithAccess,
        phaseId,
        canView,
        canEdit,
        isOwner,
        createdById,
      }) => {
        return {
          id: `link-${id}`,
          name: name,
          type: "link",
          phaseId,
          dateUploaded: dayjs(createdAt).format("DD MMM"),
          owner: getUserNameById(
            usersWithAccess.filter(({ isOwner }) => isOwner === 1)[0]?.userId
          )[0],
          ownerProfilePicture: getUserNameById(
            usersWithAccess.filter(({ isOwner }) => isOwner === 1)[0]?.userId
          )[1],
          collaborators: `${usersWithAccess.length} people`,
          usersWithAccess: usersWithAccess,
          canView: canView,
          canEdit: canEdit,
          isOwner: isOwner,
          linkId: id,
          createdById: createdById,
          createdAt: createdAt,
        };
      }
    );

    const mySpace = {
      id: `myspace-${user.id}`,
      name: `${user?.firstName}'s space`,
      type: "mySpace",
      size: "2MB",
      dateUploaded: "20 May",
      owner: `${user?.firstName} ${user?.lastName}`,
      ownerProfilePicture: `${user?.profilePicture}`,
      collaborators: "",
    };

    // console.log(mySpace, "my space setup v2");

    let newListData = [];
    // if (splat.length === 0) newListData.push(mySpace);
    newListData = [...newListData, ...folderList, ...fileList, ...linkList];

    setListData(newListData);
  }, [projectId, splat, files, folders, links]);

  const onDownload = (record) => {
    const link = document.createElement("a");
    link.download = record.name;
    link.href = record.url;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const renderMenuDropDown = (type, record) => {
    switch (type) {
      case "file":
        return (
          <MenuDropdown
            elements={[
              folderFileLink.read
                ? {
                    id: 1,
                    image: "/images/icons/info-circle.svg",
                    text: "View details",
                    textColor: "text-gray-700",
                    borderBottom: true,
                    onclick: () =>
                      dispatch(
                        updateVault({
                          key: "showDocDetailsDrawer",
                          value: {
                            visible: true,
                            docId: record.fileId,
                            docType: "file",
                            docDetails: record,
                          },
                        })
                      ),
                  }
                : null,
              commentAccess.read
                ? {
                    id: 2,
                    image: "/images/icons/message-dots-circle-gray.svg",
                    text: "Open comments",
                    textColor: "text-gray-700",
                    onclick: () =>
                      dispatch(
                        updateVault({
                          key: "openCommentsModal",
                          value: {
                            visible: true,
                            docType: "file",
                            name: record.name,
                            docId: record.fileId,
                            comments: [],
                            users: record.usersWithAccess,
                          },
                        })
                      ),
                  }
                : null,
              folderFileLink.update &&
              (record.isOwner === 1 || record.canEdit === 1)
                ? {
                    id: 3,
                    image: "/images/icons/add-user-gray.svg",
                    text: "Share access",
                    textColor: "text-gray-700",
                    onclick: () => {
                      setOpenShareAccessModal(true);
                      dispatch(
                        updateVault({
                          key: "shareAccessDoc",
                          value: {
                            docId: record.fileId,
                            users: record.usersWithAccess,
                            docType: "file",
                            docDetails: record,
                          },
                        })
                      );
                    },
                  }
                : null,
              folderFileLink.update &&
              (record.isOwner === 1 || record.canEdit === 1)
                ? {
                    id: 4,
                    image: "/images/icons/move-to.svg",
                    text: "Move to",
                    textColor: "text-gray-700",
                    onclick: () =>
                      dispatch(
                        updateVault({
                          key: "moveToFolderModal",
                          value: {
                            visible: true,
                            docId: record.fileId,
                            docType: "files",
                          },
                        })
                      ),
                  }
                : null,
              record.isOwner === 1 || record.canEdit === 1
                ? {
                    id: 5,
                    image: "/images/icons/share-gray.svg",
                    text: "Share in message",
                    textColor: "text-gray-700",
                    onclick: () =>
                      dispatch(
                        updateConversations({
                          key: "shareInMessageModal",
                          value: {
                            type: "file",
                            visible: true,
                            url: "",
                            file: [
                              {
                                name: record.name,
                                url: record.url,
                                size: record.fileSize,
                                type: record.mimeType,
                                lastModified: uniqid(`${record.name}-`),
                                savedInVault: true,
                              },
                            ],
                          },
                        })
                      ),
                  }
                : null,
              {
                id: 6,
                image: "/images/icons/copy-dashed.svg",
                text: "Make a copy",
                textColor: "text-gray-700",
                onclick: () =>
                  dispatch(
                    updateVault({
                      key: "copyModal",
                      value: {
                        visible: true,
                        docType: "file",
                        id: record.fileId,
                      },
                    })
                  ),
              },
              {
                id: 7,
                image: "/images/icons/download-icon.svg",
                text: "Download",
                textColor: "text-gray-700",
                borderBottom: true,
                onclick: () => onDownload(record),
              },

              folderFileLink.delete &&
              (record.isOwner === 1 || record.canEdit === 1)
                ? {
                    id: 8,
                    image: "/images/icons/trash.svg",
                    text: "Delete",
                    textColor: "text-error-600",
                    onclick: () =>
                      dispatch(
                        updateVault({
                          key: "deleteFileModal",
                          value: {
                            visible: true,
                            id: record.fileId,
                          },
                        })
                      ),
                  }
                : null,
            ]}
            customFunc={undefined}
          />
        );
      case "link":
        return (
          <MenuDropdown
            elements={[
              record.username && (record.isOwner === 1 || record.canEdit === 1)
                ? {
                    id: 1,
                    image: "/images/icons/copy.svg",
                    text: "Copy username",
                    textColor: "text-gray-700",
                    // onclick: () => {
                    //   copyToClipboard(record.username);
                    // },
                  }
                : null,
              record.password && (record.isOwner === 1 || record.canEdit === 1)
                ? {
                    id: 2,
                    image: "/images/icons/copy.svg",
                    text: "Copy password",
                    textColor: "text-gray-700",
                    borderBottom: true,
                    // onclick: () => {
                    //   copyToClipboard(record.password);
                    // },
                  }
                : null,
              folderFileLink.read
                ? {
                    id: 3,
                    image: "/images/icons/info-circle.svg",
                    text: "View details",
                    textColor: "text-gray-700",
                    borderBottom: true,
                    onclick: () =>
                      dispatch(
                        updateVault({
                          key: "showDocDetailsDrawer",
                          value: {
                            visible: true,
                            docId: record.linkId,
                            docType: "link",
                            docDetails: record,
                          },
                        })
                      ),
                  }
                : null,
              commentAccess.read
                ? {
                    id: 4,
                    image: "/images/icons/message-dots-circle-gray.svg",
                    text: "Open comments",
                    textColor: "text-gray-700",
                    onclick: () =>
                      dispatch(
                        updateVault({
                          key: "openCommentsModal",
                          value: {
                            visible: true,
                            docType: "link",
                            name: record.name,
                            docId: record.linkId,
                            comments: [],
                            users: record.usersWithAccess,
                          },
                        })
                      ),
                  }
                : null,
              folderFileLink.update &&
              (record.isOwner === 1 || record.canEdit === 1)
                ? {
                    id: 5,
                    image: "/images/icons/add-user-gray.svg",
                    text: "Share access",
                    textColor: "text-gray-700",
                    onclick: () => {
                      setOpenShareAccessModal(true);
                      dispatch(
                        updateVault({
                          key: "shareAccessDoc",
                          value: {
                            docId: record.linkId,
                            users: record.usersWithAccess,
                            docType: "link",
                            docDetails: record,
                          },
                        })
                      );
                    },
                  }
                : null,
              folderFileLink.update &&
              (record.isOwner === 1 || record.canEdit === 1)
                ? {
                    id: 6,
                    image: "/images/icons/move-to.svg",
                    text: "Move to",
                    textColor: "text-gray-700",
                    onclick: () =>
                      dispatch(
                        updateVault({
                          key: "moveToFolderModal",
                          value: {
                            visible: true,
                            docId: record.linkId.toString(),
                            docType: "links",
                          },
                        })
                      ),
                  }
                : null,
              {
                id: 7,
                image: "/images/icons/copy-dashed.svg",
                text: "Make a copy",
                textColor: "text-gray-700",
                onclick: () =>
                  dispatch(
                    updateVault({
                      key: "copyModal",
                      value: {
                        visible: true,
                        docType: "link",
                        id: record.linkId.toString(),
                      },
                    })
                  ),
              },
              {
                id: 8,
                image: "/images/icons/export.svg",
                text: "Export as .csv",
                textColor: "text-gray-700",
                borderBottom: true,
                // onclick: () =>
                //   exportToCsv(`${new Date()}.csv`, [
                //     ["Title", "Link", "Username", "Password"],
                //     [record.name, record.url, record.username, record.password],
                //   ]),
              },

              folderFileLink.delete &&
              (record.isOwner === 1 || record.canEdit === 1)
                ? {
                    id: 9,
                    image: "/images/icons/trash.svg",
                    text: "Delete",
                    textColor: "text-error-600",
                    onclick: () =>
                      dispatch(
                        updateVault({
                          key: "deleteLinkModal",
                          value: {
                            visible: true,
                            id: record.linkId,
                          },
                        })
                      ),
                  }
                : null,
            ]}
            customFunc={undefined}
          />
        );
      case "folder":
        return (
          <MenuDropdown
            elements={[
              folderFileLink.read
                ? {
                    id: 1,
                    image: "/images/icons/info-circle.svg",
                    text: "View details",
                    textColor: "text-gray-700",
                    borderBottom: true,
                    onclick: () =>
                      dispatch(
                        updateVault({
                          key: "showDocDetailsDrawer",
                          value: {
                            visible: true,
                            docId: record.folderId,
                            docType: "folder",
                            docDetails: folders.filter(
                              ({ id }) => id === record.folderId
                            )[0],
                          },
                        })
                      ),
                  }
                : null,
              commentAccess
                ? {
                    id: 2,
                    image: "/images/icons/message-dots-circle-gray.svg",
                    text: "Open comments",
                    textColor: "text-gray-700",
                    onclick: () =>
                      dispatch(
                        updateVault({
                          key: "openCommentsModal",
                          value: {
                            visible: true,
                            docType: "folder",
                            name: record.name,
                            docId: record.folderId,
                            comments: [],
                            users: [],
                          },
                        })
                      ),
                  }
                : null,
              folderFileLink.update
                ? {
                    id: 3,
                    image: "/images/icons/add-user-gray.svg",
                    text: "Share access",
                    textColor: "text-gray-700",
                    onclick: () => {
                      setOpenShareAccessModal(true);
                      dispatch(
                        updateVault({
                          key: "shareAccessDoc",
                          value: {
                            docId: record.folderId,
                            users: record.usersWithAccess,
                            docType: "folder",
                            docDetails: record,
                          },
                        })
                      );
                    },
                  }
                : null,
              {
                id: 4,
                image: "/images/icons/share-gray.svg",
                text: "Share in message",
                textColor: "text-gray-700",
                onclick: () =>
                  dispatch(
                    updateConversations({
                      key: "shareInMessageModal",
                      value: {
                        type: "folder",
                        visible: true,
                        url:
                          location.pathname +
                          `${record.name}-${record.folderId}`,
                        file: [],
                      },
                    })
                  ),
              },
              {
                id: 5,
                image: "/images/icons/copy-dashed.svg",
                text: "Make a copy",
                textColor: "text-gray-700",
                onclick: () => {
                  dispatch(
                    updateVault({
                      key: "copyModal",
                      value: {
                        visible: true,
                        id: record.id,
                      },
                    })
                  );
                },
              },
              {
                id: 6,
                image: "/images/icons/download-icon.svg",
                text: "Download",
                textColor: "text-gray-700",
                borderBottom: true,
                onclick: () =>
                  dispatch(
                    updateVault({
                      key: "downloadModal",
                      value: {
                        visible: true,
                        type: "folder",
                        id: record.folderId,
                      },
                    })
                  ),
              },

              folderFileLink.delete
                ? {
                    id: 7,
                    image: "/images/icons/trash.svg",
                    text: "Delete",
                    textColor: "text-error-600",
                    onclick: () =>
                      dispatch(
                        updateVault({
                          key: "deleteFolderModal",
                          value: {
                            visible: true,
                            id: record.folderId,
                          },
                        })
                      ),
                  }
                : null,
            ]}
            customFunc={undefined}
          />
        );
      case "mySpace":
        return (
          <MenuDropdown
            elements={[
              {
                id: 1,
                image: "/images/icons/info-circle.svg",
                text: "View details",
                textColor: "text-gray-700",
                onclick: () =>
                  dispatch(
                    updateVault({
                      key: "showDocDetailsDrawer",
                      value: {
                        visible: true,
                        docId: record.folderId,
                        docType: "folder",
                        docDetails: folders.filter(
                          ({ id }) => id === record.folderId
                        )[0],
                      },
                    })
                  ),
              },
              {
                id: 2,
                image: "/images/icons/download-icon.svg",
                text: "Download",
                textColor: "text-gray-700",
                borderBottom: true,
                onclick: () =>
                  dispatch(
                    updateVault({
                      key: "downloadModal",
                      value: {
                        visible: true,
                        type: "file",
                        id: record.folderId,
                      },
                    })
                  ),
              },
            ]}
            customFunc={undefined}
          />
        );
    }
  };

  const renderIcon = (type) => {
    switch (type) {
      case "folder":
        return "/images/icons/folder-fill-vault.svg";
      case "file":
        return "/images/icons/file-list-view.svg";
      case "link":
        return "/images/icons/link-yellow.svg";
      case "mySpace":
        return "/images/icons/my-space-folder.svg";
    }
  };

  const columns = [
    {
      title: () => (
        <div className="mon-500-10 pl-3 text-gray-500 uppercase">Name</div>
      ),
      width: "25%",
      dataIndex: "name",
      defaultSortOrder: "descend",
      sorter: (a, b) => a.id - b.id,
      render: (text, record) => (
        <div className="flex items-center pl-2">
          {!record.name.includes(" Space") &&
            record.type !== "mySpace" &&
            record.phaseId && (
              <div className="h-3 w-3 rounded-xl">
                <PhaseIcon
                  width="12px"
                  height="12px"
                  phaseId={+record.phaseId}
                />
              </div>
            )}
          <img
            src={
              record.name.includes(" Space")
                ? renderIcon("mySpace")
                : record.img || renderIcon(record.type)
            }
            className={`h-5 w-5 ${
              record.name.includes(" Space") ? "ml-5" : "ml-2"
            }`}
            alt={text}
          />
          <div className="mon-500-12 capitalize pl-3 pt-0.5 text-gray-700 truncate whitespace-nowrap">
            {record.name.includes(" Space")
              ? `${user?.firstName}'s Space`
              : text}
          </div>
        </div>
      ),
    },
    {
      title: () => (
        <div className="mon-500-10 pl-3 text-gray-500 uppercase">Size</div>
      ),
      // width: "7%",
      dataIndex: "fileSize",
      render: (text, record) => (
        <div className="mon-500-12 capitalize pl-3 text-gray-500 truncate whitespace-nowrap">
          {record.type === "file" ? bytesToSize(text) : "--"}
        </div>
      ),
    },
    {
      title: () => (
        <div className="mon-500-10 pl-3 text-gray-500 uppercase">
          Date uploaded
        </div>
      ),
      // width: "15%",
      dataIndex: "dateUploaded",
      defaultSortOrder: "descend",
      sorter: (a, b) => a.id - b.id,
      render: (text, record) => (
        <div className="mon-500-12 capitalize text-gray-500 pl-3 truncate whitespace-nowrap">
          {text}
        </div>
      ),
    },
    {
      title: () => (
        <div className="mon-500-10 pl-3 text-gray-500 uppercase">Owner</div>
      ),
      // width: "18%",
      dataIndex: "owner",
      render: (text, record) => (
        <div className="flex items-center ml-2">
          <CustomAvatar
            title={text}
            src={record.ownerProfilePicture}
            size={24}
          />
          <div className="mon-500-12 capitalize text-gray-500 pl-3 truncate whitespace-nowrap">
            {text}
          </div>
        </div>
      ),
    },
    {
      title: () => (
        <div className="mon-500-10 pl-3 text-gray-500 uppercase">
          Collaborators
        </div>
      ),
      // width: "15%",
      dataIndex: "collaborators",
      defaultSortOrder: "descend",
      sorter: (a, b) => a.id - b.id,
      render: (text, record) => (
        <div className="mon-500-12 capitalize pl-3 text-gray-500 truncate whitespace-nowrap">
          {!record.name.includes(" Space") && text}
        </div>
      ),
    },
    {
      title: () => (
        <Tooltip title="Start a thread">
          <img src="/images/icons/message-plus-circle.svg" />
        </Tooltip>
      ),
      width: 70,
      dataIndex: "message",

      render: (text, record) => (
        <>
          {!record.name.includes(" Space") && record.type !== "mySpace" && (
            <Tooltip title="Open comments">
              <img
                src="/images/icons/thread-icon-states.svg"
                className="w-4 h-4"
                alt="thread"
                onClick={(e) => {
                  e.stopPropagation();
                  dispatch(
                    updateVault({
                      key: "openCommentsModal",
                      value: {
                        visible: true,
                        docType: record.type,
                        name: record.name,
                        docId: record.folderId,
                        comments: [],
                      },
                    })
                  );
                }}
              />
            </Tooltip>
          )}
        </>
      ),
    },

    {
      title: () => <></>,
      width: 70,
      dataIndex: "message",

      render: (text, record) => (
        <>
          {record.name.includes(" Space")
            ? renderMenuDropDown("mySpace", record)
            : renderMenuDropDown(record.type, record)}
          {/* {record.name.includes(" Space") ? (
            <MenuDropdown
              elements={[
                {
                  id: 1,
                  image: "/images/icons/info-circle.svg",
                  text: "View details",
                  textColor: "text-gray-700",
                  onclick: () =>
                    dispatch(
                      updateVault({
                        key: "showDocDetailsDrawer",
                        value: {
                          visible: true,
                          docId: record.folderId,
                          docType: "folder",
                          docDetails: folders.filter(
                            ({ id }) => id === record.folderId
                          )[0],
                        },
                      })
                    ),
                },
                {
                  id: 2,
                  image: "/images/icons/download-icon.svg",
                  text: "Download",
                  textColor: "text-gray-700",
                  borderBottom: true,
                  onclick: () =>
                    dispatch(
                      updateVault({
                        key: "downloadModal",
                        value: {
                          visible: true,
                          type: "file",
                          id: record.folderId,
                        },
                      })
                    ),
                },
              ]}
              customFunc={undefined}
            />
          ) : (
            <MenuDropdown
              elements={[
                folderFileLink.read
                  ? {
                      id: 1,
                      image: "/images/icons/info-circle.svg",
                      text: "View details",
                      textColor: "text-gray-700",
                      borderBottom: true,
                      onclick: () =>
                        dispatch(
                          updateVault({
                            key: "showDocDetailsDrawer",
                            value: {
                              visible: true,
                              docId: record.folderId,
                              docType: "folder",
                              docDetails: folders.filter(
                                ({ id }) => id === record.folderId
                              )[0],
                            },
                          })
                        ),
                    }
                  : null,
                commentAccess
                  ? {
                      id: 2,
                      image: "/images/icons/message-dots-circle-gray.svg",
                      text: "Open comments",
                      textColor: "text-gray-700",
                      onclick: () =>
                        dispatch(
                          updateVault({
                            key: "openCommentsModal",
                            value: {
                              visible: true,
                              docType: "folder",
                              name: record.name,
                              docId: record.folderId,
                              comments: [],
                              users: [],
                            },
                          })
                        ),
                    }
                  : null,
                folderFileLink.update
                  ? {
                      id: 3,
                      image: "/images/icons/add-user-gray.svg",
                      text: "Share access",
                      textColor: "text-gray-700",
                      onclick: () => {
                        setOpenShareAccessModal(true);
                        dispatch(
                          updateVault({
                            key: "shareAccessDoc",
                            value: {
                              docId: record.folderId,
                              users: [],
                              docType: "folder",
                              docDetails: record,
                            },
                          })
                        );
                      },
                    }
                  : null,
                {
                  id: 4,
                  image: "/images/icons/share-gray.svg",
                  text: "Share in message",
                  textColor: "text-gray-700",
                  onclick: () =>
                    dispatch(
                      updateConversations({
                        key: "shareInMessageModal",
                        value: {
                          type: "folder",
                          visible: true,
                          url:
                            location.pathname +
                            `${record.name}-${record.folderId}`,
                          file: [],
                        },
                      })
                    ),
                },
                {
                  id: 5,
                  image: "/images/icons/copy-dashed.svg",
                  text: "Make a copy",
                  textColor: "text-gray-700",
                  onclick: () => {
                    dispatch(
                      updateVault({
                        key: "copyModal",
                        value: {
                          visible: true,
                          id: record.id,
                        },
                      })
                    );
                  },
                },
                {
                  id: 6,
                  image: "/images/icons/download-icon.svg",
                  text: "Download",
                  textColor: "text-gray-700",
                  borderBottom: true,
                  onclick: () =>
                    dispatch(
                      updateVault({
                        key: "downloadModal",
                        value: {
                          visible: true,
                          type: "folder",
                          id: record.folderId,
                        },
                      })
                    ),
                },

                folderFileLink.delete
                  ? {
                      id: 7,
                      image: "/images/icons/trash.svg",
                      text: "Delete",
                      textColor: "text-error-600",
                      onclick: () =>
                        dispatch(
                          updateVault({
                            key: "deleteFolderModal",
                            value: {
                              visible: true,
                              id: record.folderId,
                            },
                          })
                        ),
                    }
                  : null,
              ]}
              customFunc={undefined}
            />
          )} */}
        </>
      ),
    },
  ];
  return (
    <div className="mt-5">
      <CustomTable
        // customPadding="p-3"
        className="custom-table cursor-pointer list-table"
        scroll={listData ? "calc(100vh - 325px)" : "0px"}
        columns={columns}
        data={listData.filter((row) =>
          row.name.toLowerCase().includes(searchText)
        )}
        rowClassName={(record, index) =>
          record.type === "mySpace" ? "my-space-bg" : ""
        }
        dataCount={listData.length}
        current={currentPageNo}
        pageSize={limit}
        setPageSize={setLimit}
        setCurrent={(e) => {
          setCurrentPageNo(e);
        }}
        onRow={(record, recordIndex) => {
          return {
            onClick: () => {
              console.log(record);
              if (record.type === "folder") {
                const newPath =
                  location.pathname + `/${record.name}-${record.folderId}`;
                console.log(newPath);
                navigate(newPath);
              }
            },
          };
        }}
      />

      <ShareAccessModal
        visible={openShareAccessModal}
        onCancel={() => setOpenShareAccessModal(false)}
      />
    </div>
  );
};

export default ListViewTable;
