import { Progress } from "antd";
import dayjs from "dayjs";
import React from "react";
import CustomAvatar from "../../../Core/Common/CustomAvatar";
import { useAppSelector } from "../../../Core/redux/hooks";
import PhaseIcon from "../../../Core/svg/PhaseIcon";
import ComponentsDrawer from "../../Overview/Components/ComponentsDrawer";

const Card1 = ({ width, height, className = "", title }) => {
  return (
    <div
      className={`bg-[#fff] my-4 rounded-2xl shadow-md ${className}`}
      style={{ width, height }}
    >
      <div className="p-6">
        {title ? (
          title
        ) : (
          <div className="bg-gray-300 w-[146px] h-[17px] rounded-md"></div>
        )}
      </div>
      <div className="flex justify-center items-center flex-col">
        <div className="mt-6">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="276"
            height="181"
            fill="none"
            viewBox="0 0 276 181"
          >
            <path
              fill="#EDF3F9"
              d="M131.029 9.283a86.001 86.001 0 1018.624.51l-3.929 28.732a57.002 57.002 0 0130.325 98.917 57.002 57.002 0 01-80.471-80.513 57.001 57.001 0 0137.802-18.742l-2.351-28.904z"
            ></path>
            <path
              fill="url(#paint0_radial_744_39158)"
              d="M138 0c1.99 0 3.979.063 5.965.187l-2.386 37.925A56.957 56.957 0 00138 38V0z"
            ></path>
            <defs>
              <radialGradient
                id="paint0_radial_744_39158"
                cx="0"
                cy="0"
                r="1"
                gradientTransform="rotate(57.579 -17.44 173.065) scale(91.5175)"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#152E57"></stop>
                <stop offset="1" stopColor="#194185"></stop>
              </radialGradient>
            </defs>
          </svg>
        </div>
        <div className="mt-8">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="276"
            height="70"
            fill="none"
            viewBox="0 0 276 70"
          >
            <rect width="276" height="70" fill="#EFF8FF" rx="8"></rect>
            <rect
              width="16"
              height="16"
              x="8"
              y="8"
              fill="#C0CBD7"
              rx="5"
            ></rect>
            <rect
              width="91"
              height="11"
              x="36"
              y="10.5"
              fill="#C0CBD7"
              rx="5"
            ></rect>
            <rect
              width="242"
              height="9"
              x="8"
              y="34"
              fill="#DDE5ED"
              rx="4.5"
            ></rect>
            <rect
              width="242"
              height="9"
              x="8"
              y="53"
              fill="#DDE5ED"
              rx="4.5"
            ></rect>
          </svg>
        </div>
      </div>
    </div>
  );
};

const Card2 = ({ width, height, className }) => {
  return (
    <div
      className={`bg-[#fff] my-4 rounded-2xl shadow-md ${className}`}
      style={{ width, height }}
    ></div>
  );
};

const ProjectInfoItem = ({
  fieldName,
  image,
  fieldValue,
}: {
  fieldName: string;
  image: string;
  fieldValue: string;
}) => {
  return (
    <div className="mt-7">
      <div className="text-gray-500 uppercase text-[10px] font-medium">
        {fieldName}
      </div>
      <div className="text-black text-xs font-medium capitalize mt-2">
        {fieldValue}
      </div>
    </div>
  );
};

const Card3 = ({ width, height, className, title }) => {
  const {
    projectName,
    projectImage,
    projectColor,
    projectDuration,
    clientName,
    clientImage,
    clientLocation,
    clientColor,
  } = useAppSelector((state) => state.projectOnboarding);

  const noData = () => {
    return (
      <div className="mt-5">
        <div className="bg-gray-300 w-[146px] h-[17px] rounded-md"></div>
        <div
          className="bg-gray-200 rounded-2xl mt-[14px]"
          style={{ width: "124px", height: "11px" }}
        ></div>
      </div>
    );
  };
  return (
    <div
      className={`bg-[#fff] py-4 pt-6 rounded-2xl px-7 shadow-md ${className}`}
      style={{ width, height }}
    >
      <div className="flex justify-between  ">
        {title ? (
          <div className="flex text-base font-medium text-black">
            Project Info
          </div>
        ) : (
          <div className="bg-gray-300 w-[146px] h-[17px] rounded-md"></div>
        )}
      </div>

      {projectName ? (
        <div className="mt-5">
          <div className="text-gray-500 uppercase mon-500-10">Project Name</div>
          <div className="flex items-center mt-2">
            {projectImage.length > 0 ? (
              <img
                src={projectImage}
                className="w-6 h-6 rounded-full cursor-pointer object-cover"
                onClick={async () => {
                  const src = projectImage as string;
                  if (!src) return;
                  const image = new Image();
                  image.src = src;
                  const imgWindow = window.open(src);
                  imgWindow?.document.write(image.outerHTML);
                }}
              />
            ) : (
              <CustomAvatar
                title={projectName ? projectName : "P"}
                size={24}
                color={projectColor}
                whiteText
                fontSize="16px"
              />
            )}
            <div className="flex text-black text-xs font-medium capitalize ml-2">
              {projectName}
            </div>
          </div>
        </div>
      ) : (
        noData()
      )}

      {projectDuration ? (
        <>
          <ProjectInfoItem
            fieldName={"Start Date"}
            image={null}
            fieldValue={
              projectDuration[0]
                ? dayjs(projectDuration[0]).format("DD MMM YYYY")
                : "-"
            }
          />
          <ProjectInfoItem
            fieldName={"End Date"}
            image={null}
            fieldValue={
              projectDuration[1]
                ? dayjs(projectDuration[1]).format("DD MMM YYYY")
                : "-"
            }
          />
        </>
      ) : (
        <>
          {noData()} {noData()}
        </>
      )}

      {clientName ? (
        <div className="mt-5">
          <div className="text-gray-500 uppercase text-[10px] font-medium">
            Client Name
          </div>
          <div className="flex items-center mt-2">
            {clientImage.length > 0 ? (
              <img
                src={clientImage}
                className="w-6 h-6 rounded-full cursor-pointer object-cover"
                onClick={async () => {
                  const src = clientImage as string;
                  if (!src) return;
                  const image = new Image();
                  image.src = src;
                  const imgWindow = window.open(src);
                  imgWindow?.document.write(image.outerHTML);
                }}
              />
            ) : (
              <CustomAvatar
                title={clientName ? clientName : "P"}
                size={24}
                color={clientColor}
                whiteText
                fontSize="16px"
              />
            )}
            <div className="flex text-black text-xs font-medium capitalize ml-2">
              {clientName}
            </div>
          </div>
        </div>
      ) : (
        noData()
      )}

      {clientLocation ? (
        <ProjectInfoItem
          fieldName={"Location"}
          image={null}
          fieldValue={clientLocation}
        />
      ) : (
        noData()
      )}

      {/* <ProjectInfoItem
        fieldName={"Timezone"}
        image={null}
        fieldValue={"08:00AM, East Africa Time"}
      /> */}
    </div>
  );
};

const Card4 = ({ width, height, className, title }) => {
  const { selectedPhase } = useAppSelector((state) => state.projectOnboarding);
  const phaseCard = (phaseId, phaseName = "", color = "") => {
    return (
      <div className="flex mt-6">
        <PhaseIcon height="48" width="48" phaseId={phaseId} icon />
        <div className="w-[90%] pl-4">
          <div className="flex justify-between items-center">
            <p className="mon-500-12 text-gray-900">{phaseName}</p>
            <button
              className={` h-6 rounded-2xl text-xs font-medium bg-primary-50 text-primary-600`}
            >
              <div className="flex px-2 items-center">
                <div className="pr-1 ">Yet to begin</div>

                <svg
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4.5 9L7.5 6L4.5 3"
                    stroke="#165ABF"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            </button>
          </div>
          <div className=" flex items-center truncate whitespace-nowrap">
            <Progress
              showInfo={false}
              strokeColor={color}
              percent={+5}
              trailColor="#EDF3F9"
              className="progress-bar-tiny"
            />
            <div className="mont-500-14 text-gray-700 ml-3">0%</div>
          </div>
        </div>
      </div>
    );
  };
  const noData = () => {
    return (
      <div className="flex mt-6">
        <div className="bg-gray-300 w-[48px] h-[48px] rounded-md"></div>
        <div className="w-[90%] pl-4">
          <div className="bg-gray-300 w-[146px] h-[17px] rounded-md"></div>
          <div className="bg-gray-200 w-[146px] h-[4px] rounded-md mt-4"></div>
        </div>
      </div>
    );
  };
  return (
    <div
      className={`bg-[#fff] py-4 pt-6  px-7 rounded-2xl shadow-md ${className}`}
      style={{ width, height }}
    >
      <div className="flex justify-between ">
        {title ? (
          <div className="flex text-base font-medium text-black">Phases</div>
        ) : (
          <div className="bg-gray-300 w-[146px] h-[17px] rounded-md"></div>
        )}
      </div>

      {selectedPhase.includes(1)
        ? phaseCard(1, "Ideation", "#FFDB01")
        : noData()}
      {selectedPhase.includes(2) ? phaseCard(2, "Design", "#F92B63") : noData()}
      {selectedPhase.includes(3)
        ? phaseCard(3, "Development", "#6938EF")
        : noData()}
      {selectedPhase.includes(4)
        ? phaseCard(4, "Deployment", "#33A553")
        : noData()}
      {selectedPhase.includes(5)
        ? phaseCard(5, "Maintenance", "#6536ED")
        : noData()}
    </div>
  );
};

const SkeletonStructure = ({
  width,
  height,
  className = "",
  title,
  body = "",
}) => {
  const { currentStep } = useAppSelector((state) => state.projectOnboarding);
  const getSketeton = () => {
    switch (currentStep) {
      case 1:
        return (
          <>
            <div className="flex items-center gap-x-6">
              <Card1
                width={width}
                height={height}
                className={className}
                title={title}
              />
              <Card2 width={width} height={height} className={className} />
            </div>
            <div className="flex items-center gap-x-6">
              <Card3
                width={width}
                height={height}
                className={className}
                title=""
              />{" "}
              <Card4
                width={width}
                height={height}
                className={className}
                title=""
              />
            </div>
          </>
        );
      case 2:
        return (
          <>
            <div className="flex items-center gap-x-6">
              <Card1
                width={width}
                height={height}
                className={className}
                title={title}
              />
              <Card2 width={width} height={height} className={className} />
            </div>
            <div className="flex items-center gap-x-6">
              <Card3
                width={width}
                height={height}
                className={className}
                title="Project Info"
              />{" "}
              <Card4
                width={width}
                height={height}
                className={className}
                title=""
              />
            </div>
          </>
        );
      case 3:
        return (
          <>
            <div className="flex items-center gap-x-6">
              <Card3
                width={"332px"}
                height={"450px"}
                className={className}
                title="Project Info"
              />
              <Card2 width={width} height={"450px"} className={className} />
            </div>
            <div className="flex items-center gap-x-6">
              {" "}
              <Card1
                width={width}
                height={height}
                className={className}
                title={title}
              />
              <Card4
                width={width}
                height={height}
                className={className}
                title=""
              />
            </div>
          </>
        );
      case 4:
        return (
          <>
            <div className="flex items-center gap-x-6">
              <Card3
                width={"332px"}
                height={"450px"}
                className={className}
                title="Project Info"
              />
              <Card2 width={width} height={"450px"} className={className} />
            </div>
            <div className="flex items-center gap-x-6">
              {" "}
              <Card1
                width={width}
                height={height}
                className={className}
                title={title}
              />
              <Card4
                width={width}
                height={height}
                className={className}
                title=""
              />
            </div>
          </>
        );
      case 5:
        return (
          <>
            <div className="flex items-center gap-x-6">
              {" "}
              <Card4
                width={width}
                height={"450px"}
                className={className}
                title="Phases"
              />
              <Card1
                width={width}
                height={"450px"}
                className={className}
                title={title}
              />
            </div>
            <div className="flex items-center gap-x-6">
              <Card3
                width={"332px"}
                height={"450px"}
                className={className}
                title="Project Info"
              />
              <Card2 width={width} height={"450px"} className={className} />
            </div>
          </>
        );
      case 6:
        return (
          <>
            <div className="flex items-center gap-x-6">
              {" "}
              <Card4
                width={width}
                height={"450px"}
                className={className}
                title="Phases"
              />
              <Card1
                width={width}
                height={"450px"}
                className={className}
                title={title}
              />
            </div>
            <div className="flex items-center gap-x-6">
              <Card3
                width={"332px"}
                height={"450px"}
                className={className}
                title="Project Info"
              />
              <Card2 width={width} height={"450px"} className={className} />
            </div>
          </>
        );
      case 7:
        return <ComponentsDrawer bgColor="bg-[#ffffff]" />;
      default:
        return (
          <>
            <div
              className={`bg-[#fff] my-4 rounded-2xl shadow-md ${className}`}
              style={{ width, height }}
            >
              <div className="p-6">
                {title ? (
                  title
                ) : (
                  <div className="bg-gray-300 w-[146px] h-[17px] rounded-md"></div>
                )}
              </div>
              <div>{body}</div>
            </div>
            <div
              className={`bg-[#fff] my-4 rounded-2xl shadow-md ${className}`}
              style={{ width, height }}
            ></div>
          </>
        );
    }
  };
  return <>{getSketeton()}</>;
};

export default SkeletonStructure;
