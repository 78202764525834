import React, { useEffect, useState } from "react";
import CustomModal from "../../../Core/Common/CustomModal";
import CustomButton from "../../../Core/Common/CustomButton";
import { useAppDispatch, useAppSelector } from "../../../Core/redux/hooks";
import { DatePicker, Tooltip } from "antd";
import PhaseIcon from "../../../Core/svg/PhaseIcon";
import dayjs from "dayjs";
import SprintDetailsModal from "./SprintDetailsModal";
import { useParams, useSearchParams } from "react-router-dom";
import { updateSprint } from "../../../Core/redux/api/scheduleAPI";
import { client } from "../../../Core/utils/axiosClient";

type StartSprintModalProps = {
  visible: boolean;
  onCancel: () => void;
};

const StartSprintModal = ({ visible, onCancel }: StartSprintModalProps) => {
  const dispatch = useAppDispatch();
  const { projectId } = useParams();
  const { newSprint, phases, sprints, selectedSprint } = useAppSelector(
    (state) => state.schedule
  );

  const [searchParams, setSearchParams] = useSearchParams();
  const [sprintDetailsModal, setSpintDetailsModal] = useState(false);
  console.log(sprints, "new sprint in start sprint");

  const [sprintDates, setSprintDates] = useState({
    startDate: dayjs(),
    endDate: null,
  });

  const [activeSprint, setActiveSprint] = useState(
    sprints.find((sprint) => sprint.status == 2)
  );

  useEffect(() => {
    if (visible) {
      getActiveSprint();
    }
  }, [visible]);

  const getActiveSprint = async () => {
    const { data } = await client.get("/schedule/activeSprint", {
      params: { projectId },
    });
    if (data) {
      setActiveSprint(data?.activeSprint);
    }
  };
  return (
    <div>
      <CustomModal
        title={
          <p className="mon-500-16 text-gray-700">{selectedSprint?.name}</p>
        }
        width={"524px"}
        visible={visible}
        body={
          <>
            <div className="px-5 pt-4">
              {activeSprint ? (
                <>
                  <p className="mon-400-14 text-gray-500">
                    Please end the active sprint before starting a new sprint.
                  </p>
                  <div className="mt-4">
                    <p className="mon-500-14 text-gray-700">Active Sprint</p>
                    <div className="w-full h-10 rounded-xl bg-gray-50 border-[1px] border-gray-300 mt-[6px] text-gray-500 px-[14px] py-2">
                      {activeSprint.name}
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="mt-6 mx-1">
                    <div className="mon-500-14 text-gray-700 pb-1.5">
                      Start date
                    </div>
                    <DatePicker
                      placeholder="dd/mm/yyyy"
                      className="w-full h-10 bg-transparent border-gray-300 rounded-xl shadow-xs"
                      suffixIcon={<></>}
                      format="DD MMM YYYY"
                      value={sprintDates.startDate as any}
                      // disabledDate={(current) =>
                      //   ((current && current > (dayjs() as any)) || "") as any
                      // }
                      onChange={(e) =>
                        setSprintDates({
                          ...sprintDates,
                          startDate: e as any,
                        })
                      }
                      //disabled
                    />
                  </div>
                  <div className=" mt-6 mx-1">
                    <div className="mon-500-14 text-gray-700 pb-1.5">
                      End date
                    </div>
                    <DatePicker
                      placeholder="dd/mm/yyyy"
                      className="w-full h-10 bg-transparent border-gray-300 rounded-xl shadow-xs"
                      suffixIcon={<></>}
                      format="DD MMM YYYY"
                      value={sprintDates.endDate as any}
                      onChange={(e) =>
                        setSprintDates({
                          ...sprintDates,
                          endDate: e as any,
                        })
                      }
                      disabledDate={(current) =>
                        ((current && current < (dayjs() as any)) || "") as any
                      }
                    />
                  </div>
                </>
              )}
              <p className="mon-400-12 text-gray-500 mt-6">Ongoing phases</p>
              <div>
                {phases
                  .filter(({ isOngoing }) => isOngoing === 1)
                  .map((item, index) => (
                    <div
                      className={`flex items-center gap-x-2 pt-1.5 ${
                        index === 0 && "mt-2"
                      }`}
                      key={item.id}
                    >
                      <Tooltip title={item.phases} placement="bottom">
                        <div>
                          <PhaseIcon
                            phaseId={+item.phaseId}
                            height="10px"
                            width="10px"
                          />
                        </div>
                      </Tooltip>
                      <p className="mon-500-12 text-gray-900 ml-2 capitalize pt-0.5">
                        {item.phases}
                      </p>
                      <p className="mon-400-10 text-gray-700 pt-0.5">
                        {dayjs(item.startDate).format("DD MMM")} -{" "}
                        {dayjs(item.endDate).format("DD MMM")}
                      </p>
                    </div>
                  ))}
              </div>
            </div>
          </>
        }
        footer={
          <div className="flex justify-between pt-8 pb-4 px-3">
            <CustomButton
              text="Cancel"
              onClick={() => {
                onCancel();
              }}
              className="border-2 border-gray-200 text-black bg-white mon-500-16 "
              height="44px"
              width="49%"
            />
            {activeSprint ? (
              <CustomButton
                text="View Active Sprint"
                onClick={() => {
                  const updatedSearchParams = new URLSearchParams(
                    searchParams.toString()
                  );
                  updatedSearchParams.set(
                    "selectedSprint",
                    activeSprint.id.toString()
                  );
                  const phase = phases.find(
                    (p) => +p.phaseId === +activeSprint.phaseId
                  );
                  if (phase) {
                    updatedSearchParams.set("selectedPhase", phase.phases);
                  }

                  setSearchParams(updatedSearchParams.toString());
                  onCancel();
                }}
                className="mon-500-16 text-white  bg-primary-600 "
                height="44px"
                width="49%"
              />
            ) : (
              <CustomButton
                text="Start Sprint"
                onClick={() => {
                  dispatch(
                    updateSprint({
                      startDate: sprintDates.startDate.format("YYYY-MM-DD"),
                      endDate: sprintDates.endDate.format("YYYY-MM-DD"),
                      status: 2,
                      isOngoing: 1,
                      isCompleted: 0,
                      sprintId: selectedSprint.id,
                      startSprint: true,
                      phases: phases
                        .filter(
                          (p) =>
                            p.isOngoing === 1 && selectedSprint.phaseId != p.id
                        )
                        .map((p) => p.id),
                    })
                  );
                  onCancel();
                }}
                className="mon-500-16 text-white  bg-primary-600 "
                height="44px"
                width="49%"
              />
            )}
          </div>
        }
        onCancel={onCancel}
      />
      {sprintDetailsModal && (
        <SprintDetailsModal
          visible={sprintDetailsModal}
          onCancel={() => setSpintDetailsModal(false)}
          sprint={{
            id: "1256",
            name: "test",
            endDate: "2022-12-12",
            startDate: "2022-10-12",
            goals: [],
          }}
        />
      )}
    </div>
  );
};
export default StartSprintModal;
