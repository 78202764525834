import React, { useEffect, useState } from "react";
import { Link, useParams, useSearchParams } from "react-router-dom";
import CustomModal from "../../Core/Common/CustomModal";
import CustomButton from "../../Core/Common/CustomButton";
import CustomInputWithEdit from "../../Core/Common/CustomInputWithEdit";
import { Breadcrumb, Dropdown, Menu } from "antd";
import { CustomSelectWithInlineSearch } from "../../Core/Common/CustomSelectWithSearch";
import CustomSearch, {
  CustomSearchWithDropDown,
} from "../../Core/Common/CustomSearch";
import CustomAvatar from "../../Core/Common/CustomAvatar";
import { useAppSelector, useAppDispatch } from "../../Core/redux/hooks";
import PhaseIcon from "../../Core/svg/PhaseIcon";
import { createFolder, getAllFolders } from "../../Core/redux/api/vaultAPI";
import { resetVault } from "../../Core/redux/slices/vault.slice";
import CustomPhaseGoalsSelector from "../../Core/Common/CustomPhaseGoalsSelector";
import slugify from "../../Core/utils/slugify";
import * as Yup from "yup";
import { useFormik } from "formik";

const AddFolderModal = ({ visible, onCancel }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { shareAccessDoc, preFilledVaultModalValues } = useAppSelector(
    (state) => state.vault
  );
  const [folderState, setFolderState] = useState({
    folderName: null,
    phaseId: preFilledVaultModalValues?.phaseId || null,
    phaseName: null,
    goalId: preFilledVaultModalValues?.goalId || null,
    goalName: null,
  });

  const { currentPhaseGoals, phases } = useAppSelector(
    (state) => state.schedule
  );

  const { user } = useAppSelector((state) => state.userDetails);
  const dispatch = useAppDispatch();
  const { projectId, "*": splat } = useParams();
  const { userList } = useAppSelector((state) => state.overview);

  const [selectedUsers, setSelectedUsers] = useState(
    []
    // shareAccessDoc ? [...shareAccessDoc.users] : []
  );

  useEffect(() => {
    if (shareAccessDoc.users.length > 0) {
      setSelectedUsers([...shareAccessDoc.users]);
    }
    if (
      preFilledVaultModalValues &&
      preFilledVaultModalValues?.users?.length > 0
    ) {
      setSelectedUsers([...preFilledVaultModalValues.users]);
    }
  }, [shareAccessDoc, preFilledVaultModalValues]);

  const [isHovering, setIsHovering] = useState(false);
  const handleMouseOver = () => {
    setIsHovering(true);
  };

  const handleMouseOut = () => {
    setIsHovering(false);
  };
  const formik = useFormik({
    initialValues: {
      name: null,
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required("Please enter the name of the folder")
        .typeError("Please enter the name of the folder"),
    }),
    onSubmit: (values) => {
      onSubmit();
    },
  });

  const onSubmit = async () => {
    const { folderName, phaseId, goalId } = folderState;
    const payload: {
      folderId?: string;
      folderName: string;
      phaseId: string;
      projectId: string;
      userId: number;
      goalId: string;
      users: any[];
      notificationId?: string;
    } = {
      folderName: folderName,
      phaseId: phaseId,
      projectId: projectId,
      userId: user.id,
      goalId: goalId,
      users: selectedUsers,
    };

    if (preFilledVaultModalValues.notificationId) {
      payload.notificationId = preFilledVaultModalValues.notificationId;
    }
    const queryObject: { projectId: string; folderId?: string } = { projectId };

    if (splat && splat.length > 0) {
      const folderId = splat.split("-");
      payload.folderId = folderId[folderId.length - 1];
      queryObject.folderId = folderId[folderId.length - 1];
    }

    await dispatch(createFolder(payload)).then(() => onCancel());

    dispatch(
      resetVault([
        "addFolderModal",
        "shareAccessDoc",
        "preFilledVaultModalValues",
      ])
    );
    dispatch(getAllFolders(queryObject));
    setSelectedUsers([]);
    onCancel();
  };

  const pathSnippets =
    splat && splat.length > 0 ? splat.split("/").filter((i) => i) : [];

  const extraBreadcrumbItems = pathSnippets.map((_, index) => {
    const url = `/vault/${projectId}/${pathSnippets
      .slice(0, index + 1)
      .join("/")}`;
    return _.split("-")[0];
  });

  const breadcrumbItems = ["Vault"].concat(extraBreadcrumbItems);

  return (
    <CustomModal
      title={
        <div className="flex gap-x-1">
          {breadcrumbItems.map((item, index) => {
            return (
              <>
                <span className="mon-400-12 text-gray-500">{item}</span>

                <img
                  className="w-4 h-4"
                  src="/images/icons/chevron-right.svg"
                />
              </>
            );
          })}

          <span className="mon-500-12 text-gray-700">Folder</span>
        </div>
      }
      width={"704px"}
      visible={visible}
      body={
        <div className="max-h-[48.189386056191466vh] overflow-y-scroll">
          <div className="p-4">
            <CustomInputWithEdit
              label="Name of folder"
              placeholder="Add a name"
              {...formik.getFieldProps("name")}
              onChange={(e) => {
                if (e.target.value.includes("-")) return;
                setFolderState({ ...folderState, folderName: e.target.value });
                formik.setFieldValue("name", e.target.value);
              }}
              value={folderState.folderName}
              formikHook={formik}
            />
            <CustomPhaseGoalsSelector
              phaseId={folderState.phaseId}
              goalId={folderState.goalId}
              // onLoadingChange={(isLoading) => setShowSpinner(isLoading)}
              onChange={(phaseGoalObj) =>
                setFolderState({ ...folderState, ...phaseGoalObj })
              }
            />
            <div className="mon-500-14 text-gray-700 mt-9">
              <div className="flex items-center">
                <img src="/images/icons/users-03.svg" />
                <p className="mon-500-14 text-gray-700 ml-2">Share access</p>
              </div>
              <p className="mt-3 mon-400-14 text-gray-400">
                Share with your team members and start a thread for easy
                collaboration.
                <br />
                All your collaborators will automatically get added when you
                start a thread.
              </p>
              <div className="mt-9">
                <CustomSearchWithDropDown
                  style={{
                    border: "1px solid #C0CBD7",
                    height: "42px",
                  }}
                  placeholder="Search team members"
                  options={userList
                    .filter(({ userId }) => userId !== user.id)
                    .filter(
                      (u) =>
                        selectedUsers?.findIndex(
                          (index) => index.userId === u.userId
                        ) === -1 && u.userId !== u.id
                    )
                    .map((u) => {
                      return {
                        id: u.userId,
                        value: u.userId,
                        label: `${u.firstname} ${u.lastname}`,
                        render: (value) => (
                          <div
                            className="flex items-center -mt-0.5"
                            //key={u.userId}
                            onClick={() =>
                              setSelectedUsers((oldList) => [
                                ...oldList,
                                {
                                  userId: u.userId,
                                  isOwner: 0,
                                  canEdit: 0,
                                  canView: 1,
                                },
                              ])
                            }
                          >
                            <CustomAvatar
                              title={u.firstname ? u.firstname : "User"}
                              src={u.profilePicture}
                            />
                            <p className="ml-2 mon-400-12 text-gray-700 capitalize">
                              {u.firstname} {u.lastname}
                            </p>
                          </div>
                        ),
                      };
                    })}
                />
              </div>
            </div>
            <div className="mt-9 px-3">
              <div className="flex justify-between items-center py-2.5">
                <div className="flex items-center">
                  <CustomAvatar
                    title={`${user.firstName}`}
                    size={24}
                    src={user.profilePicture}
                  />
                  <p className="ml-2 mon-400-12 text-gray-700 capitalize">
                    {user.firstName} {user.lastName}
                  </p>
                </div>
                <p className="mon-400-12 text-gray-500">Owner</p>
              </div>
              {userList.map((user) => {
                if (
                  selectedUsers?.findIndex((u) => user.userId === u.userId) !==
                  -1
                ) {
                  return (
                    <div
                      className="flex justify-between items-center py-2.5"
                      key={user.id}
                    >
                      <div className="flex items-center">
                        <CustomAvatar
                          title={`${user.firstname}`}
                          size={24}
                          src={user.profilePicture}
                        />
                        <p className="ml-2 mon-400-12 text-gray-700 capitalize">
                          {user.firstname} {user.lastname}
                        </p>
                      </div>
                      <div className="flex justify-center items-center">
                        <p className="mon-400-12 text-gray-500 mr-4">
                          {selectedUsers.find((u) => u.userId === user.userId)
                            .isOwner === 1
                            ? "Owner"
                            : selectedUsers.find(
                                (u) => u.userId === user.userId
                              ).canEdit
                            ? "Can edit"
                            : "Can view"}
                        </p>
                        <Dropdown
                          overlay={
                            <Menu className="w-[200px] rounded-lg custom-menu-dropdown  shadow-dropdown">
                              <Menu.Item
                                className=" py-2.5 px-4 rounded-lg"
                                onClick={() =>
                                  setSelectedUsers((oldList) => [
                                    ...oldList.map((old) => {
                                      if (old.userId === user.userId) {
                                        return {
                                          ...old,
                                          canEdit: 1,
                                          canView: 1,
                                        };
                                      }
                                      return old;
                                    }),
                                  ])
                                }
                              >
                                <span className="font-normal mb-2 text-gray-900 whitespace-nowrap text-sm">
                                  Can edit
                                </span>
                              </Menu.Item>
                              <Menu.Item
                                className=" py-2.5 px-4 rounded-lg border-b-[1px]"
                                onClick={() =>
                                  setSelectedUsers((oldList) => [
                                    ...oldList.map((old) => {
                                      if (old.userId === user.userId) {
                                        return {
                                          ...old,
                                          canEdit: 0,
                                          canView: 1,
                                        };
                                      }
                                      return old;
                                    }),
                                  ])
                                }
                              >
                                <span className="font-normal mb-2 text-gray-900 whitespace-nowrap text-sm">
                                  Can view
                                </span>
                              </Menu.Item>
                              <Menu.Item
                                className=" py-2.5 px-4 rounded-lg"
                                onClick={() =>
                                  setSelectedUsers((oldList) => [
                                    ...oldList.filter(
                                      (old) => old.userId !== user.userId
                                    ),
                                  ])
                                }
                                onMouseOver={handleMouseOver}
                                onMouseOut={handleMouseOut}
                              >
                                <span
                                  className={`font-normal mb-2  whitespace-nowrap text-sm ${
                                    isHovering
                                      ? "text-gray-900"
                                      : "text-error-600"
                                  }`}
                                >
                                  Remove access
                                </span>
                              </Menu.Item>
                            </Menu>
                          }
                          trigger={["click"]}
                        >
                          <button>
                            <img
                              src="/images/icons/chevron-down-gray.svg"
                              className="w-5 h-5"
                            />
                          </button>
                        </Dropdown>
                      </div>
                    </div>
                  );
                }
              })}
            </div>
          </div>
        </div>
      }
      footer={
        <div className="flex justify-end py-4 gap-x-3 px-3 shadow-upward">
          <CustomButton
            text="Cancel"
            onClick={onCancel}
            className="border border-gray-200 text-gray-700 bg-white mon-500-14"
            height="40px"
            width="81px"
          />
          <CustomButton
            text="Create"
            onClick={() => formik.handleSubmit()}
            className="mon-500-14 text-white bg-primary-600 "
            height="40px"
            width="132px"
          />
        </div>
      }
      onCancel={onCancel}
    />
  );
};

export default AddFolderModal;
