import React, { useEffect, useState } from "react";
import CustomModal from "../../Core/Common/CustomModal";
import CustomButton from "../../Core/Common/CustomButton";
import { Dropdown, Menu } from "antd";
import { CustomSelectWithInlineSearch } from "../../Core/Common/CustomSelectWithSearch";
import CustomSearch, {
  CustomSearchWithDropDown,
} from "../../Core/Common/CustomSearch";
import CustomAvatar from "../../Core/Common/CustomAvatar";
import CustomDragAndDrop from "../../Core/Common/CustomDragAndDrop";
import CustomPhaseGoalsSelector from "../../Core/Common/CustomPhaseGoalsSelector";
import CustomSelect from "../../Core/Common/CustomSelect";
import { useAppSelector, useAppDispatch } from "../../Core/redux/hooks";
import { useParams } from "react-router-dom";
import { createFile, getAllFiles } from "../../Core/redux/api/vaultAPI";
import { resetVault } from "../../Core/redux/slices/vault.slice";
import CustomTreeSelect from "../../Core/Common/CustomTreeSelect";
import { removeDuplicates } from "../../Core/utils";
import { client } from "../../Core/utils/axiosClient";

const AddFileModal = ({ visible, onCancel }) => {
  const [uploadedFileList, setUploadedFileList] = useState([]);
  const { projectId, "*": splat } = useParams();
  const dispatch = useAppDispatch();
  const { folders, preFilledVaultModalValues } = useAppSelector(
    (state) => state.vault
  );
  const [fileState, setFileState] = useState({
    phaseId: preFilledVaultModalValues?.phaseId || null,
    goalId: null,
    folderId: null,
  });

  const { userList } = useAppSelector((state) => state.overview);
  const { user } = useAppSelector((state) => state.userDetails);
  const { shareAccessDoc } = useAppSelector((state) => state.vault);

  const [selectedUsers, setSelectedUsers] = useState(
    []
    // shareAccessDoc ? [...shareAccessDoc.users] : []
  );

  useEffect(() => {
    if (shareAccessDoc.users && shareAccessDoc.users.length > 0) {
      setSelectedUsers([...shareAccessDoc.users]);
    }
    if (
      preFilledVaultModalValues &&
      preFilledVaultModalValues?.users?.length > 0
    ) {
      setSelectedUsers([...preFilledVaultModalValues.users]);
    }
  }, [shareAccessDoc, preFilledVaultModalValues]);

  const [isHovering, setIsHovering] = useState(false);
  const handleMouseOver = () => {
    setIsHovering(true);
  };

  const handleMouseOut = () => {
    setIsHovering(false);
  };

  const onSubmit = async () => {
    const { phaseId, goalId, folderId } = fileState;
    console.log(fileState, "file state");

    const payload = {
      phaseId: phaseId,
      goalId: goalId,
      files: uploadedFileList,
      projectId: projectId,
      folderId: folderId,
      users: selectedUsers,
      notificationId: null,
    };

    if (preFilledVaultModalValues.notificationId) {
      payload.notificationId = preFilledVaultModalValues.notificationId;
    }

    console.log(payload);

    const queryObject: { projectId: string; folderId?: string } = { projectId };

    if (splat.length > 0) {
      const splatFolderId = splat.split("-");
      folderId !== null
        ? folderId
        : (payload.folderId = splatFolderId[splatFolderId.length - 1]);
      queryObject.folderId = splatFolderId[splatFolderId.length - 1];
    }

    await dispatch(createFile(payload as any)).then(() => onCancel());

    dispatch(resetVault(["addFileModal", "shareAccessDoc"]));
    dispatch(getAllFiles(queryObject));
    setSelectedUsers([]);
    onCancel();
  };

  const pathSnippets = splat.split("/").filter((i) => i);

  const extraBreadcrumbItems = pathSnippets.map((_, index) => {
    const url = `/vault/${projectId}/${pathSnippets
      .slice(0, index + 1)
      .join("/")}`;
    return _.split("-")[0];
  });

  const breadcrumbItems = ["Vault"].concat(extraBreadcrumbItems);

  return (
    <CustomModal
      title={
        <div className="flex gap-x-1">
          {breadcrumbItems.map((item, index) => {
            return (
              <>
                <span className="mon-400-12 text-gray-500">{item}</span>

                <img
                  className="w-4 h-4"
                  src="/images/icons/chevron-right.svg"
                />
              </>
            );
          })}
          <span className="mon-500-12 text-gray-700">File</span>
        </div>
      }
      width={"704px"}
      visible={visible}
      body={
        <div className="max-h-[48.189386056191466vh] overflow-y-scroll">
          <div className="p-4">
            <CustomDragAndDrop onUploaded={setUploadedFileList} />

            <CustomPhaseGoalsSelector
              phaseId={fileState.phaseId}
              goalId={fileState.goalId}
              // onLoadingChange={(isLoading) => setShowSpinner(isLoading)}
              onChange={(phaseGoalObj) =>
                setFileState({ ...fileState, ...phaseGoalObj })
              }
            />

            <div className="mon-500-14 text-gray-700 mt-9">
              Folder (optional)
              {/* <div className="mt-1.5">
                <div className="mt-1.5 w-[336px]">
                  <CustomSelect
                    value={fileState.folderId?.toString()}
                    options={folders.map((item) => {
                      return {
                        id: item.id?.toString(),
                        title: item.folderName,
                        //  pId: item.id?.toString(),
                        render: (value) => (
                          <div
                            className="flex items-center -mt-0.5"
                            onClick={() =>
                              setFileState({
                                ...fileState,
                                // folderName: item.folderName,
                                folderId: item.id?.toString(),
                              })
                            }
                          >
                            <img
                              src="/images/icons/folder-fill.svg"
                              className="h-4 w-4"
                            />
                            <p className="mon-400-14 pl-2 text-gray-900 capitalize ">
                              {item.folderName}
                            </p>
                          </div>
                        ),
                      };
                    })}
                    placeholder="Select folder"
                  />
                </div>
              </div> */}
              <div className="mt-1.5">
                <div className="mt-1.5 w-[336px]">
                  <CustomTreeSelect
                    placeholder="Select folder"
                    options={removeDuplicates(folders, "id").map((item, i) => {
                      return {
                        id: item.id?.toString(),
                        pId: 0,
                        value: item.id?.toString(),
                        isLeaf: item.hasChildFolder ? false : true,
                        title: (
                          <div
                            className="flex items-center -mt-0.5"
                            onClick={() =>
                              setFileState({
                                ...fileState,
                                // folderName: item.folderName,
                                folderId: item.id?.toString(),
                              })
                            }
                          >
                            <img
                              src="/images/icons/folder-fill.svg"
                              className="h-4 w-4"
                            />
                            <p className="mon-400-14 pl-2 text-gray-900 capitalize ">
                              {item.folderName}
                            </p>
                          </div>
                        ),
                      };
                    })}
                    onLoadData={async (
                      { id: parentChildId },
                      updateTreeData
                    ) => {
                      const chilFolders = await client.get(
                        `/repository/folder`,
                        {
                          params: { folderId: parentChildId, projectId },
                        }
                      );

                      if (chilFolders.data?.result?.length > 0) {
                        const data = removeDuplicates(
                          chilFolders.data?.result,
                          "id"
                        ).map((item) => {
                          return {
                            id: item.id?.toString(),
                            pId: parentChildId,
                            value: item.id?.toString(),
                            isLeaf: item.hasChildFolder ? false : true,
                            title: (
                              <div
                                className="flex items-center -mt-0.5"
                                onClick={() =>
                                  setFileState({
                                    ...fileState,
                                    // folderName: item.folderName,
                                    folderId: item.id?.toString(),
                                  })
                                }
                              >
                                <img
                                  src="/images/icons/folder-fill.svg"
                                  className="h-4 w-4"
                                />
                                <p className="mon-400-14 pl-2 text-gray-900 capitalize ">
                                  {item.folderName}
                                </p>
                              </div>
                            ),
                          };
                        });

                        updateTreeData((oldTreeData) => {
                          const newData = [...oldTreeData, ...data];
                          console.log(newData, "newData");
                          return newData;
                        });
                      }
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="mon-500-14 text-gray-700 mt-9">
              <div className="flex items-center">
                <img src="/images/icons/users-03.svg" />
                <p className="mon-500-14 text-gray-700 ml-2">Share access</p>
              </div>
              <p className="mt-3 mon-400-14 text-gray-400">
                Share with your team members and start a thread for easy
                collaboration.
                <br />
                All your collaborators will automatically get added when you
                start a thread.
              </p>
              <div className="mt-9">
                <CustomSearchWithDropDown
                  style={{
                    border: "1px solid #C0CBD7",
                    height: "42px",
                  }}
                  placeholder="Search team members"
                  options={userList
                    .filter(({ userId }) => userId !== user.id)
                    .filter(
                      (u) =>
                        selectedUsers?.findIndex(
                          (index) => index.userId === u.userId
                        ) === -1 && u.userId !== u.id
                    )
                    .map((u) => {
                      return {
                        id: u.userId,
                        value: u.userId,
                        label: `${u.firstname} ${u.lastname}`,
                        render: (value) => (
                          <div
                            className="flex items-center -mt-0.5"
                            //key={u.userId}
                            onClick={() =>
                              setSelectedUsers((oldList) => [
                                ...oldList,
                                {
                                  userId: u.userId,
                                  isOwner: 0,
                                  canEdit: 0,
                                  canView: 1,
                                },
                              ])
                            }
                          >
                            <CustomAvatar
                              title={u.firstname ? u.firstname : "User"}
                              src={u.profilePicture}
                            />
                            <p className="ml-2 mon-400-12 text-gray-700 capitalize">
                              {u.firstname} {u.lastname}
                            </p>
                          </div>
                        ),
                      };
                    })}
                />
              </div>
            </div>
            <div className="mt-9 px-3">
              <div className="flex justify-between items-center py-2.5">
                <div className="flex items-center">
                  <CustomAvatar
                    title={`${user.firstName}`}
                    size={24}
                    src={user.profilePicture}
                  />
                  <p className="ml-2 mon-400-12 text-gray-700 capitalize">
                    {user.firstName} {user.lastName}
                  </p>
                </div>
                <p className="mon-400-12 text-gray-500">Owner</p>
              </div>
              {userList.map((user) => {
                if (
                  selectedUsers?.findIndex((u) => user.userId === u.userId) !==
                  -1
                ) {
                  return (
                    <div
                      className="flex justify-between items-center py-2.5"
                      key={user.id}
                    >
                      <div className="flex items-center">
                        <CustomAvatar
                          title={`${user.firstname}`}
                          size={24}
                          src={user.profilePicture}
                        />
                        <p className="ml-2 mon-400-12 text-gray-700 capitalize">
                          {user.firstname} {user.lastname}
                        </p>
                      </div>
                      <div className="flex justify-center items-center">
                        <p className="mon-400-12 text-gray-500 mr-4">
                          {selectedUsers.find((u) => u.userId === user.userId)
                            .isOwner === 1
                            ? "Owner"
                            : selectedUsers.find(
                                (u) => u.userId === user.userId
                              ).canEdit
                            ? "Can edit"
                            : "Can view"}
                        </p>
                        <Dropdown
                          overlay={
                            <Menu className="w-[200px] rounded-lg custom-menu-dropdown  shadow-dropdown">
                              <Menu.Item
                                className=" py-2.5 px-4 rounded-lg"
                                onClick={() =>
                                  setSelectedUsers((oldList) => [
                                    ...oldList.map((old) => {
                                      if (old.userId === user.userId) {
                                        return {
                                          ...old,
                                          canEdit: 1,
                                          canView: 1,
                                        };
                                      }
                                      return old;
                                    }),
                                  ])
                                }
                              >
                                <span className="font-normal mb-2 text-gray-900 whitespace-nowrap text-sm">
                                  Can edit
                                </span>
                              </Menu.Item>
                              <Menu.Item
                                className=" py-2.5 px-4 rounded-lg border-b-[1px]"
                                onClick={() =>
                                  setSelectedUsers((oldList) => [
                                    ...oldList.map((old) => {
                                      if (old.userId === user.userId) {
                                        return {
                                          ...old,
                                          canEdit: 0,
                                          canView: 1,
                                        };
                                      }
                                      return old;
                                    }),
                                  ])
                                }
                              >
                                <span className="font-normal mb-2 text-gray-900 whitespace-nowrap text-sm">
                                  Can view
                                </span>
                              </Menu.Item>
                              <Menu.Item
                                className=" py-2.5 px-4 rounded-lg"
                                onClick={() =>
                                  setSelectedUsers((oldList) => [
                                    ...oldList.filter(
                                      (old) => old.userId !== user.userId
                                    ),
                                  ])
                                }
                                onMouseOver={handleMouseOver}
                                onMouseOut={handleMouseOut}
                              >
                                <span
                                  className={`font-normal mb-2  whitespace-nowrap text-sm ${
                                    isHovering
                                      ? "text-gray-900"
                                      : "text-error-600"
                                  }`}
                                >
                                  Remove access
                                </span>
                              </Menu.Item>
                            </Menu>
                          }
                          trigger={["click"]}
                        >
                          <button>
                            <img
                              src="/images/icons/chevron-down-gray.svg"
                              className="w-5 h-5"
                            />
                          </button>
                        </Dropdown>
                      </div>
                    </div>
                  );
                }
              })}
            </div>
          </div>
        </div>
      }
      footer={
        <div className="flex justify-end py-4 gap-x-3 px-3 shadow-upward">
          <CustomButton
            text="Cancel"
            onClick={onCancel}
            className="border border-gray-200 text-gray-700 bg-white mon-500-14"
            height="40px"
            width="81px"
          />
          <CustomButton
            text="Upload"
            onClick={onSubmit}
            className="mon-500-14 text-white bg-primary-600 "
            height="40px"
            width="132px"
          />
        </div>
      }
      onCancel={onCancel}
    />
  );
};

export default AddFileModal;
