import React, { useState } from "react";
import CustomButton from "../../../Core/Common/CustomButton";
import CustomInputWithEdit from "../../../Core/Common/CustomInputWithEdit";
import CustomModal from "../../../Core/Common/CustomModal";
import MenuDropdown from "../../Conversations/Components/MenuDropdown";
import { Dropdown, Menu, Tabs, Input } from "antd";
import CustomAvatar from "../../../Core/Common/CustomAvatar";
import { PriorityIconWithLabel } from "../../../Core/svg/PriorityIcon";

import CustomSelect from "../../../Core/Common/CustomSelect";
import { CustomSelectWithInlineSearch } from "../../../Core/Common/CustomSelectWithSearch";
import PhaseIcon from "../../../Core/svg/PhaseIcon";
import CustomDragAndDrop from "../../../Core/Common/CustomDragAndDrop";
import {
  resetSchedule,
  updateSchedule,
} from "../../../Core/redux/slices/schedule.slice";
import { useAppDispatch, useAppSelector } from "../../../Core/redux/hooks";
import { useParams } from "react-router-dom";
import CustomBadge from "../../../Core/Common/CustomBadge";
import {
  createGoal,
  getGoalsBySprint,
  updateGoal,
  updateProgress,
} from "../../../Core/redux/api/scheduleAPI";
import Comments from "./Comments";
import { useEffect } from "react";
import { getAllLinks } from "../../../Core/redux/api/vaultAPI";
import CustomInputWithSaveAndEdit from "../../../Core/Common/CustomInputWithSaveAndEdit";
import InfoModal, {
  CategoryDropDown,
  ComponentDropdown,
  CustomLeft,
  DependencyDropDown,
  EnvironmentInput,
  RowLayout,
  StatusDropdown,
  UploadAndAttachments,
  UserDropdown,
} from "./TaskResuable";
import TaskPhaseDropDown from "./TaskPhaseDropDown";
import TaskPriorityDropDown from "./TaskPriorityDropDown";
import TimeInput from "./TimeInput";
import * as Yup from "yup";
import { useFormik } from "formik";

type NewBugModalProps = {
  visible: boolean;
  onCancel: () => void;
};

const NewBugModal = ({ visible, onCancel }: NewBugModalProps) => {
  const dispatch = useAppDispatch();
  const { userList } = useAppSelector((state) => state.overview);
  const { user } = useAppSelector((state) => state.userDetails);
  const { links } = useAppSelector((state) => state.vault);
  const { selectedSprint, phases, selectedBug, currentSelectedPhase } =
    useAppSelector((state) => state.schedule);
  const { projectId } = useParams();
  const { TabPane } = Tabs;
  const [currentTab, setCurrentTab] = useState("description");
  const [currentDropDown, setCurrentDropDown] = useState("");
  const [uploadedFileList, setUploadedFileList] = useState([]);

  const assignee = userList.find(
    (user) => user.userId === selectedBug?.assigneeId
  );

  const reporter = userList.find(
    (user) => user.userId === selectedBug?.reporterId
  );

  const onAttachmentClick = () => dispatch(resetSchedule(["newBugModal"]));

  const formik = useFormik({
    initialValues: {
      name: null,
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required("Please enter the title of the bug")
        .min(1)
        .typeError("Please enter the title of the bug"),
    }),
    onSubmit: async (values) => {
      if (!selectedBug) {
        createBug();
      } else {
        await dispatch(
          updateGoal({ name: bug.bugName, goalId: selectedBug.id })
        );
      }
      // createBug();
    },
  });

  const editBug = selectedBug
    ? {
        ...selectedBug,
        bugName: selectedBug.name,
        assigneeId: selectedBug.assigneeId,
        reporterId: selectedBug.reporterId,
        assignee: `${
          assignee?.firstname === null ? "User" : assignee?.firstname
        } ${assignee?.lastname === null ? "User" : assignee?.lastname}`,
        reporter: `${
          reporter?.firstname === null ? "User" : reporter?.firstname
        } ${reporter?.lastname === null ? "User" : reporter?.lastname}`,
        statusId: selectedBug.statusId,
        priorityId: selectedBug.priorityId,
        componentId: selectedBug.componentId,
        componentName: selectedBug.componentName,
        assigneeProfilePicture: assignee?.profilePicture,
        reporterProfilePicture: reporter?.profilePicture,
        dependencyId: selectedBug.dependencyId,
        dependencyName:
          selectedBug.dependencyId === 1
            ? "Web - Overview - UI Issues"
            : selectedBug.dependencyId === 2
            ? "Web - Overview - Dropdown missing"
            : "API incorrect",
        categoryId: selectedBug.categoryId,
        categoryName:
          selectedBug.categoryId === 1
            ? "Functional Bug"
            : selectedBug.categoryId === 2
            ? "UI Bug"
            : "API Bug",
        environment: selectedBug.environment,
        description: selectedBug.description,
        comments: selectedBug.comments,
        phaseName: "",
        attachments: selectedBug?.attachments
          ? JSON.parse(selectedBug?.attachments)
          : [],
      }
    : {
        goalType: 3,
        bugName: "",
        assigneeId: user.id,
        reporterId: user.id,
        assignee: `${user.firstName === null ? "User" : user.firstName} ${
          user.lastName === null ? "User" : user.lastName
        }`,
        reporter: `${user.firstName === null ? "User" : user.firstName} ${
          user.lastName === null ? "User" : user.lastName
        }`,
        statusId: 1,
        priorityId: 1,
        phaseId: currentSelectedPhase.id,
        phaseName: "Ideation",
        componentId: "",
        assigneeProfilePicture: user.profilePicture,
        reporterProfilePicture: user.profilePicture,
        dependencyId: 1,
        dependencyName: "",
        categoryId: 1,
        categoryName: "",
        environment: "",
        description: "",
        comments: "",
        componentName: "",
        attachments: [],
        estimatedTime: "",
        timeLogged: "",
      };

  const [bug, setBug] = useState(editBug);

  const Dependency = () => {
    const dependency = [
      {
        id: 1,
        name: "Web - Overview - UI Issues",
      },
      {
        id: 2,
        name: "Web - Overview - Dropdown missing",
      },
      {
        id: 3,
        name: "API incorrect",
      },
    ];
    return (
      <div className="flex items-center cursor-pointer">
        <div className="flex items-center mr-[92px]">
          <img src="/images/icons/link-02.svg" className="w-4 h-4" />
          <p className="mon-400-12 text-gray-500 pl-2">Dependency</p>
        </div>
        <Dropdown
          getPopupContainer={(trigger) => trigger.parentElement}
          className=""
          overlay={
            <Menu className="w-[320px] rounded-lg custom-menu-dropdown  shadow-dropdown">
              {dependency.map(({ id, name }) => (
                <Menu.Item
                  className="py-2.5 px-4 rounded-t-lg"
                  key={id}
                  onClick={() =>
                    setBug({ ...bug, dependencyId: id, dependencyName: name })
                  }
                >
                  <div className="flex items-center">
                    <img src="/images/icons/bug.svg" className="w-4 h-4" />
                    <span className="mon-400-12 ml-2 whitespace-nowrap text-gray-700">
                      {name}
                    </span>
                  </div>
                </Menu.Item>
              ))}
            </Menu>
          }
          trigger={["click"]}
        >
          <div className="cursor-pointer">
            {bug.dependencyName === "" ? (
              <div className="flex items-center gap-x-2 cursor-pointer">
                <p className="mon-400-12 text-gray-700">Add dependency</p>
                <img
                  src="/images/icons/chevron-down-gray.svg"
                  className="w-3 h-3"
                />
              </div>
            ) : (
              <div className="flex justify-center items-center">
                <img src="/images/icons/bug.svg" className="w-4 h-4" />
                <p className="mon-400-12 ml-2 whitespace-nowrap text-gray-700">
                  {bug.dependencyName}
                </p>
              </div>
            )}
          </div>
        </Dropdown>
      </div>
    );
  };

  const createBug = async () => {
    const payload = {
      sprintId: selectedSprint.id,
      projectId: projectId,
      phaseId: bug.phaseId,
      names: [bug.bugName],
      isCompleted: 0,
      statusId: bug.statusId,
      priorityId: bug.priorityId,
      goalType: bug.goalType,
      assigneeId: bug.assigneeId,
      reporterId: bug.reporterId,
      description: bug.description,
      dependency_id: bug.dependencyId,
      category_id: bug.categoryId,
      environment: bug.environment,
      estimatedTime: bug.estimatedTime,
      timeLogged: bug.timeLogged,
      // attachment: uploadedFileList,
      attachments: JSON.stringify(bug.attachments),
    };

    await dispatch(createGoal(payload)).then(() => onCancel());
    dispatch(resetSchedule(["newBugModal"]));
    dispatch(getGoalsBySprint({ projectId, sprintId: selectedSprint.id }));
    dispatch(
      updateProgress({
        phaseId: currentSelectedPhase.id,
        projectId,
      })
    );
  };

  useEffect(() => {
    dispatch(getAllLinks({ projectId }));
  }, []);

  const linkData = [
    {
      id: 1,
      name: "Link 1",
    },
    {
      id: 2,
      name: "Link 2",
    },
    {
      id: 3,
      name: "Link 3",
    },
  ];

  const componentDetails = [
    {
      id: 1,
      title: "Documents",
      values: [
        {
          id: 1,
          name: "OneNote",
        },
        {
          id: 2,
          name: "Evernote",
        },
      ],
    },
    {
      id: 2,
      title: "Frontend",
      values: [
        {
          id: 1,
          name: "React",
        },
        {
          id: 2,
          name: "Next",
        },
      ],
    },
    {
      id: 3,
      title: "Backend",
      values: [
        {
          id: 1,
          name: "Node",
        },
        {
          id: 2,
          name: "Express",
        },
      ],
    },
  ];

  return (
    <CustomModal
      title={
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <p className="mon-400-12 pl-1 text-gray-500">
              {selectedSprint.name}
            </p>
            <img
              src="/images/icons/chevron-right.svg"
              className="w-4 h-4 ml-2"
            />
            <p className="flex mon-500-12 text-gray-700 pl-2">{bug.bugName}</p>
          </div>
        </div>
      }
      width="704px"
      visible={visible}
      menuDropdown={
        selectedBug ? (
          <MenuDropdown
            elements={[
              {
                id: 1,
                image: "/images/icons/trash.svg",
                text: "Delete",
                textColor: "text-error-600",
                onclick: () =>
                  dispatch(
                    updateSchedule({
                      key: "deleteBugModal",
                      value: {
                        visible: true,
                        statusId: selectedBug.statusId,
                        goalId: selectedBug.id,
                      },
                    })
                  ),
              },
            ]}
            customFunc={undefined}
          />
        ) : (
          <></>
        )
      }
      // height="80vh"
      body={
        <div className="pt-4 pl-4 max-h-[55vh] overflow-y-scroll">
          <CustomInputWithSaveAndEdit
            width="98%"
            inputClassName="mon-600-14 text-gray-900"
            placeholder="Bug summary"
            {...formik.getFieldProps("name")}
            onChange={(e) => {
              setBug({ ...bug, bugName: e.target.value });
              formik.setFieldValue("name", e.target.value);
            }}
            onOk={async () => {
              if (selectedBug) {
                formik.handleSubmit();
              }
            }}
            value={bug.bugName}
            formikHook={formik}
          />
          <RowLayout
            customLeft={
              <CustomLeft icon="/images/icons/users.svg" text="Assignee" />
            }
            className="mt-4"
          >
            <UserDropdown
              fieldName="Assignee"
              task={bug}
              setTask={setBug}
              userList={userList}
              onEdit={async (id) => {
                if (selectedBug) {
                  await dispatch(
                    updateGoal({
                      assigneeId: id,
                      goalId: selectedBug.id,
                    })
                  );
                }
              }}
            />
          </RowLayout>

          <RowLayout
            customLeft={
              <CustomLeft icon="/images/icons/users.svg" text="Reporter" />
            }
            className="mt-4"
          >
            <UserDropdown
              fieldName="Reporter"
              task={bug}
              setTask={setBug}
              userList={userList}
              onEdit={async (id) => {
                if (selectedBug) {
                  await dispatch(
                    updateGoal({
                      reporterId: id,
                      goalId: selectedBug.id,
                    })
                  );
                }
              }}
            />
          </RowLayout>
          <RowLayout
            customLeft={
              <CustomLeft icon="/images/icons/tag-01.svg" text="Status" />
            }
            className="mt-4"
          >
            <StatusDropdown
              setStatusId={async (e) => {
                setBug({ ...bug, statusId: e.statusId });
                if (selectedBug) {
                  await dispatch(
                    updateGoal({
                      ...e,
                      goalId: selectedBug.id,
                    })
                  );
                  if (e.statusId == 3) {
                    dispatch(
                      updateProgress({
                        phaseId: currentSelectedPhase.id,
                        projectId,
                      })
                    );
                  }
                }
              }}
              statusId={bug.statusId}
              task={bug}
            />
          </RowLayout>
          <RowLayout
            customLeft={
              <CustomLeft icon="/images/icons/flag-03.svg" text="Priority" />
            }
            className="mt-2"
          >
            <div className="min-w-[218px]">
              <TaskPriorityDropDown
                task={bug}
                setTask={async (e) => {
                  setBug({ ...bug, priorityId: e });
                  if (selectedBug) {
                    await dispatch(
                      updateGoal({
                        priorityId: e,
                        goalId: selectedBug.id,
                      })
                    );
                  }
                }}
                currentDropDown={currentDropDown}
                setCurrentDropDown={setCurrentDropDown}
              />
            </div>
          </RowLayout>

          <RowLayout
            customLeft={
              <CustomLeft icon="/images/icons/circle-cut.svg" text="Phase" />
            }
            className=""
          >
            <TaskPhaseDropDown
              currentDropDown={currentDropDown}
              task={bug}
              setTask={setBug}
              setCurrentDropDown={setCurrentDropDown}
              phases={phases}
              onEdit={async (e) => {
                if (selectedBug) {
                  await dispatch(
                    updateGoal({
                      phaseId: e,
                      goalId: selectedBug.id,
                    })
                  );
                }
              }}
            />
          </RowLayout>
          <RowLayout
            customLeft={
              <CustomLeft icon="/images/icons/link-02.svg" text="Dependency">
                <InfoModal
                  frostyMessage={
                    <p className="mon-600-12 text-primary-600 pl-1">
                      Link other bug(s) that needs to be taken care in order to
                      solve the current bug.
                    </p>
                  }
                />
              </CustomLeft>
            }
            className=""
          >
            <DependencyDropDown
              currentDropDown={currentDropDown}
              task={bug}
              setTask={setBug}
              setCurrentDropDown={setCurrentDropDown}
              onEdit={async (e) => {
                if (selectedBug) {
                  await dispatch(
                    updateGoal({
                      dependencyId: e.dependencyId,
                      goalId: selectedBug.id,
                    })
                  );
                }
              }}
              //phases={phases}
            />
          </RowLayout>
          <RowLayout
            customLeft={
              <CustomLeft icon="/images/icons/category.svg" text=" Category" />
            }
            className="mt-1"
          >
            <CategoryDropDown
              goalType={1}
              currentDropDown={currentDropDown}
              task={bug}
              setTask={setBug}
              setCurrentDropDown={setCurrentDropDown}
              onEdit={async (e) => {
                if (selectedBug) {
                  await dispatch(
                    updateGoal({
                      categoryId: e,
                      goalId: selectedBug.id,
                    })
                  );
                }
              }}
            />
          </RowLayout>
          <RowLayout
            customLeft={
              <CustomLeft
                icon="/images/icons/clock-stopwatch.svg"
                text="Estimated time"
              >
                <InfoModal />
              </CustomLeft>
            }
            className="mt-1"
          >
            <TimeInput
              className=""
              value={bug.estimatedTime || ""}
              onChange={(e) => setBug({ ...bug, estimatedTime: e })}
              onOk={async (newTime: string) => {
                if (selectedBug) {
                  await dispatch(
                    updateGoal({
                      estimatedTime: newTime,
                      goalId: selectedBug.id,
                    })
                  );
                }
              }}
            />
          </RowLayout>

          <RowLayout
            customLeft={
              <CustomLeft
                icon="/images/icons/clock-stopwatch.svg"
                text="Time logged"
              >
                <InfoModal />
              </CustomLeft>
            }
            className="mt-1"
          >
            <TimeInput
              className=""
              addNewTimeToPreviousTime
              value={bug.timeLogged || ""}
              onChange={(e) => setBug({ ...bug, timeLogged: e })}
              onOk={async (newTime) => {
                if (selectedBug) {
                  await dispatch(
                    updateGoal({
                      timeLogged: newTime,
                      goalId: selectedBug.id,
                    })
                  );
                }
              }}
            />
          </RowLayout>

          <RowLayout
            customLeft={
              <CustomLeft
                icon="/images/icons/link-04.svg"
                text=" Environment"
              />
            }
            className="mt-1"
          >
            <EnvironmentInput
              className=""
              value={bug.environment}
              onChange={(e) => setBug({ ...bug, environment: e })}
              onOk={async (e) => {
                if (selectedBug) {
                  await dispatch(
                    updateGoal({
                      environment: bug.environment,
                      goalId: selectedBug.id,
                    })
                  );
                }
              }}
            />
          </RowLayout>

          <RowLayout
            customLeft={
              <CustomLeft
                icon="/images/icons/tool-01.svg"
                text="Component (optional)"
              />
            }
            className="mt-1"
          >
            <ComponentDropdown
              task={bug}
              setTask={setBug}
              currentDropDown={currentDropDown}
              setCurrentDropDown={setCurrentDropDown}
              onEdit={async (e) => {
                if (selectedBug) {
                  await dispatch(
                    updateGoal({
                      ...e,
                      goalId: selectedBug.id,
                    })
                  );
                }
              }}
            />
          </RowLayout>
          <div className="mt-6 pr-8">
            <Tabs
              onTabClick={(key) => {
                setCurrentTab(key);
              }}
              activeKey={currentTab}
              className="overview-tab mt-8"
            >
              <TabPane
                tab={
                  <div className="flex">
                    <div
                      className={`${
                        currentTab === "description"
                          ? "mon-500-14 text-primary-600"
                          : "text-gray-500 mon-400-14"
                      }`}
                    >
                      Description
                    </div>
                  </div>
                }
                key="description"
              >
                <Input.TextArea
                  rows={3}
                  placeholder="Add a description"
                  required={true}
                  className="w-full h-full bg-white rounded-xl border border-gray-300 text-gray-900 mon-400-12 pl-4 pt-2  active:outline-none mb-6"
                  value={bug.description}
                  onChange={async (e) => {
                    setBug({ ...bug, description: e.target.value });
                    if (selectedBug) {
                      await dispatch(
                        updateGoal({
                          description: e.target.value,
                          goalId: selectedBug.id,
                        })
                      );
                    }
                  }}
                ></Input.TextArea>
                <UploadAndAttachments
                  task={bug}
                  setTask={setBug}
                  onAttachmentClick={onAttachmentClick}
                  onEdit={async (e) => {
                    //if (e.length < 1) return;
                    if (selectedBug) {
                      await dispatch(
                        updateGoal({
                          attachments: JSON.stringify(e),
                          goalId: selectedBug.id,
                        })
                      );
                    }
                  }}
                  isEdit={selectedBug}
                />
              </TabPane>
              <TabPane
                tab={
                  <div className="flex">
                    <div
                      className={`${
                        currentTab === "comment"
                          ? "mon-500-14 text-primary-600"
                          : "text-gray-500 mon-400-14"
                      }`}
                    >
                      Comment
                    </div>
                  </div>
                }
                key="comment"
              >
                <Comments
                  docId={bug.id}
                  docType="bug"
                  visible={true}
                  user={[{ userId: bug.assigneeId }]}
                />
              </TabPane>
            </Tabs>
          </div>
        </div>
      }
      footer={
        <div className="flex justify-end py-4 gap-x-3 px-3 shadow-upward">
          <div className="flex gap-x-3">
            <CustomButton
              text={selectedBug ? "Close" : "Cancel"}
              onClick={() => {
                onCancel();
                dispatch(resetSchedule("newBugModal"));
              }}
              className="border border-gray-300 text-gray-700 bg-white mon-500-14"
              height="40px"
              width="81px"
            />
            {!selectedBug && (
              <CustomButton
                text="Create"
                className="mon-500-14 text-white bg-primary-600 "
                height="40px"
                width="79px"
                onClick={() => {
                  formik.handleSubmit();
                }}
              />
            )}
          </div>
        </div>
      }
      onCancel={onCancel}
    />
  );
};

export default NewBugModal;
