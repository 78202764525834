import React, { useState } from "react";
import CustomInput from "../../Core/Common/CustomInput";
import EyeIcon from "../../Core/svg/EyeIcon";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useAppDispatch } from "../../Core/redux/hooks";
import { useNavigate } from "react-router-dom";
import CustomButton from "../../Core/Common/CustomButton";
import { Divider } from "antd";
import IdentityProviderSlice from "./IdentityProviderSlice";
import Spinner from "../../Core/svg/Spinner";
import { client } from "../../Core/utils/axiosClient";
import RightScreen from "./RightScreen";

// type Props = {};

const ForgotPasswordScreen = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [step, setStep] = useState(1);

  const [checkingEmail, setCheckingEmail] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState(true);

  const checkIfEmailValid = async (email, callback) => {
    console.log(email);

    try {
      formik.setFieldValue("checkingEmail", true);
      setCheckingEmail(true);

      const response = await client.get(`/auth/check-if-email-exists`, {
        params: {
          email,
        },
      });
      const { exists } = response.data;
      if (exists) {
        setCheckingEmail(false);
        if (typeof callback === "function") callback();
      } else {
        console.log("hehe");
        setIsEmailValid(false);
      }
    } catch (error) {
      console.log("eheehe");
      //return error;
    } finally {
      setCheckingEmail(false);
    }
  };

  const sendForgotPasswordEmail = async (email, callback) => {
    console.log(email);

    const response = await client.post(`/auth/forgot-password`, {
      email,
    });
    if (typeof callback === "function") callback();
  };

  const formik = useFormik({
    initialValues: {
      email: null,
      password: null,
      showPassword: false,
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Please enter a valid email address")
        .required("Please enter the email address")
        .typeError("Please enter the email address"),
    }),
    onSubmit: (values) => {
      console.log(values);

      const { email, password } = values;

      switch (step) {
        case 1:
          checkIfEmailValid(email, () => {
            sendForgotPasswordEmail(email, () => {
              setStep(2);
            });
          });
          // setStep(2);
          break;
        default:
          return;
      }
    },
  });

  const renderBody = () => {
    switch (step) {
      case 1:
        return (
          <div>
            <div className="mon-600-24 text-primary-600 mb-6">
              Forgot Password?
            </div>
            <div className="mt-5.5 mon-400-14 text-gray-500">
              Enter your email and you will receive a link to reset your
              password.
            </div>
            <div className="flex items-center mt-1">
              <div className="mon-400-14 text-gray-500 mr-2">Or</div>
              <div>
                <CustomButton
                  text="Sign Up"
                  className="text-primary-700 bg-primary-50 mon-500-14"
                  height="36px"
                  width="110px"
                  onClick={() => navigate("/signup")}
                />
              </div>
            </div>

            <form
              autoComplete="off"
              // style={{ width: 520 }}
              onSubmit={formik.handleSubmit}
              id="formik"
              className="mt-6"
            >
              <CustomInput
                label="Email"
                labelDefaultCase
                name={"email"}
                type="email"
                placeholder="Enter email address"
                {...formik.getFieldProps("email")}
                onChange={(e) => {
                  // console.log(e.target.value);
                  formik.setFieldValue("email", e.target.value);
                  if (!isEmailValid) setIsEmailValid(true);
                }}
                formikHook={formik}
                className="mt-5.5"
                suffix={checkingEmail ? <Spinner color="black" spin /> : ""}
                showError={!isEmailValid}
                errorText={
                  !isEmailValid
                    ? "Email in not valid. Please check email."
                    : undefined
                }
              />

              <CustomButton
                text="Send me the link"
                type="submit"
                height="36px"
                width="100%"
                className={`text-white ${
                  !(formik.isValid && formik.dirty)
                    ? "bg-primary-100"
                    : "bg-primary-600"
                } mon-500-14 mt-12`}
                disabled={!(formik.isValid && formik.dirty)}
              />
            </form>
            <div className="flex items-center justify-between mt-12">
              <div className="mon-400-14 text-gray-500">
                {"Don't have an account?"}
              </div>
              <div>
                <CustomButton
                  text="Sign Up"
                  className="text-primary-700 bg-primary-50 mon-500-14"
                  height="36px"
                  width="110px"
                  onClick={() => navigate("/signup")}
                />
              </div>
            </div>
          </div>
        );
      case 2:
        return (
          <div>
            <div className="mon-600-24 text-primary-600 mb-6">
              Reset link sent!
            </div>

            <div className="flex items-center bg-primary-50 rounded-lg p-2">
              <img
                src="/images/icons/inbox.svg"
                className="w-9 h-9 mr-2"
                alt=""
              />
              <div className="mon-500-12 text-primary-600">
                Check your inbox! We’ve sent an email with a link.
              </div>
            </div>
            <div className="flex items-center mt-5.5">
              <div className="mon-400-14 text-gray-500 mr-2">Or</div>
              <div>
                <CustomButton
                  text="Sign In"
                  className="text-primary-700 bg-primary-50 mon-500-14"
                  height="36px"
                  width="110px"
                  onClick={() => navigate("/")}
                />
              </div>
            </div>

            <div className="flex items-center justify-between mt-12">
              <div className="mon-400-14 text-gray-500">
                {"Don't have an account?"}
              </div>
              <div>
                <CustomButton
                  text="Sign Up"
                  className="text-primary-700 bg-primary-50 mon-500-14"
                  height="36px"
                  width="110px"
                  onClick={() => navigate("/signup")}
                />
              </div>
            </div>
          </div>
        );
      default:
        return <></>;
    }
  };

  return (
    <div className="flex w-screen h-screen">
      <div className="flex-[6] bg-blue-50">
        <RightScreen />
      </div>
      <div className="flex-[4] flex-center h-screen overflow-y-auto">
        <div className="w-[400px] h-[90vh] flex flex-col justify-start px-4 pt-[10vh]">
          {renderBody()}
        </div>
      </div>
    </div>
  );
};

export default ForgotPasswordScreen;
