import { Tooltip } from "antd";
import dayjs from "dayjs";
import React, { useState } from "react";
import { useAsyncError, useNavigate } from "react-router-dom";
import CustomAvatar from "../../Core/Common/CustomAvatar";
import CustomButton from "../../Core/Common/CustomButton";
import { useAppDispatch } from "../../Core/redux/hooks";
import { updateVault } from "../../Core/redux/slices/vault.slice";
import PhaseIcon from "../../Core/svg/PhaseIcon";
import DenyRequestModal from "./DenyRequestModal";
import GrantAccessModal from "./GrantAccessModal";
import ViewRequestForm from "./ViewRequestForm";
import { CheckCircleFilled } from "@ant-design/icons";
import { getPhaseName } from "../../Core/utils/getPhaseName";
import { updateSchedule } from "../../Core/redux/slices/schedule.slice";

const CustomBadge = ({ text }) => {
  return (
    <div
      className={`flex items-center w-[110px] h-[22px] gap-x-1 rounded-2xl ${
        text === "High Severity" ? "bg-error-600" : "bg-primary-600"
      }`}
    >
      <img src="/images/icons/flag-white.svg" className="ml-[6px]" />
      <p className="mon-500-12 text-white">{text}</p>
    </div>
  );
};

const BreadCrumbComponent = ({ fieldNames }) => {
  return (
    <div className="flex items-center gap-x-2 flex-nowrap truncate">
      {fieldNames.map((field, index) => (
        <div className="flex items-center" key={field.id}>
          <p className="mon-400-10 text-gray-500 cursor-pointer ml-2 ">
            {field.value}
          </p>
          {fieldNames.length !== index + 1 && (
            <img
              src="/images/icons/chevron-right.svg"
              className="w-3 h-3 ml-1"
            />
          )}
        </div>
      ))}
    </div>
  );
};

const Resolved = () => {
  return (
    <div className="flex mt-2 items-center">
      <CheckCircleFilled
        color="#039855"
        style={{
          color: "#039855",
        }}
      />
      <div className="mon-500-14 text-success-600 ml-1">Resolved</div>{" "}
    </div>
  );
};

const NotificationDescription = ({
  description,
  mentionedName,
  isRequest,
}: {
  description: string;
  mentionedName?: string;
  isRequest?: boolean;
}) => {
  return (
    <div className="flex items-center gap-x-1 mt-2">
      <div className="flex items-center max-w-max bg-white py-[9px] px-[14px] border-[1px] border-[#C0CBD7] rounded-xl hover:shadow-md">
        {mentionedName && (
          <span className="bg-primary-500 h-[18px] text-white mon-500-12 rounded-lg py-[1px]">{`@${mentionedName}`}</span>
        )}
        {description.length != 0 && (
          <div
            className={`mon-400-12 text-gray-900 ${mentionedName && "ml-1"}`}
          >
            {description}
          </div>
        )}
        {description.length == 0 && (
          <div
            className={`mon-400-12 text-gray-900 ${mentionedName && "ml-1"}`}
          >
            N/A
          </div>
        )}
      </div>
      {!isRequest && (
        <Tooltip title="Add reaction">
          <div className="flex justify-center items-center w-9 h-9 rounded-lg bg-white border-[1px] border-[#C0CBD7] hover:shadow-md">
            <img src="/images/icons/face-content.svg" className="w-5 h-5" />
          </div>
        </Tooltip>
      )}
    </div>
  );
};

const NotificationTypeDetails = ({ notificationType, metaDetails }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const details = [
    // {
    //   id: 1,
    //   name: "image",
    //   icon: "/images/icons/notifications/image.svg",
    // },
    // {
    //   id: 5,
    //   name: "link",
    //   icon: "/images/icons/notifications/link.svg",
    // },
    // {
    //   id: 3,
    //   name: "video",
    //   icon: "/images/icons/notifications/video.svg",
    // },
    {
      id: 16,
      name: "file excel",
      icon: "/images/icons/notifications/file-excel.svg",
    },
    {
      id: 1,
      name: "subtask",
      icon: "/images/icons/notifications/subtask.svg",
    },
    {
      id: 2,
      name: "subtask",
      icon: "/images/icons/notifications/subtask.svg",
    },
    {
      id: 28,
      name: "subtask",
      icon: "/images/icons/notifications/subtask.svg",
    },
    {
      id: 3,
      name: "task",
      icon: "/images/icons/notifications/task.svg",
    },
    {
      id: 27,
      name: "task",
      icon: "/images/icons/notifications/task.svg",
    },
    {
      id: 24,
      name: "task",
      icon: "/images/icons/notifications/task.svg",
    },
    {
      id: 4,
      name: "google meet",
      icon: "/images/icons/google-meet.svg",
    },
    {
      id: 5,
      name: "google meet",
      icon: "/images/icons/google-meet.svg",
    },
    {
      id: 6,
      name: "google meet",
      icon: "/images/icons/google-meet.svg",
    },
    {
      id: 7,
      name: "new scope",
      icon: "/images/icons/notifications/scope.svg",
    },
    {
      id: 8,
      name: "bug",
      icon: "/images/icons/notifications/bug.svg",
    },
    {
      id: 26,
      name: "bug",
      icon: "/images/icons/notifications/bug.svg",
    },
    {
      id: 9,
      name: "bug",
      icon: "/images/icons/notifications/bug.svg",
    },
    {
      id: 10,
      name: "sprint",
      icon: "/images/icons/notifications/sprint.svg",
    },
    {
      id: 12,
      name: "project",
      icon: "/images/icons/notifications/project.svg",
    },
    {
      id: 13,
      name: "thread",
      icon: "/images/icons/notifications/thread.svg",
    },
    {
      id: 14,
      name: "thread",
      icon: "/images/icons/notifications/thread.svg",
    },
    {
      id: 15,
      name: "thread",
      icon: "/images/icons/notifications/thread.svg",
    },
    {
      id: 22,
      name: "project",
      icon: "/images/icons/notifications/project.svg",
    },
  ];

  const item = details.find(({ id }) => id === +notificationType);

  return (
    <div className="max-h-max max-w-max bg-white py-1 px-1.5 rounded-xl hover:shadow-md mt-2">
      <div className="flex justify-start gap-x-1.5 h-12 cursor-pointer">
        {notificationType === "11" ? (
          <PhaseIcon
            phaseId={+metaDetails?.phaseId}
            width="40px"
            height="40px"
            icon
          />
        ) : (
          <div className="w-10 h-10">
            <img src={item.icon} className=" object-fill" />
          </div>
        )}
        <div className="flex flex-col mt-1">
          <Tooltip
            title={
              metaDetails?.name ||
              metaDetails?.sprintName ||
              metaDetails?.phaseName?.phases ||
              metaDetails?.title ||
              metaDetails?.threadName ||
              ""
            }
          >
            <span className="mon-500-12 text-gray-900 capitalize truncate max-w-[10vw]">
              {metaDetails?.name ||
                metaDetails?.sprintName ||
                metaDetails?.phaseName?.phases ||
                metaDetails?.title ||
                metaDetails?.threadname ||
                ""}
            </span>
          </Tooltip>
          <span className="mon-400-10 text-gray-500">
            {metaDetails?.sprint ||
              (metaDetails?.startDate &&
                metaDetails?.endDate &&
                `${dayjs(metaDetails.startDate).format("DD MMM")} - ${dayjs(
                  metaDetails.endDate
                ).format("DD MMM")}`) ||
              (metaDetails[0]?.startDate &&
                metaDetails[0]?.endDate &&
                `${dayjs(metaDetails[0].startDate).format("DD MMM")} - ${dayjs(
                  metaDetails[0].endDate
                ).format("DD MMM")}`) ||
              `${metaDetails?.usersCount} Members` ||
              "ABC"}
          </span>
        </div>
      </div>
    </div>
  );
};

const NotificationComponent = ({
  projectName,
  severity,
  customBackgroundColor,
  closeNotification,
  notificationType,
  requestType,
  notificationObject,
}: {
  notificationType: string;
  notificationDescription?: string;
  notificationObject?: any;
  projectName?: string;
  severity?: string;
  customBackgroundColor?: boolean;
  closeNotification?: boolean;
  requestType?: string;
  reaction?: boolean;
  onHover?: boolean;
  notificationSubDetails?: boolean;
  mentionedName?: string;
  isAssigned?: boolean;
  isPopup?: boolean;
}) => {
  const [close, setClose] = useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [denyRequestModal, setDenyRequestModal] = useState({
    visible: false,
    notification: null,
  });
  const [viewRequestForm, setViewRequestForm] = useState({
    visible: false,
    notification: null,
  });
  const [grantAccessModal, setGrantAccessModal] = useState({
    visible: false,
    notification: null,
  });
  const getTitle = () => {
    const additionalDetails = JSON.parse(notificationObject?.meta);

    switch (notificationType) {
      // subtask notifications
      case "1":
        return (
          <div className="mon-400-12 text-gray-500">
            <span className="mon-600-12 text-gray-700 capitalize">
              {notificationObject.firstname} {notificationObject.lastname}
            </span>{" "}
            commented on{" "}
            <span className="mon-500-12 text-primary-600">{`: ${additionalDetails.name}`}</span>
          </div>
        );
      case "2":
        return (
          <div className="mon-400-12 text-gray-500">
            <span className="mon-600-12 text-gray-700 capitalize">
              {notificationObject.firstname} {notificationObject.lastname}
            </span>{" "}
            has assigned you a{" "}
            <span className="mon-500-12 text-primary-600">Subtask</span>
          </div>
        );

      // task notification
      case "3":
        return (
          <div className="mon-400-12 text-gray-500">
            <span className="mon-600-12 text-gray-700 capitalize">
              {notificationObject.firstname} {notificationObject.lastname}
            </span>{" "}
            commented on{" "}
            <span className="mon-500-12 text-primary-600">{`: ${additionalDetails.name}`}</span>
          </div>
        );

      // event notification
      case "4":
        return (
          <div className="mon-400-12 text-gray-500">
            <span className="mon-600-12 text-gray-700 capitalize">
              {notificationObject.firstname} {notificationObject.lastname}
            </span>{" "}
            updated an event{" "}
            <span className="mon-500-12 text-primary-600">{`: ${additionalDetails.name}`}</span>
          </div>
        );

      // new scope notification
      case "7":
        return (
          <div className="mon-400-12 text-gray-500">
            <span className="mon-600-12 text-gray-700 capitalize">
              {notificationObject.firstname} {notificationObject.lastname}
            </span>{" "}
            created a new{" "}
            <span className="mon-500-12 text-primary-600">Scope</span>
          </div>
        );

      // bug notification
      case "8":
        return (
          <div className="mon-400-12 text-gray-500">
            <span className="mon-600-12 text-gray-700 capitalize">
              {notificationObject.firstname} {notificationObject.lastname}
            </span>{" "}
            changed dependency in a{" "}
            <span className="mon-500-12 text-primary-600">Bug</span>
          </div>
        );
      case "9":
        return (
          <div className="mon-400-12 text-gray-500">
            <span className="mon-600-12 text-gray-700 capitalize">
              {notificationObject.firstname} {notificationObject.lastname}
            </span>{" "}
            has assigned you a{" "}
            <span className="mon-500-12 text-primary-600">Bug</span>
          </div>
        );

      // sprint notification
      case "10":
        return (
          <div className="mon-400-12 text-gray-500">
            <span className="mon-600-12 text-gray-700 capitalize">
              {notificationObject.firstname} {notificationObject.lastname}
            </span>{" "}
            started a new{" "}
            <span className="mon-500-12 text-primary-600">Sprint</span>
          </div>
        );

      // phase notification
      case "11":
        return (
          <div className="mon-400-12 text-gray-500">
            <span className="mon-600-12 text-gray-700 capitalize">
              {notificationObject.firstname} {notificationObject.lastname}
            </span>{" "}
            started a <span className="mon-500-12 text-primary-600">Phase</span>
          </div>
        );

      // project notification
      case "12":
        return (
          <div className="mon-400-12 text-gray-500">
            <span className="mon-600-12 text-gray-700 capitalize">
              {notificationObject.firstname} {notificationObject.lastname}
            </span>{" "}
            assigned you as{" "}
            <span className="mon-500-12 text-primary-600 capitalize">
              {additionalDetails.roleName}
            </span>
          </div>
        );

      // thread notification
      case "13":
        return (
          <div className="mon-400-12 text-gray-500">
            <span className="mon-600-12 text-gray-700 capitalize">
              {notificationObject.firstname} {notificationObject.lastname}
            </span>{" "}
            added you in a{" "}
            <span className="mon-500-12 text-primary-600">Thread</span>
          </div>
        );
      case "14":
        // this message notification as per ebrahim we are reomiving this
        return <></>;
        return (
          <div className="mon-400-12 text-gray-500">
            <span className="mon-600-12 text-gray-700 capitalize">
              {notificationObject.firstname} {notificationObject.lastname}
            </span>{" "}
            sent you a{" "}
            <span className="mon-500-12 text-primary-600">message</span>
          </div>
        );
      case "15":
        return (
          <div className="mon-400-12 text-gray-500">
            <span className="mon-600-12 text-gray-700 capitalize">
              {notificationObject.firstname} {notificationObject.lastname}
            </span>{" "}
            mentioned you in a{" "}
            <span className="mon-500-12 text-primary-600">message</span>
          </div>
        );
      //event notification
      case "6":
        return (
          <div className="mon-400-12 text-gray-500">
            <span className="mon-600-12 text-gray-700 capitalize">
              {notificationObject.firstname} {notificationObject.lastname}
            </span>{" "}
            invited you to an{" "}
            <span className="mon-500-12 text-primary-600">event</span>
          </div>
        );

      // vault notification
      case "16":
        return (
          <div className="mon-400-12 text-gray-500">
            <span className="mon-600-12 text-gray-700 capitalize">
              {notificationObject.firstname} {notificationObject.lastname}
            </span>{" "}
            shared access to a{" "}
            <span className="mon-500-12 text-primary-600 capitalize">
              {additionalDetails.docType}
            </span>
          </div>
        );

      case "17":
        return (
          <div className="mon-400-12 text-gray-500">
            <span className="mon-600-12 text-gray-700 capitalize">
              {notificationObject.firstname} {notificationObject.lastname}
            </span>{" "}
            added a file to a{" "}
            <span className="mon-500-12 text-primary-600">
              {additionalDetails.name}
            </span>
          </div>
        );

      case "19":
        return (
          <div className="mon-400-12 text-gray-500">
            <span className="mon-600-12 text-gray-700 capitalize">
              {notificationObject.firstname} {notificationObject.lastname}
            </span>{" "}
            has requested{" "}
            {`${
              additionalDetails.requestType === "Upload"
                ? "you to upload a"
                : "access to a"
            }`}{" "}
            <span className="mon-500-12 text-primary-600">
              {additionalDetails.docType}
            </span>
          </div>
        );

      case "20":
        return (
          <div className="mon-400-12 text-gray-500">
            <span className="mon-600-12 text-gray-700 capitalize">
              {notificationObject.firstname} {notificationObject.lastname}
            </span>{" "}
            approved your request
          </div>
        );

      case "21":
        return (
          <div className="mon-400-12 text-gray-500">
            <span className="mon-600-12 text-gray-700 capitalize">
              {notificationObject.firstname} {notificationObject.lastname}
            </span>{" "}
            rejected your request
          </div>
        );

      // Role assigned notification
      case "22":
        return (
          <div className="mon-400-12 text-gray-500">
            <span className="mon-600-12 text-gray-700 capitalize">
              {notificationObject.firstname} {notificationObject.lastname}
            </span>{" "}
            assigned you as{" "}
            <span className="mon-500-12 text-primary-600">
              {additionalDetails.roleName}
            </span>
          </div>
        );
      case "24":
        return (
          <div className="mon-400-12 text-gray-500">
            <span className="mon-600-12 text-gray-700 capitalize">
              {notificationObject.firstname} {notificationObject.lastname}
            </span>{" "}
            has assigned you a{" "}
            <span className="mon-500-12 text-primary-600">Task</span>
          </div>
        );
      case "26":
        return (
          <div className="mon-400-12 text-gray-500">
            <span className="mon-600-12 text-gray-700 capitalize">
              {notificationObject.firstname} {notificationObject.lastname}
            </span>{" "}
            has made you Reporter of a
            <span className="mon-500-12 text-primary-600"> Bug</span>
          </div>
        );
      case "27":
        return (
          <div className="mon-400-12 text-gray-500">
            <span className="mon-600-12 text-gray-700 capitalize">
              {notificationObject.firstname} {notificationObject.lastname}
            </span>{" "}
            has made you Reporter of a
            <span className="mon-500-12 text-primary-600"> Task</span>
          </div>
        );
      case "28":
        return (
          <div className="mon-400-12 text-gray-500">
            <span className="mon-600-12 text-gray-700 capitalize">
              {notificationObject.firstname} {notificationObject.lastname}
            </span>{" "}
            has made you Reporter of a
            <span className="mon-500-12 text-primary-600"> Subtask</span>
          </div>
        );
      default:
        return (
          <div className="mon-400-12 text-gray-500">
            <span className="mon-600-12 text-gray-700 capitalize">
              {notificationObject.firstname} {notificationObject.lastname}
            </span>{" "}
            has assigned you a{" "}
            <span className="mon-500-12 text-primary-600">File</span>
          </div>
        );
    }
  };

  const getBreadCrumbs = () => {
    const additionalDetails = JSON.parse(notificationObject?.meta);

    switch (notificationType) {
      case "2":
        return (
          <BreadCrumbComponent
            fieldNames={[
              { id: 1, value: notificationObject?.projectTitle },
              { id: 2, value: notificationObject?.phases },
              { id: 3, value: additionalDetails.sprint },
              {
                id: 4,
                value: additionalDetails.parentGoalName?.name
                  ? additionalDetails.parentGoalName?.name
                  : "Task 1",
              },
              { id: 5, value: additionalDetails.name },
            ]}
          />
        );
      case "7":
        return (
          <BreadCrumbComponent
            fieldNames={[
              { id: 1, value: notificationObject?.projectTitle },
              { id: 2, value: notificationObject?.phases },
              { id: 3, value: additionalDetails?.name },
            ]}
          />
        );
      case "10":
        return (
          <BreadCrumbComponent
            fieldNames={[
              { id: 1, value: notificationObject?.projectTitle },
              { id: 2, value: notificationObject?.phases },
              { id: 3, value: additionalDetails?.name },
            ]}
          />
        );
      case "9":
        return (
          <BreadCrumbComponent
            fieldNames={[
              { id: 1, value: notificationObject?.projectTitle },
              { id: 2, value: notificationObject?.phases },
              { id: 3, value: additionalDetails?.name },
            ]}
          />
        );
      case "11":
        return (
          <BreadCrumbComponent
            fieldNames={[
              { id: 1, value: notificationObject?.projectTitle },
              { id: 2, value: notificationObject?.phases },
            ]}
          />
        );
      case "16":
      case "17":
      case "19":
        return (
          <BreadCrumbComponent
            fieldNames={[
              { id: 1, value: notificationObject?.projectTitle },
              { id: 1, value: "Vault" },
              { id: 2, value: additionalDetails?.docType },
            ]}
          />
        );
      case "24":
        return (
          <BreadCrumbComponent
            fieldNames={[
              { id: 1, value: notificationObject?.projectTitle },
              { id: 2, value: notificationObject?.phases },
              { id: 3, value: additionalDetails.sprint },
              { id: 4, value: additionalDetails.name },
            ]}
          />
        );
    }
  };

  const getBody = () => {
    switch (notificationType) {
      case "1":
        return (
          <NotificationDescription
            description={JSON.parse(notificationObject.meta).message}
          />
        );
      case "2":
      case "9":
      case "13":
      case "24":
      case "26":
      case "27":
      case "28":
        return (
          <div>
            <>
              {console.log(
                JSON.parse(notificationObject.meta),
                "notificationObject"
              )}
            </>
            <NotificationTypeDetails
              notificationType={notificationType}
              metaDetails={JSON.parse(notificationObject.meta)}
            />
          </div>
        );
      case "7":
        return (
          <NotificationTypeDetails
            notificationType={notificationType}
            metaDetails={JSON.parse(notificationObject.meta)}
          />
        );
      case "10":
        return (
          <NotificationTypeDetails
            notificationType={notificationType}
            metaDetails={JSON.parse(notificationObject.meta)}
          />
        );
      case "11":
        return (
          <NotificationTypeDetails
            notificationType={notificationType}
            metaDetails={JSON.parse(notificationObject.meta)}
          />
        );
      case "12":
        return (
          <NotificationTypeDetails
            notificationType={notificationType}
            metaDetails={JSON.parse(notificationObject.meta)}
          />
        );
      case "14":
        // this message notification as per ebrahim we are reomiving this
        return <></>;
        return (
          <NotificationDescription
            description={JSON.parse(notificationObject.meta).message}
          />
        );
      case "16":
        return (
          <NotificationTypeDetails
            notificationType={notificationType}
            metaDetails={JSON.parse(notificationObject.meta)}
          />
        );
      case "19":
        return (
          <>
            <NotificationDescription
              description={JSON.parse(notificationObject.meta).description}
              isRequest={true}
            />

            <>
              {/* {notificationObject?.isResolved !== 1 ? (
                getButtonAction("upload")
              ) : (
                <div>Resolved</div>
              )} */}
              {getButtonAction(
                JSON.parse(notificationObject.meta)?.requestType === "Access"
                  ? "grantAccess"
                  : "upload"
              )}
            </>
          </>
        );
      case "21":
        return (
          <>
            <NotificationDescription
              description={
                JSON.parse(notificationObject.meta)?.resaonForRejection || "N/A"
              }
              isRequest={true}
            />
            <>{getButtonAction("deny")}</>
          </>
        );
      case "22":
        return (
          <NotificationTypeDetails
            notificationType={notificationType}
            metaDetails={JSON.parse(notificationObject.meta)}
          />
        );
      default:
        return <></>;
    }
  };

  const getButtonAction = (accessType) => {
    const docType = JSON.parse(notificationObject.meta).docType;
    if (notificationObject.isResolved === 1) {
      return <Resolved />;
    }
    switch (accessType) {
      case "upload":
        return (
          <div className="mt-2 flex items-center gap-x-4">
            <CustomButton
              text="Upload"
              className="bg-primary-600 text-white whitespace-nowrap"
              height="40px"
              width="84px"
              onClick={() => {
                const notification = JSON.parse(notificationObject.meta);

                if (docType.toLowerCase() === "file") {
                  dispatch(updateVault({ key: "addFileModal", value: true }));
                  dispatch(
                    updateVault({
                      key: "preFilledVaultModalValues",
                      value: {
                        phaseId: notification.phaseId,
                        users: [
                          {
                            userId: notification.senderId.toString(),
                            isOwner: 0,
                            canEdit: 0,
                            canView: 1,
                          },
                        ],

                        notificationId: notificationObject.id,
                      },
                    })
                  );
                } else if (docType.toLowerCase() === "folder") {
                  dispatch(updateVault({ key: "addFolderModal", value: true }));
                  dispatch(
                    updateVault({
                      key: "preFilledVaultModalValues",
                      value: {
                        phaseId: notification.phaseId,
                        users: [
                          {
                            userId: notification.senderId.toString(),
                            isOwner: 0,
                            canEdit: 0,
                            canView: 1,
                          },
                        ],
                        notificationId: notificationObject.id,
                      },
                    })
                  );
                } else {
                  dispatch(updateVault({ key: "addLinkModal", value: true }));
                  dispatch(
                    updateVault({
                      key: "preFilledVaultModalValues",
                      value: {
                        phaseId: notification.phaseId,
                        users: [
                          {
                            userId: notification.senderId.toString(),
                            isOwner: 0,
                            canEdit: 0,
                            canView: 1,
                          },
                        ],
                        notificationId: notificationObject.id,
                      },
                    })
                  );
                }
                navigate(`/vault/${notificationObject.projectId}`);
              }}
            />

            <CustomButton
              text="Deny"
              onClick={() =>
                setDenyRequestModal({
                  visible: true,
                  notification: {
                    ...JSON.parse(notificationObject.meta),
                    id: notificationObject.id,
                  },
                })
              }
              className="bg-white text-gray-700 border-[1px] border-[#DDE5ED]"
              height="40px"
              width="70px"
            />
            {/* <ApproveRequestModal visible={false} onCancel={undefined} /> */}
          </div>
        );
      case "grantAccess":
        return (
          <div className="mt-2 flex items-center gap-x-4">
            <CustomButton
              text="Grant access"
              className="bg-primary-600 text-white whitespace-nowrap"
              height="40px"
              width="123px"
              onClick={() =>
                setGrantAccessModal({
                  visible: true,
                  notification: {
                    ...JSON.parse(notificationObject.meta),
                    id: notificationObject.id,
                  },
                })
              }
            />

            <CustomButton
              text="Deny"
              className="bg-white text-gray-700 border-[1px] border-[#DDE5ED]"
              height="40px"
              width="70px"
              onClick={() =>
                setDenyRequestModal({
                  visible: true,
                  notification: {
                    ...JSON.parse(notificationObject.meta),
                    id: notificationObject.id,
                  },
                })
              }
            />
          </div>
        );
      case "deny":
        return (
          <div className="mt-2 flex items-center gap-x-4">
            <CustomButton
              text="View Request form"
              onClick={() =>
                setViewRequestForm({
                  visible: true,
                  notification: {
                    ...JSON.parse(notificationObject.meta),
                    id: notificationObject.id,
                  },
                })
              }
              className="bg-primary-600 text-white whitespace-nowrap"
              height="40px"
              width="168px"
            />
          </div>
        );
      default:
        <></>;
    }
  };

  const getTimeStamp = (dateString) => {
    if (dateString === null || typeof dateString === undefined) {
      return "-";
    }

    if (dayjs(dateString).isToday()) {
      return `Today at ${dayjs(dateString).local().format("HH:MMa")}`;
    }
    return dayjs(dateString).local().format("DD MMM YYYY, HH:MM a");
  };

  const handleClick = () => {
    const metaDetails = notificationObject?.meta
      ? JSON.parse(notificationObject.meta)
      : {};
    if ([9, 24, 2].includes(+notificationType)) {
      const url = `/schedule/${
        metaDetails.goal[0]?.projectId
      }?selectedPhase=${getPhaseName(
        metaDetails?.goal[0]?.phaseId
      )}&selectedSprint=${metaDetails?.goal[0].sprintId}`;
      console.log(url, metaDetails, "urllllll");

      switch (+metaDetails.goalType) {
        case 1:
          dispatch(
            updateSchedule({
              key: "newTaskModal",
              value: {
                visible: true,
                isNewScope: false,
                scopeDescription: "",
              },
            })
          );
          dispatch(
            updateSchedule({ key: "selectedTask", value: metaDetails?.goal[0] })
          );
          break;
        case 2:
          dispatch(updateSchedule({ key: "newEventModal", value: true }));
          dispatch(
            updateSchedule({
              key: "selectedEvent",
              value: metaDetails?.goal[0],
            })
          );
          break;
        case 3:
          dispatch(updateSchedule({ key: "newBugModal", value: true }));
          dispatch(
            updateSchedule({ key: "selectedBug", value: metaDetails?.goal[0] })
          );
          break;
        case 4:
          dispatch(
            updateSchedule({
              key: "newSubTaskModal",
              value: {
                visible: true,
                editTask: true,
                subtask: {
                  ...metaDetails?.goal[0],
                  // sprintId: searchParams.get("selectedSprint"),
                  // projectId: projectId,
                  // phaseId: currentSelectedPhase.phaseId,
                  names: [metaDetails?.goal[0].name],
                  // isCompleted: 0,
                  // statusId: subTask.statusId,
                  // priorityId: subTask.priorityId,
                  // goalType: subTask.goalType,
                  // assigneeId: subTask.assigneeId,
                  // reporterId: subTask.reporterId,
                  // description: subTask.descripion,
                  parentGoalId: metaDetails?.goal[0].parentGoalId,
                  // goalId: subTask.id,
                },
              },
            })
          );
          break;
        default:
          break;
      }
      navigate(url);
    } else if (
      [4, 5, 6, 10, 13, 14, 15, 11, 16, 22, 7].includes(+notificationType)
    ) {
      //redirecting event notifications to the url specified in click action
      const url = notificationObject?.clickAction;
      console.log("works", url);
      navigate(url);
    }

    return;
  };

  return (
    <>
      {close ? (
        <></>
      ) : (
        <>
          <div
            className={`pb-3 my-4 mx-1 rounded-lg hover:bg-gray-50 ${
              customBackgroundColor ? "bg-error-25 " : "bg-white"
            } ${[9, 24, 2].includes(+notificationType) && "cursor-pointer"}`}
            onClick={handleClick}
          >
            <div
              className={`flex justify-between items-center px-4 ${
                projectName && "pt-4"
              }`}
            >
              {projectName && (
                <p className="mon-400-10 text-gray-500">{projectName}</p>
              )}
              <div className="flex items-center gap-x-4">
                {severity && <CustomBadge text={severity} />}
                {closeNotification && (
                  <img
                    src="/images/icons/x-close.svg"
                    className="w-5 h-5 cursor-pointer"
                    onClick={() => setClose(true)}
                  />
                )}
              </div>
            </div>
            <div className="px-3 pt-3">{getBreadCrumbs()}</div>
            <div className="flex items-center w-full p-3">
              {notificationObject.notificationType === "25" ? (
                <div className="flex justify-start gap-x-2 w-full">
                  <div className="mon-400-12 text-gray-500">
                    You have
                    <span className="mon-500-12 text-primary-600">
                      {" "}
                      {notificationObject?.meta
                        ? JSON.parse(notificationObject?.meta)?.count
                        : ""}{" "}
                    </span>
                    {JSON.parse(notificationObject?.meta)?.count === 1
                      ? "unread message"
                      : "unread messages"}
                  </div>
                </div>
              ) : (
                <div className="flex justify-start gap-x-2 w-full">
                  <CustomAvatar
                    title={`${notificationObject.firstname} ${notificationObject.lastname}`}
                    src={notificationObject.profilePicture}
                    size={40}
                  />
                  <div className=" w-full">
                    <div className={`flex items-center justify-between`}>
                      {getTitle()}
                      <div className="mon-400-12 text-gray-500">
                        {/* Today at 2.20pm */}
                        {getTimeStamp(notificationObject.createdAt)}
                      </div>
                    </div>

                    {getBody()}

                    {requestType && (
                      <div className="mt-2 flex items-center gap-x-4">
                        <CustomButton
                          text={requestType}
                          className="bg-primary-600 text-white whitespace-nowrap"
                          height="40px"
                          //width="123px"
                        />
                        {requestType !== "Resend request" && (
                          <CustomButton
                            text="Deny"
                            className="bg-white text-gray-700 border-[1px] border-[#DDE5ED]"
                            height="40px"
                            width="70px"
                          />
                        )}
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
            <DenyRequestModal
              visible={denyRequestModal.visible}
              notification={denyRequestModal.notification}
              onCancel={() =>
                setDenyRequestModal({ visible: false, notification: null })
              }
            />
            <ViewRequestForm
              visible={viewRequestForm.visible}
              notification={viewRequestForm.notification}
              onCancel={() =>
                setViewRequestForm({ visible: false, notification: null })
              }
            />
            <GrantAccessModal
              visible={grantAccessModal.visible}
              notification={grantAccessModal.notification}
              onCancel={() =>
                setGrantAccessModal({ visible: false, notification: null })
              }
            />
          </div>
        </>
      )}
    </>
  );
};

export default NotificationComponent;
