import React, { useState } from "react";

type CustomFrostyMessageProps = {
  children?: React.ReactNode;
  shadow?: boolean;
  close?: boolean;
  closePosition?: string;
};

const CustomFrostyMessage = ({
  children,
  shadow = true,
  close = true,
  closePosition = "center",
}: CustomFrostyMessageProps) => {
  const [frostyActive, setFrostyActive] = useState(true);

  return frostyActive ? (
    <div className="mb-4 max-w-max">
      <div
        className={`${
          shadow ? "shadow-frostyMessage" : ""
        } flex h-11 bg-white rounded-xl `}
      >
        <div className="flex items-center pl-1 pt-1 pb-1">
          <img
            src="/images/icons/frosty-help.svg"
            className="min-h-[36px] min-w-[36px]"
            alt="frosty"
          />
        </div>
        <div className="flex items-center pl-2 ">
          {children}
          {close && (
            <button
              className={`ml-1 mr-3 ${
                closePosition === "top" ? "self-start mt-1" : ""
              }`}
              onClick={() => {
                setFrostyActive(!frostyActive);
              }}
            >
              <img
                src="/images/icons/x-close.svg"
                className="min-h-[20px] min-w-[20px]"
              />
            </button>
          )}
        </div>
      </div>
    </div>
  ) : null;
};

export default CustomFrostyMessage;
