import { Col, Progress, Row, Spin, Tabs } from "antd";
import React, { useEffect, useState } from "react";
import CustomLayout from "../../Core/Layout/CustomLayout";

import CustomFilterButton from "../../Core/Common/CustomFilterButton";
import CustomSearch from "../../Core/Common/CustomSearch";
import CustomButton from "../../Core/Common/CustomButton";
import useHorizontalScroll from "../../Core/utils/horizontalScroll";
import CustomTable from "../../Core/Common/CustomTable";
import { useAppDispatch, useAppSelector } from "../../Core/redux/hooks";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

import { client } from "../../Core/utils/axiosClient";
import dayjs from "dayjs";
import MenuDropdown from "../Conversations/Components/MenuDropdown";
import {
  deleteProject,
  getProjectList,
  getProjectPermission,
} from "../../Core/redux/api/projectAPI";
import { changeProject } from "../../Core/redux/slices/projects.slice";
import { compeleteResetProjectOnboarding } from "../../Core/redux/slices/projectOnboarding.slice";
import { json } from "stream/consumers";
import CustomAvatar from "../../Core/Common/CustomAvatar";

const ProjectsScreen = () => {
  const [currentTab, setCurrentTab] = React.useState("Ongoing");
  const tabs = ["Ongoing", "Completed"];
  const [searchText, setSearchText] = useState("");
  const { userList } = useAppSelector((state) => state.overview);

  const getUserById = (id) => {
    const user = userList.filter(({ userId }) => +userId === +id)[0];
    const name = `${user.firstname} ${user.lastname}`;
    const profilePicture = user.profilePicture;

    return { name, profilePicture };
  };

  const handleSearch = (searchText) => {
    setSearchText(searchText.toLowerCase());
    return;
  };

  const scrollRef = useHorizontalScroll();

  const [limit, setLimit] = useState(10);
  const [currentPageNo, setCurrentPageNo] = useState(1);
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const { user, loggedIn } = useAppSelector((state) => state.userDetails);
  //const [projects, setProjects] = useState(null);
  const [loading, setLoading] = useState(false);

  const { projectList: projects } = useAppSelector((state) => state.projects);
  const [discardedProjects, setDiscardedProjects] = useState([]);

  const getAllProjects = async () => {
    dispatch(getProjectList(user.id));
  };

  const getDiscarderdProjects = async () => {
    try {
      const response = await client.get(
        `/project/project-list?id=${user.id}&isActive=no`
      );
      console.log(response.data, "data");
      setDiscardedProjects(response.data);
    } catch (e) {
      setDiscardedProjects([]);
    }
  };

  useEffect(() => {
    getAllProjects();
  }, [user]);

  const [searchparams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (searchparams.has("tab")) {
      setCurrentTab(searchparams.get("tab"));
    }
  }, [searchparams]);

  const menuDropdown = (projectId) => {
    switch (user?.roleId) {
      case "1":
        return [
          {
            id: 1,
            image: "/images/icons/calendar-date-icon.svg",
            text: "Go To Schedule",
            textColor: "text-gray-700",
            onclick: () => {
              navigate(`/schedule/${projectId}`);
            },
          },
          {
            id: 2,
            image: "/images/icons/folder-plus.svg",
            text: "Go To Vault",
            textColor: "text-gray-700",
            onclick: () => {
              navigate(`/vault/${projectId}`);
            },
          },
          {
            id: 3,
            image: "/images/icons/settings-icon.svg",
            text: "Project settings",
            textColor: "text-gray-700",
            onclick: () => {
              navigate(`/settings/${projectId}`);
            },
          },
          {
            id: 4,
            image: "/images/icons/trash.svg",
            text: "Delete Project",
            textColor: "text-error-600",
            onclick: () => {
              dispatch(deleteProject({ projectId })).then(() =>
                getAllProjects()
              );
            },
          },
        ];
      case "2":
        return [
          {
            id: 1,
            image: "/images/icons/calendar-date-icon.svg",
            text: "Go To Schedule",
            textColor: "text-gray-700",
            onclick: () => {
              navigate(`/schedule/${projectId}`);
            },
          },
          {
            id: 2,
            image: "/images/icons/folder-plus.svg",
            text: "Go To Vault",
            textColor: "text-gray-700",
            onclick: () => {
              navigate(`/vault/${projectId}`);
            },
          },
          {
            id: 3,
            image: "/images/icons/settings-icon.svg",
            text: "Project settings",
            textColor: "text-gray-700",
            onclick: () => {
              navigate(`/settings/${projectId}`);
            },
          },
          {
            id: 4,
            image: "/images/icons/trash.svg",
            text: "Delete Project",
            textColor: "text-error-600",
            onclick: () => {
              dispatch(deleteProject({ projectId })).then(() =>
                getAllProjects()
              );
            },
          },
        ];
      case "3":
        return [
          {
            id: 1,
            image: "/images/icons/calendar-date-icon.svg",
            text: "Go To Schedule",
            textColor: "text-gray-700",
            onclick: () => {
              navigate(`/schedule/${projectId}`);
            },
          },
          {
            id: 2,
            image: "/images/icons/folder-plus.svg",
            text: "Go To Vault",
            textColor: "text-gray-700",
            onclick: () => {
              navigate(`/vault/${projectId}`);
            },
          },
          {
            id: 3,
            image: "/images/icons/settings-icon.svg",
            text: "Project settings",
            textColor: "text-gray-700",
            onclick: () => {
              navigate(`/settings/${projectId}`);
            },
          },
        ];
    }
  };

  const columns = [
    {
      title: () => (
        <div className="mon-500-10  text-gray-500 uppercase">NAME</div>
      ),
      sorter: (a, b) => a.id - b.id,
      // width: "7%",
      dataIndex: "title",
      render: (text, record) => (
        <div className="flex items-center truncate whitespace-nowrap">
          <div className="mr-3">
            <CustomAvatar
              title={record.title}
              size={24}
              color={record.colorCode}
              whiteText
              fontSize="9px"
              src={record.projectImage}
            />
          </div>
          <div className="mont-400-12 text-gray-500">{text}</div>
        </div>
      ),
    },
    {
      title: () => (
        <div className="mon-500-10  text-gray-500 uppercase">LEAD</div>
      ),
      // width: "7%",
      dataIndex: "fullName",
      sorter: (a, b) => a.fullName - b.fullName,
      render: (text, { profilePicture }) => (
        <div className="flex items-center truncate whitespace-nowrap">
          <div className="mr-3">
            <CustomAvatar
              src={profilePicture}
              title={text}
              size={24}
              fontSize={"9px"}
            />
          </div>
          <div className="mont-400-12 text-gray-500">{text}</div>
        </div>
      ),
    },
    {
      title: () => (
        <div className="mon-500-10  text-gray-500 uppercase">DURATION</div>
      ),
      // width: "7%",
      dataIndex: "duration",
      render: (text, { startDate, endDate }) => (
        <div className="flex items-center truncate whitespace-nowrap">
          <div className="mont-400-12 text-gray-500">
            {isNaN(dayjs(endDate).diff(startDate, "month")) ||
            dayjs(endDate).unix() < 0
              ? "No End Date"
              : dayjs(endDate).diff(startDate, "month") < 0
              ? `0 Months`
              : dayjs(endDate).diff(startDate, "month") === 0
              ? `${dayjs(endDate).diff(startDate, "day")} Days`
              : `${dayjs(endDate).diff(startDate, "month")} Months`}
          </div>
        </div>
      ),
    },
    {
      title: () => (
        <div className="mon-500-10  text-gray-500 uppercase">
          start date - end date
        </div>
      ),
      // width: "7%",
      dataIndex: "startDate",
      render: (text, record) => (
        <div className="flex items-center truncate whitespace-nowrap">
          <div className="mont-400-12 text-gray-500">
            {typeof record.startDate === "string"
              ? dayjs(record.startDate).format("DD MMM YYYY") +
                " - " +
                dayjs(record.endDate).format("DD MMM YYYY")
              : "N/A - N/A"}
          </div>
        </div>
      ),
    },
    {
      title: () => (
        <div className="mon-500-10  text-gray-500 uppercase">PROGRESS</div>
      ),
      // width: "7%",

      dataIndex: "progress",
      render: (text, record) => (
        <div className="flex items-center truncate whitespace-nowrap">
          <div className=" w-40">
            <Progress
              showInfo={false}
              strokeColor="#165ABF"
              percent={+text}
              trailColor="#EDF3F9"
              className="progress-bar-tiny"
            />
          </div>
          <div className="mont-500-14 text-gray-700 ml-3">
            {Math.round(text)}%
          </div>
        </div>
      ),
    },
    {
      width: "5%",
      render: (text, record) => (
        <>
          {currentTab !== "Discarded" && (
            <div className="" onClick={(e) => e.stopPropagation()}>
              <MenuDropdown
                elements={menuDropdown(record?.id)}
                customFunc={undefined}
              />
            </div>
          )}
        </>
      ),
    },
  ];

  const tabData = () => {
    switch (currentTab) {
      case "Ongoing":
        console.log(
          projects?.filter(
            ({ startDate, endDate, progress }) => progress < 100
            // endDate === null ||
            // dayjs(endDate).unix() < 0 ||
            // dayjs(endDate).diff(startDate, "minute") > 0
          ),
          "xxx"
        );
        return projects?.filter(
          ({ startDate, endDate, progress }) => progress < 100
          // endDate === null ||
          // dayjs(endDate).unix() < 0 ||
          // dayjs(endDate).diff(startDate, "minute") > 0
        );
      case "Completed":
        return projects?.filter(
          ({ startDate, endDate, progress }) => progress === 100
          // dayjs(endDate).unix() > 0 &&
          // dayjs(endDate).diff(startDate, "minute") <= 0
        );
      case "Discarded":
        return discardedProjects;
      default:
        return [];
    }
  };

  console.log(tabData(), "xxxx");

  return (
    <CustomLayout page="projects" sidebar={false}>
      <Row className="flex p-6 justify-between">
        <Row>
          <div className="mont-500-12 text-primary-600">Recent</div>
        </Row>
        <Row gutter={12} className="items-center">
          {/* <Col>
              <CustomFilterButton />
            </Col>
            <Col>
              <CustomSearch
                placeholder="Search Projects"
                setSearch={(e) => handleSearch(e)}
              />
            </Col> */}
          <Col>
            {user.roleId === "1" && (
              <CustomButton
                icon="/images/icons/plus.svg"
                text="Create New Project"
                onClick={() => {
                  // onCancel();
                  // dispatch(
                  //   updateSchedule({ key: "newSprintModal", value: true })
                  // );
                  // dispatch(
                  //   updateSchedule({ key: "newSprintScope", value: true })
                  // );
                  dispatch(compeleteResetProjectOnboarding({}));
                  navigate("/project-onboarding");
                }}
                className="mon-500-14 text-white  bg-primary-600 "
                height="40px"
                width="200px"
                // disabled={showError || addGoal}
                // icon="/images/icons/featured-thread-icon.svg"
              />
            )}
          </Col>
        </Row>
      </Row>
      <div
        className="h-[162px] px-6 grid grid-cols-4 w-[calc(100vw)]"
        // ref={scrollRef}
      >
        {tabData()
          ?.slice(0, 4)
          ?.filter((i) => i?.title?.toLowerCase()?.includes(searchText))
          ?.map(
            (
              {
                id,
                title,
                progress,
                userId,
                fullName,
                profilePicture,
                colorCode,
                projectImage,
              },
              index
            ) => (
              <div
                className={`rounded-xl flex-col px-6 py-4 bg-white h-full  ${
                  index !== 0 && "ml-4"
                } cursor-pointer`}
                key={id}
                onClick={async () => {
                  navigate(`/overview/${id}`);
                  // navigate(`/schedule/${id}?selectedPhase=Ideation`);
                }}
              >
                <div className="flex">
                  <div className="mr-2">
                    <CustomAvatar
                      title={title}
                      size={24}
                      color={colorCode}
                      whiteText
                      fontSize="9px"
                      src={projectImage}
                    />
                  </div>
                  <span className="mont-500-14 text-gray-900">{title}</span>
                </div>
                <div className="mt-4 mont-500-10 text-gray-500">LEAD</div>
                <div className="mt-1.5 flex items-center ">
                  <div className="mr-3">
                    <CustomAvatar
                      src={profilePicture}
                      title={fullName}
                      size={24}
                      fontSize={"9px"}
                    />
                  </div>
                  <div className="mont-400-12 text-gray-500">{fullName}</div>
                </div>
                <div className="mt-4 flex">
                  <Progress
                    showInfo={false}
                    strokeColor="#165ABF"
                    percent={+progress}
                    trailColor="#EDF3F9"
                    className="progress-bar-tiny"
                  />
                  <div className="mont-500-14 text-gray-700 ml-3">
                    {Math.round(progress)}%
                  </div>
                </div>
              </div>
            )
          )}
      </div>
      <div className="px-6">
        <div
          className="bg-white rounded-2xl mt-4 shadow-table border "
          // style={{
          //   borderColor: phaseColor,
          // }}
        >
          <div className="w-full py-2.5 flex justify-between items-center px-6">
            <div className="flex justify-start items-center gap-x-4">
              <div
                onClick={() => setCurrentTab("Ongoing")}
                className={` cursor-pointer ${
                  currentTab === "Ongoing"
                    ? "mon-500-12 text-primary-600 border-b-2 border-primary-600"
                    : "mon-400-12 text-gray-500 border-b-2 border-transparent"
                }`}
              >
                Ongoing
              </div>
              <div
                onClick={() => setCurrentTab("Completed")}
                className={` cursor-pointer ${
                  currentTab === "Completed"
                    ? "mon-500-12 text-primary-600 border-b-2 border-primary-600"
                    : "mon-400-12 text-gray-500 border-b-2 border-transparent"
                }`}
              >
                Completed
              </div>
              <div
                onClick={() => {
                  setCurrentTab("Discarded");
                  getDiscarderdProjects();
                }}
                className={` cursor-pointer ${
                  currentTab === "Discarded"
                    ? "mon-500-12 text-primary-600 border-b-2 border-primary-600"
                    : "mon-400-12 text-gray-500 border-b-2 border-transparent"
                }`}
              >
                Discarded
              </div>
            </div>
            <div className="flex justify-end items-center gap-x-2 ">
              <CustomFilterButton />

              <CustomSearch
                className="bg-gray-50 h-11 flex justify-start items-center"
                placeholder="Search Projects"
                setSearch={(e) => handleSearch(e)}
              />
            </div>
          </div>
          <CustomTable
            // customPadding="p-3"

            className="custom-table cursor-pointer"
            scroll={projects ? "calc(100vh - 520px)" : "0px"}
            columns={columns}
            data={tabData()?.filter((row) =>
              row.title.toLowerCase().includes(searchText)
            )}
            dataCount={projects?.length}
            current={currentPageNo}
            pageSize={limit}
            setPageSize={setLimit}
            setCurrent={(e) => {
              setCurrentPageNo(e);
            }}
            onRow={(record, recordIndex) => {
              return {
                onClick: () => {
                  console.log(record);
                  if (currentTab === "Discarded") {
                    console.log("Hello");
                  } else {
                    navigate(`/overview/${record.id}`);
                  }
                },
              };
            }}
          />
        </div>
      </div>
    </CustomLayout>
  );
};

export default ProjectsScreen;
