import { Input } from "antd";
import React, { ReactNode } from "react";

type CustomInputWithEditProps = {
  label?: string;
  type?: string;
  disabled?: boolean;
  placeholder?: string;
  value?: string | number;
  inputClassName?: string;
  maxLength?: number;
  width?: string;
  containerClassName?: string;
  suffix?: ReactNode;
  onChange?: (e) => void;
  onPressEnter?: (e) => void;
  autoFocus?: boolean;
  formikHook?: any;
  showError?: boolean;
  errorText?: string;
  name?: string;
};

const CustomInputWithEdit = ({
  label,
  type = "input",
  disabled = false,
  placeholder,
  value,
  onChange,
  inputClassName,
  maxLength,
  width,
  containerClassName,
  suffix,
  onPressEnter,
  autoFocus,
  formikHook: formik,
  showError,
  errorText,
  name,
}: CustomInputWithEditProps) => {
  if (formik && name) {
    showError = showError || (formik.touched[name] && !!formik.errors[name]);
    errorText = errorText || (formik.touched[name] && formik.errors[name]);

    // valid =
  }
  return (
    <div
      className=""
      style={{
        width,
      }}
    >
      <div className="mon-500-14 text-gray-700">{label}</div>
      <div className="mt-1.5">
        <div
          className={`${
            containerClassName ||
            `border ${
              showError
                ? "border-error-600 shadow-input-error"
                : "border-gray-300"
            }  rounded-xl shadow-xs`
          } bg-white
           w-full flex items-center`}
        >
          <div className="w-full">
            <Input
              onPressEnter={onPressEnter}
              type={type}
              bordered={false}
              disabled={disabled}
              placeholder={placeholder}
              value={value}
              onChange={onChange}
              className={`font-medium  h-10 ${inputClassName}`}
              maxLength={maxLength}
              autoFocus={autoFocus}
              // name={name}
              // formatter={formatter}
              // parser={parser}
              // onFocus={(e) => {
              //   if(typeof onFocus === 'function') onFocus(e); ;
              //   setFocused(true)
              // }}
              // onBlur={(e) => {
              //   if(typeof onBlur === 'function') onBlur(e);
              //   setFocused(false)
              // }}
              // suffix={
              //   <img src="/images/icons/edit.svg" alt="edit" className="w-4" />
              // }
            />
          </div>
          {suffix && (
            <div
              className={`h-full px-2.5 flex-center text-grey-200 mon-400-13 border-r bg-background rounded-l-lg`}
            >
              {suffix}
            </div>
          )}
        </div>
      </div>
      {showError && (
        <div className="mt-1.5 mon-400-14 text-left text-error-500">
          {errorText}
        </div>
      )}
    </div>
  );
};

export default CustomInputWithEdit;
