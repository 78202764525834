import React from "react";

type ListIconProps = {
  color?: string;
  width?: number | string;
  height?: number | string;
};

const defaultProps = {
  color: "#667085",
  width: 18,
  height: 14,
};

// width={width} height={height}
const ListIcon = ({ color, width, height }: ListIconProps) => {
  return (
    <div className="cursor-pointer">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        fill="none"
        viewBox="0 0 18 14"
      >
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.667"
          d="M16.5 7h-10m10-5h-10m10 10h-10M3.167 7A.833.833 0 111.5 7a.833.833 0 011.667 0zm0-5A.833.833 0 111.5 2a.833.833 0 011.667 0zm0 10A.833.833 0 111.5 12a.833.833 0 011.667 0z"
        ></path>
      </svg>
    </div>
  );
};

ListIcon.defaultProps = defaultProps;

export default ListIcon;
