import React from "react";
import CustomModal from "../../../Core/Common/CustomModal";
import CustomButton from "../../../Core/Common/CustomButton";
import { useAppDispatch, useAppSelector } from "../../../Core/redux/hooks";
import {
  closeThread,
  getProjectsWithThreads,
  getThreads,
} from "../../../Core/redux/api/conversationAPI";
import { useParams } from "react-router-dom";
import { resetConversations } from "../../../Core/redux/slices/conversations.slice";

type DeleteThreadModalProps = {
  visible: boolean;
  onCancel: () => void;
};

const DeleteThreadModal = ({ visible, onCancel }: DeleteThreadModalProps) => {
  const { leaveThreadDetails } = useAppSelector((state) => state.conversations);
  const { user } = useAppSelector((state) => state.userDetails);
  const dispatch = useAppDispatch();
  const { projectId } = useParams();
  return (
    <CustomModal
      title={<p className="mon-500-16 text-error-600">Delete thread</p>}
      width={"524px"}
      visible={visible}
      body={
        <div className="max-h-[38.189386056191466vh] overflow-y-scroll">
          <div className="mon-400-14 text-gray-500 pt-6 pb-4 px-5">
            Are you sure you want to leave this thread? You will lose access to
            all the files shared in this thread
          </div>
        </div>
      }
      footer={
        <div className="flex justify-between pt-8 pb-4 px-3">
          <CustomButton
            text="Cancel"
            onClick={onCancel}
            className="border-2 border-gray-200 text-black bg-white mon-500-16 "
            height="44px"
            width="49%"
          />
          <CustomButton
            text="Yes, Delete"
            onClick={async () => {
              await dispatch(
                closeThread({
                  userId: String(user.id),
                  threadId: leaveThreadDetails.threadId,
                })
              );
              onCancel();
              dispatch(resetConversations(["showThreadProfileDrawer"]));
              dispatch(getThreads({ userId: user.id }));
              dispatch(getProjectsWithThreads({ userId: user.id }));
            }}
            className="mon-500-16 text-white  bg-error-600 "
            height="44px"
            width="49%"
          />
        </div>
      }
      onCancel={onCancel}
    />
  );
};

export default DeleteThreadModal;
