import React from "react";
import ReactDOM from "react-dom/client";
import "./App.less";
import "./index.css";
import "./fontStyles.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import store from "./Core/redux/store";
import { PersistGate } from "redux-persist/lib/integration/react";
import { persistStore } from "redux-persist";
import { Provider } from "react-redux";
import { setupAxiosInterceptors } from "./Core/utils/axiosClient";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import ErrorBoundary from "./Core/Layout/ErrorBoundary";
// import ErrorBoundary from "antd/lib/alert/ErrorBoundary";

const persistor = persistStore(store);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

Spin.setDefaultIndicator(<LoadingOutlined style={{ fontSize: 50 }} spin />);
setupAxiosInterceptors(store);
root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <BrowserRouter>
        <ErrorBoundary>
          <App />
        </ErrorBoundary>
      </BrowserRouter>
    </PersistGate>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
