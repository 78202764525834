import React, { useState, useEffect } from "react";
import { Row, Col, Dropdown, Menu } from "antd";
import { GoogleLogin } from "react-google-login";
import { gapi } from "gapi-script";
import { client } from "../../../Core/utils/axiosClient";
import { useAppDispatch, useAppSelector } from "../../../Core/redux/hooks";
import CustomEvent from "../../../Core/Common/CustomEvent";
import { useParams } from "react-router-dom";

import {
  updateSchedule,
  resetSchedule,
} from "../../../Core/redux/slices/schedule.slice";
import NewGlobalEventModal from "../../DashBoard/NewGlobalEventModal";

const CLIENT_ID =
  "412567791268-4sb9sdbur3coe43vp960c6vk8bulim92.apps.googleusercontent.com";
const SCOPE = "openid email profile https://www.googleapis.com/auth/calendar";

const UpcomingEvents: React.FC = () => {
  const { user } = useAppSelector((state) => state.userDetails);
  const { projectList } = useAppSelector((state) => state.projects);
  const { newEventModal } = useAppSelector((state) => state.schedule);

  const [projectName, setProjectName] = useState("");
  const [selectedProject, setSelectedProject] = useState({
    name: "All",
    id: 0,
  });
  const [showCalendarEvents, setShowCalendarEvents] = useState(false);
  const [isHovering, setIsHovering] = useState(false);
  const [events, setEvents] = useState([]);
  const { projectId } = useParams();
  const dispatch = useAppDispatch();
  const handleMouseOver = () => {
    setIsHovering(true);
  };

  const handleMouseOut = () => {
    setIsHovering(false);
  };

  const responseGoogle = async (response) => {
    console.log(response);

    const { data } = await client.post("/integration/calender/create-tokens", {
      code: response.code,
    });
    console.log(data);
  };

  const responseError = (error) => {
    console.log(error);
  };

  useEffect(() => {
    function start() {
      gapi.client?.init({
        clientId: CLIENT_ID,
        scope: SCOPE,
      });
    }
    gapi.load("client:auth2", start);
  }, []);

  const getEvents = async () => {
    try {
      const { data: events } = await client.get(
        "/integration/calender/get-events",
        {
          params: {
            assignee: user.id,
            projectId:
              selectedProject.id && selectedProject.id !== 0
                ? selectedProject.id
                : projectId,
          },
        }
      );
      setEvents(events.result);
    } catch (e) {
      console.log(e);
    }
  };

  const checkIfgoogleCalenderIsIntegrated = async () => {
    try {
      const { data } = await client.get(
        "/integration/calender/refresh-token-status",
        {
          params: { userId: user.id },
        }
      );
      if (data.value) {
        setShowCalendarEvents(true);
        getEvents();
      }
    } catch (e) {
      setShowCalendarEvents(false);
    }
  };

  function sortEventsByDate(events) {
    events.sort((a, b) => {
      const dateA = new Date(JSON.parse(a.meetMetaData).start.dateTime) as any;
      const dateB = new Date(JSON.parse(b.meetMetaData).start.dateTime) as any;
      return dateA - dateB;
    });
    return events;
  }

  useEffect(() => {
    getEvents();
  }, [selectedProject.id]);

  useEffect(() => {
    checkIfgoogleCalenderIsIntegrated();
  }, []);

  const isOverview = projectId ? true : false;

  return (
    <div className="flex-col justify-between">
      <div className="flex justify-between items-center">
        <div className="flex gap-x-2  mon-500-16 text-black">
          {isOverview ? "Upcoming Events" : "Today’s events"}{" "}
          <img
            src="/images/icons/plus-black.svg"
            onClick={() =>
              dispatch(
                updateSchedule({
                  key: "newEventModal",
                  value: true,
                })
              )
            }
          />
        </div>

        {isOverview ? (
          <></>
        ) : (
          <Dropdown
            overlay={
              <Menu className="w-[200px] rounded-lg custom-menu-dropdown bg-error-600 shadow-dropdown">
                <Menu.Item
                  className="py-2.5 px-4"
                  onClick={() => setSelectedProject({ name: "All", id: 0 })}
                  key={0}
                >
                  <div className="flex items-center justify-between gap-y-1 pt-1.5">
                    <p className="mon-400-12 whitespace-nowrap text-gray-900">
                      All
                    </p>
                    {selectedProject.name === "All" && (
                      <img src="/images/icons/check.svg" alt="" />
                    )}
                  </div>
                </Menu.Item>
                {projectList.map((item) => (
                  <Menu.Item
                    className="py-2.5 px-4"
                    onClick={() =>
                      setSelectedProject({ name: item.title, id: item.id })
                    }
                    key={item.id}
                  >
                    <div className="flex items-center justify-between gap-y-1 pt-1.5">
                      <p className="mon-400-12 whitespace-nowrap text-gray-900">
                        {item.title}
                      </p>
                      {selectedProject.name === item.title && (
                        <img src="/images/icons/check.svg" alt="" />
                      )}
                    </div>
                  </Menu.Item>
                ))}
              </Menu>
            }
            trigger={["click"]}
          >
            <div className="flex items-center cursor-pointer gap-x-3 mr-3">
              <p className="mon-500-14 text-gray-500 whitespace-nowrap">
                {selectedProject.name}
              </p>
              <img
                src="/images/icons/chevron-down-gray.svg"
                className="w-4 h-4"
              />
            </div>
          </Dropdown>
        )}
      </div>
      <div className="mon-400-12 text-gray-500 mt-1.5">
        You have{" "}
        <span className="mon-500-12 text-gray-900">{events.length} events</span>{" "}
      </div>
      <div className="flex cursor-pointer"></div>

      <div>
        {showCalendarEvents ? (
          // <div>
          //   <Row className="pt-4">
          //     <Col className="mon-400-12 text-gray-500">08:00</Col>
          //     <Col className="pl-5 pt-2.5">
          //       <div
          //         className="bg-gray-200"
          //         style={{
          //           width: "239px",
          //           height: "1px",
          //         }}
          //       ></div>
          //     </Col>
          //   </Row>
          //   <Row className="pt-6">
          //     <Col className="mon-400-12 text-gray-500">09:00</Col>
          //     <Col className="pl-5 pt-2.5">
          //       <div
          //         className="bg-gray-200"
          //         style={{
          //           width: "239px",
          //           height: "1px",
          //         }}
          //       ></div>
          //     </Col>
          //   </Row>
          //   <Row className="pt-6">
          //     <Col className="mon-400-12 text-gray-500">10:00</Col>
          //     <Col className="pl-5 pt-2.5">
          //       <div
          //         className="bg-gray-200"
          //         style={{
          //           width: "239px",
          //           height: "1px",
          //         }}
          //       ></div>
          //     </Col>
          //   </Row>
          //   <Row className="pt-3">
          //     <Col className="mon-400-12 text-error-600">10:30</Col>
          //     <Col className="pl-5 pt-2.5">
          //       <div
          //         className="bg-error-600"
          //         style={{
          //           width: "239px",
          //           height: "1px",
          //         }}
          //       ></div>
          //     </Col>
          //   </Row>
          //   <Row className="pt-3">
          //     <Col className="mon-400-12 text-gray-500">11:00</Col>
          //     <Col className="pl-5 pt-2.5">
          //       <div
          //         className="bg-gray-200"
          //         style={{
          //           width: "239px",
          //           height: "1px",
          //         }}
          //       ></div>
          //     </Col>
          //   </Row>
          //   <Row className="pt-6">
          //     <Col className="mon-400-12 text-gray-500">12:00</Col>
          //     <Col className="pl-5 pt-2.5">
          //       <div
          //         className="bg-gray-200"
          //         style={{
          //           width: "239px",
          //           height: "1px",
          //         }}
          //       ></div>
          //     </Col>
          //   </Row>
          //   <Row className="pt-6">
          //     <Col className="mon-400-12 text-gray-500">13:00</Col>
          //     <Col className="pl-5 pt-2.5">
          //       <div
          //         className="bg-gray-200"
          //         style={{
          //           width: "239px",
          //           height: "1px",
          //         }}
          //       ></div>
          //     </Col>
          //   </Row>
          //   <Row className="pt-0">
          //     <Col className="pl-12 pt-0.5 z-20 rounded-lg">
          //       <div
          //         className="bg-yellow-600"
          //         style={{
          //           width: "260px",
          //           height: "56px",
          //           borderRadius: "12px",
          //         }}
          //       >
          //         <div className="flex-column justify-between items-center pl-2 pt-1">
          //           <div className="flex mon-500-12 text-gray-600">
          //             Mazi x Antarctica Project Introduction
          //           </div>
          //           <div className="flex mon-500-12 text-gray-500">
          //             13.15 - 13.45{" "}
          //           </div>
          //         </div>
          //       </div>
          //     </Col>
          //   </Row>
          //   <Row className="pt-1">
          //     <Col className="mon-400-12 text-gray-500">14:00</Col>
          //     <Col className="pl-5 pt-2.5">
          //       <div
          //         className="bg-gray-200"
          //         style={{
          //           width: "239px",
          //           height: "1px",
          //         }}
          //       ></div>
          //     </Col>
          //   </Row>
          // </div>
          <div>
            {sortEventsByDate(events).map((event) => {
              return <CustomEvent key={event.id} event={event} />;
            })}
          </div>
        ) : (
          //   </div>
          <div className="mt-6 px-2 pt-2 pb-4 bg-primary-50 rounded-lg">
            <div className="flex justify-between items-center ">
              <div className="flex  items-center">
                <img
                  src="/images/icons/Googlecalendar.svg"
                  className="h-5 w-5"
                />
                <p className="text-gray-700 text-xs font-semibold ml-3.5">
                  Google Calendar
                </p>
              </div>
              <img src="/images/icons/Question.svg" className="h-5 w-5" />
            </div>
            <p className="text-xs text-gray-500 font-normal pt-3">
              Bring all your meetings here without leaving Waddle!
            </p>
            <GoogleLogin
              clientId={CLIENT_ID}
              render={(renderProps) => (
                <button
                  onClick={renderProps.onClick}
                  disabled={renderProps.disabled}
                  className="flex justify-center  items-center mt-2.5 w-full shadow-button rounded-xl border border-gray-200 bg-white"
                >
                  <img
                    src="/images/icons/Googlecalendar.svg"
                    className="h-5 w-5"
                  />
                  <p className="text-sm font-medium text-gray-700 ml-2.5  py-2.5">
                    Integrate Google Calendar
                  </p>
                </button>
              )}
              onSuccess={responseGoogle}
              onFailure={responseError}
              cookiePolicy={"single_host_origin"}
              responseType="code"
              accessType="offline"
              scope={SCOPE}
              uxMode="redirect"
            />
          </div>
        )}
      </div>
      {newEventModal && (
        <NewGlobalEventModal
          visible={newEventModal}
          onCancel={() =>
            dispatch(resetSchedule(["newEventModal", "selectedEvent"]))
          }
        />
      )}
    </div>
  );
};

export default UpcomingEvents;
