import { Popover } from "antd";
import React, { ReactNode } from "react";
import PropTypes from "prop-types";
import { TooltipPlacement } from "antd/lib/tooltip";

const propTypes = {};
type CustomPopoverProps = {
  color?: string;
  text?: string;
  dot?: boolean;
  className?: string;
  title?: any;
  content?: any;
  children: ReactNode;
  trigger?: string;
  placement?: TooltipPlacement;
  visible?: boolean;
};

const defaultProps = {
  title: "",
  content: "",
  children: null,
  trigger: "click",
  placement: "bottomLeft",
  visible: undefined,
  className: "",
};

export default function CustomPopover({
  title,
  content,
  children,
  trigger,
  placement,
  visible,
  className,
}: CustomPopoverProps) {
  return (
    <Popover
      visible={visible}
      placement={placement}
      title={title}
      id={className ? className : "newPop"}
      content={content}
      trigger={trigger}
      overlayInnerStyle={{ color: "red" }}
    >
      {children}
    </Popover>
  );
}

CustomPopover.propTypes = propTypes;
CustomPopover.defaultProps = defaultProps;
