import React, { useEffect, useState } from "react";
import { Drawer } from "antd";
import CustomButton from "../../../Core/Common/CustomButton";
import CopyButton from "../../../Core/Common/CopyButton";
import CustomAvatar from "../../../Core/Common/CustomAvatar";
import CustomBadge from "../../../Core/Common/CustomBadge";
import dayjs from "dayjs";
import useTimezone from "../../../Core/hooks/useTimezone";
import { useAppDispatch, useAppSelector } from "../../../Core/redux/hooks";
import {
  resetConversations,
  updateConversationsMultiple,
} from "../../../Core/redux/slices/conversations.slice";
import { getPrivateMessages } from "../../../Core/redux/api/conversationAPI";
import { useNavigate, useParams } from "react-router-dom";
import { markSpoc } from "../../../Core/redux/api/projectAPI";

type ProfileDrawerProps = {
  visible: boolean;
  onClose: () => void;
  user: {
    firstname: string;
    lastname: string;
    email: string;
    mobile: string;
    roleName: string;
    profilePicture: string;
    location: string;
    createdAt: string;
    lat: number;
    long: number;
    jobTitle: string;
    company: string;
    userId?: string;
    spoc?: number;
  };
};

const ProfileDrawer = ({ visible, onClose, user }: ProfileDrawerProps) => {
  const timezone = useTimezone(user.lat, user.long);
  const { user: currentUser } = useAppSelector((state) => state.userDetails);
  const navigate = useNavigate();
  const { userList } = useAppSelector((state) => state.overview);
  const { projectId } = useParams();
  console.log(user, "timezone");
  const [spoc, setSpoc] = useState(0);

  useEffect(() => {
    const getUser = userList.find((u) => u.userId == user.userId);
    setSpoc(getUser?.spoc);
  }, [userList, user]);
  const dispatch = useAppDispatch();

  const joinPrivateThread = async () => {
    const reciver = user;
    dispatch(
      updateConversationsMultiple([
        {
          value: { ...reciver, type: "private" },
          key: "currentThread",
        },
        { key: "currentThreadMessage", value: [] },
      ])
    );
    dispatch(
      resetConversations(["replyMessage", "editMessage", "newMessageModal"])
    );
    navigate("/conversations");
    await dispatch(
      getPrivateMessages({
        userId: currentUser.id,
        reciverId: +reciver.userId,
        limit: 100,
        offset: 0,
      })
    );
  };

  const handleMarkSpoc = async () => {
    dispatch(
      markSpoc({
        projectId,
        userId: +user.userId,
        spoc: spoc === 1 ? 0 : 1,
      })
    );
  };
  return (
    <Drawer
      placement="right"
      open={visible}
      closable={false}
      className="custom-drawer"
      width="33.875rem"
      onClose={onClose}
      footer={
        <div className="py-4 shadow-upward px-6">
          <CustomButton
            text="Message"
            onClick={joinPrivateThread}
            icon="/images/icons/message-outline-blue.svg"
            className=" text-primary-600   bg-primary-50 mon-500-14 "
            height="40px"
            width="133px"
          />
          {/* <CustomButton
            text="Share details"
            //onClick={onCancel}
            icon="/images/icons/share.svg"
            className=" text-primary-600 ml-3  bg-primary-50 mon-500-14 "
            height="40px"
            width="162px"
          /> */}
          <CustomButton
            text={spoc === 1 ? "Unmark as SPOC" : "Mark as SPOC"}
            onClick={handleMarkSpoc}
            icon={
              spoc === 1
                ? "/images/icons/minus-circle.svg"
                : "/images/icons/check-circle.svg"
            }
            className={`${
              spoc === 1 ? "text-error-600" : "text-primary-600"
            } ml-3   mon-500-14`}
            height="40px"
            width="162px"
          />
        </div>
      }
    >
      <div>
        <button onClick={onClose}>
          <img src="/images/icons/chevron-right-double.svg" alt="close" />
        </button>
        <div className="flex flex-col items-center">
          <div className="mt-4 relative flex justify-center">
            <CustomAvatar
              src={user.profilePicture}
              title={user.firstname + " " + user.lastname}
              size={128}
              fontSize={128 / 3}
              style={{
                border: spoc === 1 ? "2px solid #165ABF" : "",
              }}
            />
            {spoc === 1 && (
              <div className="mon-500-12 h-[22px] w-[51px] absolute rounded bottom-[-5px] text-white bg-primary-600 flex justify-center items-center">
                <p>SPOC</p>
              </div>
            )}
          </div>
          <h4 className="text-center capitalize mon-500-18 mt-6 text-black">
            {user.firstname || "N/A"} {user.lastname}
          </h4>
        </div>
        <div>
          <div className="mt-7">
            <p className="uppercase mon-500-10 text-gray-500">email</p>
            <div className="flex gap-x-3 items-center mt-2">
              <p className="mon-500-14 text-black ">{user.email || "N/A"}</p>
              <CopyButton
                text={user.email}
                //className="mt-1.5"
              />
            </div>
          </div>
          <div className="mt-9">
            <p className="uppercase mon-500-10 text-gray-500">mobile number</p>
            <p className="mon-500-14 text-black mt-2">{user.mobile || "N/A"}</p>
          </div>
          <div className="mt-9">
            <p className="uppercase mon-500-10 text-gray-500">company</p>
            <p className="mon-500-14 text-black mt-2">
              {user.company || "N/A"}
            </p>
          </div>
          <div className="mt-9">
            <p className="uppercase mon-500-10 text-gray-500">job title</p>
            <p className="mon-500-14 text-black mt-2">
              {user.jobTitle || "N/A"}
            </p>
          </div>
          <div className="mt-9">
            <p className="uppercase mon-500-10 text-gray-500">project role</p>
            <p className="mon-500-14 text-black mt-2">
              <CustomBadge
                dot
                text={user.roleName}
                color="#F40849"
                className="mon-500-12 pt-0.5"
              />
            </p>
          </div>

          <div className="mt-9">
            <p className="uppercase mon-500-10 text-gray-500">
              Currently located in
            </p>
            <div className="flex justify-between">
              <p className="mon-500-14 text-black mt-2">{user.location}</p>
              <div className="flex items-center">
                <img src="/images/icons/clock.svg" className="w-5 h-5 " />
                <p className="mon-500-12 text-gray-500  ml-2">
                  {timezone.timeZoneId
                    ? dayjs().tz(timezone.timeZoneId).format("hh:mm a")
                    : "N/A"}{" "}
                  Local time
                </p>
              </div>
            </div>
          </div>
          <div className="mt-9">
            <p className="uppercase mon-500-10 text-gray-500">Added on</p>
            <p className="mon-500-14 text-black mt-2">
              {user.createdAt
                ? dayjs(user.createdAt).local().format("DD MMM YYYY, hh:mm a")
                : "N/A"}
            </p>
          </div>
        </div>
      </div>
    </Drawer>
  );
};

export default ProfileDrawer;
