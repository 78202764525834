import { env } from "process";
import React, { useState, useEffect } from "react";
import GoogleLogin from "react-google-login";
import { client } from "../../../../Core/utils/axiosClient";
import CustomAvatar from "../../../../Core/Common/CustomAvatar";
import CustomInput from "../../../../Core/Common/CustomInput";
import { Checkbox, Divider, Spin, Upload } from "antd";
import CustomButton from "../../../../Core/Common/CustomButton";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useAppDispatch, useAppSelector } from "../../../../Core/redux/hooks";
import AntdImgCrop from "antd-img-crop";
import uniqid from "uniqid";
import axios from "axios";
import CustomAddressInput from "../../../../Core/Common/CustomAddressInput";
import dayjs from "dayjs";
import { updateUserProfile } from "../../../../Core/redux/api/userAPI";
import useTimezone from "../../../../Core/hooks/useTimezone";

const AccountInfoField = ({ title, children, className = "" }) => (
  <div>
    <div className="flex">
      <div className="mon-500-12 w-44">{title}</div>
      <div className="flex w-full">
        <div className={className}>{children}</div>
      </div>
    </div>
    <Divider />
  </div>
);

const MyAccount = () => {
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.userDetails);

  const {
    firstName,
    lastName,
    email,
    mobile,
    profilePicture,
    company,
    jobTitle,
    lat,
    long,
    location,
  } = user;

  const formik = useFormik({
    initialValues: {
      firstName: firstName,
      lastName: lastName,
      email: email,
      company: company,
      jobTitle: jobTitle,
      locationName: location,
      timezone: null,
      mobile: mobile,
      cords: { lat, long },
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      firstName: Yup.string()
        .required("Please enter your first name")
        .typeError("Please enter your first name")
        .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
      lastName: Yup.string()
        .required("Please enter your last name")
        .typeError("Please enter your last name")
        .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
      email: Yup.string()
        .email("Please enter a valid email address")
        .required("Please enter the email address")
        .typeError("Please enter the email address"),
      mobile: Yup.number()
        .required("This field is required")
        .typeError("This field is required"),
      // company: Yup.string().typeError("This field is required"),
      // // .required("This field is required")
      // // .typeError("This field is required"),
      // jobTitle: Yup.string(),
      // // .required("This field is required")
      // // .typeError("This field is required"),
      // locationName: Yup.string().nullable(),
      // // .required("This field is required")
      // // .typeError("This field is required")
      // cords: Yup.object().shape({
      //   lat: Yup.number(),
      //   long: Yup.number(),
      // }),
    }),
    onSubmit: (values) => {
      console.log("formik values: ", values);
      const {
        firstName,
        lastName,
        company,
        jobTitle,
        locationName,
        mobile,
        cords,
      } = values;

      dispatch(
        updateUserProfile({
          firstname: firstName,
          lastname: lastName,
          company,
          jobTitle,
          location: locationName,
          mobile,
          lat: cords.lat,
          long: cords.long,
        })
      );
      // console.log(values);
      // setIsLoading(true);
    },
  });

  const [fileList, setFileList] = useState([]);

  const [fileUploadedList, setFileUploadedList] = useState([]);

  const handleChange = ({ fileList: newFileList }) => {
    console.log(newFileList, "newFileList dleetetest");
    setFileList(newFileList);
    const fileData = [];

    console.log(newFileList);

    newFileList.forEach((file) => {
      const { name, size, type, lastModified, status } = file;

      if (status !== "done") return;

      file.response.forEach((xfile) => {
        const { fileUrl: url, thumbnail } = xfile;

        fileData.push({
          name,
          url,
          size,
          type,
          lastModified,
          savedInVault: false,
          thumbnail: thumbnail.fileUrl,
        });
        dispatch(
          updateUserProfile({
            profilePicture: url,
          })
        );
      });
    });
    console.log(fileData, "file");
    setFileUploadedList(fileData);

    // setNewProjectDetails({
    //     ...newProjectDetails,
    //     projectImage: fileData[0]?.url || "",
    // });
  };

  const timezone = useTimezone(lat, long);

  useEffect(() => {
    if (timezone && timezone.timeZoneId) {
      const currentTime = dayjs().tz(timezone.timeZoneId).format("hh:mm a");
      const timezoneField = `${currentTime}, ${timezone.timeZoneName}`;
      formik.setFieldValue("timezone", timezoneField);
    }
  }, [lat, timezone, long, formik.getFieldProps("cords").value]);

  const uploadImage = (options: any) => {
    // below file element is the file you uploaded
    const { onSuccess, onError, file, onProgress } = options;
    const fmData = new FormData();
    const config = {
      headers: {
        "content-type": "multipart/form-data",
        // Authorization: "Bearer " + userLocal.token,
      },
      onUploadProgress: (event) => {
        onProgress({ percent: (event.loaded / event.total) * 100 });
      },
    };
    console.log("FILE: ", file);
    // create copy file and change the name when it is appending to data
    const data = new FormData();
    data.append(
      "file",
      file,
      uniqid(`${file.name.split(".")[0]}-`, `.${file.name.split(".").at(-1)}`)
    ); // replace all the spaces in the file name with -
    const _file = data.get("file");
    fmData.append("image", _file);
    try {
      // then send the copy file to the server
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "/v1/upload/storej",
          fmData,
          config
        )
        .then((res) => {
          console.log("UPLOADED", res.data);
          onSuccess(res.data);
        });
    } catch (err) {
      onError({ err });
    }
  };

  return (
    // <Spin spinning={isLoading}>
    <div className="flex">
      <div className="w-1/3">
        <div className="px-4 pt-6">
          <p className="mon-400-12 text-gray-500">
            Update your personal details here
          </p>
          <p className="mon-500-12 px-2 text-gray-900 mt-6">Profile Photo</p>

          <div className="ml-2">
            <div className="flex flex-col">
              <CustomAvatar
                className="mt-6"
                title={`${firstName} ${lastName}`}
                size={190}
                fontSize={190 / 3}
                whiteText
                src={profilePicture}
              />
              <div className="mt-6">
                <AntdImgCrop rotationSlider modalClassName="image-crop-modal">
                  <Upload
                    action={process.env.REACT_APP_API_URL + "/v1/upload"}
                    // headers={{ Authorization: "Bearer " + user.token }}
                    customRequest={uploadImage}
                    fileList={fileList}
                    onChange={handleChange}
                    showUploadList={false}
                  >
                    <div className="flex items-center ">
                      <img src="/images/icons/edit-2.svg" className="ml-3" />
                      <p className="mon-500-14 text-primary-600 pl-2">
                        {user.profilePicture ? "Change Photo" : "Upload Photo"}
                      </p>
                    </div>
                  </Upload>
                </AntdImgCrop>
              </div>
            </div>
            {user.profilePicture && (
              <div
                onClick={() => null}
                className="flex cursor-pointer justify-start items-center gap-x-2 mt-2"
              >
                <img className="ml-1" src="/images/icons/cross.svg" alt="" />
                <div
                  className="mon-500-14 text-gray-500 ml-1"
                  onClick={() =>
                    dispatch(
                      updateUserProfile({
                        profilePicture: "",
                      })
                    )
                  }
                >
                  Remove Photo
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="w-2/3 pt-16">
        <div className="flex flex-col mr-5">
          <div>
            <div className="flex">
              <div className="mon-500-12 w-44">Name</div>
              <div className="flex w-full">
                <CustomInput
                  width="w-full"
                  placeholder="First name"
                  {...formik.getFieldProps("firstName")}
                  formikHook={formik}
                />
                <div className="w-6"></div>
                <CustomInput
                  width="w-full"
                  placeholder="Last name"
                  {...formik.getFieldProps("lastName")}
                  formikHook={formik}
                />
              </div>
            </div>
            <Divider />
          </div>
          <div>
            <div className="flex">
              <div className="mon-500-12 w-44">Email</div>
              <div className="flex w-full">
                <CustomInput
                  width="w-full"
                  placeholder="Email"
                  {...formik.getFieldProps("email")}
                  formikHook={formik}
                />
              </div>
            </div>
            <Divider />
          </div>
          <AccountInfoField title="Phone number" className="flex-col">
            <CustomInput
              width="w-full"
              placeholder="Phone number"
              {...formik.getFieldProps("mobile")}
              formikHook={formik}
            />
            <div className="mt-1.5 mon-400-12 text-gray-500">
              Please ensure you enter the right country code so everyone can
              reach you easily
            </div>
            <Checkbox className="mt-4">
              Show my phone number in my profile
            </Checkbox>
          </AccountInfoField>
          <AccountInfoField title="Company" className="flex-col">
            <CustomInput
              width="w-full"
              placeholder="Company name"
              {...formik.getFieldProps("company")}
              formikHook={formik}
            />
            <div className="mt-1.5 mon-400-12 text-gray-500">
              Please ensure you enter the right country code so everyone can
              reach you easily
            </div>
            <Checkbox className="mt-4">
              Show my company name in my profile
            </Checkbox>
          </AccountInfoField>
          <AccountInfoField title="Job Title" className="flex-col">
            <CustomInput
              width="w-full"
              placeholder="Job title"
              {...formik.getFieldProps("jobTitle")}
              formikHook={formik}
            />
            <div className="mt-1.5 mon-400-12 text-gray-500">
              Please ensure you enter the right country code so everyone can
              reach you easily
            </div>
            <Checkbox className="mt-4">
              Show my job title in my profile
            </Checkbox>
          </AccountInfoField>

          <AccountInfoField className="w-full" title="City & Country">
            <div className="custom-address-input">
              <CustomAddressInput
                width="80%"
                placeholder="Select City"
                fullBorder={true}
                name="locationName"
                // value={locationName}
                {...formik.getFieldProps("locationName")}
                onChange={(value) =>
                  formik.setFieldValue("locationName", value)
                }
                onSelect={({
                  locationName,
                  fullAddress,
                  lat,
                  long,
                  timezone,
                }) => {
                  console.log(
                    "locationOnSelect: ",
                    locationName,
                    fullAddress,
                    lat,
                    long,
                    timezone
                  );
                  formik.setFieldValue("locationName", fullAddress);
                  formik.setFieldValue("cords", { lat, long });

                  if (!locationName) return;
                  const { timeZoneId, timeZoneName } = timezone;
                  const currentTime = dayjs().tz(timeZoneId).format("hh:mm a");
                  const timezoneField = `${currentTime}, ${timeZoneName}`;
                  formik.setFieldValue("timezone", timezoneField);
                }}
                getTimezone
                formikHook={formik}
              />
            </div>
            {/* <CustomInput
                width="w-full"
                placeholder="Select City"
                {...formik.getFieldProps("locationName")}
                formikHook={formik}
              /> */}
          </AccountInfoField>

          {formik.values.cords.lat ? (
            <AccountInfoField title="Timezone" className="w-full">
              <CustomInput
                width="w-full"
                disabled
                placeholder=""
                {...formik.getFieldProps("timezone")}
                formikHook={formik}
              />
            </AccountInfoField>
          ) : null}
        </div>
        <div className="mt-2 mb-2">
          <CustomButton
            text="Save Changes"
            disabled={!(formik.isValid && formik.dirty)}
            type="submit"
            className={`border text-white ${
              !(formik.isValid && formik.dirty)
                ? "bg-primary-100"
                : "bg-primary-600"
            } mon-500-16 mr-6`}
            onClick={() => formik.handleSubmit()}
            height="36px"
          />
          <CustomButton
            text="Cancel"
            className="border border-gray-200 text-black bg-white mon-500-16 "
            height="36px"
            width="110px"
          />
        </div>
      </div>
    </div>
    // </Spin>
  );
};

export default MyAccount;
