import React from "react";
import IdentityProviderGoogleSlice from "./IdentityProviderGoogleSlice";

const IdentityProviderSlice = () => {
  return (
    <div className="flex-center">
      <IdentityProviderGoogleSlice />
      <div className="w-14 h-14 rounded-xl border border-gray-100 flex-center mr-3.5 cursor-pointer">
        <img
          src="/images/icons/microsoft_icon.svg"
          className="w-6"
          alt="Microsoft"
        />
      </div>
    </div>
  );
};

export default IdentityProviderSlice;
