import { createSlice } from "@reduxjs/toolkit";
import OpenAndShareAccessFile from "../../../Modules/Vault/OpenAndShareAccessFile";
import { getComments } from "../api/conversationAPI";
import { userAuthenticate } from "../api/userAPI";
import {
  getAllFolders,
  updateVaultFolder,
  copyFolderAPI,
  copyFileAPI,
  copyLinkAPI,
} from "../api/vaultAPI";
import { getAllFiles } from "../api/vaultAPI";
import { getAllLinks } from "../api/vaultAPI";

//We do not recommend the object notation for extraReducers any more, especially with TypeScript you should be using the builder notation --by redux toolkit team.

const initialState = {
  loading: false,
  error: {},
  status: "idle",
  addFileModal: false,
  addFolderModal: false,
  preFilledVaultModalValues: {
    goalId: null,
    phaseId: null,
    users: [],
    notificationId: "",
  },
  addLinkModal: false,
  openCommentsModal: {
    visible: false,
    docType: "file",
    docId: "",
    name: "",
    comments: [],
    users: [],
  },
  deleteFolderModal: {
    visible: false,
    id: null,
  },
  deleteFileModal: {
    visible: false,
    id: null,
  },
  deleteLinkModal: {
    visible: false,
    id: null,
  },
  copyModal: {
    visible: false,
    docType: "",
    id: null,
  },
  moveToFolderModal: {
    visible: false,
    docId: "",
    docType: "",
  },
  requestAccessModal: false,
  requestUploadModal: false,
  downloadModal: {
    visible: false,
    type: "",
    id: null,
  },
  openLinkModal: {
    visible: false,
    id: null,
  },
  folders: [],
  subfolders: [],
  files: [],
  links: [],
  shareAccessDoc: {
    docId: "",
    users: [],
    docType: "",
    docDetails: {
      folderName: "",
      fileName: "",
      linkName: "",
      name: "",
    },
    notificationId: "",
  },
  showDocDetailsDrawer: {
    visible: false,
    docId: "",
    docType: "",
    docDetails: {},
  },
  transferOwnershipModal: {
    visible: false,
    id: null,
    docType: null,
    usersWithAccess: []
  },

  openAndShareAccessFile: {
    visible: false,
    id: "",
  },

  mySpaceFolder: null,
};

export const vaultSlice = createSlice({
  name: "vault",
  initialState,
  reducers: {
    resetToInitialVault: (state, action) => {
      Object.keys(initialState).forEach(
        (key) => (state[key] = initialState[key])
      );
    },
    updateVault: (state, action) => {
      state[action.payload.key] = action.payload.value;
    },

    resetVault: (state, action) => {
      action.payload.map((ele) => {
        state[ele] = initialState[ele];
      });
    },
    // setUser: (state, action) => {
    //   state.user = action.payload;
    //   state.loading = false;
    // },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllFolders.pending, (state, { payload }) => {
      state.folders = [];
      state.subfolders = [];
      state.loading = true;
      state.status = "pending";
    });
    builder.addCase(getAllFolders.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.status = "fulfilled";
      state.folders = payload.result;
      state.error = null;
    });
    builder.addCase(getAllFolders.rejected, (state, { payload }) => {
      state.loading = false;
      state.status = "rejected";
      state.error = payload;
    });

    builder.addCase(getAllFiles.pending, (state, { payload }) => {
      state.loading = true;
      state.status = "pending";
    });
    builder.addCase(getAllFiles.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.status = "fulfilled";
      state.files = payload.result;
      state.error = null;
    });
    builder.addCase(getAllFiles.rejected, (state, { payload }) => {
      state.loading = false;
      state.status = "rejected";
      state.error = payload;
    });

    builder.addCase(getAllLinks.pending, (state, { payload }) => {
      state.loading = true;
      state.status = "pending";
    });
    builder.addCase(getAllLinks.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.status = "fulfilled";
      state.links = payload.result;
      state.error = null;
    });
    builder.addCase(getAllLinks.rejected, (state, { payload }) => {
      state.loading = false;
      state.status = "rejected";
      state.error = payload;
    });

    builder.addCase(getComments.pending, (state, { payload }) => {
      state.loading = true;
      state.status = "pending";
    });
    builder.addCase(getComments.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.status = "fulfilled";
      state.openCommentsModal = {
        ...state.openCommentsModal,
        comments: payload.result,
      };
      state.error = null;
    });
    builder.addCase(getComments.rejected, (state, { payload }) => {
      state.loading = false;
      state.status = "rejected";
      state.error = payload;
    });
    builder.addCase(updateVaultFolder.pending, (state, { payload }) => {
      state.loading = true;
      state.status = "pending";
    });
    builder.addCase(updateVaultFolder.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.status = "fulfilled";
      state.error = null;
    });
    builder.addCase(updateVaultFolder.rejected, (state, { payload }) => {
      state.loading = false;
      state.status = "rejected";
      state.error = payload;
    });
    builder.addCase(copyFolderAPI.pending, (state, { payload }) => {
      state.loading = true;
      state.status = "pending";
    });
    builder.addCase(copyFolderAPI.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.status = "fulfilled";
      state.error = null;
    });
    builder.addCase(copyFolderAPI.rejected, (state, { payload }) => {
      state.loading = false;
      state.status = "rejected";
      state.error = payload;
    });
    builder.addCase(copyFileAPI.pending, (state, { payload }) => {
      state.loading = true;
      state.status = "pending";
    });
    builder.addCase(copyFileAPI.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.status = "fulfilled";
      state.error = null;
    });
    builder.addCase(copyFileAPI.rejected, (state, { payload }) => {
      state.loading = false;
      state.status = "rejected";
      state.error = payload;
    });
    builder.addCase(copyLinkAPI.pending, (state, { payload }) => {
      state.loading = true;
      state.status = "pending";
    });
    builder.addCase(copyLinkAPI.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.status = "fulfilled";
      state.error = null;
    });
    builder.addCase(copyLinkAPI.rejected, (state, { payload }) => {
      state.loading = false;
      state.status = "rejected";
      state.error = payload;
    });
  },
});

export const { updateVault, resetVault, resetToInitialVault } =
  vaultSlice.actions;
export default vaultSlice.reducer;
