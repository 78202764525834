import React from "react";

const CustomOnboardingInput = ({
  placeholder,
  value,
  onChange,
  className,
  customEnd,
  formikHook: formik,
  name,
  showError,
  errorText,
  helperText,
  onClear,
}: {
  placeholder: string;
  value: string;
  onChange: (e, value) => void;
  className?: string;
  customEnd?: React.ReactNode;
  formikHook?: any;
  name?: string;
  showError?: boolean;
  errorText?: string;
  helperText?: string;
  wrapperClassName?: string;
  onFocusChange?: (isTrue, e) => void;
  onClear?: () => void;
}) => {
  if (formik && name) {
    showError = showError || (formik.touched[name] && !!formik.errors[name]);
    errorText = errorText || (formik.touched[name] && formik.errors[name]);
  }

  return (
    <div>
      <div
        className={`
      
      ${
        showError
          ? "border-b-2 border-error-600"
          : value?.length > 0
          ? "border-b-2 border-primary-600"
          : " border-b-[1px] border-gray-400"
      }

      ${className}`}
      >
        <div className="flex justify-between items-center">
          <input
            name={name}
            className="outline-none bg-transparent w-full"
            placeholder={placeholder}
            value={value}
            onChange={(e) => onChange(e, e.target.value)}
          />
          {customEnd
            ? customEnd
            : value && (
                <div className="cursor-pointer" onClick={onClear}>
                  <img
                    src="/images/icons/sector-icons/input-cross.svg"
                    alt=""
                  />
                </div>
              )}
        </div>
      </div>
      {showError && (
        <div className="mt-1.5 mon-400-14 text-left text-error-500">
          {errorText}
        </div>
      )}
      {helperText && (
        <div className="mt-1.5 mon-400-14 text-left text-error-500">
          {helperText}
        </div>
      )}
    </div>
  );
};

export default CustomOnboardingInput;
