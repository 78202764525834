import React from "react";
import CustomButton from "../../Core/Common/CustomButton";

const NewScopeBadge = ({
  style = {
    width: "120px",
    height: "32px",
  },
  textClassName,
}: {
  style?: object;
  textClassName?: string;
}) => {
  return (
    <div
      className="flex ml-2 items-center bg-pink-25 justify-center rounded-xl"
      style={style}
    >
      <div className=" w-3 h-3">
        <img
          src="/images/icons/plus-circle.svg"
          className="w-full h-full"
          alt="add-item"
        />
      </div>
      <div
        className={`flex ${
          textClassName || "mon-500-14"
        } whitespace-nowrap truncate pl-2 text-pink-700`}
      >
        New Scope
      </div>
    </div>
  );
};

export default NewScopeBadge;
