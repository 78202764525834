import React, { useEffect, useState } from "react";
import { Checkbox } from "antd";
import CustomButton from "../../../Core/Common/CustomButton";
import CustomModal from "../../../Core/Common/CustomModal";
import CustomSearch from "../../../Core/Common/CustomSearch";
import { useAppDispatch, useAppSelector } from "../../../Core/redux/hooks";
import CustomAvatar from "../../../Core/Common/CustomAvatar";
import { CustomSelectWithInlineSearch } from "../../../Core/Common/CustomSelectWithSearch";
import CustomSelect from "../../../Core/Common/CustomSelect";
import { client } from "../../../Core/utils/axiosClient";
import { useParams } from "react-router-dom";
import { getAllRoles } from "../../../Core/redux/api/settingsAPI";
import { getProjectDetatis } from "../../../Core/redux/api/projectAPI";

const AddMembersModal = ({
  visible,
  onClose,
  prefiledValue = { memberId: null, roleId: null },
}) => {
  const { tenantMembers } = useAppSelector((state) => state.tenant);
  const { projectId } = useParams();
  const { userList } = useAppSelector((state) => state.overview);
  const [addMemberModal, setAddMemberModal] = useState({
    visible: false,
  });
  const [search, setSearch] = useState("");
  const [modalState, setModalState] = useState({
    memberId: null,
    roleId: prefiledValue.roleId,
  });
  // DEBUG COMMENTED
  useEffect(() => {
    setModalState({
      memberId: prefiledValue.memberId,
      roleId: prefiledValue.roleId,
    });
  }, [prefiledValue]);

  // modal render ---> roleId null
  //

  const { roleList } = useAppSelector((state) => state.settings);
  const dispatch = useAppDispatch();

  const [selectedMemberList, setSelectedMemberList] = useState([]);
  const getRoleName = (id) => {
    switch (+id) {
      case 1:
        return "Owner";
      case 2:
        return "Admin";
      case 3:
        return "Basic User";
      default:
        return "Basic User";
    }
  };

  const handleCheckMember = (id, checked) => {
    const memberIndex = selectedMemberList.findIndex(
      ({ id: memberId }) => id === memberId
    );
    const members = [...selectedMemberList];
    members[memberIndex] = {
      ...members[memberIndex],
      checked,
    };
    setSelectedMemberList(members);
  };

  // console.log(prefiledValue, "prefiledValue");

  const handleSubmit = async () => {
    console.log(selectedMemberList, roleList);
    let list = selectedMemberList.map((member) => {
      const roleName = roleList.filter(({ id }) => +id === +member.roleId)[0]
        .title;
      console.log(roleName, member);
      return {
        ...member,
        roleName: roleName,
      };
    });
    list = list
      .filter(({ checked }) => checked)
      .map(({ id, roleId, roleName }) => {
        return {
          userId: id,
          roleId,
          userName:
            userList.find((item) => item.userId == id)?.firstname || "My",
          roleName,
        };
      });

    console.log(list);

    await client
      .put("/settings/member-assign-roles", {
        projectId: projectId,
        memberList: list,
      })
      .then((res) => {
        console.log(res.data);
      })
      .finally(() => {
        console.log("hello");
        setAddMemberModal({ ...addMemberModal, visible: false });
      });
  };
  // console.log(selectedMembers);

  const isAlreadyExist = (user) => {
    if (userList.findIndex((u) => +u.userId === +user.id) !== -1) return false;
    return true;
  };

  useEffect(() => {
    dispatch(getAllRoles({ projectId }));
  }, [projectId]);

  console.log(
    tenantMembers,
    userList,
    tenantMembers?.filter(isAlreadyExist),
    "tenatdcdacadc"
  );
  return (
    <CustomModal
      title={<p className="mon-400-12 text-gray-500">Add Members</p>}
      width={"724px"}
      visible={visible}
      body={
        <div className="h-[48.189386056191466vh] overflow-y-scroll p-4">
          <p className="mon-500-14 text-gray-700">Select members</p>
          <div className="h-full">
            <div className="grid grid-cols-6 gap-x-4 mt-1.5">
              <div className="col-span-4">
                <CustomSelectWithInlineSearch
                  value={modalState.memberId}
                  margin="mt-0"
                  group={false}
                  showSearch
                  placeholder="Search member"
                  options={tenantMembers?.filter(isAlreadyExist).map((s, i) => {
                    return {
                      id: s.id,
                      value: s.id,
                      label: `${s.firstname} ${s.lastname}`,
                      render: () => (
                        <div className="flex justify-between items-center">
                          <p className=" capitalize">
                            {s.firstname} {s.lastname}
                          </p>
                          {selectedMemberList?.findIndex(
                            ({ id }) => id === s.id
                          ) !== -1 && (
                            <img src="/images/icons/check.svg" alt="" />
                          )}
                        </div>
                      ),
                    };
                  })}
                  onChange={(e) => {
                    console.log(e);

                    const isSelectedAlready =
                      selectedMemberList.findIndex(({ id }) => id === e) !== -1;
                    if (isSelectedAlready) {
                      const newList = [...selectedMemberList].filter(
                        ({ id }) => id !== e
                      );
                      setSelectedMemberList(newList);
                      return;
                    }

                    if (modalState.roleId) {
                      const newList = [...selectedMemberList];
                      const member = tenantMembers.filter(
                        ({ id }) => +id === +e
                      )[0];

                      const newMember = {
                        ...member,
                        roleId: modalState.roleId,
                        checked: true,
                      };

                      newList.push(newMember);
                      setSelectedMemberList(newList);
                      setModalState({
                        ...modalState,
                        memberId: null,
                        roleId: null,
                      });
                    } else {
                      setModalState({
                        ...modalState,
                        memberId: e,
                      });
                    }
                  }}
                />
              </div>
              <div className="col-span-2">
                {/* <> {console.log(modalState, "prefiledValue2")}</> */}
                <CustomSelect
                  // border={false}
                  value={modalState.roleId}
                  placeholder="Select roles"
                  backgroundColor="bg-primary-50 text-primary-700 border border-primary-50"
                  options={
                    Array.isArray(roleList) &&
                    roleList?.map((s) => {
                      return {
                        id: s.id,
                        value: s.id,
                        render: () => (
                          <div className="flex justify-between items-center">
                            <p>{s.title}</p>
                          </div>
                        ),
                      };
                    })
                  }
                  onChange={(e) => {
                    console.log(e);
                    if (modalState.memberId) {
                      const newList = [...selectedMemberList];
                      const member = tenantMembers.filter(
                        ({ id }) => +id === +modalState.memberId
                      )[0];
                      const newMember = {
                        ...member,
                        roleId: e,
                        checked: true,
                      };
                      newList.push(newMember);
                      setSelectedMemberList(newList);
                      setModalState({
                        ...modalState,
                        memberId: null,
                        roleId: null,
                      });
                    } else {
                      setModalState({
                        ...modalState,
                        roleId: e,
                      });
                    }
                  }}
                />
              </div>
            </div>
            {selectedMemberList && selectedMemberList.length > 0 ? (
              <div className="mt-5">
                {selectedMemberList
                  // ?.filter((item) => selectedMemberList?.includes(item?.id))
                  ?.map((member, i) => (
                    <div
                      className="flex justify-between items-center mt-2"
                      key={member.id}
                    >
                      <div className="flex justify-start items-center gap-x-2">
                        <Checkbox
                          checked={member.checked}
                          onChange={(e) => {
                            handleCheckMember(member.id, e.target.checked);
                          }}
                        />

                        <CustomAvatar
                          src={member.profilePicture}
                          title={member.firstname}
                          size={40}
                        />
                        <div className="capitalize mon-500-14 text-gray-700">
                          {member.firstname} {member.lastname}
                        </div>
                      </div>
                      <div>
                        {member.roleId &&
                          roleList.filter(({ id }) => id === member.roleId)[0]
                            .title}
                      </div>
                    </div>
                  ))}
              </div>
            ) : (
              <div className="flex justify-center items-center h-[38.189386056191466vh] w-full">
                <div className="bg-primary-50 p-2 rounded-lg">
                  <p className="mon-600-12 text-gray-700">No user selected</p>
                  <p className="mon-400-12 text-gray-400 mt-2">
                    Search or add members from the list to assign the selected
                    role
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
      }
      footer={
        <div className="flex justify-between pt-8 pb-4 px-3">
          <CustomButton
            text="Cancel"
            onClick={() => {
              setAddMemberModal({
                ...addMemberModal,
                visible: false,
              });
              onClose();
            }}
            className="border-2 border-gray-200 text-black bg-white mon-500-16 "
            height="44px"
            width="49%"
          />
          <CustomButton
            text="Assign Role"
            onClick={async () => {
              await handleSubmit();
              dispatch(getProjectDetatis({ projectId }));
              onClose();
            }}
            className="mon-500-16 text-white  bg-primary-600 "
            height="44px"
            width="49%"
            disabled={selectedMemberList.length === 0}
          />
        </div>
      }
      onCancel={() => {
        console.log("onCancel");
        setAddMemberModal({
          ...addMemberModal,
          visible: false,
        });
        onClose();
      }}
    />
  );
};

export default AddMembersModal;
