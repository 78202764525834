import React from "react";
import CustomLayout from "../../Core/Layout/CustomLayout";
import NotificationBody from "./NotificationBody";
import NotficationComponent from "./NotificationComponent";
import ApproveRequestModal from "./ApproveRequestModal";
import DenyRequestModal from "./DenyRequestModal";

const MainScreen = () => {
  return (
    <CustomLayout page="notifications" sidebar={false}>
      <div className="bg-gray-25 overflow-hidden mx-6 mt-6 px-6 pt-3 rounded-2xl">
        {/* <NotficationComponent
          projectName="Project Iceberg"
          severity="High Severity"
          customBackgroundColor={true}
          closeNotification={true}
          notificationType="Clarity for Database"
          notificationDescription="Please approve the designs shared for us to move forward with development phase"
          reaction={false}
        /> */}
        <NotificationBody notificationPopup={false} />
      </div>
    </CustomLayout>
  );
};

export default MainScreen;
