import React, { useState } from "react";
import CustomModal from "../../../Core/Common/CustomModal";
import CustomButton from "../../../Core/Common/CustomButton";
import { useAppDispatch, useAppSelector } from "../../../Core/redux/hooks";
import { resetSchedule } from "../../../Core/redux/slices/schedule.slice";
import { endPhase } from "../../../Core/redux/api/projectAPI";
import dayjs from "dayjs";
import { useParams } from "react-router-dom";
import { getProjectPhases } from "../../../Core/redux/api/scheduleAPI";

type EndPhaseModalProps = {
  visible: boolean;
  onCancel: () => void;
  onOk: () => void;
};

const EndPhaseModal = ({ visible, onCancel, onOk }: EndPhaseModalProps) => {
  const [value, setValue] = useState(1);
  const [endPhaseActive, setEndPhaseActive] = useState(false);
  const dispatch = useAppDispatch();
  const { currentSelectedPhase, sprints } = useAppSelector(
    (state) => state.schedule
  );
  const { projectId } = useParams();

  const activeSprints = sprints.filter(
    (sprint) => sprint.isCompleted === 0 || sprint.status !== 3
  );
  console.log(activeSprints, "current activeSprints V2");

  const handleEndPhase = async () => {
    const payload = {
      projectId: projectId,
      phaseId: currentSelectedPhase.phaseId.toString(),
      isCompleted: 1,
      isOngoing: 0,
      isActive: 1,
      endDate: dayjs(),
    };

    await dispatch(endPhase(payload as any));
    await dispatch(getProjectPhases({ projectId }));
    onCancel();
    await dispatch(getProjectPhases({ projectId }));
    dispatch(resetSchedule(["endPhaseModal"]));
  };

  const onChange = (e) => {
    setValue(e.target.value);
  };

  const getStatus = (statusId) => {
    return (
      <div className="flex items-center">
        {statusId === 1 ? (
          <div className="flex items-center bg-gray-100 rounded-2xl">
            <p className="mon-400-12 text-gray-700 px-2 uppercase py-0.5">
              To Do
            </p>
          </div>
        ) : statusId === 2 ? (
          <div className="flex items-center max-w-max whitespace-nowrap bg-primary-600 rounded-2xl">
            <p className="mon-400-12 text-white px-2 uppercase py-0.5">
              In progress
            </p>
          </div>
        ) : statusId === 3 ? (
          <div className="flex items-center max-w-max whitespace-nowrap bg-success-600 rounded-2xl">
            <p className="mon-400-12 text-white px-2 uppercase py-0.5">Done</p>
          </div>
        ) : (
          <div className="flex items-center bg-gray-100 rounded-2xl">
            <p className="mon-400-12 text-gray-700 px-2 uppercase py-0.5">
              To Do
            </p>
          </div>
        )}
      </div>
    );
  };
  return (
    <CustomModal
      title={
        <p className="mon-500-16 text-gray-700">
          {currentSelectedPhase.phases}
        </p>
      }
      width={"524px"}
      visible={visible}
      body={
        endPhaseActive ? (
          <div className="mx-5 mt-5">
            <p className="mon-400-14 text-gray-500">
              Are you sure you want to end this phase today?
            </p>
            <p className="mon-500-14 text-gray-700 mt-4">Start date</p>
            <div className="bg-gray-50 border-[1px] h-10 rounded-xl mt-2 py-2 pl-3 text-gray-500">
              {dayjs(currentSelectedPhase.startDate).format("DD/MM/YYYY")}
            </div>
            <p className="mon-500-14 text-gray-700 mt-4">End date</p>
            <div className="bg-gray-50 border-[1px] h-10 rounded-xl mt-2 py-2 pl-3 text-gray-500">
              {dayjs().format("DD/MM/YYYY")}
            </div>
          </div>
        ) : (
          <div className="max-h-[38.189386056191466vh] overflow-y-scroll">
            <div className=" px-5">
              <div className="mt-6 border border-[#FECDCA]  rounded-2xl overflow-hidden">
                <div className="text-gray-500 mon-400-14 px-4 pt-[10px] pb-[9px] bg-error-50">
                  You have un-done Sprints in{" "}
                  <span className="mon-500-14 text-gray-900">
                    {currentSelectedPhase.phases}
                  </span>
                </div>
                <div className=" flex justify-between py-2 px-4 border-b items-center">
                  <p className="mon-500-10 text-gray-500 uppercase">Type</p>
                  <p className="mon-500-10 text-gray-500 uppercase">Status</p>
                </div>
                <div className=" max-h-[226px] min-h-[226px] overflow-y-scroll">
                  {activeSprints.map((sprint) => (
                    <div
                      className="px-4 flex justify-between py-3"
                      key={sprint.id}
                    >
                      <div className="mon-500-12 text-gray-900 flex items-center gap-x-2">
                        <div>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="none"
                            viewBox="0 0 20 20"
                          >
                            <path
                              stroke="#165ABF"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="1.667"
                              d="M2.323 6.932S3.64 5.202 4.71 4.171A6.002 6.002 0 018.89 2.5c3.264 0 5.91 2.551 5.91 5.699 0 3.147-2.646 5.699-5.91 5.699H1.667h16.666M2.323 6.932V3.133m0 3.8h3.94m12.07 6.965l-2.7 2.769m2.7-2.77l-2.7-2.603"
                            ></path>
                          </svg>
                        </div>
                        <span>{sprint.name}</span>
                      </div>
                      <>{getStatus(sprint.status)}</>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        )
      }
      footer={
        <div className="flex justify-between pt-8 pb-4 px-3">
          <CustomButton
            text="Cancel"
            onClick={() => {
              onCancel();
              dispatch(resetSchedule("endPhaseModal"));
            }}
            className="border-2 border-gray-200 text-black bg-white mon-500-16 "
            height="44px"
            width="49%"
          />
          <CustomButton
            text="End Phase"
            onClick={() =>
              endPhaseActive ? handleEndPhase() : setEndPhaseActive(true)
            }
            className="mon-500-14 text-white bg-primary-600 "
            height="44px"
            width="49%"
          />
        </div>
      }
      onCancel={onCancel}
    />
  );
};

export default EndPhaseModal;
