import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import { getProjectList, getProjectPermission } from "../api/projectAPI";

const initialState = {
  loading: false,
  error: {},
  status: "idle",
  loggedIn: false,
  projectList: [],
  refreshCount: 0,
  selectedProject: null,
  projectPermission: [],
  permissionInfo: null,
  overview: null,
  schedule: null,
  vault: null,
};

export const projectSlice = createSlice({
  name: "projects",
  initialState,
  reducers: {
    changeProject: (state, action) => {
      state.selectedProject = action.payload;
    },
    resetToInitialProjects: (state, action) => {
      Object.keys(initialState).forEach(
        (key) => (state[key] = initialState[key])
      );
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getProjectList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getProjectList.fulfilled, (state, action) => {
        state.loading = false;
        state.status = "fulfilled";
        state.projectList = action.payload;
      })
      .addCase(getProjectList.rejected, (state, action) => {
        state.loading = false;
        state.status = "rejected";
        state.projectList = [];
        state.error = action.error;
      })
      .addCase(getProjectPermission.pending, (state) => {
        state.loading = true;
      })
      .addCase(getProjectPermission.fulfilled, (state, action) => {
        state.loading = false;
        state.status = "fulfilled";
        state.overview = null;
        state.schedule = null;
        state.vault = null;
        const res = action.payload;
        res.permissions.forEach((permission) => {
          switch (permission.moduleId) {
            case "1":
              state.overview = _.groupBy(
                permission.permissions,
                "permissionId"
              );
              break;
            case "2":
              state.schedule = _.groupBy(
                permission.permissions,
                "permissionId"
              );
              break;
            case "3":
              state.vault = _.groupBy(permission.permissions, "permissionId");
              break;
            default:
              break;
          }
        });
      })
      .addCase(getProjectPermission.rejected, (state, action) => {
        state.loading = false;
        state.status = "rejected";
        state.error = action.error;
      });
  },
});

export const { changeProject, resetToInitialProjects } = projectSlice.actions;
export default projectSlice.reducer;
