import React, { useState } from "react";
import CustomModal from "../../../Core/Common/CustomModal";
import CustomButton from "../../../Core/Common/CustomButton";
import { Radio, Space } from "antd";
import CustomSelect from "../../../Core/Common/CustomSelect";
import { useAppDispatch, useAppSelector } from "../../../Core/redux/hooks";
import { updateSchedule } from "../../../Core/redux/slices/schedule.slice";
import {
  getGoalsBySprint,
  getSwimlanes,
  removeSwimlane,
  updateSwimlane,
} from "../../../Core/redux/api/scheduleAPI";
import { filter } from "jszip";
import { useParams, useSearchParams } from "react-router-dom";

type DeleteStatusModalProps = {
  visible: boolean;
  onCancel: () => void;
  onOk: () => void;
};

const DeleteStatusModal = ({
  visible,
  onCancel,
  onOk,
}: DeleteStatusModalProps) => {
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const [value, setValue] = useState(1);
  const [status, setStatus] = useState(null);
  const { swimlanes, swimlaneDetails } = useAppSelector(
    (state) => state.schedule
  );
  const { projectId } = useParams();

  const onChange = (e) => {
    setValue(e.target.value);
  };

  console.log(swimlaneDetails, "details of swimlanes");
  const handleDelete = async () => {
    const swimlaneToRemove = {
      id: swimlaneDetails.id,
      projectId,
      statusId: null,
      newSwimlaneId: null,
    };
    if (status) {
      const filteredSwimlanes = swimlanes.find(
        (item) => item.statusId == status && item.id !== swimlaneDetails.id
      );

      swimlaneToRemove.statusId = status ? status : null;
      swimlaneToRemove.newSwimlaneId = filteredSwimlanes.id;
    }
    await dispatch(removeSwimlane(swimlaneToRemove));
    await dispatch(getSwimlanes({ projectId }));
    dispatch(
      getGoalsBySprint({
        projectId,
        sprintId: searchParams.get("selectedSprint"),
      })
    );
    onCancel();
  };

  console.log(swimlanes, "after delete");
  return (
    <CustomModal
      title={<p className="mon-500-16 text-error-600">Delete status</p>}
      width={"524px"}
      visible={visible}
      body={
        <div className="max-h-[38.189386056191466vh] overflow-y-scroll">
          <div className="mon-400-14 text-gray-500 pt-6 px-5">
            Are you sure you want to delete this status? This action cannot be
            undone
          </div>
          <div className="pt-6 px-5 ">
            <p className="mon-500-14 text-gray-900 ">
              Choose what to do with items inside the status:
            </p>
            <div className="mt-4 mb-6">
              <Radio.Group onChange={onChange} value={value}>
                <Space direction="vertical">
                  <Radio value={1}>
                    <span className="mon-400-14 text-gray-700">
                      Delete status and the items inside (task, event, bug,
                      scope){" "}
                    </span>
                  </Radio>
                  <Radio value={2}>
                    <span className="mon-400-14 text-gray-700">
                      Move all items to a different status
                    </span>
                  </Radio>
                </Space>
              </Radio.Group>
            </div>
            {value === 2 ? (
              <div className="mb-4">
                <p className="mon-500-14 text-gray-900 mb-2.5">
                  Select a status to move all items to
                </p>
                <div className="mt-2.5">
                  <CustomSelect
                    placeholder="Select a status"
                    value={status}
                    options={[
                      {
                        id: 1,
                        title: "TO DO",
                        value: 1,
                        render: (value) => (
                          <div onClick={() => setStatus(1)}>TO DO</div>
                        ),
                      },
                      {
                        id: 2,
                        title: "IN PROGRESS",
                        value: 2,
                        render: (value) => (
                          <div onClick={() => setStatus(2)}>IN PROGRESS</div>
                        ),
                      },
                      {
                        id: 3,
                        title: "DONE",
                        value: 3,
                        render: (value) => (
                          <div onClick={() => setStatus(3)}>DONE</div>
                        ),
                      },
                    ]}
                  />
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      }
      footer={
        <div className="flex justify-between pt-8 pb-4 px-3">
          <CustomButton
            text="Cancel"
            onClick={onCancel}
            className="border-2 border-gray-200 text-black bg-white mon-500-16 "
            height="44px"
            width="49%"
          />
          <CustomButton
            text="Delete status"
            onClick={() => handleDelete()}
            className="mon-500-16 text-white  bg-error-600 "
            height="44px"
            width="49%"
          />
        </div>
      }
      onCancel={onCancel}
    />
  );
};

export default DeleteStatusModal;
