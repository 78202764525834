import React, { useState, useEffect } from "react";
import { Drawer, Menu, Dropdown } from "antd";
import ViewFileDetailsDrawer from "./ViewFileDetailsDrawer";
import MenuDropdown from "./MenuDropdown";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../Core/redux/hooks";
import { updateConversations } from "../../../Core/redux/slices/conversations.slice";
import MainChatWindow from "../MainChatWindow";
import ConversationsChatInput from "../ConversationChatInput";
import { getComments } from "../../../Core/redux/api/conversationAPI";

type ConversationDrawerProps = {
  visible: boolean;
  onCancel: () => void;
  socket?: any;
};

const ConversationsDrawer = ({
  visible,
  onCancel,
  socket,
}: ConversationDrawerProps) => {
  const { openCommentsModal } = useAppSelector((state) => state.vault);
  const dispatch = useAppDispatch();

  const handelGetComments = async () => {
    await dispatch(
      getComments({
        docId: openCommentsModal?.docId,
        docType: openCommentsModal?.docType,
        limit: 1000,
        offset: 0,
      })
    );
  };
  useEffect(() => {
    if (visible) {
      handelGetComments();
    }
  }, [visible]);
  const { vault } = useAppSelector((state) => state.projects);
  const commentAccess = vault?.[5]?.[0];

  return (
    <Drawer
      placement="right"
      open={visible}
      closable={false}
      className="conversation-drawer"
      width="45.875rem"
      onClose={onCancel}
    >
      <div className="max-h-[100vh] min-h-[100vh] overflow-hidden flex flex-col content-between justify-between	">
        <div className="flex items-center pb-3.5 pt-5 px-6 border-b border-gray-100 ">
          <button onClick={onCancel}>
            <img src="/images/icons/chevron-right-double.svg" alt="close" />
          </button>

          <div className="mon-500-14 capitalize ml-4 text-gray-700 truncate whitespace-nowrap">
            {openCommentsModal?.name}
          </div>
        </div>
        <div className="w-full bg-white max-h-[calc(100vh-56px)] min-h-[calc(100vh-56px)] overflow-hidden flex flex-col content-between justify-between	 ">
          <MainChatWindow socket={socket} comments />

          {commentAccess?.create && (
            <div className="mx-5 my-5">
              <ConversationsChatInput comments />
            </div>
          )}
        </div>
      </div>
    </Drawer>
  );
};

export default ConversationsDrawer;
