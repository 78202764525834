import React, { useState } from "react";
import CustomButton from "../../../Core/Common/CustomButton";
import CustomInputWithEdit from "../../../Core/Common/CustomInputWithEdit";
import CustomModal from "../../../Core/Common/CustomModal";
import MenuDropdown from "../../Conversations/Components/MenuDropdown";
import { Dropdown, Menu, Tabs, Input } from "antd";
import CustomAvatar from "../../../Core/Common/CustomAvatar";
import { PriorityIconWithLabel } from "../../../Core/svg/PriorityIcon";

import CustomSelect from "../../../Core/Common/CustomSelect";
import { CustomSelectWithInlineSearch } from "../../../Core/Common/CustomSelectWithSearch";
import { useAppDispatch, useAppSelector } from "../../../Core/redux/hooks";
import {
  resetSchedule,
  updateSchedule,
} from "../../../Core/redux/slices/schedule.slice";
import { useParams } from "react-router-dom";
import {
  createGoal,
  getGoalsBySprint,
  updateGoal,
  updateProgress,
} from "../../../Core/redux/api/scheduleAPI";
import CustomFrostyMessage from "../../../Core/Common/CustomFrostyMessage";
import Comments from "./Comments";
import CustomEstimatedTimeInput from "./CustomEstimatedTimeInput";
import InfoModal, {
  CategoryDropDown,
  ComponentDropdown,
  CustomLeft,
  RowLayout,
  StatusDropdown,
  UserDropdown,
} from "./TaskResuable";
import TaskPriorityDropDown from "./TaskPriorityDropDown";
import TimeInput, { updateEstimate } from "./TimeInput";
import CustomInputWithSaveAndEdit from "../../../Core/Common/CustomInputWithSaveAndEdit";
import * as Yup from "yup";
import { useFormik } from "formik";

type NewSubTaskModalProps = {
  visible: boolean;
  onCancel: () => void;
};

const NewSubTaskModal = ({ visible, onCancel }: NewSubTaskModalProps) => {
  const [estimatedInfo, setEstimatedInfo] = useState(false);
  const [estimatedTimeInput, setEstimatedTimeInput] = useState(false);
  const [currentDropDown, setCurrentDropDown] = useState("");
  const { TabPane } = Tabs;
  const [currentTab, setCurrentTab] = useState("description");
  const dispatch = useAppDispatch();
  const { projectId } = useParams();
  const {
    selectedSprint,
    currentSelectedPhase,
    newSubTaskModal,
    selectedTask,
    goals,
  } = useAppSelector((state) => state.schedule);
  const { userList } = useAppSelector((state) => state.overview);
  const { user } = useAppSelector((state) => state.userDetails);

  console.log(selectedTask, "task 1212");

  const assignee = userList.find(
    (user) => +user.userId === +newSubTaskModal.subtask.assigneeId
  );

  const reporter = userList.find(
    (user) => +user.userId === +newSubTaskModal.subtask.reporterId
  );
  const formik = useFormik({
    initialValues: {
      name: null,
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required("Please enter the title of the subtask")
        .typeError("Please enter the title of the subtask"),
    }),
    onSubmit: async (values) => {
      if (!newSubTaskModal.editTask) {
        createSubTask();
      } else {
        console.log("formik comes");
        await dispatch(
          updateGoal({
            name: subTask.names[0],
            goalId: newSubTaskModal.subtask.goalId,
          })
        );
      }
      // createBug();
    },
  });

  const editSubtask = newSubTaskModal.editTask
    ? {
        estimatedTime: "",
        goalId: "",
        timeLogged: "",
        componentId: "",
        componentName: "",
        categoryId: "",
        ...newSubTaskModal.subtask,
        assignee: `${
          assignee?.firstname === null ? "User" : assignee?.firstname
        } ${assignee?.lastname === null ? "" : assignee?.lastname}`,
        assigneeProfilePicture: assignee?.profilePicture,
        reporter: `${
          reporter?.firstname === null ? "User" : reporter?.firstname
        } ${reporter?.lastname === null ? "" : reporter?.lastname}`,
        reporterProfilePicture: reporter?.profilePicture,
        id: newSubTaskModal.subtask.goalId,
        parentTaskName: newSubTaskModal.subtask.parentTaskName,
      }
    : {
        estimatedTime: "",
        goalId: "",
        timeLogged: "",
        componentId: "",
        componentName: "",
        categoryId: "",
        ...newSubTaskModal.subtask,
        assignee: `${user.firstName === null ? "User" : user.firstName} ${
          user.lastName === null ? "User" : user.lastName
        }`,
        reporter: `${user.firstName === null ? "User" : user.firstName} ${
          user.lastName === null ? "User" : user.lastName
        }`,
        assigneeProfilePicture: user.profilePicture,
        reporterProfilePicture: user.profilePicture,
        id: newSubTaskModal.subtask.goalId,
        assigneeId: user.id,
        parentTaskName: newSubTaskModal.subtask.parentTaskName,
      };

  const [subTask, setSubTask] = useState(editSubtask);
  console.log(subTask.description, "subtask");
  // React.useEffect(()=>{
  //   if(newSubTaskModal.visible){
  //     setSubTask
  //   }
  // })

  const createSubTask = async () => {
    const payload = {
      sprintId: selectedSprint.id,
      projectId: projectId,
      phaseId: subTask.phaseId,
      names: subTask.names,
      isCompleted: 0,
      statusId: subTask.statusId,
      priorityId: subTask.priorityId,
      goalType: 4,
      assigneeId: subTask.assigneeId,
      reporterId: subTask.reporterId,
      description: subTask.description,
      parentGoalId: subTask.parentGoalId,

      estimatedTime: subTask.estimatedTime,
      timeLogged: subTask.timeLogged,
      componentId: subTask.componentId,
      componentName: subTask.componentName,
      categoryId: subTask.categoryId,
    };

    await dispatch(createGoal(payload)).then(() => onCancel());
    dispatch(resetSchedule(["newSubTaskModal"]));
    dispatch(getGoalsBySprint({ projectId, sprintId: selectedSprint.id }));
    dispatch(
      updateProgress({
        phaseId: currentSelectedPhase.id,
        projectId,
      })
    );
  };

  return (
    <CustomModal
      title={
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <p className="mon-400-16 pl-3 text-gray-400">
              {selectedSprint.name}
            </p>
            {subTask.parentTaskName && (
              <>
                <span className="mon-400-16 pl-3 text-gray-400"> / </span>
                <p className="mon-400-16 pl-3 text-gray-400">
                  {subTask.parentTaskName}
                </p>
              </>
            )}

            <span className="mon-400-16 pl-3 text-gray-400"> / </span>
            <p className="flex mon-500-16 text-black pl-3">
              {subTask.names[0]}
            </p>
          </div>
        </div>
      }
      width="704px"
      menuDropdown={
        <MenuDropdown
          elements={[
            {
              id: 1,
              image: "/images/icons/copy-dashed.svg",
              text: "Duplicate",
              textColor: "text-gray-700",
              borderBottom: true,
            },
            {
              id: 2,
              image: "/images/icons/trash.svg",
              text: "Delete",
              textColor: "text-error-600",
              onclick: () =>
                dispatch(
                  updateSchedule({
                    key: "deleteSubTaskModal",
                    value: true,
                  })
                ),
            },
          ]}
          customFunc={undefined}
        />
      }
      visible={visible}
      height="608px"
      body={
        <div className="pt-4 pl-4 pr-8 max-h-[55vh] overflow-y-scroll">
          <CustomInputWithSaveAndEdit
            width="100%"
            inputClassName="mon-600-14 text-gray-900"
            placeholder="New Subtask "
            {...formik.getFieldProps("name")}
            onChange={(e) => {
              setSubTask({ ...subTask, names: [e.target.value] });
              formik.setFieldValue("name", e.target.value);
            }}
            onOk={async () => {
              if (newSubTaskModal.editTask) {
                formik.handleSubmit();
              }
            }}
            value={subTask.names[0]}
            formikHook={formik}
          />
          <RowLayout
            customLeft={
              <CustomLeft icon="/images/icons/users.svg" text="Assignee" />
            }
            className="mt-4"
          >
            <UserDropdown
              fieldName="Assignee"
              task={subTask}
              setTask={setSubTask}
              userList={userList}
              onEdit={async (id) => {
                if (!newSubTaskModal.editTask) {
                  // console.log(subTask, "aa check");
                  await dispatch(
                    updateGoal({
                      assigneeId: id,
                      goalId: newSubTaskModal.subtask.goalId,
                    })
                  );
                }
              }}
            />
          </RowLayout>

          <RowLayout
            customLeft={
              <CustomLeft icon="/images/icons/users.svg" text="Reporter" />
            }
            className="mt-4"
          >
            <UserDropdown
              fieldName="Reporter"
              task={subTask}
              setTask={setSubTask}
              userList={userList}
              onEdit={async (id) => {
                if (newSubTaskModal.editTask) {
                  await dispatch(
                    updateGoal({
                      reporterId: id,
                      goalId: newSubTaskModal.subtask.goalId,
                    })
                  );
                }
              }}
            />
          </RowLayout>
          <RowLayout
            customLeft={
              <CustomLeft icon="/images/icons/tag-01.svg" text="Status" />
            }
            className="mt-4"
          >
            <StatusDropdown
              setStatusId={async (e) => {
                setSubTask({ ...subTask, statusId: e.statusId });
                if (newSubTaskModal.editTask) {
                  await dispatch(
                    updateGoal({
                      ...e,
                      goalId: newSubTaskModal.subtask.goalId,
                      parentGoalId: newSubTaskModal.subtask.parentGoalId,
                      goalType: 4,
                    })
                  );

                  if (e.statusId == 3) {
                    dispatch(
                      updateProgress({
                        phaseId: currentSelectedPhase.id,
                        projectId,
                      })
                    );
                  }

                  if (e.statusId == 2) {
                    dispatch(
                      getGoalsBySprint({
                        projectId,
                        sprintId: selectedSprint.id,
                      })
                    );
                  }
                }
              }}
              statusId={subTask.statusId}
              task={subTask}
            />
          </RowLayout>
          <RowLayout
            customLeft={
              <CustomLeft icon="/images/icons/flag-03.svg" text="Priority" />
            }
            className="mt-2"
          >
            <div className="min-w-[218px]">
              <TaskPriorityDropDown
                task={subTask}
                currentDropDown={currentDropDown}
                setTask={async (e) => {
                  setSubTask({ ...subTask, priorityId: e });
                  if (newSubTaskModal.editTask) {
                    await dispatch(
                      updateGoal({
                        priorityId: e,
                        goalId: newSubTaskModal.subtask.goalId,
                      })
                    );
                  }
                }}
                setCurrentDropDown={setCurrentDropDown}
              />
            </div>
          </RowLayout>

          {/* <RowLayout
            customLeft={
              <CustomLeft icon="/images/icons/category.svg" text=" Category" />
            }
            className="mt-1"
          >
            <CategoryDropDown
              goalType={1}
              currentDropDown={currentDropDown}
              task={subTask}
              setTask={setSubTask}
              setCurrentDropDown={setCurrentDropDown}
              onEdit={async (e) => {
                if (newSubTaskModal.editTask) {
                  await dispatch(
                    updateGoal({
                      categoryId: e,
                      goalId: newSubTaskModal.subtask.goalId,
                    })
                  );
                }
              }}
            />
          </RowLayout> */}

          <RowLayout
            customLeft={
              <CustomLeft
                icon="/images/icons/clock-stopwatch.svg"
                text="Estimated time"
              >
                <InfoModal />
              </CustomLeft>
            }
            className="mt-1"
          >
            <TimeInput
              className=""
              value={subTask.estimatedTime}
              onChange={(e) => setSubTask({ ...subTask, estimatedTime: e })}
              onOk={async (newTime: string) => {
                if (newSubTaskModal.editTask) {
                  await dispatch(
                    updateGoal({
                      estimatedTime: newTime,
                      goalId: newSubTaskModal.subtask.goalId,
                    })
                  );
                }
              }}
            />
          </RowLayout>

          <RowLayout
            customLeft={
              <CustomLeft
                icon="/images/icons/clock-stopwatch.svg"
                text="Time logged"
              >
                <InfoModal />
              </CustomLeft>
            }
            className="mt-1"
          >
            <TimeInput
              className=""
              addNewTimeToPreviousTime
              value={subTask.timeLogged}
              onChange={(e) => setSubTask({ ...subTask, timeLogged: e })}
              onOk={async (newTime, newTimeWithoutEdition) => {
                if (newSubTaskModal.editTask) {
                  await dispatch(
                    updateGoal({
                      timeLogged: newTime,
                      goalId: newSubTaskModal.subtask.goalId,
                    })
                  );
                  const parentGoal = goals.find(
                    (g) => g.id == newSubTaskModal.subtask.parentGoalId
                  );
                  if (parentGoal && parentGoal.id) {
                    await dispatch(
                      updateGoal({
                        timeLogged: updateEstimate(
                          parentGoal.timeLogged,
                          newTimeWithoutEdition
                        ),
                        goalId: newSubTaskModal.subtask.parentGoalId,
                      })
                    );
                  }
                }
              }}
            />
          </RowLayout>
          <RowLayout
            customLeft={
              <CustomLeft
                icon="/images/icons/tool-01.svg"
                text="Component (optional)"
              />
            }
            className="mt-1"
          >
            <ComponentDropdown
              task={subTask}
              setTask={setSubTask}
              currentDropDown={currentDropDown}
              setCurrentDropDown={setCurrentDropDown}
              onEdit={async (e) => {
                if (newSubTaskModal.editTask) {
                  await dispatch(
                    updateGoal({
                      ...e,
                      goalId: newSubTaskModal.subtask.goalId,
                    })
                  );
                }
              }}
            />
          </RowLayout>

          <div className="mt-6">
            <Tabs
              onTabClick={(key) => {
                setCurrentTab(key);
              }}
              activeKey={currentTab}
              className="overview-tab mt-8"
            >
              <TabPane
                tab={
                  <div className="flex">
                    <div
                      className={`${
                        currentTab === "description"
                          ? "mon-500-14 text-primary-600"
                          : "text-gray-500 mon-400-14"
                      }`}
                    >
                      Description
                    </div>
                  </div>
                }
                key="description"
              >
                <Input.TextArea
                  rows={3}
                  placeholder="Add a description"
                  required={true}
                  className="w-full h-full bg-white rounded-xl border border-gray-300 text-gray-500 pl-4 pt-2  active:outline-none mb-6"
                  value={subTask.description}
                  onChange={async (e) => {
                    setSubTask({ ...subTask, description: e.target.value });

                    if (newSubTaskModal.editTask) {
                      await dispatch(
                        updateGoal({
                          description: e.target.value,
                          goalId: newSubTaskModal.subtask.goalId,
                        })
                      );
                    }
                  }}
                ></Input.TextArea>
              </TabPane>
              <TabPane
                tab={
                  <div className="flex">
                    <div
                      className={`${
                        currentTab === "comment"
                          ? "mon-500-14 text-primary-600"
                          : "text-gray-500 mon-400-14"
                      }`}
                    >
                      Comment
                    </div>
                  </div>
                }
                key="comment"
              >
                <Comments
                  docId={subTask.id}
                  docType="subtask"
                  visible={true}
                  user={[{ userId: subTask.assigneeId }]}
                />
              </TabPane>
            </Tabs>
          </div>
        </div>
      }
      footer={
        <div className="flex justify-end py-4 gap-x-3 px-3 shadow-upward">
          <div className="flex gap-x-3">
            <CustomButton
              text={newSubTaskModal.editTask ? "Close" : "Cancel"}
              onClick={() => onCancel()}
              className="border border-gray-300 text-gray-700 bg-white mon-500-14"
              height="40px"
              width="81px"
            />
            {newSubTaskModal.editTask ? (
              <></>
            ) : (
              <CustomButton
                text="Create"
                className="mon-500-14 text-white bg-primary-600 "
                height="40px"
                width="124px"
                onClick={() => formik.handleSubmit()}
              />
            )}
          </div>
        </div>
      }
      onCancel={onCancel}
    />
  );
};

export default NewSubTaskModal;
