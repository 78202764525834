import React, { useState } from "react";
import { Layout, Tooltip } from "antd";
import MenuDropdown from "./Components/MenuDropdown";
import { useLocation, useNavigate } from "react-router-dom";
import ShareAccessModal from "./Components/ShareAccessModal";
import ConversationsDrawer from "./Components/ConversationsDrawer";
import CustomMediaSlider from "../../Core/Common/CustomMediaSlider";

const FilePreviewScreen = () => {
  const [openShareAccessModal, setOpenShareAccessModal] = useState(false);
  const [conversationDrawer, setConversationDrawer] = useState(false);
  const navigate = useNavigate();

  const [zoomLevel, setZoomLevel] = useState(1);

  const zoomIn = () => {
    setZoomLevel(zoomLevel + 0.2);
  };

  const zoomOut = () => {
    if (zoomLevel >= 1) {
      setZoomLevel(zoomLevel - 0.2);
    }
  };

  const dropDown = [
    {
      id: 1,
      image: "/images/icons/info-circle.svg",
      text: "View details",
      textColor: "text-gray-700",
    },
    {
      id: 2,
      image: "/images/icons/open-window.svg",
      text: "Open in new window",
      borderBottom: true,
      textColor: "text-gray-700",
    },
    {
      id: 3,
      image: "/images/icons/message-dots-circle-gray.svg",
      text: "Open thread",
      textColor: "text-gray-700",
      onclick: () => setConversationDrawer(true),
    },
    {
      id: 4,
      image: "/images/icons/add-user-gray.svg",
      text: "Share access",
      textColor: "text-gray-700",
      onclick: () => setOpenShareAccessModal(true),
    },
    {
      id: 5,
      image: "/images/icons/share-gray.svg",
      text: "Share in message",
      textColor: "text-gray-700",
    },
    {
      id: 6,
      image: "/images/icons/download-icon.svg",
      text: "Download",
      textColor: "text-gray-700",
      borderBottom: true,
    },
    {
      id: 7,
      image: "/images/icons/trash.svg",
      text: "Delete",
      textColor: "text-error-600",
    },
  ];

  return (
    <>
      <div className="bg-main-background max-h-[100vh]">
        <Layout.Header
          style={{ width: "100%", maxHeight: "56px", pointerEvents: "auto" }}
        >
          <div className="max-h-14 w-full flex justify-between border-b-[1px] border-[#DDE5ED]">
            <div className="flex items-center ml-4">
              <img
                src="/images/icons/chevron-left.svg"
                className="w-5 h-5 cursor-pointer"
                onClick={() => {
                  navigate("/conversations");
                }}
              />
              <img src="/images/icons/pdf-icon.svg" className="w-5 h-5 ml-3" />
              <p className="mon-500-12 text-gray-700 pl-2">
                First draft of ideas
              </p>
            </div>
            <div className="flex items-center mr-4">
              <Tooltip title="Share access">
                <button
                  onClick={() => setOpenShareAccessModal(true)}
                  className="w-5 h-5 mr-4 cursor-pointer"
                >
                  <img src="/images/icons/add-user-gray.svg" />
                </button>
              </Tooltip>
              <Tooltip title="Download">
                <img
                  src="/images/icons/download-icon.svg"
                  className="w-5 h-5 mr-4 cursor-pointer"
                />
              </Tooltip>
              <Tooltip title="Open Thread">
                <button
                  onClick={() => setConversationDrawer(true)}
                  className="w-5 h-5 mr-4 cursor-pointer"
                >
                  <img src="/images/icons/message-dots-circle-light-gray.svg" />
                </button>
              </Tooltip>

              <MenuDropdown elements={dropDown} customFunc={undefined} />
            </div>
          </div>
        </Layout.Header>
        <div className="flex flex-col justify-center items-center max-h-[calc(100vh-56px)] min-h-[calc(100vh-56px)]">
          <div className="w-[965px] h-[534px]">
            {/* <img
              src="/images/icons/file-preview.png"
              className="w-full h-full"
            /> */}
            {/* <CustomMediaSlider zoomLevel={zoomLevel} /> */}
          </div>
          <div className="bg-primary-900 w-60 h-12 mt-14 rounded-xl flex items-center justify-center">
            <div className="mon-400-12 text-gray-100 pl-4">Page 1 of 4</div>
            <img
              src="/images/icons/zoom-out.svg"
              className="w-5 h-5 ml-3 cursor-pointer"
              onClick={zoomOut}
            />
            <img src="/images/icons/line.svg" className="w-5 h-5 ml-2" />
            <img
              src="/images/icons/zoom-in.svg"
              className="w-5 h-5 ml-2 cursor-pointer"
              onClick={zoomIn}
            />
            <div className="mon-400-12 text-gray-100 ml-3 mr-3">100%</div>
          </div>
        </div>
      </div>
      <ShareAccessModal
        visible={openShareAccessModal}
        onCancel={() => setOpenShareAccessModal(false)}
      />
      <ConversationsDrawer
        visible={conversationDrawer}
        onCancel={() => setConversationDrawer(false)}
      />
    </>
  );
};

export default FilePreviewScreen;
