import { Collapse, Spin } from "antd";
import React, { useState } from "react";
import { useAppSelector } from "../../Core/redux/hooks";
import CustomFile from "../../Core/Common/CustomFile";
import CustomFolder from "../../Core/Common/CustomFolder";
import CustomLink from "../../Core/Common/CustomLink";
import { useParams } from "react-router-dom";
import DropTarget from "./Components/DropTarget";
import DragSource from "./Components/DragSource";
import { client } from "../../Core/utils/axiosClient";
import Spinner from "../../Core/svg/Spinner";

const { Panel } = Collapse;

const GridView = ({
  searchText,
  showFolder,
  showFile,
  showLink,
  onRefresh,
}) => {
  const { links } = useAppSelector((state) => state.vault);
  const { files } = useAppSelector((state) => state.vault);
  const { folders } = useAppSelector((state) => state.vault);
  const { projectId, "*": splat } = useParams();
  console.log(files, "files list");
  console.log(folders, "folders list");
  const { user } = useAppSelector((state) => state.userDetails);

  const [loading, setLoading] = useState(false);

  const [currentDropDestinationId, setCurrentDropDestinationId] =
    useState(null);
  const [currentDragSource, setCurrentDragSource] = useState({
    type: "",
    id: null,
  });

  const handleMoveItemToFolder = async (itemId, itemType, folderId) => {
    try {
      setLoading(true);

      const response = await client.put(`/repository/move-item-to-folder`, {
        itemId,
        itemType,
        folderId,
      });
      console.log("FILE MOVED!", response.data);
      onRefresh((type) => {
        if (type === "folders") setLoading(false);
      });
    } catch (e) {
      // console.log();
      setLoading(false);
    }
  };

  const IMG_FORMATS = ["gif", "png", "jpg", "jpeg"];
  const VIDEOS_FORMATS = [
    "mp4",
    "mp4",
    "mov",
    "avi",
    "flv",
    "wmv",
    "mkv",
    "webm",
  ];

  const getThumbnail = (file) => {
    if (file?.url?.includes("svg")) return file.url;
    if (
      file?.url &&
      VIDEOS_FORMATS.some((format) => file.url.includes(format))
    ) {
      return "/images/thumbnails/video.svg";
    }

    return IMG_FORMATS.some(
      (formatName) =>
        file?.thumbnail?.includes(formatName) || file?.url?.includes(formatName)
    )
      ? (file.thumbnail &&
          `https://images.weserv.nl/?url=${file?.url}&h=78&q=70&output=webp`) ||
          file.url
      : "/images/icons/no-files.svg";
  };

  return (
    <div className="mb-7">
      {/* <Spin spinning={loading}> */}
      <>
        <Collapse
          defaultActiveKey={[1, 2, 3]}
          ghost
          collapsible="header"
          expandIcon={({ isActive }) => {
            //console.log(isActive);
            return (
              <img
                src="/images/icons/collapse.svg"
                className="cursor-pointer"
                style={{
                  transition: "transform 0.3s ease-in-out 0s",
                  transform: isActive ? "rotate(0deg)" : "rotate(-180deg)",
                }}
              />
            );
          }}
        >
          {showFolder && (
            <Panel header="Folders" key={1} className="custom-collapse-panel">
              <div className="flexible-grid">
                {/* {splat.length === 0 && <CustomFolder isMySpace />} */}
                {folders
                  ?.filter((folder) =>
                    folder.folderName.toLowerCase().includes(searchText)
                  )
                  .map(
                    (
                      {
                        id,
                        folderName,
                        filesCount,
                        linksCount,
                        phaseId,
                        usersWithAccess,
                      },
                      i
                    ) => (
                      <DropTarget
                        key={id + i}
                        id={id}
                        type="folder"
                        onDrop={({ type, id: folderId }) => {
                          const { id: itemId, type: itemType } =
                            currentDragSource;
                          handleMoveItemToFolder(itemId, itemType, folderId);
                        }}
                        onDropTargetChange={(id, { isOver, canDrop }) => {
                          setCurrentDropDestinationId(
                            isOver && canDrop ? id : null
                          );
                        }}
                      >
                        <CustomFolder
                          title={folderName}
                          totalFiles={filesCount}
                          totalLinks={linksCount}
                          key={id}
                          folderId={id}
                          phaseId={phaseId}
                          usersWithAccess={usersWithAccess}
                          isDropTarget={currentDropDestinationId === id}
                          isMySpace={folderName.includes(" Space")}
                        />
                      </DropTarget>
                    )
                  )}
              </div>
            </Panel>
          )}
          {showFile && (
            <Panel header="Files" key={2} className="custom-collapse-panel">
              <div className="flexible-grid">
                {files
                  ?.filter((file) =>
                    file.name.toLowerCase().includes(searchText)
                  )
                  .map((file, i) => (
                    <DragSource
                      key={file.id + i}
                      id={file.id}
                      type="files" // "files" do not change this
                      onDrag={(type, id) => {
                        setCurrentDragSource({
                          type,
                          id,
                        });
                      }}
                      disabled={false}
                    >
                      <CustomFile
                        title={file.name}
                        key={file.id}
                        fileId={file.id}
                        phaseId={file.phaseId}
                        usersWithAccess={file.usersWithAccess}
                        file={file}
                        thumbnail={getThumbnail(file)}
                      />
                      {/* <img src="/images/icons/no-files.svg" className="w-full h-full" /> */}
                    </DragSource>
                  ))}
              </div>
            </Panel>
          )}

          {showLink && (
            <Panel header="Links" key={3} className="custom-collapse-panel">
              <div className="flexible-grid">
                {links
                  ?.filter((l) => l.name.toLowerCase().includes(searchText))
                  .map((link, i) => (
                    <DragSource
                      key={link.id + i}
                      id={link.id}
                      type="links" // "links" do not change this
                      onDrag={(type, id) => {
                        setCurrentDragSource({
                          type,
                          id,
                        });
                      }}
                      disabled={false}
                    >
                      <CustomLink
                        title={link.name}
                        key={link.id}
                        id={link.id}
                        link={link}
                        usersWithAccess={link.usersWithAccess}
                      />
                      {/* <img src="/images/icons/no-files.svg" className="w-full h-full" /> */}
                    </DragSource>
                  ))}
              </div>
            </Panel>
          )}
        </Collapse>
      </>
      {/* </Spin> */}
    </div>
  );
};

export default GridView;
