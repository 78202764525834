import { AutoComplete } from "antd";
import axios from "axios";
import React, { useState } from "react";

const CustomAddressInput = ({
  width,
  fullBorder,
  placeholder,
  value,
  onChange,
  className,
  customEnd,
  onSelect,
  maxLength,
  name,
  showError,
  errorText,
  formikHook: formik,
  helperText,
  getTimezone = false,
}: {
  width?: string;
  fullBorder?: boolean;
  placeholder?: string;
  value: string;
  onChange: (e) => void;
  className?: string;
  customEnd?: React.ReactNode;
  rootStyle?: any;
  onSelect: (obj) => void;
  maxLength?: number;
  name?: string;
  showError?: boolean;
  errorText?: string;
  helperText?: string;
  formikHook?: any;
  getTimezone?: boolean;
}) => {
  const [focused, setFocused] = useState(false);

  if (formik && name) {
    showError = showError || (formik.touched[name] && !!formik.errors[name]);
    errorText = errorText || (formik.touched[name] && formik.errors[name]);
    // valid =
    //   valid ||
    //   (!formik.errors[name] &&
    //     formik.initialValues[name] !== formik.values[name])
  }

  const [locationOptions, setLocationOptions] = useState([]);
  const [disabled, setDisabled] = useState(false);

  const googleAddressSuggestions = (inputVal, callback) => {
    if (
      typeof window === "undefined" ||
      typeof window?.google !== "object" ||
      typeof callback !== "function"
    )
      return;

    const service = new window.google.maps.places.AutocompleteService();

    // Country Restriction Codes : https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2
    service?.getPlacePredictions(
      { input: inputVal, types: ["(cities)"] },
      (pred, status) => {
        if (
          status !== window.google.maps.places.PlacesServiceStatus.OK ||
          !pred
        ) {
          callback([]);
        } else {
          callback(pred);
        }
      }
    );

    // service?.getQueryPredictions({ input: inputVal }, (pred, status) => {
    //   if (status !== window.google.maps.places.PlacesServiceStatus.OK || !pred) {
    //     callback([]);
    //   } else {
    //     callback(pred);
    //   }
    // });
  };

  const handleGetTimezone = async ({ lat, lng }) => {
    console.log(lat, lng);
    const API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;
    try {
      const result = await axios.get(
        `https://maps.googleapis.com/maps/api/timezone/json?location=${lat},${lng}&timestamp=1331161200&key=${API_KEY}`
      );
      console.log(result.data);
      return result.data;
    } catch (e) {
      console.log("Error while getting timzone");
      return undefined;
    }
  };

  const googleAddressCoordinates = (placeId, callback) => {
    if (
      typeof window === "undefined" ||
      typeof window?.google !== "object" ||
      typeof callback !== "function"
    )
      return;

    const geocoder = new window.google.maps.Geocoder();

    geocoder
      .geocode({ placeId })
      .then((results) => {
        callback(results);
      })
      .catch((e) => {
        callback([]);
      });
  };

  const handleInput = (value) => {
    // onSearch(value)
    if (!value) {
      setLocationOptions([]);
      return;
    }

    const callback = (pred) => {
      const options = pred.map((location) => {
        return {
          ...location,
          value: location.description,
          label: location.description,
        };
      });
      setLocationOptions(options);
    };

    googleAddressSuggestions(value, callback);
  };

  const handleSelect = (desc) => {
    const location = locationOptions.filter(
      (loc) => loc.description === desc
    )[0];

    const structured = location.structured_formatting;
    const locationName = structured.main_text;
    const locationSubName = structured.secondary_text;

    googleAddressCoordinates(location.place_id, async ({ results }) => {
      if (!results || results.length === 0) return;
      const location = results[0];
      const fullAddress = location.formatted_address;

      const getGps = location.geometry.location;
      const gps = {
        lat: getGps.lat(),
        lng: getGps.lng(),
      };
      let timezone = undefined;
      if (getTimezone) timezone = await handleGetTimezone(gps);
      console.log("googleAddressCoordinates: ", location, gps);

      onSelect({
        locationName,
        locationSubName,
        lat: gps.lat,
        long: gps.lng,
        fullAddress,
        timezone,
      });
      setDisabled(true);
      setFocused(false);
    });
  };

  const onClear = (e) => {
    setDisabled(false);
    setLocationOptions([]);
    onSelect({
      locationName: "",
      lat: null,
      long: null,
    });
  };

  return (
    <div>
      <div
        className={`  px-3
        ${
          fullBorder
            ? "border-[1px] rounded- rounded-lg py-0.5"
            : showError
            ? "border-b-2 border-error-600"
            : value?.length > 0
            ? "border-b-2 border-primary-600"
            : " border-b-[1px] border-gray-400"
        }
      w-[${width}]
      ${className} mt-2`}
      >
        <div className="flex justify-between items-center">
          <AutoComplete
            options={locationOptions}
            bordered={false}
            disabled={disabled}
            placeholder={placeholder}
            value={value}
            onSearch={handleInput}
            onChange={onChange}
            onSelect={handleSelect}
            className="font-medium text-mid custom-address-input-ob "
            maxLength={maxLength}
            onFocus={() => setFocused(true)}
            onBlur={() => setFocused(false)}
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          />
          {customEnd ? (
            customEnd
          ) : (
            <div
              className={`cursor-pointer ${fullBorder ? "mr-2" : ""}`}
              onClick={() => {
                onChange("");
              }}
            >
              {(value || disabled) && (
                <img
                  src={
                    disabled
                      ? "/images/icons/edit-01.svg"
                      : "/images/icons/sector-icons/input-cross.svg"
                  }
                  className="w-4 h-4"
                  alt=""
                  onClick={onClear}
                />
              )}
            </div>
          )}
        </div>
      </div>
      {showError && (
        <div className="mt-1.5 mon-400-14 text-left text-error-500">
          {errorText}
        </div>
      )}
      {helperText && (
        <div className="mt-1.5 mon-400-14 text-left text-error-500">
          {helperText}
        </div>
      )}
    </div>
  );
};

export default CustomAddressInput;
