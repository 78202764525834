/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from "react";
import CustomModal from "../../../src/Core/Common/CustomModal";
import CustomButton from "../../../src/Core/Common/CustomButton";
import CustomInputWithEdit from "../../Core/Common/CustomInputWithEdit";
import { DatePicker, Divider } from "antd";
import { useAppDispatch, useAppSelector } from "../../Core/redux/hooks";
import {
  createSprint,
  getSprintsByPhase,
} from "../../Core/redux/api/scheduleAPI";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";
import NewScopeBadge from "./NewScopeBadge";

type NewSprintModalProps = {
  visible: boolean;
  onCancel: () => void;
};
const NewSprintModal = ({ visible, onCancel }: NewSprintModalProps) => {
  const [sprints, setSprints] = useState([
    {
      id: 0,
      title: "",
      startDate: "",
      endDate: "",
    },
  ]);
  const dispatch = useAppDispatch();
  const { currentSelectedPhase, newSprintScope } = useAppSelector(
    (state) => state.schedule
  );
  const { projectId } = useParams();
  const handleChange = ({ id, key, value }) => {
    const newSprints = [...sprints];

    newSprints.map((s) => {
      if (s.id === id) {
        s[key] = value;
        return s;
      }
      return s;
    });

    setSprints([...newSprints]);
  };
  return (
    <CustomModal
      title={
        <div className="flex items-center">
          <p className="mon-400-16 pl-3 text-gray-400">Schedule</p>
          <span className="mon-400-16 pl-3 text-gray-400"> / </span>
          <p className="mon-400-16 pl-3 text-gray-400">
            {currentSelectedPhase.phases}
          </p>
          <span className="pl-3 text-black"> / </span>
          <p className="flex mon-500-16 text-black pl-3">Sprint</p>
          {newSprintScope ? <NewScopeBadge /> : null}
        </div>
      }
      width={"1064px"}
      height={"608px"}
      visible={visible}
      body={
        <div className="max-h-[48.189386056191466vh] h-[608px] max-w-[1064px] w-[90vw] overflow-y-scroll">
          <div className="flex items-center px-5 py-5">
            <img
              src="/images/icons/frosty-help.svg"
              className="h-9 w-9"
              alt="frosty"
            />
            <p className="mon-500-14 text-primary-600 ml-2">
              A sprint is a short, time-boxed period when a scrum team works to
              complete a set amount of work.
            </p>
          </div>
          <div className="px-4 ">
            {sprints.map((sprint, index) => (
              <div key={index} className="pt-2">
                <CustomInputWithEdit
                  label={`Sprint ${index + 1}`}
                  value={sprint.title}
                  inputClassName="text-gray-500 mon-400-14"
                  width="60%"
                  placeholder="Add a name"
                  onChange={(e) =>
                    handleChange({
                      id: sprint.id,
                      key: "title",
                      value: e.target.value,
                    })
                  }
                />
                <div className=" mt-9 w-[30%]">
                  <div className="mon-500-14 text-gray-700 pb-1.5">
                    Start date
                  </div>
                  <DatePicker
                    className="w-full h-10 bg-transparent border-gray-300 rounded-xl shadow-xs"
                    suffixIcon={<></>}
                    format="DD MMM YYYY"
                    value={sprint.startDate as any}
                    onChange={(e) =>
                      handleChange({
                        id: sprint.id,
                        key: "startDate",
                        value: e,
                      })
                    }
                  />
                </div>
                <div
                  className=" mt-9 w-[30%] mb-9"
                  style={{
                    paddingBottom: index + 1 === sprints.length && "20px",
                  }}
                >
                  <div className="mon-500-14 text-gray-700 pb-1.5">
                    End date
                  </div>
                  <DatePicker
                    className="w-full h-10 bg-transparent border-gray-300 rounded-xl shadow-xs"
                    suffixIcon={<></>}
                    format="DD MMM YYYY"
                    value={sprint.endDate as any}
                    onChange={(e) =>
                      handleChange({
                        id: sprint.id,
                        key: "endDate",
                        value: e,
                      })
                    }
                  />
                </div>
                {index + 1 !== sprints.length && (
                  <Divider className="max-w-[60%] min-w-[60%]" />
                )}
              </div>
            ))}
          </div>
        </div>
      }
      footer={
        <div className="flex justify-between py-4 gap-x-3 px-3 shadow-upward">
          <CustomButton
            text="Add more"
            onClick={() =>
              setSprints([
                ...sprints,
                {
                  id: sprints.length,
                  title: "",
                  startDate: "",
                  endDate: "",
                },
              ])
            }
            className="border border-gray-300 text-gray-700 bg-white mon-500-14 shadow-xs"
            height="40px"
            width="131px"
            icon="/images/icons/plus-gray.svg"
          />
          <div className="flex gap-x-3">
            <CustomButton
              text="Cancel"
              onClick={() => onCancel()}
              className="border border-gray-300 text-gray-700 bg-white mon-500-14"
              height="40px"
              width="81px"
            />
            <CustomButton
              text="Create"
              onClick={async () => {
                await dispatch(
                  createSprint({
                    sprints: sprints.map((s) => {
                      return {
                        title: s.title,
                        startDate: dayjs(s.startDate).format("YYYY-MM-DD"),
                        endDate: dayjs(s.endDate).format("YYYY-MM-DD"),
                      };
                    }),
                    projectId: projectId,
                    phaseId: currentSelectedPhase.id,
                    isNewSprint: newSprintScope,
                    //phaseEndDate: "12/23/3453",
                    //phaseEndDateChangeReason: "this is due to my self",
                    // projectEndDate: "12/121/13",
                    // projectEndDateChangeReason: "testing the reaseon",
                    senderId: 74,
                  })
                );
                onCancel();
                dispatch(
                  getSprintsByPhase({
                    projectId,
                    phaseId: currentSelectedPhase.id,
                  })
                );
              }}
              className="mon-500-14 text-white bg-primary-600 "
              height="40px"
              width="85px"
            />
          </div>
        </div>
      }
      onCancel={onCancel}
    />
  );
};
export default NewSprintModal;
