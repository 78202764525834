import React, { useEffect } from "react";
import { GoogleLogin } from "react-google-login";
import { gapi } from "gapi-script";
import { client } from "../../Core/utils/axiosClient";
import { userAuthenticateGoogle } from "../../Core/redux/api/userAPI";
import { useAppDispatch, useAppSelector } from "../../Core/redux/hooks";
import { useNavigate } from "react-router-dom";

// https://blog.logrocket.com/guide-adding-google-login-react-app/
const IdentityProviderGoogleSlice = () => {
  const CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;

  const dispatch = useAppDispatch();

  const { loggedIn } = useAppSelector((state) => state.userDetails);
  const navigate = useNavigate();

  useEffect(() => {
    const initClient = () => {
      gapi.auth2.init({
        clientId: CLIENT_ID,
        scope: "",
      });
    };
    gapi.load("client:auth2", initClient);
  }, []);

  const responseGoogle = async (googleInfo) => {
    console.log(googleInfo, "info g");

    const { accessToken } = googleInfo;
    const { email, familyName, givenName, imageUrl } = googleInfo.profileObj;

    if (accessToken && email) {
      // const response = await client.post("/auth/google-login", {
      //   email,
      //   isMobile: false,
      //   accessToken,
      //   lastName: familyName,
      //   firstName: givenName,
      //   profilePicture: imageUrl,
      // });
      // console.log(response, "firstname lastname");

      await dispatch(
        userAuthenticateGoogle({
          email,
          accessToken,
          lastName: familyName,
          firstName: givenName,
          profilePicture: imageUrl,
        })
      );

      if (loggedIn) {
        navigate("/dashboard", { replace: true });
      }
    }
  };

  return (
    <div>
      <GoogleLogin
        clientId={CLIENT_ID}
        render={(renderProps) => (
          <button
            className="w-14 h-14 rounded-xl border border-gray-100 flex-center mr-3.5 cursor-pointer"
            disabled={renderProps.disabled}
            onClick={renderProps.onClick}
          >
            <img
              src="/images/icons/google_icon.svg"
              className="w-6"
              alt="Google"
            />
          </button>
        )}
        buttonText="Login"
        onSuccess={responseGoogle}
        onFailure={responseGoogle}
        cookiePolicy={"single_host_origin"}
      />
    </div>
  );
};

export default IdentityProviderGoogleSlice;
