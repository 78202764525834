import {
  Checkbox,
  DatePicker,
  Dropdown,
  Input,
  Menu,
  TimePicker,
  Tooltip,
} from "antd";
import dayjs from "dayjs";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
// import CustomAvatar from "../../../../Core/Common/CustomAvatar";
// import CustomButton from "../../../Core/Common/CustomButton";
// import CustomInputWithEdit from "../../../Core/Common/CustomInputWithEdit";
// import CustomInputWithSaveAndEdit from "../../../Core/Common/CustomInputWithSaveAndEdit";
// import CustomModal from "../../../Core/Common/CustomModal";
// import { CustomSearchWithDropDown } from "../../../Core/Common/CustomSearch";
// import { CustomSelectWithInlineSearch } from "../../../Core/Common/CustomSelectWithSearch";
// import DatePicker from "../../../Core/Dayjs/DatePicker";
// import {
//   createEvent,
//   getGoalsBySprint,
// } from "../../../Core/redux/api/scheduleAPI";
// import { useAppDispatch, useAppSelector } from "../../../Core/redux/hooks";
// import { resetSchedule } from "../../../Core/redux/slices/schedule.slice";
// import PhaseIcon from "../../../Core/svg/PhaseIcon";
// import { client } from "../../../Core/utils/axiosClient";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useAppDispatch, useAppSelector } from "../../Core/redux/hooks";
import {
  createEvent,
  getGoalsBySprint,
} from "../../Core/redux/api/scheduleAPI";
import { resetSchedule } from "../../Core/redux/slices/schedule.slice";
import CustomModal from "../../Core/Common/CustomModal";
import CustomInputWithSaveAndEdit from "../../Core/Common/CustomInputWithSaveAndEdit";
import { CustomSelectWithInlineSearch } from "../../Core/Common/CustomSelectWithSearch";
import PhaseIcon from "../../Core/svg/PhaseIcon";
import CustomButton from "../../Core/Common/CustomButton";
import { CustomSearchWithDropDown } from "../../Core/Common/CustomSearch";
import CustomAvatar from "../../Core/Common/CustomAvatar";

type NewEventModalProps = {
  visible: boolean;
  onCancel: () => void;
};

const NewGlobalEventModal = ({ visible, onCancel }: NewEventModalProps) => {
  const dispatch = useAppDispatch();

  const { selectedEvent, sprints } = useAppSelector((state) => state.schedule);
  const { user } = useAppSelector((state) => state.userDetails);

  const GoogleMeetMeta =
    selectedEvent !== null
      ? JSON.parse(selectedEvent.meetMetaData)
      : { location: "", start: { dateTime: null }, end: { dateTime: null } };

  console.log(GoogleMeetMeta, "GoogleMeetMeta");

  const { phases, selectedSprint } = useAppSelector((state) => state.schedule);
  const [selectedUsers, setSelectedUsers] = useState([]);
  //const [sprints, setSprints] = useState([]);
  const { projectId } = useParams();
  const [sprintId, setSprintId] = useState(
    selectedEvent?.sprintId ? selectedEvent.sprintId : selectedSprint.id
  );
  const [phaseId, setPhaseId] = useState(
    selectedEvent !== null ? selectedEvent.phaseId : null
  );
  const [title, setTitle] = useState(
    selectedEvent !== null ? selectedEvent.name : ""
  );

  const [location, setLocation] = useState(
    GoogleMeetMeta.location ? GoogleMeetMeta.location : ""
  );
  const [description, setDescription] = useState(
    selectedEvent !== null ? selectedEvent.description : null
  );
  const [date, setDate] = useState(GoogleMeetMeta.start.dateTime);
  const [startTime, setStartTime] = useState(
    GoogleMeetMeta.start.dateTime ? GoogleMeetMeta.start.dateTime : null
  );
  const [endTime, setEndTime] = useState(
    GoogleMeetMeta.end.dateTime ? GoogleMeetMeta.end.dateTime : null
  );

  const formik = useFormik({
    initialValues: {
      title: selectedEvent !== null ? selectedEvent.name : "New Event",
      startTime: GoogleMeetMeta.start.dateTime
        ? GoogleMeetMeta.start.dateTime
        : null,
      endTime: GoogleMeetMeta.end.dateTime ? GoogleMeetMeta.end.dateTime : null,
    },
    validationSchema: Yup.object({
      title: Yup.string()
        .required("Please enter the title")
        .typeError("Please enter the title"),
    }),
    enableReinitialize: true,
    onSubmit: (values) => {
      console.log(values);
    },
  });
  const [isGoogleMeet, setIsGoogleMeet] = useState(
    selectedEvent !== null ? (selectedEvent.meetLink ? true : false) : false
  );
  const [events, setEvents] = useState([
    {
      id: 0,
      title: "",
      date: "",
    },
  ]);

  const PHASE_OPTION_TYPES = [
    {
      id: "1",
      title: "Ongoing",
      filter: (phase) => phase.isOngoing || phase.isCompleted,
    },
    {
      id: "2",
      title: "Yet to begin",
      filter: (phase) => !phase.isOngoing && !phase.isCompleted,
    },
  ];

  // const getSprints = async () => {
  //   const response = await client.get(
  //     `/schedule/sprints?phaseId=${phaseId}&projectId=${projectId}`
  //   );

  //   setSprints(response.data.result);
  //   console.log(response.data);
  // };

  // useEffect(() => {
  //   getSprints();
  // }, [phaseId]);

  const handelCreate = async () => {
    const payload = {
      //   sprintId: sprintId,
      projectId: 0,
      //   phaseId: phaseId,
      name: title,
      isCompleted: 0,
      statusId: 1,
      priorityId: 1,
      goalType: 2,
      // assigneeId: task.assigneeId,
      // reporterId: task.reporterId,
      description: description,
      location: location,
      startTime: startTime,
      endTime: endTime,
      isGoogleMeet,
      attendees: selectedUsers.map((u) => {
        return { email: u.userEmail };
      }),
    };

    await dispatch(createEvent(payload));

    dispatch(resetSchedule(["newEventModal"]));
    // dispatch(getGoalsBySprint({ proje sprintId: selectedSprint.id }));

    console.log(payload, "event");
  };

  const { tenantMembers } = useAppSelector((state) => state.tenant);

  const getUserByEmail = (email) => {
    const result = tenantMembers.find((u) => u.email === email);
    return result;
  };

  return (
    <CustomModal
      title={
        <div className="flex items-center">
          <p className="mon-400-16 pl-3 text-gray-400">Ideation</p>
          <span className="mon-400-16 pl-3 text-gray-400"> / </span>
          <p className="flex mon-500-16 text-black pl-3">{title}</p>
        </div>
      }
      width="704px"
      visible={visible}
      height="700px"
      body={
        <div className="p-4 max-h-[38vh] h-[412px]  overflow-y-scroll">
          {events.map((event, index) => (
            <div key={index} className="pt-2">
              <div className="mt-1.5">
                <CustomInputWithSaveAndEdit
                  label="Title"
                  value={title}
                  inputClassName="mon-500-15 text-gray-900"
                  placeholder="Title"
                  onChange={(e) => setTitle(e.target.value)}
                />
              </div>

              <div className="mon-500-14 text-gray-700 mt-6">Description</div>
              <div className="mt-1.5">
                <Input.TextArea
                  rows={3}
                  placeholder="Add a description"
                  required={true}
                  className="w-full h-full bg-white rounded-xl border border-gray-300 text-gray-500 pl-4 pt-2  active:outline-none "
                  value={description}
                  onChange={(e) => {
                    setDescription(e.target.value);
                  }}
                ></Input.TextArea>
              </div>
              <div className="mt-6">
                <CustomInputWithSaveAndEdit
                  label="Location"
                  value={location}
                  inputClassName="mon-500-14 text-black"
                  placeholder="Add Location"
                  onChange={(e) => setLocation(e.target.value)}
                />
              </div>
              <div className="flex items-center justify-between mt-9">
                <div className="w-[49%]">
                  <div className="mon-500-14 text-gray-700">Start Time</div>
                  <DatePicker
                    showTime={{ format: "hh:mm a" }}
                    format="dddd, DD MMMM hh:mm a"
                    className="w-full h-10 bg-transparent border-gray-300 rounded-xl shadow-xs mt-3"
                    suffixIcon={<></>}
                    // format="dddd, DD MMMM"
                    value={startTime ? (dayjs(startTime) as any) : null}
                    //onChange={(e) =>
                    // handleChange({
                    //   id: event.id,
                    //   key: "date",
                    //   value: e,
                    // })
                    // }
                    onChange={(e) => setStartTime(e.toString())}
                  />
                </div>
                <div className="w-[49%]">
                  <div className="mon-500-14 text-gray-700">End Time</div>
                  <DatePicker
                    showTime={{ format: "hh:mm a" }}
                    format="dddd, DD MMMM hh:mm a"
                    className="w-full h-10 bg-transparent border-gray-300 rounded-xl shadow-xs mt-3"
                    suffixIcon={<></>}
                    // format="dddd, DD MMMM"
                    value={endTime ? (dayjs(endTime) as any) : null}
                    //onChange={(e) =>
                    // handleChange({
                    //   id: event.id,
                    //   key: "date",
                    //   value: e,
                    // })
                    // }
                    onChange={(e) => setEndTime(e.toString())}
                  />
                </div>

                {/* <div className="mx-3">
                  <div className="w-[70%]">
                    <div className="mon-500-14 text-gray-700 ml-3">Time</div>
                    <div className="flex items-center ml-3">
                      <div className="flex items-center gap-x-2">
                        <TimePicker
                          className="mt-2.5 w-36 h-[42px] rounded-xl bg-transparent border-gray-300 border-[1px] pl-2 py-2.5 text-gray-500"
                          placeholder="Start Time"
                          value={startTime}
                          onChange={(e) => setStartTime(e)}
                        />
                        <TimePicker
                          className="mt-2.5 w-36 h-[42px] rounded-xl bg-transparent border-gray-300 border-[1px] pl-2 py-2.5 text-gray-500"
                          placeholder="End Time"
                          value={endTime}
                          onChange={(e) => setEndTime(e)}
                        />
                        {/* <TimePicker.RangePicker className="mt-2.5  h-[42px] rounded-xl bg-transparent border-gray-300 border-[1px] pl-2 py-2.5 text-gray-500" /> */}
                {/* </div>
                    </div>
                  </div>
                </div> */}
              </div>

              {selectedEvent === null ? (
                <>
                  {/* <div className="mt-12">
                    <Checkbox value="All Day" />
                    <span className="mon-400-12 ml-2 whitespace-nowrap text-gray-700">
                      All day
                    </span>
                  </div>
                  <div className="mt-5">
                    <Checkbox value="Set a timezone" />
                    <span className="mon-400-12 ml-2 whitespace-nowrap text-gray-700">
                      Set a timezone
                    </span>
                  </div> */}
                  <div className="mt-12">
                    <CustomButton
                      text=" Add Google Meet video conferencing"
                      className=" mon-500-14 text-white whitespace-nowrap bg-primary-600 "
                      height="44px"
                      width="325px"
                      icon={
                        isGoogleMeet
                          ? "/images/icons/check-white.svg"
                          : "/images/icons/plus.svg"
                      }
                      onClick={() => setIsGoogleMeet(!isGoogleMeet)}
                    />
                  </div>
                  <div className="mt-9">
                    <p className="mon-500-14 text-gray-700 pb-2 pl-1">
                      Add guests
                    </p>
                    <CustomSearchWithDropDown
                      style={{
                        border: "1px solid #C0CBD7",
                        height: "42px",
                      }}
                      placeholder="Search team members"
                      options={tenantMembers
                        // Filters current user from tenantMembers
                        .filter(({ userId }) => userId !== user.id)
                        // Filters selected users as they cannot be added again, thus should not be show in dropdown
                        .filter(
                          (u) =>
                            selectedUsers?.findIndex(
                              (index) => index.userId === u.userId
                            ) === -1
                        )
                        // Converts user data to an object which is of type options
                        .map((u) => {
                          return {
                            id: u.userId,
                            value: u.userId,
                            label: `${u.firstname} ${u.lastname}`,
                            render: (value) => (
                              <div
                                className="flex items-center -mt-0.5"
                                //key={u.userId}
                                onClick={() =>
                                  setSelectedUsers((oldList) => [
                                    ...oldList,
                                    {
                                      userId: u.userId,
                                      userEmail: u.email,
                                    },
                                  ])
                                }
                              >
                                <CustomAvatar
                                  title={u.firstname ? u.firstname : "User"}
                                  src={u.profilePicture}
                                />

                                <p className="ml-2 mon-400-12 text-gray-700 capitalize">
                                  {u.firstname} {u.lastname}
                                </p>
                              </div>
                            ),
                          };
                        })}
                    />
                  </div>
                  <div className="mt-9 px-3">
                    <div
                      className="flex justify-between items-center py-2.5"
                      key={user.id}
                    >
                      <div className="flex items-center">
                        <CustomAvatar
                          title={`${user.firstName}`}
                          size={24}
                          src={user.profilePicture}
                        />
                        <p className="ml-2 mon-400-12 text-gray-700 capitalize">
                          {user.firstName} {user.lastName}
                        </p>
                      </div>
                      <p className="mon-400-12 text-gray-500">Organiser</p>
                    </div>
                    {tenantMembers.map((user) => {
                      if (
                        selectedUsers?.findIndex(
                          (u) => user.userId === u.userId
                        ) !== -1
                      ) {
                        return (
                          <div
                            className="flex justify-between items-center py-2.5"
                            key={user.id}
                          >
                            <div className="flex items-center">
                              <CustomAvatar
                                title={`${user.firstname}`}
                                size={24}
                                src={user.profilePicture}
                              />
                              <p className="ml-2 mon-400-12 text-gray-700 capitalize">
                                {user.firstname} {user.lastname}
                              </p>
                            </div>
                          </div>
                        );
                      }
                    })}
                  </div>
                </>
              ) : (
                <>
                  <div className="flex mt-6 justify-between">
                    <CustomButton
                      text="Join with Google Meet"
                      className=" mon-500-14 text-white whitespace-nowrap bg-primary-600 "
                      height="44px"
                      width=""
                      onClick={() =>
                        window.open(
                          selectedEvent.meetLink,
                          "_blank",
                          "noopener,noreferrer"
                        )
                      }
                    />
                    <button
                      onClick={async () =>
                        await navigator.clipboard.writeText(
                          selectedEvent.meetLink
                        )
                      }
                    >
                      <Tooltip title="Copy link" placement="left">
                        <img src="/images/icons/copy.svg" />
                      </Tooltip>
                    </button>
                  </div>
                  <div className="mon-500-12 text-gray-500 mt-4">
                    Meeting ID
                  </div>
                  <div className="mon-400-12 text-gray-900 mt-1.5">
                    {selectedEvent.meetLink}
                  </div>
                  <div className="mon-500-14 text-gray-700 mt-6">Attendees</div>
                  <div className="mt-1.5">
                    {GoogleMeetMeta.attendees.map((u, i) => {
                      return (
                        <div
                          className="flex justify-between items-center py-2.5"
                          key={i}
                        >
                          <div className="flex items-center">
                            <CustomAvatar
                              title={`${
                                getUserByEmail(u.email)?.firstname
                                  ? getUserByEmail(u.email)?.firstname
                                  : u.email
                              }`}
                              size={24}
                              src={getUserByEmail(u.email)?.profilePicture}
                            />
                            <p className="ml-2 mon-400-12 text-gray-700 capitalize">
                              {getUserByEmail(u.email)?.firstname
                                ? getUserByEmail(u.email)?.firstname +
                                  " " +
                                  getUserByEmail(u.email)?.lastname
                                : u.email}
                            </p>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <div className="mon-500-14 text-gray-700 mt-6">Organizer</div>
                  <div className="mt-1.5">
                    <div className="flex justify-between items-center py-2.5">
                      <div className="flex items-center">
                        <CustomAvatar
                          title={`${
                            getUserByEmail(GoogleMeetMeta.organizer?.email)
                              ?.firstname
                              ? getUserByEmail(GoogleMeetMeta.organizer?.email)
                                  ?.firstname
                              : GoogleMeetMeta.organizer?.email
                          }`}
                          size={24}
                          src={
                            getUserByEmail(GoogleMeetMeta.organizer?.email)
                              ?.profilePicture
                          }
                        />
                        <p className="ml-2 mon-400-12 text-gray-700 capitalize">
                          {getUserByEmail(GoogleMeetMeta.organizer?.email)
                            ?.firstname
                            ? getUserByEmail(GoogleMeetMeta.organizer?.email)
                                ?.firstname +
                              " " +
                              getUserByEmail(GoogleMeetMeta.organizer?.email)
                                ?.lastname
                            : GoogleMeetMeta.organizer?.email}
                        </p>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          ))}
        </div>
      }
      footer={
        <div className="flex justify-end py-4 gap-x-3 px-3 shadow-upward">
          {selectedEvent !== null ? (
            <CustomButton
              text="Close"
              onClick={() => onCancel()}
              className="border border-gray-300 text-gray-700 bg-white mon-500-14"
              height="40px"
              width="81px"
            />
          ) : (
            <div className="flex gap-x-3">
              <CustomButton
                text="Cancel"
                onClick={() => onCancel()}
                className="border border-gray-300 text-gray-700 bg-white mon-500-14"
                height="40px"
                width="81px"
              />
              <CustomButton
                text="Create Event"
                disabled={
                  selectedUsers.length === 0 ||
                  startTime === null ||
                  endTime === null
                }
                className="mon-500-14 text-white bg-primary-600 "
                height="40px"
                width="124px"
                onClick={() => handelCreate()}
              />
            </div>
          )}
        </div>
      }
      onCancel={onCancel}
    />
  );
};

export default NewGlobalEventModal;
