import React, { useEffect, useState } from "react";
import { requestPermission } from "../../FireBase/firebaseInit";
import { useAppSelector } from "../redux/hooks";

const AllowNotificationPopup = () => {
  const [fcmTokenFound, setFCMTokenFound] = useState(false);
  const [
    dontShowAllowNotificationInPopup,
    setDontShowAllowNotificationInPopup,
  ] = useState(sessionStorage.getItem("dontShowAllowNotificationInPopup"));
  const { user, loggedIn } = useAppSelector((state) => state.userDetails);
  const [isFireBaseServiceBaseRegisterd, setIsFireBaseServiceBaseRegisterd] =
    useState(false);

  useEffect(() => {
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker
        .getRegistration("firebase-messaging-sw.js")
        .then(function (registration) {
          console.log(
            "firebase-messaging-sw.js",
            registration,
            Notification.permission
          );
          if (registration) {
            setIsFireBaseServiceBaseRegisterd(true);
          }
        });
    }
  }, [Notification.permission]);

  console.log(fcmTokenFound, "[PUSH]:fcmTokenFound");
  if (
    Notification.permission === "granted" ||
    dontShowAllowNotificationInPopup ||
    !loggedIn
  ) {
    return null;
  }
  return (
    <div className="fixed bottom-0 left-0 right-0 h-16 z-[99] bg-primary-100">
      <div className="h-16 flex items-center px-6 justify-between">
        <div
          className="mon-500-14"
          onClick={() => {
            requestPermission(setFCMTokenFound);
          }}
        >
          Waddle needs your permission to{" "}
          <span className="underline cursor-pointer">
            {" "}
            enable desktop notifications.
          </span>
        </div>
        <div
          onClick={() => {
            sessionStorage.setItem("dontShowAllowNotificationInPopup", "yes");
            setDontShowAllowNotificationInPopup("yes");
          }}
        >
          <img
            className="h-4 w-4 cursor-pointer"
            src="/images/icons/cross.svg"
          />
        </div>
      </div>
    </div>
  );
};

export default AllowNotificationPopup;
