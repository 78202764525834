import { Tooltip } from "antd";
import React, { useState, useEffect } from "react";
import CustomButton from "../../../Core/Common/CustomButton";
import FrostyErrorMessage from "../../../Core/Common/FrostyErrorMessage";
import { useAppDispatch, useAppSelector } from "../../../Core/redux/hooks";
import {
  changeStep,
  updateProjectOnboarding,
} from "../../../Core/redux/slices/projectOnboarding.slice";
import CurrentStep from "./CurrentStep";
import StepHeading from "./StepHeading";
import StepLayout from "./StepLayout";

const ProjectTypeCard = ({ img, title, select, type, isSelected }) => {
  const [open, setOpen] = useState(isSelected);

  useEffect(() => {
    setOpen(isSelected);
  }, [isSelected]);
  return (
    <Tooltip
      placement="bottomLeft"
      overlayClassName="min-w-[371px]"
      open={open}
      title={
        <div className="flex">
          <div>
            Awesome, curating a list of components based on your selection!
          </div>
          <div onClick={() => setOpen(false)} className="cursor-pointer">
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12 4L4 12M4 4L12 12"
                stroke="#ffffff"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        </div>
      }
    >
      <div
        className={`${
          isSelected
            ? "bg-primary-600"
            : "bg-white hover:bg-[#F0F5FA] border border-gray-200"
        } rounded-2xl flex justify-between flex-col h-32 w-32 cursor-pointer`}
        onClick={() => {
          select(type);
          setOpen(true);
        }}
      >
        <div className="flex justify-center items-center h-full">
          <img src={img} alt="" />
        </div>
        <div
          className={`mon-500-12 ${
            isSelected ? "text-white" : "text-gray-500"
          } w-full text-center pb-4`}
        >
          {title}
        </div>
      </div>
    </Tooltip>
  );
};

const Step4 = () => {
  const dispatch = useAppDispatch();
  const [error, setError] = useState(false);
  const { softwareType } = useAppSelector((state) => state.projectOnboarding);
  console.log(softwareType, "softwareType");
  const setSoftwareType = (type) => {
    if (JSON.stringify(softwareType).includes(type)) {
      dispatch(
        updateProjectOnboarding({
          key: "softwareType",
          value: softwareType.filter((i) => i !== type),
        })
      );
    } else {
      dispatch(
        updateProjectOnboarding({
          key: "softwareType",
          value: [...softwareType, type],
        })
      );
    }
  };
  return (
    <StepLayout>
      <CurrentStep className={"mt-20"} currentStep={4} totalStep={7} />
      <StepHeading
        title={
          <p className="text-gray-900 mon-500-20">
            Next, what kind of software are you building?
          </p>
        }
        subTitle={
          <p className="mon-400-16 text-gray-500">
            Select the right platform for your project and I will suggest the
            right
            <br />{" "}
            <span className="text-primary-600 cursor-pointer">
              components{" "}
              <Tooltip
                placement="bottom"
                overlayClassName=""
                // open={open}
                title={
                  <div className="flex gap-x-2">
                    <div>Eg. Figma, React JS</div>
                  </div>
                }
              >
                <img
                  src="/images/icons/project-onboarding/alert-circle.svg"
                  className="inline"
                />{" "}
              </Tooltip>
            </span>{" "}
            later, to make it epic!
          </p>
        }
      />
      <div className=" mt-6 flex gap-x-6">
        <ProjectTypeCard
          img={
            JSON.stringify(softwareType).includes("1")
              ? "/images/icons/project-onboarding/custom-website-white.svg"
              : "/images/icons/project-onboarding/custom-website-gray.svg"
          }
          title="Custom Website"
          select={setSoftwareType}
          type={1}
          isSelected={JSON.stringify(softwareType).includes("1")}
        />
        <ProjectTypeCard
          img={
            JSON.stringify(softwareType).includes("2")
              ? "/images/icons/project-onboarding/mobile-app-white.svg"
              : "/images/icons/project-onboarding/mobile-app-gray.svg"
          }
          title="Mobile App"
          select={setSoftwareType}
          type={2}
          isSelected={JSON.stringify(softwareType).includes("2")}
        />
        <ProjectTypeCard
          img={
            JSON.stringify(softwareType).includes("3")
              ? "/images/icons/project-onboarding/web-app-white.svg"
              : "/images/icons/project-onboarding/web-app-gray.svg"
          }
          title="Web App"
          select={setSoftwareType}
          type={3}
          isSelected={JSON.stringify(softwareType).includes("3")}
        />{" "}
        <ProjectTypeCard
          img={
            JSON.stringify(softwareType).includes("4")
              ? "/images/icons/project-onboarding/other-white.svg"
              : "/images/icons/project-onboarding/other-gray.svg"
          }
          title="Other"
          select={setSoftwareType}
          type={4}
          isSelected={JSON.stringify(softwareType).includes("4")}
        />
      </div>
      <FrostyErrorMessage
        messgae={"Please Select The Type of software"}
        visible={softwareType.length === 0 && error}
        className="my-10"
      />
      <div className="mt-6 flex gap-x-6">
        <CustomButton
          text="Continue"
          className={`bg-primary-600 text-white ${
            softwareType.length === 0 ? "opacity-30" : ""
          }`}
          height="40px"
          width="98px"
          onClick={() => {
            if (softwareType.length === 0) {
              setError(true);
              return;
            }
            dispatch(changeStep(5));
          }}
        />
        <CustomButton
          text="Back"
          className="border border-gray-300 text-gray-700 bg-white mon-500-14"
          height="40px"
          width="68px"
          onClick={() => dispatch(changeStep(3))}
        />
      </div>
    </StepLayout>
  );
};

export default Step4;
