import React, { useState } from "react";
import { Drawer, Tabs } from "antd";
import CustomSearch, {
  CustomSearchWithDropDown,
} from "../../../Core/Common/CustomSearch";
import LeaveThreadModal from "../Common/LeaveThreadModal";
import { useAppDispatch, useAppSelector } from "../../../Core/redux/hooks";
import {
  updateConversations,
  updateConversationsMultiple,
} from "../../../Core/redux/slices/conversations.slice";
import {
  getProjectsWithThreads,
  getThreads,
  getThreadsMembers,
  updateThread,
} from "../../../Core/redux/api/conversationAPI";
import { useParams } from "react-router-dom";
import CustomAvatar from "../../../Core/Common/CustomAvatar";
import PhaseIcon from "../../../Core/svg/PhaseIcon";
import dayjs from "dayjs";
import CustomButton from "../../../Core/Common/CustomButton";
import { CustomSelectWithInlineSearch } from "../../../Core/Common/CustomSelectWithSearch";
import CustomInputWithEdit from "../../../Core/Common/CustomInputWithEdit";
import { useEffect } from "react";

type ThreadProfileDrawerProps = {
  visible: boolean;
  onClose: () => void;
  user: {
    firstname: string;
    lastname: string;
    roleName: string;
    profilePicture: string;
  };
  details: {
    phase: string;
    phaseIcon: string;
    goalConnected: string;
    creator: string;
    creatorProfilePicture: string;
    createdOn: string;
  };
};

const ThreadProfileDrawer = ({
  visible,
  onClose,
  // user,
  details,
}: ThreadProfileDrawerProps) => {
  const { TabPane } = Tabs;
  const {
    currentThread: editThreadDetails,
    editThread,
    threadMembers,
    threadProfileDrawerCurrenTab,
  } = useAppSelector((state) => state.conversations);

  const { projectId } = useParams();

  const dispatch = useAppDispatch();

  const getThreadsMembersAsync = async () =>
    editThreadDetails.threadId &&
    (await dispatch(
      getThreadsMembers({ threadId: editThreadDetails.threadId })
    ));
  React.useEffect(() => {
    getThreadsMembersAsync();
    // setSelectedUsers(threadMembers.map((m) => m.userId));
  }, [editThread, editThreadDetails]);

  //const { userList } = useAppSelector((state) => state.overview);
  const { tenantMembers: userList } = useAppSelector((state) => state.tenant);
  const { user } = useAppSelector((state) => state.userDetails);
  const [selectedUsers, setSelectedUsers] = useState(
    editThread ? threadMembers.map((m) => +m.userId) : []
  );
  const [currentTab, setCurrentTab] = useState(threadProfileDrawerCurrenTab);
  const [addMorePeople, setAddMorePeople] = useState(false);
  const [leaveThreadModal, setLeaveThreadModal] = useState(false);
  const [threadChanges, setThreadChanges] = useState({
    threadId: "",
    ...editThreadDetails,
    phaseName: null,
  });
  const { currentPhaseGoals, phases } = useAppSelector(
    (state) => state.schedule
  );
  const [showEdit, setShowEdit] = useState(false);

  useEffect(
    () => setCurrentTab(threadProfileDrawerCurrenTab),
    [threadProfileDrawerCurrenTab]
  );

  useEffect(() => {
    setSelectedUsers(threadMembers.map((m) => +m.userId));
  }, [threadMembers]);

  useEffect(() => {
    setThreadChanges({ ...editThreadDetails, phaseName: null });
  }, [editThreadDetails]);
  const { leaveThread } = useAppSelector((state) => state.conversations);

  const getPhaseNameById = (id) => {
    switch (id) {
      case 1:
        return "Ideation";
      case 2:
        return "Design";
      case 3:
        return "Development";
      case 4:
        return "Deployment";
      case 5:
        return "Maintenance";
      default:
        return "N/A";
    }
  };

  const handleThreadChanges = async () => {
    dispatch(
      updateConversations({
        key: "editThreadDetails",
        value: {
          ...editThreadDetails,
          threadName: threadChanges.threadName,
          phaseId: threadChanges.phaseId,
        },
      })
    );

    const payload = {
      threadId: threadChanges.threadId,
      name: threadChanges.threadName,
      phaseId: threadChanges.phaseId,
    };

    await dispatch(updateThread(payload));
    // dispatch(getThreads({ userId: user.id }));
    // dispatch(getProjectsWithThreads({ userId: user.id }));
    onClose();
  };

  return (
    <Drawer
      placement="right"
      open={visible}
      closable={false}
      className="custom-drawer"
      width="33.875rem"
      onClose={onClose}
      destroyOnClose
    >
      <div>
        <button onClick={() => (showEdit ? setShowEdit(false) : onClose())}>
          <img src="/images/icons/chevron-right-double.svg" alt="close" />
        </button>
        {showEdit && (
          <div>
            <div className="flex justify-center w-full">
              <PhaseIcon
                width="40px"
                height="40px"
                phaseId={+editThreadDetails.phaseId}
                icon
              />
            </div>
            <div className="p-4 mt-4">
              <CustomInputWithEdit
                value={threadChanges.threadName}
                placeholder="Add a name"
                onChange={(e) =>
                  setThreadChanges({
                    ...threadChanges,
                    threadName: e.target.value,
                  })
                }
              />

              <p className="mon-500-10 text-gray-500 mt-12 uppercase">Phase</p>
              <div className="mt-1.5">
                <CustomSelectWithInlineSearch
                  containerStyle={{
                    width: "100%",
                  }}
                  options={[
                    {
                      id: "1",
                      title: "Ongoing",
                      options: phases
                        .filter((phase) => phase.isOngoing || phase.isCompleted)
                        .map((p) => {
                          return {
                            id: p.id,
                            value: p.id,
                            render: (value) => (
                              <div
                                className="flex items-center -mt-0.5"
                                key={p.id + "phases"}
                                onClick={() =>
                                  setThreadChanges({
                                    ...threadChanges,
                                    phaseId: p.id.toString(),
                                    phaseName: p.phases,
                                  })
                                }
                              >
                                <PhaseIcon
                                  width={"16"}
                                  height={"16"}
                                  phaseId={+p.id}
                                  icon
                                />

                                <p className="mon-400-14 pl-2 text-gray-900 capitalize ">
                                  {p.phases}
                                </p>
                              </div>
                            ),
                          };
                        }),
                    },
                    {
                      id: "2",
                      title: "Yet to begin",
                      options: phases
                        .filter(
                          (phase) => !phase.isOngoing && !phase.isCompleted
                        )
                        .map((p) => {
                          return {
                            id: p.id,
                            value: p.id,
                            render: (value) => (
                              <div
                                className="flex items-center -mt-0.5"
                                key={p.id + "phases"}
                                onClick={() =>
                                  setThreadChanges({
                                    ...threadChanges,
                                    phaseId: p.id.toString(),
                                    phaseName: p.phases,
                                  })
                                }
                              >
                                <PhaseIcon
                                  width={"16"}
                                  height={"16"}
                                  phaseId={p.id}
                                  icon
                                />

                                <p className="mon-400-14 pl-2 text-gray-900 capitalize ">
                                  {p.phases}
                                </p>
                              </div>
                            ),
                          };
                        }),
                    },
                  ]}
                  placeholder="Select phase"
                  value={
                    threadChanges.phaseId
                      ? threadChanges.phaseId.toString()
                      : null
                  }
                />
              </div>
              {/* </div> */}
            </div>

            <div className="flex items-center justify-end bottom-0 left-0 absolute shadow-upward w-full h-[72px] px-5 gap-x-4">
              <CustomButton
                text="Cancel"
                onClick={() => (showEdit ? setShowEdit(false) : onClose())}
                className="border border-gray-200 text-gray-700 bg-white mon-500-14"
                height="40px"
                width="81px"
              />
              <CustomButton
                text="Save"
                onClick={() => {
                  handleThreadChanges();
                  showEdit ? setShowEdit(false) : onClose();
                }}
                className="border border-gray-200 text-white bg-primary-600 mon-500-14"
                height="40px"
                width="81px"
              />
            </div>
          </div>
        )}

        {!showEdit && (
          <>
            <div className="flex flex-col items-center">
              <PhaseIcon
                width="128px"
                height="128px"
                phaseId={+editThreadDetails.phaseId}
                icon
              />
              <h4 className="text-center capitalize mon-500-18 mt-6 text-black">
                {editThreadDetails.threadName}
              </h4>
            </div>
            {currentTab !== "people" && (
              <div
                className="flex float-right mt-7 cursor-pointer"
                onClick={() => setShowEdit(true)}
              >
                <img src="/images/icons/edit-01.svg" className="w-5 h-5 ml-2" />
                <p className="mon-500-14 text-gray-500 pl-1.5">Edit</p>
              </div>
            )}
            <Tabs
              onTabClick={(key) => {
                setCurrentTab(key);
              }}
              activeKey={currentTab}
              defaultActiveKey={currentTab}
              className="overview-tab mt-6"
            >
              <TabPane
                tab={
                  <div className="flex">
                    <div
                      className={`${
                        currentTab === "details"
                          ? "font-medium text-xs text-primary-600"
                          : "text-gray-500 text-xs font-normal"
                      }`}
                      style={{ fontSize: "12px" }}
                    >
                      Details
                    </div>
                  </div>
                }
                key="details"
              >
                <div className="flex flex-col ml-3">
                  <div className="mt-6">
                    <p className="uppercase mon-500-10 text-gray-500">phase</p>
                    <div className="flex items-center pt-3">
                      <PhaseIcon
                        phaseId={+editThreadDetails.phaseId}
                        width="20px"
                        height="20px"
                        icon
                      />
                      <p className="capitalize mon-500-12 text-gray-700 pl-2">
                        {getPhaseNameById(+editThreadDetails.phaseId)}
                      </p>
                    </div>
                  </div>

                  <div className="mt-6">
                    <p className="uppercase mon-500-10 text-gray-500">
                      creator
                    </p>
                    <div className="flex items-center pt-3">
                      <CustomAvatar
                        title={editThreadDetails.firstname}
                        size={24}
                      />
                      <p className="capitalize mon-500-12 text-gray-700 pl-2">
                        {editThreadDetails.firstname}{" "}
                        {editThreadDetails.lastname}
                      </p>
                    </div>
                  </div>
                  <div className="mt-6">
                    <p className="uppercase mon-500-10 text-gray-500">
                      created on
                    </p>
                    <p className="mon-500-12 text-black mt-2">
                      {dayjs(editThreadDetails.createdAt)
                        .local()
                        .format("DD MMM YYYY , hh:mm a") || "N/A"}
                    </p>
                  </div>
                  <div
                    className="mt-6 mon-500-14 text-error-700 cursor-pointer"
                    onClick={() =>
                      dispatch(
                        updateConversationsMultiple([
                          { key: "leaveThread", value: true },
                          {
                            key: "leaveThreadDetails",
                            value: { threadId: editThreadDetails.threadId },
                          },
                        ])
                      )
                    }
                  >
                    Leave Thread
                  </div>
                </div>
              </TabPane>
              <TabPane
                tab={
                  <div className="flex">
                    <div
                      className={`${
                        currentTab === "people"
                          ? "font-medium text-sm text-primary-600"
                          : "text-gray-500 text-xs font-normal"
                      }`}
                      style={{ fontSize: "12px" }}
                    >
                      People
                    </div>
                  </div>
                }
                key="people"
              >
                <div>
                  {threadMembers.map((user) => (
                    <div
                      className="mt-6 ml-3 flex justify-between items-center hover:bg-gray-25"
                      key={user.userId}
                    >
                      <div className="flex items-center">
                        <CustomAvatar
                          size={24}
                          src={user.profilePicture}
                          title={user.firstname}
                        />
                        <p className="mon-400-12 text-gray-700 pl-2">
                          {user.firstname || "N/A"} {user.lastname}
                        </p>
                      </div>

                      <p className="mon-500-12 text-gray-500">
                        {user.projectRoleTitle}
                      </p>
                    </div>
                  ))}

                  <div className="flex justify-between items-center mt-8 cursor-pointer">
                    <div
                      className="flex"
                      onClick={() => setAddMorePeople(true)}
                    >
                      <img
                        src="/images/icons/add-user.svg"
                        className="w-5 h-5"
                      />
                      <p className="mon-500-14 text-primary-700 pl-2 ">
                        Add more people
                      </p>
                    </div>
                    {addMorePeople && (
                      <div onClick={() => setAddMorePeople(false)}>
                        <img
                          src="/images/icons/x-close.svg"
                          className="w-5 h-5"
                        />
                      </div>
                    )}
                  </div>
                  {addMorePeople && (
                    <div className="mt-9">
                      <CustomSearchWithDropDown
                        style={{
                          border: "1px solid #C0CBD7",
                          height: "42px",
                        }}
                        placeholder="Search team members"
                        options={userList
                          .filter(
                            (u) =>
                              selectedUsers?.indexOf(+u.userId) === -1 &&
                              u.userId !== user.id
                          )
                          .map((u) => {
                            return {
                              id: u.userId,
                              value: u.userId,
                              label: `${u.firstname} ${u.lastname}`,
                              render: (value) => (
                                <div
                                  className="flex items-center -mt-0.5"
                                  //key={u.userId}
                                  onClick={() => {
                                    // setSelectedUsers((oldList) => [
                                    //   ...oldList,
                                    //   u.userId,
                                    // ])
                                    dispatch(
                                      updateConversationsMultiple([
                                        { key: "threadModal", value: true },
                                        {
                                          key: "editThreadDetails",
                                          value: {
                                            ...editThreadDetails,
                                            userToBeAdded: u.userId,
                                          },
                                        },
                                        { key: "editThread", value: true },
                                        {
                                          key: "suggestedThread",
                                          value: false,
                                        },
                                        {},
                                      ])
                                    );
                                    onClose();
                                  }}
                                >
                                  <CustomAvatar
                                    title={u.firstname ? u.firstname : "User"}
                                    src={u.profilePicture}
                                  />

                                  <p className="ml-2 mon-400-12 text-gray-700 capitalize">
                                    {u.firstname} {u.lastname}
                                  </p>
                                </div>
                              ),
                            };
                          })}
                      />
                    </div>
                  )}
                </div>
              </TabPane>
            </Tabs>
          </>
        )}

        <LeaveThreadModal
          visible={leaveThread}
          onCancel={() =>
            dispatch(
              updateConversationsMultiple([
                { key: "leaveThread", value: false },
                { key: "leaveThreadDetails", value: { threadId: "" } },
              ])
            )
          }
        />
      </div>
    </Drawer>
  );
};

export default ThreadProfileDrawer;
