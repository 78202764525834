import { createAsyncThunk } from "@reduxjs/toolkit";
import { client, getError } from "../../utils/axiosClient";

export const userAuthenticate = createAsyncThunk(
  "auth/login",
  async (
    {
      email,
      password,
      isMobile,
    }: {
      email: string;
      password: string;
      isMobile: boolean;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await client.post("/auth/login", {
        email,
        password,
        isMobile,
      });

      localStorage.setItem("user", JSON.stringify(response.data));
      return response.data;
    } catch (error) {
      return rejectWithValue(getError(error));
      //return error;
    }
  }
);

export const updateUserProfile = createAsyncThunk(
  "auth/update-profile",
  async (
    payload: {
      firstname?: string;
      lastname?: string;
      mobile?: string;
      profilePicture?: string;
      company?: string;
      jobTitle?: string;
      location?: string;
      lat?: number;
      long?: number;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await client.put("/auth/update-user", payload);
      return response.data;
    } catch (error) {
      return rejectWithValue(getError(error));
      //return error;
    }
  }
);

export const userAuthenticateGoogle = createAsyncThunk(
  "auth/login-google",
  async (
    {
      email,
      accessToken,
      lastName,
      firstName,
      profilePicture,
    }: {
      email: string;
      accessToken: string;
      lastName: string;
      firstName: string;
      profilePicture: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await client.post("/auth/google-login", {
        email,
        isMobile: false,
        accessToken,
        lastName,
        firstName,
        profilePicture,
      });
      console.log(response.data);

      localStorage.setItem("user", JSON.stringify(response.data));
      return response.data;
    } catch (error) {
      return rejectWithValue(getError(error));
      //return error;
    }
  }
);
