import React, { useEffect } from "react";
import { ProductOnboardingLayout } from "../../../Core/Layout/OnboardingLayouts";
import CustomButton from "../../../Core/Common/CustomButton";
import { useAppDispatch, useAppSelector } from "../../../Core/redux/hooks";
import {
  changeStep,
  handleSelect,
} from "../../../Core/redux/slices/onboarding.slice";
import CustomSelect from "../../../Core/Common/CustomSelect";
import { getSDGs, getSectors } from "../../../Core/redux/api/onboardingAPI";
import CurrentStep from "../Project Onboarding/CurrentStep";
import { useFormik } from "formik";
import * as Yup from "yup";
import StepProgressBar from "./StepProgressBar";

const Content = () => {
  const dispatch = useAppDispatch();
  const { sectorList, selectedSector } = useAppSelector(
    (state) => state.onboarding
  );

  React.useEffect(() => {
    dispatch(getSectors());
    dispatch(getSDGs());
  }, []);

  const formik = useFormik({
    initialValues: {
      sectorList: [],
    },
    validationSchema: Yup.object({
      sectorList: Yup.array()
        .min(1, "Please select at least one sector")
        .required("Please select at least one sector")
        .typeError("Please select at least one sector"),
    }),
    onSubmit: (values) => {
      console.log(values);
      dispatch(changeStep(3));
    },
  });

  useEffect(() => {
    console.log(selectedSector);
    formik.setFieldValue("sectorList", selectedSector);
  }, [selectedSector]);

  const sectors = () => {
    // Return an empty array if sectorList is falsy
    if (!sectorList) return [];

    // Filter sectorList and return the result, or an empty array if selectedSector is falsy
    return selectedSector
      ? sectorList.filter((item) => selectedSector.includes(item?.id))
      : [];
  };

  return (
    <div className="p-6 w-full h-full bg-white">
      {/* <div className="progress-line">
        <div className="step top">
          <div className="number">1</div>
          <div className="box"></div>
        </div>
        <div className="step middle">
          <div className="number">2</div>
          <div className="box"></div>
        </div>
        <div className="step bottom">
          <div className="number">3</div>
          <div className="box"></div>
        </div>
      </div> */}

      <div className="pl-14 flex justify-start h-full items-center">
        <StepProgressBar
          currentStep={1}
          totalSteps={5}
          givenElementId="form1"
        />
        <form
          autoComplete="off"
          // style={{ width: 520 }}
          onSubmit={formik.handleSubmit}
          id="formik"
        >
          <div>
            <CurrentStep
              currentStep={1}
              totalStep={5}
              className=""
              id="form1"
            />
            <h1 className="mon-500-20 text-gray-900 mb-2 mt-6">
              How do you intend to make a difference in tackling{" "}
              <span className="mon-600-20 text-error-600">climate change?</span>
            </h1>
            <div className="flex justify-start items-center text-show-animation gap-x-2 mon-400-16 text-gray-500">
              <img
                src="/images/icons/frosty-icon.svg"
                className="min-h-[26px] min-w-[26px]"
                alt="frosty"
              />
              Let’s change the world together 🙌
            </div>
            <div className="flex justify-start items-center mb-6 gap-x-2">
              <div className="mon-500-14 text-gray-900">
                I’m <span className="text-success-600">fighting</span> climate
                change with
              </div>
              <div className=" w-[341px] relative">
                <CustomSelect
                  value={null}
                  border={true}
                  placeholder="Select your sector"
                  showBorderOnlyOnFocused
                  options={sectorList?.map((s) => {
                    return {
                      id: s.id,
                      value: s.id,
                      render: () => (
                        <div className="flex justify-between items-center">
                          <p>{s.value}</p>
                          {selectedSector?.includes(s.id) && (
                            <img src="/images/icons/check.svg" alt="" />
                          )}
                        </div>
                      ),
                    };
                  })}
                  onChange={async (e) => {
                    await dispatch(handleSelect(e));
                  }}
                  formikHook={formik}
                  name={"sectorList"}
                />
                <div className="absolute top-10 left-2.5 w-[320px]">
                  {sectors()?.map((s, i) => (
                    <div
                      className="mt-2 mon-500-14 text-gray-900 flex justify-between items-center w-full"
                      key={i}
                    >
                      <div>{s.value}</div>
                      <div
                        className="cursor-pointer"
                        onClick={() => dispatch(handleSelect(s.id))}
                      >
                        <img
                          src="/images/icons/sector-icons/input-cross.svg"
                          alt=""
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div
              style={{
                marginTop: `${sectors().length * 29}px`,
              }}
            >
              <CustomButton type="submit" text={`Continue`} width="92px" />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

const Sidebar = () => {
  const [hoverIndex, setHoverIndex] = React.useState(0);

  const { selectedSector } = useAppSelector((state) => state.onboarding);

  const onHover = (id) => {
    setHoverIndex(id);
  };

  const changeBgColor = () => {
    const finalEle = selectedSector ? selectedSector.length - 1 : 0;
    switch (selectedSector?.[finalEle]) {
      case 1:
        return "bg-opacity-10 bg-success-600";
      case 2:
        return "bg-opacity-10 bg-transport-orange";
      case 3:
        return "bg-opacity-10 bg-farming-green";
      case 4:
        return "bg-opacity-10 bg-energy-yellow";
      case 5:
        return "bg-opacity-10 bg-energy-yellow";
      case 6:
        return "bg-opacity-10 bg-education-red";
      case 7:
        return "bg-opacity-10 bg-building-yellow";
      case 8:
        return "bg-opacity-10 bg-ecommerce-red";
      case 9:
        return "bg-opacity-10 bg-banking-red";
      case 10:
        return "bg-opacity-10 bg-primary-600";
      default:
        return "bg-gray-50";
    }
  };
  return (
    <div
      className={`w-full h-screen flex justify-center items-center ${changeBgColor()} `}
    >
      <div className="hexagon-wrapper mt-24">
        <div className="hexagon-list clearfix">
          <div className="placeholder-hexagon hexagon"></div>
          <div
            onMouseEnter={() => onHover(1)}
            onMouseLeave={() => onHover(null)}
            className="hexagon"
          >
            {selectedSector?.includes(1) ? (
              <img
                src={`/images/icons/sector-icons/healthcare-selected.svg`}
                alt=""
                className="object-fit"
              />
            ) : (
              <img
                src={`/images/icons/sector-icons/${
                  hoverIndex === 1
                    ? "healthcare-hover"
                    : "healthcare-unselected"
                }.svg`}
                alt=""
                className="object-fit"
              />
            )}
          </div>
        </div>
        <div className="hexagon-list relative -top-6 clearfix">
          <div
            onMouseEnter={() => onHover(2)}
            onMouseLeave={() => onHover(null)}
            className="hexagon"
          >
            {selectedSector?.includes(2) ? (
              <img
                src={`/images/icons/sector-icons/transportation-selected.svg`}
                alt=""
                className="object-fit"
              />
            ) : (
              <img
                src={`/images/icons/sector-icons/${
                  hoverIndex === 2
                    ? "transportation-hover"
                    : "transportation-unselected"
                }.svg`}
                alt=""
                className="object-fit"
              />
            )}
          </div>
          <div
            onMouseEnter={() => onHover(3)}
            onMouseLeave={() => onHover(null)}
            className="hexagon"
          >
            {selectedSector?.includes(3) ? (
              <img
                src={`/images/icons/sector-icons/farming-selected.svg`}
                alt=""
                className="object-fit"
              />
            ) : (
              <img
                src={`/images/icons/sector-icons/${
                  hoverIndex === 3 ? "farming-hover" : "farming-unselected"
                }.svg`}
                alt=""
                className="object-fit"
              />
            )}
          </div>
        </div>
        <div className="hexagon-list relative -top-12 clearfix">
          <div className="placeholder-hexagon hexagon"></div>
          <div
            onMouseEnter={() => onHover(4)}
            onMouseLeave={() => onHover(null)}
            className="hexagon"
          >
            {selectedSector?.includes(4) ? (
              <img
                src={`/images/icons/sector-icons/energy-selected.svg`}
                alt=""
                className="object-fit"
              />
            ) : (
              <img
                src={`/images/icons/sector-icons/${
                  hoverIndex === 4 ? "energy-hover" : "energy-unselected"
                }.svg`}
                alt=""
                className="object-fit"
              />
            )}
          </div>
          <div
            onMouseEnter={() => onHover(5)}
            onMouseLeave={() => onHover(null)}
            className="hexagon"
          >
            {selectedSector?.includes(5) ? (
              <img
                src={`/images/icons/sector-icons/technology-selected.svg`}
                alt=""
                className="object-fit"
              />
            ) : (
              <img
                src={`/images/icons/sector-icons/${
                  hoverIndex === 5
                    ? "technology-hover"
                    : "technology-unselected"
                }.svg`}
                alt=""
                className="object-fit"
              />
            )}
          </div>
          <div
            onMouseEnter={() => onHover(6)}
            onMouseLeave={() => onHover(null)}
            className="hexagon"
          >
            {selectedSector?.includes(6) ? (
              <img
                src={`/images/icons/sector-icons/education-selected.svg`}
                alt=""
                className="object-fit"
              />
            ) : (
              <img
                src={`/images/icons/sector-icons/${
                  hoverIndex === 6 ? "education-hover" : "education-unselected"
                }.svg`}
                alt=""
                className="object-fit"
              />
            )}
          </div>
        </div>
        <div className="hexagon-list relative -top-[72px] clearfix">
          <div
            onMouseEnter={() => onHover(7)}
            onMouseLeave={() => onHover(null)}
            className="hexagon"
          >
            {selectedSector?.includes(7) ? (
              <img
                src={`/images/icons/sector-icons/building-selected.svg`}
                alt=""
                className="object-fit"
              />
            ) : (
              <img
                src={`/images/icons/sector-icons/${
                  hoverIndex === 7 ? "building-hover" : "building-unselected"
                }.svg`}
                alt=""
                className="object-fit"
              />
            )}
          </div>
          <div
            onMouseEnter={() => onHover(8)}
            onMouseLeave={() => onHover(null)}
            className="hexagon"
          >
            {selectedSector?.includes(8) ? (
              <img
                src={`/images/icons/sector-icons/ecommerce-selected.svg`}
                alt=""
                className="object-fit"
              />
            ) : (
              <img
                src={`/images/icons/sector-icons/${
                  hoverIndex === 8 ? "ecommerce-hover" : "ecommerce-unselected"
                }.svg`}
                alt=""
                className="object-fit"
              />
            )}
          </div>
          <div
            onMouseEnter={() => onHover(9)}
            onMouseLeave={() => onHover(null)}
            className="hexagon"
          >
            {selectedSector?.includes(9) ? (
              <img
                src={`/images/icons/sector-icons/banking-selected.svg`}
                alt=""
                className="object-fit"
              />
            ) : (
              <img
                src={`/images/icons/sector-icons/${
                  hoverIndex === 9 ? "banking-hover" : "banking-unselected"
                }.svg`}
                alt=""
                className="object-fit"
              />
            )}
          </div>
          {/* <div className="hexagon">
            <div className="hexagon-inner">CSS</div>
          </div> */}
        </div>
        <div className="hexagon-list relative -top-24 clearfix">
          <div className="placeholder-hexagon hexagon"></div>
          <div className="placeholder-hexagon hexagon"></div>
          <div
            onMouseEnter={() => onHover(10)}
            onMouseLeave={() => onHover(null)}
            className="hexagon"
          >
            {selectedSector?.includes(10) ? (
              <img
                src={`/images/icons/sector-icons/other-selected.svg`}
                alt=""
                className="object-fit"
              />
            ) : (
              <img
                src={`/images/icons/sector-icons/${
                  hoverIndex === 10 ? "others-hover" : "others-unselected"
                }.svg`}
                alt=""
                className="object-fit"
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const SectorSelection = () => {
  return (
    <ProductOnboardingLayout
      biggerSiderbar={true}
      content={<Content />}
      sidebar={<Sidebar />}
    />
  );
};

export default SectorSelection;
