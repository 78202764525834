import { Dropdown, Input, Menu } from "antd";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import CustomAvatar from "../../../Core/Common/CustomAvatar";
import CustomBadge from "../../../Core/Common/CustomBadge";
import CustomDragAndDrop from "../../../Core/Common/CustomDragAndDrop";
import CustomFrostyMessage from "../../../Core/Common/CustomFrostyMessage";
import CustomImageWithFallBack from "../../../Core/Common/CustomImageWithFallback";
import CustomInputWithSaveAndEdit from "../../../Core/Common/CustomInputWithSaveAndEdit";
import CustomSelect from "../../../Core/Common/CustomSelect";
import { CustomSelectWithInlineSearch } from "../../../Core/Common/CustomSelectWithSearch";
import { getMetaCategories } from "../../../Core/redux/api/settingsAPI";
import { useAppDispatch, useAppSelector } from "../../../Core/redux/hooks";
import { updatePreviewMultiple } from "../../../Core/redux/slices/preview.slice";
import { resetSchedule } from "../../../Core/redux/slices/schedule.slice";
import { downloadFiles } from "../../../Core/utils";
import {
  getColor,
  getHexColor,
  rgbToHexString,
} from "../../../Core/utils/colorConverter";

export const RowLayout = ({ customLeft, children, className }) => {
  return (
    <div className={`${className} flex items-center`}>
      <div className="min-w-[200px] max-w-[200px]">{customLeft}</div>
      <div>{children}</div>
    </div>
  );
};

export const CustomLeft = ({ children = <></>, text, icon }) => {
  return (
    <div className="flex items-center">
      <img src={icon} className="w-4 h-4" />
      <p className="mon-400-12 text-gray-500 pl-2">{text}</p>
      {children}
    </div>
  );
};

const InfoModal = ({ frostyMessage }: { frostyMessage?: React.ReactNode }) => {
  const [isOpen, setIsOpen] = useState(false);
  const onClose = () => setIsOpen(false);
  return (
    <>
      <img
        src="/images/icons/info-circle.svg"
        className="w-4 h-4 ml-2 cursor-pointer"
        onClick={() => setIsOpen(!isOpen)}
      />
      {isOpen && (
        <div className="w-[275px] h-[233[px]  bg-white z-20 absolute ml-[125px] rounded-2xl shadow-lg">
          <div className="flex items-center justify-between border-b-[1px] px-2 py-2 w-full border-gray-200">
            <p className="mon-500-16 pl-4 text-gray-900">Info</p>
            <button onClick={onClose}>
              <img src="/images/icons/x-close.svg" className="ml-[170px]" />
            </button>
            <img src="" />
          </div>
          <div className={`mt-3 mb-3 mx-3 ${frostyMessage && "pt-3.5 pb-0.5"}`}>
            <CustomFrostyMessage close={false} shadow={false}>
              {frostyMessage ? (
                frostyMessage
              ) : (
                <p className="mon-600-12 text-primary-600">
                  Use the format: 0w 0d 00h 00m
                </p>
              )}
            </CustomFrostyMessage>
            {!frostyMessage && (
              <div className="ml-12">
                <p className="mon-500-12 text-primary-600">w = weeks</p>
                <p className="mon-500-12 pt-2 text-primary-600">d = days</p>
                <p className="mon-500-12 pt-2 text-primary-600">h = hours</p>
                <p className="mon-500-12 pt-2 text-primary-600">m = minutes</p>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export const TaskInput = ({ value, onChange }) => {
  return (
    <CustomInputWithSaveAndEdit
      width="98%"
      value={value}
      inputClassName="mon-600-18 text-gray-900"
      placeholder="New Task"
      onChange={(e) => onChange(e)}
    />
  );
};

export const UserDropdown = ({
  fieldName,
  task,
  setTask,
  userList,
  onEdit,
}) => {
  const [search, setSearch] = useState("");

  const handleMenuItemClick = (user) => {
    setSearch("");
    if (fieldName === "Assignee") {
      setTask({
        ...task,
        assigneeId: user.userId,
        assignee: `${user.firstname} ${user.lastname}`,
        assigneeProfilePicture: user.profilePicture,
      });
    } else {
      setTask({
        ...task,
        reporterId: user.userId,
        reporter: `${user.firstname} ${user.lastname}`,
        reporterProfilePicture: user.profilePicture,
      });
    }
    onEdit(user.userId);
  };

  const renderMenuItem = (user) => (
    <Menu.Item
      className="py-2.5 px-4 hover:bg-gray-500 rounded-t-lg"
      key={user.userId}
      onClick={() => {
        handleMenuItemClick(user);
      }}
    >
      <div className="flex items-center gap-x-2">
        <CustomAvatar
          title={user.firstname || "User"}
          size={24}
          src={user.profilePicture}
        />
        <span className="mon-400-12 whitespace-nowrap text-gray-700 capitalize">
          {user.firstname === null || user.lastname === null
            ? "User"
            : `${user.firstname} ${user.lastname}`}
        </span>
      </div>
    </Menu.Item>
  );

  return (
    <Dropdown
      className=""
      destroyPopupOnHide
      overlay={
        <Menu className="w-[200px] rounded-lg custom-menu-dropdown max-h-[40vh] overflow-y-auto shadow-dropdown">
          <div className="sticky top-0 z-10  bg-gray-50">
            <Input
              className="w-full rounded-lg bg-white focus:outline-none mon-500-12  h-10 "
              prefix={<img src="/images/icons/search.svg" />}
              bordered={false}
              value={search}
              //onChange={handleSearch}
              onChange={(e) => setSearch(e.target.value)}
              placeholder="Search User"
              autoFocus
            />
          </div>
          {userList
            .filter(
              (user) =>
                user.firstName?.toLowerCase().includes(search.toLowerCase()) ||
                user.lastName?.toLowerCase().includes(search.toLowerCase()) ||
                user.email?.toLowerCase().includes(search.toLowerCase())
            )
            .map((user) => renderMenuItem(user))}
        </Menu>
      }
      trigger={["click"]}
    >
      <div className="bg-white text-gray-700 cursor-pointer flex justify-center items-center mon-500-14 rounded-xl">
        <div className="flex justify-center items-center gap-x-2">
          {fieldName === "Assignee" ? (
            <>
              <CustomAvatar
                title={task.assignee}
                size={24}
                src={task.assigneeProfilePicture}
              />
              <p className="mon-400-12 whitespace-nowrap capitalize">
                {task.assignee === "Null Null" ? "User" : task.assignee}
              </p>
            </>
          ) : (
            <>
              <CustomAvatar
                title={task.reporter}
                size={24}
                src={task.reporterProfilePicture}
              />
              <p className="mon-400-12 whitespace-nowrap capitalize">
                {task.reporter}
              </p>
            </>
          )}
        </div>
      </div>
    </Dropdown>
  );
};

export const ComponentDropdown = ({
  task,
  setTask,
  currentDropDown,
  setCurrentDropDown,
  onEdit,
}) => {
  const { phases, currentSelectedPhase } = useAppSelector(
    (state) => state.schedule
  );

  let components = [];

  if (Array.isArray(phases) && phases.length > 0) {
    const phase = phases.find(
      (p) => p.id === task.phaseId || p.id === currentSelectedPhase.id
    );
    if (phase && phase.components) {
      components = [...JSON.parse(phase.components)];
    }
  }

  console.log(task, "nexcdcww");
  return (
    <div className="min-h-[44px] items-center flex">
      {currentDropDown === "addComponent" ? (
        <CustomSelectWithInlineSearch
          containerStyle={{
            width: "200px",
          }}
          margin=""
          options={components.map(({ id, title, content }) => {
            return {
              id,
              title,
              options: content.map((item) => {
                return {
                  id: item.id,
                  title: item.heading,
                  render: (value) => (
                    <div
                      className="flex items-center -mt-0.5"
                      key={item.id}
                      onClick={() => {
                        setTask({
                          ...task,
                          componentId: item.id,
                          componentName: item.heading,
                        });
                        onEdit({
                          componentId: item.id,
                          componentName: item.heading,
                        });
                      }}
                    >
                      <p className="mon-400-14 pl-2 text-gray-900 capitalize ">
                        {item.heading}
                      </p>
                    </div>
                  ),
                };
              }),
            };
          })}
          placeholder="Select component"
          value={task.componentName}
          onDropdownVisibleChange={() => setCurrentDropDown("")}
        />
      ) : (
        <div onClick={() => setCurrentDropDown("addComponent")}>
          {task.componentName === "" ||
          task.componentName === null ||
          !task.componentName ? (
            <div className="flex items-center gap-x-2 cursor-pointer">
              <p className="mon-400-12 text-gray-700 whitespace-nowrap">
                Select component
              </p>
              <img
                src="/images/icons/chevron-down-gray.svg"
                className="w-3 h-3"
              />
            </div>
          ) : (
            <div className="cursor-pointer">
              <CustomBadge
                color={getHexColor(task.componentName)}
                //bgColor={getColor(0.1, task.componentName).light}
                text={task.componentName}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export const DependencyDropDown = ({
  currentDropDown,
  task,
  setTask,
  setCurrentDropDown,
  onEdit,
}) => {
  const { goals } = useAppSelector((state) => state.schedule);
  const bugs = goals?.filter(({ goalType }) => +goalType === 3);
  const currentDependency = bugs?.find((bug) => bug.id == task.dependencyId);

  return (
    <div className="min-h-[44px] max-h-[44px] items-center flex">
      {currentDropDown === "dependency" ? (
        <div className=" w-[150px]">
          <CustomSelect
            defaultOpen
            border
            showBorderOnlyOnFocused
            options={bugs?.map((b) => {
              return {
                id: b.id,
                value: b.id,
                render: () => (
                  <div
                    className="flex items-center gap-x-2"
                    onClick={(e) => {
                      setTask({
                        ...task,
                        dependencyId: b.id,
                        dependencyName: b.name,
                      });
                      onEdit({
                        dependencyId: b.id,
                      });
                    }}
                  >
                    <img src="/images/icons/bug.svg" className="w-4 h-4" />
                    <p className="mon-400-12 text-gray-700">{b.name}</p>
                  </div>
                ),
              };
            })}
            value={task.dependencyId || null}
            placeholder="Select dependency"
            onDropdownVisibleChange={(open) => !open && setCurrentDropDown("")}
          />
        </div>
      ) : (
        <div className="" onClick={() => setCurrentDropDown("dependency")}>
          {!task.dependencyId ||
          task.dependencyId == 1 ||
          task.dependencyId === null ? (
            <div className="flex items-center gap-x-2 cursor-pointer">
              <p className="mon-400-12 pl-0.5 text-gray-700">Add dependency</p>
              <img
                src="/images/icons/chevron-down-gray.svg"
                className="w-3 h-3"
              />
            </div>
          ) : (
            <div className="flex justify-center items-center cursor-pointer">
              <img src="/images/icons/bug.svg" className="w-4 h-4" />
              <p className="mon-400-12 ml-2 whitespace-nowrap text-gray-700">
                {currentDependency?.name || "N/A"}
              </p>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export const CategoryDropDown = ({
  currentDropDown,
  task,
  setTask,
  setCurrentDropDown,
  goalType,
  onEdit,
}) => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (goalType === 1) {
      dispatch(getMetaCategories({ metaId: 1 }));
    } else {
      dispatch(getMetaCategories({ metaId: 2 }));
    }
  }, [goalType]);

  const { categoryList } = useAppSelector((state) => state.settings);

  const category = categoryList.find((c) => c.id == task.categoryId);

  return (
    <div className="min-h-[44px] max-h-[44px] items-center flex">
      {currentDropDown === "category" ? (
        <div className="w-[250px]">
          <CustomSelect
            defaultOpen
            border
            showBorderOnlyOnFocused
            options={
              categoryList.length === 0
                ? [
                    {
                      id: 1,
                      render: () => (
                        <p className="mon-400-12 text-gray-900">
                          Add category from project settings
                        </p>
                      ),
                    },
                  ]
                : categoryList.map((s) => {
                    return {
                      id: s.id,
                      value: s.id,
                      render: () => (
                        <p
                          onClick={(e) => {
                            setTask({
                              ...task,
                              categoryId: s.id,
                              categoryName: s.categoryName,
                              categoryColor: s.color,
                            });
                            onEdit(s.id);
                          }}
                        >
                          {" "}
                          {s.categoryName}
                        </p>
                      ),
                    };
                  })
            }
            value={task.categoryId || null}
            // onChange={(e) => {
            //   dispatch(
            //     updateSchedule({
            //       key: "selectedSprint",
            //       value: sprints.find((s) => s.id === e),
            //     })
            //   );
            // }}

            placeholder="Select category"
            onDropdownVisibleChange={(open) => !open && setCurrentDropDown("")}
          />
        </div>
      ) : (
        <div className="" onClick={() => setCurrentDropDown("category")}>
          {!task.categoryId || task.categoryId === "" || !category ? (
            <div className="flex items-center gap-x-2 cursor-pointer">
              <p className="mon-400-12 pl-0.5 text-gray-700">Select category</p>
              <img
                src="/images/icons/chevron-down-gray.svg"
                className="w-3 h-3"
              />
            </div>
          ) : (
            <div className="cursor-pointer">
              <CustomBadge
                color={category?.color}
                text={category?.categoryName}
                className="mt-2"
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export const StatusDropdown = ({ statusId, setStatusId, task }) => {
  const { swimlanes } = useAppSelector((state) => state.schedule);
  const [swimlaneIdState, setSwimlaneIdState] = useState(task.swimlaneId);
  const handleStatusChange = (newStatusId, swimlaneId) => {
    console.log(newStatusId, swimlaneId, " handle change console");
    //const swimlaneId = swimlanes.find((s) => s.id == newStatusId)?.id;
    setSwimlaneIdState(swimlaneId);
    setStatusId({ statusId: newStatusId, swimlaneId });
  };
  // const statusOptions = {
  //   1: "To Do",
  //   2: "In Progress",
  //   3: "Done",
  // };

  const statusStyles = {
    1: "bg-gray-100",
    2: "bg-primary-600",
    3: "bg-success-600",
  };

  const statusTextStyles = {
    1: "text-gray-700",
    2: "text-white",
    3: "text-white",
  };

  console.log(
    swimlaneIdState,
    swimlanes.find((s) => s.id == swimlaneIdState)?.title,
    "swinlaneId"
  );

  return (
    <Dropdown
      getPopupContainer={(trigger) => trigger.parentElement}
      className="mt-1"
      overlay={
        <Menu className="  rounded-lg  pt-2">
          {swimlanes.map((s) => (
            <Menu.Item
              key={s.id}
              className=" hover:bg-[#ffff]"
              onClick={() => handleStatusChange(s.statusId, s.id)}
            >
              <div
                className={`flex items-center max-w-max ${
                  statusStyles[s.statusId]
                } rounded-2xl`}
                onClick={() => handleStatusChange(s.statusId, s.id)}
              >
                <p
                  className={`mon-500-12 ${
                    statusTextStyles[s.statusId]
                  } px-2 uppercase py-0.5`}
                >
                  {s.title}
                </p>
              </div>
            </Menu.Item>
          ))}
        </Menu>
      }
      trigger={["click"]}
    >
      <div className="">
        <div
          className={`flex justify-between items-center cursor-pointer rounded-2xl ${statusStyles[statusId]}`}
          style={{ height: "22px" }}
        >
          <p
            className={`pl-2 uppercase whitespace-nowrap mon-500-12 ${statusTextStyles[statusId]}`}
          >
            {swimlaneIdState
              ? swimlanes.find((s) => s.id == swimlaneIdState)?.title
              : "To Do"}
          </p>
          <img
            src="/images/icons/chevron-down-gray.svg"
            className="w-4 h-4 ml-2 mr-2"
          />
        </div>
      </div>
    </Dropdown>
  );
};

export default InfoModal;

const AttacmentsCard = ({ file, onClick }) => {
  console.log(file, "AttacmentsCard");

  return (
    <div onClick={onClick} className="cursor-pointer">
      <div className="h-[100px] w-[100px] group cursor-pointer rounded-lg relative flex justify-center actions-row items-center">
        <CustomImageWithFallBack
          className="h-[95px]"
          fallBackImage={file?.thumbnail}
          src={file?.url}
        />
        <div
          className="top-0 absolute hidden group-hover:block transform duration-150 transition-all hover: left-0 bottom-0 right-0 rounded-lg"
          style={{
            background: `linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2))`,
          }}
        />
        <div className="actions">
          <div
            onClick={(e) => {
              e.stopPropagation();
              downloadFiles([file?.url]);
            }}
            className="bg-white  absolute top-2 flex justify-center items-center cursor-pointer right-2 h-[35px] w-[35px] rounded-lg "
          >
            <img src="/images/icons/download-icon.svg" />
          </div>
        </div>
      </div>

      <p className="mon-500-12 mt-2 text-center max-w-[100px] truncate">
        {file?.name}
      </p>
    </div>
  );
};

export const UploadAndAttachments = ({
  task,
  setTask,
  onEdit,
  isEdit,
  onAttachmentClick,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useAppDispatch();

  const addFilePreview = () => {
    const updatedSearchParams = new URLSearchParams(searchParams.toString());
    updatedSearchParams.set("filePreview", "true");
    setSearchParams(updatedSearchParams.toString());
  };
  const handelCardClick = (currentIndex) => {
    dispatch(
      updatePreviewMultiple([
        {
          key: "docs",
          value: task.attachments,
        },
        {
          key: "visible",
          value: true,
        },
        {
          key: "from",
          value: "conversation",
        },
        {
          key: "currentIndex",
          value: currentIndex,
        },
      ])
    );
    // dispatch(resetSchedule(["newTaskModal"]));
    addFilePreview();
    onAttachmentClick();
  };

  return (
    <div className="mb-4">
      <p className="mon-500-14 text-gray-700 pb-2">Attachment</p>
      <div className="my-2 flex gap-6 flex-wrap">
        {task.attachments.map((file, i) => {
          return (
            <AttacmentsCard
              file={file}
              key={i}
              onClick={() => handelCardClick(i)}
            />
          );
        })}{" "}
      </div>

      <CustomDragAndDrop
        onUploaded={(e) => {
          setTask((task) => {
            return { ...task, attachments: e };
          });

          if (isEdit) {
            onEdit(e);
          }
        }}
        defaultFileList={task.attachments}
      />
    </div>
  );
};

export const EnvironmentInput = ({ value = "", onChange, className, onOk }) => {
  const [environmentInput, SetEnvironmentInput] = useState(value);
  const [isEditing, setIsEditing] = useState(false);

  console.log(value, "xyyyyy");

  const handleChange = (event) => {
    const value = event.target.value;

    onChange(value);
    SetEnvironmentInput(value);
  };

  useEffect(() => {
    console.log("Estimate:", environmentInput);
    onChange(environmentInput);
  }, [environmentInput]);

  if (isEditing) {
    return (
      <div
        className={`w-[218px] min-w-[216px] shadow-input-focus bg-white rounded-xl border-[1px] border-[#7DC7FF] ${className}`}
      >
        <div className="flex justify-center items-center">
          <input
            className="outline-none bg-transparent w-full h-full px-2 py-2.5"
            value={environmentInput}
            onChange={handleChange}
            placeholder="Add environemnt"
          />

          <div
            className="cursor-pointer "
            onClick={() => {
              setIsEditing(false);
              onOk();
            }}
          >
            <img
              src="/images/icons/input_save.svg"
              className="h-5 w-5"
              alt=""
            />
          </div>
          <div
            className="cursor-pointer mx-2 ml-1"
            onClick={() => {
              SetEnvironmentInput("");
              setIsEditing(false);
            }}
          >
            <img
              src="/images/icons/sector-icons/input-cross.svg"
              className="h-5 w-5"
              alt=""
            />
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="min-h-[39px] max-h-[39px] items-center flex">
      <div
        className="h-6 bg-gray-50 rounded-2xl px-2 whitespace-nowrap text-gray-700 mon-400-12 py-0.5 cursor-pointer"
        onClick={() => setIsEditing(true)}
      >
        {value || "Add environment"}
      </div>
    </div>
  );
};
