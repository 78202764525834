import { Divider, Input, Tooltip } from "antd";
import dayjs from "dayjs";
import { title } from "process";
import React, { useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import CustomButton from "../../../Core/Common/CustomButton";
import CustomInputWithEdit from "../../../Core/Common/CustomInputWithEdit";
import CustomInputWithSaveAndEdit from "../../../Core/Common/CustomInputWithSaveAndEdit";
import CustomModal from "../../../Core/Common/CustomModal";
import DatePicker from "../../../Core/Dayjs/DatePicker";
import {
  createSprint,
  getSprintsByPhase,
} from "../../../Core/redux/api/scheduleAPI";
import { useAppDispatch, useAppSelector } from "../../../Core/redux/hooks";
import {
  resetSchedule,
  updateSchedule,
} from "../../../Core/redux/slices/schedule.slice";
import PhaseIcon from "../../../Core/svg/PhaseIcon";
import TaskTypeIcon from "../../../Core/svg/TaskTypeIcon";

type NewSprintModalProps = {
  visible: boolean;
  onCancel: () => void;
};

const NewSprintModal = ({ visible, onCancel }: NewSprintModalProps) => {
  const [sprints, setSprints] = useState([
    {
      id: 0,
      title: "",
      startDate: "",
      endDate: "",
    },
  ]);

  const { newSprintModal, currentSelectedPhase, phases } = useAppSelector(
    (state) => state.schedule
  );

  const dispatch = useAppDispatch();
  const { projectId } = useParams();
  const { user } = useAppSelector((state) => state.userDetails);
  const [searchParams, setSearchParams] = useSearchParams();
  const handleChange = ({ id, key, value }) => {
    const newSprints = [...sprints];

    newSprints.map((s) => {
      if (s.id === id) {
        s[key] = value;
        return s;
      }
      return s;
    });

    setSprints([...newSprints]);
  };

  return (
    <CustomModal
      title={
        <div className="flex items-center">
          {newSprintModal.isNewScope && (
            <div className="flex items-center">
              <p className="mon-400-12 text-gray-500">
                {currentSelectedPhase.phases}
              </p>
              <img
                src="/images/icons/chevron-right.svg"
                className="w-4 h-4 ml-3"
              />
            </div>
          )}
          <p
            className={`flex mon-500-12 text-gray-700 ${
              newSprintModal.isNewScope ? "ml-3" : ""
            }`}
          >
            New Sprint
          </p>
          {newSprintModal.isNewScope && (
            <div className="ml-3 flex items-center bg-pink-25 h-[22px] rounded-xl gap-x-2 px-2">
              <TaskTypeIcon type="newscope" width="12px" height="12px" />
              <p className="mon-500-12 text-pink-700">New Scope</p>
            </div>
          )}
        </div>
      }
      width="524px"
      visible={visible}
      height="479px"
      body={
        <div className="p-4 h-[380px] overflow-y-scroll">
          {sprints.map((sprint, index) => (
            <div key={index} className="pt-2">
              {/* <CustomInputWithEdit
                value={sprint.title}
                inputClassName="mon-600-20 text-black"
                containerClassName="border-0 hover:border-2 hover:rounded-xl active:border-2 active:rounded-xl"
                placeholder="Sprint name"
                onChange={(e) =>
                  handleChange({
                    id: sprint.id,
                    key: "title",
                    value: e.target.value,
                  })
                }
              /> */}
              <CustomInputWithSaveAndEdit
                value={sprint.title}
                inputClassName="mon-600-18 text-gray-900"
                // containerClassName="border-0 hover:border-2 hover:rounded-xl active:border-2 active:rounded-xl"
                placeholder="Sprint name"
                onChange={(e) =>
                  handleChange({
                    id: sprint.id,
                    key: "title",
                    value: e.target.value,
                  })
                }
              />
              {newSprintModal.isNewScope && (
                <>
                  <div className="mon-500-14 text-gray-700 pb-1.5 mt-6">
                    Scope description
                  </div>
                  <Input.TextArea
                    rows={4}
                    disabled
                    value={newSprintModal.scopeDescription}
                    required={true}
                    className="w-full h-full mt-3 rounded-xl border border-gray-300 text-gray-700 pl-4 pt-2 active:outline-none"
                  ></Input.TextArea>
                </>
              )}
              <div className="mt-6 mx-1">
                <div className="mon-500-14 text-gray-700 pb-1.5">
                  Start date
                </div>
                <DatePicker
                  placeholder="dd/mm/yyyy"
                  className="w-full h-10 bg-transparent border-gray-300 rounded-xl shadow-xs"
                  suffixIcon={<></>}
                  format="DD MMM YYYY"
                  value={sprint.startDate as any}
                  disabledDate={(current) =>
                    ((current && current > dayjs(sprint.endDate)) || "") as any
                  }
                  onChange={(e) =>
                    handleChange({
                      id: sprint.id,
                      key: "startDate",
                      value: e,
                    })
                  }
                />
              </div>
              <div
                className=" mt-6 mx-1"
                style={{
                  paddingBottom: index + 1 === sprints.length && "20px",
                }}
              >
                <div className="mon-500-14 text-gray-700 pb-1.5">End date</div>
                <DatePicker
                  placeholder="dd/mm/yyyy"
                  className="w-full h-10 bg-transparent border-gray-300 rounded-xl shadow-xs"
                  suffixIcon={<></>}
                  format="DD MMM YYYY"
                  value={sprint.endDate as any}
                  onChange={(e) =>
                    handleChange({
                      id: sprint.id,
                      key: "endDate",
                      value: e,
                    })
                  }
                  disabledDate={(current) =>
                    ((current && current < dayjs(sprint.startDate)) ||
                      "") as any
                  }
                />

                <p className="mon-400-12 text-gray-500 mt-6">Ongoing phases</p>
                <div>
                  {phases
                    .filter(({ isOngoing }) => isOngoing === 1)
                    .map((item, index) => (
                      <div
                        className={`flex items-center gap-x-2 pt-1.5 ${
                          index === 0 && "mt-3"
                        }`}
                        key={item.id}
                      >
                        <Tooltip title={item.phases} placement="bottom">
                          <div>
                            <PhaseIcon
                              phaseId={+item.phaseId}
                              height="10px"
                              width="10px"
                            />
                          </div>
                        </Tooltip>
                        <p className="mon-500-12 text-gray-900 ml-2 capitalize pt-0.5">
                          {item.phases}
                        </p>
                        <p className="mon-400-10 text-gray-700 pt-0.5">
                          {dayjs(item.startDate).format("DD MMM")} -{" "}
                          {dayjs(item.endDate).format("DD MMM")}
                        </p>
                      </div>
                    ))}
                </div>
              </div>
              {index + 1 !== sprints.length && <Divider />}
            </div>
          ))}
        </div>
      }
      footer={
        <div className="flex justify-between py-4 gap-x-3 px-3 shadow-upward ">
          <CustomButton
            text="Add another sprint"
            onClick={() =>
              setSprints([
                ...sprints,
                {
                  id: sprints.length,
                  title: "",
                  startDate: "",
                  endDate: "",
                },
              ])
            }
            className="border border-gray-300 text-gray-700 bg-white mon-500-14 shadow-xs"
            height="40px"
            width="195px"
            icon="/images/icons/plus-gray.svg"
          />
          <div className="flex gap-x-3">
            <CustomButton
              text="Cancel"
              onClick={() => {
                {
                  newSprintModal.isNewScope &&
                    dispatch(
                      updateSchedule({ key: "newScopeModal", value: true })
                    );
                }
                onCancel();
                dispatch(resetSchedule(["newSprintModal"]));
              }}
              className="border border-gray-300 text-gray-700 bg-white mon-500-14 cursor-pointer"
              height="40px"
              width="81px"
            />
            <CustomButton
              text={sprints.length > 1 ? `Create ${sprints.length}` : "Create"}
              onClick={async () => {
                try {
                  const { payload } = await dispatch(
                    createSprint({
                      sprints: sprints.map((s) => {
                        return {
                          title: s.title,
                          startDate: dayjs(s.startDate).format("YYYY-MM-DD"),
                          endDate: dayjs(s.endDate).format("YYYY-MM-DD"),
                        };
                      }),
                      projectId: projectId,
                      phaseId: currentSelectedPhase.id,
                      isActive: 1,
                      isOngoing: 0,
                      isNewSprint: Number(newSprintModal.isNewScope),
                      senderId: user.id,
                    })
                  );

                  await dispatch(
                    getSprintsByPhase({
                      projectId,
                      phaseId: currentSelectedPhase.id,
                    })
                  );

                  onCancel();
                  dispatch(
                    updateSchedule({
                      key: "newSprint",
                      value: { ...sprints, projectId: projectId },
                    })
                  );
                  dispatch(
                    updateSchedule({
                      key: "createdSprintId",
                      value: payload.createdSprint[0].id,
                    })
                  );
                  const updatedSearchParams = new URLSearchParams(
                    searchParams.toString()
                  );
                  updatedSearchParams.set(
                    "selectedSprint",
                    payload.createdSprint[0].id
                  );
                  setSearchParams(updatedSearchParams.toString());
                  dispatch(resetSchedule("newSprintModal"));
                } catch (error) {
                  console.error(error);
                }
              }}
              className="mon-500-14 text-white bg-primary-600 cursor-pointer disabled:opacity-30 disabled:cursor-default"
              height="40px"
              width="85px"
              disabled={
                sprints[0].title === "" ||
                sprints[0].startDate === "" ||
                sprints[0].endDate === ""
              }
            />
          </div>
        </div>
      }
      onCancel={onCancel}
    />
  );
};

export default NewSprintModal;
