import React, { useEffect, useState } from "react";
import CustomModal from "../../Core/Common/CustomModal";
import CustomButton from "../../Core/Common/CustomButton";
import { Checkbox, Spin } from "antd";
import CustomFrostyMessage from "../../Core/Common/CustomFrostyMessage";
import { client } from "../../Core/utils/axiosClient";
import { useAppDispatch, useAppSelector } from "../../Core/redux/hooks";
import { CustomSearchWithDropDown } from "../../Core/Common/CustomSearch";
import CustomAvatar from "../../Core/Common/CustomAvatar";
import {
  getAllFiles,
  getAllFolders,
  getAllLinks,
  transferOwnershipController,
} from "../../Core/redux/api/vaultAPI";
import { useParams } from "react-router-dom";

const TransferOwnershipModal = ({
  visible,
  id,
  docType,
  onCancel,
  // usersWithAccess,
}) => {
  //   const [folderState, setFolderState] = useState({
  //     files: [],
  //     links: [],
  //   });

  const dispatch = useAppDispatch();
  //id is folder id
  const [loading, setLoading] = useState(false);
  const [selectedUser, setSelectedUser] = useState([]);
  const { tenantMembers } = useAppSelector((state) => state.tenant);
  const docTypeName =
    docType == 1 ? "folders" : docType == 2 ? "files" : "links";
  const { usersWithAccess } = useAppSelector(
    (state) => state.vault.transferOwnershipModal
  );

  const { user } = useAppSelector((state) => state.userDetails);
  const { projectId, "*": splat } = useParams();

  const onSubmit = async () => {
    onCancel();
    await dispatch(
      transferOwnershipController({
        docId: id,
        newUserId: selectedUser[0].userId,
        docType: docType,
      })
    );
    const queryObject: { projectId: string; folderId?: string } = { projectId };
    if (splat.length > 0) {
      const splatFolderId = splat.split("-");
      queryObject.folderId = splatFolderId[splatFolderId.length - 1];
    }
    docType == 1
      ? await dispatch(getAllFolders(queryObject))
      : docType == 2
      ? await dispatch(getAllFiles(queryObject))
      : await dispatch(getAllLinks(queryObject));
  };

  return (
    <CustomModal
      title={
        <div className="flex gap-x-1">
          <span className="mon-500-12 text-black">
            {/* {folderToDownload?.folderName} */}
            Transfer Ownership
          </span>
        </div>
      }
      width={"524px"}
      visible={visible}
      body={
        // <Spin spinning={loading}>
        <div className="max-h-[48.189386056191466vh] overflow-y-scroll  ">
          <div className="p-4 ">
            <CustomFrostyMessage shadow={false} close={false}>
              <p className="mon-500-12 text-primary-600 ">
                Are you sure you want to transfer your folder?
              </p>
            </CustomFrostyMessage>

            <CustomSearchWithDropDown
              style={{
                border: "1px solid #C0CBD7",
                height: "42px",
              }}
              placeholder="Search team members"
              options={
                // Filters current user from userWithAccess
                usersWithAccess
                  .filter(({ userId }) => {
                    return userId !== user.id;
                  })
                  ?.filter((u) => {
                    // Filters selected users as they cannot be added again, thus should not be show in dropdown
                    return (
                      selectedUser?.findIndex(
                        (index) => index.userId === u.userId
                      ) === -1
                    );
                  })
                  ?.map((u) => {
                    return {
                      id: u.userId,
                      value: u.userId,
                      label: `${u.firstname} ${u.lastname}`,
                      render: (value) => (
                        <div
                          className="flex items-center -mt-0.5"
                          //key={u.userId}
                          onClick={() =>
                            setSelectedUser((oldList) => [
                              {
                                userId: u.userId,
                                userEmail: u.email,
                              },
                            ])
                          }
                        >
                          <CustomAvatar
                            title={u.firstname ? u.firstname : "User"}
                            src={u.profilePicture}
                          />

                          <p className="ml-2 mon-400-12 text-gray-700 capitalize">
                            {u.firstname} {u.lastname}
                          </p>
                        </div>
                      ),
                    };
                  })
              }
            />
            {usersWithAccess.map((user) => {
              if (
                selectedUser?.findIndex((u) => user.userId === u.userId) !== -1
              ) {
                return (
                  <div
                    className="flex justify-between items-center py-5"
                    key={user.id}
                  >
                    <div className="flex items-center">
                      <CustomAvatar
                        title={`${user.firstname}`}
                        size={24}
                        src={user.profilePicture}
                      />
                      <p className="ml-2 mon-400-12 text-gray-700 capitalize">
                        {user.firstname} {user.lastname}
                      </p>
                    </div>
                  </div>
                );
              }
            })}
          </div>
        </div>
      }
      footer={
        <div className="flex justify-end py-4 gap-x-3 px-3 shadow-upward">
          <CustomButton
            text="Cancel"
            onClick={onCancel}
            className="border border-gray-200 text-gray-700 bg-white mon-500-14"
            height="40px"
            width="48%"
          />
          <CustomButton
            text="Transfer"
            onClick={onSubmit}
            className="mon-500-14 px-2 text-white bg-primary-600 "
            height="40px"
            width="48%"
          />
        </div>
      }
      onCancel={onCancel}
    />
  );
};

export default TransferOwnershipModal;
