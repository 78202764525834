import React, { Children, useCallback, useEffect, useState } from "react";
import CustomModal from "../../../Core/Common/CustomModal";
import CustomButton from "../../../Core/Common/CustomButton";

import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../Core/redux/hooks";
import { DndProvider, useDrag, useDrop } from "react-dnd";

import { manager } from "../../../Core/utils/dnd-utils";
import { updateSchedule } from "../../../Core/redux/slices/schedule.slice";
import { updateSwimlane } from "../../../Core/redux/api/scheduleAPI";

type MoveSwimlaneModalProps = {
  visible: boolean;

  onCancel: () => void;
};

const Item = ({ id, children, index, moveItem, canDrag, canDrop }) => {
  const [{ isDragging }, drag] = useDrag({
    item: { id, index },
    type: "card",
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [{ isOver }, drop] = useDrop({
    accept: "card",
    canDrop: () => canDrop,
    // hover: (item: { id: number; index: number }, monitor) => {
    //   if (item.id !== id) {
    //     moveItem(item.index, index);
    //   }
    // },
    drop: (item: { index: number }, monitor) => {
      moveItem(item.index, index);
    },

    collect: (monitor) => ({
      canDrop: monitor.canDrop(),
      isOver: monitor.isOver(),
    }),
  });

  const scrollUp = () => {
    window.scrollBy(0, -10);
  };

  // useEffect(() => {
  //   const intervalId = setInterval(() => {
  //     if (isOver) {
  //       scrollUp();
  //     } else {
  //       clearInterval(intervalId);
  //     }
  //   }, 10);

  //   return () => {
  //     clearInterval(intervalId);
  //   };
  // }, [isOver]);

  const opacity = isDragging ? 0.5 : 1;

  return (
    <div ref={canDrag ? drag : null} className={`rounded-xl `}>
      <div
        ref={drop}
        className={`${
          isOver ? (canDrop ? "bg-gray-200" : "bg-error-100") : "bg-white"
        }  py-[13px] px-4 rounded-xl flex items-center border overflow-hidden border-gray-300 mt-2 justify-between actions-row max-h-[48px]`}
        style={{
          opacity,
          transform: " translate(0, 0)",
          cursor: canDrop ? "move" : "no-drop	",
        }}
      >
        <div className="mon-600-12 text-gray-700 flex items-center gap-x-2">
          <p className="uppercase">{children}</p>
        </div>
      </div>
    </div>
  );
};

const MoveSwimlaneModal = ({
  visible,

  onCancel,
}: MoveSwimlaneModalProps) => {
  const dispatch = useAppDispatch();
  const { projectId } = useParams();
  const { swimlanes } = useAppSelector((state) => state.schedule);

  const [localSwimlanes, setLocalSwimlanes] = useState([...swimlanes]);
  const moveItem = useCallback(
    (dragIndex, dropIndex) => {
      const newSwimlanes = [...localSwimlanes];
      const draggedSwimlane = newSwimlanes[dragIndex];
      newSwimlanes.splice(dragIndex, 1);
      newSwimlanes.splice(dropIndex, 0, draggedSwimlane);

      const newArray = newSwimlanes.map((swimlane, index) => {
        return { ...swimlane, positionIndex: `${index + 1}` };
      });
      console.log(newArray);
      setLocalSwimlanes(newArray);
    },
    [dispatch, swimlanes, localSwimlanes]
  );

  const handleSave = () => {
    const modifiedSwimlanes = [...localSwimlanes];
    dispatch(updateSchedule({ key: "swimlanes", value: modifiedSwimlanes }));
    dispatch(updateSwimlane({ newSwimlanes: modifiedSwimlanes, projectId }));
    onCancel();
  };

  return (
    <CustomModal
      title={<p className="mon-500-16 text-gray-900">Move Swimlanes</p>}
      width={"500px"}
      visible={visible}
      body={
        <div className="max-h-[50.189386056191466vh] overflow-y-scroll bg-white px-5">
          <DndProvider manager={manager}>
            {localSwimlanes.map((swimlane, i) => (
              <Item
                key={swimlane.id}
                id={swimlane.id}
                index={i}
                moveItem={moveItem}
                canDrag={i !== 0 && i !== localSwimlanes.length - 1}
                canDrop={i !== 0 && i !== localSwimlanes.length - 1}
              >
                {swimlane.title}
              </Item>
            ))}
          </DndProvider>
        </div>
      }
      footer={
        <div className="flex justify-between pt-8 pb-4 px-3">
          <CustomButton
            text="Cancel"
            onClick={onCancel}
            className="border-2 border-gray-200 text-black bg-white mon-500-16 "
            height="44px"
            width="49%"
          />
          <CustomButton
            text="Save"
            onClick={handleSave}
            className="mon-500-16 text-white  bg-primary-600 "
            height="44px"
            width="49%"
          />
        </div>
      }
      onCancel={onCancel}
    />
  );
};

export default MoveSwimlaneModal;
