import React, { useState } from "react";
const RightScreen = () => {
  const [hover, setHover] = useState(false);

  const handleClick = () => {
    const parentDiv = document.getElementById("parent-div");
    const currentScreenHeight = window.innerHeight;
    parentDiv.scrollTo({
      top: parentDiv.scrollTop + currentScreenHeight / 2,
      behavior: "smooth",
    });
  };
  return (
    <div className="img-wrapper " id="parent-div">
      <img className="w-full" src="/images/landing-page.png" />
      <div
        className={`jumping-element ${hover ? "hover" : ""}`}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        onClick={handleClick}
      >
        <img src="/images/scroll.png" className="h-14" />
      </div>
    </div>
  );
};

export default RightScreen;
