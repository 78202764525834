import { DatePicker, Tooltip } from "antd";
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import CustomButton from "../../../Core/Common/CustomButton";

import CustomOnboardingInput from "../../../Core/Common/CustomOnboardingInput";
import FrostyErrorMessage from "../../../Core/Common/FrostyErrorMessage";
import { useAppSelector } from "../../../Core/redux/hooks";
import {
  changeStep,
  updateProjectOnboarding,
} from "../../../Core/redux/slices/projectOnboarding.slice";
import { PhaseIconPure } from "../../../Core/svg/PhaseIcon";
import CurrentStep from "./CurrentStep";
import StepHeading from "./StepHeading";
import StepLayout from "./StepLayout";

const PhaseCTA = ({ isSelected, text, phaseId, color, select, tooltip }) => {
  // const parentClassName =
  const [hover, setHover] = useState(false);
  const newColor = isSelected ? "#F9FBFE" : hover ? color : "#667085";
  return (
    <Tooltip
      color="#ffff"
      title={tooltip}
      overlayClassName="min-w-[326px]"
      placement="bottom"
    >
      <div
        className={` rounded-2xl flex items-center pl-[16px] pr-[18px] mon-500-12 cursor-pointer transform duration-200`}
        style={{
          backgroundColor: isSelected ? color : "#F9FBFE",
          color: newColor,
          boxShadow:
            hover || isSelected
              ? "0px 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.06)"
              : "",
          border: isSelected
            ? "none"
            : hover
            ? "1px solid #F9FBFE"
            : "1px solid #DDE5ED",
          maxHeight: "48px",
          minHeight: "48px",
        }}
        onClick={() => select(phaseId)}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <div className="flex items-center">
          <PhaseIconPure
            phaseId={phaseId}
            height={"32"}
            width="32"
            color={newColor}
          />
          <span className="ml-2">{text}</span>
          {isSelected ? (
            <>
              <div className="px-3">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="1"
                  height="20"
                  fill="none"
                  viewBox="0 0 1 20"
                >
                  <path
                    stroke="#F9FBFE"
                    strokeLinecap="round"
                    d="M0.5 0.5L0.5 19.5"
                  ></path>
                </svg>
              </div>
              <div className="">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12 4L4 12M4 4L12 12"
                    stroke="#ffffff"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
    </Tooltip>
  );
};

const Step5 = () => {
  const { selectedPhase } = useAppSelector((state) => state.projectOnboarding);
  console.log(selectedPhase,"swlected phase")
  const dispatch = useDispatch();
  const [error, setError] = useState(false);
  const handelSelect = (id) => {
    if (selectedPhase.includes(id)) {
      dispatch(
        updateProjectOnboarding({
          key: "selectedPhase",
          value: selectedPhase.filter((i) => i !== id),
        })
      );
    } else {
      dispatch(
        updateProjectOnboarding({
          key: "selectedPhase",
          value: [...selectedPhase, id],
        })
      );
    }
  };
  return (
    <StepLayout>
      <CurrentStep className={"mt-20"} currentStep={5} totalStep={7} />
      <StepHeading
        title={
          <p className="text-gray-900 mon-500-20">
            Let’s select and add the phases
          </p>
        }
        subTitle="For an end-to-end development of your Web App, I have pre-selected
            the important phases for you."
      />

      <div className=" mt-6 flex gap-x-6">
        <PhaseCTA
          isSelected={selectedPhase.includes(1)}
          text="Ideation"
          phaseId={1}
          color={"#FFDB01"}
          select={handelSelect}
          tooltip={
            <div className="py-2 px-2">
              <h4 className="mon-600-14">Ideation</h4>
              <p className="mon-400-12 text-gray-500 mt-2">
                In the planning stage, the team discusses what can go wrong
                during the development process and derive solutions. Includes
                documentation of:
              </p>
              <ul className="text-gray-900 mt-2 pl-3 mon-500-12 list-disc list-inside">
                <li>Technologies </li>
                <li>Restrictions </li>
                <li>Workload </li>
                <li>Budget </li>
                <li>And interaction with third parties</li>
              </ul>
            </div>
          }
        />
        <PhaseCTA
          isSelected={selectedPhase.includes(2)}
          text="Design"
          phaseId={2}
          color={"#F92B63"}
          select={handelSelect}
          tooltip={
            <div className="py-2 px-2">
              <h4 className="mon-600-14">Design</h4>
              <p className="mon-400-12 text-gray-500 mt-2">
                The design phase is a stage where software developers define the
                technical details of the product. Depending on the project,
                these details can include:
              </p>
              <ul className="text-gray-900 mt-2 pl-3 mon-500-12 list-disc list-inside">
                <li>Screen designs </li>
                <li>Databases </li>
                <li>Sketches </li>
                <li>System interfaces </li>
                <li>And prototypes</li>
              </ul>
            </div>
          }
        />
        <PhaseCTA
          isSelected={selectedPhase.includes(3)}
          text="Development"
          phaseId={3}
          color={"#6938EF"}
          select={handelSelect}
          tooltip={
            <div className="py-2 px-2">
              <h4 className="mon-600-14">Development</h4>
              <p className="mon-400-12 text-gray-500 mt-2">
                The development phase is where developers actually write code
                and build the application according to the earlier design
                documents and outlined specifications.
                <br />
                <br />
                <span>This includes: </span>
              </p>
              <ul className="text-gray-900 mt-2 pl-3 mon-500-12 list-disc list-inside">
                <li>Setting up the product structure </li>
                <li>Coding and unit testing </li>
                <li>Writing test cases </li>
                <li>Detecting bugs & errors </li>
                <li>And fixing and retesting the bugs</li>
              </ul>
            </div>
          }
        />
      </div>
      <div className=" mt-6 flex gap-x-6">
        <PhaseCTA
          isSelected={selectedPhase.includes(4)}
          text="Deployment"
          phaseId={4}
          color={"#33B7FF"}
          select={handelSelect}
          tooltip={
            <div className="py-2 px-2">
              <h4 className="mon-600-14">Deployment</h4>
              <p className="mon-400-12 text-gray-500 mt-2">
                Once the product is tested, it is deployed in the production
                environment or first UAT (User Acceptance testing) is done
                depending on the customer expectation.
              </p>
            </div>
          }
        />
        <PhaseCTA
          isSelected={selectedPhase.includes(5)}
          text="Maintenance"
          phaseId={5}
          color={"#46C66C"}
          select={handelSelect}
          tooltip={
            <div className="py-2 px-2">
              <h4 className="mon-600-14">Maintenance</h4>
              <p className="mon-400-12 text-gray-500 mt-2">
                After the deployment of a product on the production environment,
                maintenance of the product i.e. if any issue comes up and needs
                to be fixed or any enhancement is to be done is taken care by
                the developers.
              </p>
            </div>
          }
        />
      </div>
      <FrostyErrorMessage
        messgae={"Please select at least one Phase"}
        visible={selectedPhase.length === 0 && error}
        className="my-10"
      />
      <div className="mt-6 flex gap-x-6">
        <CustomButton
          text="Add phases"
          className={`bg-primary-600 text-white ${
            selectedPhase.length === 0 ? "opacity-30" : ""
          }`}
          height="40px"
          width="116px"
          onClick={() => {
            if (selectedPhase.length === 0) {
              setError(true);
              return;
            }
            dispatch(changeStep(6));
          }}
        />
        <CustomButton
          text="Back"
          className="border border-gray-300 text-gray-700 bg-white mon-500-14"
          height="40px"
          width="68px"
          onClick={() => dispatch(changeStep(4))}
        />
      </div>
    </StepLayout>
  );
};

export default Step5;
