import React from "react";
import CustomSelect from "../../../Core/Common/CustomSelect";
import { PriorityIconWithLabel } from "../../../Core/svg/PriorityIcon";

function TaskPriorityDropDown({
  currentDropDown,
  task,
  setTask,
  setCurrentDropDown,
}) {
  const options = [
    { id: "1", value: 1, priority: 1 },
    { id: "2", value: 2, priority: 2 },
    { id: "3", value: 3, priority: 3 },
  ];

  return (
    <div className="min-h-[40px] items-center flex">
      {currentDropDown === "priority" ? (
        <CustomSelect
          border
          options={options.map((option) => ({
            ...option,
            render: (value) => (
              <p>
                <PriorityIconWithLabel priority={option.priority} />
              </p>
            ),
          }))}
          value={task.priorityId}
          defaultOpen
          onChange={async (e) => {
            setTask(e);
          }}
          onDropdownVisibleChange={(open) => !open && setCurrentDropDown("")}
        />
      ) : (
        <p onClick={() => setCurrentDropDown("priority")} className="">
          <PriorityIconWithLabel priority={task.priorityId} />
        </p>
      )}
    </div>
  );
}

export default TaskPriorityDropDown;
