export const getPhaseName = (id)=>{
    switch(+id){
        case 1: 
         return "Ideation"
        case 2:
            return "Design"
        case 3:
            return "Development"
        case 4:
            return "Deployment"
        case 5:
            return "Maintenance"
        default:
            return "-"
    }
}