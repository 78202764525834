import { createSlice, current } from "@reduxjs/toolkit";

import {
  createSprint,
  getAllSprintsByPhase,
  getGoalsByPhase,
  getGoalsBySprint,
  getProjectPhases,
  getSprintsByPhase,
  getSwimlanes,
  updateGoal,
  updatePhase,
  updateSprint,
} from "../api/scheduleAPI";
//We do not recommend the object notation for extraReducers any more, especially with TypeScript you should be using the builder notation --by redux toolkit team.

const initialState = {
  loading: false,
  sprintsLoading: false,
  phaseLoading: false,
  goalsLoading: false,
  newSprintModal: {
    visible: false,
    isNewScope: false,
    scopeDescription: "",
  },
  newSprintScope: false,
  newGoalModal: false,
  newGoalScope: false,
  newScopeModal: false,
  moveSwimlaneModal: false,
  error: {},
  status: "idle",
  phases: [
    {
      id: 0,
      phaseId: "0",
      phaseProgress: 0,
      phases: "",
      startDate: "",
      endDate: "",
      color: "",
      isOngoing: 0,
      isCompleted: 0,
      components: "",
    },
  ],
  currentPhaseGoals: [],
  currentSelectedPhase: {
    id: 0,
    phaseId: "0",
    phaseProgress: 0,
    phases: "",
    startDate: "",
    endDate: "",
    color: "",
    isOngoing: 0,
    isCompleted: 0,
  },
  selectedSprint: {
    id: "0",
    name: "",
    endDate: "",
    startDate: "",
    goals: [],
    isActive: 1,
    isCompleted: 0,
    isOngoing: 0,
    status: 1,
    phaseId: 1,
  },
  sprints: [
    {
      id: 0,
      name: "",
      goals: [{ id: "", swimlaneId: 0, subTasks: [], name: "", phaseId: "" }],
      status: 0,
      isActive: 1,
      isCompleted: 0,
      isOngoing: 0,
      phaseId: 1,
    },
  ],
  allSprints: [],
  goals: [],

  selectedTask: null,
  selectedBug: null,
  selectedSubTask: null,
  selectedEvent: null,

  // schedule revised
  sprintDetailsModal: false,
  deleteSubTaskModal: false,
  newTaskModal: {
    visible: false,
    isNewScope: false,
    scopeDescription: "",
  },
  newBugModal: false,
  newSubTaskModal: {
    visible: false,
    editTask: false,
    subtask: {
      sprintId: "",
      projectId: "",
      phaseId: "",
      names: [],
      isCompleted: false,
      statusId: "",
      priorityId: "",
      goalType: "",
      assigneeId: "",
      reporterId: "",
      description: "",
      parentGoalId: "",
      goalId: "",
      parentTaskName: "Task",
    },
  },
  newEventModal: false,
  deleteTaskModal: {
    visible: false,
    statusId: 0,
    goalId: 1,
  },
  deleteBugModal: {
    visible: false,
    statusId: 0,
    goalId: 1,
  },
  deleteStatusModal: false,
  swimlanes: [
    {
      id: 0,
      projectId: 0,
      statusId: 1,
      title: "To Do",
      positionIndex: "0",
    },
  ],

  swimlaneDetails: {
    id: 0,
    projectId: 0,
    statusId: 1,
    title: "To Do",
  },
  endPhaseModal: false,
  deleteSprintModal: {
    visible: false,
    sprintId: "",
  },
  completeSprintModal: {
    visible: false,
    sprintId: "",
  },
  filterGoalParameter: {
    searchValue: "",
    assignee: [],
    typeDropdown: [],
    filter: "",
  },
  createdSprintId: null,
  startSprintModal: false,
  startPhaseModal: false,
  newSprint: {},
  filters: {
    assignee: [],
    reporter: [],
    phase: [],
    priority: [],
    status: [],
    category: [],
    component: [],
  },
  duplicateTaskModal: {
    visible: false,
    duplicateTaskDetails: null,
  },
};

export const scheduleSlice = createSlice({
  name: "schedule",
  initialState,
  reducers: {
    resetToInitialSchedule: (state, action) => {
      Object.keys(initialState).forEach(
        (key) => (state[key] = initialState[key])
      );
    },
    updateSchedule: (state, action) => {
      state[action.payload.key] = action.payload.value;
    },

    setCurrentSelectedPhase: (state, action) => {
      state.currentSelectedPhase = action.payload;
    },

    setSelectedSprint: (state, action) => {
      state.selectedSprint = action.payload;
    },

    resetSchedule: (state, action) => {
      action.payload.map((ele) => {
        state[ele] = initialState[ele];
      });
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getProjectPhases.pending, (state, { payload }) => {
      state.phaseLoading = true;
      state.status = "pending";
    });
    builder.addCase(getProjectPhases.fulfilled, (state, { payload }) => {
      state.phaseLoading = false;
      state.status = "fulfilled";
      state.phases = payload;
      if (payload.length > 0) {
        console.log(payload);
        if (state.currentSelectedPhase) {
          const newPhase = payload.find((phase) => {
            console.log(phase, phase.id, state.currentSelectedPhase);
            return +phase.id === +state.currentSelectedPhase.id;
          });
          console.log(newPhase, "xxxxyyyyyy");
          if (newPhase && newPhase.phaseId)
            state.currentSelectedPhase = newPhase;
        } else {
          state.currentSelectedPhase = payload[0];
        }
      }

      state.error = null;
    });
    builder.addCase(getProjectPhases.rejected, (state, { payload }) => {
      state.phaseLoading = false;
      state.status = "rejected";
      state.error = payload;
    });
    builder.addCase(updatePhase.pending, (state, { payload }) => {
      state.phaseLoading = true;
      state.status = "pending";
    });
    builder.addCase(updatePhase.fulfilled, (state, { payload }) => {
      state.phaseLoading = false;
      state.status = "fulfilled";

      state.error = null;
    });
    builder.addCase(updatePhase.rejected, (state, { payload }) => {
      state.phaseLoading = false;
      state.status = "rejected";
      state.error = payload;
    });

    builder.addCase(getSprintsByPhase.pending, (state, { payload }) => {
      state.sprintsLoading = true;
      state.status = "pending";
    });
    builder.addCase(getSprintsByPhase.fulfilled, (state, { payload }) => {
      state.sprintsLoading = false;
      state.status = "fulfilled";
      state.sprints = payload.result;
      state.error = null;
    });
    builder.addCase(getSprintsByPhase.rejected, (state, { payload }) => {
      state.sprintsLoading = false;
      state.status = "rejected";
      state.error = payload;
    });

    // get goals by phase id

    builder.addCase(getGoalsByPhase.pending, (state, { payload }) => {
      state.sprintsLoading = true;
      state.status = "pending";
    });
    builder.addCase(getGoalsByPhase.fulfilled, (state, { payload }) => {
      state.sprintsLoading = false;
      state.status = "fulfilled";
      state.currentPhaseGoals = payload.result;
      state.error = null;
    });
    builder.addCase(getGoalsByPhase.rejected, (state, { payload }) => {
      state.sprintsLoading = false;
      state.status = "rejected";
      state.error = payload;
    });

    // get All sprints in single section

    builder.addCase(getAllSprintsByPhase.pending, (state, { payload }) => {
      state.sprintsLoading = true;
      state.status = "pending";
    });
    builder.addCase(getAllSprintsByPhase.fulfilled, (state, { payload }) => {
      state.sprintsLoading = false;
      state.status = "fulfilled";
      state.allSprints = [...state.allSprints, ...payload.result];
      state.error = null;
    });
    builder.addCase(getAllSprintsByPhase.rejected, (state, { payload }) => {
      state.sprintsLoading = false;
      state.status = "rejected";
      state.error = payload;
    });

    builder.addCase(getGoalsBySprint.pending, (state, { payload }) => {
      state.sprintsLoading = true;
      state.status = "pending";
    });
    builder.addCase(getGoalsBySprint.fulfilled, (state, { payload }) => {
      state.sprintsLoading = false;
      state.status = "fulfilled";
      state.sprints = state.sprints.map((sprint) => {
        if (
          payload.result.length > 0 &&
          +sprint.id === +payload.result[0].sprintId
        ) {
          return { ...sprint, goals: payload.result };
        }
        return sprint;
      });
      state.goals = payload.result;
      state.error = null;
      state.newGoalModal = false;
    });
    builder.addCase(getGoalsBySprint.rejected, (state, { payload }) => {
      state.sprintsLoading = false;
      state.status = "rejected";
      state.error = payload;
    });
    builder.addCase(createSprint.pending, (state, { payload }) => {
      state.sprintsLoading = true;
      state.status = "pending";
    });
    builder.addCase(createSprint.fulfilled, (state, { payload }) => {
      state.sprintsLoading = false;
      state.status = "fulfilled";
      //state.phases = payload;
      state.newSprintScope = false;
      state.newSprintModal = initialState.newSprintModal;
      state.createdSprintId = payload.createdSprint[0].id;
      state.selectedSprint = payload.createdSprint[0];
    });
    builder.addCase(createSprint.rejected, (state, { payload }) => {
      state.sprintsLoading = false;
      state.status = "rejected";
      state.error = payload;
    });

    builder.addCase(getSwimlanes.pending, (state, { payload }) => {
      state.loading = true;
      state.status = "pending";
    });
    builder.addCase(getSwimlanes.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.status = "fulfilled";
      //state.phases = payload;
      state.swimlanes = payload.result;
      state.error = null;
    });
    builder.addCase(getSwimlanes.rejected, (state, { payload }) => {
      state.loading = false;
      state.status = "rejected";
      state.error = payload;
    });

    // update sprint
    builder.addCase(updateSprint.pending, (state, { payload }) => {
      state.loading = true;
      state.status = "pending";
    });
    builder.addCase(updateSprint.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.status = "fulfilled";
      state.error = null;

      const updatedSprint = payload.result[0];

      // Check if the selected sprint needs to be updated
      if (state.selectedSprint?.id == updatedSprint.id) {
        state.selectedSprint = { ...state.selectedSprint, ...updatedSprint };
      }

      // Find the index of the sprint that needs to be updated
      const sprintIndex = state.sprints.findIndex(
        (s) => s.id == updatedSprint.id
      );
      if (sprintIndex !== -1) {
        // Create a new array with the updated sprint
        state.sprints = [
          ...state.sprints.slice(0, sprintIndex),
          { ...state.sprints[sprintIndex], ...updatedSprint },
          ...state.sprints.slice(sprintIndex + 1),
        ];
      }
    });
    builder.addCase(updateSprint.rejected, (state, { payload }) => {
      state.loading = false;
      state.status = "rejected";
      state.error = payload;
    });

    // update goal
    builder.addCase(updateGoal.pending, (state, { payload }) => {
      state.loading = true;
      state.status = "pending";
    });
    builder.addCase(updateGoal.fulfilled, (state, { payload }) => {
      // Update the loading and error states
      state.loading = false;
      state.status = "fulfilled";
      state.error = null;

      // Get the updated goal and its id and parentGoalId
      const updatedGoal = payload.result[0];
      const { id, parentGoalId } = updatedGoal;

      // If the updated goal has a parent goal, find the parent goal in the list
      // and update its subtasks with the updated goal
      if (parentGoalId) {
        state.goals = state.goals.map((goal) => {
          // If the goal is not the parent goal, return it unchanged
          if (goal.id !== parentGoalId) return goal;
          // Find the updated goal in the subtasks and return the updated goal
          const newSubtask = goal.subTasks.map((subtask) =>
            subtask.id === id ? updatedGoal : subtask
          );
          // Return the updated parent goal with the updated subtasks
          return { ...goal, subTasks: newSubtask };
        });
      } else {
        // If the updated goal does not have a parent goal, find it in the list
        // and update it with the updated goal
        state.goals = state.goals.map((goal) =>
          goal.id !== id ? goal : { ...goal, ...updatedGoal }
        );
      }
    });

    builder.addCase(updateGoal.rejected, (state, { payload }) => {
      state.loading = false;
      state.status = "rejected";
      state.error = payload;
    });
  },
});

export const {
  updateSchedule,
  setCurrentSelectedPhase,
  resetSchedule,
  setSelectedSprint,
  resetToInitialSchedule,
} = scheduleSlice.actions;

export default scheduleSlice.reducer;
