import { Tooltip } from "antd";
import React, { useState, useEffect, useRef } from "react";
import CustomInput from "../../../Core/Common/CustomInput";
import CustomInputWithSaveAndEdit from "../../../Core/Common/CustomInputWithSaveAndEdit";
import { updateSwimlane } from "../../../Core/redux/api/scheduleAPI";
import { useAppDispatch, useAppSelector } from "../../../Core/redux/hooks";
import { updateSchedule } from "../../../Core/redux/slices/schedule.slice";
import MenuDropdown from "../../Conversations/Components/MenuDropdown";

const KanbanColumnHeader = ({
  isTask,
  title,
  count,
  channel,
  addSwimalne,
}: {
  isTask: boolean;
  title: string;
  count: string | number;
  channel: any;
  addSwimalne?: () => void;
}) => {
  const [renameStatus, setRenameStatus] = useState(false);
  const dispatch = useAppDispatch();
  const { schedule } = useAppSelector((state) => state.projects);
  const phaseAndSprints = schedule?.[1]?.[0];

  const [name, setName] = useState(title);

  const { swimlanes } = useAppSelector((state) => state.schedule);

  const handleRename = () => {
    let modifiedSwimlanes = [...swimlanes];
    modifiedSwimlanes = modifiedSwimlanes.map((s) => {
      if (s.id === channel.id) {
        return { ...s, title: name };
      }
      return s;
    });
    dispatch(updateSchedule({ key: "swimlanes", value: modifiedSwimlanes }));
    setRenameStatus(false);
    dispatch(updateSwimlane({ title: name, id: channel.id }));
  };
  return renameStatus ? (
    <CustomInputWithSaveAndEdit
      //className="min-h-[48px] rounded-xl"
      inputClassName="min-h-[45px] rounded-xl"
      focused={true}
      //wrapperClassName="min-h-[48px] rounded-xl"
      onPressEnter={handleRename}
      value={name}
      onOk={handleRename}
      onChange={(e) => setName(e.target.value)}
      onCancel={() => setRenameStatus(false)}
    />
  ) : (
    <div className="bg-white  py-[13px] px-4 rounded-xl flex items-center justify-between actions-row max-h-[48px]">
      <>
        <div className="mon-600-12 text-gray-700 flex items-center gap-x-2">
          <p className="uppercase">{title}</p>
          <div className=" rounded-full py-[2px] px-2 bg-primary-50">
            <p className="mon-500-12 text-primary-700">{count}</p>
          </div>
        </div>
        {phaseAndSprints?.update === 1 && (
          <div className="actions">
            <div className="flex h-full items-center gap-x-2">
              <Tooltip title={isTask ? "Add new task" : "Add new sprint"}>
                <button>
                  <img
                    src="/images/icons/plus-gray.svg"
                    onClick={() =>
                      isTask
                        ? dispatch(
                            updateSchedule({
                              key: "newTaskModal",
                              value: {
                                visible: true,
                                isNewScope: false,
                                scopeDescription: "",
                              },
                            })
                          )
                        : dispatch(
                            updateSchedule({
                              key: "newSprintModal",
                              value: {
                                visible: true,
                                isNewScope: false,
                                scopeDescription: "",
                              },
                            })
                          )
                    }
                  />
                </button>
              </Tooltip>
              {isTask && (
                <MenuDropdown
                  elements={[
                    {
                      id: 1,
                      image: "/images/icons/edit-01.svg",
                      text: "Rename swimlane",
                      textColor: "text-gray-700",

                      onclick: () => setRenameStatus(true),
                    },
                    {
                      id: 2,
                      image: "/images/icons/plus-square-gray.svg",
                      text: "Add new swimlane",
                      textColor: "text-gray-700",
                      onclick: () => addSwimalne(),
                    },
                    {
                      id: 3,
                      image: "/images/icons/move.svg",
                      text: "Move swimlane",
                      textColor: "text-gray-700",
                      borderBottom: true,
                      onclick: () =>
                        dispatch(
                          updateSchedule({
                            key: "moveSwimlaneModal",
                            value: true,
                          })
                        ),
                    },

                    {
                      id: 4,
                      image: "/images/icons/trash.svg",
                      text: "Delete",
                      textColor: "text-error-600",
                      onclick: () => {
                        dispatch(
                          updateSchedule({
                            key: "deleteStatusModal",
                            value: true,
                          })
                        );
                        dispatch(
                          updateSchedule({
                            key: "swimlaneDetails",
                            value: channel,
                          })
                        );
                      },
                    },
                  ]}
                  customFunc={undefined}
                />
              )}
            </div>
          </div>
        )}
      </>
    </div>
  );
};

export default KanbanColumnHeader;
